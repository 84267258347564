import {getUserProgramFinderPath} from '@utils/ClientPaths';
import {useGetMarketplaceText} from './RequestMenuButtonAndDrawer.hooks';
import {RequestType} from './RequestMenuButtonAndDrawer.interfaces';
import {NavigateFunction} from 'react-router-dom';

export const getHandleRequestFormReset =
  ({
    drawerVisible,
    form,
    setDrawerVisible,
    setRequestType,
    setSelectionIndex,
    setIsSubmitButtonDisabled,
    setIsSubmitButtonLoading,
  }) =>
  () => {
    setSelectionIndex(null);
    setDrawerVisible(!drawerVisible);
    setRequestType(undefined);
    setIsSubmitButtonDisabled(true);
    setIsSubmitButtonLoading(false);
    form.resetFields();
  };

export const getRequestTypeChangeHandler = ({
  requestType,
  setFormVisible,
  setIsSubmitButtonDisabled,
}: {
  requestType?: RequestType;
  setFormVisible: (visible: boolean) => void;
  setIsSubmitButtonDisabled: (disabled: boolean) => void;
}) => {
  return () => {
    setFormVisible(requestType !== undefined);
    setIsSubmitButtonDisabled(requestType === undefined);
  };
};

export const getSelectRequestTypeHandler = ({
  requestTypeOptions,
  setFormVisible,
  setRequestType,
  setSelectionIndex,
  hasFilters,
  drawerVisible,
}) => {
  return (idx: number) => {
    if (hasFilters.get('request') || drawerVisible) {
      setSelectionIndex(idx);
      setRequestType(requestTypeOptions[idx].requestType);
      setFormVisible(true);
    }
  };
};

export const useGetMarketplaceLinkHandler = (
  setDrawerVisible: (visible: boolean) => void
) => {
  const marketplaceLinkText = useGetMarketplaceText();

  return ({target}) => {
    const isMarketplaceLink = target?.innerText === marketplaceLinkText;
    if (isMarketplaceLink) setDrawerVisible(false);
  };
};

export const useGetProgramFinderHandler =
  (navigate: NavigateFunction) => () => {
    navigate(getUserProgramFinderPath());
  };

export const useGetRequestMenuButtonAndDrawerHandlers = ({
  drawerVisible,
  form,
  navigate,
  onSubmit,
  setDrawerVisible,
  setRequestType,
  setSelectionIndex,
  setIsSubmitButtonDisabled,
  setIsSubmitButtonLoading,
  userProgramPlanQuery,
  learningBudsQuery,
}) => {
  const handleReset = getHandleRequestFormReset({
    drawerVisible,
    form,
    setDrawerVisible,
    setRequestType,
    setSelectionIndex,
    setIsSubmitButtonDisabled,
    setIsSubmitButtonLoading,
  });

  const handleOnSubmit = () => {
    onSubmit?.();
  };

  const handleOnCloseSuccess = () => {
    setDrawerVisible?.(false);
    handleReset?.();
    userProgramPlanQuery?.refetch();
    learningBudsQuery?.refetch();
  };

  const handleOnClickOption = (requestType: RequestType, idx: number) => {
    setRequestType(requestType);
    setSelectionIndex(idx);
  };

  const handleOnClickMarketplaceLink =
    useGetMarketplaceLinkHandler(setDrawerVisible);

  const onClickProgramFinder = useGetProgramFinderHandler(navigate);
  const handleOnClickProgramFinder = () => {
    onClickProgramFinder();
    setDrawerVisible(false);
  };

  const handleOnClickReset = () => {
    handleReset();
    setDrawerVisible(false);
  };

  return {
    handleOnClickMarketplaceLink,
    handleOnClickOption,
    handleOnClickProgramFinder,
    handleOnClickReset,
    handleOnCloseSuccess,
    handleOnSubmit,
    handleReset,
  };
};
