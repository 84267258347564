// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/ResourceApprovalRequestVM.cs

import {z} from 'zod';
import {ApprovalRankTypeSchema} from './ApprovalRankTypeSchema.schema';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';
import {ApprovalTypeSchema} from './ApprovalTypeSchema.schema';

export const ResourceApprovalRequestVMSchema = z.object({
  amount: z.number(),
  amountOverLimit: z.number().nullable().optional(),
  amountType: z.string(),
  id: z.number(),
  manager: z.string(),
  rank: ApprovalRankTypeSchema,
  rejectedById: z.string().nullable().optional(),
  requestedBy: z.string(),
  requestedDate: z.string(),
  status: ApprovalStatusSchema,
  title: z.string(),
  type: ApprovalTypeSchema,
  uniqueId: z.string(),
});
