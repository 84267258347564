import {COLORS} from '../../../utils/constants';

const moneyIncentiveModalConstants = {
  padding: '16px',
  background: 'transparent',
  borderRadius: '4px',
  width: '208px',
  height: '186px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
export const ContainerStyles = {
  modalProgramCard: {
    padding: '14px',
    background: COLORS.White,
    borderRadius: '10px',
    boxShadow: COLORS.BoxShadowStandard,
  },
  moneyIncentiveModalCard: {
    ...moneyIncentiveModalConstants,
    border: `1px solid ${COLORS.Neutral300}`,
    hoverBorder: `1px solid ${COLORS.Blue800}`,
  },
  selectedMoneyIncentiveModalCard: {
    ...moneyIncentiveModalConstants,
    border: `1px solid ${COLORS.Blue800}`,
    background: COLORS.Blue50,
  },
  disabledMoneyIncentiveModalCard: {
    ...moneyIncentiveModalConstants,
    border: `1px solid ${COLORS.Neutral300}`,
  },
  commonCard: {
    padding: '24px',
    background: COLORS.White,
    borderRadius: '8px',
    boxShadow: COLORS.BoxShadowStandard,
    display: 'flex',
  },
  emptyApprovalContainer: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  personalLearningBudgetGauge: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '200px',
  },
  badgeContainer: {
    height: '23px',
    width: '23px',
    margin: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F2F2F2',
    borderRadius: '4px',
  },
  menuItemBadgeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addFundsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // This is a generic theme, to adjust all table bottom padding
  tableBottomPadding: {
    padding: '0px 0px 100px 0px',
  },
  buttonUploadContainer: {
    padding: '12px',
    height: '47px',
    width: '47px',
    border: '1px solid #747578',
    borderRadius: '4px',
    cursor: 'pointer',
    hoverBackground: COLORS.Neutral100,
  },
  programStatusCardContainer: {
    background: COLORS.White,
    border: `1px solid ${COLORS.Neutral200}`,
    borderRadius: '8px',
    padding: '16px 13px 22px',
    width: '100%',
  },
  progressBarSectionContainer: {
    padding: '16px 16px 29px',
    borderTop: `1px solid ${COLORS.Neutral200};`,
    width: '100%',
  },
};
