// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IAcademyLevelBasicInfo.cs

import {z} from 'zod';

export const IAcademyLevelBasicInfoSchema = z.object({
  description: z.string(),
  id: z.number(),
  title: z.string(),
});
