// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/PreApprovalQuestionFunctionalityType.cs

enum PreApprovalQuestionFunctionalityType {
  NotFeatureQuestion,
  ApprovalRequestFrequency,
  StartDate,
  EndDate,
  CurrencySelection,
}

export default PreApprovalQuestionFunctionalityType;
