import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';
import {DATE_FORMAT, getDisabledDates} from '@utils/timeUtils';
import {
  getCommonRules,
  renderLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {handleDateInputProps} from '@utils/preApprovalRequest';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {showHelperTextIfAvailable} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface DateInputProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  isHelperTextOn: boolean;
  rangeDates?: {startDate: any; endDate: any};
  setRangeDates?: (rangeDates) => void;
}
export const DateInput = ({
  item,
  isHelperTextOn,
  rangeDates,
  setRangeDates,
}: DateInputProps) => {
  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const {
    isRangeDate,
    isStartDate,
    isEndDate,
    isReimbursementDate,
    responseDate,
    tomorrow,
  } = handleDateInputProps({
    budgetInfoQuery: getLearningBudgetInfoQuery.data,
    item,
  });

  return (
    <LearnInFormItem
      style={{
        width: isRangeDate && '50%',
        display: isRangeDate && 'inline-block',
        paddingRight: isRangeDate && '4px',
        paddingLeft: isRangeDate && '4px',
      }}
      key={item.id}
      name={String(item.id)}
      label={renderLabel(item)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      className="lb-application-drawer"
      initialValue={responseDate}
      requiredMark={false}
      rules={getCommonRules(item, isHelperTextOn)}>
      <StyledLearnInDatePicker
        data-testid="date-picker"
        style={{width: '100%'}}
        disabledDate={getDisabledDates({
          isReimbursementDate,
          isStartDate,
          isEndDate,
          rangeDates,
          tomorrow,
        })}
        format={DATE_FORMAT.MONTH_DAY_YEAR}
        onChange={(e) => {
          setRangeDates((prevState) => ({
            ...prevState,
            [isEndDate ? 'endDate' : 'startDate']: e,
          }));
        }}
      />
    </LearnInFormItem>
  );
};
