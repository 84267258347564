// Do not modify directly
// Generated from file: learnin.core.api/Managers/Calendar/CalendarManager.cs

import {z} from 'zod';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';

export const TimeApprovalPayloadSchema = z.object({
  status: ApprovalStatusSchema.nullable().optional(),
  userPlanItemId: z.number().nullable().optional(),
  userTimeIncentiveId: z.number().nullable().optional(),
});
