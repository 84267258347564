import {
  CSSProperties,
  forwardRef,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import styled from 'styled-components';
import {Select} from 'antd';
import {LabeledValue} from 'antd/lib/tree-select';
import {OptionProps, SelectProps} from 'antd/lib/select';

import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';

export interface SProps extends SelectProps<any> {
  children?: ReactNode;
  allowClear?: boolean;
  autoClearSearchValue?: boolean;
  autoFocus?: boolean;
  bordered?: boolean;
  clearIcon?: ReactNode;
  defaultActiveFirstOption?: boolean;
  defaultOpen?: boolean;
  defaultValue?:
    | string
    | string[]
    | number
    | number[]
    | LabeledValue
    | LabeledValue[];
  disabled?: boolean;
  dropdownClassName?: string;
  dropdownMatchSelectWidth?: boolean | number;
  dropdownRender?: (
    originNode: ReactNode
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  dropdownStyle?: CSSProperties;
  filterOption?: boolean | ((inputValue: string, option?: any) => any);
  getPopupContainer?: (props: any) => HTMLElement;
  labelInValue?: boolean;
  listHeight?: number;
  loading?: boolean;
  maxTagCount?: number;
  maxTagPlaceholder?: ReactNode | ((omittedValues: any) => any);
  maxTagTextLength?: number;
  menuItemSelectedIcon?: ReactNode;
  mode?: 'multiple' | 'tags';
  notFoundContent?: ReactNode;
  open?: boolean;
  optionFilterProp?: string;
  optionLabelProp?: string;
  options?: {label: any; value: any}[];
  placeholder?: string;
  removeIcon?: ReactNode;
  searchValue?: string;
  showArrow?: boolean;
  showSearch?: boolean;
  size?: 'large' | 'middle' | 'small';
  suffixIcon?: ReactNode;
  tagRender?: (
    props: any
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  tokenSeparators?: string[];
  value?: string | string[] | number | number[] | LabeledValue | LabeledValue[];
  virtual?: boolean;
  onBlur?: (event: any) => void;
  onChange?: (value: any, option: any) => void;
  onClear?: () => void;
  onDeselect?: (value: any, option: any) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  onFocus?: (event: any) => void;
  onInputKeyDown?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
  onPopupScroll?: (event: any) => void;
  onSearch?: (value: string) => void;
  onSelect?: (value: any, option: any) => void;
  isError?: boolean;
  name?: string;
}

/**
 *
 * This is the standard select that should be used in all places where selects are needed. You may need to
 * add new tags to this select if you are implementing a new type of select. The default select should cover
 * most use cases  but you can pass in props to override styling if necessary.
 */
export const LearnInSelect = forwardRef(
  ({bordered = true, id = '', ...rest}: SProps, ref) => {
    const props = {bordered, id, ...rest};
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);

    const determineBorder = (open: any, error: any) => {
      if (open && !error && bordered) {
        return `${COLORS.Blue800}`;
      } else if (open && error) {
        return `${COLORS.Red800}`;
      } else {
        return `${COLORS.Neutral300}`;
      }
    };
    return (
      <StyledSelect
        size="large"
        bordered={bordered}
        isOpen={selectIsOpen}
        isError={false}
        ref={ref}
        id={id}
        placeholder={props?.placeholder || i18n.t(k.GENERIC__SELECT_ONE)}
        determineBorder={determineBorder}
        onDropdownVisibleChange={(open: any) => setSelectIsOpen(open)}
        {...rest}>
        {props.children}
      </StyledSelect>
    );
  }
);

const StyledSelect = styled(Select)<{bordered?: number}>`
  background: ${COLORS.White};
  border-radius: 4px !important;
  & .ant-select-selector {
    border: ${({bordered}) => !bordered && `none`} !important;
    border-color: ${(props: any) =>
      props.determineBorder(props.isOpen, props.isError)}!important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  &.ant-select-open:not(.ant-select-show-search) .ant-select-arrow {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
  }
  .ant-select-selection-placeholder {
    color: ${COLORS.Neutral600};
  }
`;

interface OProps extends OptionProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  value: string | number;
  children: ReactNode;
}

/**
 *
 * This is the standard select option that should be used in all places where select options are needed. You may need to
 * add new tags to this select option if you are implementing a new type of select option. The default select option should cover
 * most use cases  but you can pass in props to override styling if necessary.
 */
export function LearnInSelectOption({children, ...rest}: OProps) {
  return <StyledSelectOption {...rest}>{children}</StyledSelectOption>;
}
const StyledSelectOption = styled(Select.Option)``;

LearnInSelect.displayName = 'LearnInSelect';
export default LearnInSelect;
