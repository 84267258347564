// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/RecommendProgram.cs

import {z} from 'zod';
import {RecommendationLevelSchema} from './RecommendationLevelSchema.schema';

export const RecommendProgramSchema = z.object({
  id: z.number(),
  level: RecommendationLevelSchema,
  users: z.array(z.number()),
});
