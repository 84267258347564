import {useEffect} from 'react';
import {
  UseMoneyInputAmountEffectProps,
  UseMoneyInputFrequencyEffectProps,
  UseMoneyInputHooksProps,
  UseMoneyInputPrefixDisplayProps,
  UseMoneyInputResetFrequencyEffectProps,
  UseRecurringQuestionEffectProps,
} from './MoneyInput.interfaces';
import {MONEY_DEFAULT, formatCurrency} from '@utils/moneyUtils';
import {FormInstance} from 'antd';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {PreApprovalQuestionFunctionalityType} from '@generated/enums';
import {isNil} from 'lodash';

export const useRecurringInitially = (response: string) =>
  !isNil(response) && response !== '1';

export const useMonthsInitially = (response: string) =>
  !isNil(response) ? Number(response) : 1;

export const useRecurringDurationVisibility = (
  response: string,
  isSubscription: boolean
) => isSubscription && response && parseInt(response) > 1;

export const useMoneyInputPrefixDisplay = ({
  isRequestWithCurrencyOn,
  currency,
  userCurrency,
  programCurrency,
}: UseMoneyInputPrefixDisplayProps) =>
  isRequestWithCurrencyOn
    ? currency
    : programCurrency !== null
    ? programCurrency
    : userCurrency;

export const useRecurringQuestionEffect = ({
  isRecurring,
  item,
}: UseRecurringQuestionEffectProps) => {
  useEffect(() => {
    item['functionalityType'] = isRecurring
      ? PreApprovalQuestionFunctionalityType.ApprovalRequestFrequency
      : PreApprovalQuestionFunctionalityType.NotFeatureQuestion;
  }, [isRecurring, item]);
};

export const useMoneyInputTotalEffect = ({
  onTotalChange,
  form,
  item,
  months,
  total,
}: {
  onTotalChange: (total: number) => void;
  form: FormInstance;
  item: ResourceOrPlanItemPreApprovalQuestion;
  months: number;
  total: number;
}) => {
  useEffect(() => {
    const effectiveMonths = months ?? 1;
    onTotalChange?.(form.getFieldValue(String(item.id)) * effectiveMonths);
    if (total) form.validateFields([String(item.id)]);
  }, [form, item.id, months, onTotalChange, total]);
};

export const useMoneyInputResetFrequencyEffect = ({
  setMonths,
  form,
  isRecurring,
  frequencyDropdownId,
  months,
}: UseMoneyInputResetFrequencyEffectProps) => {
  useEffect(() => {
    if (!isRecurring || !months) {
      setMonths(1);
      form.setFieldsValue({[frequencyDropdownId]: 1});
    }
  }, [form, frequencyDropdownId, isRecurring, months, setMonths]);
};

export const useMoneyInputAmountEffect = ({
  setExchangeRate,
  setCostInUsersCurrency,
  setShowOnlyDefaultAmount,
  programCost,
  currency,
  programCurrency,
  exchangeRate,
  exchangeQueryData,
}: UseMoneyInputAmountEffectProps) => {
  useEffect(() => {
    const rate =
      exchangeQueryData?.find((x) => x.toCurrencyCode === currency)
        ?.exchangeRate || MONEY_DEFAULT.exchangeRate;
    const cost = formatCurrency(programCost, currency, exchangeRate);
    setExchangeRate(rate);
    setCostInUsersCurrency(cost);
    setShowOnlyDefaultAmount(currency === programCurrency);
  }, [
    programCost,
    currency,
    programCurrency,
    exchangeRate,
    exchangeQueryData,
    setExchangeRate,
    setCostInUsersCurrency,
    setShowOnlyDefaultAmount,
  ]);
};

export const useMoneyInputFrequencyEffect = ({
  setMonths,
  isSubscription,
  frequencyDropdown,
  form,
}: UseMoneyInputFrequencyEffectProps) => {
  useEffect(() => {
    if (isSubscription && frequencyDropdown?.response) {
      setMonths(Number(frequencyDropdown?.response));
      form.setFieldsValue({
        [frequencyDropdown?.id]: Number(frequencyDropdown?.response),
      });
    }
  }, [
    form,
    frequencyDropdown?.id,
    frequencyDropdown?.response,
    isSubscription,
    setMonths,
  ]);
};

export const useMoneyInputHooks = ({
  currency,
  exchangeQueryData,
  exchangeRate,
  form,
  frequencyDropdown,
  isRecurring,
  isSubscription,
  item,
  months,
  onTotalChange,
  programCost,
  programCurrency,
  setCostInUsersCurrency,
  setExchangeRate,
  setMonths,
  setShowOnlyDefaultAmount,
  total,
}: UseMoneyInputHooksProps) => {
  useRecurringQuestionEffect({
    isRecurring,
    item,
  });

  useMoneyInputTotalEffect({
    onTotalChange,
    form,
    item,
    months,
    total,
  });

  useMoneyInputResetFrequencyEffect({
    setMonths,
    form,
    isRecurring,
    frequencyDropdownId: frequencyDropdown?.id,
    months,
  });

  useMoneyInputAmountEffect({
    setExchangeRate,
    setCostInUsersCurrency,
    setShowOnlyDefaultAmount,
    programCost,
    currency,
    programCurrency,
    exchangeRate,
    exchangeQueryData,
  });

  useMoneyInputFrequencyEffect({
    setMonths,
    form,
    frequencyDropdown,
    isSubscription,
  });
};
