// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/PreApprovalQuestionVM.cs

import {z} from 'zod';
import {PreApprovalQuestionFunctionalityTypeSchema} from './PreApprovalQuestionFunctionalityTypeSchema.schema';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';
import {PreApprovalQuestionInputTypeSchema} from './PreApprovalQuestionInputTypeSchema.schema';
import {PreApprovalQuestionResponseOptionVMSchema} from './PreApprovalQuestionResponseOptionVMSchema.schema';
import {PreApprovalQuestionResponseSourceSchema} from './PreApprovalQuestionResponseSourceSchema.schema';

export const PreApprovalQuestionVMSchema = z.object({
  functionalityType: PreApprovalQuestionFunctionalityTypeSchema,
  helperText: z.string(),
  id: z.number(),
  images: z.array(AttachmentVMSchema),
  inputType: PreApprovalQuestionInputTypeSchema,
  isConditionalOnAnswerId: z.number().nullable().optional(),
  localizationId: z.string(),
  options: z.array(PreApprovalQuestionResponseOptionVMSchema),
  required: z.boolean(),
  response: z.string(),
  responseType: PreApprovalQuestionResponseSourceSchema,
  value: z.string(),
});
