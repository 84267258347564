import {
  EmployeeEngagementVM,
  FinanceApprovedVsSpentVM,
  PopularProgramVM,
  ProgressUpdatesBySummaryVM,
} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {EmployeeEngagementVMSchema} from '../zodSchemas/EmployeeEngagementVMSchema.schema';
import {FinanceApprovedVsSpentVMSchema} from '../zodSchemas/FinanceApprovedVsSpentVMSchema.schema';
import {ProgressUpdatesBySummaryVMSchema} from '../zodSchemas/ProgressUpdatesBySummaryVMSchema.schema';
import {PopularProgramVMSchema} from '../zodSchemas/PopularProgramVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Reports/InsightsController.cs

/*
|--------------------------------------------------------------------------
|  get-employee-engagement 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useEmployeeEngagementQuery} from '@generated/hooks';
 *
 *  const {
 *    data: employeeEngagement,
 *    isLoading: isEmployeeEngagementLoading,
 *    invalidateExact: invalidateEmployeeEngagement,
 *  } = useEmployeeEngagementQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useEmployeeEngagementQuery = queryHookFactory<
  null,
  EmployeeEngagementVM[] // return type
>('useEmployeeEngagementQuery', () => `insights/get-employee-engagement`, {
  responseSchema: z.array(EmployeeEngagementVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-finance-approved-vs-spent 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useFinanceApprovedVsSpentQuery} from '@generated/hooks';
 *
 *  const {
 *    data: financeApprovedVsSpent,
 *    isLoading: isFinanceApprovedVsSpentLoading,
 *    invalidateExact: invalidateFinanceApprovedVsSpent,
 *  } = useFinanceApprovedVsSpentQuery(null)
 *
 */

export const useFinanceApprovedVsSpentQuery = queryHookFactory<
  null,
  FinanceApprovedVsSpentVM[] // return type
>(
  'useFinanceApprovedVsSpentQuery',
  () => `insights/get-finance-approved-vs-spent`,
  {responseSchema: z.array(FinanceApprovedVsSpentVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-popular-programs 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePopularProgramsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: popularPrograms,
 *    isLoading: isPopularProgramsLoading,
 *    invalidateExact: invalidatePopularPrograms,
 *  } = usePopularProgramsQuery(null)
 *
 */

export const usePopularProgramsQuery = queryHookFactory<
  null,
  PopularProgramVM[] // return type
>('usePopularProgramsQuery', () => `insights/get-popular-programs`, {
  responseSchema: z.array(PopularProgramVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-progress-updates-by-summary 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgressUpdatesBySummaryQuery} from '@generated/hooks';
 *
 *  const {
 *    data: progressUpdatesBySummary,
 *    isLoading: isProgressUpdatesBySummaryLoading,
 *    invalidateExact: invalidateProgressUpdatesBySummary,
 *  } = useProgressUpdatesBySummaryQuery(null)
 *
 */

export const useProgressUpdatesBySummaryQuery = queryHookFactory<
  null,
  ProgressUpdatesBySummaryVM[] // return type
>(
  'useProgressUpdatesBySummaryQuery',
  () => `insights/get-progress-updates-by-summary`,
  {responseSchema: z.array(ProgressUpdatesBySummaryVMSchema)}
);
