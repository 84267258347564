// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserItemStatusVM.cs

import {z} from 'zod';
import {DisplayStatusSchema} from './DisplayStatusSchema.schema';
import {DisplayStatusWarningLevelSchema} from './DisplayStatusWarningLevelSchema.schema';

export const UserItemStatusVMSchema = z.object({
  completedOn: z.string().nullable().optional(),
  completedPercent: z.number().nullable().optional(),
  displayStatus: DisplayStatusSchema,
  displayStatusWarningLevel: DisplayStatusWarningLevelSchema,
  isDone: z.boolean(),
  skippedOn: z.string().nullable().optional(),
});
