// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/IncentivePayload.cs

import {z} from 'zod';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {IncentiveAvailabilitySchema} from './IncentiveAvailabilitySchema.schema';
import {AttachmentPayloadSchema} from './AttachmentPayloadSchema.schema';

export const IncentivePayloadSchema = z.object({
  approvalConfig: ApprovalConfigSchema,
  approvalGuidelines: z.string(),
  availability: IncentiveAvailabilitySchema,
  details: z.string(),
  featured: z.boolean(),
  id: z.number().nullable().optional(),
  images: z.array(AttachmentPayloadSchema),
  learningBudget: z.boolean(),
  longDescription: z.string(),
  programTypeIds: z.array(z.number()),
  shortDescription: z.string(),
  subTitle: z.string(),
  title: z.string(),
});
