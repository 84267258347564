// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ExchangeRatePayload.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

export const ExchangeRatePayloadSchema = z.object({
  currencyCode: CurrencyCodeSchema,
  exchangeRate: z.number(),
  id: z.number(),
});
