// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademySingleUserPayload.cs

import {z} from 'zod';
import {IAcademyUserBasicInfoSchema} from './IAcademyUserBasicInfoSchema.schema';
import {AcademyUserStatusSchema} from './AcademyUserStatusSchema.schema';

const schema = z.object({
  academyId: z.number(),
  status: AcademyUserStatusSchema,
  userCompanyId: z.number(),
});

export const AcademySingleUserPayloadSchema =
  IAcademyUserBasicInfoSchema.merge(schema);
