import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import AppHeaderLogo from './AppHeaderLogo';
import AppNavMenu from './AppNavMenu.container';
import UserMenu from './../UserMenuDropdown.container';
import {Breakpoints} from '@utils/responsiveUtils';
import {NoScrollBar} from '@blocks/NoScrollBar';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.header`
  position: fixed;
  left: 0;
  width: 272px;
  bottom: 0;
  top: 0;
  background-color: ${COLORS.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e5e5e5;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;

const UserMenuContainer = styled.div`
  padding: 36px 16px;
`;

const A11ySkipButton = styled.button`
  border: none;
  cursor: pointer;
  height: 1px;
  left: -9999px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 0.15s, top 0.3s;
  top: auto;
  width: 1px;
  z-index: -9999;

  &:focus,
  &:active {
    background-color: ${COLORS.Neutral200};
    border: none;
    border-radius: 0 0 10px;
    box-shadow: 0 0 12px ${COLORS.BoxShadow1};
    color: ${COLORS.Neutral900};
    font-size: 1rem;
    height: 40px;
    line-height: 2.5rem;
    left: auto;
    margin: 0;
    opacity: 1;
    outline: ${COLORS.Neutral900} solid 1px;
    overflow: auto;
    padding: 0 20px;
    text-align: center;
    top: auto;
    width: auto;
    z-index: 9999;
  }
}
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

function DesktopSideNav() {
  return (
    <Container>
      <A11ySkipButton
        onClick={() => {
          document.getElementById('main-content')?.focus();
        }}>
        {i18n.t(k.A11Y__SKIP)}
      </A11ySkipButton>
      <AppHeaderLogo />
      <NoScrollBar style={{flexGrow: 1}}>
        <AppNavMenu />
      </NoScrollBar>
      <UserMenuContainer>
        <UserMenu />
      </UserMenuContainer>
    </Container>
  );
}

export default DesktopSideNav;
