// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanTemplate.cs

import {z} from 'zod';
import {OptionCountsSchema} from './OptionCountsSchema.schema';
import {UserPlanVMSchema} from './UserPlanVMSchema.schema';

export const UserPlanTemplateSchema = z.object({
  totalCounts: OptionCountsSchema,
  userPlan: UserPlanVMSchema,
});
