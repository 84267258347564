// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyProgramStepPayload.cs

import {z} from 'zod';
import {AcademyStepTypeSchema} from './AcademyStepTypeSchema.schema';

export const AcademyProgramStepPayloadSchema = z.object({
  academyLevelId: z.number(),
  newSteps: z.array(z.number()),
  type: AcademyStepTypeSchema,
});
