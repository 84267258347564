// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/IncentiveAvailability.cs

import {z} from 'zod';

export const IncentiveAvailabilitySchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
