import {useCallback, useEffect, useState} from 'react';
import PreApprovalFormItem from './PreApprovalFormItem';
import {ProgramDetailsVM} from '@models/serverModels';
import {UserDetailsVM} from '@generated/interfaces';
import {
  ApprovalStatus,
  CurrencyCode,
  LearningResourceType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import {
  exchangeAmount,
  exchangeAmountBack,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {FormInstance} from 'antd';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  isQuestionACurrencyField,
  useGetCostFieldValidation,
} from '@utils/preApprovalRequest';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  useGetCurrencyField,
  useIsSubscription,
} from './PreApprovalFormItem.hooks';

export const getInitialProgramCostValue = ({
  isProgram,
  cost,
  programCostFeesMultiplier,
  item,
  isApplicationRejected,
  skipMultiplierFlag,
  exchangeRate,
}: {
  isProgram: boolean;
  cost: number;
  programCostFeesMultiplier: number;
  item: ResourceOrPlanItemPreApprovalQuestion;
  isApplicationRejected: boolean;
  skipMultiplierFlag: boolean;
  exchangeRate: number;
}) => {
  let defaultValue = 0;

  const effectiveMultiplier = skipMultiplierFlag
    ? 1
    : programCostFeesMultiplier;

  if (!!cost && isProgram) {
    defaultValue = Math.round(Number(cost) * effectiveMultiplier);
  }

  if (skipMultiplierFlag && isApplicationRejected) {
    const responseStr: string = item?.response;
    if (!!responseStr) {
      const parsedValue = parseFloat(responseStr);
      defaultValue = exchangeAmount(parsedValue, exchangeRate);
    }
  }

  return defaultValue;
};

export interface PreApprovalFormItemProps {
  deleteFunction?: (idx) => void;
  filesProgress?: any[];
  filesToUpload?: any[];
  focusField?: boolean;
  form: FormInstance;
  formQuestions: ResourceOrPlanItemPreApprovalQuestion[];
  frequencyDropdown?: ResourceOrPlanItemPreApprovalQuestion;
  isApplicationRejected?: boolean;
  isCostField: boolean;
  isFormDisabled?: boolean;
  isFormIsEditable?: boolean;
  isForPlanItemOrResourcePreapproval?: boolean;
  isProgram?: boolean;
  isResourceRequest?: boolean;
  isTextAreaDisabled?: boolean;
  isUserRequest?: boolean;
  item: ResourceOrPlanItemPreApprovalQuestion;
  maxLength?: number;
  onChangeCheckbox?: (id, selectedOptions: string[]) => void;
  onChangeSelect?: (selectedOption: string) => void;
  onTotalChange?: (total: number) => void;
  preApprovalApplicationStatus?: ApprovalStatus;
  programCost?: number | null;
  programCostFeesMultiplier: number;
  programCurrency: CurrencyCode;
  programDetails?: ProgramDetailsVM | null;
  rangeDates?: {startDate: any; endDate: any};
  resourceType?: LearningResourceType;
  selectedCheckboxOptions?: {[key: number]: string[]};
  setCurrencySelected?: (currency: CurrencyCode) => void;
  setFilesToUpload?: (files: any[]) => void;
  setInitialCostEstimate?: boolean;
  setRangeDates?: (rangeDates) => void;
  showCount?: boolean;
  subscriptionCost?: number | null;
  total?: number;
  user?: UserDetailsVM;
}

export default function PreApprovalFormItemContainer({
  deleteFunction,
  filesProgress,
  filesToUpload,
  focusField,
  form,
  formQuestions,
  frequencyDropdown,
  isApplicationRejected,
  isCostField,
  isFormDisabled = false,
  isForPlanItemOrResourcePreapproval,
  isProgram,
  isResourceRequest,
  isTextAreaDisabled = false,
  isUserRequest,
  item,
  maxLength = 5000,
  onChangeCheckbox,
  onChangeSelect,
  onTotalChange,
  programCost,
  programCostFeesMultiplier,
  programCurrency,
  rangeDates,
  resourceType,
  selectedCheckboxOptions,
  setCurrencySelected,
  setFilesToUpload,
  setInitialCostEstimate,
  setRangeDates,
  showCount,
  subscriptionCost,
  total,
}: PreApprovalFormItemProps) {
  const [costFormattedInUsersCurrency, setCostFormattedInUsersCurrency] =
    useState('');
  const [costFormattedInProgramCurrency, setCostFormattedInProgramCurrency] =
    useState('');
  const [defaultToProgramCurrency, setDefaultToProgramCurrency] =
    useState(false);

  const {data: user} = useGetUserQuery();
  const {isFeatureFlagOn} = useFeatureFlags();
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;
  const isHelperTextOn = isFeatureFlagOn.HelperText;
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRateFromDefault = useExchangeRate(
    MONEY_DEFAULT.currency,
    user?.currency
  );
  const isHideSubtextForResubmissionsOn =
    isFeatureFlagOn.HideSubtextForResubmissions;

  const resourceCost =
    item?.inputType === PreApprovalQuestionInputType.Money
      ? item?.response
      : null;

  const currencyQuestion = useGetCurrencyField(formQuestions);
  const effectiveCurrency = !!currencyQuestion?.response
    ? (Number(currencyQuestion?.response) as CurrencyCode)
    : programCurrency;

  const exchangeRateForUser = useExchangeRate(
    effectiveCurrency,
    user?.currency
  );
  const fromUsdRate = exchangeAmountBack(
    1,
    useExchangeRate(effectiveCurrency, MONEY_DEFAULT.currency)
  );
  const exchangeRate = isApplicationRejected
    ? fromUsdRate
    : exchangeRateForUser;

  const cost = isProgram ? programCost : resourceCost;

  const subscriptionMonths = frequencyDropdown && frequencyDropdown?.response;

  const calculatePrefill = useCallback(() => {
    const isProratingEnabled = Number(
      subscriptionMonths
        ? Number(cost) / Number(subscriptionMonths)
        : item?.response ?? cost
    );
    return (
      Math.round(
        (isApplicationRejected
          ? isProratingEnabled * exchangeRate
          : isProratingEnabled) * 100
      ) / 100
    );
  }, [
    subscriptionMonths,
    cost,
    item?.response,
    isApplicationRejected,
    exchangeRate,
  ]);

  const [calculatedPrefillAmount, setCalculatedPrefillAmount] = useState(
    calculatePrefill()
  );

  useEffect(() => {
    const result = calculatePrefill();
    setCalculatedPrefillAmount(result);
  }, [subscriptionMonths, cost, calculatePrefill]);

  const [prefilledCost, setPrefilledCost] = useState(
    getInitialProgramCostValue({
      isProgram,
      cost: exchangeAmount(calculatedPrefillAmount, exchangeRate),
      programCostFeesMultiplier,
      isApplicationRejected,
      skipMultiplierFlag:
        isHideSubtextForResubmissionsOn && isApplicationRejected,
      item,
      exchangeRate,
    })
  );

  useEffect(() => {
    const defaultToProgramCurrency = programCurrency === user?.currency;
    setDefaultToProgramCurrency(defaultToProgramCurrency);

    if (cost) {
      setPrefilledCost(
        getInitialProgramCostValue({
          isProgram,
          cost: exchangeAmount(calculatedPrefillAmount, exchangeRate),
          programCostFeesMultiplier,
          isApplicationRejected,
          skipMultiplierFlag:
            isHideSubtextForResubmissionsOn && isApplicationRejected,
          item,
          exchangeRate,
        })
      );
    }

    setCostFormattedInUsersCurrency(
      formatCurrency(programCost, user?.currency, exchangeRate, {
        currencyDisplay: user?.currency !== programCurrency ? 'symbol' : null,
      })
    );

    setCostFormattedInProgramCurrency(
      formatCurrency(programCost, programCurrency, MONEY_DEFAULT.exchangeRate, {
        currencyDisplay: isRequestWithCurrencyOn
          ? 'symbol'
          : user?.currency !== programCurrency
          ? 'symbol'
          : null,
      })
    );
  }, [exchangeRate]);

  const costFieldValidation = useGetCostFieldValidation({
    required: item.required,
    helperText: item.helperText,
    localizationId: item.localizationId,
    isHelperTextOn,
    isForPlanItemOrResourcePreapproval:
      isForPlanItemOrResourcePreapproval || isUserRequest,
    exchangeRate: exchangeRateFromDefault,
    usersCurrency: user?.currency,
    total,
    enabled: isCostField,
    form,
    isRequestWithCurrencyOn,
    getCurrencyExchangeQuery,
  });

  const inputInitialCostEstimate = () => {
    if (calculatedPrefillAmount && setInitialCostEstimate) {
      setTimeout(() => form.setFieldsValue({[item.id]: prefilledCost}));
    }
  };

  const showAmountSubtext =
    isHelperTextOn ||
    !(isHideSubtextForResubmissionsOn && isApplicationRejected);

  useEffect(inputInitialCostEstimate, [
    form,
    item.id,
    calculatedPrefillAmount,
    setInitialCostEstimate,
  ]);

  const isSubscription = useIsSubscription(
    resourceType,
    subscriptionCost,
    isFeatureFlagOn.Subscriptions
  );

  useEffect(() => {
    if (focusField) {
      setTimeout(() => {
        // It'd be best to use a ref here, but it's currently complicated with all the variations of input components
        try {
          document.getElementById(String(item?.id))?.focus();
        } catch (e) {
          /* Non-essential, so no need to do anything */
        }
      }, 300);
    }
  }, [focusField, item?.id]);

  // Currency fields are dependents and render inside <MoneyInput /> as a pair
  if (isQuestionACurrencyField(item)) return;

  return (
    <PreApprovalFormItem
      frequencyDropdown={frequencyDropdown}
      costFieldValidation={costFieldValidation}
      costFormattedInProgramCurrency={costFormattedInProgramCurrency}
      costFormattedInUsersCurrency={costFormattedInUsersCurrency}
      defaultToProgramCurrency={defaultToProgramCurrency}
      filesToUpload={filesToUpload}
      filesProgress={filesProgress}
      form={form}
      formQuestions={formQuestions}
      isCostField={isCostField}
      isFormDisabled={isFormDisabled}
      isHelperTextOn={isHelperTextOn}
      isProgram={isProgram}
      isResourceRequest={isResourceRequest}
      isTextAreaDisabled={isTextAreaDisabled}
      isUserRequest={isUserRequest}
      item={item}
      maxLength={maxLength}
      showAmountSubtext={showAmountSubtext}
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelect={onChangeSelect}
      onTotalChange={onTotalChange}
      prefilledCost={prefilledCost}
      programCost={programCost}
      subscriptionCost={subscriptionCost}
      resourceType={resourceType}
      selectedCheckboxOptions={selectedCheckboxOptions}
      setFilesToUpload={setFilesToUpload}
      showCount={showCount}
      user={user}
      total={total}
      programCurrency={programCurrency}
      rangeDates={rangeDates}
      setRangeDates={setRangeDates}
      isSubscription={isSubscription}
      currencyQuestion={currencyQuestion}
      deleteFunction={deleteFunction}
      setCurrencySelected={setCurrencySelected}
    />
  );
}
