// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/ItemDetails/ProgramDetailsVM.cs

import {z} from 'zod';
import {DetailsVMSchema} from './DetailsVMSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

const schema = z.object({
  actualLearningTime: z.string().nullable().optional(),
  applyUrl: z.string().nullable().optional(),
  cost: z.number(),
  currency: CurrencyCodeSchema,
  duration: z.string(),
  isLicenseProgram: z.boolean(),
  licenseCost: z.number().nullable().optional(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseId: z.number().nullable().optional(),
  monthlySubscriptionCost: z.number().nullable().optional(),
  presenceType: z.string(),
  programType: z.string().nullable().optional(),
  requestRemoveConfirmation: z.boolean(),
  skills: z.array(z.string()),
  status: z.string(),
  timeCommitment: z.string(),
  timeCommitmentType: z.string(),
});

export const ProgramDetailsVMSchema = DetailsVMSchema.merge(schema);
