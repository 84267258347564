import {PlusCircleOutlined} from '@ant-design/icons';
import {RequestMenuButtonAndDrawerButton} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {RequestMenuButtonProps} from './RequestMenuButton.interfaces';
import {REQUEST_MENU_BUTTON_TEST_ID} from './RequestMenuButton.constants';

function RequestMenuButton({
  drawerVisible,
  title,
  setDrawerVisible,
}: RequestMenuButtonProps) {
  return (
    <RequestMenuButtonAndDrawerButton
      data-testid={REQUEST_MENU_BUTTON_TEST_ID}
      icon={<PlusCircleOutlined aria-hidden="true" />}
      iconSize="M"
      onClick={() => setDrawerVisible(!drawerVisible)}
      tag={ButtonTags.Secondary}>
      {title}
    </RequestMenuButtonAndDrawerButton>
  );
}

export default RequestMenuButton;
