// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/UserEventVM.cs

import {z} from 'zod';
import {RecurringDetailSchema} from './RecurringDetailSchema.schema';

export const UserEventVMSchema = z.object({
  approvalStatus: z.string(),
  color: z.string(),
  endDate: z.string(),
  endTime: z.string(),
  externalId: z.string(),
  id: z.number(),
  isEditable: z.boolean(),
  notes: z.string(),
  recurring: z.boolean(),
  recurringDetail: RecurringDetailSchema,
  startDate: z.string(),
  startTime: z.string(),
  title: z.string(),
});
