import styled from 'styled-components';

import ReactQuill from 'react-quill';

const BorderLessReactQuill = styled(ReactQuill)`
  .ql-container,
  .ql-container.ql-snow {
    border: none;
    font-size: inherit;

    .ql-editor {
      padding: 0px;
      ${({maxHeight}) => maxHeight && `max-height: ${maxHeight}px;`}
      ${({maxHeight}) => maxHeight && `overflow: auto `}
    }
  }
`;

export interface Props {
  html?: string;
  placeholder?: string;
  maxHeight?: number;
}

const QuillHtmlViewer = ({html, placeholder, maxHeight}: Props) => {
  if (!html && !placeholder) {
    return null;
  }

  return !!html ? (
    <BorderLessReactQuill
      theme="snow"
      readOnly={true}
      maxHeight={maxHeight}
      preserveWhitespace={true}
      modules={{toolbar: false}}
      value={html || placeholder}
    />
  ) : (
    <p>{placeholder}</p>
  );
};

export default QuillHtmlViewer;
