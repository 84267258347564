import * as React from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {DeleteOutlined} from '@ant-design/icons';
import {b64toBlob} from '@utils/image-utils';
import UploadPreviewLogo from '@blocks/UploadPreviewLogo';
import {Progress} from 'antd';

const FileRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface UploadListProps {
  filesToUpload: any[];
  setFilesToUpload: React.Dispatch<React.SetStateAction<any[]>>;
  filesProgress?: any[];
  deleteFunction?: (idx) => void;
}

export function UploadList({
  setFilesToUpload,
  filesToUpload,
  filesProgress,
  deleteFunction,
}: UploadListProps) {
  const generatePreviewUrl = (file) => {
    const url = URL.createObjectURL(b64toBlob(file.fileContent));
    return url;
  };
  return (
    <div>
      {!!filesToUpload && (
        <div>
          {filesToUpload?.map((file, idx) => {
            return (
              <>
                <br />
                {file.fileContent ? (
                  <FileRow>
                    <UploadPreviewLogo
                      imageSrc={generatePreviewUrl(file)}
                      size={75}
                    />
                    <div>{file.name}</div>
                    <div>
                      <DeleteOutlined
                        style={{
                          cursor: 'pointer',
                          color: COLORS.Red800,
                          fontSize: '1.4em',
                        }}
                        onClick={() => {
                          deleteFunction?.(idx);
                          setFilesToUpload(
                            filesToUpload.filter(
                              (item) => item !== filesToUpload[idx]
                            )
                          );
                        }}
                      />
                    </div>
                  </FileRow>
                ) : (
                  <FileRow>
                    <div style={{width: '30%'}}>{file.name}</div>
                    {filesProgress[idx] === 100 ? (
                      <div>
                        <DeleteOutlined
                          style={{
                            cursor: 'pointer',
                            color: COLORS.Red800,
                            fontSize: '1.4em',
                          }}
                          onClick={() => {
                            deleteFunction?.(idx);
                            setFilesToUpload(
                              filesToUpload.filter(
                                (item) => item !== filesToUpload[idx]
                              )
                            );
                          }}
                        />
                      </div>
                    ) : filesProgress[idx] > 0 ? (
                      <Progress percent={filesProgress[idx]} />
                    ) : (
                      <div>
                        <DeleteOutlined
                          style={{
                            cursor: 'pointer',
                            color: COLORS.Red800,
                            fontSize: '1.4em',
                          }}
                          onClick={() => {
                            deleteFunction?.(idx);
                            setFilesToUpload(
                              filesToUpload.filter(
                                (item) => item !== filesToUpload[idx]
                              )
                            );
                          }}
                        />
                      </div>
                    )}
                  </FileRow>
                )}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}
