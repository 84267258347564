import styled from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import {COLORS} from '@utils/constants';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import GlassesBg from '@assets/glasses-bg.png';
import {BodyParagraph, H3} from '@components/reusable/Typography';

const spaceForSide = '200px';
const spaceForTop = '135px';

export const StyledLearnInModal = styled(LearnInModal)`
  .ant-modal-content {
    padding-left: ${spaceForSide};
    background: url('${GlassesBg}') 0 0 / ${spaceForSide} repeat-y,
      ${COLORS.White};
    @media (max-width: ${Breakpoints.Mobile}px) {
      background: ${COLORS.White};
      padding-left: 0;
      padding-top: ${spaceForTop};
      &::before {
        height: ${spaceForTop};
        background: url('${GlassesBg}') 0 0 / ${spaceForSide} repeat-x,
          ${COLORS.White};
        background-position-y: 5%;
        border-radius: 0;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }
`;

export const StyledModalContainer = styled.div`
  padding: 16px 24px 24px;
`;

export const StyledH3 = styled(H3)`
  line-height: 1.5rem !important;
  margin: 0 !important;
  padding: 0 0 2rem !important;
`;

export const StyledDescription = styled(BodyParagraph)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 1.5rem !important;
`;

export const StyledExternalLink = styled.a`
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue800};
  }
`;

export const StyledCheckboxContainer = styled.div`
  background-color: ${COLORS.Neutral50};
  border: solid 1px ${COLORS.Neutral200};
  border-radius: 8px;
  font-size: 0.75rem !important;
  line-height: 1.125rem;
  padding: 12px 14px;

  .ant-checkbox + span {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
