// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/UserRequestApprovalVM.cs

import {z} from 'zod';
import {PreApprovalApplicationVMSchema} from './PreApprovalApplicationVMSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {ApprovalRankTypeSchema} from './ApprovalRankTypeSchema.schema';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';
import {ApprovalTypeSchema} from './ApprovalTypeSchema.schema';
import {UserRequestTypeSchema} from './UserRequestTypeSchema.schema';

export const UserRequestApprovalVMSchema = z.object({
  amount: z.number(),
  amountOverLimit: z.number().nullable().optional(),
  amountType: z.string(),
  applicationDetails: PreApprovalApplicationVMSchema,
  approvalExpirationDate: z.string().nullable().optional(),
  approvalGuidelines: z.string().nullable().optional(),
  balance: z.number().nullable().optional(),
  costCenter: z.string(),
  employeeReasonForApplication: z.string(),
  id: z.number(),
  itemId: z.number(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseId: z.number().nullable().optional(),
  manager: z.string(),
  previousNotes: z.string(),
  previousRejectionNotes: z.string(),
  provider: z.string(),
  rank: ApprovalRankTypeSchema,
  requestedBy: z.string(),
  requestedById: z.number(),
  requestedDate: z.string(),
  status: ApprovalStatusSchema,
  title: z.string(),
  type: ApprovalTypeSchema,
  userCurrencyAtRequest: CurrencyCodeSchema,
  userRequestType: UserRequestTypeSchema,
});
