// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/FinanceApprovedVsSpentVM.cs

import {z} from 'zod';

export const FinanceApprovedVsSpentVMSchema = z.object({
  color: z.string(),
  month: z.number(),
  name: z.string(),
  type: z.string(),
  value: z.number(),
});
