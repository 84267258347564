// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPlanProjectSubmission.cs

import {z} from 'zod';
import {CustomizableLearningCategorySchema} from './CustomizableLearningCategorySchema.schema';
import {LinkPayloadSchema} from './LinkPayloadSchema.schema';

export const UserPlanProjectSubmissionSchema = z.object({
  contentId: z.number(),
  customizableLearningCategory: CustomizableLearningCategorySchema,
  links: z.array(LinkPayloadSchema).nullable().optional(),
  response: z.string().nullable().optional(),
});
