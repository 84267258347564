// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserRecommendationPayload.cs

import {z} from 'zod';
import {RecommendationLevelSchema} from './RecommendationLevelSchema.schema';
import {RecommendationItemTypeSchema} from './RecommendationItemTypeSchema.schema';

export const UserRecommendationPayloadSchema = z.object({
  financeIncentiveId: z.number().nullable().optional(),
  level: RecommendationLevelSchema,
  message: z.string(),
  programId: z.number().nullable().optional(),
  timeIncentiveId: z.number().nullable().optional(),
  type: RecommendationItemTypeSchema,
  userCompanyId: z.number(),
});
