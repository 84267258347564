// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ContentFeedbackVM.cs

import {z} from 'zod';

export const ContentFeedbackVMSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  id: z.number(),
  message: z.string(),
});
