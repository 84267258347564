// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeOverviewVM.cs

import {z} from 'zod';

export const TeamInsightEmployeeOverviewVMSchema = z.object({
  approvedAmount: z.number(),
  awaitingApproval: z.number(),
  balance: z.number(),
  email: z.string().nullable().optional(),
  employeeName: z.string(),
  isEligibleForPersonalLearningBudget: z.boolean(),
  lastActiveIn: z.string(),
  lastLoginUtc: z.string().nullable().optional(),
  pendingReimbursedAmount: z.number(),
  plbAmount: z.number(),
  reimbursedAmount: z.number(),
  remainingAmount: z.number(),
  spentAmount: z.number(),
  userCompanyId: z.number(),
});
