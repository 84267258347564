// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Reports/EmployeeCurrentFinanceIncentiveDetailsVM.cs

import {z} from 'zod';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';

export const EmployeeCurrentFinanceIncentiveDetailsVMSchema = z.object({
  amountType: FinanceAmountTypeSchema,
  balance: z.number(),
  preApprovalRequired: z.boolean(),
  recurrenceType: BalanceRecurTypeSchema,
  spendingLimit: z.number(),
  totalApproved: z.number(),
  totalSpent: z.number(),
  userFinanceIncentiveId: z.number(),
});
