import {i18n, k} from '@i18n/translate';
import {getAvatarColorwaysStyles} from '@utils/color';
import styled from 'styled-components';
import {COLORS, SECTIONS_NAV_WIDTH} from '@utils/constants';
import {NewWindowIcon} from '@assets/new-window-icon';
import {
  LoginOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  UserOutlined,
  BulbOutlined,
} from '@ant-design/icons';
import {Avatar} from 'antd';
import ContextMenuButton from './ContextMenu';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const TargetContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const UserDisplayName = styled.div`
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
  font-weight: bold;
  font-size: 0.875rem;
`;

const AdminViewMarker = styled.div`
  background: #dbdbdb;
  padding: 4px;
  font-size: 0.625rem;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  font-weight: 700;
  width: fit-content;
`;

const ExternalLabel = styled.div`
  display: flex;
`;

const ExternalLabelText = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

const DROPDOWN_MARGIN = 16;
const DROPDOWN_WIDTH = SECTIONS_NAV_WIDTH - DROPDOWN_MARGIN * 2;
const iconStyle = {marginRight: '24px'};

export interface UserMenuDropdownProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isAdminPath: boolean;
  onClickLogin: () => void;
  onClickLogout: () => void;
  userDisplayName: string;
  userInitials: string;
  onClickSettings: () => void;
  onClickChangeApps: () => void;
  onClickKnowledgeCenter: () => void;
}

function UserMenuDropdown({
  isAuthenticated,
  isAdmin,
  isAdminPath,
  onClickLogin,
  onClickLogout,
  userDisplayName,
  userInitials,
  onClickSettings,
  onClickChangeApps,
  onClickKnowledgeCenter,
}: UserMenuDropdownProps) {
  // Target
  const Target = isAuthenticated ? (
    <TargetContainer tabIndex={0}>
      <Avatar
        style={{
          ...getAvatarColorwaysStyles(userInitials.length),
          flexShrink: 0,
          fontWeight: '700',
          marginRight: `${DROPDOWN_MARGIN}px`,
        }}
        size="large"
        gap={1}>
        {userInitials}
      </Avatar>
      <div data-cy="UserMenu">
        <UserDisplayName>{userDisplayName}</UserDisplayName>
        {isAdminPath && (
          <AdminViewMarker>
            {i18n.t(k.ADMIN__VIEW).toLocaleUpperCase()}
          </AdminViewMarker>
        )}
      </div>
    </TargetContainer>
  ) : (
    <div>
      <Avatar size="large" icon={<UserOutlined aria-hidden="true" />} />
    </div>
  );

  // Menu Items
  const menuItems = [];
  if (!isAuthenticated) {
    menuItems.push({
      label: (
        <>
          <LoginOutlined
            data-cy="loginUserMenu"
            aria-hidden="true"
            style={iconStyle}
          />
          {i18n.t(k.ACCOUNT__LOGIN)}
        </>
      ),
      onClick: onClickLogin,
      noBottomBorder: true,
    });
  } else {
    if (isAdmin) {
      menuItems.push({
        label: (
          <>
            <UserSwitchOutlined
              data-cy="userSwitchUserMenu"
              aria-hidden="true"
              style={iconStyle}
            />
            {isAdminPath ? i18n.t(k.USER) : i18n.t(k.ADMIN)}
          </>
        ),
        onClick: onClickChangeApps,
        noBottomBorder: true,
      });
    }

    menuItems.push({
      label: (
        <>
          <SettingOutlined
            data-cy="settingUserMenu"
            aria-hidden="true"
            style={iconStyle}
          />
          {i18n.t(k.SETTING__PLURAL)}
        </>
      ),
      onClick: onClickSettings,
      noBottomBorder: true,
    });

    menuItems.push({
      label: (
        <ExternalLabel>
          <BulbOutlined
            data-cy="knowledgeCenterUserMenu"
            aria-hidden="true"
            style={iconStyle}
          />
          <ExternalLabelText>
            {i18n.t(k.GENERIC__KNOWLEDGE_CENTER)}
            <NewWindowIcon
              width="1rem"
              height="1rem"
              alt={i18n.t(k.CTA__VIEW_LINK)}
            />
          </ExternalLabelText>
        </ExternalLabel>
      ),
      onClick: onClickKnowledgeCenter,
      noBottomBorder: true,
    });

    menuItems.push({
      label: (
        <>
          <LogoutOutlined
            data-cy="logoutUserMenu"
            aria-hidden="true"
            style={iconStyle}
          />
          {i18n.t(k.ACCOUNT__LOGOUT)}
        </>
      ),
      onClick: onClickLogout,
      noBottomBorder: true,
    });
  }
  return (
    <ContextMenuButton
      menuItems={menuItems}
      Target={Target}
      overlayPlacement="top"
      menuWidth={`${DROPDOWN_WIDTH}px`}
    />
  );
}

export default UserMenuDropdown;
