// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/IncentivesCountVM.cs

import {z} from 'zod';

export const IncentivesCountVMSchema = z.object({
  academiesWithCurrentUserAsAssignedApprover: z.number(),
  financeIncentivesAvailableToUsersCount: z.number(),
  financeIncentivesAvailableToUsersWithManagerApprovalCount: z.number(),
  hasPersonalLearningBudget: z.boolean(),
  programIncentivesAvailableToUsersWithManagerApprovalCount: z.number(),
  resourcesAvailableToUsersWithManagerApprovalCount: z.number(),
  timeIncentivesAvailableToUsersCount: z.number(),
  timeIncentivesAvailableToUsersWithManagerApprovalCount: z.number(),
});
