// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyLevelPayload.cs

import {z} from 'zod';
import {IAcademyLevelDetailedInfoSchema} from './IAcademyLevelDetailedInfoSchema.schema';

const schema = z.object({
  academyId: z.number(),
  description: z.string(),
  id: z.number(),
  order: z.number(),
  requiredId: z.number().nullable().optional(),
  title: z.string(),
});

export const AcademyLevelPayloadSchema =
  IAcademyLevelDetailedInfoSchema.merge(schema);
