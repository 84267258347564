import {COLORS} from '@utils/constants';
import {ButtonTags} from './ButtonEnums';
import {red} from 'material-ui-colors';

const buildBorder = (color: string, width = '1px', type = 'solid') =>
  `${width} ${type} ${color}`;

const common = {
  background: 'transparent',
  borderRadius: '100px',
  padding: '8px 16px',
  focusOutline: buildBorder(COLORS.Blue950, '2px'),
  focusOutlineOffset: '2px',
  fontWeight: 'normal',
  none: 'none',
  buttonText: {
    iconPadding: '10px 16px',
    padding: '10px 20px',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0em',
  },
};

const theme = {
  [ButtonTags.Primary]: {
    ...common.buttonText,
    color: COLORS.White,
    background: COLORS.Blue800,
    borderRadius: common.borderRadius,
    border: buildBorder(COLORS.Blue800),
    focusOutline: buildBorder(COLORS.Blue950, '2px'),
    focusOutlineOffset: common.focusOutlineOffset,
    hoverBackground: COLORS.Blue700,
    hoverBorder: buildBorder(COLORS.Blue700),
    activeBackground: COLORS.Blue950,
    activeBorderColor: buildBorder(COLORS.Blue950),
    disabledColor: COLORS.Neutral900,
    disabledBackground: COLORS.Neutral200,
    disabledBorderColor: buildBorder(COLORS.Neutral200),
  },
  [ButtonTags.Secondary]: {
    ...common.buttonText,
    activeBorderColor: buildBorder(COLORS.Blue950),
    activeColor: COLORS.Blue950,
    background: COLORS.Transparent,
    border: buildBorder(COLORS.Blue800),
    borderRadius: common.borderRadius,
    color: COLORS.Blue800,
    disabledBackground: COLORS.Neutral200,
    disabledBorderColor: buildBorder(COLORS.Neutral900),
    disabledColor: COLORS.Neutral900,
    focusOutline: buildBorder(COLORS.Blue950, '2px'),
    focusOutlineOffset: common.focusOutlineOffset,
    hoverBackground: COLORS.Blue50,
    hoverBorder: buildBorder(COLORS.Blue800),
    hoverColor: COLORS.Blue950,
  },
  [ButtonTags.Destructive]: {
    ...common.buttonText,
    color: COLORS.White,
    background: red[600],
    borderRadius: common.borderRadius,
    border: buildBorder(red[600]),
    focusOutline: buildBorder(red[600], '2px'),
    focusOutlineOffset: common.focusOutlineOffset,
    hoverBackground: red[700],
    hoverBorder: buildBorder(red[700]),
    activeBackground: red[600],
    activeBorderColor: buildBorder(red[600]),
    disabledColor: COLORS.Neutral900,
    disabledBackground: COLORS.Neutral200,
    disabledBorderColor: buildBorder(COLORS.Neutral200),
  },
  [ButtonTags.DestructiveSecondary]: {
    ...common.buttonText,
    activeBorderColor: buildBorder(red[600]),
    background: COLORS.Transparent,
    border: buildBorder(COLORS.Red800),
    borderRadius: common.borderRadius,
    color: COLORS.Red800,
    disabledBackground: COLORS.Neutral200,
    disabledBorderColor: buildBorder(COLORS.Neutral200),
    disabledColor: COLORS.Neutral900,
    focusOutline: buildBorder(red[600], '2px'),
    focusOutlineOffset: common.focusOutlineOffset,
  },
  [ButtonTags.RemovedBorder]: {
    activeBorder: common.none,
    activeColor: common.none,
    border: common.none,
    focusOutline: buildBorder(COLORS.Blue950, '2px'),
    focusOutlineOffset: common.focusOutlineOffset,
    hoverBorder: common.none,
    hoverColor: common.none,
  },
};

// button style iterations
export const ButtonStyles = {
  [ButtonTags.Primary]: {
    ...theme.primary,
  },
  [ButtonTags.Secondary]: {
    ...theme.secondary,
  },
  [ButtonTags.SecondaryRemovedBorder]: {
    ...theme.secondary,
    ...theme.removedBorder,
  },
  [ButtonTags.Footer]: {
    ...common.buttonText,
    focusOutline: common.focusOutline,
    color: COLORS.Black,
    background: COLORS.White,
    borderRadius: common.borderRadius,
    border: buildBorder(COLORS.Black),
  },
  [ButtonTags.DrawerFooterPrimary]: {
    ...theme.primary,
    padding: common.padding,
  },
  [ButtonTags.DrawerFooterSecondary]: {
    ...theme.secondary,
    padding: common.padding,
  },
  [ButtonTags.SecondaryBlack]: {
    ...theme.secondary,
    color: COLORS.Black,
    border: buildBorder(COLORS.Black),
  },
  [ButtonTags.SecondaryBlackRemovedBorder]: {
    ...theme.secondary,
    ...theme.removedBorder,
    color: COLORS.Black,
    hoverBackground: 'none',
    hoverColor: COLORS.Blue800,
  },
  [ButtonTags.Link]: {
    ...common.buttonText,
    color: COLORS.Black,
    fontWeight: common.fontWeight,
    focusOutline: common.focusOutline,
    background: common.background,
    border: common.none,
    borderRadius: common.borderRadius,
  },
  [ButtonTags.Destructive]: {
    ...theme.destructive,
  },
  [ButtonTags.DestructiveSecondary]: {
    ...theme.destructiveSecondary,
  },
  [ButtonTags.DestructiveRemovedBorder]: {
    ...theme.destructiveSecondary,
    ...theme.removedBorder,
  },
};
