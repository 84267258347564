// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Audience/AudienceVM.cs

import {z} from 'zod';
import {BusinessGoalVMSchema} from './BusinessGoalVMSchema.schema';
import {DemandSchema} from './DemandSchema.schema';

export const AudienceVMSchema = z.object({
  avgSalary: z.number(),
  businessGoals: z.array(BusinessGoalVMSchema),
  demand: DemandSchema,
  members: z.number(),
  onboardingWeeks: z.number(),
  recruitmentPercent: z.number(),
  title: z.string(),
});
