/* Turned off for resetIdleTimer which uses an empty function */
/* eslint-disable @typescript-eslint/no-empty-function */

import {createContext, useContext, ReactNode, useEffect} from 'react';
import {useAuth} from '@utils/oidc-auth-wrapper';
import useFeatureFlags from '@hooks/useFeatureFlags';

const IdleContext = createContext({resetIdleTimer: () => {}});

export const useIdle = () => useContext(IdleContext);

export const IdleProvider = ({
  children,
  timeout,
}: {
  children: ReactNode;
  timeout: number | null;
}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {logout} = useAuth();

  useEffect(() => {
    if (typeof timeout === 'number' && isFeatureFlagOn.IdleTimeLogout) {
      let logoutTimer: NodeJS.Timeout;

      const resetIdleTimer = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(() => {
          console.log('Logging out due to inactivity');
          logout();
        }, timeout);
      };

      const events = ['mousemove', 'keydown', 'scroll', 'click'];
      events.forEach((event) =>
        document.addEventListener(event, resetIdleTimer)
      );

      resetIdleTimer();

      return () => {
        clearTimeout(logoutTimer);
        events.forEach((event) =>
          document.removeEventListener(event, resetIdleTimer)
        );
      };
    }
    return () => {};
  }, [timeout, isFeatureFlagOn.IdleTimeLogout]);

  const value = {resetIdleTimer: () => {}};

  return <IdleContext.Provider value={value}>{children}</IdleContext.Provider>;
};
