// Do not modify directly
// Generated from file: learnin.core.application/DTOs/PartialContent.cs

import {z} from 'zod';
import {IOrderedItemSchema} from './IOrderedItemSchema.schema';

const schema = z.object({
  id: z.number(),
  order: z.number(),
});

export const PartialContentSchema = IOrderedItemSchema.merge(schema);
