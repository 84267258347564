// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ProgramUsageResult.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

export const ProgramUsageResultSchema = z.object({
  applicationUrl: z.string(),
  cost: z.number(),
  costCenter: z.string(),
  currency: CurrencyCodeSchema,
  employeeName: z.string(),
  isInternalProgram: z.boolean(),
  planItemId: z.number(),
  programId: z.number(),
  programLength: z.string(),
  programType: z.string(),
  requestedAmount: z.number(),
  startDate: z.string().nullable().optional(),
  status: z.string(),
  title: z.string(),
  userCompanyId: z.number(),
  userRequestId: z.number().nullable().optional(),
});
