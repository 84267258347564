// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IAcademyLevelDetailedInfo.cs

import {z} from 'zod';
import {IAcademyLevelBasicInfoSchema} from './IAcademyLevelBasicInfoSchema.schema';

const schema = z.object({
  academyId: z.number(),
});

export const IAcademyLevelDetailedInfoSchema =
  IAcademyLevelBasicInfoSchema.merge(schema);
