import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInAutocomplete from '@components/reusable/Autocomplete/Autocomplete';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {Col, Form, Row} from 'antd';
import {PreApprovalQuestion} from '@models/serverModels';
import {UserDetailsVM} from '@generated/interfaces';
import {
  LearnInInput,
  LearnInMoneyInput,
  LearnInNumberInput,
} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

import {AddNewProgram} from '@store/apiEndpoints';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {ProgramTypesVM} from '@components/admin/pages/investments/components/incentives/store/interfaces';
import {
  CurrencyCode,
  DurationUnitType,
  PlanItemType,
  PreApprovalQuestionResponseSource,
} from '@generated/enums';
import {CurrencyCodeString} from '@models/clientEnums';
import {FormInstance} from 'antd/lib/form';
import PreApprovalFormItem from '../PreApprovalFormItem/PreApprovalFormItem.container';
import {currencyOptions, REQUEST_CURRENCY_ID} from '@utils/moneyUtils';
import {COLORS} from '@utils/constants';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {localizeGeneric, localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';
import {
  handleInputErrorText,
  noUserInputAllowedHtml,
  showHelperTextIfAvailable,
  validateUrl,
} from '@utils/itemUtils';
import {CurrencySelect} from '@components/admin/pages/programs/CreateProgramDrawer';
import {useState} from 'react';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {LearnInFormItem} from '@components/reusable/Form/Form';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

//Styles for create user program drawer
//Remove the required mark from ::before and add it in ::after
const CreateUserProgramStyled = styled.div`
  width: 100%;
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::after {
    display: inline-block;
    margin-right: 4px;
    color: ${COLORS.Red800};
    font-size: 0.875rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: '';
  }
  .ant-form-item-label {
    font-weight: 600;
  }
  .duration-select {
    height: 40px;
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export enum FormFields {
  Cost = 'cost',
  Currency = 'currency',
  PresenceType = 'presenceType',
  ProgramLength = 'programLength',
  ProgramLengthUnit = 'programLengthUnit',
  ProgramTypeId = 'programTypeId',
  ShortDescription = 'shortDescription',
  TimeCommitmentType = 'timeCommitmentType',
  Title = 'title',
  Url = 'url',
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CreateUserProgramDrawerProps {
  form: FormInstance;
  formItems: PreApprovalQuestion[];
  isFormDisabled: boolean;
  isHelperTextOn: boolean;
  isTextAreaDisabled: boolean;
  isUserRequest?: boolean;
  onClose: () => void;
  onChangeProvider: (value: string) => void;
  onSubmitForm: (args: AddNewProgram & {provider?: string}) => void;
  onChangeCheckbox: (id, selectedOptions: string[]) => void;
  onChangeSelect: (selectedOption: string) => void;
  planItemType: PlanItemType;
  programTypes: ProgramTypesVM;
  providerSuggestions: {id: number; label: string; value: string}[];
  provider?: string;
  programCostFeesMultiplier: number;
  rejectedNotes: string | undefined;
  selectedCheckboxOptions: {[key: number]: string[]};
  visible: boolean;
  isSubmitButtonLoading: boolean;
  showApprovalQuestions: boolean;
  user: UserDetailsVM;
  exchangeRate: number;
  filesProgress?: any[];
  filesToUpload?: any[];
  setFilesToUpload?: (files: any[]) => void;
  deleteFunction?: (idx) => void;
}

const CreateUserProgramDrawer = ({
  onClose,
  visible,
  onSubmitForm,
  providerSuggestions,
  provider,
  onChangeProvider,
  onChangeCheckbox,
  onChangeSelect,
  selectedCheckboxOptions,
  form,
  planItemType,
  formItems,
  rejectedNotes,
  isFormDisabled,
  isTextAreaDisabled,
  programCostFeesMultiplier,
  isHelperTextOn,
  isSubmitButtonLoading,
  showApprovalQuestions,
  user,
  programTypes,
  exchangeRate,
  filesProgress,
  filesToUpload,
  setFilesToUpload,
  deleteFunction,
}: CreateUserProgramDrawerProps) => {
  const validateMessages = {
    required: i18n.t(k.VALIDATION__REQUIRED),
    types: {
      email: i18n.t(k.VALIDATION__EMAIL_INVALID__POLITE),
      number: i18n.t(k.VALIDATION__REQUIRED),
    },
  };
  const validateURL = (_, value) => {
    if (!value || validateUrl(value)) return Promise.resolve();
    return Promise.reject(new Error(i18n.t(k.VALIDATION__URL_INVALID__FORMAT)));
  };
  const formatURL = (value) => {
    if (!value.startsWith('http://') && !value.startsWith('https://'))
      value = `https://${value}`;
    if (!value.includes('www.')) {
      const protocol = value.split('://');
      value = `${protocol[0]}://www.${protocol[1]}`;
    }
    return value;
  };
  const getUserQuery = useGetUserQuery();
  const [currency, setCurrency] = useState<CurrencyCode>(
    getUserQuery.data?.currency
  );
  const isCostField = (item: PreApprovalQuestion) =>
    item.responseType ===
    PreApprovalQuestionResponseSource.UserPlanProgramAmount;
  const {isFeatureFlagOn} = useFeatureFlags();
  const isRenameFieldsEnabled = isFeatureFlagOn.RenameApprovalFields;
  const [rangeDates, setRangeDates] = useState({startDate: '', endDate: ''});

  const createUserProgram = (
    <CreateUserProgramStyled>
      <Form
        style={{width: '100%'}}
        name="nest-messages"
        id="createUserProgramForm"
        className="create-user-program-drawer-drawer"
        onFinish={(values) => {
          formItems?.map((item: PreApprovalQuestion) => {
            if (isCostField(item) && item.response) {
              values[item.id] = Number(item.response);
            }
          });
          form.setFieldsValue({url: formatURL(values.url)});
          onSubmitForm({...values, provider});
        }}
        validateMessages={validateMessages}
        form={form}
        layout={'vertical'}>
        <div className="form-content" style={{width: '100%'}}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24}>
              <LearnInFormItem
                name="name"
                label={i18n.t(
                  isRenameFieldsEnabled
                    ? k.ISSUING_ORGANIZATION
                    : k.PROGRAM__PROVIDER
                )}
                extra={showHelperTextIfAvailable(
                  isHelperTextOn,
                  localizeText({
                    translationKey:
                      DYNAMIC_KEYS.PREFIXES.APPROVAL_FORM +
                      'PROVIDER' +
                      DYNAMIC_KEYS.SUFFIXES.HELPER,
                    translationValue: '',
                  })
                )}
                isHelperTextOn={isHelperTextOn}
                rules={[
                  {
                    required: true,
                    message: noUserInputAllowedHtml(
                      handleInputErrorText(
                        i18n.t(k.VALIDATION__RESPONSE_REQUIRED),
                        localizeText({
                          translationKey:
                            DYNAMIC_KEYS.PREFIXES.APPROVAL_FORM +
                            'PROGRAM__TITLE' +
                            DYNAMIC_KEYS.SUFFIXES.HELPER,
                          translationValue: '',
                        }),
                        isHelperTextOn
                      )
                    ),
                  },
                ]}>
                <LearnInAutocomplete
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={true}
                  // used for a11y ^
                  value={provider}
                  style={{width: '100%'}}
                  options={providerSuggestions}
                  filterOption={(inputValue, {value}) => {
                    return (
                      value
                        ?.toUpperCase()
                        .indexOf(inputValue?.toUpperCase()) !== -1
                    );
                  }}
                  onChange={onChangeProvider}
                />
              </LearnInFormItem>
            </Col>
            <Col xs={24} sm={24}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(
                  isRenameFieldsEnabled
                    ? k.FULL_NAME_OF_PROGRAM
                    : k.PROGRAM__TITLE
                )}
                name={FormFields.Title}
                extra={showHelperTextIfAvailable(
                  isHelperTextOn,
                  localizeText({
                    translationKey:
                      DYNAMIC_KEYS.PREFIXES.APPROVAL_FORM +
                      'PROGRAM__TITLE' +
                      DYNAMIC_KEYS.SUFFIXES.HELPER,
                    translationValue: '',
                  })
                )}
                isHelperTextOn={isHelperTextOn}
                rules={[
                  {
                    required: true,
                    max: 150,
                    message: noUserInputAllowedHtml(
                      handleInputErrorText(
                        i18n.t(k.VALIDATION__RESPONSE_REQUIRED),
                        localizeText({
                          translationKey:
                            DYNAMIC_KEYS.PREFIXES.APPROVAL_FORM +
                            'PROGRAM__TITLE' +
                            DYNAMIC_KEYS.SUFFIXES.HELPER,
                          translationValue: '',
                        }),
                        isHelperTextOn
                      )
                    ),
                  },
                ]}>
                <LearnInInput tag={InputTags.PRIMARY} />
              </LearnInFormItem>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(k.PROGRAM__TYPE)}
                name={FormFields.ProgramTypeId}
                rules={[{required: true}]}>
                <LearnInSelect size="large">
                  {programTypes?.map((programType, key) => (
                    <LearnInSelectOption value={programType.id} key={key}>
                      {localizeGeneric(programType.description)}
                    </LearnInSelectOption>
                  ))}
                </LearnInSelect>
              </LearnInFormItem>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(k.PROGRAM__URL)}
                name={FormFields.Url}
                validateTrigger={[
                  'onBlur',
                  !!form.getFieldError(FormFields.Url).length && 'onChange',
                ]}
                rules={[{required: true}, {validator: validateURL}]}>
                <LearnInInput
                  tag={InputTags.PRIMARY}
                  placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__URL),
                  })}
                />
              </LearnInFormItem>
            </Col>
            <Col xs={24} sm={24}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(
                  isRenameFieldsEnabled
                    ? k.GENERIC__DESCRIPTION
                    : k.PROGRAM__DESCRIPTION__SHORT
                )}
                name={FormFields.ShortDescription}
                rules={[{required: true}]}>
                <LearnInTextArea tag={TextAreaTags.PRIMARY} rows={1} />
              </LearnInFormItem>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12}>
              <LearnInFormItem
                name={FormFields.Cost}
                className="lb-application-drawer"
                label={i18n.t(
                  isRenameFieldsEnabled
                    ? k.PROGRAM__TOTAL_COST_IN_CURRENCY
                    : !isRenameFieldsEnabled
                    ? k.PROGRAM__COST_IN_CURRENCY
                    : isRenameFieldsEnabled
                    ? k.PROGRAM__TOTAL_COST_IN_CURRENCY__FORMAT
                    : k.PROGRAM__COST_IN_CURRENCY__FORMAT,
                  {
                    currency: CurrencyCodeString.USD,
                  }
                )}
                rules={[
                  {
                    required: true,
                    type: 'number',
                    min: 0,
                    message: i18n.t(k.VALIDATION__AMOUNT),
                  },
                ]}
                style={{
                  display: 'inline-block',
                  width: '100%',
                }}>
                <LearnInMoneyInput
                  prefix={null}
                  min={1}
                  style={{width: '100%'}}
                />
              </LearnInFormItem>
            </Col>
            <Col xs={12} sm={12}>
              <LearnInFormItem name={FormFields.Currency} noStyle>
                <CurrencySelect
                  size="large"
                  defaultValue={currency}
                  data-testid={REQUEST_CURRENCY_ID}
                  onChange={(currency: CurrencyCode) => setCurrency(currency)}
                  rowGutter={30}>
                  {currencyOptions}
                </CurrencySelect>
              </LearnInFormItem>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(k.PROGRAM__LENGTH)}
                name={FormFields.ProgramLength}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (Number.isInteger(value)) return Promise.resolve();
                      return Promise.reject(
                        new Error(i18n.t(k.VALIDATION__PROGRAM_LENGTH))
                      );
                    },
                  },
                ]}>
                <LearnInNumberInput style={{width: '100%'}} min={1} />
              </LearnInFormItem>
            </Col>
            <Col xs={12} sm={12}>
              <LearnInFormItem
                className="lb-application-drawer"
                label={i18n.t(k.PROGRAM__LENGTH_UNIT)}
                rules={[{required: true}]}
                name={FormFields.ProgramLengthUnit}>
                <LearnInSelect
                  size="large"
                  placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}>
                  <LearnInSelectOption value={DurationUnitType.Hours}>
                    {i18n.t(k.DATE__HOUR__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption value={DurationUnitType.Days}>
                    {i18n.t(k.DATE__DAY__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption value={DurationUnitType.Weeks}>
                    {i18n.t(k.DATE__WEEK__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption value={DurationUnitType.Months}>
                    {i18n.t(k.DATE__MONTH__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption value={DurationUnitType.Years}>
                    {i18n.t(k.DATE__YEAR__PLURAL)}
                  </LearnInSelectOption>
                </LearnInSelect>
              </LearnInFormItem>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24} className="pre-approval-drawer-form-layout">
              {formItems?.map((item: PreApprovalQuestion, key) => {
                return (
                  <PreApprovalFormItem
                    key={key}
                    form={form}
                    formQuestions={formItems}
                    item={item}
                    onChangeSelect={onChangeSelect}
                    selectedCheckboxOptions={selectedCheckboxOptions}
                    onChangeCheckbox={onChangeCheckbox}
                    isFormDisabled={isFormDisabled}
                    isTextAreaDisabled={isTextAreaDisabled}
                    programCost={null}
                    programCostFeesMultiplier={programCostFeesMultiplier}
                    programCurrency={currency}
                    isUserRequest={true}
                    isCostField={isCostField(item)}
                    rangeDates={rangeDates}
                    setRangeDates={setRangeDates}
                    filesToUpload={filesToUpload}
                    filesProgress={filesProgress}
                    deleteFunction={deleteFunction}
                    setFilesToUpload={setFilesToUpload}
                  />
                );
              })}

              {!!rejectedNotes && (
                <LearnInFormItem
                  name={'approver-notes'}
                  label={i18n.t(k.NOTE__FROM_APPROVER)}
                  className="lb-application-drawer"
                  initialValue={rejectedNotes}>
                  <LearnInTextArea
                    tag={TextAreaTags.PRIMARY}
                    disabled={true}
                    rows={4}
                    maxLength={10000}
                  />
                </LearnInFormItem>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </CreateUserProgramStyled>
  );

  const wrapInDrawer = (form: JSX.Element) => {
    return (
      <LearnInDrawer
        closeButtonPlacement={'right'}
        title={i18n.t(k.PROGRAM__REQUEST)}
        closable={true}
        onClose={onClose}
        visible={visible}
        height="80%"
        footer={[
          <FooterButtonsContainer key="footer">
            <LearnInButton
              tag={ButtonTags.DrawerFooterSecondary}
              onClick={onClose}
              key={'cancel'}>
              {i18n.t(k.CTA__CANCEL)}
            </LearnInButton>
            <LearnInButton
              loading={isSubmitButtonLoading}
              tag={ButtonTags.DrawerFooterPrimary}
              htmlType="submit"
              data-testid="createUserProgramForm"
              form="createUserProgramForm"
              key={'requestapproval'}>
              {i18n.t(showApprovalQuestions ? k.CTA__SUBMIT : k.PLAN__ADD_TO)}
            </LearnInButton>
          </FooterButtonsContainer>,
        ]}>
        {form}
      </LearnInDrawer>
    );
  };

  return createUserProgram;
};

export default CreateUserProgramDrawer;
