import styled from 'styled-components';
import {AutoComplete} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {COLORS} from '@utils/constants';
interface Props extends SelectProps {
  value?: string;
  style?: any;
  options?: {label: any; value: any}[];
  placeholder?: string;
  filterOption?: boolean | any;
  autoFocus?: boolean;
  onChange?: (value: any) => void;
  onKeyPress?: (e?: any) => void;
}

export default function LearnInAutocomplete({...rest}: Props) {
  return <StyledAutoComplete {...rest} />;
}

export const filterResults = (
  input: string,
  option: Partial<{label: string}>
) => (option?.label?.toUpperCase() ?? '').includes(input?.toUpperCase());

export const commonSelectStyles = `
background: ${COLORS.White} !important;
color: ${COLORS.Black};
  border-radius: 4px;
  transition: all 0.2s ease-out;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  &:disabled {
    background: ${COLORS.Neutral100} !important;
  }
`;

export const StyledAutoComplete = styled(AutoComplete)`
  ${commonSelectStyles}
  border: ${(props: any) =>
    props.isError
      ? `1px solid ${COLORS.Red800}`
      : `1px solid ${COLORS.Neutral300}`} !important;
  &:focus-within {
    border: ${(props: any) =>
      props.isError
        ? `1px solid ${COLORS.Red800}`
        : `1px solid ${COLORS.Blue800}`} !important;
    box-shadow: none !important;
  }
`;
