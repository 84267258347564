import {i18n, k} from '@i18n/translate';
import {AntdUploadFile} from './util.interface';
import fileSize from 'filesize';
import {CurrencyCodeString} from '@models/clientEnums';
import {getUsersLocale} from './l10nUtils';
import {notify} from '@components/user/notifications';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';

/**
 * Convert a file to base64
 * @param file
 * @param callback
 */
export function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsBinaryString(file);
}

export enum FileUploadType {
  Images,
  Pdf,
  Videos,
}

interface FileUploadDetail {
  mediaTypes: string[];
  fileSuffixes: string[];
}

export const FileTypeDetails: Record<FileUploadType, FileUploadDetail> = {
  [FileUploadType.Images]: {
    mediaTypes: ['image/png', 'image/jpg', 'image/jpeg'],
    fileSuffixes: ['.png', '.jpg', '.jpeg'],
  },
  [FileUploadType.Pdf]: {
    mediaTypes: ['application/pdf'],
    fileSuffixes: ['.pdf'],
  },
  [FileUploadType.Videos]: {
    mediaTypes: ['video/mp4', 'video/quicktime'],
    fileSuffixes: ['.mp4', '.mov'],
  },
};

/**
 * Generates the media types to validate against when uploading a file. You specify the types of media e.g. images and
 * this handles generating all the possible types that we accept.
 * @param desiredTypes
 */
export function generateFileUploadMediaTypes(
  desiredTypes: FileUploadType[]
): string[] {
  const result = new Set<string>();
  desiredTypes.forEach((uploadType) => {
    switch (uploadType) {
      case FileUploadType.Images:
        FileTypeDetails[FileUploadType.Images].mediaTypes.forEach((mediaType) =>
          result.add(mediaType)
        );
        break;
      case FileUploadType.Pdf:
        FileTypeDetails[FileUploadType.Pdf].mediaTypes.forEach((mediaType) =>
          result.add(mediaType)
        );
        break;
      case FileUploadType.Videos:
        FileTypeDetails[FileUploadType.Videos].mediaTypes.forEach((mediaType) =>
          result.add(mediaType)
        );
        break;
      default:
        break;
    }
  });

  return Array.from<string>(result);
}

/**
 * Returns a list of items with a conjunction in the last spot. E.g. apples, pears, and oranges
 * @param array
 * @param separator
 * @param conjunction
 */
function joinWithOxfordComma(
  array: any[],
  separator = ', ',
  conjunction?: string
): string {
  if (!conjunction) conjunction = i18n.t(k.GENERIC__AND).toLocaleLowerCase();
  switch (array.length) {
    case 0:
      return '';
    case 1:
      return String(array[0]);
    default:
      return `${array
        .slice(0, -1)
        .join(separator)} ${conjunction} ${array.slice(-1)}`;
  }
}

/**
 * Generate the file types this uploader accepts. This can be useful but you're probably looking for the method
 * generateAcceptedFileTypeDisplayString
 * @param desiredTypes
 * @param trailingJoinerWord conjunction to use for oxford comma
 */
export function generateAcceptedFileTypesForUsers(
  desiredTypes: FileUploadType[],
  trailingJoinerWord?: string
): string {
  if (!trailingJoinerWord)
    trailingJoinerWord = i18n.t(k.GENERIC__AND).toLocaleLowerCase();
  const uniqueTypes = new Set(desiredTypes);
  const resultBuilder = [];
  if (uniqueTypes.has(FileUploadType.Images)) {
    resultBuilder.push(...FileTypeDetails[FileUploadType.Images].fileSuffixes);
  }
  if (uniqueTypes.has(FileUploadType.Pdf)) {
    resultBuilder.push(...FileTypeDetails[FileUploadType.Pdf].fileSuffixes);
  }
  if (uniqueTypes.has(FileUploadType.Videos)) {
    resultBuilder.push(...FileTypeDetails[FileUploadType.Videos].fileSuffixes);
  }

  return joinWithOxfordComma(resultBuilder, ', ', trailingJoinerWord);
}

/**
 * Generates a display string to be used with file uploads to instruct user and size and file type requirements
 * @param desiredTypes
 * @param maxUploadSizeBytes
 */
export function generateAcceptedFileTypeDisplayString(
  desiredTypes: FileUploadType[],
  maxUploadSizeBytes: number
): string {
  return i18n.t(k.FILE__UPLOAD__ACCEPTED_TYPES__FORMAT, {
    types: generateAcceptedFileTypesForUsers(desiredTypes),
    maxSize: fileSize(maxUploadSizeBytes),
  });
}

/**
 * Validate aspects of the file before upload
 * @param file
 * @param fileList
 * @param maxFileSizeBytes
 * @param unitOfMeasure
 * @param validFileTypes
 */
export function validateUserUpload(
  file: AntdUploadFile,
  fileList: Array<AntdUploadFile>,
  maxFileSizeBytes: number,
  unitOfMeasure: string,
  validFileTypes: string[] = generateFileUploadMediaTypes([
    FileUploadType.Images,
    FileUploadType.Pdf,
    FileUploadType.Videos,
  ])
) {
  const {type, size} = file;
  const isDuplicateFile = fileList.filter((f) => f.name === file.name).length;
  if (isDuplicateFile) {
    file.status = 'error';
    file.errMsg = i18n.t(k.FILE__UPLOAD__ALREADY__FORMAT);
    return false;
  }
  if (validFileTypes.includes(type)) {
    if (size > maxFileSizeBytes) {
      const maxSizeFormatted = Intl.NumberFormat(getUsersLocale(), {
        style: 'currency',
        currency: CurrencyCodeString.USD,
        maximumFractionDigits: 0,
      })
        .format(maxFileSizeBytes)
        .split('$')[1];
      const maxSizeFormattedWithUnit = `${maxSizeFormatted} ${unitOfMeasure}`;
      file.status = 'error';
      file.errMsg = i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT, {
        item: file.name,
        max: maxSizeFormattedWithUnit,
      });
      console.log(file.errMsg);
      notify.maxFileUploadSizeExceededError({
        fileSize: `${(size / UploadConstraints.OneMegabyte).toFixed(2)}Mb`,
        maxSize: `${maxFileSizeBytes / UploadConstraints.OneMegabyte}Mb`,
      });
      return false;
    }
    console.log(i18n.t(k.VALIDATION__FILE));
    return true;
  }

  file.status = 'error';
  file.errMsg = i18n.t(k.FILE__UPLOAD__UNSUPPORTED__FORMAT, {file: file.name});
  console.log(file.errMsg);
  return false;
}
