// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IAcademyBasicInfo.cs

import {z} from 'zod';
import {AcademyStatusSchema} from './AcademyStatusSchema.schema';

export const IAcademyBasicInfoSchema = z.object({
  academyProfileImageUrl: z.string(),
  degreedId: z.string(),
  description: z.string(),
  discussionId: z.string().nullable().optional(),
  groupId: z.string(),
  id: z.number(),
  invitationEmail: z.boolean(),
  joinId: z.string(),
  name: z.string(),
  restrictedAccess: z.boolean(),
  status: AcademyStatusSchema,
});
