// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/NotificationSettings.cs

import {z} from 'zod';
import {NotificationConfigSchema} from './NotificationConfigSchema.schema';

export const NotificationSettingsSchema = z.object({
  allowEmails: z.boolean(),
  allowTexts: z.boolean(),
  approvalRequested: NotificationConfigSchema,
  approvalRequestUpdated: NotificationConfigSchema,
  completeYourPlan: NotificationConfigSchema,
  contributorUpdate: NotificationConfigSchema,
  dueDateReminders: NotificationConfigSchema,
  eventReminders: NotificationConfigSchema,
  managerApprovalRequested: NotificationConfigSchema,
  newProjectFeedback: NotificationConfigSchema,
  newProjectSubmission: NotificationConfigSchema,
  programAccess: NotificationConfigSchema,
  programNomination: NotificationConfigSchema,
  programNudgeReminders: NotificationConfigSchema,
  programProgressUpdate: NotificationConfigSchema,
  weeklyFollowUp: NotificationConfigSchema,
});
