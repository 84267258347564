import {
  getCommonRules,
  renderQuillLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import LearnInSelect, {
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {PreApprovalQuestionOption} from '@models/serverModels';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {showHelperTextIfAvailable} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface RecurringDurationDropdownProps {
  frequencyDropdown?: ResourceOrPlanItemPreApprovalQuestion;
  initialValue?: number;
  isHelperTextOn: boolean;
  helperText?: string;
  localizationId?: string;
  setMonths?: (e) => void;
}
export const RecurringDurationDropdown = ({
  frequencyDropdown,
  initialValue = 1,
  isHelperTextOn,
  helperText,
  localizationId,
  setMonths,
}: RecurringDurationDropdownProps) => {
  const months: PreApprovalQuestionOption[] = frequencyDropdown.options?.sort(
    (a: PreApprovalQuestionOption, b: PreApprovalQuestionOption) =>
      parseInt(a.responseValue) - parseInt(b.responseValue)
  );
  return (
    <LearnInFormItem
      name={String(frequencyDropdown.id)}
      requiredMark={false}
      initialValue={initialValue}
      label={renderQuillLabel(frequencyDropdown)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: localizationId
            ? localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: helperText,
        })
      )}
      isHelperTextOn={isHelperTextOn}
      rules={getCommonRules(frequencyDropdown, isHelperTextOn)}>
      <LearnInSelect size="large" onChange={setMonths}>
        {months.map(({responseValue: month}) => (
          <LearnInSelectOption key={month} value={month}>
            {month}
          </LearnInSelectOption>
        ))}
      </LearnInSelect>
    </LearnInFormItem>
  );
};
