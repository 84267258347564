// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/FinanceAmountType.cs

enum FinanceAmountType {
  UpTo,
  Exact,
  Any,
}

export default FinanceAmountType;
