import {useCallback, useEffect} from 'react';

function useQuickActionModalKeyListener(
  available: boolean,
  setModalVisibility: (value: React.SetStateAction<boolean>) => void
) {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const cmdShiftU =
        event?.key?.toUpperCase() === 'U' &&
        (event?.metaKey || event?.ctrlKey) &&
        event?.shiftKey;
      if (cmdShiftU) setModalVisibility(true);
    },
    [setModalVisibility]
  );

  useEffect(() => {
    if (available) {
      document.addEventListener('keydown', handleKeyPress);
      const unbindEvent = () =>
        document.removeEventListener('keydown', handleKeyPress);
      return unbindEvent;
    }
  }, [handleKeyPress, available]);
}

export default useQuickActionModalKeyListener;
