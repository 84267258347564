import {i18n, k} from '@i18n/translate';
import {Checkbox} from 'antd';
import {CardIssuerTermsViewProps} from './CardIssuerTerms.interfaces';
import {modalWidth, stripeTosUrl} from './CardIssuerTerms.shared';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  StyledCheckboxContainer,
  StyledDescription,
  StyledExternalLink,
  StyledH3,
  StyledLearnInModal,
  StyledModalContainer,
} from './CardIssuerTerms.styled';

function CardIssuerTermsView({
  visible,
  submitDisabled,
  onCheckAcceptedTerms,
  onSubmit,
  trackExternalLinkClick,
}: CardIssuerTermsViewProps) {
  return (
    <StyledLearnInModal
      tag={ModalTags.CardIssuerTermsModal}
      visible={visible}
      onOk={onSubmit}
      width={modalWidth}
      closeable={false}
      hideLeftButton={true}
      buttonDisabled={submitDisabled}
      okButtonTag={ButtonTags.Primary}
      okButtonChild={i18n.t(k.TOS__STRIPE__ACCEPT)}>
      <StyledModalContainer>
        <StyledH3 fontWeight={500}>{i18n.t(k.TOS__STRIPE__TITLE)}</StyledH3>
        <StyledDescription fontWeight={600}>
          {i18n.t(k.TOS__STRIPE__DESCRIPTION)}
        </StyledDescription>
        <StyledExternalLink
          href={stripeTosUrl}
          target="_blank"
          data-testid="card-issuer-terms-link"
          onClick={trackExternalLinkClick}>
          {i18n.t(k.TOS__STRIPE__LINK)}
        </StyledExternalLink>
        <StyledCheckboxContainer>
          <Checkbox
            data-testid="card-issuer-terms-checkbox"
            onChange={onCheckAcceptedTerms}>
            {i18n.t(k.TOS__STRIPE__ACCEPTANCE)}
          </Checkbox>
        </StyledCheckboxContainer>
      </StyledModalContainer>
    </StyledLearnInModal>
  );
}

export default CardIssuerTermsView;
