import {isLocal} from '@utils/environment-helpers';
import {Component, ErrorInfo, ReactNode} from 'react';
import StackTraceOverlay from './StackTraceOverlay';

interface ErrorBoundaryProps {
  /**
   * Callback invoked when the Error Boundary catches an exception
   */
  onCatch?: (error: Error, errorInfo: ErrorInfo) => void;
  /**
   * A single element or an array of elements to display when an error occurs
   */
  fallback?: ReactNode | ReactNode[];
  /**
   * A single element or an array of elements representing the content to be rendered within the error boundary.
   * These elements will be displayed normally when no error occurs.
   * Note: Error boundaries only catch errors within their child components and their subtree, not within the error boundary itself.
   **/
  children: ReactNode | ReactNode[];
}

interface ErrorBoundaryState {
  error: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {error: null};
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.onCatch?.(error, errorInfo);
  }

  render(): ReactNode {
    if (!!this.state.error) {
      return (
        <div>
          {isLocal && <StackTraceOverlay stack={this.state.error.stack} />}
          {this.props.fallback}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
