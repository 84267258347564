// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProjectSubmissionAdminVM.cs

import {z} from 'zod';
import {ProjectSubmissionVMSchema} from './ProjectSubmissionVMSchema.schema';
import {ContentFeedbackVMSchema} from './ContentFeedbackVMSchema.schema';

const schema = z.object({
  feedback: z.array(ContentFeedbackVMSchema),
});

export const ProjectSubmissionAdminVMSchema =
  ProjectSubmissionVMSchema.merge(schema);
