// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramParticipantVM.cs

import {z} from 'zod';

export const ProgramParticipantVMSchema = z.object({
  employeeName: z.string(),
  lastUpdated: z.string().nullable().optional(),
  planItemId: z.number(),
  status: z.string(),
  timeEnrolled: z.string().nullable().optional(),
  userCompanyId: z.number(),
});
