export const loadCredSparkScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'credspark-snippet';
  script.async = true;
  script.defer = true;
  script.src =
    'https://app.credspark.com/assessments/skill-a-self-assessment-727b2f6dc4e04/embed_script.js';
  document.getElementsByTagName('head')[0].appendChild(script);
  console.log('Loaded CredSpark');
};

export const initCredSpark = () => {
  loadCredSparkScript();
};
