/**
 * Configuration for the ZenDesk Chat widget (zESettings)
 * ZenDesk localizes the generic parts of its UI.
 */
export const configChat = () => {
  return {
    webWidget: {
      contactForm: {
        ticketForms: [{id: 10982236987292}],
      },
    },
  };
};
