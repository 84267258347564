// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyBrandPayload.cs

import {z} from 'zod';
import {IAcademyBrandBasicInfoSchema} from './IAcademyBrandBasicInfoSchema.schema';

const schema = z.object({
  academyId: z.number(),
  backgroundColor: z.string(),
  backgroundWelcomeColor: z.string(),
  buttonDefaultWelcomeColor: z.string(),
  buttonHoverWelcomeColor: z.string(),
  isDefaultTheme: z.boolean(),
  itemDefaultTextNavColor: z.string(),
  itemDefaultTextWelcomeColor: z.string(),
  itemHoverBackgroundNavColor: z.string(),
  itemHoverTextNavColor: z.string(),
  itemSelectedBackgroundNavColor: z.string(),
  itemSelectedTextNavColor: z.string(),
  primaryBrandColor: z.string(),
  secondaryBrandColor: z.string(),
});

export const AcademyBrandPayloadSchema =
  IAcademyBrandBasicInfoSchema.merge(schema);
