// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Payments/CreditCardTransactionVM.cs

import {z} from 'zod';
import {TransactionStateSchema} from './TransactionStateSchema.schema';

export const CreditCardTransactionVMSchema = z.object({
  amount: z.number(),
  description: z.string(),
  id: z.number(),
  state: TransactionStateSchema,
  status: z.string(),
  timestamp: z.string(),
  uniqueId: z.string(),
});
