import {FormItem} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import LearnInRadioButtons from '@components/reusable/RadioButtons/RadioButtons.style';
import {Radio} from 'antd';
import {i18n, k} from '@i18n/translate';

interface SubscriptionToggleProps {
  setIsRecurring: (isRecurring: boolean) => void;
  initialValue?: boolean;
}

export const SubscriptionToggle = ({
  setIsRecurring,
  initialValue = false,
}: SubscriptionToggleProps) => {
  return (
    <FormItem
      requiredMark={false}
      name={'isRecurring'}
      style={{width: 'max-content'}}
      initialValue={initialValue ?? false}>
      <LearnInRadioButtons
        onChange={(e) => setIsRecurring(e.target.value)}
        buttonStyle="solid"
        size={'large'}
        gapSize={'none'}>
        <Radio.Button value={false}>
          {i18n.t(k.DATE__FREQUENCY__ONE_TIME)}
        </Radio.Button>
        <Radio.Button value={true}>{i18n.t(k.DATE__PER_MONTH)}</Radio.Button>
      </LearnInRadioButtons>
    </FormItem>
  );
};
