// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AttachmentPayload.cs

import {z} from 'zod';
import {BaseAttachmentPayloadSchema} from './BaseAttachmentPayloadSchema.schema';

const schema = z.object({
  fileContent: z.string(),
});

export const AttachmentPayloadSchema =
  BaseAttachmentPayloadSchema.merge(schema);
