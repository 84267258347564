// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramEmailMessagePayload.cs

import {z} from 'zod';
import {ProgramNotificationTypeSchema} from './ProgramNotificationTypeSchema.schema';

export const CustomProgramEmailMessagePayloadSchema = z.object({
  customProgramId: z.number(),
  message: z.string(),
  type: ProgramNotificationTypeSchema,
});
