import useFeatureFlags from '@hooks/useFeatureFlags';
import {useNavigate} from 'react-router-dom';
import {handleClickProgramFinder} from './Request.handlers';
import {
  useGetRequestQueries,
  useGetRequestState,
  useGetRequestText,
} from './Request.hooks';
import RequestView from './Request.view';

function RequestContainer({
  showButton,
  drawerVisible,
  setDrawerVisible,
}: {
  showButton?: boolean;
  drawerVisible?: boolean;
  setDrawerVisible?: (visible: boolean) => void;
}) {
  const navigate = useNavigate();
  const {isFeatureFlagOn} = useFeatureFlags();

  const {
    companyPersonalizationQuery,
    doProgramsRequireApprovalQuery,
    userProgramPlanQuery,
  } = useGetRequestQueries();

  const {
    showFindProgramOption,
    showProgramFinderOption,
    showRequestButton,
    showRequestProgramOption,
    showRequestResourceOption,
  } = useGetRequestState({
    isHideMarketplaceOn: isFeatureFlagOn.HideMarketplace,
    isOnlyCustomProgramsUser: isFeatureFlagOn.OnlyCustomProgramsUser,
    userProgramPlanQuery,
  });

  const requestButtonText = useGetRequestText(
    !!companyPersonalizationQuery?.data,
    doProgramsRequireApprovalQuery,
    showRequestResourceOption
  );

  return (
    showRequestButton && (
      <RequestView
        handleClickProgramFinder={handleClickProgramFinder}
        isLoading={
          doProgramsRequireApprovalQuery?.isLoading ||
          companyPersonalizationQuery?.isLoading
        }
        navigate={navigate}
        requestButtonText={requestButtonText}
        showFindProgramOption={showFindProgramOption}
        showProgramFinderOption={showProgramFinderOption}
        showRequestProgramOption={showRequestProgramOption}
        showRequestResourceOption={showRequestResourceOption}
        showButton={showButton}
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
      />
    )
  );
}

export default RequestContainer;
