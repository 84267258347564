import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {
  FormItem,
  getCommonRules,
  renderLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {LearnInSelect} from '@components/reusable/LearnInForm';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {showHelperTextIfAvailable} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface DropdownProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  onChangeSelect?: (value: string) => void;
  isFormDisabled: boolean;
  isHelperTextOn: boolean;
}
export const Dropdown = ({
  item,
  onChangeSelect,
  isFormDisabled,
  isHelperTextOn,
}: DropdownProps) => {
  const getInitialDropdownValue = (
    item: ResourceOrPlanItemPreApprovalQuestion
  ) => {
    if (!!item.response) {
      const response = item.options?.find(
        (option: {id: string; responseValue: string}) =>
          option.responseValue === item.response
      );
      return response?.responseValue;
    }
  };

  return (
    <LearnInFormItem
      className="lb-application-drawer"
      key={item.id}
      name={String(item.id)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      label={renderLabel(item)}
      requiredMark={false}
      initialValue={getInitialDropdownValue(item)}
      rules={getCommonRules(item, isHelperTextOn)}>
      <LearnInSelect
        size="large"
        style={{width: '100%'}}
        onChange={onChangeSelect}
        disabled={isFormDisabled}>
        {item.options?.map((program) => (
          <LearnInSelectOption key={program.id} value={program.responseValue}>
            {program.responseValue}
          </LearnInSelectOption>
        ))}
      </LearnInSelect>
    </LearnInFormItem>
  );
};
