// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Reports/UserFinanceIncentiveTransactionsVM.cs

import {z} from 'zod';
import {CreditCardAdminActionVMSchema} from './CreditCardAdminActionVMSchema.schema';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';
import {CreditCardTransactionVMSchema} from './CreditCardTransactionVMSchema.schema';

export const UserFinanceIncentiveTransactionsVMSchema = z.object({
  adminActions: z.array(CreditCardAdminActionVMSchema),
  amountType: FinanceAmountTypeSchema,
  balance: z.number(),
  incentiveTitle: z.string(),
  isActiveOnUserPlan: z.boolean(),
  programPreApprovalRequired: z.boolean(),
  recurrenceType: BalanceRecurTypeSchema,
  spendingLimit: z.number(),
  totalApproved: z.number(),
  totalSpent: z.number(),
  transactions: z.array(CreditCardTransactionVMSchema),
  userFinanceIncentiveId: z.number(),
});
