// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/Responses/CustomProgramUserResponse.cs

import {z} from 'zod';

export const CustomProgramUserResponseSchema = z.object({
  createdOn: z.string(),
  customProgramId: z.number(),
  email: z.string().nullable().optional(),
  lastCustomProgramNudgeSent: z.string().nullable().optional(),
  userCompanyId: z.number(),
  userName: z.string(),
});
