// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AttachmentPayload.cs

import {z} from 'zod';
import {AttachmentTypeSchema} from './AttachmentTypeSchema.schema';

export const BaseAttachmentPayloadSchema = z.object({
  chunkNumber: z.number(),
  description: z.string(),
  file: z.any(),
  fileName: z.string(),
  totalChunks: z.number(),
  type: AttachmentTypeSchema,
});
