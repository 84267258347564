// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/IncentiveAdminVM.cs

import {z} from 'zod';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {IncentiveAvailabilitySchema} from './IncentiveAvailabilitySchema.schema';

export const IncentiveAdminVMSchema = z.object({
  approvalConfig: ApprovalConfigSchema,
  approvalGuidelines: z.string(),
  availability: IncentiveAvailabilitySchema,
  eligibleEmployees: z.number(),
  id: z.number(),
  imageLarge: z.string(),
  imageSmall: z.string(),
  incentiveType: z.string(),
  learningBudget: z.boolean(),
  timesPlanned: z.number(),
  timesUsed: z.number(),
  title: z.string(),
});
