import {ReactChild, ReactNode, useMemo} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {Modal} from 'antd';
import {ModalProps} from 'antd/lib/modal';
import {ModalStyles} from './ModalTheme';
import {ModalTags} from './ModalEnums';
import {getRandomKey} from '@utils/stringUtils';
import {COLORS} from '@utils/constants';
import {CloseOutlined} from '@ant-design/icons';
import {i18n, k} from '@i18n/translate';
import {setModalAriaLabel} from './utils/setModalAriaLabel';

/*
|--------------------------------------------------------------------------
| Styles & Styled Components
|--------------------------------------------------------------------------
*/

export const BUTTON_HEIGHT = '42px';

const ContentContainer = styled.section`
  margin: 24px 0px;
  padding: 0 24px;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4375rem;
  margin: 24px 0px;
  padding: 24px 24px 0px 24px;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: ${(props: any) =>
      props.borderRadius || ModalStyles[props.tag].borderRadius};
  }

  & .ant-modal-body {
    padding: ${(props: any) => props.padding || ModalStyles[props.tag].padding};
  }

  & .anticon.anticon-close {
    left: 23.69px;
    position: absolute;
    top: 22px;
  }

  & .ant-modal-close:focus svg {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }

  & .ant-modal-footer {
    padding: 16px;
    display: ${(props: any) =>
      props.displayFooterContent ||
      ModalStyles[props.tag].displayFooterContent};
    justify-content: ${(props: any) =>
      props.justifyFooterContent ||
      ModalStyles[props.tag].justifyFooterContent};
  }
`;

/*
|--------------------------------------------------------------------------
| Modal Component
|--------------------------------------------------------------------------
*/

export interface CustomModalProps extends ModalProps {
  borderRadius?: string;
  buttonDisabled?: boolean;
  cancelButtonChild?: ReactChild;
  children?: ReactNode;
  content?: React.ReactNode;
  displayFooterContent?: string;
  justifyFooterContent?: string;
  okButtonChild?: ReactChild;
  padding?: string;
  tag: ModalTags;
  title?: string;
  okButtonProps?: React.HTMLAttributes<HTMLButtonElement>;
  cancelButtonProps?: React.HTMLAttributes<HTMLButtonElement>;
  footerRight?: ReactNode;
  footerLeft?: ReactNode;
  okButtonTag?: ButtonTags;
  /** When the secondary click should do something distinct from the onCancel. i.e. do something on secondary button click, but don't trigger it on the X or ESC close events */
  onSecondaryClick?: (e: React.SyntheticEvent) => void;
  hideLeftButton?: boolean;
  closeable?: boolean;
  visible?: boolean;
  customKey?: undefined | null | string; // undefined will create a random key.
  ariaLabel?: string;
  ['data-testid-prefix']?: string;
}

export function LearnInModal({
  children,
  okButtonChild,
  cancelButtonChild,
  title,
  content,
  okButtonProps,
  okButtonTag,
  onSecondaryClick,
  cancelButtonProps,
  footerLeft,
  footerRight,
  hideLeftButton,
  closeable = true,
  customKey = undefined,
  visible,
  ariaLabel,
  ...rest
}: CustomModalProps) {
  const randomModalKey = useMemo(() => getRandomKey(rest.tag), [rest.tag]);
  const randomModalTitleKey = `${randomModalKey}-title`;
  const randomModalContentKey = `${randomModalKey}-content`;
  const dataTestIdPrefix = rest['data-testid-prefix'];

  React.useLayoutEffect(() => {
    if (visible) {
      setModalAriaLabel(title || ariaLabel);
    }
  }, [visible]);

  return (
    <StyledModal
      closeIcon={
        <span
          aria-label={i18n.t(k.CTA__ITEM__FORMAT, {
            item: i18n.t(k.GENERIC__DRAWER),
          })}>
          <CloseOutlined aria-hidden="true" />
        </span>
      }
      closable={closeable}
      maskClosable={false}
      id={customKey === undefined ? randomModalKey : customKey}
      key={customKey === undefined ? randomModalKey : customKey}
      width={567}
      centered={true}
      footer={[
        <footer
          key="footer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div>{footerLeft}</div>
          <div style={{display: 'flex'}}>
            {footerRight}
            {!hideLeftButton && (
              <LearnInButton
                data-testid={
                  dataTestIdPrefix
                    ? `${dataTestIdPrefix}-modal-cancel-button`
                    : null
                }
                key={1}
                tag={ButtonTags.Secondary}
                style={{width: 'fit-content', height: BUTTON_HEIGHT}}
                onClick={(e) => {
                  onSecondaryClick?.(e);
                  rest?.onCancel(e);
                }}
                {...cancelButtonProps}>
                {cancelButtonChild}{' '}
                {/**Left button should always be secondary and the cancel or alternative option */}
              </LearnInButton>
            )}

            <LearnInButton
              data-testid={
                dataTestIdPrefix ? `${dataTestIdPrefix}-modal-ok-button` : null
              }
              key={2}
              tag={okButtonTag || ButtonTags.Primary}
              style={{
                width: 'fit-content',
                height: BUTTON_HEIGHT,
                padding: '0px 16px',
              }}
              onClick={(e) => rest.onOk(e)}
              disabled={rest.buttonDisabled}
              {...okButtonProps}>
              {okButtonChild}
              {/**Right button should always be primary and the affirmative option */}
            </LearnInButton>
          </div>
        </footer>,
      ]}
      open={visible}
      {...rest}>
      {!!title && <Title id={randomModalTitleKey}>{title}</Title>}
      {!!content && (
        <ContentContainer id={randomModalContentKey}>
          {content}
        </ContentContainer>
      )}
      {children}
    </StyledModal>
  );
}
