import {isNil} from 'lodash';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import {getUsersLocale} from '@utils/l10nUtils';

export const currencyFormatter = (
  amount: number,
  currencyCode?: CurrencyCode | string,
  decimalDigits?: number,
  options?: Intl.NumberFormatOptions
) => {
  if (isNil(amount)) {
    return '';
  }

  // Cast to CurrencyCode if only a string is known
  const currency =
    (typeof currencyCode === 'string' &&
    CurrencyCode[currencyCode.toUpperCase()]
      ? CurrencyCode[currencyCode.toUpperCase()]
      : currencyCode) || CurrencyCode.USD; // fallback if unknown

  return new Intl.NumberFormat(getUsersLocale(), {
    style: 'currency',
    currency: CurrencyCode[currency],
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimalDigits | 0,
    maximumFractionDigits: decimalDigits | 0,
    ...options,
  }).format(amount);
};

export const getCardIconColorByType = (itemType: number): string => {
  switch (itemType) {
    case PlanItemType.Program:
      return '#ed344e';
    case PlanItemType.Finance:
      return '#52c41a';
    case PlanItemType.Time:
    default:
      return '';
  }
};

export function numberFormatter(num, digits): string {
  const si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function openInNewTab(url): void {
  const win = window.open(url, '_blank');
  win?.focus();
}

export function getInitialsOfName(name = ''): string {
  return name
    .split(' ')
    .map((partOfName) => partOfName[0])
    .join('')
    .toLocaleUpperCase();
}
