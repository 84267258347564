import {useState, useRef} from 'react';
import {computeFileChecksumMd5} from '@utils/fileUtils';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {getDeleteAcademyStepContentAttachmentRm} from '@store/apiEndpoints/academy/mutations';
import {
  AttachmentType,
  CustomizableLearningCategory,
  PlanItemType,
  ReimbursementItemType,
} from '@generated/enums';
import {useMutation} from 'react-query';
import {
  largeAttachmentQueryFn,
  multipleAttachmentProgressQueryFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {CustomProgramAttachmentPayload} from '@models/serverModels';
import {notify} from '@components/user/notifications';
import {AttachmentLinkType} from '@generated/enums';

export const getUploadChunkQueryKey = (contentId: number): string[] => {
  return ['contentAttachmentUpload', String(contentId)];
};

type AttachmentPayload = {
  contentId?: number;
  index?: number;
  linkType?: AttachmentLinkType;
};

interface UseChunkUpload {
  chunkUpload: (
    attachment: any,
    {contentId, index, linkType}: AttachmentPayload
  ) => Promise<void>;
  cancelUpload: () => Promise<void>;
  multipleUploadProgress: {
    progress: number;
    index: string;
  };
  fileId: string;
  uploadProgress: undefined | number;
}

interface UseChunkUploadProps {
  query: any;
  customizableLearningCategory:
    | CustomizableLearningCategory
    | ReimbursementItemType
    | PlanItemType;
  onComplete?: () => void;
  onError?: () => void;
}

// Set default value to normal usage, eventually we can remove this
export default function useChunkUpload({
  query,
  customizableLearningCategory,
  onComplete,
  onError,
}: UseChunkUploadProps): UseChunkUpload {
  // STATE
  const abortController = useRef(new AbortController());
  const [multipleUploadProgress, setMultipleUploadProgress] = useState({
    progress: 0,
    index: '',
  });
  const {uploadProgress, onStart, onUpdateProgress, onFinish} =
    useChunkUploadUtils();
  const [fileId, setFileId] = useState('');
  const [currentAttachmentId, setCurrentAttachmentId] = useState<
    number | undefined
  >(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  // MUTATIONS
  const deleteContentAttachmentMutation = useMutation(
    (attachmentId: number) => {
      const deleteAcademyStepContentAttachmentRm =
        getDeleteAcademyStepContentAttachmentRm(attachmentId);
      return simpleDeleteFn<null>(
        deleteAcademyStepContentAttachmentRm.path,
        deleteAcademyStepContentAttachmentRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.deleteCustomProgramContentAttachmentSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramContentAttachmentError();
      },
    }
  );
  const fileUploadMutation = useMutation(
    ({
      attachmentChunk,
      contentId,
      index,
    }: {
      attachmentChunk: CustomProgramAttachmentPayload;
      contentId: number;
      index?: number;
    }) => {
      const queryRq = query(contentId, customizableLearningCategory);
      if (index === undefined) {
        return largeAttachmentQueryFn(queryRq.path, attachmentChunk);
      }
      return multipleAttachmentProgressQueryFn(
        queryRq.path,
        attachmentChunk,
        index,
        setMultipleUploadProgress
      );
    },
    {
      onError: ({request}) => {
        if (request.status === 400) {
          notify.uploadContentAttachmentError();
        }
      },
    }
  );

  // Function to upload entire file (contains function to upload each chunk)
  async function chunkUpload(
    attachment: any,
    {contentId, index, linkType}: AttachmentPayload
  ) {
    // Calculate total number of chunks
    const totalChunks = Math.ceil(
      attachment.size / UploadConstraints.MinChunkSize
    );

    // Function to upload each chunk of the file, called recursively
    async function uploadSingleChunkByChunkIndex(
      chunkIdx: number,
      attachmentId?: number
    ) {
      setIsUploading(true);
      if (attachmentId && abortController.current.signal.aborted) {
        await deleteContentAttachmentMutation.mutateAsync(attachmentId);
      } else {
        setCurrentAttachmentId(attachmentId);
      }
      if (abortController.current.signal.aborted) {
        onError?.(); // Call error handler if provided
        setIsUploading(false);
        return;
      }
      // If all chunks have been uploaded, finish (recursive base case)
      if (chunkIdx === totalChunks) {
        onFinish();
        onComplete?.();
        setIsUploading(false);
        return notify.uploadContentAttachmentSuccess(attachment?.name);
      }

      // Get chunk
      const chunk = attachment.slice(
        chunkIdx * UploadConstraints.MinChunkSize,
        (chunkIdx + 1) * UploadConstraints.MinChunkSize
      );

      // Get checksum
      const chunkCheckSum = await computeFileChecksumMd5(chunk);

      // Upload chunk
      const res = await fileUploadMutation.mutateAsync({
        attachmentChunk: {
          file: chunk,
          id: contentId,
          linkedItemId: contentId,
          linkType,
          chunkNumber: chunkIdx + 1, // 1-indexed, not 0-indexed
          chunkSize: chunk.size,
          totalChunks,
          totalSize: attachment.size,
          chunkCheckSum,
          fileName: attachment?.name,
          type: attachment?.type?.includes('video')
            ? AttachmentType.Video
            : AttachmentType.File,
          attachmentId,
        },
        contentId,
        index,
      });
      setFileId(res);

      // Update progress after chunk has finished uploading
      onUpdateProgress((state) => state + 100 / totalChunks);

      // Recursively upload next chunk
      return await uploadSingleChunkByChunkIndex(
        chunkIdx + 1,
        attachmentId || res
      );
    }

    // Start uploading first chunk
    onStart();
    return await uploadSingleChunkByChunkIndex(0);
  }

  const cancelUpload = async () => {
    try {
      abortController.current.abort();
      notify.uploadContentAttachmentCancellation();
      onFinish();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    cancelUpload,
    chunkUpload,
    multipleUploadProgress,
    fileId,
    uploadProgress,
  };
}

export function useChunkUploadUtils() {
  const [uploadProgress, setUploadProgress] = useState<undefined | number>(
    undefined
  );
  return {
    uploadProgress,
    onStart: () => setUploadProgress(0),
    onUpdateProgress: setUploadProgress,
    onFinish: () => setUploadProgress(undefined),
  };
}
