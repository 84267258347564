import {ResourceSkillType} from './../enums';
import {SkillDetail, SkillSearchResponse} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {SkillSearchResponseSchema} from '../zodSchemas/SkillSearchResponseSchema.schema';
import {SkillDetailSchema} from '../zodSchemas/SkillDetailSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/SkillsController.cs

/*
|--------------------------------------------------------------------------
|  {resourceId}/associated/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillsAssociatedByIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skillsAssociatedById,
 *    isLoading: isSkillsAssociatedByIdLoading,
 *    invalidateExact: invalidateSkillsAssociatedById,
 *  } = useSkillsAssociatedByIdQuery({
 *    resourceId,
 *    type
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useSkillsAssociatedByIdQuery = queryHookFactory<
  {resourceId: number; type: ResourceSkillType}, // path variables
  SkillDetail[] // return type
>(
  'useSkillsAssociatedByIdQuery',
  ({resourceId, type}) => `skills/${resourceId}/associated/${type}`,
  {responseSchema: z.array(SkillDetailSchema)}
);

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillsSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skillsSearch,
 *    isLoading: isSkillsSearchLoading,
 *    invalidateExact: invalidateSkillsSearch,
 *  } = useSkillsSearchQuery({
 *    queryParams: {
 *        skip,
 *        take,
 *        term
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useSkillsSearchQuery = queryHookFactory<
  {queryParams?: {skip?: number; take?: number; term?: string}},
  SkillSearchResponse // return type
>(
  'useSkillsSearchQuery',
  ({queryParams}) => addQueryParams(() => `skills/search`, null, queryParams),
  {responseSchema: SkillSearchResponseSchema}
);
