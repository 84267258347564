// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/Requests/LxpContentSearchRequest.cs

import {z} from 'zod';

export const LxpContentSearchRequestSchema = z.object({
  boostRecent: z.boolean(),
  facets: z.array(z.any()).nullable().optional(),
  pageNumber: z.number(),
  pageSize: z.number().nullable().optional(),
  searchText: z.string(),
});
