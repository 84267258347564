import {LayoutConfiguration} from '@learnin-inc/apollo-react-cdk';

import {NavigationState, navigationToAspect} from './branding';
import {TranslateFn} from './models';
import {LxpAuthUser, LxpSupportInfo} from './models/lxp';

import {
  AcmOptions,
  LayoutFilterOptions,
  LxpOptions,
} from './layout-configuration.model';

/**
 * For any `i18n` field, replace the associated field with translated value
 * @returns LayoutConfiguration
 */
export function updatei18n(
  configuration: LayoutConfiguration,
  translate: TranslateFn
): LayoutConfiguration {
  const MAPPINGS = {
    i18n: 'text',
    headerTitleI18n: 'headerTitle',
    buttonI18n: 'buttonText',
    titleI18n: 'titleText',
  };
  const i18n = i18nWithLog(translate);
  const visitor = (node: LayoutNode, key: string) => {
    // For each node, check if it has an translation field
    switch (key) {
      case 'i18n':
      case 'headerTitleI18n':
      case 'buttonI18n':
      case 'titleI18n': {
        const field = MAPPINGS[key];
        node[field] = i18n(node[key], node[field]);
        break;
      }
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Apply feature flags, org permissions, and user settings.
 * Note: This configuration has three aspects: ACM Admin, ACM Learner, and LXP Admin
 */
export const updateWithFilters = (
  configuration: LayoutConfiguration,
  {acm, lxp}: LayoutFilterOptions
) => {
  if (acm) {
    configuration = updateAcmLearnerNavigation(configuration, acm); // Aspect comes from acm.json
    configuration = updateAcmAdminNavigation(configuration, acm); // Aspect comes from acm.json
  }

  configuration = updateLxpAdminNavigation(configuration, lxp); // Aspect comes from lxp.json, acm-lxp.json or skills-platform.json

  return configuration;
};

/**
 * Replace all `<user>` tokens with current user's vanityUrl from their viewerProfile
 * * Replace all `<orgId>` tokens with current user's defaultOrgId
 * @param configuration LayoutConfiguration
 * @param authUser AuthUser
 */
export function updateUser(
  configuration: LayoutConfiguration,
  authUser: LxpAuthUser
): LayoutConfiguration {
  const visitLinks = (node: LayoutNode, key: string) => {
    switch (key) {
      case 'href':
      case 'routerLink': {
        const text: string | undefined = node[key];
        if (text?.includes('<user>') && authUser.viewerProfile)
          node[key] = text.replace('<user>', authUser.viewerProfile.vanityUrl);
        if (text?.includes('<orgId>'))
          node[key] = text.replace('<orgId>', authUser.defaultOrgId.toString());
        break;
      }
    }
  };

  if (authUser) configuration = traverse(configuration, visitLinks);

  return configuration;
}

export function updateNavigationBranding(
  config: LayoutConfiguration,
  branding: NavigationState | undefined
): LayoutConfiguration {
  if (branding) {
    config.admin = config.admin
      ? navigationToAspect(branding, config.admin)
      : undefined;
    config.learner = config.learner
      ? navigationToAspect(branding, config.learner)
      : undefined;
  }
  return config;
}

/**
 * config = updateHelpMenu(config, organizationId, helpMenu)
 * Update the help menu items based on the organization's support info
 */
export function updateHelpMenu(
  configuration: LayoutConfiguration,
  organizationId: number | null,
  supportInfo: Partial<LxpSupportInfo>
) {
  if (!organizationId) supportInfo = {phone: '800.311.7061'};

  const visitDGATs = (node: LayoutNode, key: string, parent?: LayoutNode) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateByKey = (field: string, value: any) => {
      node[field] = value;
      !value && deleteFromParent(node, parent);
    };
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.help.knowledge-center':
        case 'global.navigation.learner.sidebar.help.knowledge-center':
          updateByKey('href', supportInfo?.helpLink);
          break;
        case 'global.navigation.admin.sidebar.help.faq':
        case 'global.navigation.learner.sidebar.help.faq':
          updateByKey('href', supportInfo?.faq);
          break;
        case 'global.navigation.admin.sidebar.help.cookie-notice':
        case 'global.navigation.learner.sidebar.help.cookie-notice':
          !supportInfo?.showCookieLink && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.help.custom-link':
        case 'global.navigation.learner.sidebar.help.custom-link':
          node.text = supportInfo?.customText || '';
          node.href = `tel:${supportInfo?.phone}` || '';
          !supportInfo?.customLink && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.help.support-phone':
        case 'global.navigation.learner.sidebar.help.support-phone':
          node.text = supportInfo?.phone || '';
          node.href = `tel:${supportInfo?.phone}`;
          !supportInfo?.phone && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.help.support-email':
        case 'global.navigation.learner.sidebar.help.support-email': {
          const hasEmail =
            supportInfo?.email && !supportInfo?.email.includes('@degreed.com');
          node.text = hasEmail ? supportInfo?.email : '';
          node.href = hasEmail ? `mailto:${supportInfo?.email}` : '';
          !hasEmail && deleteFromParent(node, parent);
          break;
        }
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

// *************************************************
// Private Utilities
// *************************************************

/**
 * Update the Degreed "Admin" view (instead of the Learner view) items requiring admin access based on feature flags and user permissions
 */
function updateLxpAdminNavigation(
  configuration: LayoutConfiguration,
  lxp: LxpOptions
) {
  const {authUser, orgInfo, featureFlags} = lxp;
  const permissions = orgInfo.permissions;
  const settings = orgInfo.settings;

  const canShowAnalytics =
    featureFlags.insightSkillAnalytics &&
    (settings.enableAdvancedSkillAnalytics
      ? settings.enableAdvancedSkillAnalytics
      : false);

  const canShowFileUpload =
    !settings.disableBulkUpload &&
    !settings.isClientProvider &&
    !settings.skillAnalyticsClient &&
    !settings.skillInventoryClient &&
    authUser?.canBulkUpload &&
    (permissions.uploadCompletions ||
      permissions.uploadContent ||
      permissions.uploadGroups ||
      permissions.uploadOpportunities ||
      permissions.uploadRequiredLearning ||
      permissions.uploadUserUpdates ||
      permissions.uploadClientUser ||
      permissions.uploadRoles ||
      permissions.uploadSkills);

  const canShowManageMembers = () => {
    const membersPermission = permissions.viewMembers;
    const segmentsPermission =
      permissions.manageSegments && featureFlags.orgManagementSegmentUI;
    return membersPermission || segmentsPermission;
  };

  const canShowSkillsStandards = () => {
    const skillsPermission =
      !!settings.useInternalJobSkills && !!permissions.manageSkills;
    const skillsStandard = permissions.manageSkillStandards;
    return skillsPermission || skillsStandard;
  };

  const canShowSettingsSecurity =
    featureFlags.sessionManagement && permissions.editSettings;

  const skillsClient =
    settings.skillInventoryClient || settings.skillAnalyticsClient;
  const showAcademies = settings.enableAcademies;
  const showExtendedEnterprise = authUser?.hasChannel;
  // TODO: Need this to always be true if in the Skills Platform app
  const showDegreedSkills = true; // settings.enableSkillsPlatform && permissions.manageSkillsPlatform;
  const showRoles =
    settings.enableCareerPathing &&
    settings.supportTargets &&
    permissions.manageTargets;
  const showReports =
    ((settings.enableReportingInApp && authUser?.canViewReporting) ||
      permissions.manageReportingFTPScheduler) &&
    !skillsClient;
  const showInsights = permissions.viewReports;
  const showManagePlans =
    permissions.manageTargets && settings.enableCareerPathing;
  const showManageContent = permissions.manageContent;
  const showOrgGroups = permissions.manageGroups && !skillsClient;
  const showPathways = permissions.managePathways && !skillsClient;
  const showSkillSettings =
    (permissions.editSettings || permissions.editPermissions) && !skillsClient;
  const showAutomations = permissions.manageBusinessRules;
  const showManageMembers = canShowManageMembers();
  const showAnalytics = canShowAnalytics;
  const showSkillStandards = canShowSkillsStandards();
  const showFileUpload = canShowFileUpload;
  const showSessionManagement = canShowSettingsSecurity;
  const showContentMarketplace = false; // TODO: Grab from ACM settings

  const visitDGATs = (node: LayoutNode, key: string, parent?: LayoutNode) => {
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.dashboard.learning':
          !showInsights && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.skills.dashboard':
          !showDegreedSkills && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.scales':
          !showDegreedSkills && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.inventory':
          !showDegreedSkills && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.roles':
          !showRoles && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.skill-standards':
          !showSkillStandards && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.settings':
          !showSkillSettings && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.people.groups':
          !showOrgGroups && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.people.users':
          !showManageMembers && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.catalog.plans':
          !showManagePlans && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.catalog.pathways':
          !showPathways && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.catalog.content':
          !showManageContent && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.catalog.academies':
          !showAcademies && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.reporting.reports':
          !showReports && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.reporting.advanced-analytics':
          !showAnalytics && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.automations':
          !showAutomations && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.integrations.file-log':
          !showFileUpload && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.extended-enterprise':
          !showExtendedEnterprise && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebar.settings.security':
          !showSessionManagement && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.content-marketplace':
          !showContentMarketplace && deleteFromParent(node, parent);
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

/**
 * Update the ACM "Learner" navigation items based on feature flags and user permissions
 */
function updateAcmLearnerNavigation(
  configuration: LayoutConfiguration,
  acm: AcmOptions
) {
  const {
    isFeatureFlagOn,
    incentivesCount,
    incentivesCountIsSuccess,
    showPrograms,
    isAcademiesOn,
    academiesCount,
    academiesCountIsSuccess,
    user,
    isApprover,
    customProgramPermissions,
    academyPermissions,
    isProduction,
    isBeta,
  } = acm;
  const showMarketplace = incentivesCountIsSuccess && showPrograms.any;
  const showAcademies =
    isAcademiesOn &&
    academiesCountIsSuccess &&
    !!academiesCount?.publishedAcademiesAvailableToUserCount;
  const showTeamInsights = user?.isManager && isFeatureFlagOn.TeamInsights;
  const showManagerProgramApprovals =
    (isApprover &&
      !!incentivesCount?.programIncentivesAvailableToUsersWithManagerApprovalCount) ||
    !!incentivesCount?.academiesWithCurrentUserAsAssignedApprover;
  const showManagerResourceApprovals =
    isApprover &&
    !!incentivesCount?.resourcesAvailableToUsersWithManagerApprovalCount;
  const showPermissions =
    customProgramPermissions?.length ||
    (academyPermissions?.length && isFeatureFlagOn.Academies);
  const showAdminSwitcher = user?.isAdmin ?? false;
  const showQa = !isProduction && !isBeta;
  const showDeveloperAdmin = !isProduction && !isBeta;

  const visitDGATs = (node: LayoutNode, key: string, parent?: LayoutNode) => {
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.sidebar.marketplace':
          !showMarketplace && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.academies':
          !showAcademies && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.team-insights':
          !showTeamInsights && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.approvals':
          !showManagerProgramApprovals &&
            !showManagerResourceApprovals &&
            deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.approvals.programs':
          !showManagerProgramApprovals && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.approvals.resources':
          !showManagerResourceApprovals && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.permissions':
          !showPermissions && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.qa':
          !showQa && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.developer-admin':
          !showDeveloperAdmin && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.features.show-admin-view':
          node.visible = showAdminSwitcher;
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

/**
 * Update the ACM "Admin" view (instead of the Learner view) items requiring admin access based on feature flags and user permissions
 */
function updateAcmAdminNavigation(
  configuration: LayoutConfiguration,
  acm: AcmOptions
) {
  const {isFeatureFlagOn, learningBudgetInfo, showPrograms, showResources} =
    acm;
  const showAcademies = isFeatureFlagOn.Academies;
  const showLicenses = isFeatureFlagOn.Licenses;
  const showIncentives = isFeatureFlagOn.Incentives;

  const virtualCard = 'Prepayment';
  const isVirtualCard = learningBudgetInfo?.[0]?.incentiveType === virtualCard;
  const showAdminSettings = !isFeatureFlagOn.HideAdminSettings && isVirtualCard;

  const visitDGATs = (node: LayoutNode, key: string, parent?: LayoutNode) => {
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.learning.academies':
          !showAcademies && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.licenses':
          !showLicenses && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.incentives':
          !showIncentives && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.admin-settings':
          !showAdminSettings && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.approvals':
          !showPrograms && !showResources && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.approvals.programs':
          !showPrograms && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.approvals.resources':
          !showResources && deleteFromParent(node, parent);
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

/**
 * Translation util that logs missing translations
 * Keys can be comma-separated to try multiple keys until a valid translation is found
 * Note: Left-most key is the most recent... right-most key is the fallback
 */
const i18nWithLog =
  (translate: TranslateFn) => (key: string, fallback: string) => {
    const allKeys = key.replace(/\s+/g, '').split(','); // This will remove all whitespace

    // Find "most recent" translation
    let value = '';
    let selectedKey = '';

    for (let i = 0; i < allKeys.length; i++) {
      if (!value && allKeys[i]) {
        selectedKey = allKeys[i];
        value = translate(fallback, selectedKey) || '';

        // If ngx/translate returns the key, it means the translation is missing
        if (value === selectedKey) value = '';
      }
    }

    if (!value) {
      console.warn(`i18n: ${selectedKey} using fallback: ${fallback}`);
      value = fallback;
    }

    return value || fallback;
  };

export const validateConfiguration = (configuration: LayoutConfiguration) => {
  const filterNulls: Visitor = (node: LayoutNode, key: string) => {
    switch (key) {
      case 'subItems':
      case 'children':
      case 'top':
      case 'bottom':
        node[key] = node[key].filter((item: unknown) => item !== null);
        break;
    }
  };

  return traverse(clearRedactionQueue(configuration), filterNulls);
};

// *************************************************
// Tree Utils
// *************************************************

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LayoutNode = Record<string, any>;
type Visitor = (node: LayoutNode, key: string, parent?: LayoutNode) => void;

// Depth-first node tree scanning
const traverse = (node: LayoutNode, visit: Visitor, parent?: LayoutNode) => {
  for (const key in node) {
    if (typeof node[key] === 'object') {
      visit(node, key, parent);
      traverse(node[key], visit, node);
    } else {
      visit(node, key, parent);
    }
  }
  return node;
};

/**
 * Remove node reference from parent...
 *
 * NOTE: this destructive process is allowed due to the nature
 * of the visitor pattern and tree traversal.
 */

let queue: (() => void)[] = [];

/**
 * Defer the removal of a node from its parent until the end of the traversal
 */
const deleteFromParent = (node: LayoutNode, parent?: LayoutNode) => {
  if (!parent) throw new Error('Parent node is required to remove a node');

  const removeNode = () => {
    const key = Object.keys(parent).find((key) => parent[key] === node);
    if (key) {
      if (Array.isArray(parent)) {
        parent.splice(+key, 1);
      } else delete parent[key];
    }
  };
  queue.push(removeNode);
};

/**
 * Execute all deferred removals
 */
const clearRedactionQueue = (configuration: LayoutConfiguration) => {
  queue.forEach((fn) => fn());
  queue = [];

  return configuration;
};
