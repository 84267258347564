// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramSearchVM.cs

import {z} from 'zod';
import {ProgramSearchCategorySchema} from './ProgramSearchCategorySchema.schema';
import {ProgramSearchResultSchema} from './ProgramSearchResultSchema.schema';

export const ProgramSearchVMSchema = z.object({
  category: ProgramSearchCategorySchema,
  count: z.number(),
  programs: z.array(ProgramSearchResultSchema),
});
