import Color from 'color';
import {COLORS} from '@utils/constants';
import {DefaultTheme} from 'styled-components';

export const fadeDown = (value) => Color(value).fade(0.5);

export const isHex = (value: string) => {
  const hex = /^#([0-9a-f]{6}){1,2}$/i;
  return hex.test(value);
};

export const convertHexToHSL = (hex: string, alpha: number) => {
  return Color(hex).alpha(alpha).hsl().string();
};

export const getAlphanumericString = (color: string) =>
  color
    ?.toString()
    .replace(/[^A-Za-z\d]/g, '')
    .toUpperCase();

export const determineBlackOrWhite = (value) => {
  return Color(value).isLight() ? COLORS.Black : COLORS.White;
};

export const determineColorShade = (value) => {
  const color = Color(value);
  const luminosity = color.luminosity();
  const multiplier = (-Math.abs(luminosity - 0.5) + 0.6) * 0.65;
  return color.isLight()
    ? color.darken(multiplier).hex()
    : color.lighten(multiplier).hex();
};

export const theme: DefaultTheme = {
  // Your Brand
  primaryBrandColor: COLORS.Neutral950,
  secondaryBrandColor: COLORS.Red500,
  // Navigation
  backgroundColor: COLORS.Neutral950,
  itemDefaultTextNavColor: COLORS.White,
  itemHoverTextNavColor: COLORS.White,
  itemHoverBackgroundNavColor: COLORS.Neutral900,
  itemSelectedTextNavColor: COLORS.White,
  itemSelectedBackgroundNavColor: COLORS.Red500,
  // Welcome Screen
  buttonDefaultWelcomeColor: COLORS.Blue800,
  buttonHoverWelcomeColor: COLORS.Blue700,
  backgroundWelcomeColor: COLORS.Neutral950,
  itemDefaultTextWelcomeColor: COLORS.White,
};

export enum AvatarColorWays {
  Green,
  Red,
  Blue,
  Purple,
  Grey,
  Yellow,
}

export const getAvatarColorwaysStyles = (
  idxOrSeed?: AvatarColorWays | number
) => {
  const totalEnums = Object.keys(AvatarColorWays).length / 2;
  const seed = Number.isInteger(idxOrSeed)
    ? idxOrSeed
    : Math.floor(Math.random() * totalEnums);

  const idx = seed % totalEnums;
  return {
    [AvatarColorWays.Green]: {
      backgroundColor: COLORS.Green200,
      color: COLORS.Green900,
    },
    [AvatarColorWays.Red]: {
      backgroundColor: COLORS.Red100,
      color: COLORS.Red800,
    },
    [AvatarColorWays.Blue]: {
      backgroundColor: COLORS.Blue100,
      color: COLORS.Blue800,
    },
    [AvatarColorWays.Purple]: {
      backgroundColor: COLORS.Purple100,
      color: COLORS.Purple800,
    },
    [AvatarColorWays.Grey]: {
      backgroundColor: COLORS.Neutral100,
      color: COLORS.Neutral900,
    },
    [AvatarColorWays.Yellow]: {
      backgroundColor: COLORS.Yellow100,
      color: COLORS.Yellow800,
    },
  }[idx];
};

/*
|--------------------------------------------------------------------------
| Academy Branding
|--------------------------------------------------------------------------
*/

export const getAcademyBrandingBorderColor = (
  itemDefaultTextNavColor: string
) => {
  return convertHexToHSL(itemDefaultTextNavColor, 0.1);
};

export const getAcademyBrandingBackgroundColor = (
  primaryBrandColor: string,
  degrees: number
) => {
  return `linear-gradient(${degrees}deg, 
    ${primaryBrandColor},
    ${determineColorShade(primaryBrandColor)}
  )`;
};
