import {useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import styled from 'styled-components';

const NoScrollContainer = styled.div`
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export interface NoScrollBarProps {
  children?: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

export function NoScrollBar({children, style}: NoScrollBarProps) {
  return <NoScrollContainer style={style}>{children}</NoScrollContainer>;
}
