import './NotificationContent.scss';
import {i18n, k} from '@i18n/translate';
import {message} from 'antd';
import {COLORS} from '@utils/constants';

const NotificationContent = (msgStr, link?, linkName?) => {
  return (
    <figure
      role="alert"
      aria-live="assertive"
      // This isn't a focusable element. However, the tabIndex is left here
      // with the thought that some screen readers may need it in order to gain
      // focus of this area.
      //
      // If it is not needed, we should remove tabIndex here since this is the
      // wrong role for a focusable element.
      //
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 0,
      }}>
      <figcaption>{msgStr}</figcaption>
      <button
        onClick={() => {
          message.destroy();
        }}
        style={{color: COLORS.Blue100}}
        className="btn-dismiss">
        {i18n.t(k.CTA__DISMISS).toLocaleUpperCase()}
      </button>

      {link && (
        <a
          onClick={() => {
            message.destroy();
          }}
          href={link}
          className="btn-link">
          {linkName}
        </a>
      )}
    </figure>
  );
};

export default NotificationContent;
