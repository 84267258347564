// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/DurationUnitType.cs

enum DurationUnitType {
  Days,
  Weeks,
  Months,
  Years,
  Hours,
}

export default DurationUnitType;
