// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProjectAdminVM.cs

import {z} from 'zod';
import {SubmissionTypeSchema} from './SubmissionTypeSchema.schema';

export const ProjectAdminVMSchema = z.object({
  dueDateTimeZone: z.string().nullable().optional(),
  dueDateUtc: z.string().nullable().optional(),
  id: z.number(),
  numberOfParticipants: z.number(),
  numberOfSubmissions: z.number(),
  sectionOrder: z.number(),
  stepOrder: z.number(),
  submissionType: SubmissionTypeSchema,
  title: z.string(),
});
