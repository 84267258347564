import {i18n, k} from '@i18n/translate';
import {EmailPreviewViewProps} from './EmailPreview.interfaces';
import {
  EmailPreviewActionButton,
  EmailPreviewActionButtons,
  EmailPreviewActionsInner,
  EmailPreviewActions,
  EmailPreviewIframe,
  EmailPreviewLearnInSelect,
  EmailPreviewWrapper,
} from './EmailPreview.styled';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {CopyToClipboard} from '@blocks/CopyToClipboard';

export const EmailPreviewView = ({
  emailTemplates,
  previewHtml,
  selectedTemplate,
  handleTemplateSelected,
}: EmailPreviewViewProps) => {
  return (
    <EmailPreviewWrapper>
      <EmailPreviewActions>
        <EmailPreviewActionsInner>
          <EmailPreviewLearnInSelect
            showSearch
            value={selectedTemplate}
            onChange={handleTemplateSelected}
            placeholder={i18n.t(k.GENERIC__SELECT_ONE)}>
            {emailTemplates?.map((template) => {
              return (
                <LearnInSelectOption key={template.id} value={template.id}>
                  {template.label}
                </LearnInSelectOption>
              );
            })}
          </EmailPreviewLearnInSelect>
          <EmailPreviewActionButtons>
            <LearnInTooltip
              title={i18n.t(k.CTA__COPY_TO_CLIPBOARD)}
              childHasButton={true}>
              <EmailPreviewActionButton
                tag={ButtonTags.SecondaryRemovedBorder}
                htmlType="button">
                <CopyToClipboard
                  ariaLabel={i18n.t(k.CARD__CLIPBOARD_CVC)}
                  text={previewHtml}
                />
              </EmailPreviewActionButton>
            </LearnInTooltip>
          </EmailPreviewActionButtons>
        </EmailPreviewActionsInner>
      </EmailPreviewActions>
      <EmailPreviewIframe title="" id="email-preview"></EmailPreviewIframe>
    </EmailPreviewWrapper>
  );
};
