// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramCohortSearchVM.cs

import {z} from 'zod';
import {CustomProgramStatusSchema} from './CustomProgramStatusSchema.schema';

export const CustomProgramCohortSearchVMSchema = z.object({
  endDateUtc: z.string(),
  id: z.number().nullable().optional(),
  parentCustomProgramId: z.number(),
  restrictedAccess: z.boolean(),
  startDateUtc: z.string(),
  status: CustomProgramStatusSchema,
});
