import {CurrencyCode} from '@generated/enums';
import {getCurrencyExchangeRq} from '@store/apiEndpoints';
import {useQuery, UseQueryResult, UseQueryOptions} from 'react-query';
import {cacheForHours, simpleInvalidateExactQueryFn} from '@store/queryClient';
import {CurrencyRatesVM} from '@models/clientModels';
import {useGetEffectiveExchangeRate, MONEY_DEFAULT} from '@utils/moneyUtils';

export type UseCurrencyRateQueryReturnType = UseQueryResult<
  CurrencyRatesVM[]
> & {
  invalidate: () => void;
};

/** Provides the full exchange-rate table.
 * Consider `useGetCurrencyExchangeQuery` instead for most common use-cases
 * */
export function useGetCurrencyExchangeQuery(
  options?: UseQueryOptions<CurrencyRatesVM[]>
): UseCurrencyRateQueryReturnType {
  const onSuccess = options?.onSuccess;
  const currencyExchangeRq = getCurrencyExchangeRq();
  const currencyExchangeQuery = useQuery<CurrencyRatesVM[]>(
    currencyExchangeRq.queryKey,
    {
      ...options,
      ...cacheForHours(24),
      onSuccess: (data) => !!onSuccess && onSuccess(data),
    }
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(currencyExchangeRq.queryKey);

  return {...currencyExchangeQuery, invalidate};
}

/** Get the user's effective exchange rate in normal conditions */
export function useExchangeRate(
  currency: CurrencyCode = MONEY_DEFAULT.currency,
  usersCurrency: CurrencyCode = MONEY_DEFAULT.currency
): number {
  return useGetEffectiveExchangeRate(currency, usersCurrency);
}
