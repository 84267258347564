// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Audience/BusinessGoal.cs

import {z} from 'zod';
import {BusinessGoalTypeSchema} from './BusinessGoalTypeSchema.schema';

export const BusinessGoalVMSchema = z.object({
  featuredOnCard: z.boolean(),
  newRole: z.string(),
  retentionValueMultiplier: z.number(),
  topic: z.string(),
  type: BusinessGoalTypeSchema,
  updatedOn: z.string(),
  value: z.number(),
});
