import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
} from 'react-query';
import {simpleQueryFn, simpleInvalidateExactQueryFn} from '@store/queryClient';
import {ReactQueryRequest} from '@store/apiEndpoints';
import queryClient, {
  simpleMutationFn,
  simpleDeleteFn,
  simplePutFn,
  simplePatchFn,
} from '@store/queryClient';
import {AxiosError} from 'axios';
import querystring from 'querystring';

/*
|--------------------------------------------------------------------------
| Query Hook Factory
|--------------------------------------------------------------------------
*/

interface QueryHookOptions<ReturnData> {
  defaultQueryOptions?: UseQueryOptions<ReturnData>;
  onSuccessNotification?: (data: ReturnData) => void;
  onErrorNotification?: (error: any) => void;
}

export type QueryHookReturnType<ReturnData> = UseQueryResult<ReturnData> & {
  invalidateExact: () => void;
  cacheData: ReturnData | undefined;
};

export function queryHookFactory<PathVars, ReturnData>(
  apiEndpointRq: (pathVars: PathVars) => ReactQueryRequest | string,
  options?: QueryHookOptions<ReturnData>
) {
  return (
    pathVars: PathVars,
    queryOptions?: UseQueryOptions<ReturnData> & {component?: string}
  ): QueryHookReturnType<ReturnData> => {
    const queryRqOrPath = apiEndpointRq(pathVars);

    // This handles legacy apiEndpoint functions and simple url functions
    const isApiFunctionSimple = typeof queryRqOrPath === 'string';
    const path = !isApiFunctionSimple ? queryRqOrPath.path : queryRqOrPath;
    const queryKey = !isApiFunctionSimple
      ? queryRqOrPath.queryKey
      : queryRqOrPath.split('/');

    const query = useQuery<ReturnData>(queryKey, () => simpleQueryFn(path), {
      // Template options, shared by all instances
      ...options?.defaultQueryOptions,

      // Instance options
      ...queryOptions,

      onSuccess: (data) => {
        // Show notification

        // if (registry[name].success) {
        //   onSuccessNotification?.(translate(registry[name].success || Default message));
        // }
        options?.onSuccessNotification?.(data);

        // Call instance onSuccess
        queryOptions?.onSuccess?.(data);
      },

      onError: (error: AxiosError) => {
        // Show notification
        options?.onErrorNotification?.(error);

        // Call instance onError
        queryOptions?.onError?.(error);
      },
    });

    // Helper function to mark the data as stale and refetch lazily
    const invalidateExact = async () =>
      await simpleInvalidateExactQueryFn(queryKey);

    return {
      ...query,
      invalidateExact,
      cacheData: queryClient.getQueryData(queryKey),
    };
  };
}

/*
|--------------------------------------------------------------------------
| Query Mutation Factory
|--------------------------------------------------------------------------
*/

export enum MutationMethods {
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}

interface MutationHookOptions<ReturnData, Payload> {
  defaultMutationOptions?: UseMutationOptions<ReturnData, unknown, Payload>;
  onSuccessNotification?: (
    data?: ReturnData,
    variables?: Payload,
    context?: unknown
  ) => void;
  onErrorNotification?: (
    data?: ReturnData,
    variables?: Payload,
    context?: unknown
  ) => void;
}

export interface HookMutationArgs<Payload, PathVars> {
  payload?: Payload;
  pathVars?: PathVars;
}

export function mutationHookFactory<
  Payload,
  PathVars = undefined,
  ReturnData = undefined
>(
  method: MutationMethods,
  pathOrPathGetter: string | ((args: PathVars) => string),
  options?: MutationHookOptions<ReturnData, HookMutationArgs<Payload, PathVars>>
) {
  return (
    mutationOptions?: UseMutationOptions<
      ReturnData,
      unknown,
      HookMutationArgs<Payload, PathVars>
    >
  ): UseMutationResult<
    ReturnData,
    unknown,
    {payload?: Payload; pathVars?: PathVars} // same as HookMutationArgs<Payload, PathVars>, but more readable in the linter
  > => {
    return useMutation(
      ({payload, pathVars}: HookMutationArgs<Payload, PathVars>) => {
        const path =
          typeof pathOrPathGetter === 'string'
            ? pathOrPathGetter
            : pathOrPathGetter(pathVars);

        const mutationFn = (() => {
          switch (method) {
            case MutationMethods.Post:
              return simpleMutationFn;
            case MutationMethods.Put:
              return simplePutFn;
            case MutationMethods.Delete:
              return simpleDeleteFn;
            case MutationMethods.Patch:
              return simplePatchFn;
          }
        })();
        return mutationFn<ReturnData>(path, payload);
      },
      {
        // Template Options, shared by all instances
        ...options?.defaultMutationOptions,

        // Instance Options
        ...mutationOptions,

        onSuccess: (data, variables, context) => {
          // Show Notification
          options?.onSuccessNotification?.(data, variables, context);

          // Call instance onSuccess
          mutationOptions?.onSuccess?.(data, variables, context);
        },
        onError: (error: AxiosError, variables, context) => {
          // Show notification
          options?.onErrorNotification?.();

          // Call instance onError
          mutationOptions?.onError?.(error, variables, context);
        },
      }
    );
  };
}

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

export const addQueryParams = (
  endpointFunction: (args: any) => string,
  pathVars: Record<string, string | number> | null,
  queryParams?: Record<string, string | number>
): string => {
  let url = endpointFunction(pathVars);
  if (!!queryParams && Object.keys(queryParams).length) {
    const queryParamsStr = querystring.stringify(queryParams);
    url = url + '?' + queryParamsStr;
  }
  return url;
};
