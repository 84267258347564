export enum ContainerTags {
  ModalProgramCard = 'modalProgramCard',
  MoneyIncentiveModalCard = 'moneyIncentiveModalCard',
  SelectedMoneyIncentiveModalCard = 'selectedMoneyIncentiveModalCard',
  DisabledMoneyIncentiveModalCard = 'disabledMoneyIncentiveModalCard',
  CommonCard = 'commonCard',
  EmptyApprovalContainer = 'emptyApprovalContainer',
  PersonalLearningBudgetGauge = 'personalLearningBudgetGauge',
  BadgeContainer = 'badgeContainer',
  MenuItemBadgeContainer = 'menuItemBadgeContainer',
  AddFundsButtonContainer = 'addFundsButtonContainer',
  TableBottomPadding = 'tableBottomPadding',
  ButtonUploadContainer = 'buttonUploadContainer',
  ProgramStatusCardContainer = 'programStatusCardContainer',
  ProgressBarSectionContainer = 'progressBarSectionContainer',
}
