// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/ApprovalConfig.cs

import {z} from 'zod';

export const ApprovalConfigSchema = z.object({
  adminPreApprovalRequired: z.boolean(),
  adminRedemptionApprovalRequired: z.boolean(),
  managerPreApprovalRequired: z.boolean(),
  managerRedemptionApprovalRequired: z.boolean(),
});
