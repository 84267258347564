// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramSectionVM.cs

import {z} from 'zod';

export const CustomProgramSectionVMSchema = z.object({
  id: z.number(),
  isFullScreen: z.boolean(),
  order: z.number(),
  title: z.string(),
});
