// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanFinanceItemVM.cs

import {z} from 'zod';
import {UserPlanItemVMSchema} from './UserPlanItemVMSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';
import {ReimbursementPreviewVMSchema} from './ReimbursementPreviewVMSchema.schema';

const schema = z.object({
  amount: z.number(),
  applicationUrl: z.string(),
  balance: z.number().nullable().optional(),
  currency: CurrencyCodeSchema,
  financeIncentiveId: z.number().nullable().optional(),
  financeType: FinanceTypeSchema,
  reimbursements: z.array(ReimbursementPreviewVMSchema),
});

export const UserPlanFinanceItemVMSchema = UserPlanItemVMSchema.merge(schema);
