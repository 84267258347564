import {i18n, k} from '@i18n/translate';
import {
  RequestMenuButtonAndDrawerList,
  RequestMenuButtonAndDrawerListContainer,
  RequestMenuButtonAndDrawerListItem,
  RequestMenuButtonAndDrawerParagraphText,
} from './RequestMenu.styled';
import {RequestMenuProps} from './RequestMenu.interfaces';
import {
  REQUEST_MENU_FOOTER_TEST_ID,
  REQUEST_MENU_HIDDEN_CLASS,
} from './RequestMenu.constants';
import RequestMenuOption from '../RequestMenuOption/RequestMenuOption.view';
import {RequestMenuButtonAndDrawerMarketplaceParagraph} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import {getUserMarketplacePath} from '@utils/ClientPaths';
import {dangerouslyNoEscape} from '@utils/l10nUtils';

function RequestMenu({
  handleOnClickMarketplaceLink,
  handleOnClickOption,
  marketplaceLinkText,
  requestTypeOptions,
  selectionIndex,
  showFooter,
}: RequestMenuProps) {
  return (
    <RequestMenuButtonAndDrawerListContainer>
      <RequestMenuButtonAndDrawerParagraphText
        className={selectionIndex !== null ? REQUEST_MENU_HIDDEN_CLASS : ''}>
        {i18n.t(k.REQUEST__NEW__SELECT)}
      </RequestMenuButtonAndDrawerParagraphText>
      <RequestMenuButtonAndDrawerList>
        {requestTypeOptions?.map((option, idx) => (
          <RequestMenuButtonAndDrawerListItem
            key={option.id}
            aria-hidden={selectionIndex !== null && selectionIndex !== idx}
            className={
              selectionIndex !== null && selectionIndex !== idx
                ? REQUEST_MENU_HIDDEN_CLASS
                : ''
            }>
            <RequestMenuOption
              data-cy={'newRequestButton'}
              option={option}
              onClick={() => handleOnClickOption(option.requestType, idx)}
              selectionIndex={selectionIndex}
              selected={selectionIndex === idx}
            />
          </RequestMenuButtonAndDrawerListItem>
        ))}
      </RequestMenuButtonAndDrawerList>
      {showFooter && (
        <RequestMenuButtonAndDrawerMarketplaceParagraph
          /* XSS-safe since there's no user input interpolated.
                If that changes, this must be handled differently. */
          dangerouslySetInnerHTML={{
            __html: i18n.t(k.REQUEST__NEW__BROWSE_MARKETPLACE__FORMAT, {
              marketplaceLink: `<a href="#${getUserMarketplacePath()}">${marketplaceLinkText}</a>`,
              ...dangerouslyNoEscape,
            }),
          }}
          data-testid={REQUEST_MENU_FOOTER_TEST_ID}
          onClick={handleOnClickMarketplaceLink}
          className={selectionIndex !== null ? REQUEST_MENU_HIDDEN_CLASS : ''}
        />
      )}
    </RequestMenuButtonAndDrawerListContainer>
  );
}

export default RequestMenu;
