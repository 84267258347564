// Used to set aria-label for antd modal.
// Used to fix a11y issue with antd modals where set by labeledby was not
// reading out titles so adding the aria label to resolve the issue.
export const setModalAriaLabel = (modalTitle: string) => {
  const elements = document.getElementsByClassName('ant-modal-wrap');
  for (let i = 0; i < elements.length; i++) {
    const elementStyles = window.getComputedStyle(elements[i]);
    if (elementStyles.getPropertyValue('display') !== 'none') {
      const modalElement = elements[i].getElementsByClassName('ant-modal')[0];
      modalElement.setAttribute('aria-label', modalTitle);
      modalElement.removeAttribute('aria-labelledby');
      return;
    }
  }
};
