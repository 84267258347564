// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewApprovalRequest.cs

import {z} from 'zod';
import {PreApprovalApplicationSchema} from './PreApprovalApplicationSchema.schema';
import {PlanItemTypeSchema} from './PlanItemTypeSchema.schema';

export const PlanItemNewApprovalRequestSchema = z.object({
  applicationDetails: PreApprovalApplicationSchema,
  attachmentIds: z.array(z.string()),
  optionId: z.number(),
  planItemType: PlanItemTypeSchema,
  userBudgetId: z.number().nullable().optional(),
});
