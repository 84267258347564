// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/PrepaymentSettings/TopUpReceiptVM.cs

import {z} from 'zod';

export const TopUpReceiptVMSchema = z.object({
  accountLast4: z.string(),
  amount: z.number(),
  date: z.string(),
  description: z.string(),
  id: z.string(),
  status: z.string(),
});
