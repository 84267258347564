import styled from 'styled-components';
import {BodySpan, H3} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {MONEY_DEFAULT, formatCurrency} from '@utils/moneyUtils';
import {CurrencyCode} from '@generated/enums';

const Total = styled.div`
  display: flex;
  flex-direction: column;

  .ant-typography + h3.ant-typography {
    margin-top: 0;
  }
`;

interface TotalCostProps {
  total?: number;
  usersCurrency?: CurrencyCode;
  exchangeRate?: number;
}
export const TotalCost = ({
  total,
  usersCurrency = MONEY_DEFAULT.currency,
  exchangeRate = MONEY_DEFAULT.exchangeRate,
}: TotalCostProps) => {
  return (
    !!total && (
      <Total>
        <BodySpan color={COLORS.Neutral900} fontWeight={500}>
          {i18n.t(k.MONEY__TOTAL_REQUEST)}
        </BodySpan>
        <H3 color={COLORS.Neutral900} fontWeight={700}>
          {formatCurrency(total, usersCurrency, exchangeRate)}
        </H3>
      </Total>
    )
  );
};
