import React, {ReactNode} from 'react';
import ErrorBoundary from './ErrorBoundary';
import {datadogLogs} from '@datadog/browser-logs';

/**
 * Datadog-integrated React Error Boundary
 * Error details will be submitted to Datadog
 */
const DatadogErrorBoundary: React.FC<{
  /**
   * A single element or an array of elements representing the content to be rendered within the error boundary.
   * These elements will be displayed normally when no error occurs.
   * Note: Error boundaries only catch errors within their child components and their subtree, not within the error boundary itself.
   **/
  children: React.ReactNode | React.ReactNode[];
  /**
   * A single element or an array of elements to display when an error occurs
   */
  fallback?: ReactNode | ReactNode[];
}> = ({children, fallback}) => {
  const handleLogToDataDog = (error: Error, errorInfo: React.ErrorInfo) => {
    const combinedError = {error, errorInfo};
    datadogLogs.logger.error(error.message, combinedError);
  };
  return (
    <ErrorBoundary onCatch={handleLogToDataDog} fallback={fallback}>
      {children}
    </ErrorBoundary>
  );
};

export default DatadogErrorBoundary;
