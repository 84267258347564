import {useEffect, useRef, useState} from 'react';
import * as React from 'react';
import styled, {css, DefaultTheme} from 'styled-components';
import {Button, DatePicker, DatePickerProps} from 'antd';
import moment, {Moment} from 'moment-timezone';
import {COLORS, KEY_CODES} from '@utils/constants';
import {disableDatesInThePast} from '@utils/timeUtils';
import {i18n, k} from '@i18n/translate';
import {SizeType} from 'antd/lib/config-provider/SizeContext';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

export const StyledLearnInDatePicker = styled(DatePicker)`
  ${(props: DefaultTheme) => {
    switch (props.theme) {
      case 'link':
        return css`
          background: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
          cursor: pointer;
          caret-color: transparent; // hides blinking cursor on field

          .ant-picker-input > input {
            color: ${COLORS.Blue800};
            cursor: pointer;
            font-size: 1rem;
            &::placeholder {
              color: ${COLORS.Blue800};
            }
            &:hover,
            &:focus::placeholder {
              text-decoration: underline;
            }
          }

          .ant-picker-suffix,
          .ant-picker-clear {
            display: none;
          }
        `;
    }
  }}
`;

const ButtonStyled = styled.div`
  display: flex;
  justify-content: center;

  .ant-btn.ant-btn-link {
    color: ${COLORS.Blue800} !important;
  }

  .ant-btn:focus {
    outline: 2px solid ${COLORS.Blue950} !important;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface LearnInDatePickerProps {
  id?: string;
  value?: Moment;
  setValue?: (value: Moment) => void;
  size?: SizeType;
  disabledDate?: (currentDate: Moment) => boolean;
  placeholder?: string;
  format?: string;
  theme?: 'link' | null;
  showTodayBtn?: boolean;
}

export const LearnInDatePicker = ({
  id,
  value,
  setValue,
  size,
  disabledDate,
  placeholder,
  format,
  theme,
  showTodayBtn = true,
}: LearnInDatePickerProps) => {
  const [open, setOpen] = useState(false);
  const [tabCount, setTabCount] = useState(0);

  const todayRef = useRef(null);
  const datePickerRef = useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === KEY_CODES.TAB) {
      setTabCount((count) => count + 1);
    }
  };

  const handleKeyPressToday = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case KEY_CODES.TAB:
        datePickerRef.current.focus();
        setOpen(false);
        break;
      case KEY_CODES.ENTER:
      case KEY_CODES.SPACE:
        setValue(moment());
        setOpen(false);
        break;
    }
  };

  useEffect(() => {
    const doubleTab = tabCount === 2;
    if (doubleTab) {
      todayRef?.current?.focus();
      setTabCount(0);
    }
  }, [tabCount]);

  useEffect(() => {
    setTabCount(0);
  }, [open]);

  const renderExtraFooter = () => {
    return (
      showTodayBtn && (
        <ButtonStyled>
          <Button
            onBlur={() => {
              datePickerRef.current.focus();
            }}
            onClick={() => {
              setValue(moment());
              setOpen(false);
            }}
            onKeyDown={handleKeyPressToday}
            ref={todayRef}
            tabIndex={0}
            type="link">
            {i18n.t(k.DATE__TODAY)}
          </Button>
        </ButtonStyled>
      )
    );
  };

  const props: DatePickerProps & {
    ref: React.MutableRefObject<any>;
    theme: string;
  } = {
    id,
    open,
    disabledDate: disabledDate || disableDatesInThePast,
    onChange: (value: Moment) => {
      setValue(value);
    },
    onKeyDown: handleKeyPress,
    onOpenChange: setOpen,
    placeholder,
    format,
    theme,
    showToday: false,
    size,
    ref: datePickerRef,
    renderExtraFooter,
  };
  if (value) {
    props.value = value;
  }
  return <StyledLearnInDatePicker {...props} />;
};
