import {i18n, k} from '@i18n/translate';
import {Moment} from 'moment';
import momentTz from 'moment-timezone';
import {Maybe} from './typeUtils';
import {TimeZoneVM} from '@models/serverModels';
import {getUsersLocale} from './l10nUtils';
import {LANGUAGE_FALLBACK_LOCALE} from './constants';
import {FinanceIncentiveTypeString} from '@models/clientEnums';
import {datadogLogs} from '@datadog/browser-logs';
import moment from 'moment/min/moment-with-locales';

/** Prefer the use of "l"-based formats (marked "locale-based") */
export const DATE_FORMAT = {
  /** ↓ 07:53 PM */
  HOUR_MINUTE_24_HR_AM_PM: 'hh:mm A',

  /** ↓ 2023-02-08 */
  ISO_DATE: 'YYYY-MM-DD',

  /** ↓ Feb 13 */
  MONTH_DAY_SHORT: 'MMM DD',

  /** ↓ 2/8/2023 or 8/2/2023 (locale-based) */
  MONTH_DAY_YEAR: 'l',

  /** ↓ 02/08/2023 or 08/02/2023 (locale-based) */
  MONTH_DAY_YEAR_PADDED: 'L',

  /** ↓ Feb 8, 2023 or 8 Feb 2023 (locale-based) */
  MONTH_FULL_DAY_YEAR: 'll',

  /** ↓ February 8, 2023 or 8 February 2023 (locale-based) */
  MONTH_LONG_DAY_YEAR: 'LL',

  /** ↓ 20230213 (for numeric sorting) */
  SORTING_ONLY: 'YYYYMMDD',

  /** ↓ 7:53pm */
  TIME_12_HR: 'h:mma',

  /** ↓ 19:53 */
  TIME_24_HR_PADDED: 'HH:mm',

  /** ↓ 19:53:12 */
  TIME_WITH_SECONDS_24_HR_PADDED: 'HH:mm:ss',

  /** ↓ 2023-02-13 19:53:12  */
  TIMESTAMP: 'YYYY-MM-DD HH:mm:ss',

  /** ↓ 2023-02-13 7:53:12 pm */
  TIMESTAMP_AM_PM: 'YYYY-MM-DD h:mm:ss a',

  /** ↓ Monday */
  WEEKDAY: 'dddd',

  // ↓ 2023 */
  YEAR: 'YYYY',

  // ↓ 2023 */
  MONTH_DAY_YEAR_ABBR: 'M/D/YY',

  /** ↓ February 8, 2023 at 7:53pm */
  getMonthLongDayYearWithTime: () => {
    return `MMMM D, YYYY [${i18n.t(k.TIME__AT).toLocaleLowerCase()}] h:mma`;
  },
};

export const getMonths = (): {
  date: moment.Moment;
  i18nKey: string;
  iso: string;
  value: string;
}[] => {
  const m = [];
  for (let i = 1; i < 13; i++) {
    const asDate = moment()
      .month(i - 1)
      .startOf('month')
      .utc(true);
    m.push({
      date: asDate,
      i18nKey: `DATE__MONTH__${i}`,
      iso: asDate.toISOString(),
      value: i,
    });
  }
  return m;
};

/**
 * Parses a date from a string or else returns a default date. Either one passed in or current date if no fallback
 * provided.
 * @param dateStr string to parse to a moment date
 * @param fallbackDate Default to use if different from current date
 */
export const parseDateOrDefault = (
  dateStr: string,
  fallbackDate?: moment.Moment
): moment.Moment => {
  const potentialDate = moment(dateStr);
  if (potentialDate.isValid()) {
    return potentialDate;
  } else if (fallbackDate) {
    return fallbackDate;
  } else {
    return moment();
  }
};

export const isPaymentOrReimbursable = (
  financeType: FinanceIncentiveTypeString | string
): string => {
  // 2 equal sign instead of 3 intentional
  if (financeType == FinanceIncentiveTypeString.TuitionReimbursement) {
    return i18n.t(k.REIMBURSEMENT__REIMBURSABLE);
  } else if (financeType == FinanceIncentiveTypeString.Prepayment) {
    return i18n.t(k.CARD__VIRTUAL_CARD__AVAILABLE);
  } else {
    return '';
  }
};

// This is deprecated because the moment library is no longer needed, and instead native JS is used
// See https://momentjs.com/docs/ for more information on why to deprecate moment
export const formatDateTime = (
  date: Date | number | string,
  formatType: string
) => {
  return moment(Number(date), 'x').format(formatType);
};

/** @deprecated - Use DATE_FORMAT.MONTH_DAY_YEAR instead for l10n reasons */
export const formatDateTimeToMDYYYY = (dateTime: string) => {
  return new Date(dateTime).toLocaleDateString(getUsersLocale());
};

// Same as moment format MMMM D, YYYY
export const formatDateTimeToMMMMDYYYY = (
  date: string,
  time: string = undefined
) => {
  const dateTime = new Date(date + (time ?? ''));
  const dateString = new Date(dateTime).toLocaleDateString(getUsersLocale(), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // We need to make this i18n-friendly:
  return dateString != 'Invalid Date' ? dateString : '';
};

export const getUserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// Returns bool to check if provided value is same as current date
export const isToday = (date: Moment) =>
  moment().format(DATE_FORMAT.ISO_DATE) === date?.format(DATE_FORMAT.ISO_DATE);

// Returns number of hours between now and the provided date
export const hoursDifference = (date: string) => moment().diff(date, 'hours');

// Extracts a user friendly display name from a timezone
// (UTC-11:00) Samoa Standard Time (Midway) becomes Samoa Standard Time
export const formatTimeZoneForDisplay = (value: string): string => {
  return value.replace(/ *\([^)]*\) */g, '');
};

// Returns array of unique timezones and converts the display name to be more generic
// [
//   {displayName: '(UTC-09:00) Alaska Time (Yakutat)', id: 'America/Yakutat'},
//   {displayName: '(UTC-09:00) Alaska Time (Sitka)', id: 'America/Sitka'},
//     ...
// ];
// becomes [{displayName: 'Alaska Time', id: 'America/Yakutat'}]
export const getSimplifiedTimeZoneList = (
  timeZones: TimeZoneVM[] | undefined
): {displayName: string; id: string}[] => {
  if (!timeZones) {
    return [];
  }

  // simplify all timezone display names
  const simplifiedNameTimeZones = timeZones.map((timeZone) => ({
    ...timeZone,
    displayName: formatTimeZoneForDisplay(timeZone.displayName),
  }));

  // create a `Map` entry of unique `displayName` values
  const timeZonesMap = new Map();
  simplifiedNameTimeZones.forEach((timeZone) => {
    if (!timeZonesMap.has(timeZone.displayName)) {
      timeZonesMap.set(timeZone.displayName, timeZone.id);
    }
  });

  // Convert the `Map` into an array of time zone objects
  return Array.from(timeZonesMap.entries(), ([displayName, id]) => ({
    displayName,
    id,
  }));
};

// Take a time zone and returns abbreviation (UTC-11:00) Samoa Standard Time (Midway) becomes SST
export const getTimeZoneAbbreviation = (value: string): string => {
  const formattedTimeZone = formatTimeZoneForDisplay(value);
  return formattedTimeZone
    .split(' ')
    .map((char) => char[0])
    .join('');
};

/**
 *
 * @param input
 */
export const parseToLocalMoment = (
  input: string | Moment | undefined | null
): Moment | null => {
  if (typeof input === 'string') {
    const parsed = moment.utc(input, 'MM/DD/YYYY hh:mm a UTC').local();
    return parsed;
  } else if (input) {
    return input;
  }
  return null;
};

/**
 * Convert utc time to another timezone
 * @param input datetime as a string in the format 'MM/DD/YYYY hh:mm a UTC'
 * @param timezone timezone id; examples: "Europe/Berlin", "America/New_York"
 */
export const formatUtcTime = (
  input: string,
  timezone?: string
): Moment | null => {
  const parsed = momentTz.utc(input, 'MM/DD/YYYY hh:mm a UTC');
  if (timezone) {
    return parsed.tz(timezone);
  }
  return parsed;
};

export function utcStringIsInThePast(dateString: Maybe<string>) {
  return parseToLocalMoment(dateString)?.isBefore();
}

export function utcStringIsInTheFuture(dateString: Maybe<string>) {
  return parseToLocalMoment(dateString)?.isAfter();
}

const dateToDateParts = (date: Moment) => {
  return {
    year: date.year(),
    month: date.month(),
    day: date.date(),
  };
};

/**
 * Ensures days don't get lost in the conversion to UTC
 * @param date as a Moment instance
 */
export const stripTimezoneMakeUtc = (date: Moment) => {
  const {year, month, day} = dateToDateParts(date);
  return Date.UTC(year, month, day);
};

/**
 * Given a list of time zones metadata, attempts to match the user's timezone with
 * its corresponding item in the list
 *
 * @param timeZoneList List of timezone data usually retrieved from backend
 * @returns TimeZone data
 */
export function matchTimeZoneFromList(timeZoneList: TimeZoneVM[]): TimeZoneVM {
  const browserTimeZone = getUserTimeZone();
  const browserTimeZoneRegion = parseTimeZoneRegion(browserTimeZone);

  return (
    timeZoneList.find((tz) => tz.id === browserTimeZone) || // exact match
    timeZoneList.find(
      (tz) =>
        tz.id
          .toLocaleLowerCase()
          .includes(browserTimeZoneRegion.toLocaleLowerCase()) // attempt to match region
    ) ||
    timeZoneList[0] // fall back to the first TZ in the list
  );
}

/**
 * Extract the region from a timezone string
 *
 * @example
 * parseTimeZoneRegion('America/Argentina/Buenos_Aires') // -> 'Buenos_Aires'
 */
function parseTimeZoneRegion(timeZone: string) {
  const tzParts = timeZone.split('/');
  return tzParts[tzParts.length - 1];
}

/*
|--------------------------------------------------------------------------
| Moment.js utils
|--------------------------------------------------------------------------
*/
export const localizeMomentWhenNotDefault = (
  momentLocale = LANGUAGE_FALLBACK_LOCALE
) => {
  const defaultLanguageSelected = momentLocale === LANGUAGE_FALLBACK_LOCALE;
  const isNonUsEnglish =
    defaultLanguageSelected &&
    window.navigator.language !== LANGUAGE_FALLBACK_LOCALE;

  // Map unmatched locales to closest moment ones
  switch (momentLocale.toLowerCase()) {
    case 'es-419':
      momentLocale = 'es-mx';
      break;
    case 'de-de':
      momentLocale = 'de';
      break;
    case 'fr-fr':
      momentLocale = 'fr';
      break;
    case 'ja-jp':
      momentLocale = 'ja';
      break;
  }

  if (!defaultLanguageSelected || isNonUsEnglish) {
    const localeFileName = defaultLanguageSelected
      ? window.navigator.language
      : momentLocale;

    try {
      moment.locale(localeFileName);
    } catch (error) {
      datadogLogs.logger.error(
        `Failed to load the language file for: ${localeFileName}`
      );
      return false;
    }
    return true;
  }

  return false;
};

/**
 * Ensure a date is a Moment instance
 * @param date as a Moment instance or a Moment-parseable string
 */
export const dateToUTCMoment = (date: Moment) => {
  if (!date) return date;
  if (date && !moment.isMoment(date))
    return moment(date as moment.MomentInput).utc();
  date.utc(); // ignore local timezone
  const {year, month, day} = dateToDateParts(date);
  return moment({year, month, day});
};

/*
|--------------------------------------------------------------------------
| Date Picker utils
|--------------------------------------------------------------------------
*/

function getYesterday() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}

function getNextYear() {
  const nextYear = new Date();
  nextYear.setDate(nextYear.getDate() + 365);
  return nextYear;
}

export function disableDatesInThePast(currentDate) {
  return new Date(String(currentDate)) < getYesterday();
}

export const disableDatesBefore = (cutoffDate) => (currentDate) => {
  const _cutoffDate = new Date(String(cutoffDate));
  return new Date(String(currentDate)) < _cutoffDate;
};

export const disableDatesAfter = (cutoffDate) => (currentDate) => {
  const _cutoffDate = new Date(String(cutoffDate));
  return new Date(String(currentDate)) > _cutoffDate;
};

export const disableDatesBeforeYesterdayAfterYear = (currentDate) => {
  const yesterday = getYesterday();
  const nextYear = getNextYear();
  return currentDate < yesterday || currentDate > nextYear;
};

export const splitDateTime = (dateTime) => {
  if (dateTime && moment(dateTime).isValid()) {
    const momentDateTime = moment(dateTime);
    const date = momentDateTime.format(DATE_FORMAT.ISO_DATE);
    const time = momentDateTime.format(
      DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED
    );
    return {date, time};
  }
  return null;
};

export const mergeDateTime = (date, time) => {
  const momentDate = moment(date, DATE_FORMAT.ISO_DATE).format(
    DATE_FORMAT.ISO_DATE
  );
  const momentTime = time
    ? moment(time, DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED).format(
        DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED
      )
    : moment()
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .format(DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED);
  return momentDate + 'T' + momentTime;
};

interface getDisabledDatesProps {
  isReimbursementDate: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  rangeDates: {
    startDate: Date;
    endDate: Date;
  };
  tomorrow: Date;
}

export const getDisabledDates = ({
  isReimbursementDate,
  isStartDate,
  isEndDate,
  rangeDates,
  tomorrow,
}: getDisabledDatesProps) => {
  return isReimbursementDate
    ? disableDatesAfter(tomorrow)
    : isStartDate
    ? disableDatesAfter(rangeDates?.endDate)
    : isEndDate
    ? disableDatesBefore(rangeDates?.startDate)
    : '';
};

/**
 * Rounds a datetime object (or the current time if none is provided)
 * up to the nearest quarter of an hour (15 minute increment).
 */
export const incrementToQuarterHour = (time?: Moment) => {
  const _time = moment(time);
  const minute = Math.ceil(moment(_time).minute() / 15) * 15;
  _time.set('minute', minute);
  return _time;
};
