// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewUserFromIdentity.cs

import {z} from 'zod';

export const NewUserFromIdentitySchema = z.object({
  companyId: z.number(),
  companyName: z.string(),
  id: z.string(),
  name: z.string(),
});
