// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanTimeItemVM.cs

import {z} from 'zod';
import {UserPlanItemVMSchema} from './UserPlanItemVMSchema.schema';
import {UserPlanEventPreviewVMSchema} from './UserPlanEventPreviewVMSchema.schema';

const schema = z.object({
  events: z.array(UserPlanEventPreviewVMSchema),
  nextEvent: z.string().nullable().optional(),
  timeIncentiveId: z.number(),
});

export const UserPlanTimeItemVMSchema = UserPlanItemVMSchema.merge(schema);
