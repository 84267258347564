import {COLORS} from '@utils/constants';

export const TextStyles = {
  modalHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    color: COLORS.Neutral950,
    paddingBottom: '20px',
  },
  modalSubHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: COLORS.Neutral900,
    paddingBottom: '24px',
  },
  modalProgramCompany: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    color: COLORS.Neutral600,
  },
  modalProgramTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    color: COLORS.Black,
  },
  modalProgramLicenseHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: COLORS.Black,
    paddingBottom: '10px',
  },
  modalProgramText: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '150%',
    color: COLORS.Black,
  },
  moneyIncentiveModalCardHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: COLORS.Neutral950,
  },
  moneyIncentiveModalCardText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: COLORS.Neutral600,
  },
  learninPersonalBudgetHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: COLORS.Neutral950,
    margin: '0px 0px 15px 0px',
  },
  learninPersonalBudgetDescription: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: COLORS.Neutral600,
  },
  learninPersonalBudgetCCApproval: {
    fontFamily: 'Nunito Sans',
    fontWeight: '800',
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: COLORS.Neutral900,
  },
  learninPersonalBudgetCCMoney: {
    fontFamily: 'Nunito Sans',
    fontWeight: '800',
    fontSize: '2.5rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.1em',
    color: COLORS.Neutral900,
  },
  learninPersonalBudgetCCHeader: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    letterSpacing: '0.1em',
    color: COLORS.Neutral900,
    margin: '0px 0px 17px 0px',
  },
  learninPersonalBudgetCCFooter: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: COLORS.Neutral900,
  },
  oopsErrorMessage: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 'clamp(48px, 4.166vw, 60px)',
    lineHeight: '2.5rem',
    color: COLORS.Red500,
    margin: '0px 0px 42px 0px',
  },
  funErrorMessage: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 'clamp(23.5px, 4.166vw, 60px)',
    lineHeight: '3.4375rem',
    color: COLORS.Neutral900,
    margin: '0px 0px 42px 0px',
  },
  boringErrorMessage: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 'clamp(16px, 1.666vw, 24px)',
    lineHeight: '2.5rem',
    color: COLORS.Neutral600,
    margin: '0px 0px 15px 0px',
  },
  inputErrorMessage: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.875rem',
    textAlign: 'center',
    color: COLORS.Red800,
    margin: '0px 0px 15px 0px',
  },
  emptyApprovalTextHeader: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '1.25rem',
    lineHeight: '1.4375rem',
    margin: '0px 0px 8px 0px',
    color: COLORS.Neutral900,
  },
  emptyApprovalTextSubHeader: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: '#7F8083',
  },
  personalLearningBudgetGaugeStatus: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    textAlign: 'center',
    color: COLORS.Neutral900,
  },
  menuBadge: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: COLORS.Neutral900,
  },
  requestPasswordModalHeader: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    color: '#1B1C23',
    paddingBottom: '8px',
  },
  programStatusCardTextHeader: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: COLORS.Neutral600,
  },
  programStatusCardTextSubHeader: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: COLORS.Neutral900,
  },
  programStatusCardTextLink: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: COLORS.Blue800,
  },
  programSectionProgressBarLabel: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: COLORS.Neutral900,
    paddingBottom: '10px',
  },
  licenseViewerModalBody: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: COLORS.Neutral950,
  },
};
