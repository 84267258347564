import {AudienceVM} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {AudienceVMSchema} from '../zodSchemas/AudienceVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Audience/AudienceController.cs

/*
|--------------------------------------------------------------------------
|  get-audiences 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAudiencesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: audiences,
 *    isLoading: isAudiencesLoading,
 *    invalidateExact: invalidateAudiences,
 *  } = useAudiencesQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useAudiencesQuery = queryHookFactory<
  null,
  AudienceVM[] // return type
>('useAudiencesQuery', () => `audience/get-audiences`, {
  responseSchema: z.array(AudienceVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-position-audiences 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePositionAudiencesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: positionAudiences,
 *    isLoading: isPositionAudiencesLoading,
 *    invalidateExact: invalidatePositionAudiences,
 *  } = usePositionAudiencesQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const usePositionAudiencesQuery = queryHookFactory<
  null,
  string[] // return type
>('usePositionAudiencesQuery', () => `audience/get-position-audiences`, {
  responseSchema: z.array(z.string()),
});
