import styled from 'styled-components';
import {
  FormColumn,
  FormColumnsContainer,
} from '@components/reusable/LearnInDrawer';
import {requestFormTransitionRate} from '../RequestMenu/RequestMenu.styled';

export const RequestFormColumnsContainer = styled(FormColumnsContainer)`
  flex-direction: column;
  margin-left: 0;
  margin: 0.625rem 1.25rem 1.25rem;

  .ant-form {
    width: 100%;
  }
`;

export const RequestFormSection = styled(FormColumn)`
  max-width: 100%;
  opacity: ${(props: any) => (props.transitioned ? 1 : 0)};
  transition: opacity ${requestFormTransitionRate * 1.25}s ease-in;
`;

export const RequestFormWrapper = styled.div`
  width: 100%;
`;
