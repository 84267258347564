import DOMPurify from 'dompurify';

/**
 * Add a trailing period to a string if it doesn't already end in a closing punctuation.
 * Used when you're not sure if there might already be punctuation at the end of the string.
 * @param input
 * @returns new string with trailing value or the original value if no changes needed
 */
export function addTrailingPeriod(input: string): string {
  if (!input) {
    return input;
  }

  const endingCharacters = ['.', '?', '!'];

  if (endingCharacters.includes(input.charAt(input.length - 1))) {
    return input;
  }

  return `${input}.`;
}

/**
 * Takes a url format url to prevent relative linking for links without http/s prefix
 * @param url
 * @returns a Formatted Url
 */
export const formatUrl = (url: string) => {
  if (!url) return '#';
  if (url.startsWith('http') || url.startsWith('mailto')) return url;
  return `//${url}`;
};

/**
 * Takes a string, looks for `href="` and runs `formatsUrl`
 * @param content
 * @returns a string with formatted urls
 */
export const mapFormatUrls = (str: string) => {
  const hrefPrefix = `href="`;
  // find href values
  if (str?.startsWith(hrefPrefix)) {
    // strip the href=" prefix
    const hrefValue = str.slice(hrefPrefix?.length);
    // format url to prevent relative linking for links without http/s prefix
    const formattedUrl = formatUrl(hrefValue);
    return `${hrefPrefix}${formattedUrl}`;
  } else {
    return str;
  }
};

/**
 * Takes a string and prevents relative linking for links without http/s prefix
 * @param content
 * @returns a string with formatted urls
 */
export const formatUrls = (content: string) => {
  const separator = ' ';
  return content?.split(separator).map(mapFormatUrls).join(separator);
};

const EMAIL_REGEX =
  /([\w\u00E0-\u00FC+_.-]+@[\w\u00E0-\u00FC]+\.[\w\u00E0-\u00FC]+)/gi;

export const formatEmailToLink = (text: string | undefined) => {
  if (!text) {
    return text;
  }

  return text.replace(EMAIL_REGEX, "<a href='mailto:$1'>$1</a>");
};

const URL_REGEX = /([\s]|^)(https?:\/\/[^\s]+)/gi;

const DESCRIPTION_LINK_REGEX = /\(([^)]+)\)\[(.*?)\]/;

export const formatUrlToLink = (text: string | undefined) => {
  if (!text) return text;

  const transformToLink = (part: string) =>
    part.replace(URL_REGEX, "<a href='$2'>$2</a>");

  const set = text.split(' ');

  // Edge-case: if the initial text is a URL, transform it now,
  // since the regex will miss it due to lacking the space prefix.
  // This is less complicated than trying to include this case in the regex.
  const firstIsUrl = set[0]?.indexOf('https://') === 0;
  if (firstIsUrl) set[0] = transformToLink(set[0]);

  return set?.map((part: string) => transformToLink(part)).join(' ');
};

export const formatEmailAndUrlToLink = (text: string | undefined) => {
  if (!text) {
    return text;
  }

  return formatUrlToLink(formatEmailToLink(text));
};

export const hasContentsInHtmlString = (value: string) =>
  !!(stripHtmlTags(value).trim().length > 0 || value?.includes('<img'));

export const ConvertRelativeLinksInText = (text: string | undefined) => {
  // Format urls in the body of the description
  const hrefPrefix = `href="`;
  const separator = ' ';
  return text
    ?.split(separator)
    .map((str) => {
      // find href values
      if (str?.startsWith(hrefPrefix)) {
        // strip the href=" prefix
        const hrefValue = str.slice(hrefPrefix?.length);
        // format url to prevent relative linking for links without http/s prefix
        const formattedUrl = formatUrl(hrefValue);
        return `${hrefPrefix}${formattedUrl}`;
      } else {
        return str;
      }
    })
    .join(separator);
};

export const formatDescriptionEmailAndUrlToLink = (
  text: string | undefined
) => {
  if (!text) {
    return null;
  }
  return splitDescription(text);
};

export const splitDescription = (content: string) => {
  const separator = /(?=\()|(?=\]])/;
  return content?.split(separator).map(convertProgramDescriptionLinks).join('');
};

export const convertProgramDescriptionLinks = (text: string) => {
  if (!text) {
    return text;
  }

  return text.replace(DESCRIPTION_LINK_REGEX, "<a href='$2'>$1</a>");
};

/**
 * Takes the beginning of string and returns capitalized string
 * @param word
 * @returns a capitalized string
 *
 * NOTE: This needs to be locale-sensitive eventually, since the first character of title-case has acceptable lowercase scenarios in other languages.
 */
export function Capitalize(word: string | undefined): string {
  if (!!word?.length) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  } else {
    return '';
  }
}

/**
 * Takes a string and adds random numbers to create an unique key for a modal
 * @param tag
 * @returns  string
 */

export function getRandomKey(tag: string): string {
  return tag + Math.floor(Math.random() * tag.length);
}

/**
 * Removes HTML tags from a given string
 */
export function stripHtmlTags(htmlString: string): string {
  return DOMPurify.sanitize(htmlString || '', {
    ALLOWED_TAGS: [], // Restrict all tags
  });
}
