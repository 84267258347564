// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/IncentivePayload.cs

import {z} from 'zod';
import {IncentivePayloadSchema} from './IncentivePayloadSchema.schema';
import {DurationUnitTypeSchema} from './DurationUnitTypeSchema.schema';
import {TimeUnitTypeSchema} from './TimeUnitTypeSchema.schema';

const schema = z.object({
  perUnit: z.number(),
  perUnitType: DurationUnitTypeSchema,
  unit: z.number(),
  unitType: TimeUnitTypeSchema,
});

export const TimeIncentivePayloadSchema = IncentivePayloadSchema.merge(schema);
