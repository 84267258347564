import NotificationContent from '@components/user/NotificationContent';
import {openMessage} from '@components/user/notifications';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

interface Notify {
  type?: 'success' | 'warning' | 'error';
  message: string;
  duration?: number;
  link?: string;
  linkName?: string;
  consoleErrorMsg?: boolean;
}
export const notify = ({
  type = 'success',
  message,
  duration = 3,
  link,
  linkName,
  consoleErrorMsg,
}: Notify) => {
  openMessage(type, NotificationContent(message, link, linkName), duration);
  if (type === 'error' && !!consoleErrorMsg) console.error(consoleErrorMsg);
};

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/

export interface ReactQueryRequest {
  queryKey: string[];
  path: string;
}

export interface ReactMutateRequest {
  path: string;
  payload: object;
}

export interface ReactQueryHookArgs<ReturnDataType> {
  onSuccess?: (data?: ReturnDataType) => void;
  onError?: () => void;
}

export const addQueryParams = (
  baseUrl: string,
  queryParams: string[]
): string => {
  if (queryParams.length) {
    return queryParams.reduce(
      (previousValue, currentValue, index) =>
        index === 0
          ? `${previousValue}?${currentValue}`
          : `${previousValue}&${currentValue}`,
      baseUrl
    );
  }

  return baseUrl;
};
