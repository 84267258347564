/** These strings aren't customer-facing: these don't need to be localized. */
export const REIMBURSEMENT_REQUEST_DECLINED = {
  approved: 'false',
  amount: 'CAD 54.32',
  name: 'Luigi',
  providerName: 'Nintendo',
  providerImageUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Mario_Series_Logo.svg/1024px-Mario_Series_Logo.svg.png',
  programUrl:
    'https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/',
  programName: 'Luigi In His Haunted Mansion',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
};
