import {EmailPreview} from './EmailPreview.interfaces';
import * as TEMPLATE from './Samples';

export const EMAIL_PREVIEW_CONTAINER_PADDING = 64;
export const EMAIL_PREVIEW_CONTAINER_WIDTH = 463;

/**
 * List of EmailPreview objects. Example: {
    id: 'unique id for this list',
    templateId: 'id used by the API',
    label: 'for dropdown, doesn't need i18n',
    data: 'params needed to fulfill the API request'
  }
*/
export const EMAIL_TEMPLATE_DATA: EmailPreview[] = [
  {
    id: 'added-to-plan',
    templateId: 'plan/added-to-plan-email',
    label: 'Plan: Added To Plan',
    data: TEMPLATE.ADDED_TO_PLAN,
  },
  {
    id: 'added-as-contributor',
    templateId: 'academy/added-as-contributor-email',
    label: 'Academy: Added As Contributor',
    data: TEMPLATE.ADDED_AS_CONTRIBUTOR,
  },
  {
    id: 'dev-notification',
    templateId: 'dev/notification-email',
    label: 'Dev Test: Notification',
    data: TEMPLATE.DEV_NOTIFICATION,
  },
  {
    id: 'email-confirmation',
    templateId: 'account/email-confirmation-email',
    label: 'Account: Email Confirmation',
    data: TEMPLATE.EMAIL_CONFIRMATION,
  },
  {
    id: 'password-reset',
    templateId: 'account/access-email',
    label: 'Account: Access (Password Reset)',
    data: TEMPLATE.ACCESS,
  },
  {
    id: 'program-welcome',
    templateId: 'program/welcome-email',
    label: 'Program: Welcome',
    data: TEMPLATE.PROGRAM_WELCOME,
  },
  {
    id: 'program-nudge',
    templateId: 'program/nudge-email',
    label: 'Program: Nudge',
    data: TEMPLATE.PROGRAM_NUDGE,
  },
  {
    id: 'program-completion',
    templateId: 'program/completion-email',
    label: 'Program: Completion',
    data: TEMPLATE.PROGRAM_COMPLETION,
  },
  {
    id: 'card-declined',
    templateId: 'payment/card-declined-email',
    label: 'Payment: Card Declined',
    data: TEMPLATE.CARD_DECLINED,
  },
  {
    id: 'due-date-reminder-academy',
    templateId: 'project/due-date-reminder-email',
    label: 'Project: Due Date Reminder (Content)',
    data: TEMPLATE.DUE_DATE_REMINDER_CONTENT,
  },
  {
    id: 'due-date-reminder-program',
    templateId: 'project/due-date-reminder-email',
    label: 'Project: Due Date Reminder (Project)',
    data: TEMPLATE.DUE_DATE_REMINDER_PROJECT,
  },
  {
    id: 'project-submission',
    templateId: 'project/submission-email',
    label: 'Project: Submission',
    data: TEMPLATE.PROJECT_SUBMISSION,
  },
  {
    id: 'project-submission-feedback-academy',
    templateId: 'project/submission-feedback-email',
    label: 'Project: Submission Feedback (Academy)',
    data: TEMPLATE.PROJECT_SUBMISSION_FEEDBACK_ACADEMY,
  },
  {
    id: 'project-submission-feedback-program',
    templateId: 'project/submission-feedback-email',
    label: 'Project: Submission Feedback (Program)',
    data: TEMPLATE.PROJECT_SUBMISSION_FEEDBACK_PROGRAM,
  },
  {
    id: 'event-reminder-academy',
    templateId: 'event/reminder-email',
    label: 'Event: Reminder (Academy)',
    data: TEMPLATE.EVENT_REMINDER_ACADEMY,
  },
  {
    id: 'event-reminder-program',
    templateId: 'event/reminder-email',
    label: 'Event: Reminder (Program)',
    data: TEMPLATE.EVENT_REMINDER_PROGRAM,
  },
  {
    id: 'reimbursement-request-approved',
    templateId: 'request/reimbursement-request-email',
    label: 'Request: Reimbursement: Approved',
    data: TEMPLATE.REIMBURSEMENT_REQUEST_APPROVED,
  },
  {
    id: 'reimbursement-request-declined',
    templateId: 'request/reimbursement-request-email',
    label: 'Request: Reimbursement: Declined',
    data: TEMPLATE.REIMBURSEMENT_REQUEST_DECLINED,
  },
  {
    id: 'password-changed-confirmation',
    templateId: 'account/password-changed-confirmation-email',
    label: 'Account: Password Changed Confirmation',
    data: TEMPLATE.PASSWORD_CHANGED_CONFIRMATION,
  },
  {
    id: 'requested',
    templateId: 'request/requested-email',
    label: 'Request: To Approver',
    data: TEMPLATE.REQUESTED,
  },
  {
    id: 'requested-additional',
    templateId: 'request/requested-email',
    label: 'Request (Additional): To Approver',
    data: TEMPLATE.REQUESTED_ADDITIONAL,
  },
  {
    id: 'request-approved',
    templateId: 'request/approval-update-email',
    label: 'Request: Approved',
    data: TEMPLATE.REQUEST_APPROVED,
  },
  {
    id: 'request-approved-expiration',
    templateId: 'request/approval-update-email',
    label: 'Request: Approved (Expiration)',
    data: TEMPLATE.REQUEST_APPROVED_EXPIRATION,
  },
  {
    id: 'request-declined',
    templateId: 'request/approval-update-email',
    label: 'Request: Declined',
    data: TEMPLATE.REQUEST_DECLINED,
  },
  {
    id: 'program-nomination',
    templateId: 'program/nomination-email',
    label: 'Program: Nomination',
    data: TEMPLATE.PROGRAM_NOMINATION,
  },
  {
    id: 'voucher-request-approved-link',
    templateId: 'request/voucher-request-email',
    label: 'Request w/ Voucher (Link): Approved',
    data: TEMPLATE.VOUCHER_REQUEST_APPROVED_LINK,
  },
  {
    id: 'voucher-request-approved-voucher',
    templateId: 'request/voucher-request-email',
    label: 'Request w/ Voucher (Code): Approved',
    data: TEMPLATE.VOUCHER_REQUEST_APPROVED_VOUCHER,
  },
  {
    id: 'voucher-request-declined',
    templateId: 'request/voucher-request-email',
    label: 'Request w/ Voucher: Declined',
    data: TEMPLATE.VOUCHER_REQUEST_DECLINED,
  },
];
