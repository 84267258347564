import {KEY_CODES} from '@utils/constants';

export const ELEMENT_TYPES = 'a, button';

/** Currently designed for a 3-depth structure from parent to menu item:
<MenuElement>
  <IteratedElement>
    <MenuItem />
  </IteratedElement>(...)
</MenuElement>
*/
export function getDropdownKeydownTarget(
  event: KeyboardEvent,
  focusableTargets: HTMLElement[],
  returnTarget: HTMLElement,
  onClickOutside?: () => void
): HTMLElement {
  if (!document.activeElement) return;

  // Elements
  const targets = Array.from(focusableTargets);
  const first = targets[0];
  const last = targets[targets.length - 1];
  const nextSibling = document.activeElement.parentNode?.nextSibling;
  const next = (nextSibling as HTMLElement)?.querySelector(
    ELEMENT_TYPES
  ) as HTMLElement;
  const previousSibling = document.activeElement.parentNode?.previousSibling;
  const previous = (previousSibling as HTMLElement)?.querySelector(
    ELEMENT_TYPES
  ) as HTMLElement;

  // Act
  let focusTarget: HTMLElement;
  let preventDefault = true;
  switch (event.key) {
    case KEY_CODES.ARROW_UP:
      focusTarget = !previousSibling ? last : previous;
      break;
    case KEY_CODES.ARROW_DOWN:
      focusTarget = !nextSibling ? first : next;
      break;
    case KEY_CODES.HOME:
      focusTarget = first;
      break;
    case KEY_CODES.END:
      focusTarget = last;
      break;
    case KEY_CODES.TAB:
    case KEY_CODES.ESCAPE:
      onClickOutside?.();
      returnTarget.focus();
      preventDefault = false;
      break;
    default:
      preventDefault = false;
      break;
  }

  if (focusTarget && preventDefault) {
    /* Prevent page scroll while focus is within the context of the menu: since we've captured menu context, don't allow key events to bubble up which would interact with the page behind the focused menu. */
    event.preventDefault();
  }

  return focusTarget;
}

export function handleDropdownKeydown(
  event: KeyboardEvent,
  focusableTargets: HTMLElement[],
  returnTarget: HTMLElement,
  onClickOutside?: () => void
) {
  const target = getDropdownKeydownTarget(
    event,
    focusableTargets,
    returnTarget,
    onClickOutside
  );
  target &&
    setTimeout(() => {
      target.focus();
    });
  return target;
}

/** Bind a dropdown key helper for keyboard navigation support.
 * If the menu persists in the DOM, be sure to handle unbinding via bind=false */
export default function dropdownKeyHelper(
  menuId: string,
  returnTarget: HTMLElement,
  onClickOutside?: () => void,
  bind = true
) {
  const menuEl = document.getElementById(menuId);
  if (!menuEl) {
    return console.error(
      `No element found with id: ${menuId}. Dropdowns require a valid menu element to function.`
    );
  }
  const targets = menuEl?.querySelectorAll<HTMLElement>(ELEMENT_TYPES) ?? [];
  const commonHandler = (event: KeyboardEvent) =>
    handleDropdownKeydown(
      event,
      Array.from(targets),
      returnTarget,
      onClickOutside
    );

  const transitionInterval = 200; // Buffer for existing DOM transitions
  targets[0] && setTimeout(() => targets[0].focus(), transitionInterval);

  bind
    ? menuEl?.addEventListener('keydown', commonHandler)
    : menuEl?.removeEventListener('keydown', commonHandler);
}
