export const Wand = ({color}: {color?: string}) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7852 2.36851C17.0206 2.4278 17.0147 2.75448 16.7774 2.80573L15.1182 3.16397C15.0333 3.1823 14.9661 3.24529 14.9443 3.32707L14.5209 4.91393C14.4599 5.14261 14.1236 5.13689 14.0709 4.90627L13.7022 3.29417C13.6833 3.21171 13.6185 3.14647 13.5343 3.12527L11.9011 2.71386C11.6657 2.65457 11.6716 2.32789 11.909 2.27664L13.5682 1.9184C13.6531 1.90007 13.7202 1.83708 13.742 1.75531L14.1655 0.168449C14.2265 -0.0602353 14.5627 -0.0545173 14.6154 0.176101L14.9842 1.7882C15.003 1.87067 15.0679 1.93591 15.152 1.95711L16.7852 2.36851ZM8.6141 3.86953C8.78222 3.91188 8.77801 4.14522 8.60847 4.18183L7.42333 4.43772C7.3627 4.45081 7.31474 4.4958 7.29916 4.55421L6.99671 5.68768C6.95312 5.85103 6.71296 5.84694 6.67529 5.68222L6.41192 4.53071C6.39845 4.47181 6.35214 4.42521 6.29202 4.41007L5.12543 4.11621C4.95732 4.07386 4.96152 3.84052 5.13106 3.80391L6.31621 3.54802C6.37683 3.53493 6.42479 3.48993 6.44038 3.43153L6.74283 2.29805C6.78641 2.13471 7.02657 2.13879 7.06425 2.30352L7.32761 3.45502C7.34108 3.51393 7.38739 3.56052 7.44751 3.57567L8.6141 3.86953ZM11.0314 4.57031L12.7235 6.21433L2.65055 16.0013L0.958496 14.3573L11.0314 4.57031ZM11.0314 5.59711L9.25918 7.31906L9.89443 7.93629L11.6667 6.21433L11.0314 5.59711ZM16.8291 8.53925C16.9987 8.50264 17.0029 8.2693 16.8348 8.22695L15.6682 7.93309C15.6081 7.91795 15.5618 7.87135 15.5483 7.81245L15.2849 6.66094C15.2472 6.49622 15.0071 6.49213 14.9635 6.65548L14.6611 7.78895C14.6455 7.84736 14.5975 7.89235 14.5369 7.90544L13.3517 8.16133C13.1822 8.19794 13.178 8.43128 13.3461 8.47363L14.5127 8.76749C14.5728 8.78263 14.6191 8.82923 14.6326 8.88814L14.896 10.0396C14.9336 10.2044 15.1738 10.2084 15.2174 10.0451L15.5198 8.91163C15.5354 8.85322 15.5834 8.80823 15.644 8.79514L16.8291 8.53925Z"
        fill={color || '#146FE3'}
      />
    </svg>
  );
};
