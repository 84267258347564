// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/BaseCustomProgramContentPayload.cs

import {z} from 'zod';
import {LinkPayloadSchema} from './LinkPayloadSchema.schema';
import {SubmissionTypeSchema} from './SubmissionTypeSchema.schema';

export const BaseCustomProgramContentPayloadSchema = z.object({
  allowPeerVisibility: z.boolean(),
  description: z.string(),
  dueDate: z.string().nullable().optional(),
  dueDateTimeZone: z.string().nullable().optional(),
  dueTime: z.string().nullable().optional(),
  endDate: z.string().nullable().optional(),
  endTime: z.string().nullable().optional(),
  eventLink: z.string().nullable().optional(),
  eventTimeZone: z.string().nullable().optional(),
  links: z.array(LinkPayloadSchema),
  startDate: z.string().nullable().optional(),
  startTime: z.string().nullable().optional(),
  submissionType: SubmissionTypeSchema.nullable().optional(),
  title: z.string(),
});
