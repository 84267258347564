// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramSearchResult.cs

import {z} from 'zod';
import {CustomProgramCohortSearchVMSchema} from './CustomProgramCohortSearchVMSchema.schema';
import {DurationUnitTypeSchema} from './DurationUnitTypeSchema.schema';

export const CustomProgramSearchResultSchema = z.object({
  id: z.number(),
  imageLarge: z.string().nullable().optional(),
  imageSmall: z.string().nullable().optional(),
  nextCohort: CustomProgramCohortSearchVMSchema.nullable().optional(),
  presenceType: z.string().nullable().optional(),
  programLength: z.string().nullable().optional(),
  programLengthUnit: DurationUnitTypeSchema,
  ptft: z.string().nullable().optional(),
  rank: z.number(),
  selected: z.boolean(),
  selectedCohort: CustomProgramCohortSearchVMSchema.nullable().optional(),
  shortDescription: z.string().nullable().optional(),
  skills: z.string(),
  timeCommitHoursWeek: z.number(),
  title: z.string(),
});
