// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyBrandVM.cs

import {z} from 'zod';
import {IAcademyBrandBasicInfoSchema} from './IAcademyBrandBasicInfoSchema.schema';

const schema = z.object({
  backgroundColor: z.string().nullable().optional(),
  backgroundWelcomeColor: z.string().nullable().optional(),
  buttonDefaultWelcomeColor: z.string().nullable().optional(),
  buttonHoverWelcomeColor: z.string().nullable().optional(),
  isDefaultTheme: z.boolean(),
  itemDefaultTextNavColor: z.string().nullable().optional(),
  itemDefaultTextWelcomeColor: z.string().nullable().optional(),
  itemHoverBackgroundNavColor: z.string().nullable().optional(),
  itemHoverTextNavColor: z.string().nullable().optional(),
  itemSelectedBackgroundNavColor: z.string().nullable().optional(),
  itemSelectedTextNavColor: z.string().nullable().optional(),
  primaryBrandColor: z.string().nullable().optional(),
  secondaryBrandColor: z.string().nullable().optional(),
});

export const AcademyBrandVMSchema = IAcademyBrandBasicInfoSchema.merge(schema);
