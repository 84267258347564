// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ApprovalStatus.cs

enum ApprovalStatus {
  Pending,
  Requested,
  Approved,
  Rejected,
  Cancelled,
}

export default ApprovalStatus;
