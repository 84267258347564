/** These strings aren't customer-facing: these don't need to be localized. */
export const VOUCHER_REQUEST_APPROVED_VOUCHER = {
  approved: 'true',
  name: 'Link',
  providerName: 'Nintendo',
  providerImageUrl:
    'https://cdn11.bigcommerce.com/s-b72t4x/images/stencil/1280x1280/products/7727/14890/NNSN590-2__40459.1624820846.jpg',
  programUrl:
    'https://www.nintendo.com/games/detail/the-legend-of-zelda-breath-of-the-wild-switch/',
  programName: 'A Journey Through Hyrule',
  managerName: 'Zelda 👸',
  managerNote: 'You have saved me and Hyrule.',
  adminName: 'Old Man 🧙',
  adminNote: 'It is dangerous to go alone, take this: 🗡',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
  voucherCode: 'Su&9gh8aZ%)',
  redemptionGuidelines: 'To redeem, use this code at checkout',
};
