import {ProjectSubmissionVM} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {ProjectSubmissionVMSchema} from '../zodSchemas/ProjectSubmissionVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramContentsController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/sections/{sectionId}/contents/{contentId}/submissions 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsSectionsContentsSubmissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramsSectionsContentsSubmissions,
 *    isLoading: isCustomProgramsSectionsContentsSubmissionsLoading,
 *    invalidateExact: invalidateCustomProgramsSectionsContentsSubmissions,
 *  } = useCustomProgramsSectionsContentsSubmissionsQuery({
 *    contentId,
 *    customProgramId,
 *    sectionId
 *  })
 *
 */

export const useCustomProgramsSectionsContentsSubmissionsQuery =
  queryHookFactory<
    {contentId: number; customProgramId: number; sectionId: number}, // path variables
    ProjectSubmissionVM[] // return type
  >(
    'useCustomProgramsSectionsContentsSubmissionsQuery',
    ({customProgramId, sectionId, contentId}) =>
      `custom-programs/${customProgramId}/sections/${sectionId}/contents/${contentId}/submissions`,
    {responseSchema: z.array(ProjectSubmissionVMSchema)}
  );
