import {ChangeEvent} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {Input} from 'antd';
import {TextAreaProps} from 'antd/lib/input/TextArea';

import {TextAreaStyles} from './TextAreaTheme';
import {TextAreaTags} from './TextAreaEnums';
import {COLORS} from '@utils/constants';

interface Props extends TextAreaProps {
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  isError?: boolean;
  tag: TextAreaTags;
}

export function LearnInTextArea({...rest}: Props) {
  return <StyledTextArea {...rest} />;
}

const StyledTextArea = styled(Input.TextArea)`
  background: ${(props: any) =>
    props.background || TextAreaStyles[props.tag].background} !important;
  border: ${(props: any) =>
    props.isError
      ? props.errorBorder || TextAreaStyles[props.tag].errorBorder
      : props.border || TextAreaStyles[props.tag].border} !important;
  color: ${(props: any) => props.color || TextAreaStyles[props.tag].color};
  border-radius: ${(props: any) =>
    props.borderRadius || TextAreaStyles[props.tag].borderRadius};
  transition: ${(props: any) =>
    props.transition || TextAreaStyles[props.tag].transition};
  &:focus {
    border: ${(props: any) =>
      props.isError
        ? props.errorBorder || TextAreaStyles[props.tag].errorBorder
        : props.focusBorder ||
          TextAreaStyles[props.tag].focusBorder} !important;
    box-shadow: none !important;
  }
  &:disabled {
    background: ${(props: any) =>
      props.disabledBackground ||
      TextAreaStyles[props.tag].disabledBackground} !important;
  }
  &::after {
    color: ${COLORS.Neutral900};
  }
  &.ant-input-status-error {
    border: ${(props: any) =>
      TextAreaStyles[props?.tag]?.errorBorder} !important;
  }
`;
