import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {isNil} from 'lodash';
import {Locale, TransactionStatuses} from '@models/clientEnums';
import {CurrencyCode} from '@generated/enums';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import {CurrencyRatesVM} from '@models/clientModels';
import {TEMP_CURRENCY_SYMBOL_KEY} from '@blocks/localizationInit/LocalizationInit';
import {COLORS} from './constants';
import {useAuth} from './oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
|  Types
|--------------------------------------------------------------------------
*/
export type CurrencyDisplay = 'symbol' | 'narrowSymbol' | 'code' | 'name';

/*
|--------------------------------------------------------------------------
|  Constants
|--------------------------------------------------------------------------
*/
export const MONEY_DEFAULT = {
  currency: CurrencyCode.USD,
  symbol: '$',
  locale: Locale.en_US,
  exchangeRate: 1,
};

export const REQUEST_CURRENCY_ID = 'currencySelection';

/*
|--------------------------------------------------------------------------
|  Utilities
|--------------------------------------------------------------------------
*/
export const hyphenateCreditCardNumber = (
  cardNumber: string,
  separator = ' '
): string => {
  if (!cardNumber || cardNumber.length % 4 !== 0) {
    return cardNumber;
  }

  return cardNumber
    .split('')
    .reduce((previousValue, currentValue, currentIndex) =>
      currentIndex && currentIndex % 4 === 0
        ? `${previousValue}${separator}${currentValue}`
        : `${previousValue}${currentValue}`
    );
};

/** Typechecks currency and casts to CurrencyCode if it's a string */
export const getCurrencyEnum = (
  currency: CurrencyCode | string,
  alwaysReturnEnum = true
): CurrencyCode => {
  if (isNil(currency)) {
    return alwaysReturnEnum ? MONEY_DEFAULT.currency : null;
  } else if (typeof currency === 'string') {
    const matched = CurrencyCode[currency.toUpperCase()];
    return matched ?? MONEY_DEFAULT.currency;
  }
  return currency in CurrencyCode ? currency : MONEY_DEFAULT.currency;
};

export interface FormatCurrencyOptionsProps {
  currencyDisplay?: CurrencyDisplay;
  decimal?: boolean;
  format?: boolean;
  formatOptions?: Intl.NumberFormatOptions;
  locale?: Locale;
  numberStyle?: string;
  usersCurrency?: CurrencyCode;
  isInput?: boolean;
}

export const parseNumberFromCurrency = (formattedNumber) => {
  const locale = navigator.language;
  const numberFormat = new Intl.NumberFormat(locale);
  const decimalSeparator = numberFormat.format(1.1).charAt(1);
  const groupSeparator = numberFormat.format(1000).charAt(1);

  return parseFloat(
    formattedNumber
      .replace(
        new RegExp(`[${groupSeparator}]|[^0-9${decimalSeparator}]`, 'g'),
        ''
      )
      .replace(decimalSeparator, '.')
  );
};

const applyCustomFormatting = (
  formattedNumber: string,
  currencyCode: CurrencyCode
) => {
  const customFormattedCurrencies = [
    {
      currency: CurrencyCode.CAD,
      fn: (format: string) =>
        format
          .replace(/([a-zA-Z ])/g, '') // i.e. full string needs 'CA' removed
          .replace('$', 'CAD '),
    },
  ];

  const customFormat = customFormattedCurrencies.find(
    (c) => c.currency === currencyCode
  );

  if (customFormat) {
    // BEN-4152: The localStorage check is to be removed once the temp feature flag "CurrencySymbolSimplification" is removed
    const isCurrencySymbolSimplificationOn = localStorage.getItem(
      TEMP_CURRENCY_SYMBOL_KEY
    );

    if (isCurrencySymbolSimplificationOn?.toLowerCase() === 'true') {
      formattedNumber = customFormat.fn(formattedNumber);
    }
  }

  return formattedNumber;
};

/** Format a number using the provided locale/currency.
 *  @amount the money amount to format
 *  @currency the CurrencyCode enum (usually the user's .currency)
 *  @exchangeRate from `useExchangeRate` (common) or use `MONEY_DEFAULT.exchangeRate` if no exchange is required (rare).
 *  @options (optional) display options of FormatCurrencyOptionsProps
 */
export const formatCurrency = (
  amount: number,
  currency: CurrencyCode,
  exchangeRate: number,
  options?: FormatCurrencyOptionsProps
): string => {
  if (isNil(amount)) return '';

  const exchangedAmount = exchangeAmount(amount, exchangeRate);
  const currencyCode = getCurrencyEnum(currency); // Ensures the enum
  const hasCents = exchangedAmount % 1 !== 0;
  const useDecimal =
    options?.decimal || (options?.decimal !== false && hasCents);
  const digits = useDecimal ? 2 : 0;

  const formattedNumber = new Intl.NumberFormat(navigator.language, {
    currency: displayCurrencyCode(currencyCode),
    currencyDisplay: options?.currencyDisplay ?? 'narrowSymbol',
    minimumFractionDigits: options?.isInput ? 0 : digits,
    maximumFractionDigits: digits,
    style: options?.numberStyle ?? 'currency',
    ...options?.formatOptions,
  }).format(exchangedAmount);

  return applyCustomFormatting(formattedNumber, currencyCode);
};

/** Convert a CurrencyCode enum into its `string` value */
export const displayCurrencyCode = (currency: CurrencyCode): string => {
  return currency in CurrencyCode && CurrencyCode[currency];
};

export const displayCurrencySymbol = (
  currency: CurrencyCode,
  locale: Locale
): {symbol: string; prefix: boolean} => {
  const currencyType = 'currency';
  const formatter = new Intl.NumberFormat(locale || MONEY_DEFAULT.locale, {
    style: currencyType,
    currency: displayCurrencyCode(currency),
  });
  const arbitraryNumber = 1; // some number to engage the Intl formatter
  const parts = formatter.formatToParts(arbitraryNumber);
  const symbol =
    parts.find((p) => p.type === currencyType)?.value || MONEY_DEFAULT.symbol;
  // `parts` are ordered by l10n preference. currencyType being first indicates
  // the currency reads with a symbol prefix... last, as a suffix
  const prefix = parts[0].type === currencyType;
  return {
    symbol,
    prefix,
  };
};
export const moneyFormInputFormatter = (value: string | number): string =>
  `$ ${Number(value).toLocaleString()}`;

export const moneyPreventAlphaInput = (
  e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>
) => {
  const exp = /[a-zA-Z]+/g;
  const namedKey = e.key.length > 1;
  if (!e.ctrlKey && !e.metaKey && !namedKey && exp.test(e.key)) {
    e.preventDefault();
    return;
  }
  return e;
};

export const moneyFormInputParserWithPrefix = (
  displayValue: string
): number | null => {
  const locale = navigator.language;

  const formatNumber = (number) => new Intl.NumberFormat(locale).format(number);
  const extractSeparator = (pattern) => formatNumber(pattern).replace(/1/g, '');

  const thousandsSeparator = extractSeparator(1111);
  const decimalSeparator = extractSeparator(1.1);

  const ifHasThousandsSeparator = (value) => {
    if (thousandsSeparator) {
      const regex = new RegExp(`\\${thousandsSeparator}`, 'g');
      return value.replace(regex, '');
    }
    return value;
  };

  const normalizeValue = (value) => {
    const valueWithoutThousands = ifHasThousandsSeparator(value);
    return valueWithoutThousands.replace(
      new RegExp(`\\${decimalSeparator}`),
      '.'
    );
  };
  return parseFloat(normalizeValue(displayValue));
};

/** More safely round numbers/floating points to the hundredths place */
export const roundToCents = (amount: number) =>
  Math.round((amount + Number.EPSILON) * 100) / 100;

/** Abstraction for calculating exchanged amount off amount * exchangeRate.
 */
export const exchangeAmount = (
  amount: number,
  exchangeRate: number,
  toCents = true
) => {
  const exchanged = amount * exchangeRate;
  return toCents ? roundToCents(exchanged) : exchanged;
};

/** Abstraction for calculating the exchanged inverse of the amount (i.e. exchange back an amount back to the source: USD)
 */
export const exchangeAmountBack = (amount: number, exchangeRate: number) => {
  const inverseExchangeRate = MONEY_DEFAULT.exchangeRate / exchangeRate;
  const exchanged = amount * inverseExchangeRate;
  return exchanged;
};

/** Conditionally attach `currencyDisplay: 'symbol'` to the provided `FormatCurrencyOptionsProps` **/
export const useGetCurrencyDisplay = (
  options?: FormatCurrencyOptionsProps
): CurrencyDisplay => {
  const {user} = useAuth();

  if (!options || options.usersCurrency < 0) {
    options = {
      ...options,
      usersCurrency: user?.currency,
    };
  }

  // If no preference, use a more complete display on non-USD for clarity
  const useSymbolDisplay = options.usersCurrency !== MONEY_DEFAULT.currency;

  return useSymbolDisplay ? 'symbol' : null;
};

/** Return a rate for a pair based off the USD exchange rates */
export function getExchangeRateOfCurrencyPair(
  exchangeRates: CurrencyRatesVM[],
  usersCurrency: CurrencyCode,
  sourceCurrency: CurrencyCode
): number {
  const sourceIsUSD = sourceCurrency === MONEY_DEFAULT.currency;
  const userIsUSD = usersCurrency === MONEY_DEFAULT.currency;

  if (usersCurrency === sourceCurrency) return MONEY_DEFAULT.exchangeRate;

  // USD -> Any (= as-is from API)
  if (sourceIsUSD && !userIsUSD) {
    const rate = exchangeRates?.find(
      (c) => c.toCurrencyCode === usersCurrency
    )?.exchangeRate;
    return rate || MONEY_DEFAULT.exchangeRate;
  }

  // Any -> USD (= as-is)
  if (!sourceIsUSD && userIsUSD) {
    const rate = exchangeRates?.find(
      (c) => c.toCurrencyCode === sourceCurrency
    )?.exchangeRate;
    return (rate && 1 / rate) || MONEY_DEFAULT.exchangeRate;
  }

  // Non-USD -> Non-USD (= calculate back from USD rates)
  if (!userIsUSD && !sourceIsUSD) {
    const sourceExchangeRate = exchangeRates?.find(
      (c) => c.toCurrencyCode === sourceCurrency
    )?.exchangeRate;

    const usersExchangeRate = exchangeRates?.find(
      (c) => c.toCurrencyCode === usersCurrency
    )?.exchangeRate;

    // Divide the user rate by the source rate since both are based off USD.
    // This produces the inferred exchange rate between them which is returned.
    if (sourceExchangeRate && usersExchangeRate) {
      return usersExchangeRate / sourceExchangeRate;
    }
  }

  // Any failure, return default
  return MONEY_DEFAULT.exchangeRate;
}

/** Common conditions, usage, and filtering via `useGetCurrencyExchangeQuery` */
export function useGetEffectiveExchangeRate(
  currency: CurrencyCode = MONEY_DEFAULT.currency,
  usersCurrency: CurrencyCode = MONEY_DEFAULT.currency
): number {
  const sourceCurrency = currency || MONEY_DEFAULT.currency;

  // Currency exchange feature off? Use fallback
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });

  // Lookup and provide matching rate
  try {
    return getExchangeRateOfCurrencyPair(
      getCurrencyExchangeQuery?.data,
      usersCurrency,
      sourceCurrency
    );
  } catch (err) {
    console.warn('Fallback currency rate in effect:', err);
    return MONEY_DEFAULT.exchangeRate;
  }
}

/** Non-hook: display (and optionally format) an amount in a localized currency.
 * Generally, this should not be invoked outside of `formatCurrency()` */
export const localizeAmount = ({
  amount,
  localizedAmount,
  sourceCurrency,
  currencyDisplay,
  options,
}: {
  amount: string | number;
  localizedAmount: number;
  sourceCurrency: CurrencyCode;
  currencyDisplay: CurrencyDisplay;
  options?: FormatCurrencyOptionsProps;
}) => {
  if (isNil(amount) || isNil(sourceCurrency)) {
    return amount?.toString();
  }

  const formattingExplicitlyOff = options?.format === false;

  if (formattingExplicitlyOff) {
    options = {
      ...options,
      currencyDisplay,
    };
  }

  return formattingExplicitlyOff
    ? localizedAmount?.toString()
    : formatCurrency(
        localizedAmount,
        sourceCurrency,
        MONEY_DEFAULT.exchangeRate,
        options
      );
};

/** Display (and optionally format) an amount in a localized currency.
 * Generally, this should not be invoked outside of `formatCurrency()` */
export const useLocalizeAmount = (
  amount: number,
  sourceCurrency: CurrencyCode,
  options?: FormatCurrencyOptionsProps
): string => {
  const {user} = useAuth();
  const usersCurrency = user?.currency;
  const localizedAmount = exchangeAmount(
    amount,
    useGetEffectiveExchangeRate(sourceCurrency, usersCurrency)
  );
  const currencyDisplay = useGetCurrencyDisplay({
    ...options,
    usersCurrency,
  });
  return localizeAmount({
    amount,
    localizedAmount,
    sourceCurrency,
    currencyDisplay,
    options,
  });
};

/** Supports the useLocalizedAmountDisclaimer hook */
export const localizedAmountDisclaimer = ({
  amount,
  sourceCurrency,
  usersCurrency,
  localizedAmount,
  useDisclaimer,
}: {
  amount: number;
  sourceCurrency: CurrencyCode;
  usersCurrency: CurrencyCode;
  localizedAmount?: string;
  useDisclaimer?: boolean;
}): string => {
  if (isNil(amount) || isNil(sourceCurrency) || isNil(usersCurrency)) {
    return null;
  }

  const displayedCurrencyCode = displayCurrencyCode(usersCurrency);

  return useDisclaimer
    ? i18n.t(k.APPROVAL__LOCALIZED_DISCLAIMER__FORMAT, {
        localizedAmount: `${localizedAmount} ${displayedCurrencyCode}`,
        originalAmount: `${formatCurrency(
          amount,
          sourceCurrency,
          MONEY_DEFAULT.exchangeRate
        )} ${displayCurrencyCode(sourceCurrency)}`,
      })
    : null;
};

/** Returns a string with our exchange disclaimer, otherwise it's null */
export const useLocalizedAmountDisclaimer = ({
  amount,
  sourceCurrency,
  usersCurrency,
  localizedAmount,
  useDisclaimer,
}: {
  amount: number;
  sourceCurrency: CurrencyCode;
  usersCurrency: CurrencyCode;
  localizedAmount?: string; // i.e. an amount already localized
  useDisclaimer?: boolean; // i.e. pre-determined
}): string => {
  const unformattedAmount = useLocalizeAmount(amount, sourceCurrency, {
    format: false,
  });
  const formattedAmount = useLocalizeAmount(amount, sourceCurrency, {
    currencyDisplay: 'symbol',
  });

  localizedAmount = localizedAmount || formattedAmount;
  useDisclaimer = useDisclaimer || unformattedAmount !== amount?.toString();

  return localizedAmountDisclaimer({
    amount,
    sourceCurrency,
    usersCurrency,
    localizedAmount,
    useDisclaimer,
  });
};

export const currencyOptions: JSX.Element[] = (() => {
  const currencies = (() => {
    const currencyInfo = [];
    for (const value in Object.keys(CurrencyCode)) {
      if (typeof CurrencyCode[value] !== 'string') {
        continue;
      }
      const currencyCodeNum = Number(value);
      currencyInfo.push({
        title: CurrencyCode[currencyCodeNum].toString(),
        value: currencyCodeNum,
        key: 'currency-' + CurrencyCode[currencyCodeNum],
      });
    }
    currencyInfo.sort((a, b) => a.title.localeCompare(b.title));
    return currencyInfo;
  })();

  const currencyOptions = (() => {
    const options = [];
    currencies.forEach((currency) => {
      options.push(
        <LearnInSelectOption
          title={currency.title}
          value={currency.value}
          key={currency.key}>
          {currency.title}
        </LearnInSelectOption>
      );
    });
    return options;
  })();

  return currencyOptions;
})();

export const applyLicenseCurrencyOrDefault = (
  row: Partial<{licenseId: number; licenseCurrency: CurrencyCode}>
) => (row.licenseId ? row.licenseCurrency : MONEY_DEFAULT.currency);

export const getExchangeRateBySelection = (
  currencySelection: CurrencyCode,
  exchangeRatesList: CurrencyRatesVM[],
  exchangeRate: number
) =>
  !isNil(currencySelection)
    ? exchangeRatesList?.find((c) => c.toCurrencyCode === currencySelection)
        ?.exchangeRate || exchangeRate
    : exchangeRate;

export const adjustAmountByStatus = (amount: number, status: string) => {
  const isPending = status === TransactionStatuses.Pending;
  const isDeclined = status === TransactionStatuses.Declined;
  return isPending || isDeclined ? amount * -1 : amount;
};

export const getColorByAmountAndStatus = (amount: number, status: string) =>
  status?.toLowerCase() !== TransactionStatuses.Declined &&
  status?.toLowerCase() !== TransactionStatuses.Pending &&
  amount > 0
    ? COLORS.Green700
    : 'inherit';
