import {ApprovalRankType, IncentiveType} from './../enums';
import {
  DashboardVM,
  DetailsVM,
  IncentiveAdminVM,
  IncentiveInitiativeProgramVM,
  IncentivesCountVM,
  NewResourceApprovalApplicationVM,
  ProgramFilterVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {DetailsVMSchema} from '../zodSchemas/DetailsVMSchema.schema';
import {IncentiveInitiativeProgramVMSchema} from '../zodSchemas/IncentiveInitiativeProgramVMSchema.schema';
import {ProgramFilterVMSchema} from '../zodSchemas/ProgramFilterVMSchema.schema';
import {DashboardVMSchema} from '../zodSchemas/DashboardVMSchema.schema';
import {IncentiveAdminVMSchema} from '../zodSchemas/IncentiveAdminVMSchema.schema';
import {NewResourceApprovalApplicationVMSchema} from '../zodSchemas/NewResourceApprovalApplicationVMSchema.schema';
import {IncentivesCountVMSchema} from '../zodSchemas/IncentivesCountVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Incentives/IncentiveController.cs

/*
|--------------------------------------------------------------------------
|  get-featured/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useFeaturedQuery} from '@generated/hooks';
 *
 *  const {
 *    data: featured,
 *    isLoading: isFeaturedLoading,
 *    invalidateExact: invalidateFeatured,
 *  } = useFeaturedQuery({
 *    type
 *  })
 *
 */

export const useFeaturedQuery = queryHookFactory<
  {type: number | string}, // path variables
  DashboardVM // return type
>('useFeaturedQuery', ({type}) => `incentive/get-featured/${type}`, {
  responseSchema: DashboardVMSchema,
});

/*
|--------------------------------------------------------------------------
|  get-incentive-counts 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentiveCountsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentiveCounts,
 *    isLoading: isIncentiveCountsLoading,
 *    invalidateExact: invalidateIncentiveCounts,
 *  } = useIncentiveCountsQuery(null)
 *
 */

export const useIncentiveCountsQuery = queryHookFactory<
  null,
  IncentivesCountVM // return type
>('useIncentiveCountsQuery', () => `incentive/get-incentive-counts`, {
  responseSchema: IncentivesCountVMSchema,
});

/*
|--------------------------------------------------------------------------
|  get-incentive-initiatives/{incentiveType}/{incentiveId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentiveInitiativesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentiveInitiatives,
 *    isLoading: isIncentiveInitiativesLoading,
 *    invalidateExact: invalidateIncentiveInitiatives,
 *  } = useIncentiveInitiativesQuery({
 *    incentiveId,
 *    incentiveType
 *  })
 *
 */

export const useIncentiveInitiativesQuery = queryHookFactory<
  {incentiveId: number; incentiveType: IncentiveType}, // path variables
  IncentiveInitiativeProgramVM[] // return type
>(
  'useIncentiveInitiativesQuery',
  ({incentiveType, incentiveId}) =>
    `incentive/get-incentive-initiatives/${incentiveType}/${incentiveId}`,
  {responseSchema: z.array(IncentiveInitiativeProgramVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-incentive-program-types/{incentiveType}/{incentiveId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentiveProgramTypesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentiveProgramTypes,
 *    isLoading: isIncentiveProgramTypesLoading,
 *    invalidateExact: invalidateIncentiveProgramTypes,
 *  } = useIncentiveProgramTypesQuery({
 *    incentiveId,
 *    incentiveType
 *  })
 *
 */

export const useIncentiveProgramTypesQuery = queryHookFactory<
  {incentiveId: number; incentiveType: IncentiveType}, // path variables
  ProgramFilterVM[] // return type
>(
  'useIncentiveProgramTypesQuery',
  ({incentiveType, incentiveId}) =>
    `incentive/get-incentive-program-types/${incentiveType}/${incentiveId}`,
  {responseSchema: z.array(ProgramFilterVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-incentives/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentivesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentives,
 *    isLoading: isIncentivesLoading,
 *    invalidateExact: invalidateIncentives,
 *  } = useIncentivesQuery({
 *    type
 *  })
 *
 */

export const useIncentivesQuery = queryHookFactory<
  {type: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>('useIncentivesQuery', ({type}) => `incentive/get-incentives/${type}`, {
  responseSchema: null,
});

/*
|--------------------------------------------------------------------------
|  get-incentives-admin/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentivesAdminQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentivesAdmin,
 *    isLoading: isIncentivesAdminLoading,
 *    invalidateExact: invalidateIncentivesAdmin,
 *  } = useIncentivesAdminQuery({
 *    type
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useIncentivesAdminQuery = queryHookFactory<
  {type: number | string}, // path variables
  IncentiveAdminVM[] // return type
>(
  'useIncentivesAdminQuery',
  ({type}) => `incentive/get-incentives-admin/${type}`,
  {responseSchema: z.array(IncentiveAdminVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-initiative-incentives/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useInitiativeIncentivesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: initiativeIncentives,
 *    isLoading: isInitiativeIncentivesLoading,
 *    invalidateExact: invalidateInitiativeIncentives,
 *  } = useInitiativeIncentivesQuery({
 *    type,
 *      queryParams: {
 *        initiativeId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useInitiativeIncentivesQuery = queryHookFactory<
  {type: IncentiveType; queryParams?: {initiativeId?: number}}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useInitiativeIncentivesQuery',
  ({type, queryParams}) =>
    addQueryParams(
      ({type}) => `incentive/get-initiative-incentives/${type}`,
      {type},
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  get-resources-approval-applications 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useResourcesApprovalApplicationsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: resourcesApprovalApplications,
 *    isLoading: isResourcesApprovalApplicationsLoading,
 *    invalidateExact: invalidateResourcesApprovalApplications,
 *  } = useResourcesApprovalApplicationsQuery({
 *    queryParams: {
 *        approvalRankType
 *    }
 *  })
 *
 */

export const useResourcesApprovalApplicationsQuery = queryHookFactory<
  {queryParams?: {approvalRankType?: ApprovalRankType}},
  NewResourceApprovalApplicationVM[] // return type
>(
  'useResourcesApprovalApplicationsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `incentive/get-resources-approval-applications`,
      null,
      queryParams
    ),
  {responseSchema: z.array(NewResourceApprovalApplicationVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-details/{type}/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentiveDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentiveDetails,
 *    isLoading: isIncentiveDetailsLoading,
 *    invalidateExact: invalidateIncentiveDetails,
 *  } = useIncentiveDetailsQuery({
 *    id,
 *    type
 *  })
 *
 */

export const useIncentiveDetailsQuery = queryHookFactory<
  {id: number; type: IncentiveType}, // path variables
  DetailsVM | null // return type
>(
  'useIncentiveDetailsQuery',
  ({type, id}) => `incentive/get-details/${type}/${id}`,
  {responseSchema: DetailsVMSchema.nullable()}
);

/*
|--------------------------------------------------------------------------
|  get-started/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIncentiveStartedQuery} from '@generated/hooks';
 *
 *  const {
 *    data: incentiveStarted,
 *    isLoading: isIncentiveStartedLoading,
 *    invalidateExact: invalidateIncentiveStarted,
 *  } = useIncentiveStartedQuery({
 *    type
 *  })
 *
 */

export const useIncentiveStartedQuery = queryHookFactory<
  {type: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>('useIncentiveStartedQuery', ({type}) => `incentive/get-started/${type}`, {
  responseSchema: null,
});
