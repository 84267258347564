import {MoneyInputViewProps} from './MoneyInput.interfaces';
import {MoneyInputWrapper} from './MoneyInput.style';
import {MONEY_INPUT_ID} from './MoneyInput.constants';
import {LearnInMoneyInput} from '@components/reusable/Input/Input.style';
import {FormItem} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {CurrencyCode} from '@generated/enums';
import {RecurringDurationDropdown} from '@components/user/pages/components/PreApprovalFormItem/RecurringDurationDropdown';
import {SubscriptionToggle} from '@components/user/pages/components/PreApprovalFormItem/SubscriptionToggle';
import {ProgramAmountSubtext} from '@components/user/pages/components/PreApprovalFormItem/ProgramAmountSubtext';
import {MoneyInputLabel} from '@components/user/pages/components/PreApprovalFormItem/MoneyInputLabel';
import {currencyOptions, REQUEST_CURRENCY_ID} from '@utils/moneyUtils';
import LearnInSelect from '@components/reusable/Select/Select.style';
import {LearnInFormItem} from '@components/reusable/Form/Form';

export const MoneyInputView = ({
  costFieldValidation,
  costFormattedInProgramCurrency,
  costInUsersCurrency,
  currencyQuestion,
  form,
  frequencyDropdown,
  item,
  isCostField,
  isFormDisabled,
  isHelperTextOn,
  isProgram,
  isRecurring,
  isRecurringDurationVisible,
  isRequestWithCurrencyOn,
  isResourceRequest,
  isSubscription = false,
  isSubTextVisible = true,
  isUserRequest,
  months, // 1 if not recurring or irrelevant
  onCurrencyChange,
  onTotalChange,
  prefilledCost,
  prefixDisplay,
  programCost,
  setIsRecurring,
  setMonths,
  showOnlyDefaultAmount,
  user,
}: MoneyInputViewProps) => {
  const applyInitialCurrencySelection = () =>
    !!currencyQuestion?.response
      ? (Number(currencyQuestion.response) as CurrencyCode)
      : user.currency;
  return (
    <>
      <LearnInFormItem
        label={
          <MoneyInputLabel
            item={item}
            isCostField={isCostField}
            isProgram={isProgram}
          />
        }>
        <MoneyInputWrapper
          style={{
            gap: isRequestWithCurrencyOn && isUserRequest ? '0.5rem' : '1rem',
          }}>
          <LearnInFormItem
            key={item.id}
            name={String(item.id)}
            initialValue={prefilledCost || ''}
            requiredMark={false}
            extra={
              isSubTextVisible && (
                <ProgramAmountSubtext
                  programCost={programCost}
                  isProgram={isProgram}
                  isResourceRequest={isResourceRequest}
                  isUserRequest={isUserRequest}
                  costFormattedInUsersCurrency={costInUsersCurrency}
                  defaultToProgramCurrency={showOnlyDefaultAmount}
                  costFormattedInProgramCurrency={
                    costFormattedInProgramCurrency
                  }
                />
              )
            }
            rules={costFieldValidation}
            style={{
              width: isRequestWithCurrencyOn && isUserRequest ? '50%' : null,
            }}>
            <LearnInMoneyInput
              data-testid={MONEY_INPUT_ID}
              onChange={(x: number) => {
                onTotalChange?.(form.getFieldValue(String(item.id)) * months);
                isRecurring && x;
              }}
              min={1}
              hide={isRequestWithCurrencyOn && isUserRequest}
              prefixType={prefixDisplay}
              currency={prefixDisplay}
              condensed={isRequestWithCurrencyOn}
              prefixBgTransparent={isRequestWithCurrencyOn}
              style={{width: '100%'}}
              disabled={isFormDisabled}
              data-cy="moneyInput"
            />
          </LearnInFormItem>
          {isRequestWithCurrencyOn && currencyQuestion && (
            <FormItem
              name={REQUEST_CURRENCY_ID}
              initialValue={applyInitialCurrencySelection()}
              noStyle>
              <LearnInSelect
                size="large"
                key={currencyQuestion?.id}
                name={String(currencyQuestion?.id)}
                defaultValue={applyInitialCurrencySelection()}
                disabled={isFormDisabled}
                data-testid={REQUEST_CURRENCY_ID}
                onChange={(currency: CurrencyCode) =>
                  onCurrencyChange(currency, item)
                }
                style={{
                  width:
                    isRequestWithCurrencyOn && isUserRequest ? '50%' : '80px',
                }}>
                {currencyOptions}
              </LearnInSelect>
            </FormItem>
          )}
          {isSubscription && (
            <SubscriptionToggle
              setIsRecurring={setIsRecurring}
              initialValue={isRecurringDurationVisible}
            />
          )}
        </MoneyInputWrapper>
      </LearnInFormItem>
      {isRecurring && (
        <RecurringDurationDropdown
          frequencyDropdown={frequencyDropdown}
          initialValue={Number(frequencyDropdown?.response || months)}
          isHelperTextOn={isHelperTextOn}
          localizationId={item?.localizationId}
          helperText={item?.helperText}
          setMonths={setMonths}
        />
      )}
    </>
  );
};
