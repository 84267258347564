import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {renderLabel} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {i18n, k} from '@i18n/translate';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {LearnInTooltip} from '@components/reusable/Tooltip';

interface MoneyInputLabelProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  isCostField: boolean;
  isProgram: boolean;
}

export const MoneyInputLabel = ({
  item,
  isCostField,
  isProgram,
}: MoneyInputLabelProps) => (
  <>
    {renderLabel(item)}
    {isCostField && isProgram && (
      <span style={{marginLeft: '4px'}}>
        <LearnInTooltip
          width={'220'}
          title={i18n.t(k.APPROVAL__MAY_IMPACT_TOTAL)}>
          <QuestionCircleOutlined aria-hidden="true" alt="" />
        </LearnInTooltip>
      </span>
    )}
  </>
);
