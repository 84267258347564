// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateApprovalRequest.cs

import {z} from 'zod';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';
import {ApprovalTypeSchema} from './ApprovalTypeSchema.schema';

export const UpdateApprovalRequestSchema = z.object({
  costCenter: z.string().nullable().optional(),
  id: z.number(),
  notes: z.string(),
  status: ApprovalStatusSchema,
  type: ApprovalTypeSchema,
});
