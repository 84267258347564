// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/PreApprovalQuestionResponseSource.cs

enum PreApprovalQuestionResponseSource {
  Text,
  UserPlanProgram,
  UserPlanProgramAmount,
  UserCurrency,
  SetOptions,
}

export default PreApprovalQuestionResponseSource;
