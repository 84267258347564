// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/UserRequestApprovalDetailsVM.cs

import {z} from 'zod';
import {UserRequestApprovalVMSchema} from './UserRequestApprovalVMSchema.schema';

export const UserRequestApprovalDetailsVMSchema = z.object({
  canBeApproved: z.boolean(),
  canBeApprovedMessage: z.string(),
  parentApproval: UserRequestApprovalVMSchema.nullable().optional(),
  requestedApproval: UserRequestApprovalVMSchema,
  requestedEmail: z.string().nullable().optional(),
});
