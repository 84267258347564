// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/IntiativePurposeDescriptionVM.cs

import {z} from 'zod';
import {InitiativePurposeSchema} from './InitiativePurposeSchema.schema';

export const InitiativePurposeDescriptionVMSchema = z.object({
  programId: z.number(),
  purpose: InitiativePurposeSchema,
  purposeDescriptions: z.array(z.string()),
});
