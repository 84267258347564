// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanItemCardVM.cs

import {z} from 'zod';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {PlanItemTypeSchema} from './PlanItemTypeSchema.schema';

export const UserPlanItemCardVMSchema = z.object({
  applicationUrl: z.string(),
  approvalConfig: ApprovalConfigSchema,
  currency: CurrencyCodeSchema,
  duration: z.number().nullable().optional(),
  durationType: z.string(),
  estimatedAmount: z.number().nullable().optional(),
  exceedsEstimatedCostAmount: z.number().nullable().optional(),
  externalUrl: z.string(),
  financeIncentiveId: z.number().nullable().optional(),
  id: z.number(),
  isAnyAmountIncentive: z.boolean(),
  isPersonalLearningBudget: z.boolean(),
  itemType: PlanItemTypeSchema,
  money: z.number().nullable().optional(),
  preApprovalApplicationStatus: z.string(),
  programId: z.number().nullable().optional(),
  provider: z.string(),
  providerLogo: z.string(),
  redemptionApprovalState: z.string(),
  redemptionApprovedBy: z.string(),
  startDate: z.string().nullable().optional(),
  status: z.string(),
  timeIncentiveId: z.number().nullable().optional(),
  title: z.string(),
});
