import {k} from '@i18n/translate';
import {TApiHookName} from '@generated/types';

type THookNotifications = Record<
  TApiHookName,
  Record<string, keyof typeof k | ''>
>;
const n: THookNotifications = {} as THookNotifications;

n.useAcademiesMutation = {};
n.useAcademiesMutation.error = '';
n.useAcademiesMutation.success = '';

n.useAcademiesAcademyLevelsAcademyStepsMutation = {};
n.useAcademiesAcademyLevelsAcademyStepsMutation.error = '';
n.useAcademiesAcademyLevelsAcademyStepsMutation.success = '';

n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation = {};
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation.error =
  '';
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation.success =
  '';

n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery = {};
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery.error = '';
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery.success = '';

n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation = {};
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation.error =
  '';
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation.success =
  '';

n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation = {};
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation.error =
  '';
n.useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation.success =
  '';

n.useAcademiesAddDateToMyCalendarMutation = {};
n.useAcademiesAddDateToMyCalendarMutation.error = '';
n.useAcademiesAddDateToMyCalendarMutation.success = '';

n.useAcademiesAddUserPermissionsMutation = {};
n.useAcademiesAddUserPermissionsMutation.error = '';
n.useAcademiesAddUserPermissionsMutation.success = '';

n.useAcademiesDeleteUserPermissionMutation = {};
n.useAcademiesDeleteUserPermissionMutation.error = '';
n.useAcademiesDeleteUserPermissionMutation.success = '';

n.useAcademiesContentAttachmentQuery = {};
n.useAcademiesContentAttachmentQuery.error = '';
n.useAcademiesContentAttachmentQuery.success = '';

n.useAcademiesContentAttachmentUrlQuery = {};
n.useAcademiesContentAttachmentUrlQuery.error = '';
n.useAcademiesContentAttachmentUrlQuery.success = '';

n.useAcademiesProjectSubmissionsQuery = {};
n.useAcademiesProjectSubmissionsQuery.error = '';
n.useAcademiesProjectSubmissionsQuery.success = '';

n.useAcademiesProjectSubmissionUrlQuery = {};
n.useAcademiesProjectSubmissionUrlQuery.error = '';
n.useAcademiesProjectSubmissionUrlQuery.success = '';

n.useAcademiesUserPermissionsQuery = {};
n.useAcademiesUserPermissionsQuery.error = '';
n.useAcademiesUserPermissionsQuery.success = '';

n.useAcademiesIntegrationsChatChannelsDeleteMutation = {};
n.useAcademiesIntegrationsChatChannelsDeleteMutation.error = '';
n.useAcademiesIntegrationsChatChannelsDeleteMutation.success = '';

n.useAcademiesIntegrationsChatChannelsQuery = {};
n.useAcademiesIntegrationsChatChannelsQuery.error = '';
n.useAcademiesIntegrationsChatChannelsQuery.success = '';

n.useAcademiesIntegrationsChatChannelsPostMutation = {};
n.useAcademiesIntegrationsChatChannelsPostMutation.error = '';
n.useAcademiesIntegrationsChatChannelsPostMutation.success = '';

n.useAcademiesIntegrationsChatChannelsPutMutation = {};
n.useAcademiesIntegrationsChatChannelsPutMutation.error = '';
n.useAcademiesIntegrationsChatChannelsPutMutation.success = '';

n.useAcademiesLevelsLxpContentMutation = {};
n.useAcademiesLevelsLxpContentMutation.error = '';
n.useAcademiesLevelsLxpContentMutation.success = '';

n.useAcademiesSubmitContentFeedbackMutation = {};
n.useAcademiesSubmitContentFeedbackMutation.error = '';
n.useAcademiesSubmitContentFeedbackMutation.success = '';

n.useAcademiesUpdateUserPermissionMutation = {};
n.useAcademiesUpdateUserPermissionMutation.error = '';
n.useAcademiesUpdateUserPermissionMutation.success = '';

n.useAcademiesUploadContentAttachmentMutation = {};
n.useAcademiesUploadContentAttachmentMutation.error = '';
n.useAcademiesUploadContentAttachmentMutation.success = '';

n.useAcademiesUserCompanyByIdQuery = {};
n.useAcademiesUserCompanyByIdQuery.error = '';
n.useAcademiesUserCompanyByIdQuery.success = '';

n.useAcademiesUsersAllNudgeMutation = {};
n.useAcademiesUsersAllNudgeMutation.error = '';
n.useAcademiesUsersAllNudgeMutation.success = '';

n.useAcademiesUsersQuery = {};
n.useAcademiesUsersQuery.error = '';
n.useAcademiesUsersQuery.success = '';

n.useAcademiesUsersNudgeMutation = {};
n.useAcademiesUsersNudgeMutation.error = '';
n.useAcademiesUsersNudgeMutation.success = '';

n.useAcademiesUsersPostMutation = {};
n.useAcademiesUsersPostMutation.error = '';
n.useAcademiesUsersPostMutation.success = '';

n.useAcademiesUsersPutMutation = {};
n.useAcademiesUsersPutMutation.error = '';
n.useAcademiesUsersPutMutation.success = '';

n.useAcademyAddedAsContributorEmailQuery = {};
n.useAcademyAddedAsContributorEmailQuery.error = '';
n.useAcademyAddedAsContributorEmailQuery.success = '';

n.useAcademyByAcademyIdIntQuery = {};
n.useAcademyByAcademyIdIntQuery.error = '';
n.useAcademyByAcademyIdIntQuery.success = '';

n.useAcademyNudgeEmailQuery = {};
n.useAcademyNudgeEmailQuery.error = '';
n.useAcademyNudgeEmailQuery.success = '';

n.useAcceptStripeTermsMutation = {};
n.useAcceptStripeTermsMutation.error = '';
n.useAcceptStripeTermsMutation.success = '';

n.useAccountAccessEmailQuery = {};
n.useAccountAccessEmailQuery.error = '';
n.useAccountAccessEmailQuery.success = '';

n.useAccountEmailConfirmationEmailQuery = {};
n.useAccountEmailConfirmationEmailQuery.error = '';
n.useAccountEmailConfirmationEmailQuery.success = '';

n.useAccountPasswordChangedConfirmationEmailQuery = {};
n.useAccountPasswordChangedConfirmationEmailQuery.error = '';
n.useAccountPasswordChangedConfirmationEmailQuery.success = '';

n.useAddAcademyMutation = {};
n.useAddAcademyMutation.error = '';
n.useAddAcademyMutation.success = '';

n.useAddAcademyContentStepMutation = {};
n.useAddAcademyContentStepMutation.error = '';
n.useAddAcademyContentStepMutation.success = '';

n.useAddAcademyLevelMutation = {};
n.useAddAcademyLevelMutation.error = '';
n.useAddAcademyLevelMutation.success = '';

n.useAddAcademyProgramStepsMutation = {};
n.useAddAcademyProgramStepsMutation.error = '';
n.useAddAcademyProgramStepsMutation.success = '';

n.useAddAcademyUserMutation = {};
n.useAddAcademyUserMutation.error = '';
n.useAddAcademyUserMutation.success = '';

n.useAddAcademyUsersMutation = {};
n.useAddAcademyUsersMutation.error = '';
n.useAddAcademyUsersMutation.success = '';

n.useAddAllDatesToMyCalendarMutation = {};
n.useAddAllDatesToMyCalendarMutation.error = '';
n.useAddAllDatesToMyCalendarMutation.success = '';

n.useAddCohortMutation = {};
n.useAddCohortMutation.error = '';
n.useAddCohortMutation.success = '';

n.useAddCompanyBankAccountMutation = {};
n.useAddCompanyBankAccountMutation.error = '';
n.useAddCompanyBankAccountMutation.success = '';

n.useAddCompanyProgramMutation = {};
n.useAddCompanyProgramMutation.error = '';
n.useAddCompanyProgramMutation.success = '';

n.useAddCreditCardAuthorizationMutation = {};
n.useAddCreditCardAuthorizationMutation.error = '';
n.useAddCreditCardAuthorizationMutation.success = '';

n.useAddCurrencyApprovalQuestionMutation = {};
n.useAddCurrencyApprovalQuestionMutation.error = '';
n.useAddCurrencyApprovalQuestionMutation.success = '';

n.useAddCustomProgramMutation = {};
n.useAddCustomProgramMutation.error = '';
n.useAddCustomProgramMutation.success = '';

n.useAddCustomProgramSectionMutation = {};
n.useAddCustomProgramSectionMutation.error = '';
n.useAddCustomProgramSectionMutation.success = '';

n.useAddCustomProgramSectionContentMutation = {};
n.useAddCustomProgramSectionContentMutation.error = '';
n.useAddCustomProgramSectionContentMutation.success = '';

n.useAddDefaultBankAccountToConnectedAccountMutation = {};
n.useAddDefaultBankAccountToConnectedAccountMutation.error = '';
n.useAddDefaultBankAccountToConnectedAccountMutation.success = '';

n.useAddDefaultPreApprovalQuestionsMutation = {};
n.useAddDefaultPreApprovalQuestionsMutation.error = '';
n.useAddDefaultPreApprovalQuestionsMutation.success = '';

n.useAddDefaultReimbursementQuestionsMutation = {};
n.useAddDefaultReimbursementQuestionsMutation.error = '';
n.useAddDefaultReimbursementQuestionsMutation.success = '';

n.useAddDefaultResourcesApprovalQuestionsMutation = {};
n.useAddDefaultResourcesApprovalQuestionsMutation.error = '';
n.useAddDefaultResourcesApprovalQuestionsMutation.success = '';

n.useAddDevCompanyMutation = {};
n.useAddDevCompanyMutation.error = '';
n.useAddDevCompanyMutation.success = '';

n.useAddEventMutation = {};
n.useAddEventMutation.error = '';
n.useAddEventMutation.success = '';

n.useAddFinanceIncentiveMutation = {};
n.useAddFinanceIncentiveMutation.error = '';
n.useAddFinanceIncentiveMutation.success = '';

n.useAddInitiativeMutation = {};
n.useAddInitiativeMutation.error = '';
n.useAddInitiativeMutation.success = '';

n.useAddItemMutation = {};
n.useAddItemMutation.error = '';
n.useAddItemMutation.success = '';

n.useAddMissingUserPlansMutation = {};
n.useAddMissingUserPlansMutation.error = '';
n.useAddMissingUserPlansMutation.success = '';

n.useAddProgramMutation = {};
n.useAddProgramMutation.error = '';
n.useAddProgramMutation.success = '';

n.useAddProgramWithApprovalMutation = {};
n.useAddProgramWithApprovalMutation.error = '';
n.useAddProgramWithApprovalMutation.success = '';

n.useAddReviewMutation = {};
n.useAddReviewMutation.error = '';
n.useAddReviewMutation.success = '';

n.useAddTimeIncentiveMutation = {};
n.useAddTimeIncentiveMutation.error = '';
n.useAddTimeIncentiveMutation.success = '';

n.useAddUserMutation = {};
n.useAddUserMutation.error = '';
n.useAddUserMutation.success = '';

n.useAddUserRecommendationMutation = {};
n.useAddUserRecommendationMutation.error = '';
n.useAddUserRecommendationMutation.success = '';

n.useAddUsersToAcademyMutation = {};
n.useAddUsersToAcademyMutation.error = '';
n.useAddUsersToAcademyMutation.success = '';

n.useAdminApprovalsApprovalDetailsQuery = {};
n.useAdminApprovalsApprovalDetailsQuery.error = '';
n.useAdminApprovalsApprovalDetailsQuery.success = '';

n.useAdminApprovalsApprovalsQuery = {};
n.useAdminApprovalsApprovalsQuery.error = '';
n.useAdminApprovalsApprovalsQuery.success = '';

n.useAdminApprovalsResourceApprovalsQuery = {};
n.useAdminApprovalsResourceApprovalsQuery.error = '';
n.useAdminApprovalsResourceApprovalsQuery.success = '';

n.useAdminApprovalsUpdateApprovalMutation = {};
n.useAdminApprovalsUpdateApprovalMutation.error = '';
n.useAdminApprovalsUpdateApprovalMutation.success = '';

n.useAdminApprovalsUpdateCostCenterMutation = {};
n.useAdminApprovalsUpdateCostCenterMutation.error = '';
n.useAdminApprovalsUpdateCostCenterMutation.success = '';

n.useAdminApprovalsUpdateUserRequestApprovalMutation = {};
n.useAdminApprovalsUpdateUserRequestApprovalMutation.error = '';
n.useAdminApprovalsUpdateUserRequestApprovalMutation.success = '';

n.useAdminApprovalsUserRequestApprovalsQuery = {};
n.useAdminApprovalsUserRequestApprovalsQuery.error = '';
n.useAdminApprovalsUserRequestApprovalsQuery.success = '';

n.useAdminApprovalsUserRequestApprovalsLegacyQuery = {};
n.useAdminApprovalsUserRequestApprovalsLegacyQuery.error = '';
n.useAdminApprovalsUserRequestApprovalsLegacyQuery.success = '';

n.useAttachmentsByIdQuery = {};
n.useAttachmentsByIdQuery.error = '';
n.useAttachmentsByIdQuery.success = '';

n.useAttachmentsUrlMutation = {};
n.useAttachmentsUrlMutation.error = '';
n.useAttachmentsUrlMutation.success = '';

n.useAttachmentsViewByIdQuery = {};
n.useAttachmentsViewByIdQuery.error = '';
n.useAttachmentsViewByIdQuery.success = '';

n.useBudgetDeleteMutation = {};
n.useBudgetDeleteMutation.error = '';
n.useBudgetDeleteMutation.success = '';

n.useBudgetIdMutation = {};
n.useBudgetIdMutation.error = '';
n.useBudgetIdMutation.success = '';

n.useBudgetPostMutation = {};
n.useBudgetPostMutation.error = '';
n.useBudgetPostMutation.success = '';

n.useByTypeQuery = {};
n.useByTypeQuery.error = '';
n.useByTypeQuery.success = '';

n.useCardPaymentInfoQuery = {};
n.useCardPaymentInfoQuery.error = '';
n.useCardPaymentInfoQuery.success = '';

n.useCheckAdminPermissionQuery = {};
n.useCheckAdminPermissionQuery.error = '';
n.useCheckAdminPermissionQuery.success = '';

n.useCheckPublishedCustomProgramsExistQuery = {};
n.useCheckPublishedCustomProgramsExistQuery.error = '';
n.useCheckPublishedCustomProgramsExistQuery.success = '';

n.useCheckUserStatusQuery = {};
n.useCheckUserStatusQuery.error = '';
n.useCheckUserStatusQuery.success = '';

n.useCompaniesUsersQuery = {};
n.useCompaniesUsersQuery.error = '';
n.useCompaniesUsersQuery.success = '';

n.useCompleteResourceMutation = {};
n.useCompleteResourceMutation.error = '';
n.useCompleteResourceMutation.success = '';

n.useConnectCalendarMutation = {};
n.useConnectCalendarMutation.error = '';
n.useConnectCalendarMutation.success = '';

n.useCreateSkillsMutation = {};
n.useCreateSkillsMutation.error = '';
n.useCreateSkillsMutation.success = '';

n.useCreateacademyMutation = {};
n.useCreateacademyMutation.error = '';
n.useCreateacademyMutation.success = '';

n.useCreateacademyenrollmentMutation = {};
n.useCreateacademyenrollmentMutation.error = '';
n.useCreateacademyenrollmentMutation.success = '';

n.useCreateClientStripeCustomAccountMutation = {};
n.useCreateClientStripeCustomAccountMutation.error = '';
n.useCreateClientStripeCustomAccountMutation.success = '';

n.useCreateOrganizationMutation = {};
n.useCreateOrganizationMutation.error = '';
n.useCreateOrganizationMutation.success = '';

n.useCreateSkillUpdateMutation = {};
n.useCreateSkillUpdateMutation.error = '';
n.useCreateSkillUpdateMutation.success = '';

n.useCreateusercompletionMutation = {};
n.useCreateusercompletionMutation.error = '';
n.useCreateusercompletionMutation.success = '';

n.useCustomProgramAddDateToMyCalendarMutation = {};
n.useCustomProgramAddDateToMyCalendarMutation.error = '';
n.useCustomProgramAddDateToMyCalendarMutation.success = '';

n.useCustomProgramAddUserPermissionsMutation = {};
n.useCustomProgramAddUserPermissionsMutation.error = '';
n.useCustomProgramAddUserPermissionsMutation.success = '';

n.useCustomProgramContentDeleteAttachmentsMutation = {};
n.useCustomProgramContentDeleteAttachmentsMutation.error = '';
n.useCustomProgramContentDeleteAttachmentsMutation.success = '';

n.useCustomProgramDeleteUserPermissionMutation = {};
n.useCustomProgramDeleteUserPermissionMutation.error = '';
n.useCustomProgramDeleteUserPermissionMutation.success = '';

n.useCustomProgramContentAttachmentQuery = {};
n.useCustomProgramContentAttachmentQuery.error = '';
n.useCustomProgramContentAttachmentQuery.success = '';

n.useCustomProgramContentAttachmentUrlQuery = {};
n.useCustomProgramContentAttachmentUrlQuery.error = '';
n.useCustomProgramContentAttachmentUrlQuery.success = '';

n.useCustomProgramProjectSubmissionsQuery = {};
n.useCustomProgramProjectSubmissionsQuery.error = '';
n.useCustomProgramProjectSubmissionsQuery.success = '';

n.useCustomProgramProjectSubmissionUrlQuery = {};
n.useCustomProgramProjectSubmissionUrlQuery.error = '';
n.useCustomProgramProjectSubmissionUrlQuery.success = '';

n.useCustomProgramStartedQuery = {};
n.useCustomProgramStartedQuery.error = '';
n.useCustomProgramStartedQuery.success = '';

n.useCustomProgramUserPermissionsQuery = {};
n.useCustomProgramUserPermissionsQuery.error = '';
n.useCustomProgramUserPermissionsQuery.success = '';

n.useCustomProgramNudgeEmailQuery = {};
n.useCustomProgramNudgeEmailQuery.error = '';
n.useCustomProgramNudgeEmailQuery.success = '';

n.useCustomProgramSearchQuery = {};
n.useCustomProgramSearchQuery.error = '';
n.useCustomProgramSearchQuery.success = '';

n.useCustomProgramsLevelsStepsMutation = {};
n.useCustomProgramsLevelsStepsMutation.error = '';
n.useCustomProgramsLevelsStepsMutation.success = '';

n.useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation = {};
n.useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation.error = '';
n.useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation.success =
  '';

n.useCustomProgramsSectionsContentsDiscussionsMessagesQuery = {};
n.useCustomProgramsSectionsContentsDiscussionsMessagesQuery.error = '';
n.useCustomProgramsSectionsContentsDiscussionsMessagesQuery.success = '';

n.useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation = {};
n.useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation.error = '';
n.useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation.success = '';

n.useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation = {};
n.useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation.error = '';
n.useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation.success = '';

n.useCustomProgramsSectionsContentsSubmissionsQuery = {};
n.useCustomProgramsSectionsContentsSubmissionsQuery.error = '';
n.useCustomProgramsSectionsContentsSubmissionsQuery.success = '';

n.useCustomProgramSubmitContentFeedbackMutation = {};
n.useCustomProgramSubmitContentFeedbackMutation.error = '';
n.useCustomProgramSubmitContentFeedbackMutation.success = '';

n.useCustomProgramsUsersAllNudgeMutation = {};
n.useCustomProgramsUsersAllNudgeMutation.error = '';
n.useCustomProgramsUsersAllNudgeMutation.success = '';

n.useCustomProgramsUsersByIdQuery = {};
n.useCustomProgramsUsersByIdQuery.error = '';
n.useCustomProgramsUsersByIdQuery.success = '';

n.useCustomProgramsUsersDeleteMutation = {};
n.useCustomProgramsUsersDeleteMutation.error = '';
n.useCustomProgramsUsersDeleteMutation.success = '';

n.useCustomProgramsUsersQuery = {};
n.useCustomProgramsUsersQuery.error = '';
n.useCustomProgramsUsersQuery.success = '';

n.useCustomProgramsUsersNudgeMutation = {};
n.useCustomProgramsUsersNudgeMutation.error = '';
n.useCustomProgramsUsersNudgeMutation.success = '';

n.useCustomProgramsUsersPostMutation = {};
n.useCustomProgramsUsersPostMutation.error = '';
n.useCustomProgramsUsersPostMutation.success = '';

n.useCustomProgramUpdateCustomProgramContentMutation = {};
n.useCustomProgramUpdateCustomProgramContentMutation.error = '';
n.useCustomProgramUpdateCustomProgramContentMutation.success = '';

n.useCustomProgramUpdateUserPermissionMutation = {};
n.useCustomProgramUpdateUserPermissionMutation.error = '';
n.useCustomProgramUpdateUserPermissionMutation.success = '';

n.useCustomProgramUploadContentAttachmentMutation = {};
n.useCustomProgramUploadContentAttachmentMutation.error = '';
n.useCustomProgramUploadContentAttachmentMutation.success = '';

n.useDeleteSkillsMutation = {};
n.useDeleteSkillsMutation.error = '';
n.useDeleteSkillsMutation.success = '';

n.useDeleteAcademyMutation = {};
n.useDeleteAcademyMutation.error = '';
n.useDeleteAcademyMutation.success = '';

n.useDeleteAcademyCoverImageMutation = {};
n.useDeleteAcademyCoverImageMutation.error = '';
n.useDeleteAcademyCoverImageMutation.success = '';

n.useDeleteAcademyLevelMutation = {};
n.useDeleteAcademyLevelMutation.error = '';
n.useDeleteAcademyLevelMutation.success = '';

n.useDeleteAcademyStepMutation = {};
n.useDeleteAcademyStepMutation.error = '';
n.useDeleteAcademyStepMutation.success = '';

n.useDeleteAcademyStepContentAllAttachmentsMutation = {};
n.useDeleteAcademyStepContentAllAttachmentsMutation.error = '';
n.useDeleteAcademyStepContentAllAttachmentsMutation.success = '';

n.useDeleteAcademyStepContentAttachmentMutation = {};
n.useDeleteAcademyStepContentAttachmentMutation.error = '';
n.useDeleteAcademyStepContentAttachmentMutation.success = '';

n.useDeleteAcademyUserMutation = {};
n.useDeleteAcademyUserMutation.error = '';
n.useDeleteAcademyUserMutation.success = '';

n.useDeleteCohortMutation = {};
n.useDeleteCohortMutation.error = '';
n.useDeleteCohortMutation.success = '';

n.useDeleteCompanyBankAccountMutation = {};
n.useDeleteCompanyBankAccountMutation.error = '';
n.useDeleteCompanyBankAccountMutation.success = '';

n.useDeleteContentAttachmentMutation = {};
n.useDeleteContentAttachmentMutation.error = '';
n.useDeleteContentAttachmentMutation.success = '';

n.useDeleteCustomProgramMutation = {};
n.useDeleteCustomProgramMutation.error = '';
n.useDeleteCustomProgramMutation.success = '';

n.useDeleteCustomProgramContentMutation = {};
n.useDeleteCustomProgramContentMutation.error = '';
n.useDeleteCustomProgramContentMutation.success = '';

n.useDeleteCustomProgramCoverImageMutation = {};
n.useDeleteCustomProgramCoverImageMutation.error = '';
n.useDeleteCustomProgramCoverImageMutation.success = '';

n.useDeleteCustomProgramSectionMutation = {};
n.useDeleteCustomProgramSectionMutation.error = '';
n.useDeleteCustomProgramSectionMutation.success = '';

n.useDeleteIncentiveMutation = {};
n.useDeleteIncentiveMutation.error = '';
n.useDeleteIncentiveMutation.success = '';

n.useDeleteInitiativeMutation = {};
n.useDeleteInitiativeMutation.error = '';
n.useDeleteInitiativeMutation.success = '';

n.useDeleteItemMutation = {};
n.useDeleteItemMutation.error = '';
n.useDeleteItemMutation.success = '';

n.useDeleteItemByUserPlanItemIdMutation = {};
n.useDeleteItemByUserPlanItemIdMutation.error = '';
n.useDeleteItemByUserPlanItemIdMutation.success = '';

n.useDeleteProgramMutation = {};
n.useDeleteProgramMutation.error = '';
n.useDeleteProgramMutation.success = '';

n.useDeleteProgramByProgramIdMutation = {};
n.useDeleteProgramByProgramIdMutation.error = '';
n.useDeleteProgramByProgramIdMutation.success = '';

n.useDeleteProgramByUserPlanItemIdMutation = {};
n.useDeleteProgramByUserPlanItemIdMutation.error = '';
n.useDeleteProgramByUserPlanItemIdMutation.success = '';

n.useDeleteReimbursementImageMutation = {};
n.useDeleteReimbursementImageMutation.error = '';
n.useDeleteReimbursementImageMutation.success = '';

n.useDeleteResourceMutation = {};
n.useDeleteResourceMutation.error = '';
n.useDeleteResourceMutation.success = '';

n.useDeleteReviewMutation = {};
n.useDeleteReviewMutation.error = '';
n.useDeleteReviewMutation.success = '';

n.useDeleteSelfAcademyUserMutation = {};
n.useDeleteSelfAcademyUserMutation.error = '';
n.useDeleteSelfAcademyUserMutation.success = '';

n.useDeleteUserMutation = {};
n.useDeleteUserMutation.error = '';
n.useDeleteUserMutation.success = '';

n.useDeleteUserRecommendationMutation = {};
n.useDeleteUserRecommendationMutation.error = '';
n.useDeleteUserRecommendationMutation.success = '';

n.useDevNotificationEmailQuery = {};
n.useDevNotificationEmailQuery.error = '';
n.useDevNotificationEmailQuery.success = '';

n.useDisableInvalidVirtualCardsMutation = {};
n.useDisableInvalidVirtualCardsMutation.error = '';
n.useDisableInvalidVirtualCardsMutation.success = '';

n.useDisconnectCalendarMutation = {};
n.useDisconnectCalendarMutation.error = '';
n.useDisconnectCalendarMutation.success = '';

n.useDoProgramsRequireApprovalQuery = {};
n.useDoProgramsRequireApprovalQuery.error = '';
n.useDoProgramsRequireApprovalQuery.success = '';

n.useDuplicateAcademyMutation = {};
n.useDuplicateAcademyMutation.error = '';
n.useDuplicateAcademyMutation.success = '';

n.useDuplicateAcademyStepContentMutation = {};
n.useDuplicateAcademyStepContentMutation.error = '';
n.useDuplicateAcademyStepContentMutation.success = '';

n.useDuplicateCustomProgramMutation = {};
n.useDuplicateCustomProgramMutation.error = '';
n.useDuplicateCustomProgramMutation.success = '';

n.useDuplicateCustomProgramContentMutation = {};
n.useDuplicateCustomProgramContentMutation.error = '';
n.useDuplicateCustomProgramContentMutation.success = '';

n.useEditCompanyProgramMutation = {};
n.useEditCompanyProgramMutation.error = '';
n.useEditCompanyProgramMutation.success = '';

n.useEventReminderEmailQuery = {};
n.useEventReminderEmailQuery.error = '';
n.useEventReminderEmailQuery.success = '';

n.useExchangeRatesQuery = {};
n.useExchangeRatesQuery.error = '';
n.useExchangeRatesQuery.success = '';

n.useFeatureProgramMutation = {};
n.useFeatureProgramMutation.error = '';
n.useFeatureProgramMutation.success = '';

n.useFixUsersCreditCardSpendingLimitMutation = {};
n.useFixUsersCreditCardSpendingLimitMutation.error = '';
n.useFixUsersCreditCardSpendingLimitMutation.success = '';

n.useGenerateVoucherCodesMutation = {};
n.useGenerateVoucherCodesMutation.error = '';
n.useGenerateVoucherCodesMutation.success = '';

n.useAcademiesQuery = {};
n.useAcademiesQuery.error = '';
n.useAcademiesQuery.success = '';

n.useAcademiesCountsQuery = {};
n.useAcademiesCountsQuery.error = '';
n.useAcademiesCountsQuery.success = '';

n.useAcademyQuery = {};
n.useAcademyQuery.error = '';
n.useAcademyQuery.success = '';

n.useAcademyEditorsQuery = {};
n.useAcademyEditorsQuery.error = '';
n.useAcademyEditorsQuery.success = '';

n.useAcademyLevelDetailQuery = {};
n.useAcademyLevelDetailQuery.error = '';
n.useAcademyLevelDetailQuery.success = '';

n.useAcademyLevelsQuery = {};
n.useAcademyLevelsQuery.error = '';
n.useAcademyLevelsQuery.success = '';

n.useAcademyLevelStepQuery = {};
n.useAcademyLevelStepQuery.error = '';
n.useAcademyLevelStepQuery.success = '';

n.useAcademyOverviewLevelReportQuery = {};
n.useAcademyOverviewLevelReportQuery.error = '';
n.useAcademyOverviewLevelReportQuery.success = '';

n.useAcademyOverviewLevelUserReportQuery = {};
n.useAcademyOverviewLevelUserReportQuery.error = '';
n.useAcademyOverviewLevelUserReportQuery.success = '';

n.useAcademyOverviewReportQuery = {};
n.useAcademyOverviewReportQuery.error = '';
n.useAcademyOverviewReportQuery.success = '';

n.useAcademyOverviewStepUserReportQuery = {};
n.useAcademyOverviewStepUserReportQuery.error = '';
n.useAcademyOverviewStepUserReportQuery.success = '';

n.useAcademyOverviewUserReportQuery = {};
n.useAcademyOverviewUserReportQuery.error = '';
n.useAcademyOverviewUserReportQuery.success = '';

n.useAcademyProjectReviewersQuery = {};
n.useAcademyProjectReviewersQuery.error = '';
n.useAcademyProjectReviewersQuery.success = '';

n.useAcademyStepContentAttachmentsQuery = {};
n.useAcademyStepContentAttachmentsQuery.error = '';
n.useAcademyStepContentAttachmentsQuery.success = '';

n.useAcademyStepContentLinksQuery = {};
n.useAcademyStepContentLinksQuery.error = '';
n.useAcademyStepContentLinksQuery.success = '';

n.useAcademyUsersQuery = {};
n.useAcademyUsersQuery.error = '';
n.useAcademyUsersQuery.success = '';

n.useAllUsersQuery = {};
n.useAllUsersQuery.error = '';
n.useAllUsersQuery.success = '';

n.useAudiencesQuery = {};
n.useAudiencesQuery.error = '';
n.useAudiencesQuery.success = '';

n.useAvailableProvidersQuery = {};
n.useAvailableProvidersQuery.error = '';
n.useAvailableProvidersQuery.success = '';

n.useCardOverviewQuery = {};
n.useCardOverviewQuery.error = '';
n.useCardOverviewQuery.success = '';

n.useCardTransactionsQuery = {};
n.useCardTransactionsQuery.error = '';
n.useCardTransactionsQuery.success = '';

n.useCohortsQuery = {};
n.useCohortsQuery.error = '';
n.useCohortsQuery.success = '';

n.useCompaniesQuery = {};
n.useCompaniesQuery.error = '';
n.useCompaniesQuery.success = '';

n.useConnectedBankAccountsQuery = {};
n.useConnectedBankAccountsQuery.error = '';
n.useConnectedBankAccountsQuery.success = '';

n.useCostCentersQuery = {};
n.useCostCentersQuery.error = '';
n.useCostCentersQuery.success = '';

n.useCustomProgramQuery = {};
n.useCustomProgramQuery.error = '';
n.useCustomProgramQuery.success = '';

n.useCustomProgramActivityReportQuery = {};
n.useCustomProgramActivityReportQuery.error = '';
n.useCustomProgramActivityReportQuery.success = '';

n.useCustomProgramAgendaQuery = {};
n.useCustomProgramAgendaQuery.error = '';
n.useCustomProgramAgendaQuery.success = '';

n.useCustomProgramContentAttachmentsQuery = {};
n.useCustomProgramContentAttachmentsQuery.error = '';
n.useCustomProgramContentAttachmentsQuery.success = '';

n.useCustomProgramContentEmployeesProgressReportQuery = {};
n.useCustomProgramContentEmployeesProgressReportQuery.error = '';
n.useCustomProgramContentEmployeesProgressReportQuery.success = '';

n.useCustomProgramContentLinksQuery = {};
n.useCustomProgramContentLinksQuery.error = '';
n.useCustomProgramContentLinksQuery.success = '';

n.useCustomProgramContentProgressByEmployeeReportQuery = {};
n.useCustomProgramContentProgressByEmployeeReportQuery.error = '';
n.useCustomProgramContentProgressByEmployeeReportQuery.success = '';

n.useCustomProgramContentProgressReportQuery = {};
n.useCustomProgramContentProgressReportQuery.error = '';
n.useCustomProgramContentProgressReportQuery.success = '';

n.useCustomProgramPreviewQuery = {};
n.useCustomProgramPreviewQuery.error = '';
n.useCustomProgramPreviewQuery.success = '';

n.useCustomProgramProjectsQuery = {};
n.useCustomProgramProjectsQuery.error = '';
n.useCustomProgramProjectsQuery.success = '';

n.useCustomProgramSectionEmployeesProgressReportQuery = {};
n.useCustomProgramSectionEmployeesProgressReportQuery.error = '';
n.useCustomProgramSectionEmployeesProgressReportQuery.success = '';

n.useCustomProgramSectionsQuery = {};
n.useCustomProgramSectionsQuery.error = '';
n.useCustomProgramSectionsQuery.success = '';

n.useCustomProgramSectionsProgressByEmployeeReportQuery = {};
n.useCustomProgramSectionsProgressByEmployeeReportQuery.error = '';
n.useCustomProgramSectionsProgressByEmployeeReportQuery.success = '';

n.useCustomProgramSectionsProgressReportQuery = {};
n.useCustomProgramSectionsProgressReportQuery.error = '';
n.useCustomProgramSectionsProgressReportQuery.success = '';

n.useDurationsQuery = {};
n.useDurationsQuery.error = '';
n.useDurationsQuery.success = '';

n.useEmployeeEngagementQuery = {};
n.useEmployeeEngagementQuery.error = '';
n.useEmployeeEngagementQuery.success = '';

n.useEnrolledAcademiesQuery = {};
n.useEnrolledAcademiesQuery.error = '';
n.useEnrolledAcademiesQuery.success = '';

n.useFeaturedQuery = {};
n.useFeaturedQuery.error = '';
n.useFeaturedQuery.success = '';

n.useFinanceApprovedVsSpentQuery = {};
n.useFinanceApprovedVsSpentQuery.error = '';
n.useFinanceApprovedVsSpentQuery.success = '';

n.useFinanceItemsQuery = {};
n.useFinanceItemsQuery.error = '';
n.useFinanceItemsQuery.success = '';

n.useFinanceLearningBudgetsQuery = {};
n.useFinanceLearningBudgetsQuery.error = '';
n.useFinanceLearningBudgetsQuery.success = '';

n.useIncentiveCountsQuery = {};
n.useIncentiveCountsQuery.error = '';
n.useIncentiveCountsQuery.success = '';

n.useIncentiveInitiativesQuery = {};
n.useIncentiveInitiativesQuery.error = '';
n.useIncentiveInitiativesQuery.success = '';

n.useIncentiveProgramTypesQuery = {};
n.useIncentiveProgramTypesQuery.error = '';
n.useIncentiveProgramTypesQuery.success = '';

n.useIncentivesQuery = {};
n.useIncentivesQuery.error = '';
n.useIncentivesQuery.success = '';

n.useIncentivesAdminQuery = {};
n.useIncentivesAdminQuery.error = '';
n.useIncentivesAdminQuery.success = '';

n.useInitiativeIncentivesQuery = {};
n.useInitiativeIncentivesQuery.error = '';
n.useInitiativeIncentivesQuery.success = '';

n.useInitiativePurposeDescriptionsQuery = {};
n.useInitiativePurposeDescriptionsQuery.error = '';
n.useInitiativePurposeDescriptionsQuery.success = '';

n.useInitiativesAdminQuery = {};
n.useInitiativesAdminQuery.error = '';
n.useInitiativesAdminQuery.success = '';

n.useIssuingBalanceDetailsQuery = {};
n.useIssuingBalanceDetailsQuery.error = '';
n.useIssuingBalanceDetailsQuery.success = '';

n.useIssuingBalanceHistoryQuery = {};
n.useIssuingBalanceHistoryQuery.error = '';
n.useIssuingBalanceHistoryQuery.success = '';

n.useItemQuery = {};
n.useItemQuery.error = '';
n.useItemQuery.success = '';

n.useItemPreApprovalApplicationQuery = {};
n.useItemPreApprovalApplicationQuery.error = '';
n.useItemPreApprovalApplicationQuery.success = '';

n.useManagedAcademiesQuery = {};
n.useManagedAcademiesQuery.error = '';
n.useManagedAcademiesQuery.success = '';

n.useManagedProgramsQuery = {};
n.useManagedProgramsQuery.error = '';
n.useManagedProgramsQuery.success = '';

n.useMaxPossiblePriceForFilterQuery = {};
n.useMaxPossiblePriceForFilterQuery.error = '';
n.useMaxPossiblePriceForFilterQuery.success = '';

n.useOnboardingLinkQuery = {};
n.useOnboardingLinkQuery.error = '';
n.useOnboardingLinkQuery.success = '';

n.useParentCustomProgramsQuery = {};
n.useParentCustomProgramsQuery.error = '';
n.useParentCustomProgramsQuery.success = '';

n.useParticipantsQuery = {};
n.useParticipantsQuery.error = '';
n.useParticipantsQuery.success = '';

n.usePeerViewableAcademySubmissionsQuery = {};
n.usePeerViewableAcademySubmissionsQuery.error = '';
n.usePeerViewableAcademySubmissionsQuery.success = '';

n.usePendingManagerApprovalsQuery = {};
n.usePendingManagerApprovalsQuery.error = '';
n.usePendingManagerApprovalsQuery.success = '';

n.usePendingManagerUserRequestApprovalsQuery = {};
n.usePendingManagerUserRequestApprovalsQuery.error = '';
n.usePendingManagerUserRequestApprovalsQuery.success = '';

n.usePersonalizationQuery = {};
n.usePersonalizationQuery.error = '';
n.usePersonalizationQuery.success = '';

n.usePlaidLinkConfigQuery = {};
n.usePlaidLinkConfigQuery.error = '';
n.usePlaidLinkConfigQuery.success = '';

n.usePlanQuery = {};
n.usePlanQuery.error = '';
n.usePlanQuery.success = '';

n.usePlbBalanceQuery = {};
n.usePlbBalanceQuery.error = '';
n.usePlbBalanceQuery.success = '';

n.usePopularProgramsQuery = {};
n.usePopularProgramsQuery.error = '';
n.usePopularProgramsQuery.success = '';

n.usePositionAudiencesQuery = {};
n.usePositionAudiencesQuery.error = '';
n.usePositionAudiencesQuery.success = '';

n.usePreApprovalApplicationQuery = {};
n.usePreApprovalApplicationQuery.error = '';
n.usePreApprovalApplicationQuery.success = '';

n.usePreapprovedPlanItemsQuery = {};
n.usePreapprovedPlanItemsQuery.error = '';
n.usePreapprovedPlanItemsQuery.success = '';

n.usePrepaymentReportQuery = {};
n.usePrepaymentReportQuery.error = '';
n.usePrepaymentReportQuery.success = '';

n.useProgramInitiativesQuery = {};
n.useProgramInitiativesQuery.error = '';
n.useProgramInitiativesQuery.success = '';

n.useProgramItemsQuery = {};
n.useProgramItemsQuery.error = '';
n.useProgramItemsQuery.success = '';

n.useProgramProgressQuery = {};
n.useProgramProgressQuery.error = '';
n.useProgramProgressQuery.success = '';

n.useProgramProgressUpdatesReportQuery = {};
n.useProgramProgressUpdatesReportQuery.error = '';
n.useProgramProgressUpdatesReportQuery.success = '';

n.useProgramsQuery = {};
n.useProgramsQuery.error = '';
n.useProgramsQuery.success = '';

n.useProgramsUsageReportQuery = {};
n.useProgramsUsageReportQuery.error = '';
n.useProgramsUsageReportQuery.success = '';

n.useProgramTitlesQuery = {};
n.useProgramTitlesQuery.error = '';
n.useProgramTitlesQuery.success = '';

n.useProgramTypesQuery = {};
n.useProgramTypesQuery.error = '';
n.useProgramTypesQuery.success = '';

n.useProgressUpdatesBySummaryQuery = {};
n.useProgressUpdatesBySummaryQuery.error = '';
n.useProgressUpdatesBySummaryQuery.success = '';

n.useProjectsQuery = {};
n.useProjectsQuery.error = '';
n.useProjectsQuery.success = '';

n.useProvidersQuery = {};
n.useProvidersQuery.error = '';
n.useProvidersQuery.success = '';

n.usePublishedAcademiesQuery = {};
n.usePublishedAcademiesQuery.error = '';
n.usePublishedAcademiesQuery.success = '';

n.useRecommendationsQuery = {};
n.useRecommendationsQuery.error = '';
n.useRecommendationsQuery.success = '';

n.useReimbursementQuery = {};
n.useReimbursementQuery.error = '';
n.useReimbursementQuery.success = '';

n.useReimbursementApprovalApplicationsQuery = {};
n.useReimbursementApprovalApplicationsQuery.error = '';
n.useReimbursementApprovalApplicationsQuery.success = '';

n.useReimbursementImageQuery = {};
n.useReimbursementImageQuery.error = '';
n.useReimbursementImageQuery.success = '';

n.useReimbursementImageUrlQuery = {};
n.useReimbursementImageUrlQuery.error = '';
n.useReimbursementImageUrlQuery.success = '';

n.useReimbursementsExportQuery = {};
n.useReimbursementsExportQuery.error = '';
n.useReimbursementsExportQuery.success = '';

n.useReimbursementsReportQuery = {};
n.useReimbursementsReportQuery.error = '';
n.useReimbursementsReportQuery.success = '';

n.useReimbursementTargetsQuery = {};
n.useReimbursementTargetsQuery.error = '';
n.useReimbursementTargetsQuery.success = '';

n.useResourceApprovalApplicationQuery = {};
n.useResourceApprovalApplicationQuery.error = '';
n.useResourceApprovalApplicationQuery.success = '';

n.useResourceItemsQuery = {};
n.useResourceItemsQuery.error = '';
n.useResourceItemsQuery.success = '';

n.useResourcesApprovalApplicationsQuery = {};
n.useResourcesApprovalApplicationsQuery.error = '';
n.useResourcesApprovalApplicationsQuery.success = '';

n.useReviewsQuery = {};
n.useReviewsQuery.error = '';
n.useReviewsQuery.success = '';

n.useSettingsQuery = {};
n.useSettingsQuery.error = '';
n.useSettingsQuery.success = '';

n.useSkillsQuery = {};
n.useSkillsQuery.error = '';
n.useSkillsQuery.success = '';

n.useSkillTagsQuery = {};
n.useSkillTagsQuery.error = '';
n.useSkillTagsQuery.success = '';

n.useTeamInsightDetailQuery = {};
n.useTeamInsightDetailQuery.error = '';
n.useTeamInsightDetailQuery.success = '';

n.useTeamInsightOverviewQuery = {};
n.useTeamInsightOverviewQuery.error = '';
n.useTeamInsightOverviewQuery.success = '';

n.useTimeBalanceQuery = {};
n.useTimeBalanceQuery.error = '';
n.useTimeBalanceQuery.success = '';

n.useTimeItemsQuery = {};
n.useTimeItemsQuery.error = '';
n.useTimeItemsQuery.success = '';

n.useTimezonesQuery = {};
n.useTimezonesQuery.error = '';
n.useTimezonesQuery.success = '';

n.useTopicsQuery = {};
n.useTopicsQuery.error = '';
n.useTopicsQuery.success = '';

n.useTransactionsReportQuery = {};
n.useTransactionsReportQuery.error = '';
n.useTransactionsReportQuery.success = '';

n.useTransactionYearsQuery = {};
n.useTransactionYearsQuery.error = '';
n.useTransactionYearsQuery.success = '';

n.useTypesQuery = {};
n.useTypesQuery.error = '';
n.useTypesQuery.success = '';

n.useUpcomingCohortsQuery = {};
n.useUpcomingCohortsQuery.error = '';
n.useUpcomingCohortsQuery.success = '';

n.useUrlQuery = {};
n.useUrlQuery.error = '';
n.useUrlQuery.success = '';

n.useUserQuery = {};
n.useUserQuery.error = '';
n.useUserQuery.success = '';

n.useUserAcademyLevelStepQuery = {};
n.useUserAcademyLevelStepQuery.error = '';
n.useUserAcademyLevelStepQuery.success = '';

n.useUserCreditCardsQuery = {};
n.useUserCreditCardsQuery.error = '';
n.useUserCreditCardsQuery.success = '';

n.useUserCurrentFinanceIncentiveDetailsQuery = {};
n.useUserCurrentFinanceIncentiveDetailsQuery.error = '';
n.useUserCurrentFinanceIncentiveDetailsQuery.success = '';

n.useUserDetailsQuery = {};
n.useUserDetailsQuery.error = '';
n.useUserDetailsQuery.success = '';

n.useUserEventsQuery = {};
n.useUserEventsQuery.error = '';
n.useUserEventsQuery.success = '';

n.useUserLanguagePreferenceQuery = {};
n.useUserLanguagePreferenceQuery.error = '';
n.useUserLanguagePreferenceQuery.success = '';

n.useUserReimbursementsQuery = {};
n.useUserReimbursementsQuery.error = '';
n.useUserReimbursementsQuery.success = '';

n.useUserTransactionsReportQuery = {};
n.useUserTransactionsReportQuery.error = '';
n.useUserTransactionsReportQuery.success = '';

n.useViewLicenseQuery = {};
n.useViewLicenseQuery.error = '';
n.useViewLicenseQuery.success = '';

n.useHardResetCurrentUserCollaborationChannelMembershipsMutation = {};
n.useHardResetCurrentUserCollaborationChannelMembershipsMutation.error = '';
n.useHardResetCurrentUserCollaborationChannelMembershipsMutation.success = '';

n.useHardResetCurrentUsersPlanMutation = {};
n.useHardResetCurrentUsersPlanMutation.error = '';
n.useHardResetCurrentUsersPlanMutation.success = '';

n.useHardResetCustomProgramsMutation = {};
n.useHardResetCustomProgramsMutation.error = '';
n.useHardResetCustomProgramsMutation.success = '';

n.useHardResetFinanceIncentivesMutation = {};
n.useHardResetFinanceIncentivesMutation.error = '';
n.useHardResetFinanceIncentivesMutation.success = '';

n.useHardResetTimeIncentivesMutation = {};
n.useHardResetTimeIncentivesMutation.error = '';
n.useHardResetTimeIncentivesMutation.success = '';

n.useHardResetUserPlansMutation = {};
n.useHardResetUserPlansMutation.error = '';
n.useHardResetUserPlansMutation.success = '';

n.useHasResourcesEnabledQuery = {};
n.useHasResourcesEnabledQuery.error = '';
n.useHasResourcesEnabledQuery.success = '';

n.useImportIncentivesMutation = {};
n.useImportIncentivesMutation.error = '';
n.useImportIncentivesMutation.success = '';

n.useIncentiveDetailsQuery = {};
n.useIncentiveDetailsQuery.error = '';
n.useIncentiveDetailsQuery.success = '';

n.useIncentiveStartedQuery = {};
n.useIncentiveStartedQuery.error = '';
n.useIncentiveStartedQuery.success = '';

n.useInitiativeDetailsQuery = {};
n.useInitiativeDetailsQuery.error = '';
n.useInitiativeDetailsQuery.success = '';

n.useIsOnboardingStartedQuery = {};
n.useIsOnboardingStartedQuery.error = '';
n.useIsOnboardingStartedQuery.success = '';

n.useLicensesByIdQuery = {};
n.useLicensesByIdQuery.error = '';
n.useLicensesByIdQuery.success = '';

n.useLicensesDeleteMutation = {};
n.useLicensesDeleteMutation.error = '';
n.useLicensesDeleteMutation.success = '';

n.useLicensesPatchMutation = {};
n.useLicensesPatchMutation.error = '';
n.useLicensesPatchMutation.success = '';

n.useLicensesPostMutation = {};
n.useLicensesPostMutation.error = '';
n.useLicensesPostMutation.success = '';

n.useLxpcontentSearchMutation = {};
n.useLxpcontentSearchMutation.error = '';
n.useLxpcontentSearchMutation.success = '';

n.useManagerApprovalsApprovalDetailsQuery = {};
n.useManagerApprovalsApprovalDetailsQuery.error = '';
n.useManagerApprovalsApprovalDetailsQuery.success = '';

n.useManagerApprovalsApprovalsQuery = {};
n.useManagerApprovalsApprovalsQuery.error = '';
n.useManagerApprovalsApprovalsQuery.success = '';

n.useManagerApprovalsResourceApprovalsQuery = {};
n.useManagerApprovalsResourceApprovalsQuery.error = '';
n.useManagerApprovalsResourceApprovalsQuery.success = '';

n.useManagerApprovalsUpdateApprovalMutation = {};
n.useManagerApprovalsUpdateApprovalMutation.error = '';
n.useManagerApprovalsUpdateApprovalMutation.success = '';

n.useManagerApprovalsUpdateCostCenterMutation = {};
n.useManagerApprovalsUpdateCostCenterMutation.error = '';
n.useManagerApprovalsUpdateCostCenterMutation.success = '';

n.useManagerApprovalsUpdateUserRequestApprovalMutation = {};
n.useManagerApprovalsUpdateUserRequestApprovalMutation.error = '';
n.useManagerApprovalsUpdateUserRequestApprovalMutation.success = '';

n.useManagerApprovalsUserRequestApprovalsQuery = {};
n.useManagerApprovalsUserRequestApprovalsQuery.error = '';
n.useManagerApprovalsUserRequestApprovalsQuery.success = '';

n.useManagerApprovalsUserRequestApprovalsLegacyQuery = {};
n.useManagerApprovalsUserRequestApprovalsLegacyQuery.error = '';
n.useManagerApprovalsUserRequestApprovalsLegacyQuery.success = '';

n.useMigrationUserPlanApprovalToUserRequestMutation = {};
n.useMigrationUserPlanApprovalToUserRequestMutation.error = '';
n.useMigrationUserPlanApprovalToUserRequestMutation.success = '';

n.usePaymentCardDeclinedEmailQuery = {};
n.usePaymentCardDeclinedEmailQuery.error = '';
n.usePaymentCardDeclinedEmailQuery.success = '';

n.usePlanAddedToPlanEmailQuery = {};
n.usePlanAddedToPlanEmailQuery.error = '';
n.usePlanAddedToPlanEmailQuery.success = '';

n.useProgramCompletionEmailQuery = {};
n.useProgramCompletionEmailQuery.error = '';
n.useProgramCompletionEmailQuery.success = '';

n.useProgramFinderSearchQuery = {};
n.useProgramFinderSearchQuery.error = '';
n.useProgramFinderSearchQuery.success = '';

n.useProgramDetailsQuery = {};
n.useProgramDetailsQuery.error = '';
n.useProgramDetailsQuery.success = '';

n.useProgramStartedQuery = {};
n.useProgramStartedQuery.error = '';
n.useProgramStartedQuery.success = '';

n.useProgramNominationEmailQuery = {};
n.useProgramNominationEmailQuery.error = '';
n.useProgramNominationEmailQuery.success = '';

n.useProgramNudgeEmailQuery = {};
n.useProgramNudgeEmailQuery.error = '';
n.useProgramNudgeEmailQuery.success = '';

n.useProgramSearchQuery = {};
n.useProgramSearchQuery.error = '';
n.useProgramSearchQuery.success = '';

n.useProgramWelcomeEmailQuery = {};
n.useProgramWelcomeEmailQuery.error = '';
n.useProgramWelcomeEmailQuery.success = '';

n.useProjectDueDateReminderEmailQuery = {};
n.useProjectDueDateReminderEmailQuery.error = '';
n.useProjectDueDateReminderEmailQuery.success = '';

n.useProjectSubmissionEmailQuery = {};
n.useProjectSubmissionEmailQuery.error = '';
n.useProjectSubmissionEmailQuery.success = '';

n.useProjectSubmissionFeedbackEmailQuery = {};
n.useProjectSubmissionFeedbackEmailQuery.error = '';
n.useProjectSubmissionFeedbackEmailQuery.success = '';

n.useReActivateUsersMutation = {};
n.useReActivateUsersMutation.error = '';
n.useReActivateUsersMutation.success = '';

n.useRecommendToUsersMutation = {};
n.useRecommendToUsersMutation.error = '';
n.useRecommendToUsersMutation.success = '';

n.useReOrderCustomProgramContentMutation = {};
n.useReOrderCustomProgramContentMutation.error = '';
n.useReOrderCustomProgramContentMutation.success = '';

n.useReOrderCustomProgramSectionsMutation = {};
n.useReOrderCustomProgramSectionsMutation.error = '';
n.useReOrderCustomProgramSectionsMutation.success = '';

n.useRequestApprovalUpdateEmailQuery = {};
n.useRequestApprovalUpdateEmailQuery.error = '';
n.useRequestApprovalUpdateEmailQuery.success = '';

n.useRequestItemPreApprovalMutation = {};
n.useRequestItemPreApprovalMutation.error = '';
n.useRequestItemPreApprovalMutation.success = '';

n.useRequestPasswordResetEmailMutation = {};
n.useRequestPasswordResetEmailMutation.error = '';
n.useRequestPasswordResetEmailMutation.success = '';

n.useRequestPreApprovalMutation = {};
n.useRequestPreApprovalMutation.error = '';
n.useRequestPreApprovalMutation.success = '';

n.useRequestReimbursementMutation = {};
n.useRequestReimbursementMutation.error = '';
n.useRequestReimbursementMutation.success = '';

n.useRequestReimbursementRequestEmailQuery = {};
n.useRequestReimbursementRequestEmailQuery.error = '';
n.useRequestReimbursementRequestEmailQuery.success = '';

n.useRequestRequestedEmailQuery = {};
n.useRequestRequestedEmailQuery.error = '';
n.useRequestRequestedEmailQuery.success = '';

n.useRequestResourceApprovalMutation = {};
n.useRequestResourceApprovalMutation.error = '';
n.useRequestResourceApprovalMutation.success = '';

n.useRequestVoucherRequestEmailQuery = {};
n.useRequestVoucherRequestEmailQuery.error = '';
n.useRequestVoucherRequestEmailQuery.success = '';

n.useResetJobQueueForUserCompanyMutation = {};
n.useResetJobQueueForUserCompanyMutation.error = '';
n.useResetJobQueueForUserCompanyMutation.success = '';

n.useResetUserAcademyPermissionProjectSubmissionCountMutation = {};
n.useResetUserAcademyPermissionProjectSubmissionCountMutation.error = '';
n.useResetUserAcademyPermissionProjectSubmissionCountMutation.success = '';

n.useResetUserCustomProgramPermissionProjectSubmissionCountMutation = {};
n.useResetUserCustomProgramPermissionProjectSubmissionCountMutation.error = '';
n.useResetUserCustomProgramPermissionProjectSubmissionCountMutation.success =
  '';

n.useRestoreCardMutation = {};
n.useRestoreCardMutation.error = '';
n.useRestoreCardMutation.success = '';

n.useRevertAcceptanceOfStripeTermsMutation = {};
n.useRevertAcceptanceOfStripeTermsMutation.error = '';
n.useRevertAcceptanceOfStripeTermsMutation.success = '';

n.useRevertMigrationUserPlanApprovalToUserRequestMutation = {};
n.useRevertMigrationUserPlanApprovalToUserRequestMutation.error = '';
n.useRevertMigrationUserPlanApprovalToUserRequestMutation.success = '';

n.useRunBackgroundJobQuery = {};
n.useRunBackgroundJobQuery.error = '';
n.useRunBackgroundJobQuery.success = '';

n.useSaveMissedTransactionsMutation = {};
n.useSaveMissedTransactionsMutation.error = '';
n.useSaveMissedTransactionsMutation.success = '';

n.useSearchInitiativeProgramsQuery = {};
n.useSearchInitiativeProgramsQuery.error = '';
n.useSearchInitiativeProgramsQuery.success = '';

n.useSendgridInboundMutation = {};
n.useSendgridInboundMutation.error = '';
n.useSendgridInboundMutation.success = '';

n.useSendgridInboundTestMutation = {};
n.useSendgridInboundTestMutation.error = '';
n.useSendgridInboundTestMutation.success = '';

n.useSetCompanyStripeAccountIdsMutation = {};
n.useSetCompanyStripeAccountIdsMutation.error = '';
n.useSetCompanyStripeAccountIdsMutation.success = '';

n.useSetSelfAsManagerMutation = {};
n.useSetSelfAsManagerMutation.error = '';
n.useSetSelfAsManagerMutation.success = '';

n.useSkillsAssociatedByIdQuery = {};
n.useSkillsAssociatedByIdQuery.error = '';
n.useSkillsAssociatedByIdQuery.success = '';

n.useSkillsSearchQuery = {};
n.useSkillsSearchQuery.error = '';
n.useSkillsSearchQuery.success = '';

n.useSubmitProjectMutation = {};
n.useSubmitProjectMutation.error = '';
n.useSubmitProjectMutation.success = '';

n.useTestIpAddressAndUserAgentInfoQuery = {};
n.useTestIpAddressAndUserAgentInfoQuery.error = '';
n.useTestIpAddressAndUserAgentInfoQuery.success = '';

n.useTestRecurringIncentiveUpdatesMutation = {};
n.useTestRecurringIncentiveUpdatesMutation.error = '';
n.useTestRecurringIncentiveUpdatesMutation.success = '';

n.useTestRecurringUserBudgetUpdatesMutation = {};
n.useTestRecurringUserBudgetUpdatesMutation.error = '';
n.useTestRecurringUserBudgetUpdatesMutation.success = '';

n.useTimeRequestApprovalMutation = {};
n.useTimeRequestApprovalMutation.error = '';
n.useTimeRequestApprovalMutation.success = '';

n.useTopUpIssuingBalanceMutation = {};
n.useTopUpIssuingBalanceMutation.error = '';
n.useTopUpIssuingBalanceMutation.success = '';

n.useUpdateAcademyMutation = {};
n.useUpdateAcademyMutation.error = '';
n.useUpdateAcademyMutation.success = '';

n.useUpdateAcademyBrandingMutation = {};
n.useUpdateAcademyBrandingMutation.error = '';
n.useUpdateAcademyBrandingMutation.success = '';

n.useUpdateAcademyContentStepMutation = {};
n.useUpdateAcademyContentStepMutation.error = '';
n.useUpdateAcademyContentStepMutation.success = '';

n.useUpdateAcademyLevelMutation = {};
n.useUpdateAcademyLevelMutation.error = '';
n.useUpdateAcademyLevelMutation.success = '';

n.useUpdateAcademyLevelOrderMutation = {};
n.useUpdateAcademyLevelOrderMutation.error = '';
n.useUpdateAcademyLevelOrderMutation.success = '';

n.useUpdateAcademyMarketplaceProgramCompletionStatusMutation = {};
n.useUpdateAcademyMarketplaceProgramCompletionStatusMutation.error = '';
n.useUpdateAcademyMarketplaceProgramCompletionStatusMutation.success = '';

n.useUpdateAcademyStepContentRsvpMutation = {};
n.useUpdateAcademyStepContentRsvpMutation.error = '';
n.useUpdateAcademyStepContentRsvpMutation.success = '';

n.useUpdateAcademyStepContentStatusMutation = {};
n.useUpdateAcademyStepContentStatusMutation.error = '';
n.useUpdateAcademyStepContentStatusMutation.success = '';

n.useUpdateAcademyStepOrderMutation = {};
n.useUpdateAcademyStepOrderMutation.error = '';
n.useUpdateAcademyStepOrderMutation.success = '';

n.useUpdateAcademyStepRequirementMutation = {};
n.useUpdateAcademyStepRequirementMutation.error = '';
n.useUpdateAcademyStepRequirementMutation.success = '';

n.useUpdateAcademyStepsLevelMutation = {};
n.useUpdateAcademyStepsLevelMutation.error = '';
n.useUpdateAcademyStepsLevelMutation.success = '';

n.useUpdateAllUsersCustomProgramCompletionStatusMutation = {};
n.useUpdateAllUsersCustomProgramCompletionStatusMutation.error = '';
n.useUpdateAllUsersCustomProgramCompletionStatusMutation.success = '';

n.useUpdateApprovalExpirationMutation = {};
n.useUpdateApprovalExpirationMutation.error = '';
n.useUpdateApprovalExpirationMutation.success = '';

n.useUpdateCardholdersMetadataMutation = {};
n.useUpdateCardholdersMetadataMutation.error = '';
n.useUpdateCardholdersMetadataMutation.success = '';

n.useUpdateClientCompanyMutation = {};
n.useUpdateClientCompanyMutation.error = '';
n.useUpdateClientCompanyMutation.success = '';

n.useUpdateCohortMutation = {};
n.useUpdateCohortMutation.error = '';
n.useUpdateCohortMutation.success = '';

n.useUpdateContentSectionMutation = {};
n.useUpdateContentSectionMutation.error = '';
n.useUpdateContentSectionMutation.success = '';

n.useUpdateCustomProgramMutation = {};
n.useUpdateCustomProgramMutation.error = '';
n.useUpdateCustomProgramMutation.success = '';

n.useUpdateCustomProgramContentRsvpMutation = {};
n.useUpdateCustomProgramContentRsvpMutation.error = '';
n.useUpdateCustomProgramContentRsvpMutation.success = '';

n.useUpdateCustomProgramEmailMessageMutation = {};
n.useUpdateCustomProgramEmailMessageMutation.error = '';
n.useUpdateCustomProgramEmailMessageMutation.success = '';

n.useUpdateCustomProgramSectionMutation = {};
n.useUpdateCustomProgramSectionMutation.error = '';
n.useUpdateCustomProgramSectionMutation.success = '';

n.useUpdateCustomProgramStatusMutation = {};
n.useUpdateCustomProgramStatusMutation.error = '';
n.useUpdateCustomProgramStatusMutation.success = '';

n.useUpdateEventMutation = {};
n.useUpdateEventMutation.error = '';
n.useUpdateEventMutation.success = '';

n.useUpdateFinanceIncentiveMutation = {};
n.useUpdateFinanceIncentiveMutation.error = '';
n.useUpdateFinanceIncentiveMutation.success = '';

n.useUpdateInitiativeMutation = {};
n.useUpdateInitiativeMutation.error = '';
n.useUpdateInitiativeMutation.success = '';

n.useUpdateInitiativeStatusMutation = {};
n.useUpdateInitiativeStatusMutation.error = '';
n.useUpdateInitiativeStatusMutation.success = '';

n.useUpdateLanguagePreferenceMutation = {};
n.useUpdateLanguagePreferenceMutation.error = '';
n.useUpdateLanguagePreferenceMutation.success = '';

n.useUpdateLocalDbMutation = {};
n.useUpdateLocalDbMutation.error = '';
n.useUpdateLocalDbMutation.success = '';

n.useUpdateMissingEmailSettingsForCompaniesMutation = {};
n.useUpdateMissingEmailSettingsForCompaniesMutation.error = '';
n.useUpdateMissingEmailSettingsForCompaniesMutation.success = '';

n.useUpdatePlbVirtualCardBalancesMutation = {};
n.useUpdatePlbVirtualCardBalancesMutation.error = '';
n.useUpdatePlbVirtualCardBalancesMutation.success = '';

n.useUpdateProgramAvailabilityMutation = {};
n.useUpdateProgramAvailabilityMutation.error = '';
n.useUpdateProgramAvailabilityMutation.success = '';

n.useUpdateProgramProgressMutation = {};
n.useUpdateProgramProgressMutation.error = '';
n.useUpdateProgramProgressMutation.success = '';

n.useUpdateProgramStatusMutation = {};
n.useUpdateProgramStatusMutation.error = '';
n.useUpdateProgramStatusMutation.success = '';

n.useUpdateRecurringUfiLastRunDateMutation = {};
n.useUpdateRecurringUfiLastRunDateMutation.error = '';
n.useUpdateRecurringUfiLastRunDateMutation.success = '';

n.useUpdateReviewMutation = {};
n.useUpdateReviewMutation.error = '';
n.useUpdateReviewMutation.success = '';

n.useUpdateSettingsMutation = {};
n.useUpdateSettingsMutation.error = '';
n.useUpdateSettingsMutation.success = '';

n.useUpdateStripeConfigMutation = {};
n.useUpdateStripeConfigMutation.error = '';
n.useUpdateStripeConfigMutation.success = '';

n.useUpdateTimeIncentiveMutation = {};
n.useUpdateTimeIncentiveMutation.error = '';
n.useUpdateTimeIncentiveMutation.success = '';

n.useUpdateUserBudgetMutation = {};
n.useUpdateUserBudgetMutation.error = '';
n.useUpdateUserBudgetMutation.success = '';

n.useUpdateUserEmailMutation = {};
n.useUpdateUserEmailMutation.error = '';
n.useUpdateUserEmailMutation.success = '';

n.useUpdateUserRequestCostCenterMutation = {};
n.useUpdateUserRequestCostCenterMutation.error = '';
n.useUpdateUserRequestCostCenterMutation.success = '';

n.useUploadFinanceIncentiveToCoudinaryMutation = {};
n.useUploadFinanceIncentiveToCoudinaryMutation.error = '';
n.useUploadFinanceIncentiveToCoudinaryMutation.success = '';

n.useUploadProjectSubmissionMutation = {};
n.useUploadProjectSubmissionMutation.error = '';
n.useUploadProjectSubmissionMutation.success = '';

n.useUploadProjectSubmissionByProjectIdMutation = {};
n.useUploadProjectSubmissionByProjectIdMutation.error = '';
n.useUploadProjectSubmissionByProjectIdMutation.success = '';

n.useUploadProviderImagesToCoudinaryMutation = {};
n.useUploadProviderImagesToCoudinaryMutation.error = '';
n.useUploadProviderImagesToCoudinaryMutation.success = '';

n.useUploadReimbursementAttachmentMutation = {};
n.useUploadReimbursementAttachmentMutation.error = '';
n.useUploadReimbursementAttachmentMutation.success = '';

n.useUploadTimeIncentiveToCoudinaryMutation = {};
n.useUploadTimeIncentiveToCoudinaryMutation.error = '';
n.useUploadTimeIncentiveToCoudinaryMutation.success = '';

n.useUserMutation = {};
n.useUserMutation.error = '';
n.useUserMutation.success = '';

n.useUserPlanRequestApprovalMutation = {};
n.useUserPlanRequestApprovalMutation.error = '';
n.useUserPlanRequestApprovalMutation.success = '';

n.useUserPlanSubmitProjectContentMutation = {};
n.useUserPlanSubmitProjectContentMutation.error = '';
n.useUserPlanSubmitProjectContentMutation.success = '';

n.useUserPlanSubmitProjectContentLinkMutation = {};
n.useUserPlanSubmitProjectContentLinkMutation.error = '';
n.useUserPlanSubmitProjectContentLinkMutation.success = '';

n.useUserPlanUpdateCustomProgramContentMutation = {};
n.useUserPlanUpdateCustomProgramContentMutation.error = '';
n.useUserPlanUpdateCustomProgramContentMutation.success = '';

n.useUserSearchQuery = {};
n.useUserSearchQuery.error = '';
n.useUserSearchQuery.success = '';

n.useViewProgramProgressDetailsMutation = {};
n.useViewProgramProgressDetailsMutation.error = '';
n.useViewProgramProgressDetailsMutation.success = '';

export default n;
