// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyStepContentPayload.cs

import {z} from 'zod';
import {IAcademyStepBasicInfoSchema} from './IAcademyStepBasicInfoSchema.schema';
import {LinkPayloadSchema} from './LinkPayloadSchema.schema';
import {SubmissionTypeSchema} from './SubmissionTypeSchema.schema';
import {AcademyStepTypeSchema} from './AcademyStepTypeSchema.schema';

const schema = z.object({
  academyLevelId: z.number(),
  allowPeerVisibility: z.boolean(),
  allowSkip: z.boolean(),
  description: z.string(),
  dueDate: z.string().nullable().optional(),
  dueDateTimeZone: z.string().nullable().optional(),
  dueTime: z.string().nullable().optional(),
  endDate: z.string().nullable().optional(),
  endTime: z.string().nullable().optional(),
  eventLink: z.string().nullable().optional(),
  eventTimeZone: z.string().nullable().optional(),
  id: z.number(),
  links: z.array(LinkPayloadSchema).nullable().optional(),
  requiredId: z.number().nullable().optional(),
  startDate: z.string().nullable().optional(),
  startTime: z.string().nullable().optional(),
  submissionType: SubmissionTypeSchema.nullable().optional(),
  title: z.string(),
  type: AcademyStepTypeSchema,
});

export const AcademyStepContentPayloadSchema =
  IAcademyStepBasicInfoSchema.merge(schema);
