// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewUserEvent.cs

import {z} from 'zod';
import {RecurrenceTypeSchema} from './RecurrenceTypeSchema.schema';

export const RecurringDetailSchema = z.object({
  dayOfMonth: z.number(),
  dayOfWeek: z.number(),
  maxOccurrences: z.number(),
  monthOfYear: z.number(),
  recurrenceType: RecurrenceTypeSchema,
  separationCount: z.number(),
  weekOfMonth: z.number(),
});
