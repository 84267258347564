import {Form, Input} from 'antd';
import styled from 'styled-components';

export const MoneyInputWrapper = styled(Input.Group)`
  display: flex;
  align-items: start;

  & > .ant-form-item {
    margin-bottom: 0 !important;
  }

  .hidden-label {
    display: none;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  display: flex;
  width: 100%;
  .ant-row.ant-form-item-row {
    width: 100% !important;
  }
`;
