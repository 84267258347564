// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Requests/CreateAcademyLevelRequest.cs

import {z} from 'zod';

export const CreateAcademyLevelRequestSchema = z.object({
  academyId: z.number(),
  description: z.string(),
  title: z.string(),
});
