import {MouseEvent, ReactNode, useEffect, useRef, useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {DefaultTheme, css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {Button, Dropdown} from 'antd';
import {EllipsisOutlined} from '@ant-design/icons';
import useClickOutside from '@hooks/useClickOutside';
import {a11yDropdownKeyHelper} from '@utils/a11yUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const RoundButtonStyles = styled(Button)`
  &.ant-btn:focus,
  &.ant-btn:active {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

export const ContextMenuContainer = styled.nav`
  background: ${COLORS.White};
  border-radius: 4px;
  box-shadow: ${COLORS.BoxShadowStandard};
  z-index: 10000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const ContextMenuTargetButton = styled.button`
  background: inherit;
  border: none;
`;

export const ContextMenuRowButton = styled.button<{noBottomBorder?: boolean}>`
  background: inherit;
  border: none;
  color: ${COLORS.Neutral950};
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 1.1875rem;
  padding: 16px;
  text-align: left;
  width: 100%;

  &:hover {
    background: ${COLORS.Neutral100};
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }

  ${({noBottomBorder}) => {
    if (noBottomBorder) return null;
    return css`
      &:not(:last-child) {
        border-bottom: 1px solid ${COLORS.Neutral200};
      }
    `;
  }}
`;

export const DropdownWrapper = styled.div`
  button {
    color: ${COLORS.Neutral600};
    border-color: ${COLORS.Neutral600};
  }

  button:focus {
    color: ${COLORS.Blue800};
    border-color: ${COLORS.Blue800};
  }
`;

export const StyledButton = styled(Button)`
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950} !important;
    outline-offset: 2px;
    transition: none 0s ease 0s;
  }
`;

/*
|--------------------------------------------------------------------------
| Menu Component
|--------------------------------------------------------------------------
*/

export interface ContextMenuItem {
  label: React.ReactNode;
  onClick: (e) => void;
  dataTestId?: string;
  noBottomBorder?: boolean;
  dataCy?: string;
}

const ContextMenu = ({
  id,
  itemId,
  menuItems,
}: {
  id: string; // context menu unique id
  itemId: string | number; // programId, userId, etc.
  menuItems: ContextMenuItem[];
}) => {
  return (
    <ContextMenuContainer
      id={id}
      data-cy={`context-${itemId || id}`}
      aria-label={i18n.t(k.USER__MENU)}>
      <ul>
        {menuItems.map(
          (
            {label, onClick, noBottomBorder, dataTestId = null, dataCy = null},
            i: number
          ) => {
            return (
              <li key={String(label) + i}>
                <ContextMenuRowButton
                  onClick={onClick}
                  data-testid={dataTestId}
                  data-cy={dataCy}
                  noBottomBorder={noBottomBorder}
                  role="menuitem">
                  {label}
                </ContextMenuRowButton>
              </li>
            );
          }
        )}
      </ul>
    </ContextMenuContainer>
  );
};

/*
|--------------------------------------------------------------------------
| Context Menu w/ Button
|--------------------------------------------------------------------------
*/

export interface ContextMenuButtonProps {
  menuItems: ContextMenuItem[];
  Target?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  overlayPlacement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'bottom';
  popupContainerId?: string;
  menuWidth?: string;
  overlayStyle?: React.CSSProperties;
  title?: string;
  dataCy?: string;
  itemId?: string | number;
}

function ContextMenuButton({
  menuItems,
  Target,
  overlayPlacement,
  size,
  popupContainerId,
  menuWidth,
  overlayStyle,
  title,
  itemId = null,
  dataCy = null,
}: ContextMenuButtonProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [returnTarget, setReturnTarget] = useState(null);
  const buttonRef = useRef<any>();
  const handleClickOutside = (callback?: () => void) => {
    showMenu && setShowMenu(false);
    callback?.();
  };
  useClickOutside(buttonRef, handleClickOutside);
  const menuItemsWithCloseOnClick = menuItems.filter(Boolean).map((item) => {
    return {
      ...item,
      onClick: (e: MouseEvent) => {
        e.stopPropagation();
        item.onClick(e);
        setShowMenu(false);
      },
    };
  });
  const getPopupContainer = popupContainerId
    ? () => document.getElementById(popupContainerId)
    : null;
  const instanceId = `context-${Date.now()}`;

  const handleOnClick = (e: MouseEvent) => {
    e.stopPropagation();
    setReturnTarget(e.target);
    setShowMenu(!showMenu);
  };

  const fullTitle = title
    ? `${title}: ${i18n.t(k.CTA__VIEW_MORE)}`
    : i18n.t(k.CTA__VIEW_MORE);

  useEffect(() => {
    showMenu &&
      a11yDropdownKeyHelper(instanceId, returnTarget, () => {
        handleClickOutside(() => buttonRef?.current.focus);
      });
  }, [showMenu]);

  return (
    <DropdownWrapper>
      <Dropdown
        trigger={['click']}
        overlay={
          <ContextMenu
            id={instanceId}
            itemId={itemId}
            menuItems={menuItemsWithCloseOnClick}
          />
        }
        overlayStyle={overlayStyle}
        getPopupContainer={getPopupContainer}
        open={showMenu}
        placement={overlayPlacement}>
        {!!Target ? (
          <>
            <ContextMenuTargetButton
              ref={buttonRef}
              aria-label={fullTitle}
              aria-haspopup={true}
              aria-expanded={showMenu}
              onClick={handleOnClick}
              style={{width: menuWidth ?? 'initial'}}>
              {Target}
            </ContextMenuTargetButton>
          </>
        ) : (
          <RoundButtonStyles
            shape="circle"
            size={size || 'small'}
            icon={<EllipsisOutlined aria-hidden="true" />}
            ref={buttonRef}
            aria-label={fullTitle}
            aria-haspopup={true}
            aria-expanded={showMenu}
            onClick={handleOnClick}
            data-cy={dataCy}
            tabIndex={0}
          />
        )}
      </Dropdown>
    </DropdownWrapper>
  );
}

export default ContextMenuButton;
