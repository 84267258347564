import {LearningResourceType} from '@generated/enums';
import {UserPlanResourceVM} from '@generated/interfaces';
import {RequestMenuDrawerViewProps} from '../RequestMenuDrawer/RequestMenuDrawer.interfaces';

export interface RequestMenuButtonAndDrawerContainerProps {
  buttonTitle?: string;
  onClickProgramFinder?: () => void;
  rerequestResource?: UserPlanResourceVM;
  showFindProgramOption?: boolean;
  showProgramFinderOption?: boolean;
  showRequestProgramOption?: boolean;
  showRequestResourceOption?: boolean;
  showButton?: boolean;
  buttonAltStyle?: boolean;
  drawerVisible?: boolean;
  setDrawerVisible?: (visible: boolean) => void;
}

export const ProgramRequestType = 'Program';
export type RequestType = LearningResourceType | typeof ProgramRequestType;

export interface RequestMenuButtonAndDrawerViewProps
  extends RequestMenuDrawerViewProps {
  buttonTitle?: string;
  drawerVisible: boolean;
  buttonAltStyle?: boolean;
  showButton?: boolean;
}

export const enum RequestOptionTypes {
  Book = 'book',
  Conference = 'conference',
  Other = 'other',
  Program = 'program',
  Subscription = 'subscription',
}
