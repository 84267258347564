// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPlanContentStatusUpdate.cs

import {z} from 'zod';
import {CustomProgramContentStatusSchema} from './CustomProgramContentStatusSchema.schema';

export const UserPlanContentStatusUpdateSchema = z.object({
  contentId: z.number(),
  status: CustomProgramContentStatusSchema,
});
