import mixpanel from 'mixpanel-browser';

/*
|--------------------------------------------------------------------------
| Interfaces
|--------------------------------------------------------------------------
*/

enum EventType {
  Click = 'click',
  View = 'view',
}

enum EventElementType {
  Button = 'button',
  CheckBox = 'checkbox',
  Card = 'card',
  Link = 'link',
}

enum EventContainer {
  MarketplaceProgramCard = 'marketplace-program-card',
  MarketplaceProgramSearchFilter = 'marketplace-search-filter',
  AcademyChatChannelLink = 'academy-chat-channel-link',
  CardIssuerTermsLink = 'card-issuer-terms-link',
  ProgramFinder = 'program-finder',
}

interface MarketplaceProgramInfo {
  id: number;
  cost: number;
  title: string;
  provider: string;
}

enum MarketplaceProgramSearchFilterType {
  Provider = 'provider',
}
interface Provider {
  id: number;
  name: string;
}

/*
|--------------------------------------------------------------------------
| Tracking Methods
|--------------------------------------------------------------------------
*/

export enum MarketplaceProgramCardCta {
  AddToPlan = 'Add to Plan',
  RequestApproval = 'Request Approval',
  RequestLicense = 'Request License',
  ResubmitApproval = 'Resubmit Approval',
  SeeDetails = 'See Details',
  Selected = 'Selected',
  ViewLicense = 'View License',
}

export enum ProgramFinderLaunchOrigin {
  Marketplace = 'marketplace',
  UserPlanEmpty = 'user-plan-empty',
  UserPlanNewRequest = 'user-plan-new-request',
}

export enum ProgramFinderButtonType {
  Back = 'back',
  Next = 'next',
  Skip = 'skip',
  Close = 'close',
}

export const trackMarketplaceProgramCardButtonClick = (
  marketplaceProgram: MarketplaceProgramInfo,
  cta: MarketplaceProgramCardCta
) => {
  mixpanel.track(EventType.Click, {
    marketplaceProgram,
    elementType: EventElementType.Button,
    container: EventContainer.MarketplaceProgramCard,
    cta,
  });
};

export const trackProgramFilterProviderClick = (provider: Provider) => {
  mixpanel.track(EventType.Click, {
    provider,
    container: EventContainer.MarketplaceProgramSearchFilter,
    elementType: EventElementType.CheckBox,
    filterType: MarketplaceProgramSearchFilterType.Provider,
  });
};

export const trackMarketplaceProgramCardView = (
  marketplaceProgram: MarketplaceProgramInfo
) => {
  mixpanel.track(EventType.View, {
    marketplaceProgram,
    container: EventContainer.MarketplaceProgramCard,
    elementType: EventElementType.Card,
  });
};

export const trackChannelLinkClick = (academyId: number) => {
  mixpanel.track(EventType.Click, {
    academyId,
    container: EventContainer.AcademyChatChannelLink,
    elementType: EventElementType.Button,
  });
};

export const trackTermsLinkClick = (origin: 'modal' | 'settings') => {
  mixpanel.track(EventType.Click, {
    container: EventContainer.CardIssuerTermsLink,
    elementType: EventElementType.Link,
    origin,
  });
};

export const trackProgramFinderWelcomeModalView = (
  userActions,
  options = {}
) => {
  mixpanel.track(
    EventType.Click,
    {
      container: EventContainer.ProgramFinder,
      elementType: EventElementType.Button,
      userActions,
    },
    options
  );
};
