/** These strings aren't customer-facing: these don't need to be localized. */
export const REIMBURSEMENT_REQUEST_APPROVED = {
  approved: 'true',
  amount: '$123.45',
  name: 'Mario',
  providerName: 'Nintendo',
  providerImageUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Mario_Series_Logo.svg/1024px-Mario_Series_Logo.svg.png',
  programUrl:
    'https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/',
  programName: "It's me! Mario!",
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
  voucherUrl: 'https%3A%2F%2Fec.nintendo.com%2Fredeem%2F%23%2F',
};
