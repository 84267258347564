// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyReportEmployeesVM.cs

import {z} from 'zod';

export const AcademyReportEmployeesVMSchema = z.object({
  academyStartDate: z.string(),
  currentLevel: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  employeeName: z.string(),
  lastNudgeSent: z.string().nullable().optional(),
  userCompanyId: z.number(),
});
