import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';
import {ThemeProvider, DefaultTheme} from 'styled-components';
import {theme as defaultTheme} from '@utils/color';
import {datadogLogs} from '@datadog/browser-logs';

export interface IThemeProps {
  children: ReactNode;
}
const ThemeUpdateContext = createContext((_: Partial<DefaultTheme>) =>
  datadogLogs.logger.error(
    'attempted to set theme outside of a ThemeUpdateContext.Provider'
  )
);
export const useUpdateTheme = () => useContext(ThemeUpdateContext);
export const Theme = ({children}: IThemeProps) => {
  const [theme, setTheme] = useState(defaultTheme);

  const updateTheme = useCallback(
    (changes: Partial<DefaultTheme>) => {
      setTheme({...theme, ...changes});
    },
    [theme, setTheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <ThemeUpdateContext.Provider value={updateTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeProvider>
  );
};
