// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ProgramReviewPayload.cs

import {z} from 'zod';

export const ProgramReviewPayloadSchema = z.object({
  hasCompletedProgram: z.boolean(),
  id: z.number().nullable().optional(),
  programCompletedOn: z.string(),
  programId: z.number(),
  rating: z.number(),
  reviewContent: z.string(),
  title: z.string(),
});
