import {produce} from 'immer';
import {useEffect, useState} from 'react';

import {LayoutAspect, LayoutConfiguration} from '@learnin-inc/apollo-react-cdk';

import {loadBranding} from './branding';
import {FeatureFlagKey, initOrgInfo, LxpOrgInfo} from './models';
import {
  configureApolloWithFeatureFlags,
  loadLxpPermissions,
  useApolloLayout as shouldUseApolloFor_Skills,
} from './services';

import {
  AcmOptions,
  BaseOptions,
  LxpOptions,
  SkillsPlatformOptions,
} from './layout-configuration.model';
import {
  updateHelpMenu,
  updatei18n,
  updateNavigationBranding,
  updateUser,
  updateWithFilters,
  validateConfiguration,
} from './layout-configuration.utils';

import LxpToAcmConfig from './configs/acm-lxp.json'; // Users arriving to ACM from LXP
import AcmConfig from './configs/acm.json'; // Users launching ACM
import SkillsPlatformConfig from './configs/skills-platform.json';

export interface UseApolloLayoutResponse {
  showApolloLayout: boolean;
  layoutAspect: LayoutAspect | undefined;
}

/**
 * Custom Hook to load Apollo Layout configuration for ACM
 */
export const useApolloLayout_Acm = (
  options: AcmOptions
): UseApolloLayoutResponse => {
  const lxpOptions = useLxpOptions(options);
  const [configuration, setConfiguration] = useState(() =>
    options.isLxpAdmin ? LxpToAcmConfig : AcmConfig
  );
  const [response, setResponse] = useState<UseApolloLayoutResponse>(() =>
    initApolloSkeleton(configuration, options)
  );

  // When ACM options are ready, update the layout aspect
  useEffect(() => {
    if (lxpOptions) {
      const updated = produce<LayoutConfiguration>(configuration, (draft) => {
        draft = updatei18n(draft, options.translate);
        draft = updateUser(draft, lxpOptions.authUser);
        draft = updateHelpMenu(
          draft,
          lxpOptions.authUser?.defaultOrgId,
          lxpOptions.supportInfo
        );
        draft = updateNavigationBranding(
          draft,
          lxpOptions.branding?.navigation
        );
        draft = updateWithFilters(draft, {lxp: lxpOptions, acm: options});
        validateConfiguration(draft);
      });
      const layoutAspect = options.isAdminPath
        ? updated?.admin
        : updated?.learner;
      const showApolloLayout =
        options.isFeatureFlagOn.ACMLayout &&
        (!options.isLxpAdmin || options.isFeatureFlagOn.LXPAdminLayout);

      setResponse({layoutAspect, showApolloLayout});
    }
  }, [options, lxpOptions, configuration]);

  useEffect(() => {
    setConfiguration(options.isLxpAdmin ? LxpToAcmConfig : AcmConfig);
  }, [options.isLxpAdmin]);

  return response;
};

/**
 * Custom Hook to load Apollo Layout configuration for Skills Platform
 */
export const useApolloLayout_SkillsPlatform = (
  options: SkillsPlatformOptions
): UseApolloLayoutResponse => {
  const lxpOptions = useLxpOptions(options); // async loading of LXP options
  const [configuration] = useState(() => SkillsPlatformConfig);
  const [response, setResponse] = useState<UseApolloLayoutResponse>(() =>
    initApolloSkeleton(configuration, options)
  );

  // When Skills Platfroms options are ready, update the layout aspect
  useEffect(() => {
    if (lxpOptions) {
      const updated = produce<LayoutConfiguration>(configuration, (draft) => {
        draft = updatei18n(draft, options.translate);
        draft = updateUser(draft, lxpOptions.authUser);
        draft = updateHelpMenu(
          draft,
          lxpOptions.authUser?.defaultOrgId,
          lxpOptions.supportInfo
        );
        draft = updateNavigationBranding(
          draft,
          lxpOptions.branding?.navigation
        );
        draft = updateWithFilters(draft, {lxp: lxpOptions});
        validateConfiguration(draft);
      });
      const layoutAspect = options.isAdminPath
        ? updated?.admin
        : updated?.learner;
      const showApolloLayout = shouldUseApolloFor_Skills();

      setResponse({layoutAspect, showApolloLayout});
    }
  }, [options, lxpOptions, configuration]);

  return response;
};

/**
 * Load authenticated user, org info, branding, and featureFlags from LXP endpoints
 */
export const useLxpOptions = ({
  isLxpApiEnabled,
  featureFlags,
  orgId,
}: BaseOptions): LxpOptions | null => {
  const [options, setOptions] = useState<LxpOptions | null>(null);

  useEffect(() => {
    const fetchLxpPermissions = async () => {
      const {authUser, supportInfo} = await loadLxpPermissions(isLxpApiEnabled);
      const organizationId = orgId || authUser.defaultOrgId;
      const orgInfo =
        authUser.orgInfo.find(
          (x: LxpOrgInfo) => x.organizationId === organizationId
        ) || initOrgInfo();

      const branding = await loadBranding(authUser, orgInfo, isLxpApiEnabled);

      // Initialize Apollo lookup feature flags for future access
      configureApolloWithFeatureFlags((key: string): boolean => {
        return featureFlags[key as FeatureFlagKey];
      });

      // Publish full set of LxP options that were loaded asynchronously.
      setOptions({
        authUser,
        orgInfo,
        supportInfo,
        featureFlags,
        branding,
      });
    };

    fetchLxpPermissions();
  }, [orgId, isLxpApiEnabled, featureFlags]);

  return options;
};

/**
 * Build correct initial Layout without navigation items...
 * since those are async filtered and updated later
 */
const initApolloSkeleton = (
  configuration: LayoutConfiguration,
  options: SkillsPlatformOptions | AcmOptions
): UseApolloLayoutResponse => {
  const updated = produce<LayoutConfiguration>(configuration, (draft) => {
    updatei18n(draft, options.translate);
  });
  const isAdmin = options.isAdminPath || (options as AcmOptions).isLxpAdmin;
  const layoutAspect = isAdmin ? updated?.admin : updated?.learner;

  return {
    layoutAspect: {
      ...layoutAspect,
      navigation: {
        top: [], // clear until ACM options are ready
      },
    },
    showApolloLayout: true,
  };
};
