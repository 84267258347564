// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateUserBudgetPayload.cs

import {z} from 'zod';

export const UpdateUserBudgetPayloadSchema = z.object({
  balance: z.number(),
  budgetLimit: z.number(),
  note: z.string(),
  userFinanceIncentiveId: z.number(),
});
