import {LanguagePreference} from '@generated/enums';
import {UserDetailsVM} from '@generated/interfaces';
import {loadTranslation} from '@i18n/init';
import {LANGUAGE_LOCAL_STORAGE_KEY} from '@utils/constants';
import {
  LANGUAGE,
  LanguageProperties,
  changeUsersLanguage,
} from '@utils/l10nUtils';
import {hoursDifference, localizeMomentWhenNotDefault} from '@utils/timeUtils';
import i18next from 'i18next';
import {useEffect} from 'react';

/**
 * Returns the localStorage key of the language being set, unique to the user
 */
export const generateLanguageSetKey = (userCompanyId: number) =>
  `lng-set-${userCompanyId}`;

/**
 * If we've set the localStorage locale for the first time (known to us) and if
 * the user's first login was within the last hour, we infer they are new
 */
export const checkIsNewUser = (user: UserDetailsVM, offsetHours: number) =>
  !localStorage.getItem(generateLanguageSetKey(user?.userCompanyId)) &&
  (user?.firstLogin === null || offsetHours <= 1);

/**
 * Returns the 1st match of the user's navigator languages from our list of supported languages
 */
export const matchSupportedLanguagePreference = (
  navLanguages: readonly string[]
): LanguageProperties => {
  for (let i = 0; i < navLanguages.length; i++) {
    const lang = navLanguages[i];
    for (const x in LANGUAGE) {
      const isFullMatch =
        lang.toLowerCase() === LANGUAGE[x].locale.toLowerCase();

      const isRegionalAgnosticMatch =
        lang.length === 2 &&
        lang.toLowerCase() === LANGUAGE[x].locale.substring(0, 2).toLowerCase();

      if (isFullMatch || isRegionalAgnosticMatch) return LANGUAGE[x];
    }
  }
  return null;
};

/**
 * Returns the inferred language set based off the user and feature flag
 */
export const getInferredSupportedLanguage = (
  user: UserDetailsVM,
  isSetLanguageFromBrowserOn: boolean
) =>
  isSetLanguageFromBrowserOn &&
  checkIsNewUser(user, hoursDifference(user.firstLogin)) &&
  matchSupportedLanguagePreference(navigator.languages);

/**
 * Returns the inferred language set if it's a new user, otherwise it returns the user's preferred language as per their settings
 */
export const getEffectiveLocale = (
  user: UserDetailsVM,
  isSetLanguageFromBrowserOn: boolean
) => {
  const inferredSupportedLanguage: LanguageProperties =
    getInferredSupportedLanguage(user, isSetLanguageFromBrowserOn);

  return {
    inferredSupportedLanguage,
    usersLocale: getUsersLocale(
      !!inferredSupportedLanguage
        ? inferredSupportedLanguage.enum
        : user.languagePreference
    ),
  };
};

/**
 * Converts a LanguagePreference enum to a locale string
 */
export const getUsersLocale = (preference: LanguagePreference) =>
  LANGUAGE[LanguagePreference[preference]]?.locale;

/**
 * Initializes i18next + related configs when the locale has changed
 */
export const initI18nOnLocaleChange = (usersLocale: string) => {
  const lsValue = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
  const isPseudoLocale =
    lsValue?.toLowerCase() === LANGUAGE.EN_JM.locale.toLowerCase();
  const effectiveLocale = isPseudoLocale ? lsValue : usersLocale;

  if (usersLocale && usersLocale !== lsValue) {
    i18next.init({
      lng: effectiveLocale,
    });
    loadTranslation(effectiveLocale);
    changeUsersLanguage(effectiveLocale);
  }
};

/**
 * Initializes date/Moment-related configs for the set locale
 */
export const initMomentLocale = (usersLocale: string) =>
  localizeMomentWhenNotDefault(usersLocale);

/**
 * Reactively sets the language for the user based off changing conditions
 */
export const useSetLanguageEffect = ({
  user,
  initI18nOnLocaleChange,
  initMomentLocale,
  isSetLanguageFromBrowserOn,
  setAntDLocaleName,
  updateSettingsMutation,
}) => {
  useEffect(() => {
    const {inferredSupportedLanguage, usersLocale} = getEffectiveLocale(
      user,
      isSetLanguageFromBrowserOn
    );

    initI18nOnLocaleChange(usersLocale);
    initMomentLocale(usersLocale);
    setAntDLocaleName(usersLocale.toUpperCase().replace('-', '_'));

    if (inferredSupportedLanguage) {
      updateSettingsMutation.mutate({
        payload: {
          languagePreference: inferredSupportedLanguage.enum,
        },
      });

      localStorage.setItem(generateLanguageSetKey(user?.userCompanyId), 'true');
    }
  }, [
    user,
    initI18nOnLocaleChange,
    initMomentLocale,
    isSetLanguageFromBrowserOn,
    setAntDLocaleName,
    updateSettingsMutation,
  ]);
};
