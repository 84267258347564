import {COLORS} from '../../../utils/constants';

export const InputStyles = {
  primary: {
    background: COLORS.White,
    color: COLORS.Black,
    borderRadius: '4px',
    transition: 'all 0.2s ease-out',
    disabledBackground: COLORS.Neutral100,
    border: `1px solid ${COLORS.Neutral300}`,
    focusBorder: `1px solid ${COLORS.Blue800}`,
  },
  standard: {
    background: COLORS.White,
    color: COLORS.Black,
    borderRadius: '4px',
    transition: 'all 0.2s ease-out',
    disabledBackground: COLORS.Neutral100,
    border: `1px solid ${COLORS.Neutral300}`,
    focusBorder: `1px solid ${COLORS.Blue800}`,
    errorBorder: `1px solid ${COLORS.Red800}`,
  },
};
