// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/LearningResourceType.cs

enum LearningResourceType {
  Book,
  Conference,
  Subscription,
  Other,
}

export default LearningResourceType;
