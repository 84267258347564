// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ProgramProgressUpdateResult.cs

import {z} from 'zod';
import {UserProgressSummarySchema} from './UserProgressSummarySchema.schema';

export const ProgramProgressUpdateResultSchema = z.object({
  employeeName: z.string(),
  id: z.number(),
  programTitle: z.string(),
  rating: z.number(),
  skills: z.string(),
  submittedDate: z.string(),
  summary: UserProgressSummarySchema.nullable().optional(),
  viewedBy: z.string(),
  viewedDate: z.string().nullable().optional(),
});
