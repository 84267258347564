// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramContentActivityResult.cs

import {z} from 'zod';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

export const CustomProgramContentActivityResultSchema = z.object({
  completedOn: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  employeeId: z.number(),
  employeeName: z.string(),
  id: z.number(),
  lastCustomProgramNudgeSent: z.string().nullable().optional(),
  order: z.number(),
  status: UserItemStatusVMSchema,
  title: z.string(),
});
