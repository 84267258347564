import {
  AdminUserTabVM,
  PeopleTabUserDetailsVM,
  UserDetailsVM,
  UserPersonalizationVM,
  UserSettingsVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {UserDetailsVMSchema} from '../zodSchemas/UserDetailsVMSchema.schema';
import {AdminUserTabVMSchema} from '../zodSchemas/AdminUserTabVMSchema.schema';
import {PeopleTabUserDetailsVMSchema} from '../zodSchemas/PeopleTabUserDetailsVMSchema.schema';
import {UserSettingsVMSchema} from '../zodSchemas/UserSettingsVMSchema.schema';
import {UserPersonalizationVMSchema} from '../zodSchemas/UserPersonalizationVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/User/UserController.cs

/*
|--------------------------------------------------------------------------
|  check-user-status 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCheckUserStatusQuery} from '@generated/hooks';
 *
 *  const {
 *    data: checkUserStatus,
 *    isLoading: isCheckUserStatusLoading,
 *    invalidateExact: invalidateCheckUserStatus,
 *  } = useCheckUserStatusQuery(null)
 *
 */

export const useCheckUserStatusQuery = queryHookFactory<
  null,
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>('useCheckUserStatusQuery', () => `user/check-user-status`, {
  responseSchema: null,
});

/*
|--------------------------------------------------------------------------
|  get-all-users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAllUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: allUsers,
 *    isLoading: isAllUsersLoading,
 *    invalidateExact: invalidateAllUsers,
 *  } = useAllUsersQuery({
 *    queryParams: {
 *        isAscending,
 *        orderBy,
 *        page,
 *        searchTerm
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAllUsersQuery = queryHookFactory<
  {
    queryParams?: {
      isAscending?: boolean;
      orderBy?: string;
      page?: number;
      searchTerm?: string;
    };
  },
  AdminUserTabVM // return type
>(
  'useAllUsersQuery',
  ({queryParams}) =>
    addQueryParams(() => `user/get-all-users`, null, queryParams),
  {responseSchema: AdminUserTabVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-personalization 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePersonalizationQuery} from '@generated/hooks';
 *
 *  const {
 *    data: personalization,
 *    isLoading: isPersonalizationLoading,
 *    invalidateExact: invalidatePersonalization,
 *  } = usePersonalizationQuery(null)
 *
 */

export const usePersonalizationQuery = queryHookFactory<
  null,
  UserPersonalizationVM // return type
>('usePersonalizationQuery', () => `user/get-personalization`, {
  responseSchema: UserPersonalizationVMSchema,
});

/*
|--------------------------------------------------------------------------
|  get-settings 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSettingsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: settings,
 *    isLoading: isSettingsLoading,
 *    invalidateExact: invalidateSettings,
 *  } = useSettingsQuery(null)
 *
 */

export const useSettingsQuery = queryHookFactory<
  null,
  UserSettingsVM // return type
>('useSettingsQuery', () => `user/get-settings`, {
  responseSchema: UserSettingsVMSchema,
});

/*
|--------------------------------------------------------------------------
|  get-user 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserQuery} from '@generated/hooks';
 *
 *  const {
 *    data: user,
 *    isLoading: isUserLoading,
 *    invalidateExact: invalidateUser,
 *  } = useUserQuery(null)
 *
 */

export const useUserQuery = queryHookFactory<
  null,
  UserDetailsVM // return type
>('useUserQuery', () => `user/get-user`, {responseSchema: UserDetailsVMSchema});

/*
|--------------------------------------------------------------------------
|  get-user-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userDetails,
 *    isLoading: isUserDetailsLoading,
 *    invalidateExact: invalidateUserDetails,
 *  } = useUserDetailsQuery({
 *    queryParams: {
 *        userCompanyId
 *    }
 *  })
 *
 */

export const useUserDetailsQuery = queryHookFactory<
  {queryParams?: {userCompanyId?: number}},
  PeopleTabUserDetailsVM // return type
>(
  'useUserDetailsQuery',
  ({queryParams}) =>
    addQueryParams(() => `user/get-user-details`, null, queryParams),
  {responseSchema: PeopleTabUserDetailsVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-user-language-preference/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserLanguagePreferenceQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userLanguagePreference,
 *    isLoading: isUserLanguagePreferenceLoading,
 *    invalidateExact: invalidateUserLanguagePreference,
 *  } = useUserLanguagePreferenceQuery({
 *    id
 *  })
 *
 */

export const useUserLanguagePreferenceQuery = queryHookFactory<
  {id: number | string}, // path variables
  string // return type
>(
  'useUserLanguagePreferenceQuery',
  ({id}) => `user/get-user-language-preference/${id}`,
  {responseSchema: z.string()}
);

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userSearch,
 *    isLoading: isUserSearchLoading,
 *    invalidateExact: invalidateUserSearch,
 *  } = useUserSearchQuery({
 *    queryParams: {
 *        limit,
 *        name
 *    }
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useUserSearchQuery = queryHookFactory<
  {queryParams?: {limit?: number; name?: string}},
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useUserSearchQuery',
  ({queryParams}) => addQueryParams(() => `user/search`, null, queryParams),
  {responseSchema: null}
);
