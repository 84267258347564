// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewUserEvent.cs

import {z} from 'zod';
import {RecurringDetailSchema} from './RecurringDetailSchema.schema';

export const NewUserEventSchema = z.object({
  allDayEvent: z.boolean(),
  endDate: z.string(),
  endTime: z.string(),
  notes: z.string(),
  recurring: z.boolean(),
  recurringDetail: RecurringDetailSchema,
  startDate: z.string(),
  startTime: z.string(),
  title: z.string(),
  userPlanItemId: z.number(),
});
