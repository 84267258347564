import axios from 'axios';
import UrlConfig from '../UrlConfig';
import {getUser, login} from '../utils/oidc-auth-wrapper';
import {User} from 'oidc-client-ts';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {isTestRun} from '@utils/environment-helpers';

const uConfig = new UrlConfig();
const baseURL = uConfig.API_URL;

const api = axios.create({
  baseURL,
  responseType: 'json',
});

const updateHeaderWithAuthToken = (axiosConfig, token: User | null) => {
  axiosConfig.headers = {
    Authorization: `Bearer ${token?.access_token}`,
    Accept: 'application/json',
    'Content-Type': axiosConfig.headers?.['Content-Type'] || 'application/json',
  };

  return axiosConfig;
};

async function getAuthToken(config, failOnInvalidToken = false) {
  try {
    const token = await getUser();
    if (!token && failOnInvalidToken) {
      login();
      return Promise.reject('Token is null');
    } else if (token.expired && failOnInvalidToken) {
      login();
      return Promise.reject(`Token is expired [${token.expires_in}]`);
    } else {
      return updateHeaderWithAuthToken(config, token);
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

if (!isTestRun) {
  api.interceptors.request.use(getAuthToken);

  /**
   * Logic for handling retrying requests if they fail for 401 auth errors
   * @param failedRequest
   */
  const refreshAuthLogic = async (failedRequest) => {
    return await getAuthToken(failedRequest.response.config, true);
  };

  createAuthRefreshInterceptor(api, refreshAuthLogic);
}

export {api};
