// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyUserVM.cs

import {z} from 'zod';
import {AcademyUserStatusSchema} from './AcademyUserStatusSchema.schema';

export const AcademyUserVMSchema = z.object({
  currentLevelId: z.number().nullable().optional(),
  dateAdded: z.string(),
  email: z.string().nullable().optional(),
  lastNudgeSent: z.string().nullable().optional(),
  name: z.string(),
  role: z.string(),
  status: AcademyUserStatusSchema,
  userCompanyId: z.number(),
});
