import {LANGUAGE_LOCAL_STORAGE_KEY} from '../constants';
import {configChat} from './chat-config';

export const openWidget = (): void => {
  try {
    (window as any)?.zE.activate();
  } catch (error) {
    console.error(error);
  }
};

interface IZeUser {
  name: string;
  email: string;
  organization?: string;
}

// This function returns a boolean depending on if called successfully
// which depends on if ZenDesk library has loaded
export const identifyZeUser = (user: IZeUser): boolean => {
  try {
    (window as any)?.zE.identify(user);
    return true;
  } catch (error) {
    return false;
  }
};

// Provides the user's stored locale to ZenDesk
export const setZeLocale = () => {
  try {
    const locale = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
    if (locale) {
      (window as any)?.zE('webWidget', 'setLocale', locale.toLowerCase());
    }
  } catch (error) {
    console.error(error);
  }
};

export const registerUserInZenDesk = (
  user: IZeUser,
  onSuccess?: () => void
) => {
  const userIdentified = identifyZeUser({
    name: user?.name,
    email: user?.email,
  });
  if (!userIdentified) {
    setTimeout(() => registerUserInZenDesk(user, onSuccess), 250);
  } else {
    onSuccess?.();
  }
};

export const loadZenDeskScript = () => {
  (window as any).zESettings = configChat();
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.async = true;
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=4ff54264-51e9-4232-a230-44eb8f1cce12';
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const initZenDesk = (user: IZeUser) => {
  loadZenDeskScript();
  registerUserInZenDesk(user, setZeLocale);
};
