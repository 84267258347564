import {
  NewUserFromIdentity,
  UserSettingsUpdateLanguagePreferenceRequest,
  UserSettingsUpdateRequest,
  UserSettingsVM,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UserSettingsUpdateRequestSchema} from '../zodSchemas/UserSettingsUpdateRequestSchema.schema';
import {UserSettingsUpdateLanguagePreferenceRequestSchema} from '../zodSchemas/UserSettingsUpdateLanguagePreferenceRequestSchema.schema';
import {NewUserFromIdentitySchema} from '../zodSchemas/NewUserFromIdentitySchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/User/UserController.cs

/*
|--------------------------------------------------------------------------
|  accept-stripe-terms 
|--------------------------------------------------------------------------
*/

/**
*  @example
*
*  import {useAcceptStripeTermsMutation} from '@generated/hooks';
*
*  const acceptStripeTerms = useAcceptStripeTermsMutation({
*    onSuccess: () => {},
*  });
*
*  acceptStripeTerms.mutate({

*  }) 
*
*/

export const useAcceptStripeTermsMutation = mutationHookFactory<
  null,
  null,
  UserSettingsVM // return type
>('useAcceptStripeTermsMutation', 'post', () => `user/accept-stripe-terms`, {
  payloadSchema: undefined,
});

/*
|--------------------------------------------------------------------------
|  add-user 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddUserMutation} from '@generated/hooks';
 *
 *  const addUser = useAddUserMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addUser.mutate({
 *    payload: {
 *      ...NewUserFromIdentity
 *    },
 *  })
 *
 */

export const useAddUserMutation = mutationHookFactory<
  NewUserFromIdentity, // payload
  null,
  number // return type
>('useAddUserMutation', 'post', () => `user/add-user`, {
  payloadSchema: NewUserFromIdentitySchema,
});

/*
|--------------------------------------------------------------------------
|  request-password-reset-email 
|--------------------------------------------------------------------------
*/

/**
*  @example
*
*  import {useRequestPasswordResetEmailMutation} from '@generated/hooks';
*
*  const requestPasswordResetEmail = useRequestPasswordResetEmailMutation({
*    onSuccess: () => {},
*  });
*
*  requestPasswordResetEmail.mutate({

*  }) 
*
*/

export const useRequestPasswordResetEmailMutation = mutationHookFactory<
  null,
  null,
  UserSettingsVM // return type
>(
  'useRequestPasswordResetEmailMutation',
  'post',
  () => `user/request-password-reset-email`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  update-language-preference 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateLanguagePreferenceMutation} from '@generated/hooks';
 *
 *  const updateLanguagePreference = useUpdateLanguagePreferenceMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateLanguagePreference.mutate({
 *    payload: {
 *      ...UserSettingsUpdateLanguagePreferenceRequest
 *    },
 *  })
 *
 */

export const useUpdateLanguagePreferenceMutation = mutationHookFactory<
  UserSettingsUpdateLanguagePreferenceRequest, // payload
  null,
  UserSettingsUpdateLanguagePreferenceRequest // return type
>(
  'useUpdateLanguagePreferenceMutation',
  'post',
  () => `user/update-language-preference`,
  {payloadSchema: UserSettingsUpdateLanguagePreferenceRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  update-settings 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateSettingsMutation} from '@generated/hooks';
 *
 *  const updateSettings = useUpdateSettingsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateSettings.mutate({
 *    payload: {
 *      ...UserSettingsUpdateRequest
 *    },
 *  })
 *
 */

export const useUpdateSettingsMutation = mutationHookFactory<
  UserSettingsUpdateRequest, // payload
  null,
  UserSettingsVM // return type
>('useUpdateSettingsMutation', 'post', () => `user/update-settings`, {
  payloadSchema: UserSettingsUpdateRequestSchema,
});
