import {message} from 'antd';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import notifications from './hookNotifications';

/*
|--------------------------------------------------------------------------
| Notification by Hook Name and Notification Type
|--------------------------------------------------------------------------
*/

type TNotificationType = 'success' | 'error' | 'warning';

/*
|--------------------------------------------------------------------------
| Notification Display Content
|--------------------------------------------------------------------------
*/

export default function hookNotificationMiddleware(
  hookName: string,
  notificationType: TNotificationType
) {
  const notification = notifications?.[hookName]?.[notificationType];
  if (!!notification) {
    message[notificationType]({
      content: <NotificationContent notification={i18n.t(notification)} />,
      duration: 3,
      style: {
        marginTop: '90vh',
      },
    });
  }
}

function NotificationContent({
  notification,
  link,
}: {
  notification: string;
  link?: {
    href: string;
    name: string;
  };
}) {
  return (
    <>
      {notification}
      <button
        onClick={() => {
          message.destroy();
        }}
        style={{color: COLORS.Blue100}}
        className="btn-dismiss">
        {i18n.t(k.CTA__DISMISS).toLocaleUpperCase()}
      </button>

      {!!link && (
        <a
          onClick={() => {
            message.destroy();
          }}
          href={link?.href}
          className="btn-link">
          {link?.name}
        </a>
      )}
    </>
  );
}
