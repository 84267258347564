// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/CreditCardOverviewVM.cs

import {z} from 'zod';

export const CreditCardOverviewVMSchema = z.object({
  balance: z.number(),
  cardId: z.number(),
  last4: z.string(),
});
