// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateUserPlanProgram.cs

import {z} from 'zod';
import {UserProgramStatusSchema} from './UserProgramStatusSchema.schema';

export const PlanProgramStatusUpdateSchema = z.object({
  status: UserProgramStatusSchema,
  userPlanItemId: z.number(),
});
