export enum TextTags {
  ModalHeader = 'modalHeader',
  ModalSubHeader = 'modalSubHeader',
  ModalProgramCompany = 'modalProgramCompany',
  ModalProgramTitle = 'modalProgramTitle',
  ModalProgramLicenseHeader = 'modalProgramLicenseHeader',
  ModalProgramText = 'modalProgramText',
  MoneyIncentiveModalCardHeader = 'moneyIncentiveModalCardHeader',
  MoneyIncentiveModalCardText = 'moneyIncentiveModalCardText',
  LearnInPersonalBudgetHeader = 'learninPersonalBudgetHeader',
  LearnInPersonalBudgetDescription = 'learninPersonalBudgetDescription',
  LearnInPersonalBudgetCCApproval = 'learninPersonalBudgetCCApproval',
  LearnInPersonalBudgetCCMoney = 'learninPersonalBudgetCCMoney',
  LearnInPersonalBudgetCCHeader = 'learninPersonalBudgetCCHeader',
  LearnInPersonalBudgetCCFooter = 'learninPersonalBudgetCCFooter',
  OopsErrorMessage = 'oopsErrorMessage',
  FunErrorMessage = 'funErrorMessage',
  BoringErrorMessage = 'boringErrorMessage',
  EmptyApprovalTextHeader = 'emptyApprovalTextHeader',
  EmptyApprovalTextSubHeader = 'emptyApprovalTextSubHeader',
  PersonalLearningBudgetGaugeStatus = 'personalLearningBudgetGaugeStatus',
  MenuBadge = 'menuBadge',
  InputErrorMessage = 'inputErrorMessage',
  RequestPasswordModalHeader = 'requestPasswordModalHeader',
  ProgramStatusCardTextHeader = 'programStatusCardTextHeader',
  ProgramStatusCardTextSubHeader = 'programStatusCardTextSubHeader',
  ProgramStatusCardTextLink = 'programStatusCardTextLink',
  ProgramSectionProgressBarLabel = 'programSectionProgressBarLabel',
  LicenseViewerModalBody = 'licenseViewerModalBody',
}
