// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ApprovalsDiscriminator.cs

enum ApprovalsDiscriminator {
  Incentive,
  Program,
  LearningResource,
  License,
  ProgramReimbursement,
  LearningResourceReimbursement,
}

export default ApprovalsDiscriminator;
