// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Permission/AcademyPermissionVM.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  academyId: z.number(),
  academyName: z.string(),
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  newSubmissionCount: z.number(),
});

export const AcademyPermissionVMSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
