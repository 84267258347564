// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyUserPayload.cs

import {z} from 'zod';
import {IAcademyUserBasicInfoSchema} from './IAcademyUserBasicInfoSchema.schema';
import {AcademyUserStatusSchema} from './AcademyUserStatusSchema.schema';

const schema = z.object({
  academyId: z.number(),
  status: AcademyUserStatusSchema,
  userCompanyIds: z.array(z.number()),
});

export const AcademyUserPayloadSchema =
  IAcademyUserBasicInfoSchema.merge(schema);
