// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/InitiativePayload.cs

import {z} from 'zod';
import {InitiativePurposeSchema} from './InitiativePurposeSchema.schema';
import {InitiativeStatusSchema} from './InitiativeStatusSchema.schema';

export const InitiativePayloadSchema = z.object({
  audience: z.string(),
  financeIncentiveIds: z.array(z.number()),
  id: z.number().nullable().optional(),
  programIds: z.array(z.number()),
  purpose: InitiativePurposeSchema,
  purposeDescription: z.string(),
  status: InitiativeStatusSchema,
  timeIncentiveIds: z.array(z.number()),
  title: z.string(),
});
