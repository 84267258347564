// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserCustomProgramContentPreviewVM.cs

import {z} from 'zod';
import {ContentTypeSchema} from './ContentTypeSchema.schema';

export const UserCustomProgramContentPreviewVMSchema = z.object({
  contentId: z.number(),
  contentTitle: z.string(),
  contentType: ContentTypeSchema,
  customProgramId: z.number(),
  sectionId: z.number(),
  sectionTitle: z.string(),
});
