// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramInitiativeIncentiveVM.cs

import {z} from 'zod';

export const ProgramInitiativeIncentiveVMSchema = z.object({
  id: z.number(),
  imageSmall: z.string(),
  provider: z.string(),
  providerLogo: z.string(),
  shortDescription: z.string(),
  title: z.string(),
  type: z.string(),
  units: z.number(),
  unitType: z.string(),
});
