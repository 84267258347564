// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewClientCompany.cs

import {z} from 'zod';

export const DefaultResourcesApprovalQuestionsSchema = z.object({
  addDefaultBookQuestions: z.boolean(),
  addDefaultConferenceQuestions: z.boolean(),
  addDefaultOtherQuestions: z.boolean(),
  addDefaultSubscriptionQuestions: z.boolean(),
});
