// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AddCreditCardAuthorizationPayload.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

export const AddCreditCardAuthorizationPayloadSchema = z.object({
  amount: z.number(),
  approved: z.boolean(),
  cardId: z.number(),
  currencyCode: CurrencyCodeSchema,
});
