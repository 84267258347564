// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewClientCompany.cs

import {z} from 'zod';

export const DefaultPreApprovalQuestionsSchema = z.object({
  addDefaultFinanceQuestions: z.boolean(),
  addDefaultLicenseQuestions: z.boolean(),
  addDefaultProgramQuestions: z.boolean(),
  addDefaultTimeQuestions: z.boolean(),
});
