// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/ProgramProgressPreviewVM.cs

import {z} from 'zod';

export const ProgramProgressPreviewVMSchema = z.object({
  date: z.string(),
  id: z.number(),
  status: z.string(),
  title: z.string(),
  week: z.number(),
});
