export enum ButtonTags {
  Destructive = 'destructive',
  DestructiveRemovedBorder = 'destructiveRemovedBorder',
  DestructiveSecondary = 'destructiveSecondary',
  DrawerFooterPrimary = 'drawerFooterPrimary',
  DrawerFooterSecondary = 'drawerFooterSecondary',
  Footer = 'footer',
  Link = 'link',
  Primary = 'primary',
  RemovedBorder = 'removedBorder',
  Secondary = 'secondary',
  SecondaryBlack = 'secondaryBlack',
  SecondaryBlackRemovedBorder = 'secondaryBlackRemovedBorder',
  SecondaryRemovedBorder = 'secondaryRemovedBorder',
}
