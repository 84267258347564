// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyMoveStepPayload.cs

import {z} from 'zod';

export const AcademyMoveStepPayloadSchema = z.object({
  newAcademyLevelId: z.number(),
  oldAcademyLevelId: z.number(),
  steps: z.array(z.number()),
});
