// Do not modify directly
// Generated from file: learnin.core.api/Managers/Payments/DTOs/PlaidLinkConfig.cs

import {z} from 'zod';

export const PlaidLinkConfigSchema = z.object({
  clientName: z.string(),
  environment: z.string(),
  products: z.array(z.string()),
  token: z.string(),
});
