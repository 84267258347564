// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramSkillVM.cs

import {z} from 'zod';

export const ProgramSkillVMSchema = z.object({
  description: z.string(),
  id: z.number(),
  name: z.string(),
});
