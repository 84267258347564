// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/NewResourceApprovalApplicationVM.cs

import {z} from 'zod';
import {PreApprovalQuestionVMSchema} from './PreApprovalQuestionVMSchema.schema';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';

export const NewResourceApprovalApplicationVMSchema = z.object({
  questions: z.array(PreApprovalQuestionVMSchema),
  resourceType: LearningResourceTypeSchema,
});
