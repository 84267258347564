import {UserDetailsVM} from '@generated/interfaces';
import {UrlPath} from './useGetUrlIncludesList';

/**
 * Returns true if the hash includes any of the paths in the hideSideNavWhenUrlIncludes list
 */
export const useUrlIsInHideNavList = (
  hideSideNavWhenUrlIncludes: UrlPath[],
  hash: string,
  user: UserDetailsVM
) => {
  const hashNormalized = '/' + hash.toLowerCase();
  return !!hideSideNavWhenUrlIncludes.find((pathObject) => {
    const pathNormalized = pathObject.path.toLowerCase();
    const enabled = pathObject.enabled?.(user) ?? true;
    return enabled && hashNormalized.includes(pathNormalized);
  });
};
