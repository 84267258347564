// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPlanContentRsvpUpdate.cs

import {z} from 'zod';
import {RsvpStatusSchema} from './RsvpStatusSchema.schema';

export const UserPlanContentRsvpUpdateSchema = z.object({
  contentId: z.number(),
  rsvpStatus: RsvpStatusSchema,
});
