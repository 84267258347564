// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramActivityResult.cs

import {z} from 'zod';

export const CustomProgramActivityResultSchema = z.object({
  email: z.string().nullable().optional(),
  employeeId: z.number(),
  employeeName: z.string(),
  lastActiveDate: z.string().nullable().optional(),
  lastCustomProgramNudgeSent: z.string().nullable().optional(),
  progressPercentage: z.number(),
  startDate: z.string().nullable().optional(),
  userId: z.string(),
});
