// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramCohortAdminVM.cs

import {z} from 'zod';
import {CohortParticipantPreviewVMSchema} from './CohortParticipantPreviewVMSchema.schema';
import {CustomProgramStatusSchema} from './CustomProgramStatusSchema.schema';

export const CustomProgramCohortAdminVMSchema = z.object({
  cohortTitle: z.string().nullable().optional(),
  endDateUtc: z.string(),
  id: z.number().nullable().optional(),
  parentCustomProgramId: z.number(),
  participantCount: z.number(),
  participantPreview: z.array(CohortParticipantPreviewVMSchema),
  recommendationPermissionCompanyIds: z.array(z.number()),
  restrictedAccess: z.boolean(),
  startDateUtc: z.string(),
  status: CustomProgramStatusSchema,
});
