import {ModalTags} from './ModalEnums';

const defaultStyle = {
  borderRadius: '10px',
  padding: '0',
  displayFooterContent: 'flex',
  justifyFooterContent: 'flex-end',
};

export const ModalStyles = {
  [ModalTags.MoneyIncentive]: {
    ...defaultStyle,
    padding: '24px',
  },
  [ModalTags.Default]: defaultStyle,
  [ModalTags.LicenseAuto]: defaultStyle,
  [ModalTags.PublishIncentiveConfirmation]: defaultStyle,
  [ModalTags.RemoveProgramFromUserPlan]: defaultStyle,
  [ModalTags.AddFundsToCreditCard]: defaultStyle,
  [ModalTags.ConnectCalendar]: defaultStyle,
  [ModalTags.CardIssuerTermsModal]: defaultStyle,
};
