// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserRequestType.cs

import {z} from 'zod';

export const UserRequestTypeSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
]);
