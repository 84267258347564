// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/DisplayStatus.cs

import {z} from 'zod';

export const DisplayStatusSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(13),
  z.literal(14),
  z.literal(15),
]);
