// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Payments/AdminActionDetailVM.cs

import {z} from 'zod';
import {UserFinanceIncentiveUpdateTypeSchema} from './UserFinanceIncentiveUpdateTypeSchema.schema';

export const AdminActionDetailVMSchema = z.object({
  amountAfterUpdate: z.number().nullable().optional(),
  amountBeforeUpdate: z.number().nullable().optional(),
  type: UserFinanceIncentiveUpdateTypeSchema,
});
