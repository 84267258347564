// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/MessageDto.cs

import {z} from 'zod';
import {BaseEntitySchema} from './BaseEntitySchema.schema';
import {AuthorSchema} from './AuthorSchema.schema';

const schema = z.object({
  author: AuthorSchema.nullable().optional(),
  authorId: z.number(),
  content: z.string().nullable().optional(),
  discussionId: z.string(),
  id: z.string(),
  parentId: z.string().nullable().optional(),
});

export const MessageDtoSchema = BaseEntitySchema.merge(schema);
