import {ChangeEvent, CSSProperties, forwardRef, ReactNode} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {isNil} from 'lodash';
import {Input, Select} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {InputProps} from 'antd/lib/input';
import {PreApprovalQuestionInputType} from '@generated/enums';
import {Locale} from '@models/clientEnums';
import {BodySpan} from '@components/reusable/Typography';
import {exchangeAmountBack} from '@utils/moneyUtils';

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/

export const updateValueFromEvent = (updateFunc: (val: any) => void) => {
  return (e: ChangeEvent<HTMLInputElement>) => updateFunc(e.target.value);
};

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const InputLabelContainer = styled.label`
  font-weight: 500;
  font-size: 1rem !important;
  color: ${COLORS.Neutral900};
  margin-bottom: 7px;
  display: block;
`;

export const InputLabelStub = styled.div`
  height: 24px;
  margin-bottom: 7px;
`;

const ErrorMessageContainer = styled.div`
  font-size: 0.75rem;
  color: ${COLORS.Red800};
  margin-top: 6px;
  ${({extraStyles}) => ({...extraStyles})};
`;

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/

export const RequiredMarker = () => (
  <BodySpan color={COLORS.Red800} style={{paddingLeft: '4px'}}>
    *
  </BodySpan>
);

export const InputLabel = ({
  id,
  label,
  htmlFor,
  required,
  tooltipContent,
}: {
  id?: string;
  label: string;
  htmlFor: string; // a11y requirement: set to "id" of the related field
  required?: boolean;
  tooltipContent?: ReactNode;
}) => {
  return (
    <InputLabelContainer
      id={id}
      htmlFor={htmlFor}
      onClick={() => {
        let target = document.getElementById(htmlFor);

        // ReactQuill handling
        if (target?.classList.contains('quill')) {
          target = target?.querySelector('[contenteditable]') as HTMLElement;
        }

        target?.focus();
      }}>
      {label}
      {required && <RequiredMarker />}
      {!!tooltipContent && (
        <span style={{marginLeft: '6px'}}>
          <LearnInTooltip title={tooltipContent}>
            <QuestionCircleOutlined aria-hidden="true" />
          </LearnInTooltip>
        </span>
      )}
    </InputLabelContainer>
  );
};

export const ErrorMessage = ({
  message,
  style,
  ...rest
}: {
  message?: string;
  style?: CSSProperties;
}) => {
  return !!message ? (
    <ErrorMessageContainer extraStyles={style} {...rest}>
      {message}
    </ErrorMessageContainer>
  ) : null;
};

export const charLimitErrorMessage = (
  fieldName: string,
  charLimit: number
): string => {
  return i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT, {
    item: fieldName,
    max: charLimit,
  });
};

/*
|--------------------------------------------------------------------------
| Inputs
|--------------------------------------------------------------------------
*/

// Text Input
export const StyledLearnInInput = styled(Input)`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.Neutral300};
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  padding: 0 16px;
  color: ${COLORS.Neutral900};
`;

export const InputPrefixContainer = styled.div`
  background: ${(props) =>
    props.prefixBgTransparent ? 'transparent' : COLORS.Neutral200};
  height: 36px;
  width: fit-content;
  min-width: ${(props) => (props.condensed ? 0 : '36px')};
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) =>
    props.condensed ? (props.prefixBgTransparent ? 0 : '6px') : '8px'};
  border-radius: 3px;
  color: ${COLORS.Black};
`;

type InputPropsType = InputProps & {updateFunc: (val?: any) => void};

export const LearnInInput = forwardRef<any, InputPropsType>(
  function LearnInInput({updateFunc, ...rest}, ref) {
    return (
      <StyledLearnInInput
        ref={ref}
        onChange={updateValueFromEvent(updateFunc)}
        {...rest}
      />
    );
  }
);

export const addHttpsToUrl = (url: string): string => {
  if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
};

/* Money Input */
const internationalNumberFormat = new Intl.NumberFormat(Locale.en_US);

// Removes commas and converts a string to a number
export const convertStringToNumber = (moneyString: string): number =>
  Number((moneyString || '').replace(/,/g, ''));
export const isMoneyInputValid = (inputVal: string): boolean =>
  !Number.isNaN(convertStringToNumber(inputVal));
export const getMoneyFieldError = (
  responseValue: string,
  maxAmount = Infinity,
  minAmount = 0.01, // User cannot request no money
  allowDecimals = true
): string | undefined => {
  if (!isMoneyInputValid(responseValue)) return i18n.t(k.VALIDATION__NUMBER);
  if (Number(convertStringToNumber(responseValue)) > maxAmount)
    return i18n.t(k.FUND__BUDGET__EXCEEDED__FORMAT, {
      amount: maxAmount,
    });
  if (Number(convertStringToNumber(responseValue)) < minAmount)
    return i18n.t(k.VALIDATION__MONEY_AT_LEAST__FORMAT, {
      amount: `$${minAmount}`,
    });
  if (!allowDecimals && responseValue.includes('.'))
    return i18n.t(k.VALIDATION__NO_DECIMAL);
};

// Takes a string and adds commas and removes extra decimal places
export const formatNumberString = (numberStr: string): string => {
  // do not apply formatting for invalid numbers
  if (!isMoneyInputValid(numberStr)) return numberStr;

  const [dollars, cents] = numberStr.split('.');
  // Recalculate where commas are in dollars
  const formattedDollars = internationalNumberFormat.format(
    Number(dollars.replace(/,/g, ''))
  );
  const formattedValue = [formattedDollars];
  // Add cents
  if (cents !== undefined) {
    // Add decimal if present
    const formattedCents = cents.slice(0, 2);
    // Add cents if present
    if (!Number.isNaN(Number(formattedCents)))
      formattedValue.push(formattedCents.slice(0, 2));
  }
  return formattedValue.join('.');
};

export const MoneyInput = ({
  updateFunc,
  ...rest
}: InputProps & {updateFunc?: (val: any) => void}) => {
  // Format the input on blur
  const handleBlur = () => {
    updateFunc?.(formatNumberString(String(rest.value)));
  };
  return (
    <StyledLearnInInput
      prefix={<InputPrefixContainer>$</InputPrefixContainer>}
      style={{paddingLeft: '2px'}}
      onBlur={handleBlur}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (updateFunc) updateFunc(e.target.value);
      }}
      {...rest}
    />
  );
};

// Text Area
export const StyledLearnInTextArea = styled(Input.TextArea)`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.Neutral200};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  color: ${COLORS.Neutral900};
`;

export const LearnInTextArea = ({updateFunc, ...rest}: InputPropsType) => {
  return (
    <StyledLearnInTextArea
      onChange={updateValueFromEvent(updateFunc)}
      {...rest}
    />
  );
};

// Select
export const LearnInSelect = styled(Select)`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.Neutral200};
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  width: 100%;
  color: ${COLORS.Neutral900};

  .ant-select-selector {
    height: 42px !important;
  }

  .ant-select-selection-item {
    padding-top: 5px !important;
  }
`;

export const formatResponseValue = ({
  type,
  response,
  total,
  exchangeRate,
}: {
  type: PreApprovalQuestionInputType;
  response: any;
  total?: number;
  exchangeRate?: number;
}): any => {
  switch (type) {
    case PreApprovalQuestionInputType.Url:
      return !!response ? addHttpsToUrl(response) : '';
    case PreApprovalQuestionInputType.Money:
      const amount = total || response;

      if (isNil(amount) || isNil(exchangeRate)) {
        throw new Error(
          'Invalid arguments: `total` and `exchangeRate` are required for PreApprovalQuestionInputType.Money type'
        );
      }
      return exchangeAmountBack(amount, exchangeRate);
    default:
      return response || '';
  }
};
