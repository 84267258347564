// Do not modify directly
// Generated from file: learnin.core.application/Services/UserService/Responses/AdminUserTabVM.cs

import {z} from 'zod';
import {PeopleTabUserSchema} from './PeopleTabUserSchema.schema';

export const AdminUserTabVMSchema = z.object({
  currentPage: z.number(),
  resultsPerPage: z.number(),
  totalPages: z.number(),
  totalResults: z.number(),
  users: z.array(PeopleTabUserSchema),
});
