import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 41px;
  text-align: center;
  width: 100%;
  @media (min-width: 1000px) {
    margin-top: 151px;
  }
`;

const NoAccess = ({path}: {path: 'academies' | 'programs'}) => {
  const featureTranslation =
    path === 'academies' ? 'ACADEMY__PLURAL' : 'PROGRAM__PLURAL';
  return (
    <Wrapper>
      <LearnInText tag={TextTags.BoringErrorMessage}>
        {i18n.t(k.NOTIFICATION__ACESSS_NOT_ALLOWED, {
          feature: i18n.t(k[featureTranslation]),
        })}
      </LearnInText>
    </Wrapper>
  );
};

export default NoAccess;
