import keyMirror from 'key-mirror';
import GENERATED_KEYS from './GENERATED_KEYS';

/*
  i18n Keys Style Guide:

    Naming Conventions:
      - Use all caps (these are constants)
      - Namespaces should be singular (not plural)
      - Double-underscore (__) between namespaces and sub-namespaces
      - Single-underscore (_) for related words

  Namespaces represent a distinct category (noun or verb) in the product.
  Use #regions per namespace so namespaces can be easily folded/unfolded.
*/

export type TKeyMapKey = keyof typeof GENERATED_KEYS;
const keys: Record<TKeyMapKey, TKeyMapKey> = keyMirror(GENERATED_KEYS);

export default keys;
