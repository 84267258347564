// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewProgram.cs

import {z} from 'zod';
import {NewProgramSchema} from './NewProgramSchema.schema';

const schema = z.object({
  programId: z.number(),
});

export const EditProgramSchema = NewProgramSchema.merge(schema);
