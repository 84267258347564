// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/PlanItemType.cs

enum PlanItemType {
  Program,
  Time,
  Finance,
  ProgramLicense,
}

export default PlanItemType;
