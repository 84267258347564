import {LearnInFormItem} from '@components/reusable/Form/Form';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {
  getCommonRules,
  renderQuillLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {LearningResourceType} from '@generated/enums';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {showHelperTextIfAvailable} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface TextInputProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  isHelperTextOn: boolean;
  isTextAreaDisabled: boolean;
  maxLength: number;
  resourceType?: LearningResourceType;
  showCount?: boolean;
  dataCy?: string;
}

export const TextInput = ({
  item,
  isHelperTextOn,
  isTextAreaDisabled,
  maxLength,
  resourceType,
  showCount,
  dataCy = null,
}: TextInputProps) => {
  return (
    <LearnInFormItem
      key={item.id}
      name={String(item.id)}
      className="lb-application-drawer"
      data-cy={dataCy}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      initialValue={item.response || ''}
      label={renderQuillLabel(item, resourceType)}
      requiredMark={false}
      rules={getCommonRules(item, isHelperTextOn)}>
      <LearnInTextArea
        tag={TextAreaTags.PRIMARY}
        disabled={isTextAreaDisabled}
        maxLength={maxLength}
        showCount={showCount}
      />
    </LearnInFormItem>
  );
};
