import {useEffect, useState} from 'react';
import i18next from 'i18next';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {handleOnLanguageUpdated, LANGUAGE} from '@utils/l10nUtils';
import {ConfigProvider as AntDLanguageProvider} from 'antd';
import QuickActionModal from '@blocks/QuickActionModal';
import useQuickActionModalKeyListener from '@blocks/QuickActionModal/QuickActionModal.hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {UserSettingsVM} from '@generated/interfaces';
import {useUpdateLanguagePreferenceMutation} from '@generated/hooks';
import {
  initI18nOnLocaleChange,
  initMomentLocale,
  useSetLanguageEffect,
} from './LocalizationInit.utils';
import {
  LANGUAGE_DEFAULT_NAMESPACE,
  LANGUAGE_FALLBACK_LOCALE,
} from '@utils/constants';
import {useAuth} from '@utils/oidc-auth-wrapper';

interface Props {
  children: JSX.Element | null;
}

export const showQuickActionModal = (
  isProductionAny: boolean,
  quickActionModalVisible: boolean
) => !isBetaAny && !isProductionAny && quickActionModalVisible;

// BEN-4152: This is to be removed once the temp feature flag "CurrencySymbolSimplification" is removed
export const TEMP_CURRENCY_SYMBOL_KEY = 'TEMP_CURRENCY_SYMBOL_KEY';

export default function LocalizationInit({children}: Props) {
  const [isLanguageFileLoaded, setIsLanguageFileLoaded] = useState(false);
  const [quickActionModalVisible, setQuickActionModalVisible] = useState(false);
  const [antDLocaleName, setAntDLocaleName] = useState(null);
  const {isFeatureFlagOn} = useFeatureFlags();
  const updateSettingsMutation = useUpdateLanguagePreferenceMutation({
    onSuccess: (userData: UserSettingsVM) => handleOnLanguageUpdated(userData),
  });
  const {user} = useAuth();

  useQuickActionModalKeyListener(!isProductionAny, setQuickActionModalVisible);

  useSetLanguageEffect({
    user,
    initI18nOnLocaleChange,
    initMomentLocale,
    isSetLanguageFromBrowserOn: isFeatureFlagOn.SetLanguageFromBrowser,
    setAntDLocaleName,
    updateSettingsMutation,
  });

  useEffect(() => {
    setIsLanguageFileLoaded(true);
  }, [
    i18next.hasResourceBundle(
      LANGUAGE_FALLBACK_LOCALE,
      LANGUAGE_DEFAULT_NAMESPACE
    ),
  ]);

  return (
    user &&
    isLanguageFileLoaded && (
      <AntDLanguageProvider
        locale={LANGUAGE[antDLocaleName]?.antD ?? LANGUAGE.EN_US.antD}>
        {children}
        {showQuickActionModal(isProductionAny, quickActionModalVisible) && (
          <QuickActionModal
            isVisible={quickActionModalVisible}
            setIsVisible={() => setQuickActionModalVisible(false)}
          />
        )}
      </AntDLanguageProvider>
    )
  );
}
