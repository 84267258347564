import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import LearnInSelect from '@components/reusable/Select/Select.style';
import {
  EMAIL_PREVIEW_CONTAINER_PADDING,
  EMAIL_PREVIEW_CONTAINER_WIDTH,
} from './EmailPreview.constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';

export const EmailPreviewWrapper = styled.section`
  background: repeating-linear-gradient(
    45deg,
    ${COLORS.Neutral50},
    ${COLORS.Neutral50} 6px,
    ${COLORS.Neutral100} 8px,
    ${COLORS.Neutral100} 30px
  );
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const EmailPreviewIframe = styled.iframe`
  background: ${COLORS.White};
  border: none;
  border-radius: 3px;
  box-shadow: 0.125rem 0.25rem 2rem ${COLORS.Neutral300};
  box-sizing: initial;
  height: calc(100% - 20vh);
  margin-top: 5vh;
  padding: 0;
  width: ${EMAIL_PREVIEW_CONTAINER_WIDTH +
  EMAIL_PREVIEW_CONTAINER_PADDING * 2}px;
`;

export const EmailPreviewActions = styled.div`
  background: ${COLORS.Neutral200};
  border-bottom: solid 1px ${COLORS.White};
  margin: 0 auto;
  padding: 0.75rem 1rem;
  text-align: left;
`;

export const EmailPreviewLearnInSelect = styled(LearnInSelect)`
  text-align: left;
  width: ${EMAIL_PREVIEW_CONTAINER_WIDTH + 78}px;
`;

export const EmailPreviewActionButton = styled(LearnInButton)`
  display: inline-block;
  padding: 0.5rem 0.75rem;
`;

export const EmailPreviewActionButtons = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const EmailPreviewActionsInner = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 37rem;
`;
