// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Dashboard/GetStartedItemVM.cs

import {z} from 'zod';

export const GetStartedItemVMSchema = z.object({
  featured: z.number(),
  id: z.number(),
  imageSmall: z.string(),
  logo: z.string(),
  orderBy: z.number(),
  recommendationLevel: z.number(),
  subtitle: z.string(),
  title: z.string(),
});
