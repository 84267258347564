// Do not modify directly
// Generated from file: learnin.core.application/Services/UserService/Responses/PeopleTabUser.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

export const PeopleTabUserSchema = z.object({
  approvedAmount: z.number().nullable().optional(),
  costCenter: z.string().nullable().optional(),
  lastLogin: z.string().nullable().optional(),
  managerName: z.string().nullable().optional(),
  spentAmount: z.number().nullable().optional(),
  totalBudget: z.number().nullable().optional(),
  userCompanyId: z.number().nullable().optional(),
  userCurrency: CurrencyCodeSchema.nullable().optional(),
  userName: z.string(),
});
