// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeDetailVm.cs

import {z} from 'zod';
import {UserPlanResourceVMSchema} from './UserPlanResourceVMSchema.schema';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

const schema = z.object({
  resourceUrl: z.string().nullable().optional(),
  status: UserItemStatusVMSchema,
});

export const UserResourceInsightSchema = UserPlanResourceVMSchema.merge(schema);
