// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/PreApprovalAmountBalanceVM.cs

import {z} from 'zod';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';

export const PreApprovalAmountBalanceVMSchema = z.object({
  amountBalance: z.number().nullable().optional(),
  amountLimit: z.number().nullable().optional(),
  financeAmountType: FinanceAmountTypeSchema,
  financeType: FinanceTypeSchema,
});
