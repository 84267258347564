// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramAdminVM.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';

export const ProgramAdminVMSchema = z.object({
  addedBy: z.string(),
  available: z.boolean(),
  cost: z.number(),
  currency: CurrencyCodeSchema,
  duration: z.string(),
  featured: z.boolean(),
  id: z.number(),
  programTitle: z.string(),
  programType: z.string().nullable().optional(),
  provider: z.string(),
  providerLogo: z.string(),
});
