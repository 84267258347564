// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/IncentiveInitiativeProgramVM.cs

import {z} from 'zod';

export const IncentiveInitiativeProgramVMSchema = z.object({
  cost: z.number(),
  id: z.number(),
  imageSmall: z.string(),
  provider: z.string(),
  providerLogo: z.string(),
  selected: z.boolean(),
  shortDescription: z.string(),
  title: z.string(),
});
