// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeDetailVm.cs

import {z} from 'zod';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

export const UserProgramInsightSchema = z.object({
  amount: z.number(),
  dateStarted: z.string().nullable().optional(),
  name: z.string(),
  programId: z.number().nullable().optional(),
  provider: z.string(),
  status: UserItemStatusVMSchema,
});
