import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  RequestApprovalType,
} from './../enums';
import {
  ApprovalRequestVM,
  ResourceApprovalRequestVM,
  UserRequestApprovalDetailsVM,
  UserRequestApprovalsResponse,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ApprovalRequestVMSchema} from '../zodSchemas/ApprovalRequestVMSchema.schema';
import {ResourceApprovalRequestVMSchema} from '../zodSchemas/ResourceApprovalRequestVMSchema.schema';
import {UserRequestApprovalDetailsVMSchema} from '../zodSchemas/UserRequestApprovalDetailsVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Approvals/ManagerApprovalsController.cs

/*
|--------------------------------------------------------------------------
|  get-approval-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsApprovalDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managerApprovalsApprovalDetails,
 *    isLoading: isManagerApprovalsApprovalDetailsLoading,
 *    invalidateExact: invalidateManagerApprovalsApprovalDetails,
 *  } = useManagerApprovalsApprovalDetailsQuery(null)
 *
 */

export const useManagerApprovalsApprovalDetailsQuery = queryHookFactory<
  null,
  UserRequestApprovalDetailsVM // return type
>(
  'useManagerApprovalsApprovalDetailsQuery',
  () => `manager-approvals/get-approval-details`,
  {responseSchema: UserRequestApprovalDetailsVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managerApprovalsApprovals,
 *    isLoading: isManagerApprovalsApprovalsLoading,
 *    invalidateExact: invalidateManagerApprovalsApprovals,
 *  } = useManagerApprovalsApprovalsQuery({
 *    queryParams: {
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Manager Access Only
 *
 */

export const useManagerApprovalsApprovalsQuery = queryHookFactory<
  {queryParams?: {useNewApprovalSystem?: boolean}},
  ApprovalRequestVM[] // return type
>(
  'useManagerApprovalsApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(() => `manager-approvals/get-approvals`, null, queryParams),
  {responseSchema: z.array(ApprovalRequestVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-resource-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsResourceApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managerApprovalsResourceApprovals,
 *    isLoading: isManagerApprovalsResourceApprovalsLoading,
 *    invalidateExact: invalidateManagerApprovalsResourceApprovals,
 *  } = useManagerApprovalsResourceApprovalsQuery({
 *    queryParams: {
 *        status,
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Manager Access Only
 *
 */

export const useManagerApprovalsResourceApprovalsQuery = queryHookFactory<
  {queryParams?: {status?: ApprovalStatus; useNewApprovalSystem?: boolean}},
  ResourceApprovalRequestVM[] // return type
>(
  'useManagerApprovalsResourceApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `manager-approvals/get-resource-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ResourceApprovalRequestVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsUserRequestApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managerApprovalsUserRequestApprovals,
 *    isLoading: isManagerApprovalsUserRequestApprovalsLoading,
 *    invalidateExact: invalidateManagerApprovalsUserRequestApprovals,
 *  } = useManagerApprovalsUserRequestApprovalsQuery({
 *    queryParams: {
 *        isAscending,
 *        pageNumber,
 *        pageSize,
 *        requestType,
 *        searchTerm,
 *        sortColumn,
 *        status
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useManagerApprovalsUserRequestApprovalsQuery = queryHookFactory<
  {
    queryParams?: {
      isAscending?: boolean;
      pageNumber?: number;
      pageSize?: number;
      requestType?: RequestApprovalType;
      searchTerm?: string;
      sortColumn?: string;
      status?: ApprovalStatus;
    };
  },
  UserRequestApprovalsResponse // return type
>('useManagerApprovalsUserRequestApprovalsQuery', ({queryParams}) =>
  addQueryParams(
    () => `manager-approvals/user-request-approvals`,
    null,
    queryParams
  )
);

/*
|--------------------------------------------------------------------------
|  get-user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsUserRequestApprovalsLegacyQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managerApprovalsUserRequestApprovalsLegacy,
 *    isLoading: isManagerApprovalsUserRequestApprovalsLegacyLoading,
 *    invalidateExact: invalidateManagerApprovalsUserRequestApprovalsLegacy,
 *  } = useManagerApprovalsUserRequestApprovalsLegacyQuery({
 *    queryParams: {
 *        status,
 *        type
 *    }
 *  })
 *
 */

export const useManagerApprovalsUserRequestApprovalsLegacyQuery =
  queryHookFactory<
    {queryParams?: {status?: ApprovalStatus; type?: ApprovalsDiscriminator}},
    UserRequestApprovalDetailsVM[] // return type
  >(
    'useManagerApprovalsUserRequestApprovalsLegacyQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `manager-approvals/get-user-request-approvals`,
        null,
        queryParams
      ),
    {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)}
  );
