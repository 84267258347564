import {WhatsNextContainerProps} from './WhatsNext.interfaces';
import WhatsNextView from './WhatsNext.view';
import useFeatureFlags from '@hooks/useFeatureFlags';

function WhatsNextContainer(props: WhatsNextContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isWhatsNextOn = isFeatureFlagOn.WhatsNext;

  return <WhatsNextView visible={isWhatsNextOn} />;
}

export default WhatsNextContainer;
