import {AcademyStatus} from './../enums';
import {
  AcademiesCountVM,
  AcademyLevelVM,
  AcademyPeopleUserVM,
  AcademyStepVM,
  AcademyUserVM,
  AcademyVM,
  AcademyWithMembershipStatusVM,
  AttachmentVM,
  CursorEnvelopeMessageResponse,
  LinkVM,
  PermissionVM,
  ProjectAdminVM,
  ProjectSubmissionAdminVM,
  ProjectSubmissionVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {AcademyVMSchema} from '../zodSchemas/AcademyVMSchema.schema';
import {AcademyWithMembershipStatusVMSchema} from '../zodSchemas/AcademyWithMembershipStatusVMSchema.schema';
import {AcademiesCountVMSchema} from '../zodSchemas/AcademiesCountVMSchema.schema';
import {AcademyUserVMSchema} from '../zodSchemas/AcademyUserVMSchema.schema';
import {AcademyLevelVMSchema} from '../zodSchemas/AcademyLevelVMSchema.schema';
import {AcademyStepVMSchema} from '../zodSchemas/AcademyStepVMSchema.schema';
import {LinkVMSchema} from '../zodSchemas/LinkVMSchema.schema';
import {AttachmentVMSchema} from '../zodSchemas/AttachmentVMSchema.schema';
import {CursorEnvelopeMessageResponseSchema} from '../zodSchemas/CursorEnvelopeMessageResponseSchema.schema';
import {ProjectAdminVMSchema} from '../zodSchemas/ProjectAdminVMSchema.schema';
import {ProjectSubmissionAdminVMSchema} from '../zodSchemas/ProjectSubmissionAdminVMSchema.schema';
import {ProjectSubmissionVMSchema} from '../zodSchemas/ProjectSubmissionVMSchema.schema';
import {PermissionVMSchema} from '../zodSchemas/PermissionVMSchema.schema';
import {AcademyPeopleUserVMSchema} from '../zodSchemas/AcademyPeopleUserVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/academy-levels/{academyLevelId}/academy-steps/{academyStepId}/discussions/{discussionId}/messages 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesAcademyLevelsAcademyStepsDiscussionsMessages,
 *    isLoading: isAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesLoading,
 *    invalidateExact: invalidateAcademiesAcademyLevelsAcademyStepsDiscussionsMessages,
 *  } = useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery({
 *    academyId,
 *    academyLevelId,
 *    academyStepId,
 *    discussionId,
 *      queryParams: {
 *        after,
 *        size
 *    }
 *  })
 *
 */

export const useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery =
  queryHookFactory<
    {
      academyId: number | string;
      academyLevelId: number | string;
      academyStepId: number | string;
      discussionId: string;
      queryParams?: {after?: string; size?: number};
    }, // path variables
    CursorEnvelopeMessageResponse // return type
  >(
    'useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery',
    ({academyId, academyLevelId, academyStepId, discussionId, queryParams}) =>
      addQueryParams(
        ({academyId, academyLevelId, academyStepId, discussionId}) =>
          `academies/${academyId}/academy-levels/${academyLevelId}/academy-steps/${academyStepId}/discussions/${discussionId}/messages`,
        {academyId, academyLevelId, academyStepId, discussionId},
        queryParams
      ),
    {responseSchema: CursorEnvelopeMessageResponseSchema}
  );

/*
|--------------------------------------------------------------------------
|  get-content-attachment/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesContentAttachmentQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesContentAttachment,
 *    isLoading: isAcademiesContentAttachmentLoading,
 *    invalidateExact: invalidateAcademiesContentAttachment,
 *  } = useAcademiesContentAttachmentQuery({
 *    attachmentId
 *  })
 *
 */

export const useAcademiesContentAttachmentQuery = queryHookFactory<
  {attachmentId: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAcademiesContentAttachmentQuery',
  ({attachmentId}) => `academies/get-content-attachment/${attachmentId}`,
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  get-content-attachment-url/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesContentAttachmentUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesContentAttachmentUrl,
 *    isLoading: isAcademiesContentAttachmentUrlLoading,
 *    invalidateExact: invalidateAcademiesContentAttachmentUrl,
 *  } = useAcademiesContentAttachmentUrlQuery({
 *    attachmentId
 *  })
 *
 */

export const useAcademiesContentAttachmentUrlQuery = queryHookFactory<
  {attachmentId: number | string}, // path variables
  string // return type
>(
  'useAcademiesContentAttachmentUrlQuery',
  ({attachmentId}) => `academies/get-content-attachment-url/${attachmentId}`,
  {responseSchema: z.string()}
);

/*
|--------------------------------------------------------------------------
|  get-project-submissions/{projectId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesProjectSubmissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesProjectSubmissions,
 *    isLoading: isAcademiesProjectSubmissionsLoading,
 *    invalidateExact: invalidateAcademiesProjectSubmissions,
 *  } = useAcademiesProjectSubmissionsQuery({
 *    projectId
 *  })
 *
 */

export const useAcademiesProjectSubmissionsQuery = queryHookFactory<
  {projectId: number}, // path variables
  ProjectSubmissionAdminVM[] // return type
>(
  'useAcademiesProjectSubmissionsQuery',
  ({projectId}) => `academies/get-project-submissions/${projectId}`,
  {responseSchema: z.array(ProjectSubmissionAdminVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-project-submission-url/{submissionId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesProjectSubmissionUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesProjectSubmissionUrl,
 *    isLoading: isAcademiesProjectSubmissionUrlLoading,
 *    invalidateExact: invalidateAcademiesProjectSubmissionUrl,
 *  } = useAcademiesProjectSubmissionUrlQuery({
 *    submissionId
 *  })
 *
 */

export const useAcademiesProjectSubmissionUrlQuery = queryHookFactory<
  {submissionId: number | string}, // path variables
  string // return type
>(
  'useAcademiesProjectSubmissionUrlQuery',
  ({submissionId}) => `academies/get-project-submission-url/${submissionId}`,
  {responseSchema: z.string()}
);

/*
|--------------------------------------------------------------------------
|  get-user-permissions/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUserPermissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesUserPermissions,
 *    isLoading: isAcademiesUserPermissionsLoading,
 *    invalidateExact: invalidateAcademiesUserPermissions,
 *  } = useAcademiesUserPermissionsQuery({
 *    academyId
 *  })
 *
 */

export const useAcademiesUserPermissionsQuery = queryHookFactory<
  {academyId: number | string}, // path variables
  PermissionVM[] // return type
>(
  'useAcademiesUserPermissionsQuery',
  ({academyId}) => `academies/get-user-permissions/${academyId}`,
  {responseSchema: z.array(PermissionVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  {academyId}/user-company/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUserCompanyByIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesUserCompanyById,
 *    isLoading: isAcademiesUserCompanyByIdLoading,
 *    invalidateExact: invalidateAcademiesUserCompanyById,
 *  } = useAcademiesUserCompanyByIdQuery({
 *    academyId,
 *    userCompanyId
 *  })
 *
 */

export const useAcademiesUserCompanyByIdQuery = queryHookFactory<
  {academyId: number; userCompanyId: number}, // path variables
  AcademyUserVM // return type
>(
  'useAcademiesUserCompanyByIdQuery',
  ({academyId, userCompanyId}) =>
    `academies/${academyId}/user-company/${userCompanyId}`,
  {responseSchema: AcademyUserVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-academies 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academies,
 *    isLoading: isAcademiesLoading,
 *    invalidateExact: invalidateAcademies,
 *  } = useAcademiesQuery({
 *    queryParams: {
 *        isRestricted,
 *        searchText,
 *        status
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAcademiesQuery = queryHookFactory<
  {
    queryParams?: {
      isRestricted?: boolean;
      searchText?: string;
      status?: AcademyStatus;
    };
  },
  AcademyVM[] // return type
>(
  'useAcademiesQuery',
  ({queryParams}) =>
    addQueryParams(() => `academies/get-academies`, null, queryParams),
  {responseSchema: z.array(AcademyVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academies-counts 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesCountsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesCounts,
 *    isLoading: isAcademiesCountsLoading,
 *    invalidateExact: invalidateAcademiesCounts,
 *  } = useAcademiesCountsQuery(null)
 *
 */

export const useAcademiesCountsQuery = queryHookFactory<
  null,
  AcademiesCountVM // return type
>('useAcademiesCountsQuery', () => `academies/get-academies-counts`, {
  responseSchema: AcademiesCountVMSchema,
});

/*
|--------------------------------------------------------------------------
|  get-academy/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academy,
 *    isLoading: isAcademyLoading,
 *    invalidateExact: invalidateAcademy,
 *  } = useAcademyQuery({
 *    academyId,
 *      queryParams: {
 *        includeSkills
 *    }
 *  })
 *
 */

export const useAcademyQuery = queryHookFactory<
  {academyId: number | string; queryParams?: {includeSkills?: boolean}}, // path variables
  AcademyVM // return type
>(
  'useAcademyQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/get-academy/${academyId}`,
      {academyId},
      queryParams
    ),
  {responseSchema: AcademyVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-academy-editors/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyEditorsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyEditors,
 *    isLoading: isAcademyEditorsLoading,
 *    invalidateExact: invalidateAcademyEditors,
 *  } = useAcademyEditorsQuery({
 *    academyId
 *  })
 *
 */

export const useAcademyEditorsQuery = queryHookFactory<
  {academyId: number | string}, // path variables
  AcademyPeopleUserVM[] // return type
>(
  'useAcademyEditorsQuery',
  ({academyId}) => `academies/get-academy-editors/${academyId}`,
  {responseSchema: z.array(AcademyPeopleUserVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-level-detail/{academyLevelId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyLevelDetailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyLevelDetail,
 *    isLoading: isAcademyLevelDetailLoading,
 *    invalidateExact: invalidateAcademyLevelDetail,
 *  } = useAcademyLevelDetailQuery({
 *    academyLevelId
 *  })
 *
 */

export const useAcademyLevelDetailQuery = queryHookFactory<
  {academyLevelId: number | string}, // path variables
  AcademyLevelVM // return type
>(
  'useAcademyLevelDetailQuery',
  ({academyLevelId}) => `academies/get-academy-level-detail/${academyLevelId}`,
  {responseSchema: AcademyLevelVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-academy-levels/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyLevelsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyLevels,
 *    isLoading: isAcademyLevelsLoading,
 *    invalidateExact: invalidateAcademyLevels,
 *  } = useAcademyLevelsQuery({
 *    academyId,
 *      queryParams: {
 *        excludeUserProgress
 *    }
 *  })
 *
 */

export const useAcademyLevelsQuery = queryHookFactory<
  {academyId: number; queryParams?: {excludeUserProgress?: boolean}}, // path variables
  AcademyLevelVM[] // return type
>(
  'useAcademyLevelsQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/get-academy-levels/${academyId}`,
      {academyId},
      queryParams
    ),
  {responseSchema: z.array(AcademyLevelVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-level-step/{academyLevelId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyLevelStepQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyLevelStep,
 *    isLoading: isAcademyLevelStepLoading,
 *    invalidateExact: invalidateAcademyLevelStep,
 *  } = useAcademyLevelStepQuery({
 *    academyLevelId
 *  })
 *
 */

export const useAcademyLevelStepQuery = queryHookFactory<
  {academyLevelId: number | string}, // path variables
  AcademyStepVM[] // return type
>(
  'useAcademyLevelStepQuery',
  ({academyLevelId}) => `academies/get-academy-level-step/${academyLevelId}`,
  {responseSchema: z.array(AcademyStepVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-project-reviewers/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyProjectReviewersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyProjectReviewers,
 *    isLoading: isAcademyProjectReviewersLoading,
 *    invalidateExact: invalidateAcademyProjectReviewers,
 *  } = useAcademyProjectReviewersQuery({
 *    academyId
 *  })
 *
 */

export const useAcademyProjectReviewersQuery = queryHookFactory<
  {academyId: number | string}, // path variables
  AcademyPeopleUserVM[] // return type
>(
  'useAcademyProjectReviewersQuery',
  ({academyId}) => `academies/get-academy-project-reviewers/${academyId}`,
  {responseSchema: z.array(AcademyPeopleUserVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-step-content-attachments/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyStepContentAttachmentsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyStepContentAttachments,
 *    isLoading: isAcademyStepContentAttachmentsLoading,
 *    invalidateExact: invalidateAcademyStepContentAttachments,
 *  } = useAcademyStepContentAttachmentsQuery({
 *    academyStepId
 *  })
 *
 */

export const useAcademyStepContentAttachmentsQuery = queryHookFactory<
  {academyStepId: number}, // path variables
  AttachmentVM[] // return type
>(
  'useAcademyStepContentAttachmentsQuery',
  ({academyStepId}) =>
    `academies/get-academy-step-content-attachments/${academyStepId}`,
  {responseSchema: z.array(AttachmentVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-step-content-links/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyStepContentLinksQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyStepContentLinks,
 *    isLoading: isAcademyStepContentLinksLoading,
 *    invalidateExact: invalidateAcademyStepContentLinks,
 *  } = useAcademyStepContentLinksQuery({
 *    academyStepId
 *  })
 *
 */

export const useAcademyStepContentLinksQuery = queryHookFactory<
  {academyStepId: number | string}, // path variables
  LinkVM[] // return type
>(
  'useAcademyStepContentLinksQuery',
  ({academyStepId}) =>
    `academies/get-academy-step-content-links/${academyStepId}`,
  {responseSchema: z.array(LinkVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-users/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyUsers,
 *    isLoading: isAcademyUsersLoading,
 *    invalidateExact: invalidateAcademyUsers,
 *  } = useAcademyUsersQuery({
 *    academyId
 *  })
 *
 */

export const useAcademyUsersQuery = queryHookFactory<
  {academyId: number | string}, // path variables
  AcademyUserVM[] // return type
>(
  'useAcademyUsersQuery',
  ({academyId}) => `academies/get-academy-users/${academyId}`,
  {responseSchema: z.array(AcademyUserVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-enrolled-academies 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useEnrolledAcademiesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: enrolledAcademies,
 *    isLoading: isEnrolledAcademiesLoading,
 *    invalidateExact: invalidateEnrolledAcademies,
 *  } = useEnrolledAcademiesQuery(null)
 *
 */

export const useEnrolledAcademiesQuery = queryHookFactory<
  null,
  AcademyVM[] // return type
>('useEnrolledAcademiesQuery', () => `academies/get-enrolled-academies`, {
  responseSchema: z.array(AcademyVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-peer-viewable-academy-submissions/{academyStepId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePeerViewableAcademySubmissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: peerViewableAcademySubmissions,
 *    isLoading: isPeerViewableAcademySubmissionsLoading,
 *    invalidateExact: invalidatePeerViewableAcademySubmissions,
 *  } = usePeerViewableAcademySubmissionsQuery({
 *    academyStepId
 *  })
 *
 */

export const usePeerViewableAcademySubmissionsQuery = queryHookFactory<
  {academyStepId: number}, // path variables
  ProjectSubmissionVM[] // return type
>(
  'usePeerViewableAcademySubmissionsQuery',
  ({academyStepId}) =>
    `academies/get-peer-viewable-academy-submissions/${academyStepId}`,
  {responseSchema: z.array(ProjectSubmissionVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-projects/{academyId:int} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProjectsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: projects,
 *    isLoading: isProjectsLoading,
 *    invalidateExact: invalidateProjects,
 *  } = useProjectsQuery({
 *    academyId
 *  })
 *
 */

export const useProjectsQuery = queryHookFactory<
  {academyId: number | string}, // path variables
  ProjectAdminVM[] // return type
>('useProjectsQuery', ({academyId}) => `academies/get-projects/${academyId}`, {
  responseSchema: z.array(ProjectAdminVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-published-academies 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePublishedAcademiesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: publishedAcademies,
 *    isLoading: isPublishedAcademiesLoading,
 *    invalidateExact: invalidatePublishedAcademies,
 *  } = usePublishedAcademiesQuery({
 *    queryParams: {
 *        includeSkills
 *    }
 *  })
 *
 */

export const usePublishedAcademiesQuery = queryHookFactory<
  {queryParams?: {includeSkills?: boolean}},
  AcademyWithMembershipStatusVM[] // return type
>(
  'usePublishedAcademiesQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `academies/get-published-academies`,
      null,
      queryParams
    ),
  {responseSchema: z.array(AcademyWithMembershipStatusVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-user-academy-level-step/{academyLevelId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserAcademyLevelStepQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userAcademyLevelStep,
 *    isLoading: isUserAcademyLevelStepLoading,
 *    invalidateExact: invalidateUserAcademyLevelStep,
 *  } = useUserAcademyLevelStepQuery({
 *    academyLevelId,
 *      queryParams: {
 *        excludeUserProgress
 *    }
 *  })
 *
 */

export const useUserAcademyLevelStepQuery = queryHookFactory<
  {academyLevelId: number; queryParams?: {excludeUserProgress?: boolean}}, // path variables
  AcademyStepVM[] // return type
>(
  'useUserAcademyLevelStepQuery',
  ({academyLevelId, queryParams}) =>
    addQueryParams(
      ({academyLevelId}) =>
        `academies/get-user-academy-level-step/${academyLevelId}`,
      {academyLevelId},
      queryParams
    ),
  {responseSchema: z.array(AcademyStepVMSchema)}
);
