// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/ProgressUpdatesBySummaryVM.cs

import {z} from 'zod';

export const ProgressUpdatesBySummaryVMSchema = z.object({
  key: z.number(),
  name: z.string(),
  percent: z.number(),
  total: z.number(),
});
