// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Requests/LxpContentId.cs

import {z} from 'zod';
import {LxpContentTypeSchema} from './LxpContentTypeSchema.schema';

export const LxpContentIdSchema = z.object({
  contentType: LxpContentTypeSchema,
  id: z.number(),
});
