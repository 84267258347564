import Dragger from 'antd/lib/upload/Dragger';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {notify} from '@components/user/notifications';
import {i18n, k} from '@i18n/translate';
import {FileTypeDetails, FileUploadType} from '@utils/uploadUtils';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';
import {UploadList} from '@components/reusable/Upload/UploadList';
import {
  getCommonRules,
  renderLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {FormInstance} from 'antd';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {showHelperTextIfAvailable} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface ImageUploadProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  isHelperTextOn: boolean;
  form: FormInstance;
  filesToUpload?: any[];
  setFilesToUpload?: (files: any[]) => void;
  filesProgress?: any[];
  deleteFunction?: (idx) => void;
}
export const ImageUpload = ({
  item,
  isHelperTextOn,
  form,
  filesToUpload,
  setFilesToUpload,
  filesProgress,
  deleteFunction,
}: ImageUploadProps) => {
  return (
    <LearnInFormItem
      onChange={(e) => form.setFieldsValue({[item.id]: e.target.value})}
      key={item.id}
      name={String(item.id)}
      label={renderLabel(item)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      requiredMark={false}
      className="lb-application-drawer"
      initialValue={item.response || ''}
      rules={getCommonRules(item, isHelperTextOn)}>
      <>
        {filesToUpload.length < 5 && (
          <Dragger
            onChange={(e) => {
              form.setFieldsValue({[item.id]: e.file.name});
            }}
            customRequest={async ({file}) => {
              if (
                ((file as File)?.size as number) >
                UploadConstraints.MaxUserContentUploadSizeBytes
              ) {
                return notify.uploadContentAttachmentTooLargeError(
                  (file as File)?.name || i18n.t(k.FILE)
                );
              } else {
                setFilesToUpload([...filesToUpload, file]);
              }
            }}
            showUploadList={false}
            maxCount={1}
            multiple={true}
            accept={[
              ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
              ...FileTypeDetails[FileUploadType.Pdf].fileSuffixes,
            ].join(', ')}>
            <UploadInstructions />
          </Dragger>
        )}

        {/* Attachment list*/}
        {!!filesToUpload && (
          <UploadList
            deleteFunction={deleteFunction}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            filesProgress={filesProgress}
          />
        )}
      </>
    </LearnInFormItem>
  );
};
