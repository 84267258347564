// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramSectionAdminVM.cs

import {z} from 'zod';
import {CustomProgramSectionVMSchema} from './CustomProgramSectionVMSchema.schema';
import {CustomProgramContentVMSchema} from './CustomProgramContentVMSchema.schema';

const schema = z.object({
  content: z.array(CustomProgramContentVMSchema),
});

export const CustomProgramSectionAdminVMSchema =
  CustomProgramSectionVMSchema.merge(schema);
