// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/LinkPayload.cs

import {z} from 'zod';

export const LinkPayloadSchema = z.object({
  id: z.number().nullable().optional(),
  title: z.string(),
  url: z.string(),
});
