// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/NotificationSettings.cs

import {z} from 'zod';

export const NotificationConfigSchema = z.object({
  isEmailEnabled: z.boolean(),
  isTextEnabled: z.boolean(),
  name: z.string(),
});
