// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewClientCompany.cs

import {z} from 'zod';

export const DefaultReimbursementQuestionsSchema = z.object({
  addDefaultBookResourceReimbursementQuestions: z.boolean(),
  addDefaultConferenceResourceReimbursementQuestions: z.boolean(),
  addDefaultOtherResourceReimbursementQuestions: z.boolean(),
  addDefaultProgramReimbursementQuestions: z.boolean(),
  addDefaultSubscriptionResourceReimbursementQuestions: z.boolean(),
});
