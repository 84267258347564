// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/ReimbursementTargetVM.cs

import {z} from 'zod';

export const ReimbursementTargetProgramVMSchema = z.object({
  linked: z.boolean(),
  programTitle: z.string(),
  userPlanItemId: z.number(),
  userPlanProgramId: z.number(),
});
