/* Module Barrel: A11y Utils - consumable export for our a11y utilities.

This barrel contains the explicit utilities that are able to be used for a11y. Meanwhile, each imported module maintains flexibility in having its own exported members required for things like unit tests or other internal concerns. The barrel helps clarify what we're using by guarding the inner details and abstracting the parts that are actually designed for utility. */

import dropdownKeyHelper from './a11y/dropdownKeyHelper';
import keyboardFocusHelper from './a11y/keyboardFocusHelper';

const selectableSelector =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), [type=radio]';

export const a11yDropdownKeyHelper = dropdownKeyHelper;
export const a11yKeyboardFocusHelper = keyboardFocusHelper;
export const a11ySelectableSelector = selectableSelector;
