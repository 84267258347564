import {getLDConfig} from '@utils/feature-flag-helpers';
import {LDProvider} from 'launchdarkly-react-client-sdk';
import {useAuth} from '@utils/oidc-auth-wrapper';

interface Props {
  children: JSX.Element | null;
}
export default function FeatureFlagInitV2({children}: Props) {
  const {
    user: {companyId, userCompanyId},
  } = useAuth();

  return (
    <LDProvider {...getLDConfig({companyId, userCompanyId})}>
      {children}
    </LDProvider>
  );
}
