// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramCurriculumActivityResult.cs

import {z} from 'zod';

export const CustomProgramCurriculumActivityResultSchema = z.object({
  email: z.string().nullable().optional(),
  employeeId: z.number(),
  employeeName: z.string(),
  id: z.number(),
  order: z.number(),
  progressPercentage: z.number(),
  title: z.string(),
  userId: z.string(),
});
