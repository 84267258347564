// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramAgendaVM.cs

import {z} from 'zod';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';
import {ContentTypeSchema} from './ContentTypeSchema.schema';
import {LinkVMSchema} from './LinkVMSchema.schema';

export const CustomProgramAgendaVMSchema = z.object({
  addedToExternalCalendar: z.boolean(),
  attachments: z.array(AttachmentVMSchema),
  completed: z.boolean(),
  contentType: ContentTypeSchema,
  description: z.string(),
  endDateUtc: z.string().nullable().optional(),
  eventLinkUrl: z.string(),
  id: z.number(),
  links: z.array(LinkVMSchema),
  startDateUtc: z.string().nullable().optional(),
  title: z.string(),
});
