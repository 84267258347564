/** Language-related helpers to manage the user's localization settings and map strings to their localization keys.
 *
 * In general, we should avoid building up localization keys so that we can keep them statically discoverable. However, there are certain necessary scenarios when dynamic build-up is required. When that build-up is required, we should use (or add to) this utility so we can better track and manage these special scenarios. */

import {i18n, k} from '@i18n/translate';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {
  LANGUAGE_FALLBACK_LOCALE,
  LANGUAGE_LOCAL_STORAGE_KEY,
} from './constants';
import {LanguagePreference, LearningResourceType} from '@generated/enums';
import {isProductionAny} from '@utils/environment-helpers';

// AntD l10n provider + locales (we can also supply our own)
import {Locale as AntDLocale} from 'antd/lib/locale-provider';
import deDE from 'antd/lib/locale-provider/de_DE';
import esES from 'antd/lib/locale-provider/es_ES';
import enGB from 'antd/lib/locale-provider/en_GB';
import enUS from 'antd/lib/locale-provider/en_US';
import frCA from 'antd/lib/locale-provider/fr_CA';
import frFR from 'antd/lib/locale-provider/fr_FR';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import daDK from 'antd/lib/locale-provider/da_DK';
import itIT from 'antd/lib/locale-provider/it_IT';
import koKR from 'antd/lib/locale-provider/ko_KR';
import nlNL from 'antd/lib/locale-provider/nl_NL';
import plPL from 'antd/lib/locale-provider/pl_PL';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import svSE from 'antd/lib/locale-provider/sv_SE';
import csCz from 'antd/lib/locale-provider/cs_CZ';
import huHu from 'antd/lib/locale-provider/hu_HU';
import idId from 'antd/lib/locale-provider/id_ID';
import ptPt from 'antd/lib/locale-provider/pt_PT';
import roRo from 'antd/lib/locale-provider/ro_RO';
import thTh from 'antd/lib/locale-provider/th_TH';
import trTr from 'antd/lib/locale-provider/tr_TR';
import viVn from 'antd/lib/locale-provider/vi_VN';
import zhTw from 'antd/lib/locale-provider/zh_TW';
import elGr from 'antd/lib/locale-provider/el_GR';

import {UserSettingsVM} from '@generated/interfaces';
import queryClient from '@store/queryClient';
import {notify} from '@components/user/notifications';
import i18next from 'i18next';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';
import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export interface LanguageProperties {
  locale: string;
  label: string;
  enum: number;
  antD: AntDLocale;
  isPseudo?: boolean;
  flagName?: string;
}

export interface LanguageType {
  [key: string]: LanguageProperties;
}

/** Maps server LanguagePreference to locale ISO code and the language's native display name */
export const LANGUAGE: LanguageType = {
  EN_US: {
    locale: LANGUAGE_FALLBACK_LOCALE,
    label: 'English',
    enum: LanguagePreference.EN_US,
    antD: enUS,
  },
  EN_GB: {
    locale: 'en-GB',
    label: 'English (United Kingdom)',
    enum: LanguagePreference.EN_GB,
    antD: enGB,
  },
  /* Pseudo-locale for rigi.io integration and browser extensions: This locale should show up only in non-production environments (behind environment check + flag). Its enum is not associated with the server's `LanguagePreference` enum, and it is only persisted at the localStorage level. */
  EN_JM: {
    locale: 'en-JM', // effectively en-US, but used as a pseudo-locale
    label: 'English (Jamaica)',
    enum: -1, // pseudo enum: not persisted in the db
    antD: enUS,
    isPseudo: true, // used only for non-prod environment testing
    flagName: 'PseudoLocale',
  },
  DE_DE: {
    locale: 'de-DE',
    label: 'Deutsch',
    antD: deDE,
    enum: LanguagePreference.DE_DE,
  },
  ES_419: {
    locale: 'es-419',
    label: 'Español (Latinoamérica)',
    antD: esES,
    enum: LanguagePreference.ES_419,
  },
  EL_GR: {
    locale: 'el-GR',
    label: 'ελληνικά',
    antD: elGr,
    enum: LanguagePreference.EL_GR,
    flagName: 'Greek_Greece',
  },
  FR_FR: {
    locale: 'fr-FR',
    label: 'français',
    antD: frFR,
    enum: LanguagePreference.FR_FR,
  },
  FR_CA: {
    locale: 'fr-CA',
    label: 'français (Canada)',
    antD: frCA,
    enum: LanguagePreference.FR_CA,
  },
  JA_JP: {
    locale: 'ja-JP',
    label: '日本語',
    antD: jaJP,
    enum: LanguagePreference.JA_JP,
  },
  PT_BR: {
    locale: 'pt-BR',
    label: 'português (Brasil)',
    antD: ptBR,
    enum: LanguagePreference.PT_BR,
  },
  ZH_CN: {
    locale: 'zh-CN',
    label: '中文(简体)',
    antD: zhCN,
    enum: LanguagePreference.ZH_CN,
  },
  DA_DK: {
    locale: 'da-DK',
    label: 'dansk (Danmark)',
    antD: daDK,
    enum: LanguagePreference.DA_DK,
    flagName: 'Danish',
  },
  ES_ES: {
    locale: 'es-ES',
    label: 'español (España)',
    antD: esES,
    enum: LanguagePreference.ES_ES,
    flagName: 'SpainSpanish',
  },
  IT_IT: {
    locale: 'it-IT',
    label: 'italiano',
    antD: itIT,
    enum: LanguagePreference.IT_IT,
    flagName: 'Italian',
  },
  KO_KR: {
    locale: 'ko-KR',
    label: '한국어',
    antD: koKR,
    enum: LanguagePreference.KO_KR,
    flagName: 'Korean',
  },
  NL_NL: {
    locale: 'nl-NL',
    label: 'Nederlands',
    antD: nlNL,
    enum: LanguagePreference.NL_NL,
    flagName: 'Dutch',
  },
  PL_PL: {
    locale: 'pl-PL',
    label: 'polski',
    antD: plPL,
    enum: LanguagePreference.PL_PL,
    flagName: 'Polish',
  },
  RU_RU: {
    locale: 'ru-RU',
    label: 'русский',
    antD: ruRU,
    enum: LanguagePreference.RU_RU,
    flagName: 'Russian',
  },
  SV_SE: {
    locale: 'sv-SE',
    label: 'svenska',
    antD: svSE,
    enum: LanguagePreference.SV_SE,
    flagName: 'Swedish',
  },
  CS_CZ: {
    locale: 'cs-CZ',
    label: 'čeština',
    antD: csCz,
    enum: LanguagePreference.CS_CZ,
    flagName: 'Czech_Czech_Republic',
  },
  HU_HU: {
    locale: 'hu-HU',
    label: 'magyar',
    antD: huHu,
    enum: LanguagePreference.HU_HU,
    flagName: 'Hungarian',
  },
  ID_ID: {
    locale: 'id-ID',
    label: 'Indonesia',
    antD: idId,
    enum: LanguagePreference.ID_ID,
    flagName: 'Indonesian',
  },
  PT_PT: {
    locale: 'pt-PT',
    label: 'português',
    antD: ptPt,
    enum: LanguagePreference.PT_PT,
    flagName: 'Portuguese_Portugal',
  },
  RO_RO: {
    locale: 'ro-RO',
    label: 'română',
    antD: roRo,
    enum: LanguagePreference.RO_RO,
    flagName: 'Romanian_Romania',
  },
  TH_TH: {
    locale: 'th-TH',
    label: 'ไทย',
    antD: thTh,
    enum: LanguagePreference.TH_TH,
    flagName: 'Thai',
  },
  TR_TR: {
    locale: 'tr-TR',
    label: 'Türkçe',
    antD: trTr,
    enum: LanguagePreference.TR_TR,
    flagName: 'Turkish',
  },
  VI_VN: {
    locale: 'vi-VN',
    label: 'Tiếng Việt',
    antD: viVn,
    enum: LanguagePreference.VI_VN,
    flagName: 'Vietnamese',
  },
  ZH_TW: {
    locale: 'zh-TW',
    label: '中文 (台湾)',
    antD: zhTw,
    enum: LanguagePreference.ZH_TW,
    flagName: 'Chinese_Taiwan',
  },
};

/**
 * Normalize the string format of the locale string
 * e.g. 'EN_US' => 'en-US'
 */
export const normalizeLocaleFormat = (lang: string) => {
  if (!lang) return;
  lang = lang.replace(/_/g, '-');
  const prefix = lang.split('-')[0]?.toLowerCase();
  const suffix = lang.split('-')[1]?.toUpperCase();
  return suffix ? `${prefix}-${suffix}` : prefix;
};

/**
 * Gets a user's locale (language)
 */
export const getUsersLocale = () =>
  localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) || LANGUAGE_FALLBACK_LOCALE;

/**
 * Sets a user's language in localStorage
 */
export const changeUsersLanguage = (language: string) => {
  const locale = normalizeLocaleFormat(language);
  locale && localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, locale);
};

/**
 * Transforms a string to its constant-case equivalent
 */
export const toConstantCase = (s: string) =>
  s.toUpperCase().replace(/[^A-Z0-9]/gi, '_');

/**
 * A helper to look-up a key by its prefix and suffix
 * @param genericName
 * @returns translated genericName
 */
export const locateKey = (prefix: string, suffix: string) => {
  return k[`${toConstantCase(prefix)}${toConstantCase(suffix)}`];
};

/**
 * A helper to locate and localize a key if it's found, otherwise return untranslated
 * @param genericName
 * @returns translated genericName
 */
export const localizeIfLocated = (text: string, prefix = '') => {
  if (!text) return '';
  const key = locateKey(prefix, toConstantCase(text));
  return key ? i18n.t(key) : text;
};

/**
 * Maps a generic server-generated word/phrase into its localization key. Assumes a prefix of "GENERIC__" and a key that matches constant-cased genericName. No match returns an untranslated `genericName`.
 * @param genericName
 * @returns translated genericName or original text, if a match not found
 */
export const localizeGeneric = (genericName: string) =>
  localizeIfLocated(genericName, DYNAMIC_KEYS.PREFIXES.GENERIC);

/**
 * Maps a skill-based server-generated word/phrase into its localization key. Assumes a prefix of "SKILL__" and a key that matches constant-cased skillName. No match returns an untranslated `genericName`.
 * @param skillName
 * @returns translated skillName or original text, if a match not found
 */
export const localizeSkill = (skillName: string) =>
  localizeIfLocated(skillName, DYNAMIC_KEYS.PREFIXES.SKILL);

/**
 * Maps a server-generated status into its localization key.
 * Assumes a prefix of "STATUS__" and a key that matches constant-cased stateName. No match returns an untranslated `stateName`.
 * @param status
 * @returns translated status or original text, if a match not found
 */
export const localizeStatus = (status: string) =>
  localizeIfLocated(status, DYNAMIC_KEYS.PREFIXES.STATUS);

/** Maps a server-generated duration string to its localization key, with plurals supported: i.e. k.DATE__DAY, k.DATE__YEAR__PLURAL, k.DATE__WEEK
 * @param serverDuration
 * @returns translated serverDuration
 */
export const localizeDuration = (serverDuration: string): string => {
  if (!serverDuration) {
    return '';
  }

  const duration = parseInt(serverDuration.split(' ')[0]);
  const serverDurationUnit = serverDuration.split(' ')[1];
  const durationUnit = serverDurationUnit.substring(
    0,
    duration <= 1 ? serverDurationUnit.length : serverDurationUnit.length - 1
  );

  return localizeDurationFromParts(duration, durationUnit);
};

/** Localizes a duration/unit set, with plurals supported: i.e. k.DATE__DAY, k.DATE__YEAR__PLURAL, k.DATE__WEEK
 * @param serverDuration
 * @returns translated duration
 */
export const localizeDurationFromParts = (
  duration: number,
  durationUnit: string
): string => {
  const maybePluralized = duration !== 1 ? DYNAMIC_KEYS.SUFFIXES.PLURAL : '';
  const key =
    DYNAMIC_KEYS.PREFIXES.DATE + durationUnit.toUpperCase() + maybePluralized;
  return `${duration} ${i18n.t(k[key])}`; // e.g. 1 Day, 2 Weeks, etc.
};

/** Returns a translated match if the value is found in the default locale set
 * @param untranslated
 * @returns translated string if a match is found, otherwise it returns untranslated
 */
export const applyMatchFromDefaultLocale = (untranslated: string) => {
  // fallback provided if match is found in the default language
  const {translation} = i18n.getDataByLanguage(LANGUAGE_FALLBACK_LOCALE);
  for (const [key, value] of Object.entries(translation)) {
    if (value === untranslated) return i18n.t(key);
  }
  return untranslated;
};

export const localizeDynamicQuestion = ({
  item,
  resourceType,
}: {
  item: ResourceOrPlanItemPreApprovalQuestion;
  resourceType?: LearningResourceType;
}) => {
  const dependentQuestions = [
    'APPROVAL_FORM__QUESTION__WHAT_REQUESTING',
    'APPROVAL_FORM__QUESTION__LEARNINGRESOURCE__WHAT_REQUESTING',
  ];
  const isDependentQuestion = dependentQuestions.filter(
    (q) => q.indexOf(item.localizationId) > -1
  );

  const resourceTypeString = LearningResourceType[resourceType];
  const resourceTypeKey =
    isDependentQuestion &&
    `APPROVAL_FORM__QUESTION__WHICH_${resourceTypeString?.toUpperCase()}_REQUESTING`;
  return isDependentQuestion && k[resourceTypeKey];
};

/** Localizes an approval answer
 * @param answer id
 * @returns translated answer if it's one we have available
 */
export const localizeApprovalAnswer = (
  answerId: string,
  responseValue: string
) => {
  const normalizedId = toConstantCase(answerId);

  // Default localizations
  const defaultAnswers = {
    EXPLORE_CAREER_OPTIONS: k.APPROVAL_FORM__ANSWER__CAREER_OPTIONS,
    IMPROVE_IN_MY_CURRENT_ROLE: k.APPROVAL_FORM__ANSWER__IMPROVE_ROLE,
    LEARN_ANOTHER_LANGUAGE: k.APPROVAL_FORM__ANSWER__LANGUAGE,
    ASPIRE_OR_PREPARE_FOR_A_NEW_ROLE: k.APPROVAL_FORM__ANSWER__NEW_ROLE,
    TO_ACHIEVE_A_PERSONAL_GOAL: k.APPROVAL_FORM__ANSWER__PERSONAL_GOAL,
    PURSUE_PROFESSIONAL_ADVANCEMENT:
      k.APPROVAL_FORM__ANSWER__PROFESSIONAL_ADVANCEMENT,
    EXPLORE_A_PROFESSIONAL_INTEREST:
      k.APPROVAL_FORM__ANSWER__PROFESSIONAL_INTEREST,
  };

  // Return the localization, otherwise show the original responseValue
  return defaultAnswers[normalizedId]
    ? i18n.t(defaultAnswers[normalizedId])
    : applyMatchFromDefaultLocale(responseValue);
};

/**
 * Only use on non-user-generated text. If this is violated, it exposes XSS.
 */
export const dangerouslyNoEscape = {interpolation: {escapeValue: false}};

/**
 *  The parameter title contains the resource type in english, this method localizes that prop.
 */
export const localizeResourceType = (title) => {
  return title === 'Book'
    ? `${i18n.t(k.RESOURCE__BOOK)}`
    : title === 'Conference'
      ? `${i18n.t(k.RESOURCE__CONFERENCE)}`
      : title === 'Subscription'
        ? `${i18n.t(k.RESOURCE__SUBSCRIPTION)}`
        : `${i18n.t(k.GENERIC__OTHER)}`;
};

export const getResourceTypeName = (resourceType: LearningResourceType) => {
  let resourceTypeNameKey: string;

  switch (resourceType) {
    case LearningResourceType.Book:
      resourceTypeNameKey = k.RESOURCE__BOOK;
      break;
    case LearningResourceType.Conference:
      resourceTypeNameKey = k.RESOURCE__CONFERENCE;
      break;
    case LearningResourceType.Subscription:
      resourceTypeNameKey = k.RESOURCE__SUBSCRIPTION;
      break;
    default:
      resourceTypeNameKey = k.GENERIC__OTHER;
  }

  return i18n.t(resourceTypeNameKey);
};

/**
 * BEN-3993: Use the temporary localization if available
 */
export const getPlbL10nIfAvailable = ({
  nonLocalized,
  key,
  locale,
  flagIsOn,
}: {
  nonLocalized: string;
  key: TempLocalizationKeys;
  locale: string;
  flagIsOn: boolean;
}) => {
  const localization = tempLocalizations[locale]?.[key];
  const localize = flagIsOn && !!localization;
  return localize ? localization : nonLocalized;
};

/**
 * BEN-3993: Interface for each locale's temporary localization
 */
interface TempLocalization {
  plbTitle: string;
  plbApprovalGuidelines: string;
  plbDescription: string;
  plbDetails: string;
}

/**
 * BEN-3993: Type for each locale's temporary localization
 */
export type TempLocalizationKeys = keyof TempLocalization;

/**
 * BEN-3993: Translations for each locale's temporary localization
 */
export const tempLocalizations: {[key: string]: TempLocalization} = {
  'fr-CA': {
    plbTitle: `BMO au Canada et BMO Nesbitt Burns – Programme de remboursement des frais de scolarité`,

    plbDescription: `Le remboursement des frais de scolarité est offert pour soutenir vos objectifs d’apprentissage et votre développement professionnel. Le programme contribue à une croissance individuelle qui s’harmonise aux priorités d’affaires de BMO. Le respect des lignes directrices du programme relève de vous et de votre responsable de l’approbation.<br /><br />Après avoir eu une discussion sur votre développement avec votre gestionnaire, suivez les étapes ci-dessous :<br /><br />1. Cliquez sur <strong>Ajouter programme</strong> dans le coin supérieur droit.<br />2. Soumettez une demande de préapprobation contenant des renseignements sur le cours ou le programme.<ul><li>Assurez-vous d’avoir l’approbation écrite de votre dirigeant local si la demande de remboursement est supérieure à 5 000 $ pour les cours de premier cycle ou à 80 % du montant (maximum de 8 000 $) pour les cours de deuxième cycle ou toute autre exemption aux lignes directrices.</li></ul>3. Après avoir terminé le cours ou le programme, soumettez une approbation de remboursement et téléversez votre reçu ou facture et votre relevé des cours réussis.`,

    plbDetails: `Le perfectionnement est un parcours que nous vivons tous à mesure que nous développons et approfondissons nos compétences. Le développement des compétences est important, que vous choisissiez :<br /><br /><ul><li>de <strong>rester au fait</strong> de l’évolution de votre profession;</li><li>de <strong>progresser dans votre carrière</strong> pour devenir plus compétent;</li><li>de <strong>commencer une nouvelle carrière</strong> à mesure que de nouveaux champs d’intérêt et types de travail émergent.</li></ul><br />À BMO, nous avons pris l’engagement d’investir dans nos employés. La formation continue et le développement professionnel sont au cœur de nos valeurs fondamentales. La stratégie d’apprentissage de la Banque concorde avec les priorités stratégiques de BMO. En favorisant la croissance personnelle par l’apprentissage, nous contribuons à renforcer la fierté des employés, accroissons la mobilisation et offrons des possibilités de carrière et de réussite, autant de facteurs qui contribuent à rendre la Banque plus forte et plus performante.<br /><br />Notre Programme de remboursement des frais de scolarité soutient le développement et la planification continus entre vous et votre gestionnaire.<br /><br />Pour être admissible au programme, il est important de discuter de vos objectifs avec votre gestionnaire. Le programme est axé sur le développement professionnel. Par conséquent, toute décision relative au remboursement des frais de scolarité est fondée sur ce qui suit :<br /><br /><ul>
    <li>vos fonctions actuelles;</li>
    <li>vos occasions de perfectionnement potentielles;</li>
    <li>les priorités stratégiques de votre groupe d’exploitation.</li></ul><br />L’approbation du remboursement des frais de scolarité est à la discrétion de votre gestionnaire ou de votre leader local.<br /><br /><ul><li>Vous serez tenu de remettre tout montant qui vous est remboursé si vous décidez de quitter l’organisation sans avoir satisfait à votre exigence de service contractuelle (deux années de service après la réception du remboursement).</li></ul>`,

    plbApprovalGuidelines: `
      <strong>Renseignements sur la politique de remboursement du programme</strong><br />&bull;&nbsp;Cours de premier cycle / programmes spécialisés – 100 % des frais de scolarité, jusqu’à 5 000 $ CA par année civile.<br />&bull;&nbsp;Cours de deuxième cycle / programmes de MBA pour dirigeants – 80 % des frais de scolarité, jusqu’à 8 000 $ CA par année civile.<br /><br /><strong>Admissibilité et approbations</strong><br />Satisfaire à tous les critères suivants est requis:<br />&bull;&nbsp;Employé à temps plein ou à temps partiel et avoir le statut d’employé permanent (salaireétabli au prorata et travaillant plus de 7,5 heures par semaine). Remarque : Les employésoccasionnels et contractuels ne sont pas admissibles.<br />&bull;&nbsp;Employé de la Banque depuis au moins 90 jours.<br /><br /><strong>Exceptions</strong><br />Toutes les exceptions nécessitent l’approbation écrite du leader local (par courriel ounumérisation de la formule).<br />Pour en savoir plus, veuillez consulter les directives du Programme de remboursement des fraisde scolarité de BMO au Canada et de BMO Nesbitt Burns.`,
  },
};

export const getLanguageByEnum = (
  languageEnum: LanguagePreference
): LanguageProperties => {
  for (const x in LANGUAGE) {
    if (LANGUAGE[x].enum === languageEnum) {
      return LANGUAGE[x];
    }
  }
  return null;
};

export const handleOnLanguageUpdated = async (
  userData: UserSettingsVM,
  notifyOnSuccess = false
) => {
  const languageCode = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
  const i18nextLocale = LANGUAGE[languageCode.toUpperCase().replace('-', '_')];
  const changedLanguage = userData?.languagePreference !== i18nextLocale.enum;

  const onUpdated = async () => {
    await queryClient.invalidateQueries();
    notifyOnSuccess && notify.settingsUpdateSuccess();
  };

  if (changedLanguage) {
    const changedTo = getLanguageByEnum(userData?.languagePreference);
    i18next.changeLanguage(changedTo.locale, async (err) => {
      if (err) return notify.settingsUpdateError();
      await onUpdated();
    });
  } else {
    await onUpdated();
  }
};

/** Localizes an approval question
 * @param item
 * @returns translated question
 */
export const localizeText = ({
  translationKey,
  translationValue,
}: {
  translationKey: string;
  translationValue: string;
}) => {
  if (!translationKey) return translationValue;

  const isHelperText =
    translationKey.indexOf(DYNAMIC_KEYS.SUFFIXES.HELPER) > -1;
  const modifiedKey = isHelperText
    ? translationKey?.replace(DYNAMIC_KEYS.SUFFIXES.HELPER, '')
    : translationKey;
  const stringIteration =
    modifiedKey.substring(0, modifiedKey?.lastIndexOf('_')) +
    (isHelperText ? DYNAMIC_KEYS.SUFFIXES.HELPER : '');

  // When it's exactly a default or company-specific translated key
  const translationKeyMatch = translationKey && k[translationKey];
  if (translationKeyMatch) {
    return i18n.t(k[translationKey]);
  }

  // When it has a company-specific prefix but it isn't found, use the default
  const companySegment = translationKey
    ?.substring(0, translationKey?.indexOf('_'))
    ?.match(/C[0-9]+/); // C followed by any number of numerals
  const companySegmentWithUnderscore = companySegment?.[0] + '_';
  const companySegmentRemoved = translationKey?.replace(
    companySegmentWithUnderscore,
    ''
  );
  if (companySegment && k[companySegmentRemoved]) {
    return i18n.t(k[companySegmentRemoved]);
  }

  // When it has an iterator string (suffix) but it isn't found, use the default
  const numberedKeyMatchesDefault = stringIteration && k[stringIteration];
  if (numberedKeyMatchesDefault) {
    return i18n.t(k[stringIteration]);
  }

  // Last-ditch: try to find without the supplied prefix or suffix
  const prefixAndSuffixRemoved = stringIteration?.replace(
    companySegmentWithUnderscore,
    ''
  );
  if (companySegment && k[prefixAndSuffixRemoved]) {
    return i18n.t(k[prefixAndSuffixRemoved]);
  }

  // Still no match: try a match from English, otherwise display untranslated
  return applyMatchFromDefaultLocale(translationValue);
};

/**
 * Some languages are disabled by conditions, or need to defer to the value of a feature flag
 */
export const isSkippedLanguage = ({
  lang,
  isFeatureFlagOn,
}: {
  lang: string;
  isFeatureFlagOn: TIsFeatureFlagOn;
}) => {
  const languagesWithFlags = [
    LANGUAGE.DA_DK,
    LANGUAGE.ES_ES,
    LANGUAGE.IT_IT,
    LANGUAGE.KO_KR,
    LANGUAGE.NL_NL,
    LANGUAGE.PL_PL,
    LANGUAGE.RU_RU,
    LANGUAGE.SV_SE,
  ];

  const withFlagConditions = languagesWithFlags.map(
    (language: LanguageProperties) => {
      return {
        language,
        skip:
          isFeatureFlagOn[language?.flagName] === false && // <- strictly false
          LANGUAGE[lang].locale === language?.locale,
      };
    }
  );

  const withOtherConditions = [
    {
      language: LANGUAGE.EN_JM,
      skip:
        LANGUAGE[lang]?.isPseudo &&
        (!isFeatureFlagOn.PseudoLocale || isProductionAny),
    },
  ];

  return [...withFlagConditions, ...withOtherConditions].some(
    (lang) => !!lang.skip
  );
};
