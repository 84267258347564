import {ReactQueryRequest, addQueryParams} from './../utils';
import {RecommendationLevel} from '@generated/enums';
import {BASE_PATH} from '.';

export const getSearchInitiativesProgramsRq = (
  keywords?: string,
  initiativeId?: string | number
): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/search-initiative-programs`;
  const queryKey = [baseUrl, keywords || '', String(initiativeId) || ''];
  const queryParams = [];
  keywords && queryParams.push(`keywords=${keywords}`);
  initiativeId && queryParams.push(`initiativeId=${initiativeId}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getProgramReviewsRq = (programId: number): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-reviews/${programId}`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getProgramTypesRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-types`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getProgramsProgramTypesRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-program-types`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getProgramDurationsRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-durations`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getProvidersRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-providers`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getAvailableProvidersRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-available-providers`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getTopicsRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-topics`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getInitiativePurposeDescriptionsRq = (
  data: {id: number}[]
): ReactQueryRequest => {
  const programIds = data.map((value) => value.id).sort();
  const base = `${BASE_PATH}/get-initiative-purpose-descriptions`;
  const path = programIds.reduce(
    (previousValue: string, currentValue: number, idx: number) =>
      `${previousValue}${idx > 0 ? '&' : '?'}programIds=${currentValue}`,
    base
  );
  const queryKey = [base, programIds.join(',')];

  return {
    path,
    queryKey,
  };
};

export const getStartedProgramsRq = (): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-started`;
  const queryKey = [baseUrl];
  return {
    path: baseUrl,
    queryKey,
  };
};

export const getMaxPossibleProgramPriceRq = (): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-max-possible-price-for-filter`;
  const queryKey = [baseUrl];
  return {
    path: baseUrl,
    queryKey,
  };
};

export const getProgramInitiativesForItemRq = (
  programId: string | number
): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-program-initiatives`;
  const path = `${baseUrl}/${programId}`;
  const queryKey = [baseUrl, programId.toString()];

  return {
    path,
    queryKey,
  };
};

export const getProgramDetailsRq = (programId: number) => {
  const baseUrl = `${BASE_PATH}/get-details`;
  const path = `${baseUrl}/${programId}`;
  const queryKey = [baseUrl, programId?.toString()];

  return {
    path,
    queryKey,
  };
};

export const getProgramParticipantsRq = (programId: number) => {
  const baseUrl = `${BASE_PATH}/get-participants`;
  const path = `${baseUrl}/${programId}`;
  const queryKey = [baseUrl, programId.toString()];

  return {
    path,
    queryKey,
  };
};

export const getProgramRecommendationsRq = (
  programId: number,
  recommendationLevel: RecommendationLevel
) => {
  const baseUrl = `${BASE_PATH}/get-recommendations`;
  const path = `${baseUrl}/${programId}`;
  const queryKey = [
    baseUrl,
    programId.toString(),
    recommendationLevel.toString(),
  ];
  const queryParams = [`recommendationLevel=${recommendationLevel}`];

  return {
    path: addQueryParams(path, queryParams),
    queryKey,
  };
};

export const getAdminMarketplaceProgramsRq = (): ReactQueryRequest => {
  const path = ['program', 'get-programs'].join('/');
  return {
    path,
    queryKey: [path],
  };
};
