// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/User/UserSettingsVM.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LanguagePreferenceSchema} from './LanguagePreferenceSchema.schema';
import {NotificationSettingsSchema} from './NotificationSettingsSchema.schema';

export const UserSettingsVMSchema = z.object({
  currency: CurrencyCodeSchema,
  email: z.string(),
  familyName: z.string(),
  givenName: z.string(),
  jobTitle: z.string(),
  languagePreference: LanguagePreferenceSchema,
  notificationSettings: NotificationSettingsSchema,
});
