// DO NOT MODIFY THIS FILE DIRECTLY
// PLEASE RUN yarn generate:i18n-keys

export default {
  A11Y__CAROUSEL__TYPE__FORMAT: null, // {{type}} Carousel
  A11Y__COLLAPSED__FORMAT: null, // Collapsed
  A11Y__DEGREED_LOGO: null, // Degreed Logo
  A11Y__DRAWER__CLOSE: null, // Close Drawer
  A11Y__ENTER_A_SKILL: null, // Enter a skill
  A11Y__EXPANDED__FORMAT: null, // Expanded
  A11Y__GO_TO_ITEM__FORMAT: null, // Go to {{item}}
  A11Y__INFO_ABOUT__FORMAT: null, // Information about
  A11Y__ITEM__FORMAT: null, // Item
  A11Y__LINK__OPEN_ATTACHMENT__FORMAT: null, // Open supporting link for {{title}}
  A11Y__LOGO_OF__FORMAT: null, // Logo of {{company}}
  A11Y__MARK_AS_COMPLETE_ITEM__FORMAT: null, // Mark as Complete {{item}}
  A11Y__PAGE__FORMAT: null, // Page {{number}}
  A11Y__PAGINATION: null, // Pagination
  A11Y__PHOTO_OF_CATEGORY: null, // Photo of this category
  A11Y__PHOTO_OF_INCENTIVE: null, // Photo of this incentive
  A11Y__PREVIEW_ITEM__FORMAT: null, // Preview {{item}}
  A11Y__SEARCH_FOR_INCENTIVES: null, // Search For Incentives
  A11Y__SEARCH_FOR_PROGRAMS: null, // Search For Programs
  A11Y__SKIP: null, // Skip to content
  A11Y__SORTABLE_TABLE_COLUMN__FORMAT: null, // {{column}} is a sortable column
  A11Y__STEP__TITLE__FORMAT: null, // Step
  A11Y__TYPE_TO_SELECT: null, // Type to select
  A11Y__VIEW_ITEM__FORMAT: null, // View {{item}}
  A11Y__WRITE_HERE: null, // Write Here
  ACADEMY: null, // Academy
  ACADEMY_STEP_CHECKBOX_LABEL_ALLOW_SKIP_STEP: null, // Allow participants to mark step as \skipped\
  ACADEMY__ADD__DESCRIPTION: null, // Create an exclusive experience for participants to learn new skills over a long period of time with the support they need.
  ACADEMY__ADD__TITLE: null, // Let’s create your personalized academy.
  ACADEMY__CHAT_APPLICATION_ADD_ERROR: null, // There was an error adding the link
  ACADEMY__CHAT_APPLICATION_ADD_LINK: null, // Add Link
  ACADEMY__CHAT_APPLICATION_ADD_SUCCESSFUL: null, // Link added successfully
  ACADEMY__CHAT_APPLICATION_COLLABORATE: null, // Collaborate in Chat
  ACADEMY__CHAT_APPLICATION_DESCRIPTION: null, // Add a link to a dedicated chat channel (i.e. Teams or Slack) to encourage academy participants to collaborate in.
  ACADEMY__CHAT_APPLICATION_MODAL_DESCRIPTION: null, // Collaborate with other people in this Academy in a dedicated channel setup by your company.
  ACADEMY__CHAT_APPLICATION_MODAL_OPEN_CHANNEL: null, // Open Channel
  ACADEMY__CHAT_APPLICATION_MODAL_TITLE: null, // Collaborate Together!
  ACADEMY__CHAT_APPLICATION_PLACEHOLDER_TEXT: null, // Paste Link to Channel
  ACADEMY__CHAT_APPLICATION_REMOVE_LINK: null, // Remove Link
  ACADEMY__CHAT_APPLICATION_TITLE: null, // Chat Application Link
  ACADEMY__CHAT_APPLICATION_UPDATE_LINK: null, // Update Link
  ACADEMY__CLONE: null, // Clone Academy
  ACADEMY__CLONE_OF_PREFIX: null, // [Clone of] 
  ACADEMY__COMPLETE_TO_ACCESS__FORMAT: null, // Complete <strong>{{requiredTitle}}</strong> to access.
  ACADEMY__CONTRIBUTOR__PLURAL: null, // Academy Contributors
  ACADEMY__DELETE: null, // Delete Academy
  ACADEMY__DUPLICATE__VERB: null, // Duplicate Academy
  ACADEMY__EDIT: null, // Edit Academy
  ACADEMY__GET_APPROVED_TO_ACCESS__FORMAT: null, // Get approved for <strong>{{requiredTitle}}</strong> to access.
  ACADEMY__GO_TO: null, // Go To Academy
  ACADEMY__IMAGE: null, // Academy Image
  ACADEMY__INVITATION_EMAIL__DESCRIPTION: null, // Send an invitation email to participants when they are added to an academy.
  ACADEMY__INVITATION_EMAIL__EDIT_PREVIEW: null, // Edit & Preview
  ACADEMY__INVITATION_EMAIL__PREVIEW: null, // Invitation Email Preview
  ACADEMY__INVITATION_EMAIL__SETUP: null, // Set up the notification preferences for this academy.
  ACADEMY__INVITATION_EMAIL__SUBJECT__FORMAT: null, // Subject
  ACADEMY__INVITATION_EMAIL__TITLE: null, // Invitation Email
  ACADEMY__JOIN: null, // Join Academy
  ACADEMY__NAME: null, // Academy Name
  ACADEMY__NEW: null, // New Academy
  ACADEMY__NO_PARTICIPANTS_HEADER: null, // There are no active participants in this academy
  ACADEMY__NO_PARTICIPANTS_SUBHEADER: null, // There are no active participants in this academy
  ACADEMY__OVERVIEW_PAGE: null, // Overview Page
  ACADEMY__PEOPLE_PAGE: null, // People Page
  ACADEMY__PLURAL: null, // Academies
  ACADEMY__PLURAL__MANAGE: null, // Manage Academies
  ACADEMY__PLURAL__VIEW: null, // View Academies
  ACADEMY__PREVIEW__VERB: null, // Preview Academy
  ACADEMY__PROJECT_PAGE: null, // Project Page
  ACADEMY__PUBLISH: null, // Publish Academy
  ACADEMY__PUBLISH_WARNING: null, // An Invitation email will be sent to participants once published. Are you sure you want to publish this academy?
  ACADEMY__PUBLISH_WARNING__FORMAT: null, // An Invitation email will be sent to <strong>{{count}}</strong> participants once published. Are you sure you want to publish this academy?
  ACADEMY__REMOVE: null, // Remove Academy
  ACADEMY__REPORT_PAGE: null, // Report Page
  ACADEMY__SETUP__DESCRIPTION: null, // Tell us a little bit about this academy
  ACADEMY__SETUP__PRIVACY_DESCRIPTION: null, // Control who can see and participate in this academy.
  ACADEMY__SETUP__TITLE: null, // Setup
  ACADEMY__SHARE: null, // Share Academy
  ACADEMY__START: null, // Academy Start
  ACADEMY__TABLE__DELETE_CONFIRM: null, // Are you sure you want to delete this academy? It will no longer be accessible to any participants.
  ACADEMY__TABLE__EMPTY__DESCRIPTION: null, // You can view and manage academies here.
  ACADEMY__TABLE__EMPTY__TITLE: null, // You have not created any academies.
  ACADEMY__VALIDATION__ACADEMY_NAME: null, // Please enter the academy name.
  ACADEMY__VALIDATION__SHORT_DESCRIPTION: null, // Short Academy Description
  ACADEMY__VALIDATION__SHORT_TITLE: null, // Please enter a short academy description.
  ACADEMY__VIEW: null, // View Academy
  ACADEMY__WELCOME_TO: null, // Welcome to the academy.
  ACADEMY__WELCOME__FORMAT: null, // Welcome to the academy {{name}}!
  ACADEMY__WHAT_TYPE: null, // What type of academy is this?
  ACCESS__ALL_EMPLOYEES__ACADEMY: null, // All employees can see this academy in the marketplace and participate
  ACCESS__ALL_EMPLOYEES__PROGRAM: null, // All employees can see this program in the marketplace and participate
  ACCESS__DENIED__DESCRIPTION: null, // Contact your Admin to request access.
  ACCESS__DENIED__PROGRAM__TITLE: null, // You don't have permission to view this program
  ACCESS__DRAFT: null, // Draft
  ACCESS__PERMITTED_EMPLOYEES__ACADEMY: null, // Only employees with permission will be able to see this academy and it will be automatically added to their plan
  ACCESS__PERMITTED_EMPLOYEES__PROGRAM: null, // Only employees with permission will be able to see this program and it will be automatically added to their plan
  ACCESS__PLURAL: null, // Access
  ACCESS__PRIVATE: null, // Private
  ACCESS__PUBLIC: null, // Public
  ACCESS__PUBLISHED: null, // Published
  ACCESS__RESTRICTED: null, // Restricted
  ACCOUNT: null, // Account
  ACCOUNT__ADD: null, // Add Account
  ACCOUNT__CHANGE_PASSWORD: null, // Change Password
  ACCOUNT__LOGIN: null, // Login
  ACCOUNT__LOGOUT: null, // Logout
  ACCOUNT__REMOVE: null, // Remove Account
  ACTION__PREVIEW_EMAIL: null, // Preview Email
  ADD_URL_LINK: null, // Add a URL link
  ADMIN: null, // Admin
  ADMIN_ACTIVITY: null, // Admin Activity
  ADMIN_ACTIVITY__DETAIL_BALANCE__FORMAT: null, // updated the <strong>card balance</strong> from <strong>{{fromAmount}}</strong> to <strong>{{toAmount}}</strong>.
  ADMIN_ACTIVITY__DETAIL_LEARNING_BUDGET__FORMAT: null, // updated the <strong>learning budget</strong> from <strong>{{fromAmount}}</strong> to <strong>{{toAmount}}</strong>.
  ADMIN_ACTIVITY__DETAIL_LIMIT__FORMAT: null, // updated the <strong>learning budget limit</strong> from <strong>{{fromAmount}}</strong> to <strong>{{toAmount}}</strong>.
  ADMIN_ACTIVITY__EMPTY__DESCRIPTION: null, // Any activity done by admins will show up here.
  ADMIN_ACTIVITY__EMPTY__TITLE: null, // There has not been any admin activity.
  ADMIN_ACTIVITY__NOTE: null, // added a note.
  ADMIN__COMPANY__ADD__DESCRIPTION: null, // This is a simplified way to create a new form. Please use the database to customize company settings
  ADMIN__SETTING__PLURAL: null, // Admin Settings
  ADMIN__VIEW: null, // ADMIN VIEW
  ADMIN__VIEW__SHOW: null, // Show Admin View
  AGENDA: null, // Agenda
  AGENDA__EMPTY__DESCRIPTION: null, // Any upcoming events or due dates for this program will appear here.
  AGENDA__EMPTY__TITLE: null, // You don't have any upcoming events or due dates.
  AGENDA__ITEM: null, // Agenda item
  AGENDA__NO_UPCOMING_DESCRIPTION: null, // Any upcoming events or due dates for this program will appear here.
  AGENDA__NO_UPCOMING_TITLE: null, // You don't have any upcoming events or due dates.
  AGENDA__PAST_DESCRIPTION: null, // Any past events or due dates for this program will appear here.
  AGENDA__PAST_TITLE: null, // You don't have any past events or due dates.
  APPLICATION: null, // Application
  APPLICATION__CRITERIA: null, // Application Criteria
  APPLICATION__STATUS: null, // Application Status
  APPROVAL: null, // Approval
  APPROVAL_FORM__ANSWER__CAREER_OPTIONS: null, // Explore career options
  APPROVAL_FORM__ANSWER__IMPROVE_ROLE: null, // Improve in my current role
  APPROVAL_FORM__ANSWER__LANGUAGE: null, // Learn another language
  APPROVAL_FORM__ANSWER__NEW_ROLE: null, // Aspire or prepare for a new role
  APPROVAL_FORM__ANSWER__PERSONAL_GOAL: null, // To achieve a personal goal
  APPROVAL_FORM__ANSWER__PROFESSIONAL_ADVANCEMENT: null, // Pursue professional advancement
  APPROVAL_FORM__ANSWER__PROFESSIONAL_INTEREST: null, // Explore a professional interest
  APPROVAL_FORM__CUSTOM__BMO__CPRIME: null, // You are strongly encouraged to complete the following courses before registering for this Cprime workshop
  APPROVAL_FORM__QUESTION__AMOUNT: null, // Amount
  APPROVAL_FORM__QUESTION__COMMENTS: null, // Comments
  APPROVAL_FORM__QUESTION__CONFERENCE__RESOURCE__URL: null, // URL
  APPROVAL_FORM__QUESTION__DATE_PURCHASE_DATE: null, // Date / Purchase Date
  APPROVAL_FORM__QUESTION__HOW_MUCH_ADD: null, // How much would you like to add?
  APPROVAL_FORM__QUESTION__HOW_MUCH_REQUEST: null, // How much money would you like to request?
  APPROVAL_FORM__QUESTION__HOW_MUCH_REQUESTING: null, // How much money would you like to request?
  APPROVAL_FORM__QUESTION__HOW_SUPPORT_DEVELOPMENT: null, // How will this support your personal and professional development?
  APPROVAL_FORM__QUESTION__INCENTIVE__HOW_INCENTIVE_HELPFUL: null, // How will this incentive help you be successful in your program?
  APPROVAL_FORM__QUESTION__INCENTIVE__HOW_MUCH_REQUEST: null, // How much money would you like to request?
  APPROVAL_FORM__QUESTION__INCENTIVE__WHAT_VALUE_ADD: null, // How will this help add value to the company?
  APPROVAL_FORM__QUESTION__INCENTIVE__WHICH_PROGRAM_FOR_INCENTIVE: null, // What program do you plan on using this incentive towards?
  APPROVAL_FORM__QUESTION__LEARNINGRESOURCE__HOW_MUCH_ADD: null, // How much would you like to add?
  APPROVAL_FORM__QUESTION__LEARNINGRESOURCE__HOW_MUCH_REQUEST: null, // How much money would you like to request?
  APPROVAL_FORM__QUESTION__LEARNINGRESOURCE__WHAT_REQUESTING: null, // What are you requesting?
  APPROVAL_FORM__QUESTION__LEARNINGRESOURCE__WHY_REQUEST: null, // Why do you want to purchase this?
  APPROVAL_FORM__QUESTION__LICENSE__WHY_REQUESTING_LICENSE: null, // Why are you requesting this license?
  APPROVAL_FORM__QUESTION__OTHER__LEARNINGRESOURCE__WHY_REQUEST: null, // Why do you want to purchase this?
  APPROVAL_FORM__QUESTION__PERMITTED_CURRICULUM_BOOKS: null, // Employees are permitted to purchase books that are required for their course per the curriculum. No other books will be approved. What course is this book required for?
  APPROVAL_FORM__QUESTION__PROGRAM__HOW_MUCH_ADD: null, // How much would you like to add?
  APPROVAL_FORM__QUESTION__PROGRAM__HOW_MUCH_REQUEST: null, // How much money would you like to request?
  APPROVAL_FORM__QUESTION__PROGRAM__HOW_MUCH_REQUEST_638__HELPER: null, // Don't Forget Taxes
  APPROVAL_FORM__QUESTION__PROGRAM__HOW_MUCH_REQUEST__HELPER: null, // An additional 10% has been added to account for taxes and fees.
  APPROVAL_FORM__QUESTION__PROGRAM__TOP_3_SKILLS: null, // Top 3 skills to develop?
  APPROVAL_FORM__QUESTION__PROGRAM__WHAT_VALUE_ADD: null, // How will this help add value to the company?
  APPROVAL_FORM__QUESTION__PROGRAM__WHAT_VALUE_ADD_581__HELPER: null, // Be <em>specific</em> as possible
  APPROVAL_FORM__QUESTION__PROGRAM__WHY_MORE_FUNDS: null, // Why are you adding more funds?
  APPROVAL_FORM__QUESTION__PROGRAM__WHY_WANT_PROGRAM: null, // Why do you want to take this program?
  APPROVAL_FORM__QUESTION__PROVIDER__HELPER: null, // School e-Learning Company etc. - No abbreviations
  APPROVAL_FORM__QUESTION__PURCHASE_DATE: null, // Purchase Date
  APPROVAL_FORM__QUESTION__RESOURCE__URL: null, // URL
  APPROVAL_FORM__QUESTION__TIME__HOW_INCENTIVE_HELPFUL: null, // How will this incentive help you be successful in your program?
  APPROVAL_FORM__QUESTION__TIME__WHAT_VALUE_ADD: null, // How will this help add value to the company?
  APPROVAL_FORM__QUESTION__TIME__WHICH_PROGRAM_FOR_INCENTIVE: null, // What program do you plan on using this incentive towards?
  APPROVAL_FORM__QUESTION__UPLOAD_DOCUMENT: null, // Upload Document
  APPROVAL_FORM__QUESTION__UPLOAD_RECEIPT: null, // Upload Receipt
  APPROVAL_FORM__QUESTION__WHAT_PROGRAM_COST: null, // What is the estimated program cost?
  APPROVAL_FORM__QUESTION__WHAT_REIMBURSEMENT_FOR: null, // What are you submitting a reimbursement for?
  APPROVAL_FORM__QUESTION__WHAT_REQUESTING: null, // What are you requesting?
  APPROVAL_FORM__QUESTION__WHICH_BOOK_REQUESTING: null, // Which book are you requesting?
  APPROVAL_FORM__QUESTION__WHICH_CONFERENCE_REQUESTING: null, // Which conference are you requesting?
  APPROVAL_FORM__QUESTION__WHICH_SUBSCRIPTION_REQUESTING: null, // Which subscription are you requesting?
  APPROVAL_FORM__QUESTION__WHY_INTERESTED_PROGRAM: null, // Why are you interested in this program?
  APPROVAL__APPLICATION_FOR__FORMAT: null, // This application will be used to determine whether to approve you for use of this {{planItemTypeTitle}} incentive. Please highlight how this incentive will help you be successful in your program and how your program will add value to the company. This application may be viewed by your manager and by Human Resources.
  APPROVAL__APPROVED_AMOUNT: null, // Approved Amount
  APPROVAL__APPROVED_FINANCE__DESCRIPTION__COMPANY: null, // When your company applies for finance incentives you can see the approved incentives here.
  APPROVAL__APPROVED_FINANCE__DESCRIPTION__TEAM: null, // When your team applies for finance incentives you can see the approved incentives here.
  APPROVAL__APPROVED_FINANCE__TITLE: null, // You have not approved any finance incentives.
  APPROVAL__APPROVED_PROGRAM__DESCRIPTION__COMPANY: null, // When your company requests approval for programs you can see the approved requests here.
  APPROVAL__APPROVED_PROGRAM__TITLE__COMPANY: null, // You have not approved any programs from your company.
  APPROVAL__APPROVED_RESOURCE__DESCRIPTION__COMPANY: null, // When your company requests approval for resources you can see the approved requests here.
  APPROVAL__APPROVED_RESOURCE__DESCRIPTION__TEAM: null, // When your team requests approval for resources you can see the approved requests here.
  APPROVAL__APPROVED_RESOURCE__TITLE__COMPANY: null, // You have not approved any resources for your company.
  APPROVAL__APPROVED_TIME__DESCRIPTION__COMPANY: null, // When your company applies for time incentives you can see the approved incentives here.
  APPROVAL__APPROVED_TIME__DESCRIPTION__TEAM: null, // When your team applies for time incentives you can see the approved incentives here.
  APPROVAL__APPROVED_TIME__TITLE: null, // You have not approved any time incentives.
  APPROVAL__APPROVED__DESCRIPTION: null, // Once requests are approved they will be viewable here.
  APPROVAL__APPROVED__TITLE: null, // No requests have been approved.
  APPROVAL__COMPLETE__TITLE: null, // Please complete this short application.
  APPROVAL__EXCEEDS_BALANCE: null, // This program exceeds your remaining balance.
  APPROVAL__EXCEEDS_BALANCE_DISCLAIMER__FORMAT: null, // The program cost is <b>{{programCost}}</b> which exceeds your remaining balance of <b>{{balance}}</b>.
  APPROVAL__FEE_SUGGESTION: null, // An additional 10% has been added to account for taxes and fees.
  APPROVAL__FINANCE__PLURAL: null, // Finance Approvals
  APPROVAL__FOR: null, // You have been approved for
  APPROVAL__FROM_YOUR_TEAM__APPROVED: null, // You have not approved any resources from your team.
  APPROVAL__FROM_YOUR_TEAM__REJECTED: null, // You have not rejected any resources from your team.
  APPROVAL__GUIDELINES: null, // Approval Guidelines
  APPROVAL__HAVE_NOT__APPROVED_FINANCES: null, // You have not approved any finances
  APPROVAL__HAVE_NOT__APPROVED_INCENTIVES: null, // You have not approved any incentives
  APPROVAL__HAVE_NOT__REJECTED_FINANCES: null, // You have not rejected any finances
  APPROVAL__HAVE_NOT__REJECTED_INCENTIVES: null, // You have not rejected any incentives
  APPROVAL__HELP: null, // Help approvers understand when to accept or reject a request.
  APPROVAL__INCLUDES_TAX_FEES: null, // Include taxes and fees
  APPROVAL__LOCALIZED_DISCLAIMER__FORMAT: null, // The program cost is {{localizedAmount}} (the program website advertised {{originalAmount}}).
  APPROVAL__MAY_IMPACT_TOTAL: null, // This amount may impact the total amount provided to pay for the program. Include all taxes and fees.
  APPROVAL__NOTE_REQUIRED_ON_DECLINE: null, // Notes are required when declining a request
  APPROVAL__NO_MORE_VOUCHERS_SUBTITLE: null, // More vouchers will need to be provided before this request can be approved.
  APPROVAL__NO_MORE_VOUCHERS_TITLE: null, // No more vouchers available this program cannot be approved.
  APPROVAL__ONCE_APPROVED_PURCHASE: null, // Once approved purchase the program and submit a reimbursement request.
  APPROVAL__ONCE_APPROVED_VIRTUAL: null, // Once approved use your virtual card to pay on the program’s website.
  APPROVAL__PENDING_FINANCE__DESCRIPTION: null, // When finance incentives are pending manager approval you can see them here.
  APPROVAL__PENDING_FINANCE__TITLE: null, // There are no finance incentives pending manager approval.
  APPROVAL__PENDING_RESOURCE__DESCRIPTION: null, // When resource requests are pending manager approval you can see them here.
  APPROVAL__PENDING_RESOURCE__TITLE: null, // There are no resource requests pending manager approval.
  APPROVAL__PENDING_TIME__DESCRIPTION: null, // When time incentives are pending manager approval you can see them here.
  APPROVAL__PENDING_TIME__TITLE: null, // There are no time incentives pending manager approval.
  APPROVAL__PENDING__DESCRIPTION: null, // Once you receive a request it will appear here for review.
  APPROVAL__PENDING__TITLE: null, // No requests awaiting approval.
  APPROVAL__PLURAL: null, // Approvals
  APPROVAL__PLURAL__SEARCH: null, // Search Approvals
  APPROVAL__PRE: null, // Pre-Approval
  APPROVAL__PREVIOUS_NOTES: null, // Previous notes
  APPROVAL__PROGRAM_COST_IS: null, // Program cost
  APPROVAL__PROGRAM__PLURAL: null, // Program Approvals
  APPROVAL__REJECTED_PROGRAM__DESCRIPTION__COMPANY: null, // When your company requests approval for programs you can see the rejected requests here.
  APPROVAL__REJECTED_PROGRAM__TITLE__COMPANY: null, // You have not rejected any programs from your company.
  APPROVAL__REJECTED_RESOURCE__DESCRIPTION__COMPANY: null, // When your company requests approval for resources you can see the rejected requests here.
  APPROVAL__REJECTED_RESOURCE__DESCRIPTION__TEAM: null, // When your team requests approval for resources you can see the rejected requests here.
  APPROVAL__REJECTED_RESOURCE__TITLE__COMPANY: null, // You have not rejected any resources for your company.
  APPROVAL__REJECTED_TIME__DESCRIPTION__COMPANY: null, // When your company applies for time incentives you can see the rejected incentives here.
  APPROVAL__REJECTED_TIME__DESCRIPTION__TEAM: null, // When your team applies for time incentives you can see the rejected incentives here.
  APPROVAL__REJECTED_TIME__TITLE: null, // You have not rejected any time incentives.
  APPROVAL__REJECTED__DESCRIPTION: null, // Once requests are declined they will be viewable here.
  APPROVAL__REJECTED__TITLE: null, // No requests have been declined.
  APPROVAL__REQUESTED_FINANCE__DESCRIPTION__COMPANY: null, // When your company applies for finance incentives you can review them here.
  APPROVAL__REQUESTED_FINANCE__DESCRIPTION__TEAM: null, // When your company applies for finance incentives you can review them here.
  APPROVAL__REQUESTED_FINANCE__TITLE__COMPANY: null, // Your company has not applied for any finance incentives that require your approval.
  APPROVAL__REQUESTED_PROGRAM__DESCRIPTION__COMPANY: null, // When your company requests approval for programs you can review them here.
  APPROVAL__REQUESTED_PROGRAM__TITLE__COMPANY: null, // Your company has not requested your approval for any programs.
  APPROVAL__REQUESTED_RESOURCE__DESCRIPTION__COMPANY: null, // When your company requests approval for resources you can view them here.
  APPROVAL__REQUESTED_RESOURCE__DESCRIPTION__TEAM: null, // When your team requests approval for resources you can view them here.
  APPROVAL__REQUESTED_RESOURCE__TITLE__COMPANY: null, // Your company has not requested your approval for any resources.
  APPROVAL__REQUESTED_TIME__DESCRIPTION__COMPANY: null, // When your company applies for time incentives you can review them here.
  APPROVAL__REQUESTED_TIME__DESCRIPTION__TEAM: null, // When your team applies for time incentives you can review them here.
  APPROVAL__REQUESTED_TIME__TITLE__COMPANY: null, // Your company has not applied for any time incentives that require your approval.
  APPROVAL__REQUESTOR: null, // Requestor
  APPROVAL__REQUEST_APPROVAL: null, // Request approval for your program.
  APPROVAL__REQUEST__FORMAT: null, // Request Approval for {{item}}
  APPROVAL__REQUIRED: null, // Programs must be approved by your manager or an admin.
  APPROVAL__REQUIRED_FOR_INCENTIVE: null, // Requires approval in order to redeem this incentive
  APPROVAL__REQUIRED_FOR_PROGRAM: null, // Programs require approval in order to use this incentive
  APPROVAL__REQUIRED__BILLING_PARAGRAPH__FORMAT: null, // The cost ({{programCost}}) will be removed from your budget.  Details of how to access the program are provided upon approval.
  APPROVAL__REQUIRED__DETAILED: null, // Programs must be approved by your manager or an admin in order to use your personal learning budget.
  APPROVAL__REQUIRED__PARAGRAPH__FORMAT: null, // The cost of this program is covered by your company. Details of how to access the program are provided upon approval.
  APPROVAL__RESOURCE__PLURAL: null, // Resource Approvals
  APPROVAL__REVIEW__DESCRIPTION: null, // Review the details carefully and either approve or decline. You may also provide notes or comments to be seen by the employee.
  APPROVAL__SELECT: null, // Please select required approvals
  APPROVAL__START_JOURNEY: null, // Start your learning journey!
  APPROVAL__TEAM_HASNT_APPLIED__FINANCE_INCENTIVE: null, // Your team has not applied for any finance incentives.
  APPROVAL__TEAM_HASNT_APPLIED__TIME_INCENTIVE: null, // Your team has not applied for any time incentives.
  APPROVAL__TIME__PLURAL: null, // Time Approvals
  APPROVAL__TOTAL_APPROVED: null, // Total Approved
  APPROVAL__TOTAL_APPROVED__TOOLTIP: null, // Total balance approved but has not been spent.
  APPROVAL__TOTAL_REQUESTED: null, // Total Requested
  APPROVAL__WHATS_NEXT__DESCRIPTION: null, // Your request will be reviewed shortly and you will receive an email notifying of the status. This may take a few days to complete.
  APPROVAL__WHATS_NEXT__TITLE: null, // What's Next
  AVAILABILITY__APPROVAL_REQUIRED: null, // Requires approval
  AVAILABILITY__DRAFT: null, // No one - this is a draft
  AVAILABILITY__INITIATIVE_GROUP: null, // Only available for specific groups in Initiatives
  AVAILABILITY__PROGRAM_TYPES: null, // Only available for specific program types
  AVAILABILITY__PROVIDER: null, // Available for all programs from this provider
  AVAILABILITY__REQUESTED: null, // Only available for requested program
  BRAND__ADD_YOUR_COLORS: null, // Add your brand colors and we’ll do the rest!
  BRAND__BG: null, // Background
  BRAND__BG_COLOR: null, // Background Color
  BRAND__BRANDING: null, // Branding
  BRAND__BUTTON_DEFAULT_COLOR: null, // Button Default Color
  BRAND__CREATE_A_CUSTOM_THEME: null, // Create a custom theme
  BRAND__DEFAULT_TEXT: null, // Item Default Text
  BRAND__HOVER_BG: null, // Item Hover Background
  BRAND__ITEM_DEFAULT_TEXT: null, // Item Default Text
  BRAND__MAKE_DEFAULT: null, // Make this the default theme for future academies
  BRAND__POWERED_BY: null, // Powered by
  BRAND__PREVIEW_OVERVIEW: null, // Preview Overview
  BRAND__PREVIEW_WELCOME: null, // Preview Welcome Screen
  BRAND__PRIMARY: null, // Primary Brand Color
  BRAND__SECONDARY: null, // Secondary Brand Color
  BRAND__SELECTED_BG: null, // Item Selected Background
  BRAND__SELECTED_TEXT: null, // Item Selected Text
  BRAND__WELCOME_BUTTON_HOVER_COLOR: null, // Welcome Screen Button Hover Color
  BRAND__WELCOME_SCREEN: null, // Welcome Screen
  BRAND__YOUR: null, // Your brand
  C105_APPROVAL_FORM__QUESTION__PROVIDER: null, // Provider
  C105_APPROVAL_FORM__QUESTION__TOP_THREE_SKILLS: null, // What are the top three skills associated with this learning?
  C1_APPROVAL_FORM__QUESTION__PROGRAM__HOW_MANY_MONTHS__HELPER: null, // Add an Extra Month
  C1_APPROVAL_FORM__QUESTION__PROGRAM__START_DATE__HELPER: null, // If You Know
  C1_APPROVAL_FORM__QUESTION__PROGRAM__UPLOAD_SUPPORTING_DOC_0__HELPER: null, // Screenshots Are Fine
  C1_APPROVAL_FORM__QUESTION__PROGRAM__WHAT_VALUE_ADD__HELPER: null, // A Test
  CALENDAR: null, // Calendar
  CALENDAR__ADD_PROMPT: null, // Would you like to add events and due dates for this program to your calendar?
  CALENDAR__ADD_TO: null, // Add To Calendar
  CALENDAR__CONNECT: null, // Connect Calendar
  CALENDAR__CONNECT_CALENDAR__FORMAT: null, // Connect {{calendar}}
  CALENDAR__CONNECT_TO_EVENTS: null, // Connect Calendar for events in this section
  CALENDAR__DISCONNECT: null, // Disconnect Calendar
  CALENDAR__DISCONNECT_PROMPT__FORMAT: null, // Connecting your {{connecting}} Calendar will disconnect {{disconnecting}} Calendar. Would you like to continue?
  CALENDAR__DISCONNECT_PROMPT__SHORT__FORMAT: null, // Disconnect {{calendar}} Calendar
  CALENDAR__DISCONNECT__DESCRIPTION__FORMAT: null, // To fully disconnect your calendar please remove access in your {{calendarProvider}} calendar settings.
  CALENDAR__GOOGLE: null, // Google Calendar
  CALENDAR__OUTLOOK: null, // Outlook Calendar
  CALENDAR__SYNC_WITH: null, // Sync Calender With
  CARD__ACTIVE_ONCE: null, // Your card will be active once your program requests are approved.
  CARD__ADDRESS: null, // Address
  CARD__ADD_ADDITIONAL_FUNDS: null, // Add additional funds
  CARD__ADD_TO_WALLET: null, // Add card to digital wallet
  CARD__APPLE_GOOGLE_INSTRUCTIONS: null, // Open Apple Wallet or Google Pay on your mobile device and enter the card number manually. Once that has been completed you can use it at any store that accepts Apple Pay or Google Pay.
  CARD__BALANCE_TOO_LOW: null, // Is your balance too low?
  CARD__BILLING_ADDRESS: null, // Billing Address
  CARD__CARDHOLDER: null, // Cardholder
  CARD__CARDHOLDER__PLURAL: null, // Cardholders
  CARD__CARD_HOLDER: null, // Card Holder
  CARD__CLIPBOARD_CARD: null, // Copy card number to clipboard
  CARD__CLIPBOARD_CVC: null, // Copy CVC to clipboard
  CARD__CREDIT_CARD: null, // Credit Card
  CARD__CVC: null, // CVC
  CARD__ERROR_CONTACT_ADMIN: null, // Contact your admin or reach out to support for more information.
  CARD__ERROR_CREATING: null, // There was an error creating your card please contact support.
  CARD__ERROR_GENERATING: null, // Oh no! There was an error generating your virtual card.
  CARD__EXP: null, // EXP
  CARD__HELP_PAY_WITHOUT_CARD: null, // Program doesn't accept cards? We can help.
  CARD__PAY_FOR_PROGRAMS: null, // Use this card to pay for programs in your plan. Purchases will be visible by your manager.
  CARD__PHYSICAL_STORES: null, // Want to use your card at a physical store?
  CARD__PROGRAM_DESCRIPTION: null, // Use this card to pay for programs in your plan. Purchases will be visible by your manager.
  CARD__REMOVE_PROGRAM_QUESTION: null, // Have you purchased this program with your virtual card?
  CARD__REMOVE_RESOURCE_QUESTION: null, // Have you purchased this resource with your virtual card?
  CARD__RESTORE_YOUR_CARD: null, // Restore Your Card
  CARD__SHOW_CARD_NUMBER: null, // Show Card Number
  CARD__TRANSFER_HISTORY: null, // Transfer History
  CARD__UPON_APPROVAL: null, // Upon Approval
  CARD__VIRTUAL_CARD: null, // Virtual Card
  CARD__VIRTUAL_CARD__AVAILABLE: null, // Virtual Card Available
  CARD__VIRTUAL_CARD__AVAILABLE__TOOLTIP: null, // Current balance available to fund virtual cards.
  CARD__VIRTUAL_CARD__BALANCE: null, // Virtual Card Balance
  CARD__VIRTUAL_CARD__FOR_PREPAYMENT: null, // These funds will be used for virtual cards on prepayment incentives.
  CARD__VIRTUAL_CARD__FUNDS: null, // Virtual Card Funds
  CARD__VIRTUAL_CARD__NO_BALANCE_DETAILS: null, // No balance details.
  CARD__VIRTUAL_CARD__PLURAL: null, // Virtual Cards
  CARD__YOUR_CARD: null, // Your Card
  COHORT: null, // Cohort
  COHORT__ADD: null, // Add Cohort
  COHORT__CURRENT__FORMAT: null, // Current Cohort
  COHORT__DATE__END: null, // Cohort End Date
  COHORT__DATE__START: null, // Cohort Start Date
  COHORT__DELETE: null, // Delete Cohort
  COHORT__DELETE__CONFIRM__DESCRIPTION: null, // Are you sure you want to delete this cohort? Any participants will no longer have access to the program.
  COHORT__DUPLICATE__VERB: null, // Duplicate Cohort
  COHORT__EDIT: null, // Edit Cohort
  COHORT__EMPTY__DESCRIPTION: null, // When there is an active cohort you will see their progress here.
  COHORT__EMPTY__TITLE: null, // No one has been enrolled in this program.
  COHORT__NEXT_START__FORMAT: null, // Next Start
  COHORT__PARTICIPANTS: null, // Control who can see and participate in this program
  COHORT__PLURAL: null, // Cohorts
  COHORT__PREVIEW_AVAILABLE_MODAL_TITLE: null, // Available Cohorts
  COHORT__PREVIEW_BUTTON_LABEL: null, // Preview Cohort
  COHORT__SELECT: null, // Select a Cohort
  COHORT__SETTINGS: null, // Cohort Settings
  COHORT__SETUP__METADATA: null, // Metadata will be inherited from the parent program.
  COHORT__TABLE__EMPTY__DESCRIPTION: null, // Once you create cohorts you can view and manage them here.
  COHORT__TABLE__EMPTY__TITLE: null, // This program doesn't have any cohorts.
  COHORT__TITLE: null, // Cohort Name
  COHORT__VIEW__PLURAL: null, // View Cohorts
  COHORT__YOUR_COHORT: null, // Your Cohort
  COMMENT__PLURAL: null, // Comments
  COMPANY: null, // Company
  COMPANY__ADD: null, // Add Company
  COMPANY__HOME__FORMAT: null, // {{company}} Home
  COMPANY__NAME: null, // Company Name
  CONTENT: null, // Content
  CONTENT_MARKETPLACE: null, // Content Marketplace
  CONTENT__ADD: null, // Add Content
  CONTENT__ADD_CUSTOMIZED: null, // Add customized content for participants to complete.
  CONTENT__ADD_FROM_CATALOG: null, // Add content from your catalog
  CONTENT__BASIC: null, // Basic Content
  CONTENT__CATALOG: null, // Catalog Content
  CONTENT__DELETE: null, // Delete Content
  CONTENT__DELETE__PROMPT: null, // Are you sure you want to delete this content?
  CONTENT__DUPLICATE__VERB: null, // Duplicate Content
  CONTENT__EDIT: null, // Edit Content
  CONTENT__GO_TO: null, // Go To Content
  CONTENT__MOVE: null, // Move Content
  CONTENT__NEW: null, // New Content
  CONTENT__PROGRESS: null, // Content Progress
  CONTENT__SAVE: null, // Save Content
  CONTENT__SELECT_FROM_CATALOG: null, // Select Catalog Content
  CONTENT__SKIP: null, // Skip Content
  CONTENT__UNSKIP: null, // Unskip Content
  CONTRIBUTOR: null, // Contributor
  CONTRIBUTOR__ACADEMY_PERMISSIONS_APPROVER_DETAILS: null, // Select which contributors can edit view reports review projects. A contributor can also be set as the sole approver for marketplace programs within this academy. If no user is selected approvals for programs will default to the platform level approval settings.
  CONTRIBUTOR__ADD: null, // Add Contributors
  CONTRIBUTOR__ADD_WHO: null, // Who would you like to add?
  CONTRIBUTOR__PERMISSIONS__DETAILS__ACADEMY: null, // Academy specific permissions for who can edit view reports and review projects.
  CONTRIBUTOR__PERMISSIONS__DETAILS__PROGRAM: null, // Program specific permissions for who can edit view reports and review projects.
  CONTRIBUTOR__PERMISSIONS__TITLE__ACADEMY: null, // Academy Contributors + Permissions
  CONTRIBUTOR__PERMISSIONS__TITLE__PROGRAM: null, // Program Contributors + Permissions
  CONTRIBUTOR__PLURAL: null, // Contributors
  CONTRIBUTOR__REMOVE: null, // Remove Contributor
  COST_CENTER: null, // Cost Center
  CREDIT_CARD_DISCLOSURE: null, // Commercial Credit cards are issued by Celtic Bank
  CTA__ADD: null, // Add
  CTA__ADD_ITEM__FORMAT: null, // Add {{item}}
  CTA__APPLY: null, // Apply
  CTA__APPLY_NOW: null, // Apply Now
  CTA__APPROVE: null, // Approve
  CTA__BULK_NUDGE: null, // Bulk Nudge
  CTA__CANCEL: null, // Cancel
  CTA__CANCELLING: null, // Cancelling
  CTA__CHECK_ALL: null, // Check All
  CTA__CHECK_ITEM: null, // Check Item
  CTA__CLEAR_ALL: null, // Clear All
  CTA__CLOSE: null, // Close
  CTA__COMPLETE: null, // Complete
  CTA__CONNECT: null, // Connect
  CTA__CONTINUE: null, // Continue
  CTA__COPY_ACADEMY_LINK: null, // Copy Academy Link
  CTA__COPY_TO_CLIPBOARD: null, // Copy to clipboard
  CTA__CREATE: null, // Create
  CTA__CREATE_ITEM__FORMAT: null, // Create {{item}}
  CTA__DECLINE: null, // Decline
  CTA__DELETE: null, // Delete
  CTA__DELETE_ITEM__FORMAT: null, // Delete {{item}}
  CTA__DISCARD_CHANGES: null, // Discard Changes
  CTA__DISCONNECT: null, // Disconnect
  CTA__DISMISS: null, // Dismiss
  CTA__DRAG_AND_DROP: null, // Drag and Drop
  CTA__DUPLICATE: null, // Duplicate
  CTA__EDIT: null, // Edit
  CTA__EDIT_ITEM__FORMAT: null, // Edit {{item}}
  CTA__ENTER_URL: null, // Enter URL
  CTA__EXPORT: null, // Export
  CTA__GO_TO_ITEM__FORMAT: null, // Go to {{item}}
  CTA__HIDE: null, // Hide
  CTA__HIDE_ITEM__FORMAT: null, // Hide {{item}}
  CTA__ITEM__FORMAT: null, // Close {{item}}
  CTA__LEARN_HOW: null, // Learn how.
  CTA__LETS_GO: null, // Let's Go
  CTA__MANAGE: null, // Manage
  CTA__MARK_AS_AVAILABLE: null, // Mark as Available
  CTA__MARK_AS_COMPLETE: null, // Mark as Complete
  CTA__MARK_AS_COMPLETE_ITEM__FORMAT: null, // Mark as Complete {{item}}
  CTA__MARK_AS_FEATURED: null, // Mark as Featured
  CTA__MARK_AS_NOT_COMPLETED_ITEM__FORMAT: null, // Mark {{item}} as not completed
  CTA__MARK_COMPLETE: null, // Mark Complete
  CTA__MOVE_TO_BOTTOM: null, // Move to Bottom
  CTA__MOVE_TO_TOP: null, // Move to Top
  CTA__NEW_ITEM__FORMAT: null, // New {{item}}
  CTA__NEXT: null, // Next
  CTA__NEXT_STEP: null, // Next Step
  CTA__NEXT__ABBREVIATED: null, // Next
  CTA__NOMINATE: null, // Nominate
  CTA__OK: null, // OK
  CTA__OVERRIDE: null, // Override
  CTA__PREVIEW_ITEM__FORMAT: null, // Preview {{item}}
  CTA__PREVIOUS: null, // Previous
  CTA__PREVIOUS__ABBREVIATED: null, // Prev
  CTA__REAPPLY: null, // Reapply
  CTA__REJECT: null, // Reject
  CTA__REMOVE: null, // Remove
  CTA__RENEW: null, // Renew
  CTA__SAVE: null, // Save
  CTA__SAVE_AND_CONTINUE: null, // Save and Continue
  CTA__SAVE_CHANGES: null, // Save Changes
  CTA__SAVE_ITEM__FORMAT: null, // Save {{item}}
  CTA__SEARCH_ITEM__FORMAT: null, // Search {{item}}
  CTA__SEARCH_SKILLS: null, // Academy Skills
  CTA__SEND_NUDGE: null, // Send Nudge
  CTA__SHOW: null, // Show
  CTA__SHOW_ITEM__FORMAT: null, // Show {{item}}
  CTA__SHOW_LESS: null, // Show Less
  CTA__SHOW_MORE: null, // Show More
  CTA__SKIP: null, // Skip
  CTA__SORT__ASC: null, // Sort ascending
  CTA__SORT__DESC: null, // Sort descending
  CTA__SORT__NONE: null, // Don't sort
  CTA__SUBMIT: null, // Submit
  CTA__SUBMIT_A_LINK: null, // Submit A Link
  CTA__SUBMIT_FOR_APPROVAL: null, // Submit for approval
  CTA__SUBMIT_LINK: null, // Submit Link
  CTA__SUBMIT_RESPONSE: null, // Submit Response
  CTA__SUBMIT_REVIEW: null, // Submit Review
  CTA__SYNC: null, // Sync
  CTA__TRY_IT_OUT: null, // Try it Out
  CTA__UNMARK_AS_COMPLETED: null, // Unmark as Completed
  CTA__UNSKIP: null, // Unskip
  CTA__VIEW: null, // View
  CTA__VIEW_ALL: null, // View All
  CTA__VIEW_BY: null, // View by
  CTA__VIEW_DETAILS_ITEM__FORMAT: null, // View Details of {{item}}
  CTA__VIEW_ITEM__FORMAT: null, // View {{item}}
  CTA__VIEW_LINK: null, // View Link
  CTA__VIEW_MORE: null, // View More
  CTA__VIEW_RESULTS: null, // View Results
  CTA__VIEW__DETAIL__PLURAL: null, // View Details
  CTA__VISIT_WEBSITE: null, // Visit Website
  CTJ__ACADEMY_ELIGIBILITY: null, // All eligible employees who have this link will be given permission to access this academy.
  CTJ__COPY_PARTICIPANT_LINK: null, // Copy Participant Link
  CTJ__CUSTOM_PROGRAM_ELIGIBILITY: null, // All eligible employees who have this link will be given permission to access this custom program.
  CTJ__LINK_USE: null, // Anyone with this link can
  CTJ__VIEW_ACCESS: null, // View and Access
  DATE: null, // Date
  DATE__ADDED: null, // Date Added
  DATE__A_WEEK: null, // A Week
  DATE__DAILY: null, // Daily
  DATE__DAY: null, // Day
  DATE__DAY__PLURAL: null, // Days
  DATE__DUE: null, // Due
  DATE__DUE_DATE: null, // Due Date
  DATE__END: null, // End Date
  DATE__FEEDBACK_LEFT_ON__FORMAT: null, // left feedback on {{date}}
  DATE__FREQUENCY__EVERY_YEAR: null, // Every year
  DATE__FREQUENCY__FIRST_MONTH: null, // The 1st of each month
  DATE__FREQUENCY__FIRST_YEAR: null, // January 1st of each year
  DATE__FREQUENCY__ONE_TIME: null, // One Time
  DATE__HOUR: null, // Hour
  DATE__HOUR__PLURAL: null, // Hours
  DATE__LATE: null, // Late
  DATE__MONTH: null, // Month
  DATE__MONTHLY: null, // Monthly
  DATE__MONTH__10: null, // October
  DATE__MONTH__10__SHORT: null, // Oct
  DATE__MONTH__11: null, // November
  DATE__MONTH__11__SHORT: null, // Nov
  DATE__MONTH__12: null, // December
  DATE__MONTH__12__SHORT: null, // Dec
  DATE__MONTH__1: null, // January
  DATE__MONTH__1__SHORT: null, // Jan
  DATE__MONTH__2: null, // February
  DATE__MONTH__2__SHORT: null, // Feb
  DATE__MONTH__3: null, // March
  DATE__MONTH__3__SHORT: null, // Mar
  DATE__MONTH__4: null, // April
  DATE__MONTH__4__SHORT: null, // Apr
  DATE__MONTH__5: null, // May
  DATE__MONTH__5__SHORT: null, // May
  DATE__MONTH__6: null, // June
  DATE__MONTH__6__SHORT: null, // Jun
  DATE__MONTH__7: null, // July
  DATE__MONTH__7__SHORT: null, // Jul
  DATE__MONTH__8: null, // August
  DATE__MONTH__8__SHORT: null, // Aug
  DATE__MONTH__9: null, // September
  DATE__MONTH__9__SHORT: null, // Sep
  DATE__MONTH__PLURAL: null, // Months
  DATE__NEXT_SCHEDULED_TIME: null, // Next Scheduled Time
  DATE__NOMINATED: null, // Date Nominated
  DATE__PAST: null, // Past
  DATE__PAST_DUE: null, // Past Due
  DATE__PER_MONTH: null, // Per Month
  DATE__POSTED_ON__FORMAT: null, // Posted on {{date}}
  DATE__REQUESTED: null, // Date Requested
  DATE__SELECT: null, // Select a date
  DATE__START: null, // Start Date
  DATE__STARTED: null, // Date Started
  DATE__SUBMITTED: null, // Date Submitted
  DATE__TODAY: null, // Today
  DATE__UPCOMING: null, // Upcoming
  DATE__WEEK: null, // Week
  DATE__WEEKLY: null, // Weekly
  DATE__WEEK__PLURAL: null, // Weeks
  DATE__YEAR: null, // Year
  DATE__YEAR__PLURAL: null, // Years
  DEVELOPER: null, // Developer
  DIRECT_BILLING: null, // Direct Billing
  DISCUSSION: null, // Discussion
  DISCUSSION__ANSWER_PROMPT: null, // Write an answer to the discussion prompt
  DISCUSSION__CREATE: null, // Create a space for participants to discuss a specific prompt
  DISCUSSION__DELETE: null, // Delete Discussion
  DISCUSSION__DELETE__PROMPT: null, // Are you sure you want to delete this discussion?
  DISCUSSION__DUPLICATE__VERB: null, // Duplicate Discussion
  DISCUSSION__EDIT: null, // Edit Discussion
  DISCUSSION__NEW: null, // New Discussion
  DISCUSSION__SAVE: null, // Save Discussion
  DISCUSSION__SKIP: null, // Skip Discussion
  DISCUSSION__SUBMIT_FORMAT: null, // Submit written response for {{item}}
  DISCUSSION__UNSKIP: null, // Unskip Discussion
  DISCUSSION__UPDATE: null, // Update Discussion
  DISCUSSION__VIEW_ALL_RESPONSES__FORMAT: null, // View all {{count}} responses
  DISCUSSION__VIEW_RESPONSE: null, // View response
  EMAIL__BULK_NUDGE_ERROR: null, // Something went wrong sending email nudge.
  EMAIL__BULK_NUDGE_SUCCESSFUL: null, // Email nudge was sent successfully!
  EMAIL__SAVE: null, // Save Email
  EMAIL__SINGLE_NUDGE_ERROR: null, // Something went wrong sending email nudge.
  EMAIL__SINGLE_NUDGE_SUCCESSFUL: null, // Email nudge was sent successfully!
  EMPLOYEE: null, // Employee
  EMPLOYEE__COMMENT__PLURAL: null, // Employee Comments
  EMPLOYEE__ELIGIBLE: null, // Eligible Employees
  EMPLOYEE__JOB_ROLE: null, // Job Role
  EMPLOYEE__LINE_OF_BUSINESS: null, // Line Of Business
  EMPLOYEE__MAKING_TIME: null, // Making time for learning is one of the biggest challenges. Learn more about your options for learning while still working in your current job. Chat with an advisor or take our online quiz to learn which option is best for you.
  EMPLOYEE__NAME: null, // Employee Name
  EMPLOYEE__PLURAL: null, // Employees
  EMPLOYEE__PLURAL__ADDED: null, // Added Employees
  EMPLOYEE__PLURAL__SEARCH: null, // Search Employees
  EMPLOYEE__REAL_STORIES: null, // Real Employee Stories
  EMPLOYEE__SEARCH_TO_ADD: null, // Search for an employee to add
  EMPLOYEE__SEARCH_TO_NOMINATE: null, // Search for an employee to nominate
  ERROR__CODE_404: null, // Error Code
  ERROR__CONTACT_SUPPORT: null, // Please contact support
  ERROR__FRIENDLY: null, // Something went wrong sorry about that!
  ERROR__FUN_404: null, // Looks like your page flew away!
  ERROR__OOPS: null, // Oops!
  ERROR__PAGE__NOT__FOUND: null, // Page Not Found
  ERROR__TRY_AGAIN: null, // Something went wrong! Try again later.
  EVENT: null, // Event
  EVENT__ADD: null, // Add Event
  EVENT__ADD__PLURAL: null, // Add Events
  EVENT__ATTENDED_NO: null, // No I did not attend the event
  EVENT__ATTENDED_YES: null, // Yes I attended the event
  EVENT__CANCEL: null, // Cancel Event
  EVENT__COMPLETED: null, // Event Completed
  EVENT__DELETE: null, // Delete Event
  EVENT__DELETE__PROMPT: null, // Are you sure you want to delete this event?
  EVENT__DID_ATTEND: null, // Did you attend this event?
  EVENT__DISCUSSION__PROMPT: null, // Are you sure you want to delete this discussion?
  EVENT__DUPLICATE__VERB: null, // Duplicate Event
  EVENT__EDIT: null, // Edit Event
  EVENT__GO_TO: null, // Go To Event
  EVENT__JOIN: null, // Join Event
  EVENT__LIVE: null, // Live Event
  EVENT__MAKE_ALL_DAY: null, // Make event all day
  EVENT__MEETING_LINK: null, // Meeting Link
  EVENT__NEW: null, // New Event
  EVENT__PLURAL: null, // Events
  EVENT__RECURRING: null, // Recurring Event
  EVENT__RECURS_HOW_OFTEN: null, // Recurs Every
  EVENT__RSVP_DID_NOT: null, // Did not RSVP
  EVENT__RSVP_NO: null, // RSVP
  EVENT__RSVP_YES: null, // RSVP
  EVENT__SAVE: null, // Save Event
  EVENT__SCHEDULE_LIVE: null, // Schedule a live event for participants to join.
  EVENT__SKIP: null, // Skip Event
  EVENT__UNSKIP: null, // Unskip Event
  EVENT__UPDATE: null, // Update Event
  EVENT__WILL_ATTEND: null, // Are you attending this event?
  FEEDBACK: null, // Feedback
  FEEDBACK__SUBMIT: null, // Submit Feedback
  FEEDBACK__TO_PARTICIPANT__FORMAT: null, // Your Feedback to {{participant}}
  FEEDBACK__TO__FORMAT: null, // Feedback to {{user}}
  FILE: null, // File
  FILE__ATTACHMENT: null, // Attachment
  FILE__ATTACHMENT_OPEN: null, // Open Attachment
  FILE__ATTACHMENT__ATTACH: null, // Attach a File
  FILE__ATTACHMENT__DELETING: null, // Deleting Attachment
  FILE__ATTACHMENT__DELETING_OLD: null, // Deleting Old Attachment
  FILE__ATTACHMENT__LINK_TITLE: null, // Title of Link
  FILE__ATTACHMENT__PLURAL: null, // Attachments
  FILE__CLICK_TO_SELECT: null, // Click to Select a File
  FILE__DOWNLOAD__IN_PROGRESS_PERCENT__FORMAT: null, // Download In Progress
  FILE__DRAG_AND_DROP: null, // Drag and Drop file
  FILE__EXPORT__TO_CSV: null, // Export to CSV
  FILE__OPEN_ATTACHMENT: null, // Open File Attachment
  FILE__SELECT__COMPUTER: null, // Select a file on computer to upload
  FILE__UPLOAD: null, // Upload File
  FILE__UPLOAD__A: null, // Upload a File
  FILE__UPLOAD__ACCEPTED_TYPES__FORMAT: null, // Accepted file types
  FILE__UPLOAD__ALREADY__FORMAT: null, // {{file}} is already uploaded
  FILE__UPLOAD__DEFAULT_TYPES: null, // .png .jpg .jpeg and .pdf
  FILE__UPLOAD__ERROR__FORMAT: null, // {{file}} upload failed. Please try again!
  FILE__UPLOAD__FAILED__FORMAT: null, // {{file}} file upload failed. Please try again.
  FILE__UPLOAD__IMAGE: null, // Upload Image
  FILE__UPLOAD__IMAGES_DOCUMENTS_AND_VIDEOS: null, // .png .jpg .jpeg .pdf .mp4 and .mov
  FILE__UPLOAD__IN_PROGRESS: null, // Upload In Progress
  FILE__UPLOAD__READY__FORMAT: null, // {{file}} is ready to be uploaded
  FILE__UPLOAD__SAVE_AND_DELETE_ATTACHMENT: null, // Save and Delete Attachment
  FILE__UPLOAD__SAVE_AND_UPLOAD_CONTENT: null, // Save and Upload Content
  FILE__UPLOAD__SUCCESS__FORMAT: null, // {{file}} uploaded successfully!
  FILE__UPLOAD__UNSUPPORTED__FORMAT: null, // {{file}} is not supported! Please only upload .jpg (.jpeg) .png .pdf .mp4 and/or .mov files.
  FILE__UPLOAD__VERB: null, // Upload File
  FINANCE__DETAILS: null, // Finance Details
  FINANCE__PLURAL__EXPLORE: null, // Explore Financing
  FINANCE__PLURAL__VIEW_MORE: null, // View More Finances
  FULL_NAME_OF_PROGRAM: null, // Full Name of Program
  FUND__ADD__NOTE_REQUIRED: null, // Please provide a short description.
  FUND__ADD__NOTE_VISIBILITY_NOTICE: null, // This note will be seen by the employee.
  FUND__ADD__PLURAL: null, // Add Funds
  FUND__ADD__QUESTION_WHY: null, // Why are you updating funds?
  FUND__APPROVED_OF__FORMAT: null, // Approved out of {{amount}}
  FUND__BUDGET__EXCEEDED__FORMAT: null, // Funds entered exceed your maximum available amount of {{amount}}.
  FUND__GET_UP_TO: null, // Get up to
  FUND__INCREASE__FIRST_OF_MONTH__FORMAT: null, // Your balance will increase {{amount}} the first of each month.
  FUND__INCREASE__FIRST_OF_YEAR__FORMAT: null, // Your balance will increase {{amount}} the first of each year.
  FUND__MANAGE: null, // Manage Funds
  FUND__PLURAL: null, // Funds
  FUND__PLURAL__MANAGE: null, // Manage Funds
  FUND__REMAINING_APPROVED: null, // Remaining Approved Balance
  FUND__REQUESTED: null, // Funds Requested
  FUND__REQUESTED_ADDITIONAL__FORMAT: null, // You have requested additional funds
  FUND__REQUESTED_PROGRAM: null, // Program Requested
  FUND__REQUEST_APPROVAL_TO: null, // Request approval to
  FUND__REQUEST__ADDITIONAL: null, // Request Additional Funds
  FUND__REQUEST__AMOUNT: null, // Requested Amount
  FUND__REQUEST__APPROVED: null, // Approved Request
  FUND__REQUEST__NEW: null, // New Request
  FUND__REQUEST__WHAT_TYPE: null, // What type of request is this?
  FUND__RESET__FIRST_OF_MONTH__FORMAT: null, // Your balance will increase {{amount}} the first of each month.
  FUND__RESET__FIRST_OF_YEAR__FORMAT: null, // Your balance resets to {{amount}} the first of each year
  FUND__SPENT_OF__FORMAT: null, // spent out of {{amount}}
  FUND__TOTAL_SUBMITTED: null, // Total Submitted
  FUND__UNLOCK__PLURAL: null, // Unlock Funds
  FUND__UPDATE__PLURAL: null, // Update Funds
  GENERIC__1___3_MONTHS: null, // 1-3 Months
  GENERIC__1___4_WEEKS: null, // 2-4 Weeks
  GENERIC__3___9_MONTHS: null, // 3-9 Months
  GENERIC__9__MONTHS: null, // 9+ Months
  GENERIC__ABOUT: null, // About
  GENERIC__ACTION: null, // Action
  GENERIC__ACTIVITY: null, // Activity
  GENERIC__ADD: null, // Add
  GENERIC__ADDED_BY: null, // Added By
  GENERIC__ADDITIONAL_DETAILS: null, // Additional Details
  GENERIC__ADD_PROJECT_OR_ASSIGNMENT: null, // Add a project or assignment for participants to complete.
  GENERIC__AMOUNT: null, // Amount
  GENERIC__AND: null, // And
  GENERIC__ANY: null, // Any
  GENERIC__APPROVAL_GUIDELINE: null, // Approval Guideline
  GENERIC__APPROVAL_REQUIRED_BY: null, // Requires approval from
  GENERIC__APP_NAME: null, // Academies and Content Marketplace
  GENERIC__ARTICLE: null, // Article
  GENERIC__ASSESSMENT: null, // Assessment
  GENERIC__A_WEEK: null, // A Week
  GENERIC__BACHELORS_DEGREE: null, // Bachelors Degree
  GENERIC__BACK: null, // Back
  GENERIC__BETA: null, // Beta
  GENERIC__BOOTCAMP: null, // Bootcamp
  GENERIC__CATEGORY: null, // Category
  GENERIC__CERTIFICATE: null, // Certificate
  GENERIC__CERTIFICATION: null, // Certification
  GENERIC__COACHING: null, // Coaching
  GENERIC__COLLAPSED__FORMAT: null, // Collapsed
  GENERIC__COMPANY: null, // Company
  GENERIC__COMPANY_NAME: null, // Company Name
  GENERIC__COMPANY__HOME__FORMAT: null, // {{company}} Home
  GENERIC__CONTACT: null, // Contact
  GENERIC__CONTENT: null, // Content
  GENERIC__CONTENT__PROGRESS: null, // Content Progress
  GENERIC__COPIED: null, // Copied!
  GENERIC__COURSE: null, // Course
  GENERIC__CREATED_DATE: null, // Created Date
  GENERIC__DATE_UPDATED: null, // Date Updated
  GENERIC__DEGREE: null, // Degree
  GENERIC__DEGREE___ASSOCIATE: null, // Degree - Associate
  GENERIC__DEGREE___BACHELORS: null, // Degree - Bachelor's
  GENERIC__DEGREE___DOCTORATE: null, // Degree - Doctorate
  GENERIC__DEGREE___GRADUATE: null, // Degree - Graduate
  GENERIC__DEGREE___MASTERS: null, // Degree - Master's
  GENERIC__DESCRIPTION: null, // Description
  GENERIC__DESCRIPTION__NONE: null, // No Description
  GENERIC__DESCRIPTION__SHORT: null, // Short Description
  GENERIC__DETAIL__PLURAL: null, // Details
  GENERIC__DRAWER: null, // Drawer
  GENERIC__EDITOR__PLURAL: null, // Editors
  GENERIC__EMAIL: null, // Email
  GENERIC__EMPTY_RESULTS__DESCRIPTION: null, // If you can’t find what you need try checking your spelling or using different keywords.
  GENERIC__EMPTY_RESULTS__DESCRIPTION_ALT: null, // We couldn’t find any results try updating your search
  GENERIC__EMPTY_RESULTS__TITLE: null, // No Results Found
  GENERIC__EMPTY_STATE: null, // No data available
  GENERIC__EMPTY_STATUS__SIMPLE: null, // No data
  GENERIC__ENDORSED: null, // Endorsed
  GENERIC__EVERYONE: null, // Everyone
  GENERIC__EXPANDED__FORMAT: null, // Expanded
  GENERIC__EXPLORE: null, // Explore
  GENERIC__FAQ__PLURAL: null, // FAQs
  GENERIC__FEATURED: null, // Featured
  GENERIC__FINANCE: null, // Finance
  GENERIC__FINANCING: null, // Financing
  GENERIC__FIRST_NAME: null, // First Name
  GENERIC__FREE_RESPONSE: null, // Free Response
  GENERIC__HAS_NOT_LOGGED_IN: null, // Has not logged in
  GENERIC__HOME: null, // Home
  GENERIC__HOSTED: null, // Hosted
  GENERIC__IMAGE: null, // Image
  GENERIC__INDIVIDUAL_COURSE: null, // Individual Course
  GENERIC__INFORMATION__OTHER: null, // Other Information
  GENERIC__INSTRUCTOR__PLURAL: null, // Instructors
  GENERIC__KNOWLEDGE_CENTER: null, // Knowledge Center
  GENERIC__LANGUAGE_ENGLISH: null, // English
  GENERIC__LANGUAGE_FRENCH: null, // French
  GENERIC__LANGUAGE_SPANISH: null, // Spanish
  GENERIC__LAST_ACTIVE: null, // Last Active
  GENERIC__LAST_LOGIN: null, // Last Login
  GENERIC__LAST_NAME: null, // Last Name
  GENERIC__LAST_NUDGE_SENT: null, // Last Nudge Sent
  GENERIC__LAST_UPDATE: null, // Last Update
  GENERIC__LAST_USED: null, // Last Used
  GENERIC__LEARNING: null, // Learning
  GENERIC__LESS_THAN_A_DAY: null, // Less Than A Day
  GENERIC__LINK: null, // Link
  GENERIC__LINK_COPIED: null, // Link Copied!
  GENERIC__LIST: null, // List
  GENERIC__MANAGER: null, // Manager
  GENERIC__MANAGER_NAME: null, // Manager Name
  GENERIC__MASTERS_DEGREE: null, // Masters Degree
  GENERIC__MEMBERSHIP: null, // Membership
  GENERIC__MESSAGE: null, // Message
  GENERIC__MESSAGE__PERSONAL: null, // Personal Message
  GENERIC__MICROMASTERS: null, // MicroMasters
  GENERIC__MINUTE: null, // Minute
  GENERIC__MINUTE__PLURAL: null, // Minutes
  GENERIC__MODAL: null, // Modal
  GENERIC__NAME: null, // Name
  GENERIC__NAVIGATION: null, // Navigation
  GENERIC__NO: null, // No
  GENERIC__NOMINATION: null, // Nomination
  GENERIC__NOMINATION__PLURAL: null, // Nominations
  GENERIC__NOTE: null, // Note
  GENERIC__NOTES_FOR_EMPLOYEE: null, // Notes (will be seen by employee)
  GENERIC__NOTES_FROM_APPROVER: null, // Notes from approver
  GENERIC__NOTES_FROM__FORMAT: null, // Notes from {{name}}
  GENERIC__NOT_FOUND: null, // Not Found
  GENERIC__NO_I_HAVE_NOT: null, // No I have not
  GENERIC__NO_SEARCH_RESULTS: null, // No search results match your query
  GENERIC__NUDGE_EMAIL_PREVIEW: null, // Nudge Email Preview
  GENERIC__N_A: null, // N/A
  GENERIC__OF: null, // Of
  GENERIC__OR: null, // Or
  GENERIC__OTHER: null, // Other
  GENERIC__OTHER_INFORMATION: null, // Other Information
  GENERIC__OUTCOME__PLURAL: null, // Outcomes
  GENERIC__OVERVIEW: null, // Overview
  GENERIC__PAGE: null, // Page
  GENERIC__PAGE__CURRENT: null, // Current Page
  GENERIC__PAGE__NEXT: null, // Next Page
  GENERIC__PAGE__PLURAL: null, // Pages
  GENERIC__PAGE__PREVIOUS: null, // Previous Page
  GENERIC__PATHWAY: null, // Pathway
  GENERIC__PAYMENT_SCHEDULE: null, // Payment Schedule
  GENERIC__PEOPLE: null, // People
  GENERIC__PER: null, // Per
  GENERIC__PODCAST: null, // Podcast
  GENERIC__PREREQUISITES: null, // Pre-requisites
  GENERIC__PREVIEW: null, // Preview
  GENERIC__PREVIEW_NOTICE: null, // This is a Preview
  GENERIC__PRE_APPROVAL_REQUIRED_BY: null, // Requires pre-approval from
  GENERIC__PRIVACY_SETTINGS: null, // Privacy Settings
  GENERIC__PROVIDER: null, // Provider
  GENERIC__PROVIDER__PLURAL: null, // Provider
  GENERIC__QUESTION: null, // Question
  GENERIC__QUESTION__PLURAL: null, // Questions
  GENERIC__RATING: null, // Rating
  GENERIC__RATING__PLURAL: null, // Ratings
  GENERIC__READY_TO_SPEND: null, // Ready to Spend
  GENERIC__REASON: null, // Reason
  GENERIC__RECOMMENDED: null, // Recommended
  GENERIC__REDEMPTION_GUIDELINE: null, // Redemption Guideline
  GENERIC__REMAINING: null, // Remaining
  GENERIC__REQUIRED: null, // Required
  GENERIC__RESPONSE: null, // Response
  GENERIC__RESULTS: null, // Results
  GENERIC__REVIEW: null, // Review
  GENERIC__ROLE: null, // Role
  GENERIC__SAVE_TO_DRAFT: null, // Save changes and revert to a draft
  GENERIC__SEARCH: null, // Search
  GENERIC__SEARCH_RESULTS: null, // Search results
  GENERIC__SECOND: null, // Second
  GENERIC__SECOND__PLURAL: null, // Seconds
  GENERIC__SELECTED_PAGE: null, // Selected Page
  GENERIC__SELECT_ONE: null, // Select One
  GENERIC__SERIES: null, // Series
  GENERIC__SETUP: null, // Setup
  GENERIC__SHARE_PROGRAM: null, // Share Program
  GENERIC__SHORTCUT__PLURAL: null, // Shortcuts
  GENERIC__SHOWING_X_TO_Y_OF_Z__FORMAT: null, // Showing {{startCount}} to {{endCount}} of {{totalCount}} {{itemType}}
  GENERIC__SKILL: null, // Skill
  GENERIC__SKILL__PLURAL: null, // Skills
  GENERIC__SPENT: null, // Spent
  GENERIC__STEP: null, // Step
  GENERIC__SUBJECT: null, // Subject
  GENERIC__SUBJECTS: null, // Subjects
  GENERIC__SUMMARY: null, // Summary
  GENERIC__SUPPORT: null, // Support
  GENERIC__TAG: null, // Tag
  GENERIC__TAG__PLURAL: null, // Tags
  GENERIC__TARGETED: null, // Targeted
  GENERIC__TEXT: null, // Text
  GENERIC__THE_BASICS: null, // The Basics
  GENERIC__TIMES_PLANNED: null, // Times Planned
  GENERIC__TIMES_USED: null, // Times Used
  GENERIC__TITLE: null, // Title
  GENERIC__TOTAL_BUDGET: null, // Total Budget
  GENERIC__TRUE: null, // True
  GENERIC__TYPE: null, // Type
  GENERIC__TYPE_TO_SEARCH: null, // Type to search
  GENERIC__UNDER_DEVELOPMENT: null, // Under Development
  GENERIC__UNKNOWN: null, // Unknown
  GENERIC__UNSAVED_CHANGES: null, // There are some unsaved changes.
  GENERIC__URL: null, // URL
  GENERIC__USER_NAME: null, // User Name
  GENERIC__VIDEO: null, // Video
  GENERIC__VIEW_ALL: null, // View All
  GENERIC__WHOLE_COMPANY: null, // Whole Company
  GENERIC__WORD: null, // Word
  GENERIC__WORD__PLURAL: null, // Words
  GENERIC__X_BY_Y__FORMAT: null, // {{itemX}} by {{itemY}}
  GENERIC__X_CHARACTER__PLURAL__FORMAT: null, // {{max}} characters
  GENERIC__X_ITEMS__FORMAT: null, // {{count}} {{item}}
  GENERIC__YES: null, // Yes
  GENERIC__YES_COMPLETED_ON: null, // Yes completed on
  GENERIC__YES_I_HAVE: null, // Yes I have
  GENERIC__YOUR_PROGRAM: null, // Your Program
  HOME__DIVE_IN: null, // Let's dive right in.
  HOME__GET_STARTED: null, // Get Started
  HOME__GO_TO: null, // Go to Home
  HOME__HERE_TO_HELP: null, // We're here to help you make the next step in your career to help you get that promotion or gain valuable skills in your current role.
  HOME__LEARN_SKILLS: null, // Learn in-demand skills through bootcamps certificate programs or even full-length degree programs.
  HOME__LETS_MAKE_ONE: null, // Let's make one.
  HOME__MAKE_PLAN: null, // Start making your plan now
  HOME__PLAN_CONTAINS: null, // Your plan will consist of choosing a program allocating time and making a financial plan.
  HOME__START_WITH_A_PLAN: null, // Everything great starts with a plan.
  HOME__WELCOME_BACK__FORMAT: null, // Welcome back {{firstName}}!
  HOME__YOUR__GO_TO: null, // Go To Your Home
  INCENTIVE: null, // Incentive
  INCENTIVE__ADD: null, // Add Incentives
  INCENTIVE__ADD__WHAT_TYPE: null, // What type of financial incentive will this be?
  INCENTIVE__APPROVAL_REQUIRES: null, // Pre-approval of this incentive requires approval from
  INCENTIVE__AVAILABLE_TO: null, // This incentive is available to use with these specific programs.
  INCENTIVE__AVAILABLE__ALL: null, // This incentive is available for all program types.
  INCENTIVE__AVAILABLE__ONLY__FORMAT: null, // This incentive is available for {{item}} only.
  INCENTIVE__CAROUSEL_TITLE: null, // Awesome Incentives
  INCENTIVE__COMPANY_SPONSORED: null, // Company Sponsored
  INCENTIVE__CONFIRM_PUBLISH_BUTTON: null, // Yes Publish Incentive
  INCENTIVE__CONFIRM_PUBLISH_DESCRIPTION: null, // This incentive will apply to the entire company and will be available for everyone to use.
  INCENTIVE__CONFIRM_TITLE: null, // Are you sure you want to publish this incentive?
  INCENTIVE__CREATE_A_FINANCE: null, // Create A Finance Incentive
  INCENTIVE__EDIT: null, // Edit Incentive
  INCENTIVE__EMPTY__DESCRIPTION: null, // Create personal learning budgets scholarships and more for your team to use for learning.
  INCENTIVE__EMPTY__TITLE: null, // Get started by adding an Incentive
  INCENTIVE__EXPIRES_DAYS_AFTER_APPROVAL: null, // days after approval.
  INCENTIVE__EXPIRES_WHEN: null, // When do approved funds expire?
  INCENTIVE__FINANCE: null, // Finance Incentive
  INCENTIVE__FINANCE__DESCRIPTION: null, // You can apply for employer assistance for your programs and track the approval right inside of Learn In. Check out all the available plans we have to help meet your financial needs.
  INCENTIVE__FINANCE__EMPTY__DESCRIPTION: null, // As more employees use financial incentives you will see an overview of how much has been spent and approved.
  INCENTIVE__FINANCE__EMPTY__TITLE: null, // No one has used financial incentives
  INCENTIVE__FINANCE__PLURAL: null, // Finance Incentives
  INCENTIVE__HOW_MUCH_TIME: null, // How much time can be used?
  INCENTIVE__HOW_MUCH_USE: null, // How much money can people use?
  INCENTIVE__HOW_OFTEN: null, // How often will this occur?
  INCENTIVE__HOW_REDEEM: null, // How can this be redeemed?
  INCENTIVE__LEARNING_BUDGET: null, // Learning Budget Incentive
  INCENTIVE__LEARNING_BUDGET_DESCRIPTION: null, // A company-wide learning budget that is automatically added to employees’ plans.
  INCENTIVE__LEARNING_BUDGET__CREATE: null, // Create a Learning Budget Incentive
  INCENTIVE__LEARNING_BUDGET__UPDATE: null, // Update Learning Budget Incentive
  INCENTIVE__LINK_TO_EXPENSE_REPORT: null, // Add a Link to submit an expense report
  INCENTIVE__NEW: null, // New Incentive
  INCENTIVE__NONE: null, // No Incentives
  INCENTIVE__PLURAL: null, // Incentives
  INCENTIVE__PLURAL__EXPLORE: null, // Explore Incentives
  INCENTIVE__POSSESSIVE__FORMAT: null, // {{groupName}}'s Incentives
  INCENTIVE__PROGRAMS_AVAILABLE: null, // Programs Available for this Incentive
  INCENTIVE__REDEMPTION_REQUIRES: null, // Redemption of this incentive requires approval from
  INCENTIVE__SCHOLARSHIPS_ETC: null, // Scholarships bonuses or any other type of financial incentives you would like to provide.
  INCENTIVE__SELECT: null, // Select Financing Incentives
  INCENTIVE__SELECTED__PLURAL__FORMAT: null, // {{count}} Incentives Selected
  INCENTIVE__SELECTED__SINGLE: null, // 1 Incentive Selected
  INCENTIVE__SELECT_TIME: null, // Select Time Incentives
  INCENTIVE__TIME__DESCRIPTION: null, // Whether your company is willing to give you time during your normal work schedule or if you’re doing this program on the side we’ll help you make a plan and stick to it. Check out our many available time incentives and find the one that best fits your needs.
  INCENTIVE__TIME__EMPTY__DESCRIPTION: null, // Time incentives are a great way to allow your team to block off time for learning.
  INCENTIVE__TIME__EMPTY__TITLE: null, // Create your first time incentive to get started!
  INCENTIVE__UNAVAILABLE__ALL: null, // Incentives not available for all programs
  INCENTIVE__UNAVAILABLE__ANY: null, // Sorry no programs are available for this incentive
  INCENTIVE__UNAVAILABLE__YOUR: null, // Incentives not available for your programs
  INCENTIVE__UPDATE: null, // Update Incentive
  INCENTIVE__UPDATE_A_FINANCE: null, // Update A Finance Incentive
  INITIATIVE: null, // Initiative
  INITIATIVE__ADD_TO: null, // Add to Initiative
  INITIATIVE__AUDIENCE: null, // Select Audience
  INITIATIVE__CREATE: null, // Create Initiative
  INITIATIVE__DELETE: null, // Delete Initiative
  INITIATIVE__DELETE__PROMPT: null, // Are you sure you want to delete this initiative?
  INITIATIVE__EDIT: null, // Edit Initiative
  INITIATIVE__EMPTY__DESCRIPTION: null, // Choose courses and incentives to provide to your whole organization or a specific audience.
  INITIATIVE__EMPTY__TITLE: null, // You have not created any initiatives.
  INITIATIVE__FOR: null, // Who is this initiative for?
  INITIATIVE__HELP_LEARN: null, // To help employees learn a critical skill
  INITIATIVE__HELP_PREPARE: null, // To help prepare employees for a new role
  INITIATIVE__HOW_RELOAD: null, // How will the balance reload?
  INITIATIVE__NAME: null, // Initiative Name
  INITIATIVE__NAME__EXAMPLE: null, // Ex) Upskill software engineers in cybersecurity
  INITIATIVE__NEW: null, // New Initiative
  INITIATIVE__NEW_ROLE: null, // What is the new role?
  INITIATIVE__NONE__DESCRIPTION: null, // Create financial incentives for this initiative or skip this step.
  INITIATIVE__PLURAL: null, // Initiatives
  INITIATIVE__PRORATE_FIELD: null, // Prorate budget for new employees
  INITIATIVE__PRORATE_HELP: null, // New employees will receive a prorated budget based on their start date
  INITIATIVE__PRORATE__RENEWAL_MONTH_FIELD: null, // Select Renewal Month
  INITIATIVE__PRORATE__RENEWAL_MONTH_HELP: null, // Budgets renew on the first day of the selected month
  INITIATIVE__PUBLISH: null, // Publish Initiative
  INITIATIVE__PURPOSE: null, // Why are you creating this initiative?
  INITIATIVE__SELECT_AUDIENCE: null, // Select Audience
  INITIATIVE__SELECT_PROGRAMS_FOR: null, // Select Programs for this initiative
  INITIATIVE__SPECIFIC_AUDIENCE: null, // Specific Audience
  INITIATIVE__THE_BASICS: null, // The Basics
  INITIATIVE__UNPUBLISH: null, // Un-publish Initiative
  INITIATIVE__UNSAVED_ITEMS: null, // This initiative has some unsaved changes.
  INITIATIVE__VISIBILITY: null, // Who can see this incentive?
  INSIGHTS__NO_ACTIVITY__DESCRIPTION__FORMAT: null, // Once {{firstName}} has activity to show you can see it here.
  INSIGHTS__NO_ACTIVITY__TITLE__FORMAT: null, // There’s no activity for {{firstName}}
  INSIGHTS__USERS_ACTIVITY__FORMAT: null, // ⁪⁪⁪‌​‍​‌‍​​​‍‌‌‍​​‍‍‌‌​‌​‌‍‍​​​‌​​‌‌‍‍​‍​​‌​⁪​​​{{firstName}}‌‌‌'s Activity
  INSIGHTS__USERS_PLAN__FORMAT: null, // ⁪⁪⁪‌​‍​‌‍​​​‍‌‌‍​​‍‍‌‌​‌​‌‍‍​​​‌​​‌‌‍‍​‍​​‌​⁪​​​{{firstName}}‌‌‌'s Plan⁪⁪
  INSIGHTS__USER_NO_TRANSACTIONS__DESCRIPTION: null, // You will see activity here once available.
  INSIGHTS__USER_NO_TRANSACTIONS__TITLE__FORMAT: null, // {{firstName}} has not made any transactions.
  INSIGHT__PLURAL: null, // Insights
  INSIGHT__USER: null, // User Insight
  INTEGRATION__PLURAL: null, // Integrations
  ISSUING_ORGANIZATION: null, // Issuing Organization/Provider
  ITEM__SELECTED__PLURAL__FORMAT: null, // <strong>{{number}}</strong> items selected
  ITEM__SELECTED__SINGLE: null, // <strong>1</strong> item selected
  LABEL__ALLOW_SKIP: null, // Allow Skip
  LABEL__DO_NOT_ALLOW_SKIP: null, // Don't Allow Skip
  LABEL__SEARCH_NO_SKILLS_FOUND: null, // No matching skills found
  LABEL__UPLOADING: null, // uploading
  LEARNER__SHOW_VIEW: null, // Show Learner View
  LEVEL: null, // Section
  LEVEL__ADD_TO_CALENDAR: null, // Stay up to date! Add the events for this section to your calendar.
  LEVEL__FOOTER_PHRASE: null, // Can’t find what you need?
  LEVEL__FOOTER_PHRASE__CREATE_CONTENT: null, // Create your own content.
  LEVEL__GET_STARTED: null, // Get started by selecting programs or content to sequence.
  LEVEL__MOVE_TO: null, // Move to Section
  LEVEL__MOVE_TO_BOTTOM: null, // Move Section to Bottom
  LEVEL__MOVE_TO_SECTION: null, // Select which section to move content to
  LEVEL__MOVE_TO_TOP: null, // Move Section to Top
  LEVEL__NO_MOVE_TO_LEVEL: null, // There are no other levels to move this content to
  LEVEL__NO_MOVE_TO_SECTION: null, // There are no other sections to move this content to
  LEVEL__RENAME: null, // Rename Section
  LEVEL__REQUIREMENT__ADD: null, // Add Requirement
  LEVEL__REQUIREMENT__EDIT: null, // Edit Requirement
  LEVEL__REQUIREMENT__IS_PROGRAM_STEP: null, // The selected step is a marketplace program what should trigger the unlock?
  LEVEL__REQUIREMENT__PREVIOUS_STEP: null, // The previous step (if any).
  LEVEL__REQUIREMENT__REMOVE: null, // Remove Requirement
  LEVEL__REQUIREMENT__SAVE: null, // Save Requirement
  LEVEL__REQUIREMENT__SPECIFIC_STEP: null, // A specific previous step.
  LEVEL__REQUIREMENT__SPECIFIC_STEP_APPROVED: null, // When the program is approved.
  LEVEL__REQUIREMENT__SPECIFIC_STEP_MARKED_COMPLETE: null, // When the program is marked as complete.
  LEVEL__REQUIREMENT__WHICH_STEP: null, // What step must be completed to access this?
  LEVEL__SEARCH: null, // Search Section
  LEVEL__SELECT: null, // Select Section
  LEVEL__SELECT_TO_MOVE: null, // Select which level to move content to
  LEVEL__STEP__ADD: null, // Add Step
  LEVEL__STEP__CUSTOM: null, // Custom Step
  LEVEL__STEP__CUSTOM__DELETE: null, // Delete Custom Step
  LEVEL__STEP__DESCRIPTION__ADD: null, // Add a description
  LEVEL__STEP__TITLE__ADD: null, // Add a title
  LICENSE: null, // License
  LICENSE__ABOUT: null, // Tell us about this license
  LICENSE__ACCESS_BY: null, // Who can access this license?
  LICENSE__ACCESS_GUIDELINES__LABEL: null, // Access Guidelines
  LICENSE__ACCESS_QUESTION: null, // Who can access this?
  LICENSE__ACTIVE_DURATION: null, // How long is the license active?
  LICENSE__ADD: null, // Add License
  LICENSE__ADD__EMPTY__DESCRIPTION: null, // You can manage licenses here once they have been created.
  LICENSE__ADD__EMPTY__TITLE: null, // No licenses have been created.
  LICENSE__ADD__PLURAL: null, // Add Licenses
  LICENSE__ALLOCATION: null, // License Allocation
  LICENSE__APPLICABLE_PROGRAMS: null, // Applicable Programs
  LICENSE__APPROVALS: null, // Approvals
  LICENSE__APPROVAL_GUIDELINES__LABEL: null, // Approval Guidelines
  LICENSE__APPROVAL_GUIDELINE__DESCRIPTION: null, // Help approvers understand when to accept or reject a request.
  LICENSE__APPROVAL__CONTROLLED: null, // Control approvals and access to this license.
  LICENSE__APPROVED_TOTAL: null, // Total Approved Licenses
  LICENSE__APPROVED_TOTAL_30_DAYS: null, // Total Approved Licenses in Past 30 Days
  LICENSE__AUTO_ADD__DESCRIPTION: null, // Your company has a license available that you can use to pay for this program. We added it to your plan for you.
  LICENSE__AUTO_ADD__EDX: null, // A EdX License was Added to Your Plan
  LICENSE__AUTO_ADD__EDX_LICENSE: null, // EdX License
  LICENSE__AUTO_ADD__INTERESTED: null, // Interested in a EdX program? Add this to your plan to request access for a EdX License from your company.
  LICENSE__AUTO_ALLOCATE: null, // Auto-allocate license voucher codes on creation
  LICENSE__AVAILABLE: null, // License Available
  LICENSE__COST__DEPENDENT: null, // Dependent on requested program cost
  LICENSE__COST__NO_COST: null, // No Cost
  LICENSE__DELETE: null, // Delete License
  LICENSE__DELETE__PROMPT: null, // Are you sure you want to delete this license?
  LICENSE__DIRECT_FUNDING: null, // Direct Funding
  LICENSE__DIRECT_FUNDING__OPTION: null, // Select this option if you will fund learning directly with the provider.
  LICENSE__EDIT: null, // Edit License
  LICENSE__ELIGIBILITY: null, // Eligibility
  LICENSE__FUNDING_METHOD: null, // Funding Method
  LICENSE__FUNDING_TYPE: null, // Type of Funding
  LICENSE__INTERNAL_COST: null, // Internal Cost
  LICENSE__INTERNAL_COST_PER: null, // Internal Cost Per License
  LICENSE__NEW: null, // New License
  LICENSE__NOTES: null, // Notes from Manager or Admin
  LICENSE__OPTION: null, // Select this option if you will provide employees access to learning via a license or voucher.
  LICENSE__PLURAL: null, // Licenses
  LICENSE__PROVIDER: null, // License Provider
  LICENSE__PROVIDER__COLON: null, // License Provider
  LICENSE__REDEMPTION_GUIDELINE__DESCRIPTION: null, // Tell employees how to redeem this license once they are approved.
  LICENSE__REQUEST: null, // Request License
  LICENSE__SAVE: null, // Save License
  LICENSE__USAGE: null, // License Usage
  LICENSE__VIEW: null, // View License
  LICENSE__VOUCHER_CODE__COLON: null, // Voucher Code
  LINK: null, // Link
  LINK__SUBMIT: null, // Submit Link
  LINK__SUBMIT_A: null, // Submit A Link
  LINK__VIEW: null, // View Link
  LXP__CONTENT: null, // LXP Content
  LXP__LAST_SYNC: null, // Last LXP Sync
  MESSAGE: null, // Message
  MESSAGE__EDIT: null, // Edit Message
  MESSAGE__PERSONAL: null, // Personal Message
  MONEY_AMOUNT_SPECIFIC: null, // Specific Amount
  MONEY_AMOUNT_SPECIFIC__NONE: null, // No specific amount
  MONEY__ADDITIONAL_FUNDS: null, // Additional Funds
  MONEY__AMOUNT_PAID_OUT: null, // Amount Paid Out
  MONEY__AMOUNT_SPENT: null, // Spent Amount
  MONEY__BALANCE: null, // Balance
  MONEY__BALANCE_MINUS_APPROVED: null, // Current balance minus approved funds
  MONEY__BALANCE__CURRENT: null, // Current Balance
  MONEY__BALANCE__PLURAL: null, // Balances
  MONEY__BALANCE__RESET_EACH_TIME: null, // Reset the balance each time
  MONEY__BALANCE__ROLLOVER_PREVIOUS: null, // Rollover the previous balance
  MONEY__COST: null, // Cost
  MONEY__OTHER_COSTS: null, // Other Costs
  MONEY__PAYOUT_HISTORY: null, // Payout History
  MONEY__PRICE__MAX: null, // Max Price
  MONEY__PRICE__MIN: null, // Min Price
  MONEY__PRICE__RANGE: null, // Price Range
  MONEY__PROJECTED_BALANCE: null, // Projected Balance
  MONEY__REMAINING_BALANCE: null, // Remaining Balance
  MONEY__SCHEDULE_TRANSFER: null, // Schedule Transfer
  MONEY__SPENT_YEAR__FORMAT: null, // Total Spent in {{year}}
  MONEY__SPENT_YEAR__TOOLTIP: null, // Total spent this calendar year. See more details in reports.
  MONEY__TOTAL_AMOUNT: null, // Total Amount
  MONEY__TOTAL_COST: null, // Total Cost
  MONEY__TOTAL_REQUEST: null, // Total Request
  MONEY__TOTAL_SPENT: null, // Total Spent
  NAME: null, // Name
  NAME__SEARCH: null, // Search Name
  NAV__ADVANCED_ANALYTICS: null, // Advanced Analytics
  NAV__API_KEYS: null, // API Keys
  NAV__AUTOMATIONS: null, // Automations
  NAV__CATALOG: null, // Catalog
  NAV__CATEGORIES: null, // Categories
  NAV__CONFIGURATIONS: null, // Configurations
  NAV__CONNECTED: null, // Connected
  NAV__COOKIE_NOTICE: null, // Cookie Notice
  NAV__DASHBOARD: null, // Dashboard
  NAV__DIRECTORY: null, // Directory
  NAV__EXTENDED_ENTERPRISE: null, // Extended Enterprise
  NAV__FAQ: null, // FAQ
  NAV__FILE_LOG: null, // File Log
  NAV__FILE_UPLOAD: null, // File Upload
  NAV__FREQUENTLY_ASKED_QUESTIONS: null, // Frequently Asked Questions
  NAV__FTP: null, // FTP
  NAV__GROUPS: null, // Groups
  NAV__HELP_MENU: null, // Help Menu
  NAV__INVENTORY: null, // Inventory
  NAV__MESSAGING: null, // Messaging
  NAV__ORG_SKILLS: null, // Org Skills
  NAV__PATHWAYS: null, // Pathways
  NAV__PLANS: null, // Plans
  NAV__PRESETS: null, // Presets
  NAV__PRIVACY_POLICY: null, // Privacy Policy
  NAV__REPORTING: null, // Reporting
  NAV__ROLES: null, // Roles
  NAV__SCALES: null, // Scales
  NAV__SECURITY: null, // Security
  NAV__SEGMENTS: null, // Segments
  NAV__SKILL_STANDARDS: null, // Skill Standards
  NAV__SKILL_TRENDS: null, // Skill Trends
  NAV__USER_ATTRIBUTES: null, // User Attributes
  NOMINATION: null, // Nomination
  NOMINATION__PLURAL: null, // Nominations
  NOMINATION__REMOVE: null, // Remove Nomination
  NOTE: null, // Note
  NOTE__ADD: null, // Add Note
  NOTE__FOR_EMPLOYEE: null, // Notes (will be seen by employee)
  NOTE__FROM_APPROVER: null, // Notes from approver
  NOTE__REMOVE: null, // Remove Note
  NOTIFICATION__ACADEMY__DUPLICATED__ERROR: null, // Something went wrong duplicating academy. Please try again.
  NOTIFICATION__ACADEMY__INVITATION_EMAIL_SUCCESS: null, // Invitation Email Changes have been saved
  NOTIFICATION__ACADEMY__PUBLISHED__ERROR: null, // Failed to publish academy. Please try again.
  NOTIFICATION__ACADEMY__PUBLISHED__SUCCESS: null, // Academy successfully published!
  NOTIFICATION__ACADEMY__REMOVED__ERROR: null, // There was an error removing the academy
  NOTIFICATION__ACADEMY__REMOVED__SUCCESS: null, // Academy removed successfully
  NOTIFICATION__ACCESS__GRANTED__PROGRAM__SUCCESS: null, // Users have been granted access to this program successfully
  NOTIFICATION__ACCESS__REVOKED__PROGRAM__SUCCESS: null, // User's access was successfully removed from the program
  NOTIFICATION__ACESSS_NOT_ALLOWED: null, // You do not have access to manage {{feature}}.
  NOTIFICATION__ADD_SKILLS_ERROR: null, // Something went wrong adding skills
  NOTIFICATION__APPROVAL__REQUESTED___SUCCESS: null, // You have requested approval.
  NOTIFICATION__ATTACHMENT__DELETED__ERROR: null, // Failed to delete attachment. Please try again.
  NOTIFICATION__ATTACHMENT__DELETED__SUCCESS: null, // Attachment successfully deleted!
  NOTIFICATION__ATTACHMENT__OPENED__ERROR: null, // Failed to open attachment. Please try again.
  NOTIFICATION__AVAILABLE__MARKED__SUCCESS: null, // Successfully marked as available!
  NOTIFICATION__BALANCE__UPDATED__SUCCESS: null, // Funds updated successfully
  NOTIFICATION__BANK_ACCOUNT__ADDED__ERROR: null, // Failed to add new bank account
  NOTIFICATION__BANK_ACCOUNT__ADDED__SUCCESS: null, // Successfully added new bank account!
  NOTIFICATION__BANK_ACCOUNT__REMOVED__ERROR: null, // Failed to remove bank account
  NOTIFICATION__BANK_ACCOUNT__REMOVED__SUCCESS: null, // Successfully removed bank account!
  NOTIFICATION__BRANDING_UPDATE_ERROR: null, // There was an error updating the branding colors
  NOTIFICATION__BRANDING_UPDATE_SUCCESS: null, // Branding colors updated successfully
  NOTIFICATION__CALENDAR__CONNECTED__ERROR: null, // Can't make calendar connection. Please try again.
  NOTIFICATION__CALENDAR__DISCONNECTED__ERROR: null, // Can't make calendar disconnection. Please try again.
  NOTIFICATION__CALENDAR__DISCONNECTED__SUCCESS: null, // Successfully disconnected your active calendar.
  NOTIFICATION__CARD__RESTORED__ERROR: null, // Your card was not restored. Please try again or contact support.
  NOTIFICATION__CHANGES__SAVED__SUCCESS: null, // Your changes have been saved.
  NOTIFICATION__CHECK_EMAIL: null, // Check your email!
  NOTIFICATION__COHORT__ADDED__ERROR: null, // There was an error creating your cohort.
  NOTIFICATION__COHORT__ADDED__SUCCESS: null, // Cohort successfully added!
  NOTIFICATION__COHORT__DELETED__ERROR: null, // There was an error deleting your cohort
  NOTIFICATION__COHORT__DELETED__SUCCESS: null, // Cohort was successfully deleted
  NOTIFICATION__COHORT__UPDATED__ERROR: null, // There was an error updating your cohort
  NOTIFICATION__COHORT__UPDATED__SUCCESS: null, // Cohort successfully updated
  NOTIFICATION__COMPANY__ADDED__FAILED: null, // Failed to add company. Please try again.
  NOTIFICATION__COMPANY__ADDED__SUCCESS: null, // Company added successfully!
  NOTIFICATION__CONTENT__ADDED__ERROR: null, // Something went wrong adding content. Please try again.
  NOTIFICATION__CONTENT__ADDED__SUCCESS: null, // Program content successfully updated!
  NOTIFICATION__CONTENT__DELETED__ERROR__FORMAT: null, // Something went wrong deleting {{contentType}}. Please try again.
  NOTIFICATION__CONTENT__DELETED__SUCCESS__FORMAT: null, // {{contentType}} deleted successfully!
  NOTIFICATION__CONTENT__DUPLICATED__ERROR: null, // Something went wrong duplicating content. Please try again.
  NOTIFICATION__CONTENT__MOVED__ERROR: null, // Something went wrong moving content. Please try again.
  NOTIFICATION__CONTENT__REORDERED__ERROR: null, // Something went wrong reordering content. Please try again.
  NOTIFICATION__CONTENT__UPDATED__ERROR: null, // Something went wrong updating content. Please try again.
  NOTIFICATION__COST_CENTER__UPDATED__ERROR: null, // Something went wrong updating cost center
  NOTIFICATION__COST_CENTER__UPDATED__SUCCESS: null, // Cost Center updated successfully
  NOTIFICATION__CREDIT_CARD__COPIED__SUCCESS: null, // Credit card number copied to clipboard.
  NOTIFICATION__CREDIT_CARD__CVV__COPIED__SUCCESS: null, // CVV copied to your clipboard.
  NOTIFICATION__CTJ_LINK_COPIED: null, // The share link has been successfully copied.
  NOTIFICATION__DATA_EXPORTED: null, // All data has already been exported.
  NOTIFICATION__DATE__ADDED__ERROR: null, // Something went wrong adding this date to your calendar. Please try again.
  NOTIFICATION__DATE__ADDED__SUCCESS: null, // Date was successfully added to your calendar!
  NOTIFICATION__DATE__START_END___ERROR: null, // Start Date cannot be after the End Date.
  NOTIFICATION__DELETE_SKILLS_ERROR: null, // Something went wrong deleting skills
  NOTIFICATION__ERROR_LOGGED: null, // Failure! Check logs
  NOTIFICATION__EVENTS__ADDED__ERROR: null, // Something went wrong adding events
  NOTIFICATION__EVENTS__ADDED__SUCCESS: null, // Events added successfully
  NOTIFICATION__EVENT__ADDED__ERROR: null, // Can't add the event. Please try again.
  NOTIFICATION__EVENT__ADDED__SUCCESS: null, // Successfully added a new event.
  NOTIFICATION__EVENT__UPDATED__ERROR: null, // Can't update the event. Please try again.
  NOTIFICATION__EVENT__UPDATED__SUCCESS: null, // Successfully updated the event.
  NOTIFICATION__FEATURE__MARKED__ERROR__FORMAT: null, // Failed to mark {{program}} as featured! Please try again.
  NOTIFICATION__FEATURE__MARKED__SUCCESS: null, // Successfully marked as featured!
  NOTIFICATION__FEATURE__UNMARKED__SUCCESS: null, // Successfully removed as featured!
  NOTIFICATION__FEEDBACK__SUBMITTED__ERROR: null, // There was an error submitting feedback
  NOTIFICATION__FEEDBACK__SUBMITTED__SUCCESS: null, // Feedback submitted successfully
  NOTIFICATION__FILE__UPDATED__SUCCESS__FORMAT: null, // {{fileName}} successfully updated
  NOTIFICATION__FILE__UPLOAD__CANCELLATION: null, // Cancelling file upload...
  NOTIFICATION__FILE__UPLOAD__ERROR: null, // There was an error uploading your file
  NOTIFICATION__FILE__UPLOAD__ERROR__INVALID: null, // Selected file is invalid.
  NOTIFICATION__FILE__UPLOAD__ERROR__TOO_LARGE__FORMAT: null, // {{fileName}} is too large to upload.
  NOTIFICATION__FUNDS__ADDED__ERROR: null, // Failed to add funds to the issuing balance
  NOTIFICATION__FUNDS__ADDED__SUCCESS: null, // Successfully added funds to issuing balance!
  NOTIFICATION__GENERIC_ERROR: null, // Sorry we encountered an issue please try again
  NOTIFICATION__IMAGE__DELETED__ERROR: null, // Failed to delete image. Please try again.
  NOTIFICATION__IMAGE__DELETED__SUCCESS: null, // Image successfully deleted!
  NOTIFICATION__INCENTIVE__FINANCE__DELETED__ERROR: null, // Can't delete the Finance Incentive. Please try again.
  NOTIFICATION__INCENTIVE__FINANCE__DELETED__SUCCESS: null, // The Finance Incentive deleted successfully
  NOTIFICATION__INCENTIVE__TIME__DELETED__ERROR: null, // Can't delete the Time Incentive. Please try again.
  NOTIFICATION__INCENTIVE__TIME__DELETED__SUCCESS: null, // The Time Incentive deleted Successfully
  NOTIFICATION__INITIATIVE__DRAFT__SUCCESS: null, // Draft of Initiative changes saved successfully
  NOTIFICATION__INITIATIVE__PUBLISHED__ERROR: null, // Failed to publish changes. Please try again.
  NOTIFICATION__INITIATIVE__PUBLISHED__SUCCESS: null, // Initiative changes published successfully
  NOTIFICATION__IN_PROGRESS_REMOVAL__ERROR: null, // This is already in progress and cannot be removed
  NOTIFICATION__LICENSE__DETAILS__ERROR: null, // Something went wrong displaying this program's license information. Please try again.
  NOTIFICATION__LICENSE__REMOVED__ERROR: null, // There was an error removing the license
  NOTIFICATION__LICENSE__REMOVED__SUCCESS: null, // License removed successfully
  NOTIFICATION__LICENSE__SAVED__ERROR: null, // There was an error saving the license
  NOTIFICATION__LICENSE__SAVED__SUCCESS: null, // License saved successfully
  NOTIFICATION__LOAD_SKILLS_ERROR: null, // Something went wrong loading skills for this academy
  NOTIFICATION__NOMINATED__SUCCESS: null, // Successfully nominated the employee(s) for this program
  NOTIFICATION__NOMINATION_REMOVED__ERROR: null, // Failed to remove nomination. Please try again.
  NOTIFICATION__NOMINATION_REMOVED__SUCCESS: null, // Successfully removed nomination!
  NOTIFICATION__NUDGES: null, // Nudge Reminders
  NOTIFICATION__NUDGES__DESCRIPTION: null, // Send participants a reminder if they have been inactive for 7 days.
  NOTIFICATION__PERMISSION__ADDED__ERROR: null, // Failed to add user permission. Please try again.
  NOTIFICATION__PERMISSION__ADDED__SUCCESS: null, // User permission successfully added.
  NOTIFICATION__PERMISSION__DELETED__ERROR: null, // Failed to delete user permission. Please try again.
  NOTIFICATION__PERMISSION__DELETED__SUCCESS: null, // User permission successfully deleted.
  NOTIFICATION__PERMISSION__UPDATED__ERROR: null, // Failed to update user permission. Please try again.
  NOTIFICATION__PERMISSION__UPDATED__SUCCESS: null, // User permission successfully updated.
  NOTIFICATION__PLAN__ADDED__ERROR: null, // Can't add to plan. Please try again.
  NOTIFICATION__PLAN__ADDED__SUCCESS: null, // Successfully added to your upskilling plan.
  NOTIFICATION__PLAN__APPLY___ERROR: null, // Can't apply to plan. Please try again.
  NOTIFICATION__PLAN__APPLY___SUCCESS: null, // You have applied to this program!
  NOTIFICATION__PLAN__REMOVED_FROM__ERROR: null, // Can't remove from plan. Please try again.
  NOTIFICATION__PLAN__REMOVED_FROM__SUCCESS: null, // Successfully removed
  NOTIFICATION__PLURAL: null, // Notifications
  NOTIFICATION__PREFERENCE__PLURAL: null, // Set up the notification preferences for this program.
  NOTIFICATION__PROGRAM_COMPLETION__DESCRIPTION: null, // Send participants a notification when they complete the program.
  NOTIFICATION__PROGRAM_START__DESCRIPTION: null, // Send participants an email when their program has started.
  NOTIFICATION__PROGRAM__ADDED_TO_PLAN___ERROR: null, // Cannot add the program to your plan. Please try again.
  NOTIFICATION__PROGRAM__ADDED___SUCCESS: null, // Program successfully added!
  NOTIFICATION__PROGRAM__APPLIED___ERROR: null, // Can't set this program as applied. Please try again.
  NOTIFICATION__PROGRAM__APPROVED___ERROR: null, // Can't set this program as approved. Please try again.
  NOTIFICATION__PROGRAM__APPROVED___SUCCESS: null, // You have been approved for this program!
  NOTIFICATION__PROGRAM__CUSTOM__DUPLICATED__SUCCESS: null, // Custom Program successfully duplicated
  NOTIFICATION__PROGRAM__DELETED__ERROR: null, // Something went wrong deleting this program. Please try again.
  NOTIFICATION__PROGRAM__DELETED__SUCCESS: null, // Program successfully deleted!
  NOTIFICATION__PROGRAM__DUPLICATED__ERROR: null, // Something went wrong duplicating this program. Please try again.
  NOTIFICATION__PROGRAM__PLEASE_SAVE: null, // Please save this program first
  NOTIFICATION__PROGRAM__PUBLISHED__ERROR: null, // Failed to publish program. Please try again.
  NOTIFICATION__PROGRAM__PUBLISHED__SUCCESS: null, // Program successfully published!
  NOTIFICATION__PROGRAM__REQUESTED__SUCCESS: null, // Program requested successfully
  NOTIFICATION__PROGRAM__UPDATED___ERROR: null, // An error occurred updating your program
  NOTIFICATION__PROGRAM__UPDATED___SUCCESS: null, // Program successfully updated!
  NOTIFICATION__PROGRESS__UPDATED__ERROR: null, // Something went wrong updating your progress
  NOTIFICATION__RECOMMEND_EMPLOYEE__ERROR: null, // Something went wrong with recommending the employee(s). Please try again.
  NOTIFICATION__REQUEST__APPROVAL__UPDATED___ERROR: null, // Unable to update the approval request. Please try again.
  NOTIFICATION__REQUEST__APPROVED___SUCCESS: null, // You have approved the request!
  NOTIFICATION__REQUEST__REJECTED___SUCCESS: null, // You have rejected the request.
  NOTIFICATION__REQUEST__TIME__APPROVAL__ERROR: null, // Can't submit your time for approval. Please try again.
  NOTIFICATION__REQUEST__TIME__APPROVAL__SUCCESS: null, // Successfully submitted your time for approval!
  NOTIFICATION__RESET_LINK: null, // We sent a password reset link to your email.
  NOTIFICATION__RESOURCE_REQUEST__ERROR: null, // There was an error processing your request
  NOTIFICATION__RESOURCE_REQUEST__SUCCESS: null, // Resource requested successfully
  NOTIFICATION__REVIEW__ADDED__ERROR: null, // Your review was not added. Please try again or contact support.
  NOTIFICATION__REVIEW__ADDED__SUCCESS: null, // Review successfully added!
  NOTIFICATION__REVIEW__DELETED__ERROR: null, // Your review was not deleted. Please try again or contact support.
  NOTIFICATION__REVIEW__DELETED__SUCCESS: null, // Review successfully deleted.
  NOTIFICATION__REVIEW__UPDATED__ERROR: null, // Your review was not updated. Please try again or contact support.
  NOTIFICATION__REVIEW__UPDATED__SUCCESS: null, // Review successfully updated.
  NOTIFICATION__RSVP__UPDATED__ERROR: null, // Something went wrong updating the RSVP status of this event. Please try again.
  NOTIFICATION__SAVED___SUCCESS: null, // You have saved successfully!
  NOTIFICATION__SEARCH_SKILLS_ERROR: null, // Something went wrong searching skills
  NOTIFICATION__SECTION__ADDED__SUCCESS: null, // Section successfully added!
  NOTIFICATION__SECTION__DELETED__SUCCESS: null, // Section successfully deleted!
  NOTIFICATION__SECTION__UPDATED__SUCCESS: null, // Section successfully updated!
  NOTIFICATION__SETTINGS__UPDATED__FORM___ERROR: null, // Cannot update settings. Please try fixing all the errors on the form.
  NOTIFICATION__SETTINGS__UPDATED___ERROR: null, // Settings failed to update. Please try again.
  NOTIFICATION__SETTINGS__UPDATED___SUCCESS: null, // Settings updated successfully!
  NOTIFICATION__STEP__ADDED__ERROR: null, // There was an error adding academy step
  NOTIFICATION__STEP__ADDED__SUCCESS: null, // Successfully added step
  NOTIFICATION__STEP__DELETED__ERROR: null, // Something went wrong deleting step. Please try again.
  NOTIFICATION__STEP__DUPLICATED__ERROR: null, // Something went wrong duplicating step. Please try again.
  NOTIFICATION__STEP__PLURAL__ADDED__SUCCESS: null, // Successfully added steps
  NOTIFICATION__STEP__UPDATED__ERROR: null, // Something went wrong updating this step. Please try again.
  NOTIFICATION__STEP__UPDATED__SUCCESS: null, // Successfully updated step
  NOTIFICATION__SUBMISSION__SUBMITTED__ERROR: null, // There was an error submitting submission
  NOTIFICATION__SUBMISSION__SUBMITTED__SUCCESS: null, // Submission submitted successfully
  NOTIFICATION__SUCCESS: null, // Success!
  NOTIFICATION__TABLE__LOADED__ERROR__FORMAT: null, // An error occurred loading the {{tableName}} Table data
  NOTIFICATION__TEST__ERROR: null, // Failed to test
  NOTIFICATION__TIME__START_END___ERROR: null, // Start Time cannot be after the End Time.
  NOTIFICATION__TYPE: null, // Notification Type
  NOTIFICATION__UNAVAILABLE__MARKED__SUCCESS: null, // Successfully marked as unavailable!
  NOTIFICATION__UNAVAILABLE__UPDATE__ERROR__FORMAT: null, // Failed to update {{program}} availability! Please try again.
  NOTIFICATION__UPDATED__SUCCESS__FORMAT: null, // {{emailNotificationType}} notification template have been updated.
  NOTIFICATION__UPDATE__ERROR: null, // Something went wrong while updating email notification template. Please try again.
  NOTIFICATION__UPLOAD__MAX_SIZE_EXCEEDED__FORMAT: null, // File is too large for upload {{fileSize}} is greater than maximum {{maxSize}} allowed
  NOTIFICATION__USER__ADDED__ACADEMY__ERROR: null, // Failed to add user. Please try again.
  NOTIFICATION__USER__ADDED__ACADEMY__SUCCESS: null, // Successfully added user to academy!
  NOTIFICATION__USER__ADDED__ERROR: null, // Failed to add user. Please try again.
  NOTIFICATION__USER__ADDED__SUCCESS: null, // User added successfully!
  NOTIFICATION__YOURE_PREAPPROVED: null, // You have been pre-approved!
  NUDGE_EMAIL__EMAIL_PREVIEW_SUBJECT__FORMAT: null, // A note about the {{type}}
  NUDGE_EMAIL__PARTICIPANTS_COUNT__FORMAT: null, // This will be sent to <strong>{{count}}</strong> participants
  NUDGE_EMAIL__PARTICIPANTS_NOT_STARTED: null, // Participants that <strong>have not started</strong>
  OPTION_LABEL__FULL_PAGE_SECTION: null, // Full Page Section
  OPTION_LABEL__STACKED_SECTION: null, // Stacked Section
  OPTION__PLURAL: null, // Options
  OPTION__PLURAL__VIEW: null, // View Options
  PAGINATION__JUMP_NEXT: null, // Next 5 pages
  PAGINATION__JUMP_PREV: null, // Previous 5 pages
  PARTICIPANT: null, // Participant
  PARTICIPANT__PLURAL: null, // Participants
  PARTICIPANT__PROGRAM__EMPTY__DESCRIPTION: null, // Once participants are enrolled project submissions will appear here.
  PARTICIPANT__PROGRAM__EMPTY__TITLE: null, // There are no participants in this program.
  PEOPLE__ACCOUNT_STATUS: null, // Account Status
  PEOPLE__ACTIVE: null, // Active
  PEOPLE__DEACTIVATED: null, // Deactivated
  PEOPLE__LEADERSHIP: null, // Leadership
  PEOPLE__PROFILE_CONFIGURATION: null, // Profile Configuration
  PEOPLE__USER_ROLE: null, // User Role
  PERMISSION: null, // permission
  PERMISSION__NO_CONTRIBUTORS: null, // You haven’t added contributors to this academy.
  PERMISSION__NO_CONTRIBUTORS_CUSTOM_PROGRAM: null, // You haven’t added contributors to this custom program.
  PERMISSION__PLURAL: null, // Permissions
  PERMISSION__PROMPT__REMOVE__FORMAT: null, // Are you sure you want to remove all permissions for {{contributor}}?
  PERMISSION__SELECT: null, // Select Permissions
  PERMISSION__SELECT_CONTRIBUTORS: null, // Select who can review projects view reports and edit this academy.
  PERMISSION__SELECT_CONTRIBUTORS_CUSTOM_PROGRAM: null, // Select who can review projects view reports and edit this custom program.
  PLAN: null, // Plan
  PLAN__ADD_TO: null, // Add to Plan
  PLAN__EMPTY__DESCRIPTION: null, // We can’t wait to see what you learn!
  PLAN__EMPTY__FINANCE: null, // Select a financing option to help pay for your program.
  PLAN__EMPTY__PROGRAM: null, // Select an educational program to start learning.
  PLAN__EMPTY__TIME: null, // Select a time incentive to make time for learning.
  PLAN__EMPTY__TITLE: null, // Anything you complete on Your Plan will appear here.
  PLAN__EXPIRY__EXPIRED_FUNDS_REMOVED: null, // Unspent funds have been removed from your card. Contact your manager or admin for an extension.
  PLAN__EXPIRY__EXPIRED_ON: null, // Expired On
  PLAN__EXPIRY__FUNDS_EXPIRE_ON: null, // Funds Expire On
  PLAN__EXPIRY__LABEL: null, // Purchase On or Before
  PLAN__EXPIRY__TOOLTIP: null, // After this date unspent funds will be removed from your card.
  PLAN__MANAGE_YOUR: null, // Manage Your Plan
  PLAN__YOUR: null, // Your Plan
  PLAN__YOUR__GO_TO: null, // Go To Your Plan
  PLB: null, // Personal Learning Budget
  PLB__ALLOW_REMOVAL: null, // Allow removal of approved programs
  PLB__ALLOW_REQUESTS: null, // Allow employees to request approval for resources
  PLB__AMOUNT_PEOPLE_SPENT: null, // Amount people have spent using their card.
  PLB__APPROVED_NOT_SPENT: null, // Approved funds that have not been spent yet.
  PLB__BUDGET_MINUS_APPROVED: null, // Total budget minus approved funds.
  PLB__BUDGET_REMAINING: null, // Remaining Budget
  PLB__EXCEEDED_FUNDS__FORMAT: null, // Amount cannot exceed the available amount of {{amount}}
  PLB__MAX_APPROVAL: null, // Maximum amount this person can be approved for.
  PLB__NO_INCREASE_ADVANCE: null, // Your balance will not increase until your advance has been covered.
  PLB__ONLY_ONE: null, // Only one learning budget can be created per company and will automatically be added to employee’s plans.
  PLB__OUT_OF_FUNDS: null, // Your personal learning budget has no funds available.
  PLB__TITLE: null, // Explore Programs to Get Started
  PLB__VALIDATION__BE_MORE_THAN_APPROVED__FORMAT: null, // Amount must be more than the approved amount
  PLB__VALIDATION__BE_MORE_THAN_SPENT__FORMAT: null, // Amount must be more than the spent amount
  PLB__VALIDATION__EQUAL_OR_LESS_BUDGET__FORMAT: null, // Amount must be equal to or less than the remaining budget
  PLB__VALIDATION__RENEWAL_MONTH_REQUIRED: null, // Renewal month selection required
  PLB__YOUR_BALANCE: null, // Your Balance
  PREPAYMENT__ADD_DEFAULT_ACCOUNT: null, // Add Default Account
  PREPAYMENT__CONNECTED_ACCOUNT: null, // Connected Bank Account
  PREPAYMENT__CONNECT_BANK: null, // Connect a Bank Account
  PREPAYMENT__CONNECT_BANK__DESCRIPTION: null, // Add an account to start issuing cards.
  PREPAYMENT__LINK__TRANSIENT: null, // The onboarding form is a short-lived URL that can only be used once.<br />Please only visit the onboarding form when you are ready to complete all necessary details.
  PREPAYMENT__LINK__USED: null, // Your onboarding link has already been used.<br />If you need to add information to your onboarding details please contact your customer success representative.
  PREPAYMENT__ONBOARDING: null, // Onboarding
  PREPAYMENT__ONBOARDING__FORM: null, // Onboarding Form
  PROGRAM: null, // Program
  PROGRAM_FINDER_VIEW_PROGRAMS__FORMAT: null, // View {{amount}} Programs
  PROGRAM_FINDER_VIEW_PROGRAM__FORMAT: null, // View {{count}} Program
  PROGRAM_FINDER__BUDGET: null, // Budget
  PROGRAM_FINDER__BUDGET__OVER_BUDGET__FORMAT: null, // This amount is {{amount}} over your remaining budget.
  PROGRAM_FINDER__BUDGET__SUBTITLE__FORMAT: null, // You currently have {{budget}} remaining in your Personal Learning Budget.
  PROGRAM_FINDER__BUDGET__TITLE: null, // How much would you like to spend?
  PROGRAM_FINDER__BUTTON__LETS_GO: null, // Let's go!
  PROGRAM_FINDER__DESCRIPTION: null, // In a few simple steps we'll help you find the right program for you. Get started by telling us what you are looking for.
  PROGRAM_FINDER__DESCRIPTION__ALT: null, // Let us help you find the perfect program for your learning journey.
  PROGRAM_FINDER__GREETING__FORMAT: null, // Hi {{firstName}}! How’s it going?
  PROGRAM_FINDER__LAUNCHER_MARKETPLACE: null, // Can't find the right Program?
  PROGRAM_FINDER__LAUNCHER_MARKETPLACE_DESCRIPTION: null, // Let us guide you to the perfect program for you.
  PROGRAM_FINDER__NO_RESULTS: null, // We didn’t find any results.
  PROGRAM_FINDER__NO_RESULTS__SUBTITLE: null, // Speak to one of our advisors they can help you recommend a program that you’re looking for that best fit your needs.
  PROGRAM_FINDER__NO_RESULTS__TITLE: null, // Can’t Find what you’re looking for?
  PROGRAM_FINDER__PATH__BROWSE: null, // I'd like to browse
  PROGRAM_FINDER__PATH__COACH: null, // I want to find a coach
  PROGRAM_FINDER__PATH__PROGRAMS: null, // I want to find a specific program
  PROGRAM_FINDER__PATH__SKILLS: null, // I want to develop a specific skill
  PROGRAM_FINDER__PROGRAM_TYPE: null, // Program Type
  PROGRAM_FINDER__PROGRAM__CAROUSEL__EMPTY__PROGRAM: null, // You haven't added any programs. We can help you find one.
  PROGRAM_FINDER__SKILLS: null, // Skills
  PROGRAM_FINDER__SKILL__EXAMPLE: null, // Ex. Design
  PROGRAM_FINDER__SUBTITLE__PROGRAMS: null, // Search for a program you’re interested in.
  PROGRAM_FINDER__SUBTITLE__SKILLS: null, // Search for skills you’re interested in building.
  PROGRAM_FINDER__TITLE: null, // Program Finder
  PROGRAM_FINDER__TITLE__PROGRAMS: null, // What program are you looking for?
  PROGRAM_FINDER__TITLE__SKILLS: null, // What skill do you want to develop?
  PROGRAM_FINDER__TRY__SUBTITLE: null, // Find the right program for you.
  PROGRAM_FINDER__TRY__TITLE: null, // Try our Program Finder
  PROGRAM_FINDER__TYPE__BOOTCAMPS_AVAILABLE__FORMAT: null, // {{amount}} Bootcamps Available
  PROGRAM_FINDER__TYPE__BOOTCAMP_AVAILABLE__FORMAT: null, // {{amount}} Bootcamp Available
  PROGRAM_FINDER__TYPE__COURSES_AVAILABLE__FORMAT: null, // {{amount}} Courses Available
  PROGRAM_FINDER__TYPE__COURSE_AVAILABLE__FORMAT: null, // {{amount}} Course Available
  PROGRAM_FINDER__TYPE__DEGREES_AVAILABLE__FORMAT: null, // {{amount}} Degrees Available
  PROGRAM_FINDER__TYPE__DEGREE_AVAILABLE__FORMAT: null, // {{amount}} Degree Available
  PROGRAM_FINDER__TYPE__SUBTITLE: null, // Select all that apply.
  PROGRAM_FINDER__TYPE__TITLE__ALT__FORMAT: null, // Here are the program results we found for {{skill}}
  PROGRAM_FINDER__TYPE__TITLE__FORMAT: null, // What type of {{skill}} program are you looking for?
  PROGRAM_FINDER__TYPE__WITHIN_BUDGET__FORMAT: null, //  {{amount}} Within Your Budget
  PROGRAM_FINDER__WELCOME: null, // Welcome to the Program Finder
  PROGRAM_LANGUAGE: null, // Program Language
  PROGRAM__ACCESS: null, // Access Program
  PROGRAM__ADD: null, // Add Program
  PROGRAM__ADD_TO_SECTION: null, // Add to Section
  PROGRAM__ADD__EXISTING: null, // Add an Existing Program
  PROGRAM__ADD__EXTERNAL_FROM_LEARN_IN: null, // Add external programs from the marketplace.
  PROGRAM__ADD__FROM_DIFF: null, // Add programs from different providers for your team to browse.
  PROGRAM__ADD__NEW: null, // Add New Program
  PROGRAM__ADD__PROGRAM: null, // Add a program
  PROGRAM__BUILD_CUSTOM__TITLE: null, // Build a Custom Program
  PROGRAM__CAROUSEL_TITLE: null, // Credible Programs
  PROGRAM__CAROUSEL__EMPTY__FINANCE: null, // You haven’t added any finance incentives. Here are a few to consider
  PROGRAM__CAROUSEL__EMPTY__PROGRAM: null, // You haven't added any programs. We found a few you might enjoy.
  PROGRAM__CAROUSEL__EMPTY__TIME: null, // You haven’t added any time incentives. Here are a few to consider
  PROGRAM__CHOOSE: null, // Choose a program
  PROGRAM__COMPLETED_ON__FORMAT: null, // Completed Program on {{date}}
  PROGRAM__COMPLETED__DESCRIPTION: null, // You will still be able to access this program in the “Completed” tab on Your Plan.
  PROGRAM__COMPLETED__TITLE: null, // Congratulations! You completed the program!
  PROGRAM__COMPLETION: null, // Program Completion
  PROGRAM__COST: null, // Program Cost
  PROGRAM__COST_IN_CURRENCY: null, // Total Program Cost
  PROGRAM__COST_IN_CURRENCY__FORMAT: null, // Total Program Cost (in {{currency}})
  PROGRAM__COST_REQUIRED: null, // Program Cost Required
  PROGRAM__CRITICAL_SKILL__DESCRIPTION: null, // This program can help you learn a critical skill.
  PROGRAM__CUSTOM: null, // Custom Program
  PROGRAM__CUSTOM__ADD_PROGRAMS_BY: null, // Add custom programs created by your company.
  PROGRAM__CUSTOM__ADD__DESCRIPTION: null, // Create a program that fits your needs.
  PROGRAM__CUSTOM__CURRICULUM: null, // Curriculum
  PROGRAM__CUSTOM__CURRICULUM__CONTENT__ADD: null, // Add custom content like a link file etc.
  PROGRAM__CUSTOM__DELETE_CONFIRM: null, // Are you sure you want to delete this program? All cohorts will be deleted and participants will no longer have access to the program.
  PROGRAM__CUSTOM__EMPTY__DESCRIPTION: null, // It looks like this section doesn’t have any content.
  PROGRAM__CUSTOM__EMPTY__TITLE: null, // This section is empty.
  PROGRAM__CUSTOM__GET_STARTED: null, // Get started by adding content.
  PROGRAM__CUSTOM__LIST__EMPTY__DESCRIPTION: null, // Custom programs that can be added to an academy will appear here.
  PROGRAM__CUSTOM__LIST__EMPTY__TITLE: null, // You haven’t created any custom programs.
  PROGRAM__CUSTOM__NO_CURRICULUM: null, // Once you add content you will be able to view and edit your curriculum here.
  PROGRAM__CUSTOM__PARENT: null, // Parent Program
  PROGRAM__CUSTOM__PEER__VISIBILITY: null, // Allow participants to see all submitted projects.
  PROGRAM__CUSTOM__PLURAL: null, // Custom Programs
  PROGRAM__CUSTOM__SELECT: null, // Select Custom Programs
  PROGRAM__CUSTOM__SETUP__AVAILABILITY: null, // Control who can see and participate in this program
  PROGRAM__CUSTOM__SETUP__INFO: null, // Tell us a little bit about this program
  PROGRAM__CUSTOM__SHARE: null, // Share Custom Program
  PROGRAM__CUSTOM__SUBMISSION_TYPE: null, // Submission Type
  PROGRAM__CUSTOM__VIEW__FORMAT: null, // View Custom Program {{item}}
  PROGRAM__DELETE: null, // Delete Program
  PROGRAM__DELETE__PROMPT: null, // Are you sure you want to delete this program?
  PROGRAM__DESCRIPTION: null, // Program Description
  PROGRAM__DESCRIPTION__SHORT: null, // Short Program Description
  PROGRAM__DETAILS: null, // Program Details
  PROGRAM__DETAILS__STAR__REVIEW__FORMAT: null, // Star rating
  PROGRAM__DETAILS__VIEW: null, // View Program Details
  PROGRAM__DUPLICATE__VERB: null, // Duplicate Program
  PROGRAM__DURATION: null, // Program Duration
  PROGRAM__EDIT: null, // Edit Program
  PROGRAM__EST_PROGRAM_COST: null, // Est. Program Cost
  PROGRAM__FEATURED: null, // Featured Programs
  PROGRAM__FILTER_SEARCH: null, // Search and filter programs
  PROGRAM__FULL_TIME: null, // Full Time
  PROGRAM__GO_TO: null, // Go To Program
  PROGRAM__IMAGE: null, // Program Image
  PROGRAM__INCENTIVE__PLURAL: null, // Program Incentives
  PROGRAM__JOIN: null, // Join Program
  PROGRAM__LENGTH: null, // Program Length
  PROGRAM__LENGTH_UNIT: null, // Program Length Unit
  PROGRAM__MANAGE_YOUR: null, // Manage Your Program
  PROGRAM__MARKETPLACE: null, // Marketplace Program
  PROGRAM__MARKETPLACE__ADD__NON_TITLE: null, // Add marketplace program
  PROGRAM__MARKETPLACE__PLURAL: null, // Marketplace Programs
  PROGRAM__MARKETPLACE__SELECT: null, // Select Marketplace Programs
  PROGRAM__MARKETPLACE__SIMPLE: null, // Marketplace
  PROGRAM__MY_COMPANYS: null, // My Company's Programs
  PROGRAM__NAME: null, // Program Name
  PROGRAM__NEW: null, // New Program
  PROGRAM__NEW_REQUEST__DESCRIPTION: null, // You have the ability to request a variety of learning resources.
  PROGRAM__NEW_REQUEST__TITLE: null, // Know what you want to learn?
  PROGRAM__NOMINATED__DESCRIPTION: null, // Someone has nominated you for a program! Check out the details below.
  PROGRAM__NOMINATED__PLURAL: null, // Nominated Programs
  PROGRAM__NO_SUBMITTED_PROGRESS: null, // Submitted progress will be displayed here. You have no submitted progress yet.
  PROGRAM__OTHER: null, // Other Programs
  PROGRAM__PART_FULL_TIME: null, // Part Time / Full Time
  PROGRAM__PART_TIME: null, // Part Time
  PROGRAM__PLURAL: null, // Programs
  PROGRAM__PLURAL__APPROVE: null, // Approve Programs
  PROGRAM__PLURAL__EXPLORE: null, // Explore Programs
  PROGRAM__PLURAL__MANAGE: null, // Manage Programs
  PROGRAM__PLURAL__SEARCH: null, // Search Programs
  PROGRAM__PLURAL__VIEW: null, // View Programs
  PROGRAM__PLURAL__VIEW_MORE: null, // View More Programs
  PROGRAM__PRESENCE_TYPE: null, // Presence Type
  PROGRAM__PRESENCE_TYPE__CLASSROOM: null, // Classroom
  PROGRAM__PRESENCE_TYPE__ONLINE: null, // Online
  PROGRAM__PREVIEW__VERB: null, // Preview Program
  PROGRAM__PROGRESS: null, // Program Progress
  PROGRAM__PROVIDER: null, // Program Provider
  PROGRAM__PROVIDER__SELECT_OR_ADD: null, // Select provider or enter a new one
  PROGRAM__PUBLISH: null, // Publish Program
  PROGRAM__PUBLISH_WARNING: null, // An Invitation email will be sent to participants once published. Are you sure you want to publish this program?
  PROGRAM__PUBLISH_WARNING__FORMAT: null, // An Invitation email will be sent to <strong>{{count}}</strong> participants once published. Are you sure you want to publish this program?
  PROGRAM__RECOMMENDED: null, // Recommended Programs
  PROGRAM__REMOVE: null, // Remove Program
  PROGRAM__REMOVE_FROM_PLAN: null, // Remove program from your plan
  PROGRAM__REMOVE__CONFIRM: null, // Are you sure you want to remove this program from Your Plan?
  PROGRAM__REMOVE__CONFIRMED: null, // Yes Remove Program
  PROGRAM__REMOVE__WARN_VC_DEDUCTION__FORMAT: null, // This option will <strong>remove {{localizedAmount}} from your virtual card</strong>.
  PROGRAM__REQUEST: null, // Request Program
  PROGRAM__REQUIRED: null, // Program Required
  PROGRAM__REVIEW: null, // Program review
  PROGRAM__REVIEW__DESCRIBE_EXPERIENCE: null, // Describe your experience with this program
  PROGRAM__REVIEW__HAVE_COMPLETED: null, // Have you completed this program?
  PROGRAM__REVIEW__HOW_RATE: null, // How would you rate this program?
  PROGRAM__REVIEW__PLURAL: null, // Program Reviews
  PROGRAM__REVIEW__TITLE: null, // Review Title
  PROGRAM__SAVE: null, // Save Program
  PROGRAM__SEARCH: null, // Search Program
  PROGRAM__SEARCH_PROGRAMS_OR_PROVIDERS: null, // Search Programs or Providers
  PROGRAM__SEE_OTHER: null, // See Other Programs
  PROGRAM__SELECT: null, // Select Program
  PROGRAM__SELECTED__NONE: null, // No programs selected
  PROGRAM__SELECTED__PLURAL__FORMAT: null, // <strong>{{number}}</strong> programs selected
  PROGRAM__SELECTED__SINGLE: null, // <strong>1</strong> program selected
  PROGRAM__SKIP: null, // Skip Program
  PROGRAM__START__NOUN: null, // Program Start
  PROGRAM__START__VERB: null, // Start Program
  PROGRAM__STATUS: null, // Program Status
  PROGRAM__TABLE__EMPTY__DESCRIPTION: null, // We couldn’t find a program that matches try updating your search.
  PROGRAM__TITLE: null, // Program Title
  PROGRAM__TOTAL_COST_IN_CURRENCY: null, // Total Cost
  PROGRAM__TOTAL_COST_IN_CURRENCY__FORMAT: null, // Total Cost (in {{currency}})
  PROGRAM__TYPE: null, // Program Type
  PROGRAM__UNSKIP: null, // Unskip Program
  PROGRAM__URL: null, // Program URL
  PROGRAM__VALIDATION__CONTENT: null, // Please input the content
  PROGRAM__VIEW: null, // View Program
  PROGRAM__VIEW_ELIGIBLE: null, // View Eligible Programs
  PROGRAM__VISIBLE_COMPANY: null, // This review will be visible to everyone in your company.
  PROGRAM__WEBSITE: null, // Program Website
  PROGRAM__WEBSITE__VIEW: null, // View Program Website
  PROGRAM__WHAT_TYPE: null, // What type of program is this?
  PROGRESS: null, // Progress
  PROGRESS__HOW_FEELING: null, // How are you feeling?
  PROGRESS__PROVIDE_NOTES: null, // Let your manager and company know how your program is going. Please provide a few sentences detailing what you’re learning how you are feeling about your program and if there is anything your manager could be doing to help support you.
  PROGRESS__SKILLS_YOU_LEARNED: null, // Skills you learned
  PROGRESS__STATUS__FEELING_GREAT: null, // Feeling Great
  PROGRESS__STATUS__MAKING_PROGRESS: null, // Making Progress
  PROGRESS__STATUS__NEED_HELP: null, // Need one-on-one guidance?
  PROGRESS__STATUS__STRESSED: null, // Stressed
  PROGRESS__STATUS__TOO_BUSY: null, // Too Busy
  PROGRESS__TAKE_NOTE: null, // Take note of your progress
  PROGRESS__TOTAL_COMPLETED: null, // Total Completed
  PROGRESS__TOTAL_OUTSTANDING: null, // Total Outstanding
  PROGRESS__UPDATES: null, // Progress Updates
  PROGRESS__UPDATES__DESCRIPTION: null, // Let your manager and company know how your program is going.
  PROGRESS__UPDATES__TITLE: null, // Take note of your progress
  PROGRESS__YOUR_PROGRESS: null, // Your progress
  PROJECT: null, // Project
  PROJECT__ANSWER_PROMPT: null, // Write an answer to the project prompt
  PROJECT__DELETE: null, // Delete Project
  PROJECT__DELETE__PROMPT: null, // Are you sure you want to delete this project?
  PROJECT__DUPLICATE__VERB: null, // Duplicate Project
  PROJECT__EDIT: null, // Edit Project
  PROJECT__NEW: null, // New Project
  PROJECT__PLURAL: null, // Projects
  PROJECT__REVIEWER__PLURAL: null, // Project Reviewers
  PROJECT__REVIEW__PLURAL: null, // Review Projects
  PROJECT__SAVE: null, // Save Project
  PROJECT__SKIP: null, // Skip Project
  PROJECT__SUBMIT_FILE__FORMAT: null, // Submit uploaded file for {{item}}
  PROJECT__SUBMIT_TEXT__FORMAT: null, // Submit written response for {{item}}
  PROJECT__SUBMIT_URL__FORMAT: null, // Submit URL for {{item}}
  PROJECT__UNSKIP: null, // Unskip Project
  PROJECT__YOUR: null, // Your Project
  PROMPT__DELETE: null, // Are you sure you want to delete this?
  PROMPT__DELETE_ITEM_FRIENDLY__FORMAT: null, // Are you sure you want to delete this {{item}}?
  PROMPT__DELETE_ITEM_YES__FORMAT: null, // Yes Delete {{item}}
  PROMPT__DELETE_ITEM__FORMAT: null, // Delete {{item}}?
  PROMPT__GENERIC_CHOICE: null, // What would you like to do?
  PROMPT__REMOVE: null, // Are you sure you want to remove this?
  PROMPT__REMOVE_ACADEMY_YES: null, // Yes Remove Academy
  PROMPT__REMOVE_CONTRIBUTOR_YES: null, // Yes Remove Contributor
  PROMPT__REMOVE_ITEM_FRIENDLY__FORMAT: null, // Are you sure you want to remove this {{item}}?
  PROMPT__REMOVE_ITEM__FORMAT: null, // Remove {{item}}?
  PROMPT__REMOVE_PERMISSIONS_USER__FORMAT: null, // Are you sure you want to remove all permissions for {{user}}?
  PROVIDER: null, // Provider
  PROVIDER__CONTACT__VERB: null, // Contact Provider
  PROVIDER__PLURAL: null, // Provider
  QA: null, // QA
  QUILL__ENTER_EDITOR: null, // Press \Enter\ to enter the text editor
  REIMBURSEMENT: null, // Reimbursement
  REIMBURSEMENT__AMOUNT_PENDING: null, // Amount Pending Reimbursement
  REIMBURSEMENT__APPROVED_BY: null, // Reimbursements Approved By
  REIMBURSEMENT__COMPANY: null, // Reimbursement
  REIMBURSEMENT__EMPTY_SUBMISSIONS: null, // Submitted reimbursements will be displayed here. You have no submitted reimbursements yet.
  REIMBURSEMENT__EXCEEDS_BALANCE: null, // Requested amount exceeds balance
  REIMBURSEMENT__EXCEEDS_DESCRIPTION__FORMAT: null, // Your reimbursements exceed the estimated program cost by {{localizedAmount}}
  REIMBURSEMENT__EXTERNAL: null, // External Reimbursement (Integration Required)
  REIMBURSEMENT__FUNDS_REMAINING: null, // Reimbursement Funds Remaining
  REIMBURSEMENT__NEW_REQUEST: null, // New Reimbursement Request
  REIMBURSEMENT__PENDING: null, // Pending Reimbursement
  REIMBURSEMENT__PLURAL: null, // Reimbursements
  REIMBURSEMENT__PLURAL__MANAGE: null, // Manage Reimbursements
  REIMBURSEMENT__PRE: null, // Reimbursements Pre-Approval
  REIMBURSEMENT__REIMBURSABLE: null, // Reimbursable
  REIMBURSEMENT__REIMBURSED: null, // Reimbursed
  REIMBURSEMENT__REIMBURSED_AMOUNT: null, // Reimbursed Amount
  REIMBURSEMENT__REMOVE_PROGRAM: null, // Are you sure you want to remove this program?<br/> Any pending requests will be removed as well.
  REIMBURSEMENT__REMOVE_RESOURCE: null, // Are you sure you want to remove this resource?<br/> Any pending requests will be removed as well.
  REIMBURSEMENT__STATUS__APPROVED: null, // Reimbursement Approved
  REIMBURSEMENT__STATUS__NEEDS_REVIEW: null, // Reimbursement Needs Review
  REIMBURSEMENT__STATUS__PAID: null, // Reimbursement Paid
  REIMBURSEMENT__STATUS__REDEMPTION: null, // Redemption
  REIMBURSEMENT__STATUS__SUBMITTED: null, // Reimbursement Submitted
  REIMBURSEMENT__SUBMIT: null, // Submit Reimbursement
  REIMBURSEMENT__SUBMITTED_SUCCESS: null, // Successfully submitted reimbursement
  REIMBURSEMENT__TAKE_A_WHILE: null, // It may take a while to appear in your account.
  REIMBURSEMENT__YOUR_LIMIT: null, // Your Reimbursement Limit
  REPORT: null, // Report
  REPORT__ACTIVATED_ACCOUNT__PLURAL: null, // Activated accounts
  REPORT__DETAILS: null, // Report Details
  REPORT__EMPLOYEE_ACTIVITY: null, // Employee Activity
  REPORT__EMPLOYEE_ACTIVITY__EMPTY: null, // There hasn’t been much activity.
  REPORT__EMPLOYEE__ENGAGEMENT__SUMMARY: null, // As employees use their virtual cards you will see a summary here.
  REPORT__GENERIC_SKILL: null, // This is some generic skill that I have
  REPORT__LOGGED_IN: null, // Logged in
  REPORT__PLURAL: null, // Reports
  REPORT__PLURAL__VIEW: null, // View Reports
  REPORT__POPULAR_PROGRAM__LACKING_DATA: null, // As more employees enroll in programs you will see the most popular ones.
  REPORT__POPULAR_PROGRAM__NO_ENROLLMENTS: null, // No one has enrolled in a program.
  REPORT__POPULAR_PROGRAM__NO_ENROLLMENTS__DESCRIPTION: null, // You can find program status and activity information here once people are enrolled.
  REPORT__POPULAR_PROGRAM__PLURAL: null, // Popular Programs
  REPORT__PROGRESS__LACKING_DATA: null, // As more employees submit progress updates you will see a summary of their responses.
  REPORT__PROGRESS__NO_SUBMISSIONS: null, // No one has submitted a progress update.
  REPORT__USED_VC: null, // Used virtual card
  REQUEST: null, // Request
  REQUEST__APPROVAL: null, // Request Approval
  REQUEST__FIND_PROGRAM__SUBTITLE: null, // Find a program in Learn In's curated marketplace
  REQUEST__FIND_PROGRAM__TITLE: null, // Find a Program
  REQUEST__NEW__BOOK__SUBTITLE: null, // Get a wide variety of book materials such as textbooks to support your learning.
  REQUEST__NEW__BROWSE_MARKETPLACE__FORMAT: null, // You can also browse programs in our {{marketplaceLink}}.
  REQUEST__NEW__CONFERENCE__SUBTITLE: null, // Attend a conference or workshop to learn from industry professionals.
  REQUEST__NEW__OTHER__SUBTITLE: null, // Enhance your learning with a new resource that is not yet included in our offerings.
  REQUEST__NEW__OTHER__TITLE: null, // Other Resource
  REQUEST__NEW__PROGRAM__SUBTITLE: null, // Request approval to purchase a program such as a course degree or bootcamp.
  REQUEST__NEW__SELECT: null, // Select the type of learning resource you’d like to request
  REQUEST__NEW__SUBSCRIPTION__SUBTITLE: null, // Unlock your full potential with subscription based learning.
  REQUEST__PLURAL: null, // Requests
  REQUEST__PROGRAM__SUBTITLE: null, // Request approval to purchase a program such as a course degree or bootcamp.
  REQUEST__PROGRAM__TITLE: null, // Request a Program
  REQUEST__RESOURCE__SUBTITLE: null, // Request books supplies classes experiences etc.
  REQUEST__RESOURCE__TITLE: null, // Request Resource
  REQUEST__RESUBMIT: null, // Resubmit Request
  RESOURCE: null, // Resource
  RESOURCE__BOOK: null, // Book
  RESOURCE__CONFERENCE: null, // Conference
  RESOURCE__PLURAL: null, // Resources
  RESOURCE__REMOVE: null, // Remove Resource
  RESOURCE__REMOVE__CONFIRMED: null, // Yes Remove Resource
  RESOURCE__SUBSCRIPTION: null, // Subscription
  REVIEW: null, // Review
  REVIEW__ADD: null, // Leave a Review
  REVIEW__DELETE: null, // Delete Review
  REVIEW__DELETE__PROMPT: null, // Are you sure you want to delete this review?
  REVIEW__DETAIL__PLURAL: null, // Review Details
  REVIEW__EDIT: null, // Edit Review
  REVIEW__PLURAL: null, // Reviews
  REVIEW__SUBMIT: null, // Submit Review
  REVIEW__UPDATE: null, // Update Review
  SABBATICAL__DESCRIPTION: null, // Unilever understands the significance of having employees who are leaders in their field and endeavors to encourage innovation and creativity in our employees to ensure that Unilever remains a leader of cutting-edge products in our industry. It is important that company employees who are responsible for innovation are given time to learn and gain new skills so that new ideas can continue to be generated. It is the policy of Unilever to allow eligible employees to take paid sabbatical leave to focus on education for a specified period of time. Employees eligible for sabbatical leave will be held accountable for the activities or materials they choose to conduct or create during this leave. All intellectual property created during an employee's sabbatical leave will be deemed property of Unilever. Exempt-level professional and creative employees working in designated \innovative\ areas such as product development research and design may apply for a sabbatical leave after 2 years of continuous full-time service. These employees may apply to take sabbatical leave for 3 weeks at full pay or 1 - 9 months at 50% of pay. After an employee has completed sabbatical leave he or she is not permitted to reapply for sabbatical leave for a period of 1 year following completion of an employee's last sabbatical. Employees on approved sabbatical leave will receive pay and continued benefits during the period of leave.
  SABBATICAL__LEAVE_GUIDELINES: null, // Sabbatical Leave Guidelines
  SABBATICAL__SALARY_BENEFITS: null, // Salary & Benefits
  SECTION: null, // Section
  SECTION__ADD: null, // Add Section
  SECTION__COMPLETED: null, // Completed Section
  SECTION__CURRENT: null, // Current Section
  SECTION__DELETE: null, // Delete Section
  SECTION__DELETE_PERMANENTLY: null, // Delete content in this section - this cannot be undone
  SECTION__DELETE__PROMPT: null, // Are you sure you want to delete this section?
  SECTION__DELETE__TITLE: null, // Select Section
  SECTION__DESCRIPTION: null, // Section Description
  SECTION__IN_PROGRESS: null, // In Progress Section
  SECTION__MOVE_TO: null, // Move to Section
  SECTION__MOVE_TO_DIFFERENT: null, // Move content to a different section
  SECTION__MOVE_WHERE: null, // Where do you want to move your content?
  SECTION__NEW: null, // New Section
  SECTION__NEXT__GO_TO: null, // Go To Next Section
  SECTION__PLURAL: null, // Sections
  SECTION__PROGRESS: null, // Section Progress
  SECTION__RENAME: null, // Rename Section
  SECTION__REQUIRES_ACCESS__FORMAT: null, // What section is required to access {{levelTitle}}?
  SECTION__SELECT: null, // Select Section
  SECTION__TITLE: null, // Section Title
  SECTION__TO_BOTTOM: null, // Move Section to Bottom
  SECTION__TO_TOP: null, // Move Section to Top
  SECTION__UNSTARTED: null, // Unstarted Section
  SETTING__ADMIN_APPROVAL_REQUESTS: null, // Admin Approval Requests
  SETTING__ADVANCED__PLURAL: null, // Advanced Settings
  SETTING__ALLOW_EMAIL: null, // Allow Email
  SETTING__APPLICATION_NOTIFICATIONS: null, // Approval Request Status
  SETTING__CURRENCY: null, // Currency
  SETTING__CURRENCY_PREFERENCE: null, // Select a currency preference
  SETTING__DEFAULT_CONFIGURATION: null, // Default Configuration
  SETTING__DUE_DATE_REMINDERS: null, // Due Date Reminders
  SETTING__EMAIL_PREFERENCES: null, // Email Preferences
  SETTING__EVENT_REMINDERS: null, // Event Reminders
  SETTING__LANGUAGE: null, // Language
  SETTING__LANGUAGE_PREFERENCE: null, // Select a language preference
  SETTING__MANAGER_APPROVAL_REQUESTS: null, // Manager Approval Requests
  SETTING__PERMISSIONS_UPDATES: null, // Added as a Contributor
  SETTING__PLURAL: null, // Settings
  SETTING__PROFILE: null, // Profile
  SETTING__PROGRAM_ACCESS: null, // Program Access
  SETTING__PROGRAM_NOMINATIONS: null, // Program Nominations
  SETTING__PROGRAM_NUDGE_REMINDERS: null, // Nudge Reminders
  SETTING__PROJECT_FEEDBACK: null, // Project Feedback
  SETTING__PROJECT_SUBMISSION: null, // Project Submissions
  SKILL_ERROR: null, // Only 5 skills allowed.
  SKILL_TIP: null, // Tip
  SKILL__5G_STRATEGY: null, // 5G Strategy
  SKILL__ACCOUNTING: null, // Accounting
  SKILL__AGILE: null, // Agile
  SKILL__AI: null, // AI
  SKILL__ANALYTICS: null, // Analytics
  SKILL__APP_DESIGN: null, // App Design
  SKILL__ARTIFICIAL_INTELLIGENCE: null, // Artificial Intelligence
  SKILL__AWS: null, // AWS
  SKILL__BIG_DATA: null, // Big Data
  SKILL__BLOCKCHAIN: null, // Blockchain
  SKILL__BOOSTING_SELF_CONFIDENCE: null, // Boosting Self Confidence
  SKILL__BUSINESS_ADMINISTRATION: null, // Business Administration
  SKILL__BUSINESS_ANALYTICS: null, // Business Analytics
  SKILL__BUSINESS_ECONOMICS: null, // Business Economics
  SKILL__BUSINESS_MANAGEMENT: null, // Business Management
  SKILL__BUSINESS_STRATEGY: null, // Business Strategy
  SKILL__CAREER_DEVELOPMENT: null, // Career Development
  SKILL__CHANGE_MANAGEMENT: null, // Change Management
  SKILL__CLOUD_COMPUTING: null, // Cloud Computing
  SKILL__COACHING: null, // Coaching
  SKILL__COMMUNICATION: null, // Communication
  SKILL__CONSULTING: null, // Consulting
  SKILL__CSS: null, // CSS
  SKILL__CUSTOMER_SUCCESS: null, // Customer Success
  SKILL__CYBERSECURITY: null, // Cybersecurity
  SKILL__C__: null, // C++
  SKILL__DATABASE_MANAGEMENT: null, // Database Management
  SKILL__DATA_ANALYSIS: null, // Data Analysis
  SKILL__DATA_ANALYTICS: null, // Data Analytics
  SKILL__DATA_ENGINEERING: null, // Data Engineering
  SKILL__DATA_SCIENCE: null, // Data Science
  SKILL__DATA_VISUALIZATION: null, // Data Visualization
  SKILL__DEEP_LEARNING: null, // Deep Learning
  SKILL__DEIB: null, // DEIB
  SKILL__DESIGN: null, // Design
  SKILL__DIGITAL_MARKETING: null, // Digital Marketing
  SKILL__DIGITAL_MEDIA: null, // Digital Media
  SKILL__DIVERSITY_EQUITY_INCLUSION: null, // Diversity/Equity/Inclusion
  SKILL__DIVERSITY_EQUITY_INCLUSION_BELONGING: null, // Diversity/Equity/Inclusion/Belonging
  SKILL__DIVERSITY___INCLUSION: null, // Diversity & Inclusion
  SKILL__DJANGO: null, // Django
  SKILL__EDUCATION: null, // Education
  SKILL__ENGINEERING: null, // Engineering
  SKILL__ENGLISH_LANGUAGE: null, // English Language
  SKILL__E_COMMERCE: null, // E-Commerce
  SKILL__FINANCE: null, // Finance
  SKILL__FINANCIAL_FORECASTING: null, // Financial Forecasting
  SKILL__FINTECH: null, // FinTech
  SKILL__FRAMEWORKS: null, // Frameworks
  SKILL__FRENCH_LANGUAGE: null, // French Language
  SKILL__FRONT_END_WEB_DEVELOPMENT: null, // Front-End Web Development
  SKILL__FULL_STACK_WEB_DEVELOPMENT: null, // Full Stack Web Development
  SKILL__GIT: null, // Git
  SKILL__GOOGLE: null, // Google
  SKILL__HEALTHCARE: null, // Healthcare
  SKILL__HOSPITALITY: null, // Hospitality
  SKILL__HR: null, // HR
  SKILL__HTML: null, // HTML
  SKILL__HUMAN_RESOURCES: null, // Human Resources
  SKILL__INFORMATION_SECURITY: null, // Information Security
  SKILL__INFORMATION_TECHNOLOGY: null, // Information Technology
  SKILL__IT: null, // IT
  SKILL__JAVASCRIPT: null, // JavaScript
  SKILL__KANBAN: null, // Kanban
  SKILL__LEADERSHIP: null, // Leadership
  SKILL__LEAN_SIX_SIGMA: null, // Lean Six Sigma
  SKILL__LINUX: null, // Linux
  SKILL__MACHINE_LEARNING: null, // Machine Learning
  SKILL__MANAGEMENT: null, // Management
  SKILL__MARKETING: null, // Marketing
  SKILL__MARKETING_ANALYSIS: null, // Marketing Analysis
  SKILL__MARKETING_ANALYTICS: null, // Marketing Analytics
  SKILL__MICROSOFT_AZURE: null, // Microsoft Azure
  SKILL__NODE_JS: null, // Node.js
  SKILL__OFFICE_PRODUCTIVITY: null, // Office Productivity
  SKILL__OPENSTACK: null, // OpenStack
  SKILL__PERSONAL___PROFESSIONAL_PRODUCTIVITY: null, // Personal & Professional Productivity
  SKILL__PHARMACY: null, // Pharmacy
  SKILL__PRODUCT_MANAGEMENT: null, // Product Management
  SKILL__PROJECT_MANAGEMENT: null, // Project Management
  SKILL__PYTHON: null, // Python
  SKILL__R: null, // R
  SKILL__RUBY_ON_RAILS: null, // Ruby on Rails
  SKILL__SAAS: null, // SaaS
  SKILL__SALES: null, // Sales
  SKILL__SALESFORCE: null, // Salesforce
  SKILL__SALES_PROCESS: null, // Sales Process
  SKILL__SCRUM: null, // Scrum
  SKILL__SEO: null, // SEO
  SKILL__SOCIAL_MEDIA: null, // Social Media
  SKILL__SOFTWARE_DEVELOPMENT: null, // Software Development
  SKILL__SOFTWARE_ENGINEERING: null, // Software Engineering
  SKILL__SPANISH_LANGUAGE: null, // Spanish Language
  SKILL__SQL: null, // SQL
  SKILL__STORYTELLING: null, // Storytelling
  SKILL__SWIFT: null, // Swift
  SKILL__SYSTEMS_ADMINISTRATION: null, // Systems Administration
  SKILL__TECHNICAL_SUPPORT: null, // Technical Support
  SKILL__TECHNOLOGY: null, // Technology
  SKILL__TENSORFLOW: null, // TensorFlow
  SKILL__TIME_MANAGEMENT: null, // Time Management
  SKILL__TINY_MACHINE_LEARNING: null, // Tiny Machine Learning
  SKILL__UX_DESIGN: null, // UX Design
  SKILL__UX_RESEARCH: null, // UX Research
  SKILL__VERSION_CONTROL: null, // Version Control
  SKILL__VR: null, // VR
  SKILL__WEB_DEVELOPMENT: null, // Web Development
  SKILL__WELL_BEING: null, // Well-being
  SKILL__WELL_BEING_SCIENCE: null, // Well-Being Science
  SLACK__CONFIGURATION__DESCRIPTION: null, // Configure the Linked Channel
  SLACK__CONFIGURATION__ENABLED: null, // Slack Enabled
  SLACK__CONFIGURATION__ENABLED__PLACEHOLDER: null, // Please select whether Slack is enabled
  SLACK__CONFIGURATION__TITLE: null, // Slack Configuration
  SLACK__CONFIGURATION__VISIBILITY__DESCRIPTION: null, // Please select who can see the channel
  SLACK__CONFIGURATION__VISIBILITY__TITLE: null, // Channel Visibility
  START_LEARNING__BUTTON_TITLE: null, // Request a Program
  START_LEARNING__IMG_ALT: null, // An illustration depicting a certificate with a gold seal and signature on a blue background. Next to it is a rolled-up diploma tied with a red ribbon an open book with visible text and a golden trophy placed on a blue pedestal.
  START_LEARNING__SUBTITLE: null, // Unlock your funds by requesting approval for a program now.
  START_LEARNING__SUBTITLE__REIMBURSEMENT: null, // Begin by submitting a request for pre-approval.
  START_LEARNING__TITLE: null, // Get started learning.
  STATUS__ACCEPTED: null, // Accepted
  STATUS__ADDING: null, // Adding...
  STATUS__ADMITTED: null, // Admitted
  STATUS__APPLIED: null, // Applied
  STATUS__APPROVED: null, // Approved
  STATUS__AVAILABLE: null, // Available
  STATUS__AWAITING_ADMISSION: null, // Awaiting Admission
  STATUS__AWAITING_APPROVAL: null, // Awaiting Approval
  STATUS__CANCELLED: null, // Cancelled
  STATUS__COMPLETE: null, // Complete
  STATUS__COMPLETED: null, // Completed
  STATUS__COMPLETED_ON__FORMAT: null, // Completed on {{date}}
  STATUS__DECLINED: null, // Declined
  STATUS__DENIED: null, // Denied
  STATUS__DUPLICATING: null, // Duplicating...
  STATUS__ENROLLED: null, // Enrolled
  STATUS__EXPIRED: null, // Expired
  STATUS__EXPIRES_NEVER: null, // Never expires
  STATUS__FINISHED: null, // Finished
  STATUS__FUNDS_REQUESTED: null, // Funds Requested
  STATUS__IN_PROGRESS: null, // In Progress
  STATUS__LATE: null, // Late
  STATUS__LOADING: null, // Loading...
  STATUS__MARK_AS_COMPLETED: null, // Marked As Completed
  STATUS__NOT_COMPLETED: null, // Not Completed
  STATUS__NOT_ENROLLED: null, // Not Enrolled
  STATUS__NOT_PAID: null, // Not Paid
  STATUS__NOT_STARTED: null, // Not Started
  STATUS__NOT_SUBMITTED: null, // Not Submitted
  STATUS__OFF: null, // Off
  STATUS__ON: null, // On
  STATUS__PAID: null, // Paid
  STATUS__PAYMENT_PENDING: null, // Payment Pending
  STATUS__PENDING: null, // Pending
  STATUS__PENDING_MANAGER_REVIEW: null, // Pending Manager Review
  STATUS__READY_TO_APPLY: null, // Ready to Apply
  STATUS__READY_TO_START: null, // Ready to Start
  STATUS__REJECTED: null, // Rejected
  STATUS__REQUESTED: null, // Requested
  STATUS__RSVPED: null, // Rsvped
  STATUS__SAVING: null, // Saving...
  STATUS__SCHEDULE_APPROVED: null, // Schedule Approved
  STATUS__SCHEDULE_AWAITING_APPROVAL: null, // Schedule Awaiting Approval
  STATUS__SCHEDULE_NOT_APPROVED: null, // Schedule Not Approved
  STATUS__SELECTED: null, // Selected
  STATUS__SKIPPED: null, // Skipped
  STATUS__SKIPPED_ON__FORMAT: null, // Skipped on {{date}}
  STATUS__STATUS: null, // Status
  STATUS__SUBMITTED: null, // Submitted
  STATUS__SUBMITTED_ON__FORMAT: null, // Submitted on {{date}}
  STATUS__UNKNOWN: null, // Unknown
  STATUS__VERIFIED_COMPLETED: null, // Verified Completed
  STEP__REQUIRES_ACCESS__FORMAT: null, // What step is required to access {{stepTitle}}?
  SUBMISSION: null, // Submission
  SUBMISSION__ACTIVE: null, // Submitting
  SUBMISSION__DELETE: null, // Delete Submission
  SUBMISSION__DELETE__PROMPT: null, // Are you sure you want to delete this submission?
  SUBMISSION__FROM__FORMAT: null, // {{participant}}'s Submission
  SUBMISSION__NAVIGATE_ALL: null, // All Submissions
  SUBMISSION__NAVIGATE_NEXT: null, // Next Answer
  SUBMISSION__NAVIGATE_PREVIOUS: null, // Previous Answer
  SUBMISSION__NO_SUBMISSIONS: null, // No projects have been submitted.
  SUBMISSION__PLURAL: null, // Submissions
  SUBMISSION__REVIEW: null, // Review Submission
  SUBMISSION__VIEW: null, // View Submission
  SUPPORT__BETA_THANKS: null, // Thanks for being a beta user! Please share any and all feedback questions or ideas in this chat. This will let you chat directly with Taylor our CPO.
  SUPPORT__CHAT_NOW: null, // Chat Now
  SUPPORT__CONCIERGE_NAME: null, // Advising & Support
  SUPPORT__CONTACT_US: null, // Contact us
  SUPPORT__DISPLAY__BYLINE: null, // How can I help? 👋🏼
  SUPPORT__DISPLAY__TITLE: null, // Sarah - Learn In Advisor
  SUPPORT__GREETING__OFFLINE: null, // Hello your Learn In Advisor is not online at the moment.\n\nFill out this form or send us an email at support@learnin.com and we'll get back to you!\n\n
  SUPPORT__GREETING__ONLINE: null, // Hi there! What can we help you with?
  SUPPORT__HELP: null, // Help
  SUPPORT__LIVE_SUPPORT: null, // Live support
  SUPPORT__NEED_HELP: null, // Need Help?
  SUPPORT__NEED_SOME_HELP: null, // Need some help?
  SUPPORT__OFFLINE_MESSAGE: null, // Hello your Learn In Advisor is not online at the moment.\n\nFill out this form or send us an email at support@learnin.com and we'll get back to you!\n\n
  SUPPORT__PERSONAL_ADVISOR: null, // Personal Advisor
  SUPPORT__PERSONAL_ADVISOR__DESCRIPTION: null, // Our support team is happy to help with any questions you might have.
  SUPPORT__PRECHAT__GREETING: null, // Hi there! What can we help you with?
  SUPPORT__TOP_TITLE: null, // Advising & Support
  TAG__CRITICAL_SKILL: null, // Critical Skill
  TAG__NEW_ROLE: null, // New Role
  TAG__NONE_FOR_PROGRAM: null, // This program does not have any tags
  TAG__PLURAL: null, // Tags
  TAG__REMOVE__FORMAT: null, // Remove {{item}} tag
  TAG__SEARCH_OR_ADD: null, // Type to search or create a tag
  TEAM: null, // Team
  TEAM__INSIGHTS: null, // Team Insights
  TEST__FALLBACK: null, // FallbackValue
  TEXT__LABEL: null, // Text label
  TIME: null, // Time
  TIME__APPROVAL: null, // Time Approval
  TIME__APPROVED_BY: null, // Time Approved By
  TIME__AT: null, // At
  TIME__DETAILS: null, // Time Details
  TIME__DUE: null, // Due Time
  TIME__DURATION: null, // Duration
  TIME__END: null, // End Time
  TIME__ENROLLED: null, // Time Enrolled
  TIME__INCENTIVE: null, // Time Incentive
  TIME__INCENTIVE__CREATE: null, // Create Time Incentive
  TIME__INCENTIVE__PLURAL: null, // Time Incentives
  TIME__INCENTIVE__UPDATE: null, // Update Time Incentive
  TIME__MANAGE: null, // Manage Time
  TIME__PICK_YOUR_ALLOCATION: null, // Pick your time allocation
  TIME__SCHEDULED: null, // Time Scheduled
  TIME__START: null, // Start Time
  TOS__STRIPE__ACCEPT: null, // Accept & Continue
  TOS__STRIPE__ACCEPTANCE: null, // I accept the Terms & Conditions
  TOS__STRIPE__DESCRIPTION: null, // Please review and agree to these Terms and Conditions by checking the box below.
  TOS__STRIPE__LINK: null, // Authorized User Terms in partnership with Stripe—our card issuing platform
  TOS__STRIPE__TITLE: null, // Bank Issuing Terms & Conditions
  TRANSACTION__ADDED_FUNDS: null, // Added Funds
  TRANSACTION__ADDED_FUNDS_ADVANCE: null, // Added Funds (Advance)
  TRANSACTION__AUTOMATED_BALANCE_UPDATE: null, // Automated Balance Update
  TRANSACTION__DECREASED_FUNDS: null, // Decreased Funds
  TRANSACTION__EMPTY__DESCRIPTION: null, // Once transactions have been made you can see them here.
  TRANSACTION__EMPTY__TITLE: null, // No transactions have been made.
  TRANSACTION__EXPIRING_FUNDS: null, // Automatic
  TRANSACTION__MANUAL_BALANCE_UPDATE: null, // Manual Balance Update
  TRANSACTION__NO_BY_USER__FORMAT: null, // {{user}} has not made any transactions yet.
  TRANSACTION__PAYING_BACK_ADVANCE: null, // Paying back advance
  TRANSACTION__PLURAL: null, // Transactions
  TRANSACTION__PLURAL__VIEW: null, // View Transactions
  TRANSACTION__VIEW: null, // View Transaction
  TUITION__REIMBURSEMENT_REQUESTS: null, // Tuition Reimbursement Requests
  USER: null, // User
  USER__ADD: null, // Add User
  USER__ADD__INVALID_COMPANY: null, // Please choose the company to add the user to
  USER__FINANCE: null, // User's Finance
  USER__FINANCE__TITLE: null, // Choose your financial plan
  USER__MENU: null, // User Menu
  USER__PLURAL: null, // Users
  USER__ROLES: null, // User Roles
  VALIDATION__AMOUNT: null, // Please enter an amount
  VALIDATION__BALANCE_RENEWAL: null, // Please select a balance renewal setting
  VALIDATION__CANT_ADD_MORE_TAGS__FORMAT: null, // You cannot add more than {{max}} tags
  VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT: null, // {{item}} must be less than {{max}} characters
  VALIDATION__CHAR_LIMIT_REACHED__FORMAT: null, // Must be less than {{max}} characters
  VALIDATION__CHOOSE_EVENT_DATE__POLITE: null, // Please choose an event date
  VALIDATION__DISCUSSION__ERROR__EMPTY_RESPONSE: null, // You cannot submit an empty response to the discussion prompt.
  VALIDATION__EMAIL_INVALID__POLITE: null, // Please enter a valid email address
  VALIDATION__ENTER_DURATION__POLITE: null, // Please enter a duration
  VALIDATION__FIELD_REQUIRED: null, // This field is required
  VALIDATION__FIELD_REQUIRED__FORMAT: null, // {{field}} is required
  VALIDATION__FILE: null, // Validated the file
  VALIDATION__GREATER_THAN_ZERO: null, // Must be a number greater than 0
  VALIDATION__IMAGE_IS_UPLOADING: null, // Please wait for file upload to complete
  VALIDATION__LICENSE_PROVIDER: null, // Please select a license provider
  VALIDATION__MESSAGE: null, // Please enter a message
  VALIDATION__MISSING_FIELD__FORMAT: null, // Enter the {{fieldName}}
  VALIDATION__MISSING_FIELD__POLITE__FORMAT: null, // Please enter the {{fieldName}}
  VALIDATION__MONEY_AT_LEAST__FORMAT: null, // Please enter at least {{amount}}
  VALIDATION__NO_DECIMAL: null, // Please enter a number without a decimal
  VALIDATION__NUMBER: null, // Please enter a number
  VALIDATION__NUMBER_UP_BETWEEN__FORMAT: null, // Please use a number between {{min}} and {{max}}
  VALIDATION__PRIVACY_SETTING: null, // Please select a privacy setting
  VALIDATION__PROGRAM_LENGTH: null, // Length must be at least one or higher.
  VALIDATION__PROJECT__ERROR_EMPTY_RESPONSE: null, // You cannot submit an empty response to the project prompt.
  VALIDATION__PROJECT__ERROR_EMPTY_URL: null, // You cannot submit an empty response for the URL.
  VALIDATION__REDEMPTION_GUIDELINES__POLITE: null, // Please enter redemption guidelines
  VALIDATION__REDEMPTION_TYPE__POLITE: null, // Please select a type of funding
  VALIDATION__REQUIRED: null, // Required field.
  VALIDATION__RESPONSE_REQUIRED: null, // Please respond to this question.
  VALIDATION__SELECT_AT_LEAST__FORMAT: null, // Select at least 1 {{item}}
  VALIDATION__SELECT_DURATION: null, // Select a duration
  VALIDATION__SELECT_DURATION__POLITE: null, // Please select a duration
  VALIDATION__SELECT_ONE: null, // Select one
  VALIDATION__SELECT_ONE__POLITE: null, // Please select one
  VALIDATION__SELECT_PROGRAM: null, // Select a program
  VALIDATION__SELECT_REQUIRED_APPROVALS: null, // Please select required approvals
  VALIDATION__SELECT_SPAN: null, // Select a span
  VALIDATION__SELECT_SPAN__POLITE: null, // Please select a span
  VALIDATION__SELECT_SUBMISSION_TYPE__POLITE: null, // Please select a submission type
  VALIDATION__SELECT_TIMEZONE: null, // Select a timezone
  VALIDATION__SELECT_TIMEZONE__POLITE: null, // Please select a timezone
  VALIDATION__URL_INVALID__FORMAT: null, // Invalid URL.
  WELCOME: null, // Welcome
  WELCOME__COMPANY_MONEY__DESCRIPTION: null, // Your company is providing you both time and money to use towards educational programs.
};
