// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/SubmissionType.cs

import {z} from 'zod';

export const SubmissionTypeSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
]);
