import {useState, useEffect} from 'react';
import styled from 'styled-components';
import AppHeaderLogo from './AppHeaderLogo';
import {CloseCircleOutlined, MenuOutlined} from '@ant-design/icons';
import {Breakpoints} from '@utils/responsiveUtils';
import AppNavMenu from './AppNavMenu.container';
import UserMenu from './../UserMenuDropdown.container';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const TOP_NAV_HEIGHT = 68;
const Container = styled.div`
  display: none;
  justify-content: space-between;
  position: fixed;
  height: ${TOP_NAV_HEIGHT}px;
  top: 0;
  width: 100%;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: flex;
  }
`;

const MobileNavMenuContainer = styled.div`
  background: white;
  z-index: 1000;
  position: fixed;
  top: ${TOP_NAV_HEIGHT}px;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;

const HeaderItem = styled.div`
  padding: 16px;
`;

const HeaderButton = styled.button`
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;

const UserMenuContainer = styled.div`
  padding: 36px 16px;
`;

const mobileMenuStyle = {
  fontSize: '1.6em',
  cursor: 'pointer',
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

function MobileAppHeaderWithMobileMenu() {
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const handleClickNavToggleButton = () =>
    setShowMobileNavMenu(!showMobileNavMenu);

  // Close the mobile nav if user opens it on mobile then makes the screen wider
  const closeMobileMenuOnDesktop = () => {
    if (window.innerWidth > Breakpoints.Desktop) setShowMobileNavMenu(false);
  };

  useEffect(() => {
    window.addEventListener('resize', closeMobileMenuOnDesktop);
    return () => window.removeEventListener('resize', closeMobileMenuOnDesktop);
  }, []);

  return (
    <>
      <Container>
        <HeaderItem>
          <AppHeaderLogo />
        </HeaderItem>
        <HeaderItem>
          <HeaderButton
            onClick={handleClickNavToggleButton}
            aria-label={i18n.t(showMobileNavMenu ? k.CTA__CLOSE : k.CTA__VIEW)}>
            {showMobileNavMenu ? (
              <CloseCircleOutlined aria-hidden="true" style={mobileMenuStyle} />
            ) : (
              <MenuOutlined aria-hidden="true" style={mobileMenuStyle} />
            )}
          </HeaderButton>
        </HeaderItem>
      </Container>
      {showMobileNavMenu && (
        <MobileNavMenuContainer>
          <div>
            <AppNavMenu closeMobileMenu={handleClickNavToggleButton} />
          </div>
          <UserMenuContainer>
            <UserMenu closeMobileMenu={handleClickNavToggleButton} />
          </UserMenuContainer>
        </MobileNavMenuContainer>
      )}
    </>
  );
}

export default MobileAppHeaderWithMobileMenu;
