import {i18n, k} from '@i18n/translate';
import {useQuery, UseQueryResult} from 'react-query';
import {cacheForeverOptions, simpleQueryFn} from '@store/queryClient';
import {UserPlanProgramItemVM} from '@generated/interfaces';
import {useHasResourcesEnabledQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {
  UseGetRequestStateProps,
  UseShowRequestButtonProps,
} from './Request.interfaces';
import {
  getDoProgramsRequireApprovalRq,
  getUserProgramPlanRq,
} from '@store/apiEndpoints';
import {useGetCompanyPersonalizationQuery} from '@hooks/apiEndpoints/user/queries';
import {useAuth} from '@utils/oidc-auth-wrapper';

export const useHasProgramFinderEnabled = (
  isEligibleForPersonalLearningBudget: boolean
) => isEligibleForPersonalLearningBudget;

export const useShowRequestButton = ({
  isEligibleForPersonalLearningBudget,
  showFindProgramOption,
  showRequestProgramOption,
  showRequestResourceOption,
}: UseShowRequestButtonProps) =>
  (showRequestResourceOption ||
    showFindProgramOption ||
    showRequestProgramOption) &&
  isEligibleForPersonalLearningBudget;

export const useShowProgramFinderOption = (
  hasProgramFinderEnabled: boolean,
  isOnlyCustomProgramsUser: boolean,
  isHideMarketplaceOn: boolean
) =>
  hasProgramFinderEnabled && !isOnlyCustomProgramsUser && !isHideMarketplaceOn;

export const useShowExploreProgramButton = (
  customProgramsAvailable: boolean,
  userProgramPlanQueryLoaded: boolean,
  userProgramPlanQueryHasData: boolean,
  isHideMarketplaceOn: boolean
) =>
  customProgramsAvailable &&
  userProgramPlanQueryLoaded &&
  userProgramPlanQueryHasData &&
  isHideMarketplaceOn;

export const useShowFindProgramOption = (
  isOnlyCustomProgramsUser: boolean,
  isHideMarketplaceOn: boolean
) => !isOnlyCustomProgramsUser && !isHideMarketplaceOn;

export const useGetRequestState = ({
  isHideMarketplaceOn,
  isOnlyCustomProgramsUser,
  userProgramPlanQuery,
  userPlanResourcesQuery,
}: UseGetRequestStateProps) => {
  const {user} = useAuth();

  const hasResourcesEnabledQuery = useHasResourcesEnabledQuery();

  const isEligibleForPersonalLearningBudget =
    user?.isEligibleForPersonalLearningBudget;

  const hasProgramFinderEnabled = useHasProgramFinderEnabled(
    isEligibleForPersonalLearningBudget
  );

  const showProgramFinderOption = useShowProgramFinderOption(
    hasProgramFinderEnabled,
    isOnlyCustomProgramsUser,
    isHideMarketplaceOn
  );

  const hasProgramsOrResources =
    userPlanResourcesQuery?.data?.length || userProgramPlanQuery?.data?.length;

  const showExploreProgramButton = useShowExploreProgramButton(
    !isOnlyCustomProgramsUser,
    userProgramPlanQuery?.isSuccess,
    !userProgramPlanQuery?.data?.length,
    !isHideMarketplaceOn
  );

  const showRequestProgramOption = !isOnlyCustomProgramsUser;

  const showFindProgramOption = useShowFindProgramOption(
    isOnlyCustomProgramsUser,
    isHideMarketplaceOn
  );

  const showRequestResourceOption = hasResourcesEnabledQuery?.data;

  const showRequestButton = useShowRequestButton({
    isEligibleForPersonalLearningBudget,
    showFindProgramOption,
    showRequestProgramOption,
    showRequestResourceOption,
  });

  return {
    showExploreProgramButton,
    showFindProgramOption,
    showProgramFinderOption,
    showRequestButton,
    showRequestProgramOption,
    showRequestResourceOption,
    hasProgramsOrResources,
  };
};

/** This was extracted from a refactor. We could make this more declarative. */
export const useGetRequestText = (
  companyPersonalization: boolean,
  doProgramsRequireApprovalQuery: UseQueryResult<boolean, any>,
  hasResourcesEnabled: boolean
) => {
  if (!companyPersonalization || !doProgramsRequireApprovalQuery.data) {
    return i18n.t(k.PROGRAM__ADD);
  }
  if (doProgramsRequireApprovalQuery.data && !hasResourcesEnabled) {
    return i18n.t(k.PROGRAM__REQUEST);
  }
  if (doProgramsRequireApprovalQuery.data && hasResourcesEnabled) {
    return i18n.t(k.FUND__REQUEST__NEW);
  }
};

export const useUserProgramPlanQuery = () => {
  const userProgramPlanRq = getUserProgramPlanRq(2);
  return useQuery<UserPlanProgramItemVM[]>(
    userProgramPlanRq.queryKey,
    () => simpleQueryFn(userProgramPlanRq.path) // Old hook from refactor. Use generated hook when possible.
  );
};

export const useDoProgramsRequireApprovalQuery = () => {
  const doProgramsRequireApprovalRq = getDoProgramsRequireApprovalRq();
  return useQuery<boolean>(
    doProgramsRequireApprovalRq.queryKey,
    () => simpleQueryFn(doProgramsRequireApprovalRq.path),
    {...cacheForeverOptions} // Old hook from refactor. Use generated hook when possible.
  );
};

export const useGetRequestQueries = () => {
  return {
    companyPersonalizationQuery: useGetCompanyPersonalizationQuery(),
    doProgramsRequireApprovalQuery: useDoProgramsRequireApprovalQuery(),
    userProgramPlanQuery: useUserProgramPlanQuery(),
  };
};
