import {ReactElement} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {AIRPLANE_DESKTOP, AIRPLANE_IMAGE} from '@assets/cloudinary-urls';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {Helmet} from 'react-helmet-async';
import useFeatureFlags from '@hooks/useFeatureFlags';

const mobileWidth = window.innerWidth * 0.03958;

/**
 * Determine the clamp() css property margin-top. Get the smallest number and biggest number (restrictions) then compare against browser width
 * @param width
 * @param largestNumber
 * @param smallestNumber
 * @returns {String}
 */
const determineScreenWidth = (width, largestNumber, smallestNumber) => {
  if (width >= largestNumber) {
    return `${largestNumber}px`;
  } else if (width <= smallestNumber) {
    return `${smallestNumber}px`;
  } else {
    return `${width}px`;
  }
};

export default function PageNotFound({title}: {title?: string}): ReactElement {
  const navigate = useNavigate();
  const {isFeatureFlagOn} = useFeatureFlags();

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LearnInText tag={TextTags.OopsErrorMessage}>
        {i18n.t(k.ERROR__OOPS)}
      </LearnInText>
      <LearnInText tag={TextTags.FunErrorMessage}>
        {i18n.t(k.ERROR__FUN_404)}
      </LearnInText>
      <LearnInText tag={TextTags.BoringErrorMessage}>
        {i18n.t(k.ERROR__CODE_404)}
      </LearnInText>
      <ButtonWrapper>
        <LearnInButton tag={ButtonTags.Primary} onClick={() => navigate('/')}>
          {isFeatureFlagOn.YourPlanNameChange
            ? i18n.t(k.HOME__YOUR__GO_TO)
            : i18n.t(k.PLAN__YOUR__GO_TO)}
        </LearnInButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

// clamp is used to scale a css item to a min-size and max-size (smallest number it can achieve, variable size, maximum number it can achieve)
const Wrapper = styled.div`
  margin-top: clamp(41px, 3.958vw, 57px);
  text-align: center;
  height: calc(100vh - 120px - ${determineScreenWidth(mobileWidth, 57, 41)});
  background: url(${AIRPLANE_IMAGE}) no-repeat left bottom;
  background-size: 60%;
  width: 100vw;
  @media (min-width: 1000px) {
    background: url(${AIRPLANE_DESKTOP}) no-repeat left bottom;
    background-size: 60%;
    margin-left: -10em;
    margin-top: clamp(151px, 11.875vw, 171px);
    height: calc(100vh - 15px - ${determineScreenWidth(mobileWidth, 171, 151)});
    width: 100vw;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
