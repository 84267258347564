// Do not modify directly
// Generated from file: learnin.core.api/Managers/Calendar/SyncCalendarRequest.cs

import {z} from 'zod';
import {CalendarProviderSchema} from './CalendarProviderSchema.schema';
import {CalendarCallbackRedirectSchema} from './CalendarCallbackRedirectSchema.schema';

export const SyncCalendarRequestSchema = z.object({
  calendar: CalendarProviderSchema,
  id: z.number().nullable().optional(),
  redirectOption: CalendarCallbackRedirectSchema,
});
