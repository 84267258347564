// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewLevelUserReport.cs

import {z} from 'zod';
import {AcademyOverviewLevelUserCompletionReportVmSchema} from './AcademyOverviewLevelUserCompletionReportVmSchema.schema';

export const AcademyOverviewLevelUserReportVmSchema = z.object({
  academyLevelId: z.number(),
  academyLevelName: z.string(),
  completionPercentage: z.number(),
  order: z.number(),
  totalCompleted: z.number(),
  totalOutstanding: z.number(),
  users: z.array(AcademyOverviewLevelUserCompletionReportVmSchema),
});
