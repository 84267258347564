// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/EmployeeEngagementVM.cs

import {z} from 'zod';

export const EmployeeEngagementVMSchema = z.object({
  category: z.string(),
  color: z.string(),
  month: z.string(),
  monthNumber: z.number(),
  name: z.string(),
  value: z.number(),
});
