import {pxToRem} from '@utils/styleMixins';
import styled from 'styled-components';

const Overlay = styled.section`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${pxToRem(18)};
  opacity: 0.8;
`;

const StackBlock = styled.pre`
  width: 100%;
  background-color: #202124;
  padding: ${pxToRem(12)};
  color: white;
  font-family: monospace;
`;

/**
 * Displays an overlay that prints the error stack trace to the screen.
 */
const StackTraceOverlay = ({stack}: {stack: string}) => {
  return (
    <Overlay>
      <StackBlock>{stack}</StackBlock>
    </Overlay>
  );
};

export default StackTraceOverlay;
