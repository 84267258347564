import PageNotFound from '404';
import EmailPreview from '@components/EmailPreview';
import AdminWrapper from '@components/admin/AdminWrapper';
import {i18n, k} from '@i18n/translate';
import {RouteElement} from '@models/clientModels';
import {
  AdminPaths,
  EmailPreviewPaths,
  ManagerPaths,
  UserPaths,
} from '@utils/ClientPaths';
import {isProductionAny} from '@utils/environment-helpers';
import React from 'react';
import {Navigate} from 'react-router-dom';

// Lazy-Loaded Routes
const {lazy} = React;
const UserRoutes = lazy(() => import('../components/user/UserRoutes'));
const AdminRoutes = lazy(() => import('../components/admin/AdminRoutes'));
const ManagerRoutes = lazy(() => import('../components/manager/ManagerRoutes'));
const ApprovalsInternalRoutes = lazy(
  () =>
    import(
      '../components/manager/pages/approvals/components/ApprovalsInternalRoutes'
    )
);

/** Get routes that are common to both integrated and non-integrated (standard) users.
 * This is used in combination of useBuildRouteElements to handle complexity.
 */
const useGetEnabledPrimaryRoutesShared = ({
  isManager,
  isAdmin,
}: {
  isManager: boolean;
  isAdmin: boolean;
}): RouteElement[] => {
  const enabled = true;
  const routeElements: RouteElement[] = [
    {
      path: '/',
      enabled,
      element: <Navigate to="/user/manage" />,
    },
    {
      path: UserPaths.Wildcard,
      enabled,
      element: <UserRoutes />,
    },
    {
      path: ManagerPaths.Wildcard,
      enabled: isManager,
      element: <ManagerRoutes />,
    },
    {
      path: ManagerPaths.ApprovalsWildcard,
      enabled,
      element: <ApprovalsInternalRoutes />,
    },
    {
      path: AdminPaths.Wildcard,
      enabled,
      element: (
        <AdminWrapper isAdmin={isAdmin}>
          <AdminRoutes />
        </AdminWrapper>
      ),
    },
    {
      path: EmailPreviewPaths.Wildcard,
      enabled: !isProductionAny,
      element: <EmailPreview />,
    },
    {
      path: '*',
      enabled,
      element: <PageNotFound title={i18n.t(k.ERROR__PAGE__NOT__FOUND)} />,
    },
  ];

  return routeElements;
};

export default useGetEnabledPrimaryRoutesShared;
