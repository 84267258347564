// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewProgramPreApproval.cs

import {z} from 'zod';
import {PreApprovalApplicationSchema} from './PreApprovalApplicationSchema.schema';
import {NewProgramSchema} from './NewProgramSchema.schema';

export const NewProgramPreApprovalSchema = z.object({
  applicationDetails: PreApprovalApplicationSchema,
  attachmentIds: z.array(z.string()),
  newProgram: NewProgramSchema,
  userBudgetId: z.number().nullable().optional(),
});
