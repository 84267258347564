// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/IncentivePayload.cs

import {z} from 'zod';
import {IncentivePayloadSchema} from './IncentivePayloadSchema.schema';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';
import {FinanceRecurrenceSchema} from './FinanceRecurrenceSchema.schema';

const schema = z.object({
  allowApprovedItemsCancellation: z.boolean(),
  allowedLearningResources: z.array(LearningResourceTypeSchema),
  amount: z.number(),
  amountType: FinanceAmountTypeSchema,
  balanceRecurType: BalanceRecurTypeSchema,
  expirationInDays: z.number().nullable().optional(),
  externalReimbursementApplicationUrl: z.string(),
  financeType: FinanceTypeSchema,
  isProratingEnabled: z.boolean(),
  recurrence: FinanceRecurrenceSchema,
  recurring: z.boolean(),
  renewalMonth: z.string().nullable().optional(),
});

export const FinanceIncentivePayloadSchema =
  IncentivePayloadSchema.merge(schema);
