import {ReactQueryHookArgs} from '@store/apiEndpoints';
import {
  getCompanyPersonalizationRq,
  getUserRq,
  userSearchRq,
} from '@store/apiEndpoints/user/queries';
import {useQuery, UseQueryOptions, UseQueryResult} from 'react-query';
import {UserPersonalizationVM} from '@models/serverModels';
import {
  simpleQueryFn,
  cacheForHours,
  simpleInvalidateExactQueryFn,
  cacheForeverOptions,
} from '@store/queryClient';
import {queryHookFactory} from '../utils';
import {UserDetailsVM} from '@generated/interfaces';

export const useGetCompanyPersonalizationQuery = (
  queryOptions?: UseQueryOptions<UserPersonalizationVM>
) => {
  const queryRq = getCompanyPersonalizationRq();
  const query = useQuery<UserPersonalizationVM>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    {
      refetchOnWindowFocus: false,
      ...cacheForHours(6),
      ...queryOptions,
    }
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  return {
    ...query,
    invalidate,
  };
};

export type UseGetUserQueryReturnType = UseQueryResult<UserDetailsVM> & {
  invalidate: () => void;
};

export function useGetUserQuery(
  options?: ReactQueryHookArgs<UserDetailsVM>
): UseGetUserQueryReturnType {
  const onSuccess = options?.onSuccess;
  const userRq = getUserRq();
  const userQuery = useQuery<UserDetailsVM>(
    userRq.queryKey,
    () => simpleQueryFn(userRq.path),
    {
      ...cacheForeverOptions,
      onSuccess: (data) => !!onSuccess && onSuccess(data),
    }
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(userRq.queryKey);

  return {...userQuery, invalidate};
}

interface UserSearchQueryArgs {
  name: string;
  limit?: number;
  companyId: number;
}

interface UserSearchResultVM {
  name: string;
  id: number;
}

export const useUserSearchQuery = queryHookFactory<
  UserSearchQueryArgs,
  UserSearchResultVM[]
>(userSearchRq);
