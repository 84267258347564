/** These strings aren't customer-facing: these don't need to be localized. */
export const REQUESTED_ADDITIONAL = {
  name: 'Diddy Kong',
  approvalRank: 'additional',
  requesterName: 'Lanky Kong',
  amount: '$123.45',
  providerName: 'Nintendo',
  providerImageUrl: 'https://logodix.com/logo/1023869.png',
  programName: 'Bananas As A Currency',
  buttonUrl:
    'https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/',
  itemType: 'program',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
};
