// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateInitiativeStatus.cs

import {z} from 'zod';
import {InitiativeStatusSchema} from './InitiativeStatusSchema.schema';

export const UpdateInitiativeStatusSchema = z.object({
  id: z.number(),
  status: InitiativeStatusSchema,
});
