// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IAcademyStepBasicInfo.cs

import {z} from 'zod';
import {AcademyStepTypeSchema} from './AcademyStepTypeSchema.schema';

export const IAcademyStepBasicInfoSchema = z.object({
  academyLevelId: z.number(),
  allowPeerVisibility: z.boolean(),
  allowSkip: z.boolean(),
  id: z.number(),
  title: z.string(),
  type: AcademyStepTypeSchema,
});
