export type LxpOrgSettings = Record<string, boolean | Date | string>;

export interface LxpOrgInfo {
  organizationId: number;
  name: string;
  image: string;
  endorsedImage?: string;
  isEngaged: boolean;
  permissions: Record<string, unknown>;
  settings: LxpOrgSettings;
  orgRole: string;
  organizationBranding?: {
    brandColor: string;
    useLightText: boolean;
    organizationNameInOnboarding: boolean;
  };
}

export const initOrgInfo = (): LxpOrgInfo => ({
  name: '',
  image: '',
  organizationId: 0,
  isEngaged: false,
  permissions: {} as Record<string, unknown>,
  settings: {} as LxpOrgSettings,
  orgRole: '',
});
