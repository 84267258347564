import {
  BookOutlined,
  PlusOutlined,
  SnippetsOutlined,
  SyncOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Icon from '@blocks/Icon/Icon';
import {IncentiveType, LearningResourceType} from '@generated/enums';
import {
  useFinanceLearningBudgetsQuery,
  useIncentiveDetailsQuery,
} from '@generated/hooks';
import {UserPlanProgramItemVM} from '@generated/interfaces';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import useSearchParamUtils from '@hooks/useSearchParamUtils';
import {i18n, k} from '@i18n/translate';
import {getUserProgramPlanRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {COLORS} from '@utils/constants';
import {dangerouslyNoEscape} from '@utils/l10nUtils';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useCallback, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {RequestOptionButtonProps} from '../Request.interfaces';
import {COLORS_REQUEST_MENU} from './RequestMenuButtonAndDrawer.constants';
import {
  getRequestTypeChangeHandler,
  getSelectRequestTypeHandler,
} from './RequestMenuButtonAndDrawer.handlers';
import {
  ProgramRequestType,
  RequestOptionTypes,
  RequestType,
} from './RequestMenuButtonAndDrawer.interfaces';
import {useAuth} from '@utils/oidc-auth-wrapper';

export interface UseGetRequestTypeOptionsProps {
  showRequestProgramOption?: boolean;
  showRequestResourceOption?: boolean;
}

interface OptionsProps {
  activeBackground: string;
  background: string;
  enabled: boolean;
  hoverBackground: string;
  iconBackground: string;
  iconColor: string;
  id: RequestOptionTypes;
  requestType: LearningResourceType | RequestType;
  subtitle: string;
  title: string;
  Icon: () => JSX.Element;
}

export const useGetRequestTypeOptions = ({
  showRequestProgramOption,
  showRequestResourceOption,
}: UseGetRequestTypeOptionsProps): RequestOptionButtonProps[] => {
  const programRequestType: RequestType = ProgramRequestType;
  const learningBudsQUery = useFinanceLearningBudgetsQuery(null);
  const incentive = learningBudsQUery?.data;
  const incentiveId = incentive?.financeIncentiveId;
  const incentiveDetailsQuery = useIncentiveDetailsQuery({
    id: incentiveId,
    type: IncentiveType.Finance,
  });
  const incentiveDetails: any = incentiveDetailsQuery?.data;
  const enabledResources = incentiveDetails?.allowedLearningResources;

  let options: OptionsProps[] = [
    {
      activeBackground: COLORS_REQUEST_MENU.YellowActive,
      background: COLORS_REQUEST_MENU.Yellow,
      enabled: showRequestResourceOption,
      hoverBackground: COLORS_REQUEST_MENU.YellowHover,
      iconBackground: COLORS_REQUEST_MENU.YellowIconBackground,
      iconColor: COLORS_REQUEST_MENU.YellowIcon,
      id: RequestOptionTypes.Book,
      requestType: LearningResourceType.Book,
      subtitle: i18n.t(k.REQUEST__NEW__BOOK__SUBTITLE),
      title: i18n.t(k.RESOURCE__BOOK),
      Icon: () => <Icon size="XL" icon={<BookOutlined aria-hidden="true" />} />,
    },
    {
      activeBackground: COLORS_REQUEST_MENU.BlueActive,
      background: COLORS_REQUEST_MENU.Blue,
      enabled: showRequestResourceOption,
      hoverBackground: COLORS_REQUEST_MENU.BlueHover,
      iconBackground: COLORS_REQUEST_MENU.BlueIconBackground,
      iconColor: COLORS.Blue350,
      id: RequestOptionTypes.Conference,
      requestType: LearningResourceType.Conference,
      subtitle: i18n.t(k.REQUEST__NEW__CONFERENCE__SUBTITLE),
      title: i18n.t(k.RESOURCE__CONFERENCE),
      Icon: () => <Icon size="XL" icon={<TeamOutlined aria-hidden="true" />} />,
    },
    {
      activeBackground: COLORS_REQUEST_MENU.GreenActive,
      background: COLORS_REQUEST_MENU.Green,
      enabled: showRequestResourceOption,
      hoverBackground: COLORS_REQUEST_MENU.GreenHover,
      iconBackground: COLORS_REQUEST_MENU.GreenIconBackground,
      iconColor: COLORS_REQUEST_MENU.GreenIcon,
      id: RequestOptionTypes.Subscription,
      requestType: LearningResourceType.Subscription,
      subtitle: i18n.t(k.REQUEST__NEW__SUBSCRIPTION__SUBTITLE),
      title: i18n.t(k.RESOURCE__SUBSCRIPTION),
      Icon: () => <Icon size="XL" icon={<SyncOutlined aria-hidden="true" />} />,
    },
    {
      activeBackground: COLORS_REQUEST_MENU.PurpleActive,
      background: COLORS_REQUEST_MENU.Purple,
      enabled: showRequestResourceOption,
      hoverBackground: COLORS_REQUEST_MENU.PurpleHover,
      iconBackground: COLORS_REQUEST_MENU.PurpleIconBackground,
      iconColor: COLORS_REQUEST_MENU.PurpleIcon,
      id: RequestOptionTypes.Other,
      requestType: LearningResourceType.Other,
      subtitle: i18n.t(k.REQUEST__NEW__OTHER__SUBTITLE),
      title: i18n.t(k.REQUEST__NEW__OTHER__TITLE),
      Icon: () => <Icon size="XL" icon={<PlusOutlined aria-hidden="true" />} />,
    },
  ];

  if (enabledResources?.length) {
    options = options.filter((o: any) =>
      enabledResources?.includes(o.requestType)
    );
  }
  options.unshift({
    activeBackground: COLORS_REQUEST_MENU.PinkActive,
    background: COLORS_REQUEST_MENU.Pink,
    enabled: showRequestProgramOption,
    hoverBackground: COLORS_REQUEST_MENU.PinkHover,
    iconBackground: COLORS_REQUEST_MENU.PinkIconBackground,
    iconColor: COLORS.Pink100,
    id: RequestOptionTypes.Program,
    requestType: programRequestType,
    subtitle: i18n.t(k.REQUEST__NEW__PROGRAM__SUBTITLE),
    title: i18n.t(k.PROGRAM),
    Icon: () => (
      <Icon size="XL" icon={<SnippetsOutlined aria-hidden="true" />} />
    ),
  });
  const filteredOptions = options.filter((o) => o.enabled);
  return filteredOptions;
};

export const useGetMarketplaceText = () =>
  i18n.t(k.PROGRAM__MARKETPLACE__SIMPLE, dangerouslyNoEscape);

export const useRequestTypeChangeEffect = ({
  requestType,
  setFormVisible,
  setIsSubmitButtonDisabled,
}) =>
  useEffect(() => {
    const onRequestTypeChange = getRequestTypeChangeHandler({
      requestType,
      setFormVisible,
      setIsSubmitButtonDisabled,
    });
    onRequestTypeChange();
  }, [requestType, setFormVisible, setIsSubmitButtonDisabled]);

export const useSetShowFooterEffect = ({
  formVisible,
  selectionIndex,
  setShowFooter,
  showProgramFinderOption,
}) =>
  useEffect(() => {
    setShowFooter(!formVisible && showProgramFinderOption && !selectionIndex);
  }, [formVisible, selectionIndex, setShowFooter, showProgramFinderOption]);

export const useSetBypassMenuSelectionEffect = ({
  requestTypeOptions,
  setBypassMenuSelection,
}) =>
  useEffect(() => {
    setBypassMenuSelection(requestTypeOptions?.length === 1);
  }, [requestTypeOptions.length, setBypassMenuSelection]);

export const useOnSelectRequestTypeEffect = ({
  bypassMenuSelection,
  requestTypeOptions,
  setFormVisible,
  setRequestType,
  setSelectionIndex,
  drawerVisible,
}) => {
  const {search} = useLocation();
  const hasFilters = new URLSearchParams(search);

  useEffect(() => {
    const onSelectRequestType = getSelectRequestTypeHandler({
      requestTypeOptions,
      setFormVisible,
      setRequestType,
      setSelectionIndex,
      hasFilters,
      drawerVisible,
    });
    bypassMenuSelection && onSelectRequestType(0);
  }, [
    bypassMenuSelection,
    requestTypeOptions,
    setFormVisible,
    setRequestType,
    setSelectionIndex,
  ]);
};

export const useRequestMenuCurrencyHooks = () => {
  const {user} = useAuth();
  const {RequestWithCurrency: isRequestWithCurrencyOn} =
    useFeatureFlags().isFeatureFlagOn;
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, user?.currency);
  const currencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRatesList = currencyExchangeQuery?.data;

  return {
    currencyExchangeQuery,
    exchangeRate,
    exchangeRatesList,
  };
};

export const useRequestMenuFormHooks = ({
  showRequestProgramOption,
  showRequestResourceOption,
}) => {
  const requestTypeOptions = useGetRequestTypeOptions({
    showRequestProgramOption,
    showRequestResourceOption,
  });

  const marketplaceLinkText = useGetMarketplaceText();

  return {
    marketplaceLinkText,
    requestTypeOptions,
  };
};

export const useRequestMenuOptionCustomHooks = ({
  showRequestProgramOption,
  showRequestResourceOption,
}) => {
  const navigate = useNavigate();
  const {currencyExchangeQuery, exchangeRate, exchangeRatesList} =
    useRequestMenuCurrencyHooks();

  const {RequestWithCurrency: isRequestWithCurrencyOn} =
    useFeatureFlags().isFeatureFlagOn;

  const {marketplaceLinkText, requestTypeOptions} = useRequestMenuFormHooks({
    showRequestProgramOption,
    showRequestResourceOption,
  });

  const userProgramPlanRq = getUserProgramPlanRq(2);
  const userProgramPlanQuery = useQuery<UserPlanProgramItemVM[]>(
    userProgramPlanRq.queryKey,
    () => simpleQueryFn(userProgramPlanRq.path)
  );
  const learningBudsQuery = useFinanceLearningBudgetsQuery(null);

  return {
    currencyExchangeQuery,
    exchangeRate,
    exchangeRatesList,
    isRequestWithCurrencyOn,
    marketplaceLinkText,
    navigate,
    requestTypeOptions,
    userProgramPlanQuery,
    learningBudsQuery,
  };
};

const useAppendRequestURL = ({requestType}: {requestType?: string}) => {
  const {setSearchParamsAndNavigate} = useSearchParamUtils();

  useEffect(() => {
    setSearchParamsAndNavigate({
      request: requestType,
    });
  }, [requestType]);
};

const useAppendRequestChange = ({
  setRequestType,
  setSelectionIndex,
  setDrawerVisible,
  setFormVisible,
  requestTypeOptions,
}) => {
  const {search} = useLocation();
  const getFiltersFromURL = useCallback(
    () => new URLSearchParams(search),
    [search]
  );
  const getFilteredItemsIds = useCallback(
    (filteredIds: string) =>
      getFiltersFromURL().get(filteredIds) as RequestType,
    [getFiltersFromURL]
  );
  useEffect(() => {
    const handleRequestChange = (request: RequestType) => {
      setRequestType(request);

      const selectedOption = requestTypeOptions
        .map((item) => item.requestType?.toString())
        .indexOf(request.toString());

      setSelectionIndex(selectedOption);
      setDrawerVisible(true);
      setFormVisible(true);
    };

    const request = getFilteredItemsIds('request');
    request && handleRequestChange(request);
  }, []);
};

export const useRequestMenuOptionEffects = ({
  bypassMenuSelection,
  formVisible,
  requestType,
  requestTypeOptions,
  selectionIndex,
  setBypassMenuSelection,
  setFormVisible,
  setIsSubmitButtonDisabled,
  setRequestType,
  setSelectionIndex,
  setShowFooter,
  showProgramFinderOption,
  setDrawerVisible,
  drawerVisible,
}) => {
  useRequestTypeChangeEffect({
    requestType,
    setFormVisible,
    setIsSubmitButtonDisabled,
  });

  useSetShowFooterEffect({
    formVisible,
    showProgramFinderOption,
    selectionIndex,
    setShowFooter,
  });

  useSetBypassMenuSelectionEffect({
    requestTypeOptions,
    setBypassMenuSelection,
  });

  useOnSelectRequestTypeEffect({
    bypassMenuSelection,
    requestTypeOptions,
    setFormVisible,
    setRequestType,
    setSelectionIndex,
    drawerVisible,
  });

  useAppendRequestURL(drawerVisible ? {requestType} : {});

  useAppendRequestChange({
    setRequestType,
    setSelectionIndex,
    setDrawerVisible,
    setFormVisible,
    requestTypeOptions,
  });
};
