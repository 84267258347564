// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Reports/PrepaymentTransactionVM.cs

import {z} from 'zod';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';

export const PrepaymentTransactionVMSchema = z.object({
  amountType: FinanceAmountTypeSchema,
  balance: z.number(),
  employeeName: z.string(),
  lastUsed: z.string(),
  lineOfBusiness: z.string(),
  recurrenceType: BalanceRecurTypeSchema,
  spendingLimit: z.number(),
  totalApproved: z.number(),
  totalSpent: z.number(),
  userCompanyId: z.number(),
  userFinanceIncentiveId: z.number(),
});
