import {i18n, k} from '@i18n/translate';
import {IncentiveType} from '@generated/enums';

export const mapIncentiveTypeToString = (value: IncentiveType): string => {
  switch (value) {
    case IncentiveType.Finance:
      return i18n.t(k.GENERIC__FINANCE).toLowerCase();
    case IncentiveType.Time:
      return i18n.t(k.TIME).toLowerCase();
    default:
      console.error('Unrecognized incentive type enum: ', value);
      return '';
  }
};
