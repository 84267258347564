export const NewWindowIcon = ({...rest}) => {
  const fill = rest?.fill || 'currentColor';
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M21.8576 22.711H2.14328C1.66917 22.711 1.28613 22.328 1.28613 21.8539V2.1396C1.28613 1.66549 1.66917 1.28246 2.14328 1.28246H11.7861C11.904 1.28246 12.0004 1.37889 12.0004 1.49674V2.99674C12.0004 3.1146 11.904 3.21103 11.7861 3.21103H3.2147V20.7825H20.7861V12.211C20.7861 12.0932 20.8826 11.9967 21.0004 11.9967H22.5004C22.6183 11.9967 22.7147 12.0932 22.7147 12.211V21.8539C22.7147 22.328 22.3317 22.711 21.8576 22.711Z"
        fill={fill}
      />
      <path
        d="M18.9222 3.65983L17.524 2.26162C17.4956 2.23315 17.4759 2.19731 17.4669 2.15817C17.4579 2.11902 17.4601 2.07815 17.4733 2.0402C17.4864 2.00225 17.5099 1.96874 17.5411 1.94348C17.5723 1.91822 17.61 1.90223 17.6498 1.89733L22.4552 1.33483C22.5918 1.31876 22.7097 1.43394 22.6936 1.57322L22.1311 6.37858C22.1097 6.55537 21.8927 6.63037 21.7668 6.50447L20.3632 5.1009L13.5007 11.9634C13.4177 12.0464 13.2811 12.0464 13.1981 11.9634L12.0623 10.8277C11.9793 10.7447 11.9793 10.608 12.0623 10.525L15.4923 7.09242L18.9222 3.65983Z"
        fill={fill}
      />
    </svg>
  );
};
