import {useState, useEffect} from 'react';
import AppNavMenu from './AppNavMenu';
import useGetUserNavMenuItems from '@hooks/useGetUserNavMenuItems';
import useGetAdminNavMenuItem from '@hooks/useGetAdminNavMenuItems';
import {
  UserPaths,
  ApprovalsPaths,
  ExploreSubMenuPaths,
} from '@utils/ClientPaths';
import {useNavigate, useLocation} from 'react-router-dom';
import usePathInfo from '@hooks/usePathInfo';
import {SubMenuKeys} from '@blocks/appNav/AppNavMenu';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {FinanceType} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IAppNavMenuContainerProps {
  closeMobileMenu?: () => void;
}

function AppNavMenuContainer({closeMobileMenu}: IAppNavMenuContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {isAdminPath} = usePathInfo();

  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();

  const isDirectBilling =
    isFeatureFlagOn.DirectBilling &&
    getLearningBudgetInfoQuery.data?.financeType === FinanceType.DirectBilling;

  const getDefaultSubMenuKeysState = () => {
    if (ApprovalsPaths.some((path) => pathname.includes(path)))
      return [SubMenuKeys.Approvals];
    if (ExploreSubMenuPaths.some((path) => pathname.includes(path)))
      return [SubMenuKeys.Explore];
    return [];
  };

  const [openSubMenuKeys, setOpenSubMenuKeys] = useState<SubMenuKeys[]>(
    getDefaultSubMenuKeysState
  );
  const userNavMenuItems = useGetUserNavMenuItems();
  const adminNavMenuItems = useGetAdminNavMenuItem();

  useEffect(() => {
    setOpenSubMenuKeys(getDefaultSubMenuKeysState());
  }, [isAdminPath]);

  // Event Handlers
  const handleClickMenuItem = (path: UserPaths) => {
    navigate(path);
    closeMobileMenu && closeMobileMenu();
  };
  //Comment from @zachfitz: AntD Menu doesn't have an accordion behavior for menus/sub-menus, but it is available for the Collapse component.
  //If we end up having more of these menus require that UX, it may make sense to extract the behavior we have here and extend Menu with it, so it's available as a reusable option.
  const handleOpenChange = (subMenuKeys: SubMenuKeys[]) => {
    const latestOpenKey = subMenuKeys.find(
      (key) => openSubMenuKeys.indexOf(key) === -1
    );
    setOpenSubMenuKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  // Nav Menu Item Styling Calculation
  const isSubMenuItemSelected = (path) => pathname.includes(path);
  return (
    <AppNavMenu
      isAdminPath={isAdminPath}
      isDirectBilling={isDirectBilling}
      isSubMenuItemSelected={isSubMenuItemSelected}
      navMenuItems={isAdminPath ? adminNavMenuItems : userNavMenuItems}
      onClickMenuItem={handleClickMenuItem}
      onOpenChange={handleOpenChange}
      openSubMenuKeys={openSubMenuKeys}
      pathname={pathname}
    />
  );
}

export default AppNavMenuContainer;
