// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateCustomProgramContentPayload.cs

import {z} from 'zod';
import {BaseCustomProgramContentPayloadSchema} from './BaseCustomProgramContentPayloadSchema.schema';

const schema = z.object({
  id: z.number(),
});

export const UpdateCustomProgramContentPayloadSchema =
  BaseCustomProgramContentPayloadSchema.merge(schema);
