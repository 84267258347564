// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeDetailVm.cs

import {z} from 'zod';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

export const UserAcademyInsightSchema = z.object({
  academyId: z.number(),
  dateStarted: z.string(),
  name: z.string(),
  status: UserItemStatusVMSchema,
});
