// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/FinanceType.cs

enum FinanceType {
  Loan,
  ISA,
  Scholarship,
  Discount,
  Prepayment,
  TuitionReimbursement,
  CompletionBonus,
  Other,
  ExternalReimbursement,
  DirectBilling,
}

export default FinanceType;
