// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewLevelReport.cs

import {z} from 'zod';
import {AcademyOverviewStepReportVmSchema} from './AcademyOverviewStepReportVmSchema.schema';

export const AcademyOverviewLevelReportVmSchema = z.object({
  academyLevelId: z.number(),
  academyLevelName: z.string(),
  completionPercentage: z.number(),
  order: z.number(),
  steps: z.array(AcademyOverviewStepReportVmSchema),
});
