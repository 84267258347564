import {i18n, k} from '@i18n/translate';
import {AdminPaths} from '@utils/ClientPaths';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {
  getAdminUserRequestApprovalsRq,
  getIncentiveAdminFinanceRq,
  getIncentivesCountRq,
} from '@store/apiEndpoints';
import {ApprovalsDiscriminator, ApprovalStatus} from '@generated/enums';
import {useQuery} from 'react-query';
import {cacheForeverOptions, simpleQueryFn} from '@store/queryClient';
import {
  ApprovalRequestDetailsVM,
  IncentivesCountVM,
  UserRequestDetailsVM,
} from '@models/serverModels';
import usePathInfo from '@hooks/usePathInfo';
import {NavMenuItem, SubMenuKeys} from '@blocks/appNav/AppNavMenu';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import SnippetsOutlined from '@ant-design/icons/lib/icons/SnippetsOutlined';
import TrophyOutlined from '@ant-design/icons/lib/icons/TrophyOutlined';
import RocketOutlined from '@ant-design/icons/lib/icons/RocketOutlined';
import BarChartOutlined from '@ant-design/icons/lib/icons/BarChartOutlined';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {transformApprovalResponse} from '@utils/itemUtils';
import {ApprovalsDiscriminatorWithTime} from '@models/clientEnums';
import {UserOutlined} from '@ant-design/icons';
import {useAuth} from '@utils/oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function useGetAdminNavMenuItem(): NavMenuItem[] {
  const {isAdminPath} = usePathInfo();
  const {user} = useAuth();

  const {isFeatureFlagOn} = useFeatureFlags();
  const isTimeIncentivesOn = isFeatureFlagOn.TimeIncentives;
  const isAcademiesOn = isFeatureFlagOn.Academies;
  const isLicensesOn = isFeatureFlagOn.Licenses;
  const isIncentivesOn = isFeatureFlagOn.Incentives;
  const isHideAdminSettings = isFeatureFlagOn.HideAdminSettings;
  const isSunsetInitiativesOn = isFeatureFlagOn.SunsetInitiatives;

  const incentivesCountRq = getIncentivesCountRq();
  const incentivesCountQuery = useQuery<IncentivesCountVM>(
    incentivesCountRq.queryKey,
    () => simpleQueryFn(incentivesCountRq.path),
    cacheForeverOptions
  );

  // Determine which manager nav menu items to show based on counts and flags
  const showFinanceApprovalNavMenuItem =
    user?.isAdmin &&
    !!incentivesCountQuery.data?.financeIncentivesAvailableToUsersCount;
  const showTimeApprovalNavMenuItem =
    user?.isAdmin &&
    isTimeIncentivesOn &&
    !!incentivesCountQuery.data?.timeIncentivesAvailableToUsersCount;

  // Fetch Data For Badge Counts
  const adminApprovalsProgramRq = getAdminUserRequestApprovalsRq(
    ApprovalsDiscriminator.Program,
    ApprovalStatus.Requested
  );
  const getAdminApprovalsQueryProgram = useQuery<ApprovalRequestDetailsVM[]>(
    adminApprovalsProgramRq.queryKey,
    () => simpleQueryFn(adminApprovalsProgramRq.path),
    {
      enabled: isAdminPath,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );
  const adminUserRequestApprovalsRq = getAdminUserRequestApprovalsRq(
    ApprovalsDiscriminator.License,
    ApprovalStatus.Requested
  );
  const getAdminUserRequestApprovalsQuery = useQuery<UserRequestDetailsVM[]>(
    adminUserRequestApprovalsRq.queryKey,
    () =>
      simpleQueryFn(
        adminUserRequestApprovalsRq.path,
        transformApprovalResponse
      ),
    {enabled: isAdminPath, refetchOnWindowFocus: false}
  );
  const adminApprovalsTimeRq = getAdminUserRequestApprovalsRq(
    ApprovalsDiscriminatorWithTime.Time as unknown as ApprovalsDiscriminator,
    ApprovalStatus.Requested
  );
  const getAdminApprovalsQueryTime = useQuery<ApprovalRequestDetailsVM[]>(
    adminApprovalsTimeRq.queryKey,
    () => simpleQueryFn(adminApprovalsTimeRq.path),
    {
      enabled: isAdminPath && isTimeIncentivesOn,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );
  const adminApprovalsFinanceRq = getAdminUserRequestApprovalsRq(
    ApprovalsDiscriminator.Incentive,
    ApprovalStatus.Requested
  );
  const getAdminApprovalsQueryFinance = useQuery<ApprovalRequestDetailsVM[]>(
    adminApprovalsFinanceRq.queryKey,
    () => simpleQueryFn(adminApprovalsFinanceRq.path),
    {
      enabled: isAdminPath,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );

  const adminApprovalsResourceRq = getAdminUserRequestApprovalsRq(
    ApprovalsDiscriminator.LearningResource,
    ApprovalStatus.Requested
  );
  const getAdminApprovalsQueryResource = useQuery(
    adminApprovalsResourceRq.queryKey,
    () => simpleQueryFn(adminApprovalsResourceRq.path),
    {
      enabled: isAdminPath,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );

  const getLearningBudgetInfoQuery = getIncentiveAdminFinanceRq();
  const {data: learningBudgetInfo} = useQuery(
    getLearningBudgetInfoQuery.queryKey,
    () => simpleQueryFn(getLearningBudgetInfoQuery.path)
  );
  const virtualCard = 'Prepayment';
  const isVirtualCard = learningBudgetInfo?.[0]?.incentiveType === virtualCard;

  // Calculate Total Pending Approval Requests
  const allPendingRequestsCount =
    (getAdminApprovalsQueryProgram?.data?.length || 0) +
    (getAdminApprovalsQueryFinance?.data?.length || 0) +
    (getAdminApprovalsQueryTime?.data?.length || 0) +
    (getAdminApprovalsQueryResource?.data?.length || 0) +
    (getAdminUserRequestApprovalsQuery?.data?.length || 0);
  // Nav Menu Items
  const navMenuItems = [
    {
      label: i18n.t(k[SubMenuKeys.Learning]),
      icon: <SnippetsOutlined aria-hidden="true" />,
      subItems: [
        {
          label: i18n.t(k.PROGRAM__PLURAL),
          path: AdminPaths.Programs,
        },
        isAcademiesOn && {
          label: i18n.t(k.ACADEMY__PLURAL),
          path: AdminPaths.Academies,
        },
        isLicensesOn && {
          label: i18n.t(k.LICENSE__PLURAL),
          path: AdminPaths.Licenses,
        },
      ].filter(Boolean),
    },
    isIncentivesOn && {
      label: i18n.t(k.INCENTIVE__PLURAL),
      icon: <TrophyOutlined aria-hidden="true" />,
      path: AdminPaths.InvestmentsIncentives,
    },
    !isSunsetInitiativesOn && {
      label: i18n.t(k.INITIATIVE__PLURAL),
      icon: <RocketOutlined aria-hidden="true" />,
      path: AdminPaths.Initiatives,
    },
    {
      label: i18n.t(k[SubMenuKeys.Approvals]),
      icon: (
        <span aria-hidden="true">
          <AuditOutlined
            aria-hidden="true"
            title=""
            aria-label=""
            aria-labelledby="test"
          />
        </span>
      ),
      count: allPendingRequestsCount,
      subItems: [
        {
          label: i18n.t(k.PROGRAM__PLURAL),
          path: AdminPaths.ApprovalsProgram,
          count:
            getAdminApprovalsQueryProgram?.data?.length +
              getAdminUserRequestApprovalsQuery?.data?.length || 0,
        },
        showFinanceApprovalNavMenuItem && {
          label: i18n.t(k.GENERIC__FINANCE),
          path: AdminPaths.ApprovalsFinance,
          count: getAdminApprovalsQueryFinance?.data?.length || 0,
        },
        showTimeApprovalNavMenuItem && {
          label: i18n.t(k.TIME),
          path: AdminPaths.ApprovalsTime,
          count: getAdminApprovalsQueryTime?.data?.length || 0,
        },
        {
          label: i18n.t(k.RESOURCE__PLURAL),
          path: AdminPaths.ApprovalsResource,
          count: getAdminApprovalsQueryResource?.data?.length || 0,
        },
      ].filter(Boolean),
    },
    {
      label: i18n.t(k.REPORT__PLURAL),
      icon: <BarChartOutlined aria-hidden="true" />,
      path: AdminPaths.Reports,
    },
    {
      label: i18n.t(k.GENERIC__PEOPLE),
      icon: <UserOutlined aria-hidden="true" />,
      path: AdminPaths.People,
    },
    isHideAdminSettings &&
      isVirtualCard && {
        label: `${i18n.t(k.ADMIN__SETTING__PLURAL)}`,
        icon: <SettingOutlined aria-hidden="true" />,
        path: AdminPaths.Prepayment,
      },
    !isProductionAny && !isBetaAny && {label: 'QA', path: AdminPaths.Qa},
    !isProductionAny &&
      !isBetaAny && {
        label: 'Developer Admin',
        path: AdminPaths.DeveloperAdmin,
      },
  ].filter(Boolean);

  return navMenuItems;
}

export default useGetAdminNavMenuItem;
