// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Discussions/Responses/CursorEnvelopeMessageResponse.cs

import {z} from 'zod';
import {MessageDtoSchema} from './MessageDtoSchema.schema';
import {CursorLinksSchema} from './CursorLinksSchema.schema';
import {CursorMetaSchema} from './CursorMetaSchema.schema';

export const CursorEnvelopeMessageResponseSchema = z.object({
  data: z.array(MessageDtoSchema),
  links: CursorLinksSchema,
  meta: CursorMetaSchema,
});
