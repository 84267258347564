import DesktopSideNav from '@blocks/appNav/DesktopSideNav';
import MobileAppHeaderWithMobileMenu from '@blocks/appNav/MobileAppHeaderWithMobileMenu';
import CardIssuerTerms from '@components/CardIssuerTerms';
import DatadogErrorBoundary from '@components/reusable/ErrorBoundary/DataDogErrorBoundary';
import Request from '@components/user/components/request';
import {RequestMenuButtonAndDrawerViewContainer} from '@components/user/components/request/RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import {useApolloLayoutForAcm} from '@hooks/apollo';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {useBuildRouteElements} from '@hooks/useBuildRouteElements';
import useGetEnabledIntegratedPrimaryRoutes from '@hooks/useGetEnabledIntegratedPrimaryRoutes';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {Theme} from '@utils/Theme';
import {Layout} from 'apollo/cdk/src';
import * as React from 'react';
import {useState} from 'react';
import {Routes} from 'react-router-dom';
import styled from 'styled-components';
import LoadingSpinner from './elements/loadingSpinner/LoadingSpinner';
import PrimaryRoutesShared from './PrimaryRoutesShared';
import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const MainContentContainer = styled.main<{fullWidth?: boolean}>`
  position: fixed;
  left: ${({fullWidth}) => (fullWidth ? 0 : '272px')};
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  background: ${COLORS.Neutral50};
  @media (max-width: ${Breakpoints.Desktop}px) {
    left: 0;
    top: 68px;
  }
`;

/*
|--------------------------------------------------------------------------
| Main Routes Component
|--------------------------------------------------------------------------
*/
interface PrimaryRoutesProps {
  flagsReady: boolean;
  hasApolloFlags: boolean;
  hidePrimaryNavigation: boolean;
  isAdmin: boolean;
  isIntegratedUser: boolean;
  isLxpAdmin: boolean;
  isManager: boolean;
}

const PrimaryRoutes = ({
  flagsReady,
  hasApolloFlags,
  hidePrimaryNavigation,
  isAdmin,
  isLxpAdmin,
  isIntegratedUser,
  isManager,
}: PrimaryRoutesProps) => {
  const [{showApolloLayout, layoutAspect}, {isAdminPath, isDirectBilling}] =
    useApolloLayoutForAcm(isLxpAdmin);

  const [drawerVisible, setDrawerVisible] = useState(false);

  return !flagsReady ? (
    <LoadingSpinner />
  ) : showApolloLayout && hasApolloFlags ? (
    !hidePrimaryNavigation ? (
      <Layout
        configuration={layoutAspect}
        onAddContent={() => setDrawerVisible(true)}>
        <div className="tw-grid-apollo tw-mt-10">
          <div className="tw-col-span-full">
            <CardIssuerTerms />
            <PrimaryRoutesContent
              isAdmin={isAdmin}
              isIntegratedUser={isIntegratedUser}
              isManager={isManager}
            />
            {!isAdminPath && !isDirectBilling && (
              <RequestMenuButtonAndDrawerViewContainer>
                <Request
                  showButton={false}
                  drawerVisible={drawerVisible}
                  setDrawerVisible={setDrawerVisible}
                />
              </RequestMenuButtonAndDrawerViewContainer>
            )}
          </div>
        </div>
      </Layout>
    ) : (
      <>
        <CardIssuerTerms />
        <PrimaryRoutesContent
          isAdmin={isAdmin}
          isIntegratedUser={isIntegratedUser}
          isManager={isManager}
        />
      </>
    )
  ) : (
    // Legacy Layout
    <>
      <CardIssuerTerms />
      {!hidePrimaryNavigation && (
        <>
          <DesktopSideNav /> <MobileAppHeaderWithMobileMenu />
        </>
      )}
      <MainContentContainer
        id="main-content"
        tabIndex="-1"
        fullWidth={hidePrimaryNavigation}>
        <PrimaryRoutesContent
          isIntegratedUser={isIntegratedUser}
          isAdmin={isAdmin}
          isManager={isManager}
        />
      </MainContentContainer>
    </>
  );
};

interface PrimaryRoutesContentProps {
  isIntegratedUser: boolean;
  isAdmin: boolean;
  isManager: boolean;
}

const PrimaryRoutesContent = ({
  isIntegratedUser,
  isAdmin,
  isManager,
}: PrimaryRoutesContentProps) => {
  const integratedRoutes = useGetEnabledIntegratedPrimaryRoutes({
    isAdmin,
    isIntegratedUser,
    isManager,
  });
  const integratedPrimaryRouteElements =
    useBuildRouteElements(integratedRoutes);
  return (
    <DatadogErrorBoundary>
      <Theme>
        <React.Suspense fallback={<LoadingSpinner />}>
          {isIntegratedUser ? (
            <Routes>{integratedPrimaryRouteElements}</Routes>
          ) : (
            <PrimaryRoutesShared isAdmin={isAdmin} isManager={isManager} />
          )}
        </React.Suspense>
      </Theme>
    </DatadogErrorBoundary>
  );
};

export default PrimaryRoutes;
