// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/ItemDetails/ProgramProgressVM.cs

import {z} from 'zod';
import {ProgramProgressUpdateSchema} from './ProgramProgressUpdateSchema.schema';
import {UserProgressStatusSchema} from './UserProgressStatusSchema.schema';

const schema = z.object({
  date: z.string(),
  status: UserProgressStatusSchema,
  week: z.number(),
});

export const ProgramProgressVMSchema =
  ProgramProgressUpdateSchema.merge(schema);
