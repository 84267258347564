import {ArrowRightOutlined} from '@ant-design/icons';
import Icon from '@blocks/Icon/Icon';
import {StyledButton} from '@blocks/StartLearningCard/StartLearningCard.styled';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import RequestMenuButton from '../RequestMenuButton/RequestMenuButton.view';
import RequestMenuDrawer from '../RequestMenuDrawer/RequestMenuDrawer.view';
import {
  REQUEST_MENU_BUTTON_AND_DRAWER_BUTTON_TEST_ID,
  REQUEST_MENU_BUTTON_AND_DRAWER_DRAWER_TEST_ID,
} from './RequestMenuButtonAndDrawer.constants';
import {RequestMenuButtonAndDrawerViewProps} from './RequestMenuButtonAndDrawer.interfaces';

function RequestMenuButtonAndDrawerView({
  buttonAltStyle,
  buttonTitle,
  drawerVisible,
  form,
  formVisible,
  handleOnClickMarketplaceLink,
  handleOnClickOption,
  handleOnClickProgramFinder,
  handleOnClickReset,
  handleOnCloseSuccess,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  marketplaceLinkText,
  navigate,
  onSubmit,
  requestType,
  requestTypeOptions,
  rerequestResource,
  reset,
  selectionIndex,
  setDrawerVisible,
  setIsSubmitButtonLoading,
  setOnSubmit,
  setRequestType,
  setTotal,
  showFindProgramOption,
  showFooter,
  showButton,
  total,
}: RequestMenuButtonAndDrawerViewProps) {
  showButton = showButton ?? true;

  return (
    <>
      {showButton ? (
        buttonAltStyle ? (
          <StyledButton
            onClick={() => setDrawerVisible(!drawerVisible)}
            tag={ButtonTags.Primary}>
            {buttonTitle}
            <Icon icon={<ArrowRightOutlined aria-hidden="true" />} size="L" />
          </StyledButton>
        ) : (
          <RequestMenuButton
            data-testid={REQUEST_MENU_BUTTON_AND_DRAWER_BUTTON_TEST_ID}
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            title={buttonTitle}
          />
        )
      ) : null}

      {drawerVisible && (
        <div data-testid={REQUEST_MENU_BUTTON_AND_DRAWER_DRAWER_TEST_ID}>
          <RequestMenuDrawer
            form={form}
            formVisible={formVisible}
            handleOnClickMarketplaceLink={handleOnClickMarketplaceLink}
            handleOnClickOption={handleOnClickOption}
            handleOnClickProgramFinder={handleOnClickProgramFinder}
            handleOnClickReset={handleOnClickReset}
            handleOnCloseSuccess={handleOnCloseSuccess}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isSubmitButtonLoading={isSubmitButtonLoading}
            marketplaceLinkText={marketplaceLinkText}
            navigate={navigate}
            onSubmit={onSubmit}
            requestType={requestType}
            requestTypeOptions={requestTypeOptions}
            rerequestResource={rerequestResource}
            reset={reset}
            selectionIndex={selectionIndex}
            setDrawerVisible={setDrawerVisible}
            setIsSubmitButtonLoading={setIsSubmitButtonLoading}
            setOnSubmit={setOnSubmit}
            setRequestType={setRequestType}
            setTotal={setTotal}
            showFindProgramOption={showFindProgramOption}
            showFooter={showFooter}
            total={total}
          />
        </div>
      )}
    </>
  );
}

export default RequestMenuButtonAndDrawerView;
