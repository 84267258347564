// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewUserPlanItem.cs

import {z} from 'zod';
import {PlanItemTypeSchema} from './PlanItemTypeSchema.schema';

export const NewUserPlanItemSchema = z.object({
  optionId: z.number(),
  planId: z.number().nullable().optional(),
  planItemType: PlanItemTypeSchema,
});
