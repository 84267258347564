import {
  IncentiveType,
  UserProgramStatus,
  ReportAccessType,
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  CurrencyCode,
  CustomizableLearningCategory,
  PlanItemType,
  AddFundsToCreditCardOptions,
} from '@generated/enums';
import {
  CostCenterOverridePayload,
  CostCenterUserRequestOverridePayload,
  FinanceIncentivePayload,
  InitiativePayload,
  LicensePayload,
  NewUserEvent,
  PreApprovalApplication,
  RemoveUserPlanResourcePayload,
  RequestPreApprovalPayload,
  RequestResourceApprovalPayload,
  TimeIncentivePayload,
  UpdateInitiativeStatus,
  UpdateUserEvent,
  UserPlanAcademyEventRSVPUpdatePayload,
  UserPlanProjectSubmission,
  LicenseUpdatePayload,
  NewReimbursementApprovalApplicationVM,
} from '@models/serverModels';
import {AdminReportsTabName} from '@components/admin/pages/reports/store/interface';
import {AdminPaths} from '@utils/ClientPaths';
import querystring from 'querystring';
import {ReimbursementPayload} from '@models/api/finance/payloads';
import {NewDevCompany, NewDevUser} from '@generated/interfaces';
import {CompleteSectionContentVM, PlanItem} from '@models/clientModels';
import {mapIncentiveTypeToString} from '@utils/enumMapping/IncentiveTypeEnumMapper';
export interface ReactQueryRequest {
  queryKey: string[];
  path: string;
}

// mutations
export interface ReactMutateRequest {
  path: string;
  payload: object;
}

export interface ReactQueryHookArgs<ReturnDataType> {
  onSuccess?: (data?: ReturnDataType) => void;
  onError?: () => void;
}

export const addQueryParams = (
  baseUrl: string,
  queryParams: string[]
): string => {
  if (queryParams.length) {
    return queryParams.reduce(
      (previousValue, currentValue, index) =>
        index === 0
          ? `${previousValue}?${currentValue}`
          : `${previousValue}&${currentValue}`,
      baseUrl
    );
  }

  return baseUrl;
};

// INCENTIVE

export const getAddFinanceIncentiveRm = (
  incentiveToAdd: FinanceIncentivePayload
): ReactMutateRequest => {
  const path = `incentive/add-finance-incentive`;
  return {
    path,
    payload: incentiveToAdd,
  };
};

export const getUpdateFinanceIncentiveRm = (
  incentiveToAdd: FinanceIncentivePayload
): ReactMutateRequest => {
  const path = `incentive/update-finance-incentive`;
  return {
    path,
    payload: incentiveToAdd,
  };
};

export const getAddTimeIncentiveRm = (
  incentiveToAdd: TimeIncentivePayload
): ReactMutateRequest => {
  const path = `incentive/add-time-incentive`;
  return {
    path,
    payload: incentiveToAdd,
  };
};

export const getUpdateTimeIncentiveRm = (
  incentiveToAdd: TimeIncentivePayload
): ReactMutateRequest => {
  const path = `incentive/update-time-incentive`;
  return {
    path,
    payload: incentiveToAdd,
  };
};

export const getDeleteCompanyIncentiveRm = (
  incentiveType: IncentiveType,
  incentiveId: number
): ReactMutateRequest => {
  const path = `incentive/delete-incentive/${mapIncentiveTypeToString(
    incentiveType
  )}/${incentiveId}`;
  const payload = {};
  return {
    path,
    payload,
  };
};

export const getIncentivesRq = (
  incentiveType: PlanItemType.Time | PlanItemType.Finance
): ReactQueryRequest => {
  const incentiveString =
    incentiveType === PlanItemType.Time ? 'time' : 'finance';
  const path = `incentive/get-incentives/${incentiveString}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getInitiativesRq = (): ReactQueryRequest => {
  // type is InitiativeAdminVM[]
  const path = `initiative/get-initiatives-admin`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getInitiativeIncentivesRq = (
  incentiveType: IncentiveType,
  initiativeId?: string | number
): ReactQueryRequest => {
  // type is TimeIncentiveVM[] | FinanceIncentiveVM
  const baseUrl = `incentive/get-initiative-incentives/${incentiveType
    .toString()
    .toLowerCase()}`;
  const queryKey = [baseUrl];
  const queryParams = [];

  initiativeId && queryParams.push(`initiativeId=${initiativeId}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getFinanceIncentiveDetailRq = (
  incentiveId: number
): ReactQueryRequest => {
  const path = `incentive/get-details/finance/${incentiveId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getTimeIncentiveDetailRq = (
  incentiveId: number
): ReactQueryRequest => {
  const path = `incentive/get-details/time/${incentiveId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

// 'incentive/get-incentives-admin/finance'
export const getIncentiveAdminFinanceRq = (): ReactQueryRequest => {
  const path = 'incentive/get-incentives-admin/finance';
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getIncentiveAdminTimeRq = (): ReactQueryRequest => {
  const path = 'incentive/get-incentives-admin/time';
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getInitiativeDetailsRq = (
  initiativeId: number
): ReactQueryRequest => {
  // type is InitiativePayload
  const baseUrl = 'initiative/get-details';
  const path = `${baseUrl}/${initiativeId}`;
  const queryKey = [baseUrl, String(initiativeId)];

  return {
    path,
    queryKey,
  };
};

export const getAddInitiativeRm = (
  payload: InitiativePayload
): ReactMutateRequest => {
  const path = 'initiative/add-initiative';

  return {
    path,
    payload,
  };
};

export const getUpdateInitiativeRm = (
  payload: InitiativePayload
): ReactMutateRequest => {
  const path = 'initiative/update-initiative';

  return {
    path,
    payload,
  };
};

export const getUpdateInitiativesStatusRm = (
  payload: UpdateInitiativeStatus
): ReactMutateRequest => {
  const path = `initiative/update-initiative-status`;

  return {
    path,
    payload,
  };
};

export const getDeleteInitiativesRm = (
  initiativeId: number | string
): ReactMutateRequest => {
  const path = `initiative/delete-initiative/${initiativeId}`;

  return {
    path,
    payload: {},
  };
};

export const getFinanceIncentiveInitiativesRq = (
  incentiveId: number | string
): ReactQueryRequest => {
  // type is IncentiveInitiativeProgramVM[]
  const baseUrl = 'incentive/get-incentive-initiatives/finance';
  const path = `${baseUrl}/${incentiveId}`;
  const queryKey = [baseUrl, String(incentiveId)];

  return {
    path,
    queryKey,
  };
};

export const getFinanceIncentiveProgramTypesRq = (
  incentiveId: number | string
): ReactQueryRequest => {
  // type is ProgramFilterVM[]
  const baseUrl = 'incentive/get-incentive-program-types/finance';
  const path = `${baseUrl}/${incentiveId}`;
  const queryKey = [baseUrl, String(incentiveId)];

  return {
    path,
    queryKey,
  };
};

export const getTimeIncentiveProgramTypesRq = (
  incentiveId: number | string
): ReactQueryRequest => {
  // type is ProgramFilterVM[]
  const baseUrl = 'incentive/get-incentive-program-types/time';
  const path = `${baseUrl}/${incentiveId}`;
  const queryKey = [baseUrl, String(incentiveId)];

  return {
    path,
    queryKey,
  };
};

// PAYMENT
export const getCardOverviewRq = (
  planItemId: string | number
): ReactQueryRequest => {
  const path = `payment/get-card-overview/${planItemId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getCompleteProgramContentRm = (
  payload: CompleteSectionContentVM
): ReactMutateRequest => {
  const path = `/user-plan/update-custom-program-content`;

  return {
    path,
    payload,
  };
};

export const getCreateCardRm = (
  planItemId: string | number
): ReactMutateRequest => {
  const path = `/payment/restore-card?planItemId=${planItemId}`;

  return {
    path,
    payload: {},
  };
};

export const getCardPaymentInfoRq = (cardId: string): ReactQueryRequest => {
  const baseUrl = 'payment/card-payment-info';
  const path = `${baseUrl}/${cardId}`;
  const queryKey = [baseUrl, cardId];

  return {
    path,
    queryKey,
  };
};

export const getIncreaseCardSpendingLimitRm = (
  userFinanceIncentiveId: number,
  amount: number,
  isAdvance: boolean,
  option: AddFundsToCreditCardOptions
): ReactMutateRequest => {
  const path = `payment/increase-credit-card-spending-limit`;
  const payload = {
    userFinanceIncentiveId: userFinanceIncentiveId,
    amount: amount,
    isAdvance: isAdvance,
    option: option,
  };

  return {
    path,
    payload,
  };
};

export const getUpdateUserBudgetRm = (
  userFinanceIncentiveId: number,
  budgetLimit: number,
  balance: number,
  note?: string
): ReactMutateRequest => {
  const path = `payment/update-user-budget`;
  const payload = {
    userFinanceIncentiveId: userFinanceIncentiveId,
    budgetLimit: budgetLimit,
    balance: balance,
    note: note,
  };

  return {
    path,
    payload,
  };
};

// ADMIN

// ADMIN - PREPAYMENT - STRIPE / PLAID
export const getPlaidLinkConfigRq = () => {
  const path = '/payment/get-plaid-link-config';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getConnectedBankAccountsRq = () => {
  const path = '/payment/get-connected-bank-accounts';
  const queryKey = path;
  return {
    path,
    queryKey,
  };
};

export const getIssuingBalanceDetailsRq = () => {
  const path = '/payment/get-issuing-balance-details';
  const queryKey = path;
  return {
    path,
    queryKey,
  };
};

export const getIssuingBalanceHistoryRq = (): ReactQueryRequest => {
  const path = '/payment/get-issuing-balance-history';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getIsOnboardingStartedRq = (): ReactQueryRequest => {
  const path = '/payment/is-onboarding-started';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getOnboardingLinkRq = (): ReactQueryRequest => {
  const path = '/payment/get-onboarding-link';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getAddCompanyBankAccountRm = (
  publicToken: string,
  accountId: string
): ReactMutateRequest => {
  const path = '/payment/add-company-bank-account';
  const payload = {PublicToken: publicToken, AccountId: accountId};
  return {
    path,
    payload,
  };
};

export const getDefaultBankAccountRm = (): ReactMutateRequest => {
  const path = '/payment/add-default-bank-account-to-connected-account';
  return {
    path,
    payload: {},
  };
};

export const getDeleteCompanyBankAccountRm = (
  id: string
): ReactMutateRequest => {
  const path = `/payment/delete-company-bank-account/${id}`;

  return {
    path,
    payload: {},
  };
};

export const getTopUpIssuingBalanceRm = (
  amount: number,
  description: string
): ReactMutateRequest => {
  const path = '/payment/top-up-issuing-balance';
  const payload = {Amount: amount, Description: description};
  return {
    path,
    payload,
  };
};

// INSIGHTS DASHBOARD

export const getEmployeeEngagementRq = (
  year: number | string
): ReactQueryRequest => {
  const baseUrl = 'insights/get-employee-engagement';
  const queryKey = [baseUrl];
  const queryParams = [];

  queryParams.push(`year=${year}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getFinanceByProgramTypeRq = (
  year: number | string
): ReactQueryRequest => {
  const baseUrl = 'insights/get-finance-approved-vs-spent';
  const queryKey = [baseUrl];
  const queryParams = [];

  queryParams.push(`year=${year}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getProgressUpdatesBySummaryRq = (
  year: number | string
): ReactQueryRequest => {
  const baseUrl = 'insights/get-progress-updates-by-summary';
  const queryKey = [baseUrl];
  const queryParams = [];

  queryParams.push(`year=${year}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getPopularProgramsRq = (
  year: number | string
): ReactQueryRequest => {
  const baseUrl = 'insights/get-popular-programs';
  const queryKey = [baseUrl];
  const queryParams = [];

  queryParams.push(`year=${year}`);
  queryParams.push(`limit=5`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

// ADMIN APPROVALS

export const getAdminUserRequestApprovalsRq = (
  type: ApprovalsDiscriminator,
  status: ApprovalStatus
): ReactQueryRequest => {
  const baseUrl = 'admin-approvals/get-user-request-approvals';
  const queryKey = [baseUrl];
  const queryParams = [];

  const typeParam = `type=${type || 0}`;
  queryParams.push(typeParam);
  queryKey.push(`type=${type || 0}`);

  const statusParam = `status=${status}`;
  queryParams.push(statusParam);
  queryKey.push(statusParam);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getAdminPendingManagerUserRequestApprovalsRq = (
  type: ApprovalsDiscriminator
): ReactQueryRequest => {
  const baseUrl = 'admin-approvals/get-pending-manager-user-request-approvals';
  const queryKey = [baseUrl];
  const queryParams = [];

  const typeParam = `type=${type || 0}`;
  queryParams.push(typeParam);
  queryKey.push(`type=${type || 0}`);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getAdminApprovalDetailsRq = (
  type: ApprovalType,
  approvalId: number
): ReactQueryRequest => {
  const baseUrl = 'admin-approvals/get-approval-details';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (type !== null && type !== undefined) {
    queryParams.push(`type=${type}`);
    queryKey.push(type.toString());
  }

  if (approvalId) {
    queryParams.push(`id=${approvalId}`);
    queryKey.push(approvalId.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getAdminUpdateUserRequestApprovalRm = (
  approvalId: number,
  approvalType: ApprovalType,
  approvalStatus: ApprovalStatus,
  notes: string,
  costCenter: string
): ReactMutateRequest => {
  const path = 'admin-approvals/update-user-request-approval';
  const payload = {
    id: approvalId,
    type: approvalType,
    status: approvalStatus,
    notes: notes,
    costCenter: costCenter,
  };
  return {
    path,
    payload,
  };
};

// USER PLAN

export const getUserCalendarEventsRq = (
  startDate: string,
  endDate: string
): ReactQueryRequest => {
  const baseUrl = 'time/get-user-events';
  const queryKey = [baseUrl];
  const queryParams = [];

  queryParams.push(`from=${startDate}`);
  queryKey.push(startDate);

  queryParams.push(`to=${endDate}`);
  queryKey.push(endDate);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getUserLearningBudgetInfoRq = (): ReactQueryRequest => {
  const baseUrl = 'user-plan/get-finance-learning-budgets';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getStartedTimeIncentivePrograms = (): ReactQueryRequest => {
  const baseUrl = 'incentive/get-started/time';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getStartedFinanceIncentivePrograms = (): ReactQueryRequest => {
  const baseUrl = 'incentive/get-started/finance';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getUserProgramPlanRq = (
  progressUpdatesLimit = 2
): ReactQueryRequest => {
  const baseUrl = 'user-plan/get-program-items';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (progressUpdatesLimit) {
    queryParams.push(`progressUpdatesLimit=${progressUpdatesLimit}`);
    queryKey.push(progressUpdatesLimit.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getUserPreApprovedItemsRq = (): ReactQueryRequest => {
  const baseUrl = '/user-plan/get-preapproved-plan-items';
  const queryKey = [baseUrl];
  return {
    path: baseUrl,
    queryKey,
  };
};

export const getUserTimePlanRq = (eventsLimit = 7): ReactQueryRequest => {
  const baseUrl = 'user-plan/get-time-items';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (eventsLimit) {
    queryParams.push(`eventsLimit=${eventsLimit}`);
    queryKey.push(eventsLimit.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

/**
 *
 * @param reimbursementsLimit
 * @param includePlb whether to include results for personal learning budgets or not
 */
export const getUserFinancePlanRq = (
  reimbursementsLimit = 7,
  includePlb = false
): ReactQueryRequest => {
  const baseUrl = 'user-plan/get-finance-items';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (reimbursementsLimit) {
    queryParams.push(`reimbursementsLimit=${reimbursementsLimit}`);
    queryKey.push(reimbursementsLimit.toString());
  }

  if (includePlb !== undefined) {
    queryParams.push(`includePlb=${includePlb}`);
    queryKey.push(includePlb.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getDeletePlanItemRm = (
  userPlanItemId: string | number
): ReactMutateRequest => {
  const baseUrl = `user-plan/delete-item`;
  const path = `${baseUrl}/${userPlanItemId}`;

  return {
    path,
    payload: {},
  };
};

export const getDeletePlanItemByResourceRm = (
  resourceId: string | number,
  itemType: PlanItemType
): ReactMutateRequest => {
  const path = `user-plan/delete-item/${itemType}/${resourceId}`;

  return {
    path,
    payload: {},
  };
};

export const getDeleteProgramPlanItemByItemIdRm = (
  userPlanItemId: string | number,
  amountSpent = 0
): ReactMutateRequest => {
  const baseUrl = `user-plan/delete-program-by-user-plan-item-id`;
  const queryParams = [];

  if (userPlanItemId) {
    queryParams.push(`userPlanItemId=${userPlanItemId}`);
  }

  if (amountSpent) {
    queryParams.push(`amountSpent=${amountSpent}`);
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    payload: {},
  };
};

export const getDeleteProgramPlanItemByProgramIdRm = (
  programId: string | number,
  amountSpent = 0
): ReactMutateRequest => {
  const baseUrl = `user-plan/delete-program-by-program-id`;
  const queryParams = [];

  if (programId) {
    queryParams.push(`programId=${programId}`);
  }

  if (amountSpent) {
    queryParams.push(`amountSpent=${amountSpent}`);
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    payload: {},
  };
};

export const getUpdateProgramStatusRm = (
  userPlanItemId: string | number,
  currentStatus: UserProgramStatus
): ReactMutateRequest => {
  const path = `user-plan/update-program-status`;
  const payload = {
    UserPlanItemId: userPlanItemId,
    Status: currentStatus,
  };

  return {
    path,
    payload,
  };
};

export const getFinanceIncentiveRq = (itemId: number | string) => {
  const baseUrl = `user-plan/get-item`;
  const path = `${baseUrl}/${itemId}`;
  const queryKey = [baseUrl, itemId];

  return {
    path,
    queryKey,
  };
};

export const getUserReimbursementsRq = (userPlanItemId: number | string) => {
  const baseUrl = `finance/get-user-reimbursements`;
  const path = `${baseUrl}/${userPlanItemId}`;
  const queryKey = [baseUrl, userPlanItemId];

  return {
    path,
    queryKey,
  };
};

export const getAddToPlanRm = (
  optionId: string | number,
  planItemType: PlanItemType
): ReactMutateRequest => {
  const path = 'user-plan/add-item';
  // we're not passing plan id although it was being passed before because backend looks it up. We can add it if needed.
  const payload = {OptionId: optionId, PlanItemType: planItemType};

  return {
    path,
    payload,
  };
};

export const getRequestPreApprovalRm = (
  payload: RequestPreApprovalPayload
): ReactMutateRequest => {
  const path = ['user-plan', 'request-pre-approval'].join('/');
  return {
    path,
    payload,
  };
};

export const getRequestAcademyItemPreApprovalRm = (
  payload: RequestPreApprovalPayload,
  academyId: number
): ReactMutateRequest => {
  const path = ['user-plan', 'request-approval', academyId].join('/');
  return {
    path,
    payload,
  };
};

// USER
export const getReimbursementDetailsRq = (
  planItmeId: string
): ReactQueryRequest => {
  const baseUrl = 'finance/get-reimbursement-targets';
  const path = `${baseUrl}/${planItmeId}`;
  const queryKey = [baseUrl, planItmeId];

  return {
    path,
    queryKey,
  };
};

export const getReimbursementImageURLRq = (id): ReactQueryRequest => {
  const baseUrl = 'finance/get-reimbursement-image-url';
  const path = `${baseUrl}/${id}`;
  const queryKey = [baseUrl, id];

  return {
    path,
    queryKey,
  };
};
export const getAttachmentURLRq = (id): ReactQueryRequest => {
  const baseUrl = 'attachments';
  const path = `${baseUrl}/${id}`;
  const queryKey = [baseUrl, id];

  return {
    path,
    queryKey,
  };
};
export const getUserCardTransactionsRq = (planItemId: number | string) => {
  const path = `payment/get-card-transactions/${planItemId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getPreApprovalFormUrlBases = (): string[] => [
  'user-plan/get-pre-approval-application',
  'user-plan/get-item-pre-approval-application',
];
export const getPreApprovalFormRq = (
  planItemType?: PlanItem,
  optionId?: number,
  userPlanItemId?: number,
  approvalRankType?: number
): ReactQueryRequest => {
  const bases = getPreApprovalFormUrlBases();
  const baseUrl = userPlanItemId == null ? bases[0] : bases[1];
  const queryKey = [baseUrl];
  const queryParams = [];

  if (userPlanItemId > 0) {
    queryParams.push(`itemId=${userPlanItemId}`);
    queryKey.push(String(userPlanItemId));
  }

  if (approvalRankType >= 0) {
    queryParams.push(`approvalRankType=${approvalRankType}`);
    queryKey.push(String(approvalRankType));
  }

  if (optionId > 0 && baseUrl === bases[0]) {
    queryParams.push(`optionId=${optionId}`);
    queryKey.push(String(optionId));

    queryParams.push(`planItemType=${planItemType}`);
    queryKey.push(String(planItemType));
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getPersonalLearningBudgetLimitRq = (): ReactQueryRequest => {
  const baseUrl = 'user-plan/get-plb-balance';

  const queryKey = [baseUrl];
  const queryParams = [];

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getRequestItemPreApprovalRm = (
  preApprovalApplication: PreApprovalApplication,
  userPlanItemId: number
): ReactMutateRequest => {
  const path = `user-plan/request-item-pre-approval`;
  const payload = {
    userPlanItemId,
    applicationDetails: preApprovalApplication,
  };

  return {
    path,
    payload,
  };
};

// implemented as a Mutation so it's not cached and because it functions like a mutation in practice

export interface AddNewProgram {
  providerId: number;
  providerName: string;
  programTypeId: number;
  title: string;
  shortDescription: string;
  cost: number;
  currency: CurrencyCode;
  url: string;
  presenceType: number;
  timeCommitmentType: number;
  programLength: number;
  programLengthUnit: number;
  programId?: number; // used when updating
}

export const getIncentivesCountRq = () => {
  const path = '/incentive/get-incentive-counts';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

// LOCALIZATION

export const getCurrencyExchangeRq = (): ReactQueryRequest => {
  const path = `localization/exchange-rates`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

// REPORTS

export const getTransactionsReportRq = (year?: number) => {
  const baseUrl = '/report/get-transactions-report';
  const queryKey = [baseUrl];
  const queryParams = [];
  if (year) {
    queryParams.push(`year=${year}`);
    queryKey.push(year.toString());
  }
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getPrepaymentReportRq = (year?: number) => {
  const baseUrl = '/report/get-prepayment-report';
  const queryKey = [baseUrl];
  const queryParams = [];
  if (year) {
    queryParams.push(`year=${year}`);
    queryKey.push(year.toString());
  }
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getUserCurrentFinanceIncentiveDetailsRq = (
  userFinanceIncentiveId: number
) => {
  const baseUrl = '/report/get-user-current-finance-incentive-details';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (userFinanceIncentiveId) {
    queryParams.push(`userFinanceIncentiveId=${userFinanceIncentiveId}`);
    queryKey.push(userFinanceIncentiveId.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getUserTransactionsReportRq = (userFinanceIncentiveId: number) => {
  const baseUrl = '/report/get-user-transactions-report';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (userFinanceIncentiveId) {
    queryParams.push(`userFinanceIncentiveId=${userFinanceIncentiveId}`);
    queryKey.push(userFinanceIncentiveId.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getAdminReportsPageTabPath = (
  tabName: AdminReportsTabName
): string => {
  return `${AdminPaths.Reports}?tab=${tabName}`;
};

export const getTimeAllocationDetailsPath = (id: string | number): string => {
  return `/user/time-detail/${id}`;
};

export const getCompaniesRq = (): ReactQueryRequest => {
  const path = 'dev/get-companies';
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const getDevAddUserRm = (payload: NewDevUser): ReactMutateRequest => {
  const path = 'dev/add-user';
  return {
    path,
    payload,
  };
};

export const getCreateCompanyRm = (
  payload: NewDevCompany
): ReactMutateRequest => {
  const path = 'dev/add-dev-company';
  return {
    path,
    payload,
  };
};

/*
|--------------------------------------------------------------------------
| Custom Program Reports
|--------------------------------------------------------------------------
*/

export const getCustomProgramActivityReportRq = (
  customProgramId: number
): ReactQueryRequest => {
  const baseUrl = ['report', 'get-custom-program-activity-report'].join('/');
  const queryKey = [baseUrl, String(customProgramId)];
  const queryParams = [querystring.stringify({customProgramId})];

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCustomProgramSectionsProgressRq = (
  customProgramId: number
): ReactQueryRequest => {
  const baseUrl = [
    'report',
    'get-custom-program-sections-progress-report',
  ].join('/');
  const queryKey = [baseUrl, String(customProgramId)];
  const queryParams = [querystring.stringify({customProgramId})];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCustomProgramContentProgressRq = ({
  customProgramId,
  sectionId,
}: {
  customProgramId: number;
  sectionId: number;
}): ReactQueryRequest => {
  const baseUrl = ['report', 'get-custom-program-content-progress-report'].join(
    '/'
  );
  const queryKey = [baseUrl, String(customProgramId), String(sectionId)];
  const queryParams = [
    querystring.stringify({customProgramId}),
    querystring.stringify({sectionId}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getEmployeeCustomProgramSectionsProgressRq = ({
  customProgramId,
  employeeId,
}: {
  customProgramId: number;
  employeeId: number;
}): ReactQueryRequest => {
  const baseUrl = [
    'report',
    'get-custom-program-sections-progress-by-employee-report',
  ].join('/');
  const queryKey = [baseUrl, String(customProgramId), String(employeeId)];
  const queryParams = [
    querystring.stringify({customProgramId}),
    querystring.stringify({employeeId}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getEmployeeCustomProgramContentProgressRq = ({
  customProgramId,
  sectionId,
  employeeId,
}: {
  customProgramId: number;
  sectionId: number;
  employeeId: number;
}): ReactQueryRequest => {
  const baseUrl = [
    'report',
    'get-custom-program-content-progress-by-employee-report',
  ].join('/');
  const queryKey = [
    baseUrl,
    String(customProgramId),
    String(sectionId),
    String(employeeId),
  ];
  const queryParams = [
    querystring.stringify({customProgramId}),
    querystring.stringify({sectionId}),
    querystring.stringify({employeeId}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCustomProgramSectionEmployeesProgressRq = ({
  customProgramId,
  sectionId,
}: {
  customProgramId: number;
  sectionId: number;
}): ReactQueryRequest => {
  const baseUrl = [
    'report',
    'get-custom-program-section-employees-progress-report',
  ].join('/');
  const queryKey = [baseUrl, String(customProgramId), String(sectionId)];
  const queryParams = [
    querystring.stringify({customProgramId}),
    querystring.stringify({sectionId}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCustomProgramContentEmployeesProgressRq = ({
  customProgramId,
  contentId,
}: {
  customProgramId: number;
  contentId: number;
}): ReactQueryRequest => {
  const baseUrl = [
    'report',
    'get-custom-program-content-employees-progress-report',
  ].join('/');
  const queryKey = [baseUrl, String(customProgramId), String(contentId)];
  const queryParams = [
    querystring.stringify({customProgramId}),
    querystring.stringify({contentId}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

const PROGRAM_PATH = 'program';
export const getProgramUrlRq = (programId: number): ReactQueryRequest => {
  const path = [PROGRAM_PATH, `get-url`, programId].join('/');
  const queryKey = [path, String(programId)];

  return {
    path,
    queryKey,
  };
};

/*
|--------------------------------------------------------------------------
| Resources
|--------------------------------------------------------------------------
*/

export const getRequestResourceApprovalRm = (
  payload: RequestResourceApprovalPayload
): ReactMutateRequest => {
  const path = ['user-plan', 'request-resource-approval'].join('/');
  return {
    path,
    payload,
  };
};

export const getUserPlanResourcesRq = (limit?: number): ReactQueryRequest => {
  const _limit = limit || 75;
  const baseUrl = ['user-plan', 'get-resource-items'].join('/');
  const queryKey = [baseUrl];
  const queryParams = [querystring.stringify({limit: _limit})];
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
  // return type is UserPlanResourceVM
};

export const getRemoveUserPlanResourceRm = ({
  userPlanResourceId,
  amountSpent,
}: RemoveUserPlanResourcePayload): ReactMutateRequest => {
  const baseUrl = ['user-plan', 'delete-resource'].join('/');
  const queryParams = [
    querystring.stringify({userPlanResourceId}),
    querystring.stringify({amountSpent}),
  ];
  return {
    path: addQueryParams(baseUrl, queryParams),
    payload: null,
  };
};

export const getResourceApprovalApplicationsRq = (
  approvalRankType?: number
): ReactQueryRequest => {
  const baseUrl = ['incentive', 'get-resources-approval-applications'].join(
    '/'
  );
  const queryKey = [baseUrl];
  const queryParams = [];
  if (approvalRankType >= 0) {
    queryParams.push(`approvalRankType=${approvalRankType}`);
    queryKey.push(approvalRankType?.toString());
  }
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCompleteResourceRm = (
  userPlanResourceId: number
): ReactMutateRequest => {
  const baseUrl = ['user-plan', 'complete-resource'].join('/');
  const queryParams = [querystring.stringify({userPlanResourceId})];
  return {
    path: addQueryParams(baseUrl, queryParams),
    payload: {},
  };
};

export const getHasResourcesEnabledRq = (): ReactQueryRequest => {
  const path = ['user-plan', 'has-resources-enabled'].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getDoProgramsRequireApprovalRq = (): ReactQueryRequest => {
  const path = ['user-plan', 'do-programs-require-approval'].join('/');
  return {
    path,
    queryKey: [path],
  };
};

/*
Add program with approval
*/

export const getResourceApprovalApplicationRq = (
  userPlanResourceId: number,
  approvalRankType?: number
): ReactQueryRequest => {
  const baseUrl = ['user-plan', 'get-resource-approval-application'].join('/');
  const queryKey = [baseUrl, userPlanResourceId?.toString()];
  const queryParams = [querystring.stringify({userPlanResourceId})];
  if (approvalRankType >= 0) {
    queryParams.push(`approvalRankType=${approvalRankType}`);
    queryKey.push(approvalRankType?.toString());
  }
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getCheckUserStatusRq = (): ReactQueryRequest => {
  const path = ['user', 'check-user-status'].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const updateCustomProgramEventRSVPRm = (
  payload: UserPlanAcademyEventRSVPUpdatePayload
): ReactMutateRequest => {
  const path = `user-plan/update-custom-program-content-rsvp`;
  return {
    path,
    payload,
  };
};

export const getTimezonesRq = (): ReactQueryRequest => {
  const path = `time/get-timezones`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getSubmitCustomProgramProjectRm = (
  payload: UserPlanProjectSubmission
): ReactMutateRequest => {
  const path = ['user-plan', 'submit-project'].join('/');
  return {
    path,
    payload,
  };
};

export const getUploadProjectSubmissionRm = (
  projectId: number,
  customizableLearningCategory: CustomizableLearningCategory
): ReactMutateRequest => {
  const path = [
    'user-plan',
    'upload-project-submission',
    projectId,
    customizableLearningCategory,
  ].join('/');
  return {
    path,
    payload: {},
  };
};

/*
|--------------------------------------------------------------------------
| Calendar
|--------------------------------------------------------------------------
*/

export const getDisconnectCalendarRm = () => {
  const path = `calendar/disconnect-calendar`;

  return {
    path,
    payload: {},
  };
};

export const getLicenseRq = (licenseId: number): ReactQueryRequest => {
  const path = `licenses/${licenseId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getLicensesRq = () => {
  const path = `licenses`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getProgramReportRq = (year?: number) => {
  const baseUrl = `report/get-programs-usage-report`;
  const queryKey = [baseUrl];
  const queryParams = [];
  if (year) {
    queryParams.push(`year=${year}`);
    queryKey.push(year.toString());
  }
  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getAddLicenseRm = (
  payload: LicensePayload
): ReactMutateRequest => {
  const path = '/licenses';

  return {
    path,
    payload,
  };
};

export const getUpdateLicenseRm = (
  licenseId: number,
  payload: LicenseUpdatePayload
): ReactMutateRequest => {
  const baseUrl = `licenses`;
  const path = `${baseUrl}/${licenseId}`;

  return {
    path,
    payload,
  };
};

export const getDeleteLicenseRm = (licenseId: number): ReactMutateRequest => {
  const baseUrl = `licenses`;
  const path = `${baseUrl}/${licenseId}`;
  return {
    path,
    payload: {},
  };
};

export const getFinanceReportRq = (): ReactQueryRequest => {
  const path = `report/get-reimbursements-report`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getProgressReportRq = (
  reportAccessType: ReportAccessType
): ReactQueryRequest => {
  const basePath = 'report/get-program-progress-updates-report';
  const path = `${basePath}/${reportAccessType}`;
  const queryKey = [basePath, String(reportAccessType)];

  return {
    path,
    queryKey,
  };
};

export const getProgressReportDetailsRq = (
  userProgramProgressId: number | string
): ReactQueryRequest => {
  const basePath = 'user-plan/view-program-progress-details';
  const path = `${basePath}/${userProgramProgressId}`;
  const queryKey = [basePath, String(userProgramProgressId)];

  return {
    path,
    queryKey,
  };
};

export const getProgramProgressUpdatesRq = (
  planItemId: number | string
): ReactQueryRequest => {
  const basePath = 'user-plan/get-program-progress';
  const path = `${basePath}/${planItemId}`;
  const queryKey = [basePath, String(planItemId)];

  return {
    path,
    queryKey,
  };
};

export const getAddEventRm = (payload: NewUserEvent) => {
  const path = `time/add-event`;

  return {
    path,
    payload: payload,
  };
};

export const getUpdateEventRm = (payload: UpdateUserEvent) => {
  const path = `time/update-event`;

  return {
    path,
    payload: payload,
  };
};

export const getAddReimbursementRm = (payload: ReimbursementPayload) => {
  const path = `finance/request-reimbursement`;

  return {
    path,
    queryKey: payload,
  };
};

export const getReimbursementApprovalApplicationsRq = () => {
  const basePath = 'finance/get-reimbursement-approval-applications';
  const path = `${basePath}`;
  const queryKey = [basePath];
  return {
    path,
    queryKey,
  };
};
export const getUploadReimbursementAttachmentRm = (id: number) => {
  const baseUrl = `/finance/upload-reimbursement-attachment/${id}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getUploadAttachmentRm = () => {
  const baseUrl = `/attachments`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getRemoveAttachmentRm = (id: number) => {
  const baseUrl = `/attachments/${id}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getRequestReimbursementRm = (
  itemId: number,
  application: NewReimbursementApprovalApplicationVM,
  reimbursementItemType: number,
  attachmentIds: Array<number>
): ReactMutateRequest => {
  const path = `/finance/request-reimbursement`;
  const payload = {
    itemId,
    reimbursementItemType,
    application,
    attachmentIds,
  };

  return {
    path,
    payload,
  };
};

/*
|--------------------------------------------------------------------------
| Cost Center
|--------------------------------------------------------------------------
*/

export const getCostCentersRq = (): ReactQueryRequest => {
  const basePath = 'company/get-cost-centers';
  const path = `${basePath}`;
  const queryKey = [basePath];

  return {
    path,
    queryKey,
  };
};

export const updateCostCenterAsManagerRq = (
  payload: CostCenterOverridePayload
): ReactMutateRequest => {
  const path = `manager-approvals/update-cost-center`;
  return {
    path,
    payload,
  };
};

export const updateCostCenterAsAdminRq = (
  payload: CostCenterOverridePayload
): ReactMutateRequest => {
  const path = `admin-approvals/update-cost-center`;
  return {
    path,
    payload,
  };
};

export const updateCostCenterAsAdminByUserRequestIdRq = (
  payload: CostCenterUserRequestOverridePayload
): ReactMutateRequest => {
  const path = `admin-approvals/update-user-request-cost-center`;
  return {
    path,
    payload,
  };
};

// Permissions, Academy

// Permissions, Custom Program

// Permissions, Manager
const PERMISSION_PATH = 'permission';

export const getCustomProgramsManagedPermissionRq = (): ReactQueryRequest => {
  const path = `${PERMISSION_PATH}/get-managed-programs`;
  return {
    path,
    queryKey: [path],
  };
};

export const getAcademiesManagedPermissionRq = (): ReactQueryRequest => {
  const path = `${PERMISSION_PATH}/get-managed-academies`;
  return {
    path,
    queryKey: [path],
  };
};

export const getResetAcademyProjectCountRm = (
  academyId: number
): ReactMutateRequest => {
  const path = `${PERMISSION_PATH}/reset-user-academy-permission-project-submission-count`;
  return {
    path,
    payload: {academyId},
  };
};

export const getResetProgramProjectCountRm = (
  customProgramId: number
): ReactMutateRequest => {
  const path = `${PERMISSION_PATH}/reset-user-custom-program-permission-project-submission-count`;
  return {
    path,
    payload: {customProgramId},
  };
};

/*
|--------------------------------------------------------------------------
| Team Insights, Sections
|--------------------------------------------------------------------------
*/

const TEAM_INSIGHTS_PATH = 'team-insights';

export const getTeamInsightsDrilldownRq = (
  userCompanyId: string | number
): ReactQueryRequest => {
  const path = `${TEAM_INSIGHTS_PATH}/get-team-insight-detail/${userCompanyId}`;
  return {path, queryKey: [path]};
};

export const getTeamInsightsOverviewRq = (): ReactQueryRequest => {
  const path = `${TEAM_INSIGHTS_PATH}/get-team-insight-overview`;
  return {path, queryKey: [path]};
};

export const getManagerUserRequestApprovalsRq = (
  type: ApprovalsDiscriminator,
  status: ApprovalStatus
): ReactQueryRequest => {
  const baseUrl = 'manager-approvals/get-user-request-approvals';
  const queryKey = [baseUrl];
  const queryParams = [];

  const typeParam = `type=${type || 0}`;
  queryParams.push(typeParam);
  queryKey.push(`type=${type || 0}`);

  const statusParam = `status=${status}`;
  queryParams.push(statusParam);
  queryKey.push(statusParam);

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};

export const getManagerApprovalDetailsRq = (
  type: ApprovalType,
  approvalId: number
): ReactQueryRequest => {
  const baseUrl = 'manager-approvals/get-approval-details';
  const queryKey = [baseUrl];
  const queryParams = [];

  if (type) {
    queryParams.push(`type=${type}`);
    queryKey.push(type.toString());
  }

  if (approvalId) {
    queryParams.push(`id=${approvalId}`);
    queryKey.push(approvalId.toString());
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    queryKey,
  };
};
export const getManagerUpdateUserRequestApprovalRm = (
  approvalId: number,
  approvalType: ApprovalType,
  approvalStatus: ApprovalStatus,
  notes: string,
  costCenter?: string
): ReactMutateRequest => {
  const path = 'manager-approvals/update-user-request-approval';
  const payload = {
    id: approvalId,
    type: approvalType,
    status: approvalStatus,
    notes: notes,
    costCenter: costCenter,
  };
  return {
    path,
    payload,
  };
};
