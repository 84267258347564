import {Form} from 'antd';
import {i18n, k} from '@i18n/translate';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';

interface RejectionNotesProps {
  rejectedNotes: string;
}

export const RejectionNotes = ({rejectedNotes}: RejectionNotesProps) => {
  return (
    !!rejectedNotes && (
      <Form.Item
        name={'approver-notes'}
        label={i18n.t(k.NOTE__FROM_APPROVER)}
        className="lb-application-drawer"
        initialValue={rejectedNotes}>
        <LearnInTextArea
          tag={TextAreaTags.PRIMARY}
          disabled={true}
          rows={4}
          maxLength={10000}
          defaultValue={rejectedNotes}
          value={rejectedNotes}
        />
      </Form.Item>
    )
  );
};
