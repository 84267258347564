import {useLocation} from 'react-router-dom';
import {UserPaths, ManagerPaths, AdminPaths} from '@utils/ClientPaths';

interface Return {
  isAdminPath: boolean;
  isManagerPath: boolean;
  isUserPath: boolean;
}

function usePathInfo(): Return {
  const {pathname} = useLocation();
  const isAdminPath = Object.values(AdminPaths).some((path) =>
    pathname.includes(path)
  );
  const isManagerPath = Object.values(ManagerPaths).some((path) =>
    pathname.includes(path)
  );
  const isUserPath = Object.values(UserPaths).some((path) =>
    pathname.includes(path)
  );
  return {isAdminPath, isManagerPath, isUserPath};
}

export default usePathInfo;
