import {useEffect} from 'react';

interface Options {
  executeCallbackOnPressEscape?: boolean;
}

export default (ref: any, callback: () => void, options?: Options) => {
  const _options = options || {};
  // By default execute the callback when escape is pressed. This makes senses for closing things, but may not always be applicable
  if (_options.executeCallbackOnPressEscape === undefined)
    _options.executeCallbackOnPressEscape = true;

  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);

    if (_options.executeCallbackOnPressEscape)
      document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClick);
      if (_options.executeCallbackOnPressEscape)
        document.removeEventListener('keydown', handleKeyDown);
    };
  });
};
