import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import './LoadingSpinner.scss';
import {Spin} from 'antd';

const Container = styled.div`
  flex-direction: column;
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface Props {
  button?: boolean;
  size?: 'small' | 'large' | 'default';
  style?: React.CSSProperties;
  label?: React.ReactNode;
}

const defaultStyle = {
  zIndex: 100,
  marginBottom: 16,
};

export default function LoadingSpinner({
  button = false,
  size = 'large',
  style,
  label = null,
}: Props) {
  const antIcon = <LoadingOutlined spin />;
  if (button) {
    return (
      <>
        <Spin
          style={{...defaultStyle, ...style}}
          size={size}
          indicator={antIcon}
        />
        <div>{label}</div>
      </>
    );
  }
  return (
    <Container>
      <Spin
        style={{...defaultStyle, ...style}}
        size={size}
        indicator={antIcon}
      />
      <div>{label}</div>
    </Container>
  );
}
