// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPlanAcademyContentStatusUpdate.cs

import {z} from 'zod';
import {AcademyContentStatusSchema} from './AcademyContentStatusSchema.schema';

export const UserPlanAcademyContentStatusUpdatePayloadSchema = z.object({
  contentId: z.number(),
  status: AcademyContentStatusSchema,
});
