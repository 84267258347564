// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramContentPreviewVM.cs

import {z} from 'zod';
import {CustomProgramContentVMSchema} from './CustomProgramContentVMSchema.schema';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';
import {ContentFeedbackVMSchema} from './ContentFeedbackVMSchema.schema';
import {LinkVMSchema} from './LinkVMSchema.schema';
import {MessageDtoSchema} from './MessageDtoSchema.schema';
import {RsvpStatusSchema} from './RsvpStatusSchema.schema';

const schema = z.object({
  attachments: z.array(AttachmentVMSchema),
  discussionId: z.string().nullable().optional(),
  feedback: z.array(ContentFeedbackVMSchema),
  googleCalendarLink: z.string().nullable().optional(),
  links: z.array(LinkVMSchema),
  messageCount: z.number(),
  messagesPreview: z.array(MessageDtoSchema).nullable().optional(),
  outlookCalendarLink: z.string().nullable().optional(),
  projectContentCompletedOn: z.string().nullable().optional(),
  rsvpStatus: RsvpStatusSchema.nullable().optional(),
  submissionCount: z.number(),
  submittedFiles: z.array(AttachmentVMSchema),
  submittedLinks: z.array(LinkVMSchema),
  submittedResponse: z.string().nullable().optional(),
  textContentCompleted: z.boolean(),
  userMessage: MessageDtoSchema.nullable().optional(),
});

export const CustomProgramContentPreviewVMSchema =
  CustomProgramContentVMSchema.merge(schema);
