// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/OptionCounts.cs

import {z} from 'zod';

export const OptionCountsSchema = z.object({
  financing: z.number(),
  program: z.number(),
  timeAllotment: z.number(),
});
