// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Permission/PermissionVM.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  email: z.string().nullable().optional(),
  name: z.string(),
  userCompanyId: z.number(),
});

export const PermissionVMSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
