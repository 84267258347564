import {simpleQueryFn, simpleInvalidateExactQueryFn} from '@store/queryClient';
import {useQuery, UseQueryOptions} from 'react-query';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {
  getUserLearningBudgetInfoRq,
  getHasResourcesEnabledRq,
  getUserProgramPlanRq,
} from '@store/apiEndpoints';
import {
  AcademyLevelVM,
  UserPlanFinanceLearningBudgetOverviewVM,
} from '@generated/interfaces';
import {getAcademyLevelsRq} from '@store/apiEndpoints/academy/queries';

import {queryHookFactory} from '../utils';

export const useUserPlanFinanceLearningBudgetOverviewVMQuery = (
  queryOptions?: UseQueryOptions<UserPlanFinanceLearningBudgetOverviewVM>
) => {
  const queryRq = getUserLearningBudgetInfoRq();
  const query = useQuery<UserPlanFinanceLearningBudgetOverviewVM>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    queryOptions
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  const activePlbExists = !!query.data?.userPlanItemId && !!query.data?.title;

  return {
    ...query,
    invalidate,
    activePlbExists,
  };
};

export const useHasResourcesEnabledQuery = (
  queryOptions?: UseQueryOptions<boolean>
) => {
  const queryRq = getHasResourcesEnabledRq();
  const query = useQuery<boolean>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    {
      ...queryOptions,
    }
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  return {
    ...query,
    invalidate,
  };
};

// TODO: move to academy/queries
export const useAcademyLevelVMsQuery = queryHookFactory<
  number,
  AcademyLevelVM[]
>(getAcademyLevelsRq, {
  defaultQueryOptions: {placeholderData: () => []},
});

export const useUserPlanProgramItemVMQuery = queryHookFactory<
  number,
  UserPlanProgramItemVM[]
>(getUserProgramPlanRq, {
  defaultQueryOptions: {placeholderData: () => []},
});
