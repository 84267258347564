// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Dev/Requests/UpdateCompanyRequest.cs

import {z} from 'zod';
import {PermissionRoleSchema} from './PermissionRoleSchema.schema';

export const UpdateCompanyRequestSchema = z.object({
  defaultPermissionRole: PermissionRoleSchema.nullable().optional(),
  isAcademiesIntegratedExperienceDisabled: z.boolean().nullable().optional(),
  isContentMarketplaceIntegratedExperienceDisabled: z
    .boolean()
    .nullable()
    .optional(),
  lxpId: z.number().nullable().optional(),
  name: z.string().nullable().optional(),
  primaryBrandColor: z.string().nullable().optional(),
  rectangleLogoUrl: z.string().nullable().optional(),
  squareLogoUrl: z.string().nullable().optional(),
  virtualCardColor: z.string().nullable().optional(),
});
