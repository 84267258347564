// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/ItemDetails/DetailsSectionVM.cs

import {z} from 'zod';

export const DetailsSectionVMSchema = z.object({
  content: z.string(),
  sequenceNum: z.number(),
  title: z.string(),
});
