import {ReportAccessType} from './../enums';
import {
  AcademyOverviewLevelReportVm,
  AcademyOverviewLevelUserReportVm,
  AcademyOverviewStepUserReportVm,
  AcademyReportEmployeesVM,
  AcademyStepVM,
  CustomProgramActivityResult,
  CustomProgramContentActivityResult,
  CustomProgramContentProgressResult,
  CustomProgramCurriculumActivityResult,
  CustomProgramCurriculumProgressResult,
  EmployeeCurrentFinanceIncentiveDetailsVM,
  PrepaymentTransactionVM,
  ProgramProgressUpdateResult,
  ProgramUsageResult,
  ReimbursementsReportResult,
  TransactionReportVM,
  UserFinanceIncentiveTransactionsVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ProgramUsageResultSchema} from '../zodSchemas/ProgramUsageResultSchema.schema';
import {ReimbursementsReportResultSchema} from '../zodSchemas/ReimbursementsReportResultSchema.schema';
import {ProgramProgressUpdateResultSchema} from '../zodSchemas/ProgramProgressUpdateResultSchema.schema';
import {PrepaymentTransactionVMSchema} from '../zodSchemas/PrepaymentTransactionVMSchema.schema';
import {UserFinanceIncentiveTransactionsVMSchema} from '../zodSchemas/UserFinanceIncentiveTransactionsVMSchema.schema';
import {EmployeeCurrentFinanceIncentiveDetailsVMSchema} from '../zodSchemas/EmployeeCurrentFinanceIncentiveDetailsVMSchema.schema';
import {TransactionReportVMSchema} from '../zodSchemas/TransactionReportVMSchema.schema';
import {CustomProgramActivityResultSchema} from '../zodSchemas/CustomProgramActivityResultSchema.schema';
import {CustomProgramCurriculumProgressResultSchema} from '../zodSchemas/CustomProgramCurriculumProgressResultSchema.schema';
import {CustomProgramContentProgressResultSchema} from '../zodSchemas/CustomProgramContentProgressResultSchema.schema';
import {CustomProgramCurriculumActivityResultSchema} from '../zodSchemas/CustomProgramCurriculumActivityResultSchema.schema';
import {CustomProgramContentActivityResultSchema} from '../zodSchemas/CustomProgramContentActivityResultSchema.schema';
import {AcademyReportEmployeesVMSchema} from '../zodSchemas/AcademyReportEmployeesVMSchema.schema';
import {AcademyStepVMSchema} from '../zodSchemas/AcademyStepVMSchema.schema';
import {AcademyOverviewLevelReportVmSchema} from '../zodSchemas/AcademyOverviewLevelReportVmSchema.schema';
import {AcademyOverviewLevelUserReportVmSchema} from '../zodSchemas/AcademyOverviewLevelUserReportVmSchema.schema';
import {AcademyOverviewStepUserReportVmSchema} from '../zodSchemas/AcademyOverviewStepUserReportVmSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Reports/ReportController.cs

/*
|--------------------------------------------------------------------------
|  get-academy-overview-level-report/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyOverviewLevelReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyOverviewLevelReport,
 *    isLoading: isAcademyOverviewLevelReportLoading,
 *    invalidateExact: invalidateAcademyOverviewLevelReport,
 *  } = useAcademyOverviewLevelReportQuery({
 *    academyId
 *  })
 *
 */

export const useAcademyOverviewLevelReportQuery = queryHookFactory<
  {academyId: number}, // path variables
  AcademyOverviewLevelReportVm[] // return type
>(
  'useAcademyOverviewLevelReportQuery',
  ({academyId}) => `report/get-academy-overview-level-report/${academyId}`,
  {responseSchema: z.array(AcademyOverviewLevelReportVmSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-overview-level-user-report/{academyLevelId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyOverviewLevelUserReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyOverviewLevelUserReport,
 *    isLoading: isAcademyOverviewLevelUserReportLoading,
 *    invalidateExact: invalidateAcademyOverviewLevelUserReport,
 *  } = useAcademyOverviewLevelUserReportQuery({
 *    academyLevelId
 *  })
 *
 */

export const useAcademyOverviewLevelUserReportQuery = queryHookFactory<
  {academyLevelId: number}, // path variables
  AcademyOverviewLevelUserReportVm // return type
>(
  'useAcademyOverviewLevelUserReportQuery',
  ({academyLevelId}) =>
    `report/get-academy-overview-level-user-report/${academyLevelId}`,
  {responseSchema: AcademyOverviewLevelUserReportVmSchema}
);

/*
|--------------------------------------------------------------------------
|  get-academy-overview-report/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyOverviewReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyOverviewReport,
 *    isLoading: isAcademyOverviewReportLoading,
 *    invalidateExact: invalidateAcademyOverviewReport,
 *  } = useAcademyOverviewReportQuery({
 *    academyId
 *  })
 *
 */

export const useAcademyOverviewReportQuery = queryHookFactory<
  {academyId: number}, // path variables
  AcademyReportEmployeesVM[] // return type
>(
  'useAcademyOverviewReportQuery',
  ({academyId}) => `report/get-academy-overview-report/${academyId}`,
  {responseSchema: z.array(AcademyReportEmployeesVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-academy-overview-step-user-report/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyOverviewStepUserReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyOverviewStepUserReport,
 *    isLoading: isAcademyOverviewStepUserReportLoading,
 *    invalidateExact: invalidateAcademyOverviewStepUserReport,
 *  } = useAcademyOverviewStepUserReportQuery({
 *    academyStepId
 *  })
 *
 */

export const useAcademyOverviewStepUserReportQuery = queryHookFactory<
  {academyStepId: number}, // path variables
  AcademyOverviewStepUserReportVm // return type
>(
  'useAcademyOverviewStepUserReportQuery',
  ({academyStepId}) =>
    `report/get-academy-overview-step-user-report/${academyStepId}`,
  {responseSchema: AcademyOverviewStepUserReportVmSchema}
);

/*
|--------------------------------------------------------------------------
|  get-academy-overview-user-report/{academyLevelId}/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyOverviewUserReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyOverviewUserReport,
 *    isLoading: isAcademyOverviewUserReportLoading,
 *    invalidateExact: invalidateAcademyOverviewUserReport,
 *  } = useAcademyOverviewUserReportQuery({
 *    academyLevelId,
 *    userCompanyId
 *  })
 *
 */

export const useAcademyOverviewUserReportQuery = queryHookFactory<
  {academyLevelId: number; userCompanyId: number}, // path variables
  AcademyStepVM[] // return type
>(
  'useAcademyOverviewUserReportQuery',
  ({academyLevelId, userCompanyId}) =>
    `report/get-academy-overview-user-report/${academyLevelId}/${userCompanyId}`,
  {responseSchema: z.array(AcademyStepVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-activity-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramActivityReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramActivityReport,
 *    isLoading: isCustomProgramActivityReportLoading,
 *    invalidateExact: invalidateCustomProgramActivityReport,
 *  } = useCustomProgramActivityReportQuery({
 *    queryParams: {
 *        customProgramId
 *    }
 *  })
 *
 */

export const useCustomProgramActivityReportQuery = queryHookFactory<
  {queryParams?: {customProgramId?: number}},
  CustomProgramActivityResult[] // return type
>(
  'useCustomProgramActivityReportQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `report/get-custom-program-activity-report`,
      null,
      queryParams
    ),
  {responseSchema: z.array(CustomProgramActivityResultSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-content-employees-progress-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentEmployeesProgressReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentEmployeesProgressReport,
 *    isLoading: isCustomProgramContentEmployeesProgressReportLoading,
 *    invalidateExact: invalidateCustomProgramContentEmployeesProgressReport,
 *  } = useCustomProgramContentEmployeesProgressReportQuery({
 *    queryParams: {
 *        contentId,
 *        customProgramId
 *    }
 *  })
 *
 */

export const useCustomProgramContentEmployeesProgressReportQuery =
  queryHookFactory<
    {queryParams?: {contentId?: number; customProgramId?: number}},
    CustomProgramContentActivityResult[] // return type
  >(
    'useCustomProgramContentEmployeesProgressReportQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `report/get-custom-program-content-employees-progress-report`,
        null,
        queryParams
      ),
    {responseSchema: z.array(CustomProgramContentActivityResultSchema)}
  );

/*
|--------------------------------------------------------------------------
|  get-custom-program-content-progress-by-employee-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentProgressByEmployeeReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentProgressByEmployeeReport,
 *    isLoading: isCustomProgramContentProgressByEmployeeReportLoading,
 *    invalidateExact: invalidateCustomProgramContentProgressByEmployeeReport,
 *  } = useCustomProgramContentProgressByEmployeeReportQuery({
 *    queryParams: {
 *        customProgramId,
 *        employeeId,
 *        sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramContentProgressByEmployeeReportQuery =
  queryHookFactory<
    {
      queryParams?: {
        customProgramId?: number;
        employeeId?: number;
        sectionId?: number;
      };
    },
    CustomProgramContentProgressResult[] // return type
  >(
    'useCustomProgramContentProgressByEmployeeReportQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `report/get-custom-program-content-progress-by-employee-report`,
        null,
        queryParams
      ),
    {responseSchema: z.array(CustomProgramContentProgressResultSchema)}
  );

/*
|--------------------------------------------------------------------------
|  get-custom-program-content-progress-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentProgressReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentProgressReport,
 *    isLoading: isCustomProgramContentProgressReportLoading,
 *    invalidateExact: invalidateCustomProgramContentProgressReport,
 *  } = useCustomProgramContentProgressReportQuery({
 *    queryParams: {
 *        customProgramId,
 *        sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramContentProgressReportQuery = queryHookFactory<
  {queryParams?: {customProgramId?: number; sectionId?: number}},
  CustomProgramCurriculumProgressResult[] // return type
>(
  'useCustomProgramContentProgressReportQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `report/get-custom-program-content-progress-report`,
      null,
      queryParams
    ),
  {responseSchema: z.array(CustomProgramCurriculumProgressResultSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-section-employees-progress-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSectionEmployeesProgressReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramSectionEmployeesProgressReport,
 *    isLoading: isCustomProgramSectionEmployeesProgressReportLoading,
 *    invalidateExact: invalidateCustomProgramSectionEmployeesProgressReport,
 *  } = useCustomProgramSectionEmployeesProgressReportQuery({
 *    queryParams: {
 *        customProgramId,
 *        sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramSectionEmployeesProgressReportQuery =
  queryHookFactory<
    {queryParams?: {customProgramId?: number; sectionId?: number}},
    CustomProgramCurriculumActivityResult[] // return type
  >(
    'useCustomProgramSectionEmployeesProgressReportQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `report/get-custom-program-section-employees-progress-report`,
        null,
        queryParams
      ),
    {responseSchema: z.array(CustomProgramCurriculumActivityResultSchema)}
  );

/*
|--------------------------------------------------------------------------
|  get-custom-program-sections-progress-by-employee-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSectionsProgressByEmployeeReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramSectionsProgressByEmployeeReport,
 *    isLoading: isCustomProgramSectionsProgressByEmployeeReportLoading,
 *    invalidateExact: invalidateCustomProgramSectionsProgressByEmployeeReport,
 *  } = useCustomProgramSectionsProgressByEmployeeReportQuery({
 *    queryParams: {
 *        customProgramId,
 *        employeeId
 *    }
 *  })
 *
 */

export const useCustomProgramSectionsProgressByEmployeeReportQuery =
  queryHookFactory<
    {queryParams?: {customProgramId?: number; employeeId?: number}},
    CustomProgramCurriculumProgressResult[] // return type
  >(
    'useCustomProgramSectionsProgressByEmployeeReportQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `report/get-custom-program-sections-progress-by-employee-report`,
        null,
        queryParams
      ),
    {responseSchema: z.array(CustomProgramCurriculumProgressResultSchema)}
  );

/*
|--------------------------------------------------------------------------
|  get-custom-program-sections-progress-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSectionsProgressReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramSectionsProgressReport,
 *    isLoading: isCustomProgramSectionsProgressReportLoading,
 *    invalidateExact: invalidateCustomProgramSectionsProgressReport,
 *  } = useCustomProgramSectionsProgressReportQuery({
 *    queryParams: {
 *        customProgramId
 *    }
 *  })
 *
 */

export const useCustomProgramSectionsProgressReportQuery = queryHookFactory<
  {queryParams?: {customProgramId?: number}},
  CustomProgramCurriculumProgressResult[] // return type
>(
  'useCustomProgramSectionsProgressReportQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `report/get-custom-program-sections-progress-report`,
      null,
      queryParams
    ),
  {responseSchema: z.array(CustomProgramCurriculumProgressResultSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-prepayment-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePrepaymentReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: prepaymentReport,
 *    isLoading: isPrepaymentReportLoading,
 *    invalidateExact: invalidatePrepaymentReport,
 *  } = usePrepaymentReportQuery({
 *    queryParams: {
 *        year
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const usePrepaymentReportQuery = queryHookFactory<
  {queryParams?: {year?: number}},
  PrepaymentTransactionVM[] // return type
>(
  'usePrepaymentReportQuery',
  ({queryParams}) =>
    addQueryParams(() => `report/get-prepayment-report`, null, queryParams),
  {responseSchema: z.array(PrepaymentTransactionVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-program-progress-updates-report/{accessType} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramProgressUpdatesReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programProgressUpdatesReport,
 *    isLoading: isProgramProgressUpdatesReportLoading,
 *    invalidateExact: invalidateProgramProgressUpdatesReport,
 *  } = useProgramProgressUpdatesReportQuery({
 *    accessType
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useProgramProgressUpdatesReportQuery = queryHookFactory<
  {accessType: ReportAccessType}, // path variables
  ProgramProgressUpdateResult[] // return type
>(
  'useProgramProgressUpdatesReportQuery',
  ({accessType}) => `report/get-program-progress-updates-report/${accessType}`,
  {responseSchema: z.array(ProgramProgressUpdateResultSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-programs-usage-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramsUsageReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programsUsageReport,
 *    isLoading: isProgramsUsageReportLoading,
 *    invalidateExact: invalidateProgramsUsageReport,
 *  } = useProgramsUsageReportQuery({
 *    queryParams: {
 *        year
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useProgramsUsageReportQuery = queryHookFactory<
  {queryParams?: {year?: number}},
  ProgramUsageResult[] // return type
>(
  'useProgramsUsageReportQuery',
  ({queryParams}) =>
    addQueryParams(() => `report/get-programs-usage-report`, null, queryParams),
  {responseSchema: z.array(ProgramUsageResultSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-reimbursements-export 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementsExportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementsExport,
 *    isLoading: isReimbursementsExportLoading,
 *    invalidateExact: invalidateReimbursementsExport,
 *  } = useReimbursementsExportQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useReimbursementsExportQuery = queryHookFactory<
  null,
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>('useReimbursementsExportQuery', () => `report/get-reimbursements-export`, {
  responseSchema: null,
});

/*
|--------------------------------------------------------------------------
|  get-reimbursements-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementsReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementsReport,
 *    isLoading: isReimbursementsReportLoading,
 *    invalidateExact: invalidateReimbursementsReport,
 *  } = useReimbursementsReportQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useReimbursementsReportQuery = queryHookFactory<
  null,
  ReimbursementsReportResult[] // return type
>('useReimbursementsReportQuery', () => `report/get-reimbursements-report`, {
  responseSchema: z.array(ReimbursementsReportResultSchema),
});

/*
|--------------------------------------------------------------------------
|  get-transactions-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTransactionsReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: transactionsReport,
 *    isLoading: isTransactionsReportLoading,
 *    invalidateExact: invalidateTransactionsReport,
 *  } = useTransactionsReportQuery({
 *    queryParams: {
 *        userFinanceIncentiveId,
 *        year
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useTransactionsReportQuery = queryHookFactory<
  {queryParams?: {userFinanceIncentiveId?: number; year?: number}},
  TransactionReportVM[] // return type
>(
  'useTransactionsReportQuery',
  ({queryParams}) =>
    addQueryParams(() => `report/get-transactions-report`, null, queryParams),
  {responseSchema: z.array(TransactionReportVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-transaction-years 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTransactionYearsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: transactionYears,
 *    isLoading: isTransactionYearsLoading,
 *    invalidateExact: invalidateTransactionYears,
 *  } = useTransactionYearsQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useTransactionYearsQuery = queryHookFactory<
  null,
  number[] // return type
>('useTransactionYearsQuery', () => `report/get-transaction-years`, {
  responseSchema: z.array(z.number()),
});

/*
|--------------------------------------------------------------------------
|  get-user-current-finance-incentive-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserCurrentFinanceIncentiveDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userCurrentFinanceIncentiveDetails,
 *    isLoading: isUserCurrentFinanceIncentiveDetailsLoading,
 *    invalidateExact: invalidateUserCurrentFinanceIncentiveDetails,
 *  } = useUserCurrentFinanceIncentiveDetailsQuery({
 *    queryParams: {
 *        userFinanceIncentiveId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useUserCurrentFinanceIncentiveDetailsQuery = queryHookFactory<
  {queryParams?: {userFinanceIncentiveId?: number}},
  EmployeeCurrentFinanceIncentiveDetailsVM // return type
>(
  'useUserCurrentFinanceIncentiveDetailsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `report/get-user-current-finance-incentive-details`,
      null,
      queryParams
    ),
  {responseSchema: EmployeeCurrentFinanceIncentiveDetailsVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-user-transactions-report 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserTransactionsReportQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userTransactionsReport,
 *    isLoading: isUserTransactionsReportLoading,
 *    invalidateExact: invalidateUserTransactionsReport,
 *  } = useUserTransactionsReportQuery({
 *    queryParams: {
 *        userFinanceIncentiveId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useUserTransactionsReportQuery = queryHookFactory<
  {queryParams?: {userFinanceIncentiveId?: number}},
  UserFinanceIncentiveTransactionsVM // return type
>(
  'useUserTransactionsReportQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `report/get-user-transactions-report`,
      null,
      queryParams
    ),
  {responseSchema: UserFinanceIncentiveTransactionsVMSchema}
);
