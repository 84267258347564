// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/NewReimbursementApprovalApplicationVM.cs

import {z} from 'zod';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {PreApprovalQuestionVMSchema} from './PreApprovalQuestionVMSchema.schema';
import {ReimbursementItemTypeSchema} from './ReimbursementItemTypeSchema.schema';

export const NewReimbursementApprovalApplicationVMSchema = z.object({
  learningResourceType: LearningResourceTypeSchema.nullable().optional(),
  questions: z.array(PreApprovalQuestionVMSchema),
  reimbursementType: ReimbursementItemTypeSchema,
});
