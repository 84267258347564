// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramPreviewVM.cs

import {z} from 'zod';
import {CustomProgramSectionPreviewVMSchema} from './CustomProgramSectionPreviewVMSchema.schema';

export const CustomProgramPreviewVMSchema = z.object({
  allDatesOnCalendar: z.boolean(),
  id: z.number(),
  imageUrl: z.string(),
  logoUrl: z.string(),
  planItemId: z.number().nullable().optional(),
  progressPercentage: z.number(),
  restrictedAccess: z.boolean(),
  sections: z.array(CustomProgramSectionPreviewVMSchema),
  selected: z.boolean(),
  skills: z.array(z.string()),
  title: z.string(),
});
