// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/Requests/AddSkillTagPayload.cs

import {z} from 'zod';
import {ResourceSkillTypeSchema} from './ResourceSkillTypeSchema.schema';

export const AddSkillTagPayloadSchema = z.object({
  entityId: z.number(),
  entityType: ResourceSkillTypeSchema,
  lxpInputId: z.number(),
  name: z.string(),
  title: z.string(),
});
