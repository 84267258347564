// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyWithMembershipStatusVM.cs

import {z} from 'zod';
import {AcademyVMSchema} from './AcademyVMSchema.schema';
import {AcademyUserStatusSchema} from './AcademyUserStatusSchema.schema';

const schema = z.object({
  academyUserStatus: AcademyUserStatusSchema.nullable().optional(),
  hasCompleted: z.boolean(),
  isInProgress: z.boolean(),
});

export const AcademyWithMembershipStatusVMSchema =
  AcademyVMSchema.merge(schema);
