import styled from 'styled-components';
import {BodySpan} from '@components/reusable/Typography';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {Space} from 'antd';
import {Breakpoints} from '@utils/responsiveUtils';

export const StyledBodySpan = styled(BodySpan)`
  display: block;
`;

export const StyledButton = styled(LearnInButton)`
  width: max-content;
`;

export const StyledSpace = styled(Space)`
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-direction: column;
  }
`;
