import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {
  getEmailPreviewTemplateById,
  getHandlerOnTemplateSelected,
  previewEmailTemplate,
} from './EmailPreview.utils';
import {EmailPreviewView} from './EmailPreview.view';
import {EMAIL_TEMPLATE_DATA} from './EmailPreview.constants';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';

function EmailPreviewContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const getUserQuery = useGetUserQuery();
  const emailTemplates = [
    ...EMAIL_TEMPLATE_DATA?.sort((a, b) => a.label.localeCompare(b.id)),
  ];
  const initialTemplate = emailTemplates?.[0];
  const [displayedTemplate, setDisplayedTemplate] = useState(initialTemplate);
  const [selectedTemplate, setSelectedTemplate] = useState(initialTemplate);
  const [previewHtml, setPreviewHtml] = useState('');

  useEffect(() => {
    const template = getEmailPreviewTemplateById(selectedTemplate?.id);

    let params: any = {
      ...template?.data,
    };

    const notDropdownChange = selectedTemplate?.id === displayedTemplate?.id;
    if (notDropdownChange) {
      params = {
        ...params,
        ...(location?.search ? queryString.parse(location?.search) : null),
      };
    }

    previewEmailTemplate({
      navigate,
      params,
      setPreviewHtml,
      templateId: selectedTemplate?.templateId,
      languagePreference: getUserQuery?.data?.languagePreference,
    });

    // For detecting param changes independent of template dropdown changes
    setDisplayedTemplate(selectedTemplate);
  }, [location?.search, navigate, displayedTemplate, selectedTemplate]);

  return (
    <EmailPreviewView
      emailTemplates={emailTemplates}
      handleTemplateSelected={getHandlerOnTemplateSelected(setSelectedTemplate)}
      previewHtml={previewHtml}
      selectedTemplate={selectedTemplate}
    />
  );
}

export default EmailPreviewContainer;
