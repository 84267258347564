// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewLevelReport.cs

import {z} from 'zod';

export const AcademyOverviewStepReportVmSchema = z.object({
  academyStepId: z.number(),
  academyStepName: z.string(),
  completionPercentage: z.number(),
  order: z.number(),
});
