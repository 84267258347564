// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyPayload.cs

import {z} from 'zod';
import {IAcademyBasicInfoSchema} from './IAcademyBasicInfoSchema.schema';
import {AttachmentPayloadSchema} from './AttachmentPayloadSchema.schema';
import {AcademyStatusSchema} from './AcademyStatusSchema.schema';

const schema = z.object({
  academyProfileImageUrl: z.string(),
  degreedId: z.string().nullable().optional(),
  description: z.string(),
  discussionId: z.string().nullable().optional(),
  groupId: z.string().nullable().optional(),
  id: z.number(),
  images: z.array(AttachmentPayloadSchema),
  invitationEmail: z.boolean(),
  joinId: z.string(),
  name: z.string(),
  restrictedAccess: z.boolean(),
  status: AcademyStatusSchema,
});

export const AcademyPayloadSchema = IAcademyBasicInfoSchema.merge(schema);
