// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramCurriculumProgressResult.cs

import {z} from 'zod';

export const CustomProgramCurriculumProgressResultSchema = z.object({
  id: z.number(),
  order: z.number(),
  progressPercentage: z.number(),
  title: z.string(),
});
