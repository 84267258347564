import {useCallback} from 'react';
import {focusManager, useMutation, useQuery} from 'react-query';
import {Form} from 'antd';
import i18next from 'i18next';
import {LANGUAGE_LOCAL_STORAGE_KEY} from '@utils/constants';
import {LANGUAGE} from '@utils/l10nUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {UserSettingsVM} from '@generated/interfaces';
import {UserSettingsVM as DeprecatedUserSettingsVM} from '@models/serverModels'; // @generated/interfaces doesn't fully overlap (e.g. phoneNumber)
import queryClient, {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {getUpdateSettingsRm} from '@store/apiEndpoints/user/mutations';
import {getSettingsRq} from '@store/apiEndpoints/user/queries';
import {FIELD_NAME, modalTestId} from './QuickActionModal.constants';
import QuickActionModalView from './QuickActionModal.view';
import {useUpdateSettingsMutation} from '@generated/hooks';

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export default function QuickActionModalContainer({
  isVisible,
  setIsVisible,
}: Props) {
  const [form] = Form.useForm();
  const {data: currentUser} = useGetUserQuery();
  const settingsRq = getSettingsRq();
  const settingsQuery = useQuery<UserSettingsVM>(settingsRq.queryKey, () =>
    simpleQueryFn(settingsRq.path)
  );

  const getInitialFormValues = useCallback(() => {
    return {
      ...currentUser,
      [FIELD_NAME.CURRENCY]: currentUser.currency,
      [FIELD_NAME.LOCALE]: currentUser.languagePreference,
    };
  }, [currentUser]);

  const forceFlush = () => {
    // Helpful for use with this internal tool which makes deep cutting
    // changes to state. This resets more than invalidateQueries, preventing
    // artifacts from lingering after major state changes. It's helpful in this
    // internal tool, but is heavy-handed if considered for normal scenarios.
    // https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
    focusManager.setFocused(false);
    focusManager.setFocused(true);
  };

  const handleCancel = () => setIsVisible(false);

  const handleOk = async () => {
    setIsVisible(false);
    const formData = form.getFieldsValue();

    const settingsData = {...settingsQuery.data};
    if (settingsData.hasOwnProperty('meta')) {
      // Remove "meta" which is not part of the VM
      // and which causes a circular reference
      delete settingsData['meta'];
    }

    const settings = {
      payload: {
        ...settingsData,
        ...{currency: formData[FIELD_NAME.CURRENCY]},
        ...{languagePreference: formData[FIELD_NAME.LOCALE]},
      },
    };

    await updateSettingsMutation.mutateAsync(settings);
  };

  const handleUpdatedSettingsMutation = async (userData: UserSettingsVM) => {
    const languageCode = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
    const i18nextLocale =
      LANGUAGE[languageCode.toUpperCase().replace('-', '_')];
    const changedLanguage = userData?.languagePreference !== i18nextLocale.enum;

    if (changedLanguage) {
      i18next.changeLanguage(languageCode, async (err) => {
        if (err) return notify.settingsUpdateError();
        await onUpdated();
        forceFlush();
      });
    } else {
      await onUpdated();
    }
  };

  const onUpdated = async () => {
    await queryClient.invalidateQueries();
    notify.settingsUpdateSuccess();
  };

  const updateSettingsMutation = useUpdateSettingsMutation({
    onError: () => notify.settingsUpdateError(),
    onSuccess: (settingsData: UserSettingsVM) =>
      handleUpdatedSettingsMutation(settingsData),
  });

  return (
    <QuickActionModalView
      data-testid={modalTestId}
      form={form}
      isVisible={isVisible}
      getInitialFormValues={getInitialFormValues}
      handleOk={handleOk}
      handleCancel={handleCancel}
    />
  );
}
