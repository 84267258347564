// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/PreApprovalApplication.cs

import {z} from 'zod';
import {PreApprovalQuestionSchema} from './PreApprovalQuestionSchema.schema';
import {ApprovalRankTypeSchema} from './ApprovalRankTypeSchema.schema';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';

export const PreApprovalApplicationSchema = z.object({
  application: z.array(PreApprovalQuestionSchema),
  approvalRankType: ApprovalRankTypeSchema,
  id: z.number(),
  status: ApprovalStatusSchema,
});
