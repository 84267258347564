// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IPermission.cs

import {z} from 'zod';

export const IAcademyAndCustomProgramPermissionSchema = z.object({
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
});
