// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanResourceVM.cs

import {z} from 'zod';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {UserResourceStatusSchema} from './UserResourceStatusSchema.schema';

export const UserPlanResourceVMSchema = z.object({
  additionalRequestedAmount: z.number(),
  approvedAmount: z.number(),
  dateRequested: z.string().nullable().optional(),
  daysUntilExpiration: z.number().nullable().optional(),
  id: z.number(),
  purchaseMade: z.boolean().nullable().optional(),
  reimbursementApprovedAmount: z.number().nullable().optional(),
  reimbursementRequestedAmount: z.number().nullable().optional(),
  resourceType: LearningResourceTypeSchema,
  status: UserResourceStatusSchema,
  title: z.string(),
  url: z.string(),
});
