// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/User/UserSearchResultVM.cs

import {z} from 'zod';

export const UserSearchResultVMSchema = z.object({
  email: z.string().nullable().optional(),
  id: z.number(),
  name: z.string(),
});
