// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeDetailVm.cs

import {z} from 'zod';

export const UserTransactionInsightSchema = z.object({
  amount: z.number(),
  date: z.string(),
  description: z.string(),
  status: z.string(),
});
