export enum UploadTags {
  CustomProgramUpload = 'customProgramUpload',
}
export enum UploadConstraints {
  OneMegabyte = 1000000, // 1000^2 bytes = 1 MB. Your computer filesystem uses this
  OneMebibyte = 1048576, // 1024^2 bytes = 1 MiB. Amazon uses this
  MaxUserContentUploadSizeBytes = OneMegabyte * 500,
  MinChunkSize = OneMebibyte * 5,
  CustomProgramHeaderImageLimit = 1 * OneMegabyte,
}
