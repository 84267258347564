// Do not modify directly
// Generated from file: learnin.core.integrations/LXP/Models/Skills/SkillSearchResponse.cs

import {z} from 'zod';
import {SkillDetailSchema} from './SkillDetailSchema.schema';

export const SkillSearchResponseSchema = z.object({
  details: z.array(SkillDetailSchema),
  hasMoreItems: z.boolean(),
  total: z.number(),
});
