import {
  FeatureFlagTreatments,
  getLocalHostTreatment,
  isLDFeatureFlagOn,
} from '@utils/feature-flag-helpers';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {isLocal} from '@utils/environment-helpers';

/**
 * Hook get Launch Darkly feauture flag treatment by flag name
 * @param treatmentName
 * @returns treatment
 */
export default function useTreatment(
  treatmentName: string
): FeatureFlagTreatments {
  // New Launch Darkly treatment
  if (isLocal) {
    return getLocalHostTreatment(treatmentName);
  }
  const ldClient = useLDClient();
  return isLDFeatureFlagOn(ldClient?.variation(treatmentName));
}
