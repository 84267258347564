import {BASE_PATH} from '.';
import {addQueryParams, ReactMutateRequest} from './../utils';
import {
  EditProgram,
  FeatureProgram,
  HideProgram,
  NewProgram,
  ProgramReviewPayload,
  ProgramWithApprovalPayload,
  RecommendProgram,
} from '@models/serverModels';
import {LanguagePreference} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Reviews
|--------------------------------------------------------------------------
*/

export const getAddProgramReviewRm = (
  reviewToAdd: ProgramReviewPayload
): ReactMutateRequest => {
  const path = `/${BASE_PATH}/add-review`;
  return {
    path,
    payload: reviewToAdd,
  };
};

export const getUpdateProgramReviewRm = (
  reviewToUpdate: ProgramReviewPayload
): ReactMutateRequest => {
  const path = `/${BASE_PATH}/update-review`;
  return {
    path,
    payload: reviewToUpdate,
  };
};

export const getDeleteProgramReviewRm = (
  reviewId: number
): ReactMutateRequest => {
  const baseUrl = `${BASE_PATH}/delete-review`;
  const path = `${baseUrl}/${reviewId}`;

  return {
    path,
    payload: {},
  };
};

/*
|--------------------------------------------------------------------------
| Marketplace Program Attributes
|--------------------------------------------------------------------------
*/

export const updateFeatureProgramRm = (
  payload: FeatureProgram
): ReactMutateRequest => {
  const path = `${BASE_PATH}/feature-program`;
  return {
    path,
    payload,
  };
};

export const updateProgramAvailabilityRm = (
  payload: HideProgram
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-program-availability`;
  return {
    path,
    payload,
  };
};

/*
|--------------------------------------------------------------------------
| Recommendations
|--------------------------------------------------------------------------
*/

export const getRecommendToUsersRm = (
  payload: RecommendProgram
): ReactMutateRequest => {
  const path = `${BASE_PATH}/recommend-to-users`;
  return {
    path,
    payload,
  };
};

export const getDeleteRecommendationRm = (id: number): ReactMutateRequest => {
  const path = `${BASE_PATH}/delete-user-recommendation/${id}`;
  return {
    path,
    payload: {},
  };
};

/*
|--------------------------------------------------------------------------
| Program Search
|--------------------------------------------------------------------------
*/

export const getProgramSearchRm = (
  keywords?: string,
  minPrice?: string | number,
  maxPrice?: string | number,
  programTypeIds?: Array<string | number>,
  durationIds?: Array<string | number>,
  skillIds?: Array<string | number>,
  providerIds?: Array<string | number>,
  languages?: Array<LanguagePreference>
) => {
  const baseUrl = `${BASE_PATH}/search`;
  const queryParams = [];

  keywords && queryParams.push(`keywords=${keywords}`);
  minPrice && queryParams.push(`minPrice=${minPrice}`);
  maxPrice && queryParams.push(`maxPrice=${maxPrice}`);
  if (programTypeIds?.length) {
    programTypeIds.forEach((id) => {
      queryParams.push(`programTypeIds=${id}`);
    });
  }
  if (durationIds?.length) {
    durationIds.forEach((id) => {
      queryParams.push(`durationIds=${id}`);
    });
  }
  if (skillIds?.length) {
    skillIds.forEach((id) => {
      queryParams.push(`skillIds=${id}`);
    });
  }
  if (providerIds?.length) {
    providerIds.forEach((id) => {
      queryParams.push(`providerIds=${id}`);
    });
  }
  if (languages?.length) {
    languages.forEach((id) => {
      queryParams.push(`languages=${id}`);
    });
  }

  return {
    path: addQueryParams(baseUrl, queryParams),
    payload: {},
  };
};

export const getAddCompanyProgramRm = (
  programToAdd: NewProgram
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-company-program`;
  const escapedProgram = {
    ...programToAdd,
    ...{url: encodeURIComponent(programToAdd.url)},
  };

  return {
    path,
    payload: escapedProgram,
  };
};

export const getAddProgramWithApprovalRm = (
  payload: ProgramWithApprovalPayload
) => {
  const baseUrl = `/${BASE_PATH}/add-program-with-approval`;
  return {
    path: baseUrl,
    payload,
  };
};

export const getAddNewProgramRm = (payload: NewProgram) => {
  const baseUrl = `/${BASE_PATH}/add-program`;
  return {
    path: baseUrl,
    payload,
  };
};

export const getUpdateMarketplaceProgramRm = (
  payload: EditProgram
): ReactMutateRequest => {
  const path = ['program', 'edit-company-program'].join('/');
  return {
    path,
    payload,
  };
};
