import {SyntheticEvent, useState} from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {CopyOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';

const StyledButton = styled.button`
  background: inherit;
  border: none;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

interface Props {
  text: string;
  children?: React.ReactNode;
  ariaLabel?: string;
  onTextCopied?: () => void;
  onMouseLeave?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
}
export const CopyToClipboard = ({
  children,
  text,
  ariaLabel,
  onTextCopied,
  onMouseLeave,
}: Props) => {
  const [_, setCopySuccess] = useState(false);

  const copyText = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      onTextCopied?.call(null);
    } catch (err) {}
  };

  return (
    <StyledButton
      aria-label={ariaLabel ?? i18n.t(k.CTA__COPY_TO_CLIPBOARD)}
      onClick={copyText}
      onMouseLeave={onMouseLeave}>
      {children ? children : <CopyOutlined aria-hidden="true" />}
    </StyledButton>
  );
};

export default CopyToClipboard;
