/* eslint-disable react/prop-types */
function CaretRight(props) {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.33622 1.19323C0.0758704 1.45358 0.0758701 1.87569 0.33622 2.13604L6.58233 8.38215C6.64742 8.44724 6.64742 8.55276 6.58233 8.61785L0.33622 14.864C0.0758701 15.1243 0.0758704 15.5464 0.33622 15.8068L1.04333 16.5139C1.30368 16.7742 1.72579 16.7742 1.98614 16.5139L9.0572 9.44281C9.5779 8.92211 9.5779 8.07789 9.0572 7.55719L1.98614 0.486123C1.72579 0.225774 1.30368 0.225773 1.04333 0.486123L0.33622 1.19323Z"
        fill={props?.fill || '#747578'}
      />
    </svg>
  );
}

export default CaretRight;
