// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/PopularProgramVM.cs

import {z} from 'zod';

export const PopularProgramVMSchema = z.object({
  applicationUrl: z.string(),
  enrolled: z.number(),
  id: z.number(),
  isInternalProgram: z.boolean(),
  programType: z.string(),
  providerLogo: z.string(),
  providerName: z.string(),
  title: z.string(),
});
