import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import RequestForm from '../RequestForm/RequestForm.container';
import RequestMenu from '../RequestMenu/RequestMenu.view';
import {RequestMenuButtonAndDrawerStyles} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import RequestProgramFinderBanner from '../RequestProgramFinderBanner/RequestProgramFinderBanner.view';
import {REQUEST_PROGRAM_FINDER_BANNER_TEST_ID} from './RequestMenuDrawer.constants';
import {RequestMenuDrawerViewProps} from './RequestMenuDrawer.interfaces';

function RequestMenuDrawer({
  form,
  formVisible,
  handleOnClickMarketplaceLink,
  handleOnClickOption,
  handleOnClickProgramFinder,
  handleOnClickReset,
  handleOnCloseSuccess,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  marketplaceLinkText,
  onSubmit,
  requestType,
  requestTypeOptions,
  rerequestResource,
  reset,
  selectionIndex,
  setIsSubmitButtonLoading,
  setRequestType,
  setOnSubmit,
  setTotal,
  showFindProgramOption,
  showFooter,
  total,
}: RequestMenuDrawerViewProps) {
  return (
    <LearnInDrawer
      closeButtonPlacement={'right'}
      title={i18n.t(k.FUND__REQUEST__NEW)}
      footerStyle={{borderTopColor: COLORS.Neutral400}}
      onClose={reset}
      bodyStyle={RequestMenuButtonAndDrawerStyles}
      footer={
        <FooterButtonsContainer>
          <LearnInButton
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            onClick={handleOnClickReset}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            disabled={isSubmitButtonDisabled}
            loading={isSubmitButtonLoading}
            onClick={() => {
              onSubmit();
            }}
            data-cy="requestSubmitButton">
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </FooterButtonsContainer>
      }
      visible={true}>
      {showFindProgramOption && (
        <RequestProgramFinderBanner
          onButtonClick={handleOnClickProgramFinder}
          dataTestId={REQUEST_PROGRAM_FINDER_BANNER_TEST_ID}
        />
      )}

      {/* This becomes the option's header once an option is selected */}
      {
        <RequestMenu
          marketplaceLinkText={marketplaceLinkText}
          handleOnClickMarketplaceLink={handleOnClickMarketplaceLink}
          handleOnClickOption={handleOnClickOption}
          requestTypeOptions={requestTypeOptions}
          selectionIndex={selectionIndex}
          showFooter={showFooter}
        />
      }

      {/* Application form */}
      {formVisible && (
        <RequestForm
          form={form}
          handleOnCloseSuccess={handleOnCloseSuccess}
          requestType={requestType}
          rerequestResourceType={rerequestResource?.resourceType}
          setIsSubmitButtonLoading={setIsSubmitButtonLoading}
          setOnSubmit={setOnSubmit}
          setRequestType={setRequestType}
          setTotal={setTotal}
          total={total}
          userPlanResourceId={rerequestResource?.id}
        />
      )}
    </LearnInDrawer>
  );
}

export default RequestMenuDrawer;
