/** These strings aren't customer-facing: these don't need to be localized. */
export const REQUESTED = {
  name: 'Donkey Kong',
  requesterName: 'Dixie Kong',
  amount: 'CAD 45.67',
  providerName: 'Nintendo',
  providerImageUrl: 'https://logodix.com/logo/1023869.png',
  programName: 'Breaking Barrels With Your Hands',
  buttonUrl:
    'https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/',
  itemType: 'programLicense',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
};
