import {
  ApprovalRequestDetailsVM,
  UserPlanFinanceItemVM,
  UserPlanProgramItemVM,
  UserPlanTimeItemVM,
} from '@models/serverModels';
import {openInNewTab} from '@components/providers/utils';
import {PlanItemType} from '@generated/enums';
import {UserPlanItemStatusString} from '@models/clientEnums';
import {JSXElementConstructor, ReactElement} from 'react';

export type TItem =
  | UserPlanFinanceItemVM
  | UserPlanProgramItemVM
  | UserPlanTimeItemVM;

export type UserPlanCardItem = TItem &
  Partial<{
    licenseId: number;
    id: number;
  }> &
  Partial<UserPlanFinanceItemVM> &
  Partial<UserPlanProgramItemVM> &
  Partial<UserPlanTimeItemVM>;

/*
|--------------------------------------------------------------------------
| Item State Utils
|--------------------------------------------------------------------------
*/

export const isItemComplete = (item: any): boolean => {
  return item.status === UserPlanItemStatusString.Complete;
};

/*
|--------------------------------------------------------------------------
| Determine which CTA button to render on Card or Detail Page
|--------------------------------------------------------------------------
*/

export enum ItemCTAButtonType {
  Selected,
  AddToInitiative,
  AddToPlan,
  RequestApproval,
  RequestLicense,
  ViewLicense,
  Rejected,
  Requested,
  Resubmit,
}

export const getItemCTAButtonType = ({
  approvalRequired = false,
  approvalRequested,
  approvalReceived = false,
  approvalRejected = false,
  forAdminInitiative = false,
  selected,
  cost,
  isLicenseProgram,
  licenseId,
  daysUntilExpiration,
  itemType,
}: {
  approvalRequired?: boolean;
  forAdminInitiative?: boolean;
  approvalRequested: boolean;
  approvalReceived?: boolean;
  approvalRejected?: boolean;
  licenseRequested?: boolean;
  selected: boolean;
  cost?: number;
  isLicenseProgram?: boolean;
  licenseId?: number;
  daysUntilExpiration?: number;
  itemType: PlanItemType;
}): ItemCTAButtonType | null => {
  if (isLicenseProgram) {
    if (
      inPlanWithActiveLicense(
        selected,
        approvalReceived,
        isLicenseProgram && !!licenseId,
        daysUntilExpiration
      )
    ) {
      return ItemCTAButtonType.ViewLicense;
    } else if (approvalRejected) {
      return ItemCTAButtonType.Rejected;
    } else if (approvalRequested) {
      return ItemCTAButtonType.Requested;
    } else if (selected && daysUntilExpiration > -1) {
      return ItemCTAButtonType.Selected;
    }
    return ItemCTAButtonType.RequestLicense;
  }

  // Admin Initiative Buttons are used to add/remove items from an initiative
  if (forAdminInitiative) {
    if (selected) {
      return ItemCTAButtonType.Selected;
    }
    return ItemCTAButtonType.AddToInitiative;
  }

  // Handle Free Programs
  if (itemType === PlanItemType.Program && !cost) {
    if (selected) {
      return ItemCTAButtonType.Selected;
    } else {
      return ItemCTAButtonType.AddToPlan;
    }
  }

  // Handle Items that require approval
  if (approvalRequired) {
    if (approvalRequested) {
      return ItemCTAButtonType.Requested;
    } else if (selected) {
      return ItemCTAButtonType.Selected;
    } else if (approvalRejected) {
      return ItemCTAButtonType.Resubmit;
    } else return ItemCTAButtonType.RequestApproval;
  }

  // Handle items that do not require approval
  if (selected) {
    return ItemCTAButtonType.Selected;
  }
  return ItemCTAButtonType.AddToPlan;
};

/*
|--------------------------------------------------------------------------
| Licenses
|--------------------------------------------------------------------------
*/

export const inPlanWithActiveLicense = (
  selected: boolean,
  isApproved: boolean,
  hasLicense: boolean,
  daysUntilExpiration?: number
): boolean => {
  return (
    selected &&
    isApproved &&
    hasLicense &&
    (!daysUntilExpiration || daysUntilExpiration > -1)
  );
};

export const addLicenseFlag = (data: any[]) => {
  data?.forEach((item) => (item.hasLicense = true));
  return data;
};

/*
|--------------------------------------------------------------------------
| Approvals
|--------------------------------------------------------------------------
*/

export const transformApprovalResponse = (
  data: ApprovalRequestDetailsVM[]
): ApprovalRequestDetailsVM[] => {
  return addLicenseFlag(data);
};

/** This displays unsanitized HTML and is only safe when we completely control the input (input from a user is not allowed to be mixed). The consequence of using with user input is it opens an XSS vulnerability. */
export const noUserInputAllowedHtml = (htmlString: string) => (
  <div
    dangerouslySetInnerHTML={{
      __html: htmlString,
    }}></div>
);

export const showHelperTextIfAvailable = (
  isHelperTextOn: boolean,
  helperText: string,
  sanitizeHtml = false
) =>
  isHelperTextOn
    ? sanitizeHtml
      ? helperText
      : noUserInputAllowedHtml(helperText)
    : null;

/*
|--------------------------------------------------------------------------
| Urls
|--------------------------------------------------------------------------
*/

export const openProgramExternalUrl = (item: UserPlanProgramItemVM) => {
  openInNewTab((item as UserPlanProgramItemVM).externalUrl);
};

/**
 * URL validation is non-trivial. This is a simple but effective solution of
 * our most common cases. See the tests for example of its pass/fails.
 *
 * This intentionally does not strictly adhere to the RFC-3986 specification.
 * Strict adherence would fail many of the cases we are intentionally allowing.
 *
 * Because of this, if you are looking for an RFC-3986 compliant validation,
 * it's best to create a separate function.
 *
 * For RFC-3986, here's a caution and implementation:
 * http://guido-flohr.net/the-gory-details-of-url-validation/
 *
 * https://github.com/gflohr/Lingua-Poly/blob/main/src/app/core/validators/urlValidator.tsx
 */
export const validateUrl = (url: string) => {
  if (!url) return false;

  const isLeadingWww = url?.toLowerCase().substring(0, 4) === 'www.';
  const isInferredProtocol = url?.toLowerCase().substring(0, 2) === '//';
  const dots = url.match(/\./g);
  const wwwWithoutExt = isLeadingWww && (dots === null || dots.length < 2);
  const isInferredPrefixWithoutExt =
    !isLeadingWww && (dots === null || dots.length < 1);
  const isInferredPrefixWithExt =
    !isInferredPrefixWithoutExt && dots !== null && dots.length > 0;
  const hasInferredPrefix =
    isLeadingWww || isInferredProtocol || isInferredPrefixWithExt;
  const hasTrailingDot =
    dots !== null && dots.length === 1 && url.endsWith('.');
  const hasWwwAndTrailingDot =
    isLeadingWww && dots !== null && dots.length === 2 && url.endsWith('.');
  const partialProtocol = url.indexOf(':/');
  const hasIncompleteProtocol =
    partialProtocol > -1 &&
    url.substring(partialProtocol + 2, partialProtocol + 3) !== '/';

  const isKnownFailure =
    wwwWithoutExt ||
    isInferredPrefixWithoutExt ||
    !isInferredPrefixWithExt ||
    hasTrailingDot ||
    hasWwwAndTrailingDot ||
    hasIncompleteProtocol;

  if (isKnownFailure) return false;

  try {
    new URL(hasInferredPrefix ? `https://${url}` : url);
    return true;
  } catch {
    return false;
  }
};

export const validateEmail = (email: string) => {
  const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return validEmail.test(email);
};

/**
 * Error messages need to also incorporate any helper text provided for the input
 */
export const handleInputErrorText = (
  validationMsg:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>,
  helperText?: string,
  isHelperTextOn = false
) => validationMsg + (isHelperTextOn && helperText ? ` — ${helperText}` : '');
