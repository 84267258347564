/** These strings aren't customer-facing: these don't need to be localized. */
export const PROJECT_SUBMISSION_FEEDBACK_ACADEMY = {
  name: 'Eleven',
  title: 'D&D Starter Kit',
  employeeName: 'Steve',
  projectUrl: 'https://staging.degreed.dev/develop/#fake-project-url',
  message:
    'I think you did a great job, but focus on taking care of the demogorgons first.',
  type: 'academy',
  parentTitle: 'Academy of Paranormal Studies',
  companyLogoUrl:
    'https://static.wikia.nocookie.net/logopedia/images/f/f5/Stranger_Things.svg/revision/latest/scale-to-width-down/300?cb=20220803140619',
};
