// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Responses/AcademyIntegrationChatChannelResponse.cs

import {z} from 'zod';
import {IntegrationChatChannelTypeSchema} from './IntegrationChatChannelTypeSchema.schema';
import {IntegrationChatProviderSchema} from './IntegrationChatProviderSchema.schema';

export const AcademyIntegrationChatChannelResponseSchema = z.object({
  academyId: z.number(),
  channelLink: z.string(),
  id: z.number(),
  integrationChatChannelType: IntegrationChatChannelTypeSchema,
  integrationChatProvider: IntegrationChatProviderSchema,
});
