import {Form} from 'antd';
import {useEffect, useState} from 'react';
import {useGetRequestMenuButtonAndDrawerHandlers} from './RequestMenuButtonAndDrawer.handlers';
import {
  useRequestMenuOptionCustomHooks,
  useRequestMenuOptionEffects,
} from './RequestMenuButtonAndDrawer.hooks';
import {
  RequestMenuButtonAndDrawerContainerProps,
  RequestType,
} from './RequestMenuButtonAndDrawer.interfaces';
import RequestMenuButtonAndDrawerView from './RequestMenuButtonAndDrawer.view';

function RequestMenuButtonAndDrawerContainer({
  buttonAltStyle,
  buttonTitle,
  rerequestResource,
  showFindProgramOption,
  showProgramFinderOption,
  showRequestProgramOption,
  showRequestResourceOption,
  showButton,
  drawerVisible: drawerVisibleOptional,
  setDrawerVisible: setDrawerVisibleOptional,
}: RequestMenuButtonAndDrawerContainerProps) {
  const [form] = Form.useForm();

  const [bypassMenuSelection, setBypassMenuSelection] = useState(false);
  // Initialize with passed in state
  const [drawerVisible, setDrawerVisible] = useState(
    drawerVisibleOptional ?? false
  );
  const [formVisible, setFormVisible] = useState(false);
  const [requestType, setRequestType] = useState<RequestType | undefined>(
    undefined
  );
  const [selectionIndex, setSelectionIndex] = useState(null);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [onSubmit, setOnSubmit] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const [total, setTotal] = useState(null);

  // Update both local and passed in state
  const handleSetDrawerVisible = (visible: boolean) => {
    setDrawerVisible(visible);
    setDrawerVisibleOptional?.(visible);
  };

  const {
    marketplaceLinkText,
    navigate,
    requestTypeOptions,
    userProgramPlanQuery,
    learningBudsQuery,
  } = useRequestMenuOptionCustomHooks({
    showRequestProgramOption,
    showRequestResourceOption,
  });

  const {
    handleReset,
    handleOnSubmit,
    handleOnClickMarketplaceLink,
    handleOnClickOption,
    handleOnClickProgramFinder,
    handleOnClickReset,
    handleOnCloseSuccess,
  } = useGetRequestMenuButtonAndDrawerHandlers({
    drawerVisible,
    form,
    navigate,
    onSubmit,
    setDrawerVisible: handleSetDrawerVisible,
    setRequestType,
    setSelectionIndex,
    setIsSubmitButtonDisabled,
    setIsSubmitButtonLoading,
    userProgramPlanQuery,
    learningBudsQuery,
  });

  useRequestMenuOptionEffects({
    bypassMenuSelection,
    formVisible,
    requestType,
    requestTypeOptions,
    selectionIndex,
    setBypassMenuSelection,
    setFormVisible,
    setIsSubmitButtonDisabled,
    setRequestType,
    setSelectionIndex,
    setShowFooter,
    showProgramFinderOption,
    setDrawerVisible: handleSetDrawerVisible,
    drawerVisible,
  });

  // Watch for changes in the passed in state and update local state
  useEffect(() => {
    setDrawerVisible(drawerVisibleOptional ?? false);
  }, [drawerVisibleOptional]);

  return (
    <RequestMenuButtonAndDrawerView
      buttonAltStyle={buttonAltStyle}
      buttonTitle={buttonTitle}
      drawerVisible={drawerVisible}
      form={form}
      formVisible={formVisible}
      handleOnClickMarketplaceLink={handleOnClickMarketplaceLink}
      handleOnClickOption={handleOnClickOption}
      handleOnClickProgramFinder={handleOnClickProgramFinder}
      handleOnClickReset={handleOnClickReset}
      handleOnCloseSuccess={handleOnCloseSuccess}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      isSubmitButtonLoading={isSubmitButtonLoading}
      marketplaceLinkText={marketplaceLinkText}
      navigate={navigate}
      onSubmit={handleOnSubmit}
      requestType={requestType}
      requestTypeOptions={requestTypeOptions}
      rerequestResource={rerequestResource}
      reset={handleReset}
      selectionIndex={selectionIndex}
      setDrawerVisible={handleSetDrawerVisible}
      setIsSubmitButtonLoading={setIsSubmitButtonLoading}
      setOnSubmit={setOnSubmit}
      setRequestType={setRequestType}
      setTotal={setTotal}
      showFindProgramOption={showFindProgramOption}
      showFooter={showFooter}
      showButton={showButton}
      total={total}
    />
  );
}

export default RequestMenuButtonAndDrawerContainer;
