// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramSectionPayload.cs

import {z} from 'zod';

export const CustomProgramSectionPayloadSchema = z.object({
  customProgramId: z.number().nullable().optional(),
  isFullScreen: z.boolean(),
  sectionId: z.number().nullable().optional(),
  title: z.string(),
});
