import {ApprovalsDiscriminator} from '../../../../src/api/generated/enums';

/** These strings aren't customer-facing: these don't need to be localized. */
export const REQUEST_APPROVED = {
  name: 'Diddy Kong',
  provider: 'Nintendo',
  title: 'Bananas As A Currency',
  imageUrl: 'https://logodix.com/logo/1023869.png',
  approved: true,
  approver: 'Link',
  approvalNotes: 'LGTM',
  approvalsDiscriminator: ApprovalsDiscriminator.Program,
  amountRequested: '$123.45',
  isReimbursement: false,
  isAdditional: false,
  itemType: 'program',
  daysUntilExpiration: null,
  canUsePlb: true,
  buttonUrl:
    'https://www.nintendo.com/us/store/products/super-mario-odyssey-switch/',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
};
