import {RouteElement} from '@models/clientModels';
import {Route} from 'react-router-dom';

/** Returns nodes of `<Route />`s and any embedded children.
 *  Only one level of children is supported, but more can likely be supported.
 *
 *  Consider a normal `<Routes><Route /></Routes>` first.
 *
 *  This is aimed for when `<Route />`s have conditional access, require the
 *  logic to be abstracted for tests, or the routes are built dynamically.
 *  Ensure your use case is worth the abstraction and complexity.
 */
export const useBuildRouteElements = (routes: RouteElement[]): JSX.Element => {
  const routeElement = (route: RouteElement) => (
    <Route key={route.path} path={route.path} element={route.element} />
  );

  return (
    <>
      {routes?.map((route) => {
        // Disabled
        if (!route.enabled) return;

        // Standard (no children)
        if (!route.children?.length) return routeElement(route);

        // Nested children
        return (
          <Route key={route.path} path={route.path} element={route.element}>
            {route.children.map((childRoute) => routeElement(childRoute))}
          </Route>
        );
      })}
    </>
  );
};
