import styled from 'styled-components';
import {Typography} from 'antd';
import {convertHexToHSL} from '@utils/color';
import {COLORS} from '@utils/constants';
import {REQUEST_MENU_HIDDEN_CLASS} from './RequestMenu.constants';

const commonHide = `
  min-height: 0;
  height: 0;
  opacity: 0;
  transform: scale(0);
  h3, h4 {
    display: none;
  }
`;
const commonWillChange = 'height, margin, min-height, opacity';
const willChangeWithTransform = `${commonWillChange}, transform`;
const timingFunction = 'ease-out';
const baseZIndex = 0;

// Public
export const commonMargin = '1.125rem';
export const requestFormTransitionRate = 0.225;

export const RequestMenuButtonAndDrawerIcon = styled.div`
  .learnin-icon {
    background: ${(props) => convertHexToHSL(props.iconBackground, 0.55)};
    border-radius: 4px;
    color: ${(props) => props.iconColor};
    height: auto;
    padding: 0.5rem;
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
    width: auto;
  }
`;

export const RequestMenuButtonAndDrawerTitleSection = styled.div`
  padding-left: 1rem;
`;

export const RequestMenuButtonAndDrawerTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0 0 0.25rem;
`;

export const RequestMenuButtonAndDrawerSubTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
`;

export const RequestMenuButtonAndDrawerParagraphText = styled(
  Typography.Paragraph
)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0 0 ${commonMargin} !important;
  min-height: 1.25rem;
  position: relative;
  transition-duration: ${requestFormTransitionRate}s;
  transition-timing-function: ${timingFunction};
  transition-property: ${commonWillChange};
  will-change: ${commonWillChange};
  z-index: ${baseZIndex};

  &.${REQUEST_MENU_HIDDEN_CLASS} {
    height: 0;
    margin: 0 !important;
    min-height: 0;
    text-indent: -100vw;
  }
`;

export const RequestMenuButtonAndDrawerListContainer = styled.div`
  margin: 2rem ${commonMargin} 0;
`;

export const RequestMenuButtonAndDrawerList = styled.ol`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: ${baseZIndex + 1};
`;

export const RequestMenuButtonAndDrawerListItem = styled.li`
  display: flex;
  min-height: 3rem;
  position: relative;
  transition: ${willChangeWithTransform} ${requestFormTransitionRate}s
    ${timingFunction};
  width: 100%;
  will-change: ${willChangeWithTransform};

  &.${REQUEST_MENU_HIDDEN_CLASS} {
    ${commonHide}
  }
`;

export const RequestMenuButtonAndDrawerListButton = styled.button`
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border-radius: 4px;
  display: flex;
  line-height: 1.5rem;
  margin: 0 0 ${commonMargin};
  min-height: 80px;
  opacity: 1;
  padding: 1rem;
  transition-duration: ${requestFormTransitionRate}s;
  width: 100%;

  &:disabled {
    cursor: default;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: ${(props) => props.hoverBackground};
    }

    &:focus-visible {
      outline: 2px solid ${COLORS.Blue50};
      transition: none;
    }

    &:active {
      background: ${(props) => props.activeBackground};
    }
  }
`;

export const RequestMenuButtonAndDrawerListArrow = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  text-align: right;

  .arrow-icon {
    height: 20px;
    width: 20px;
  }

  &.${REQUEST_MENU_HIDDEN_CLASS} {
    ${commonHide}
  }
`;
