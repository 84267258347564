import {FinanceType} from '@generated/enums';

export const stripeTosUrl =
  'https://stripe.com/legal/issuing/celtic-authorized-user-terms';

export const modalWidth = 680;

export const cacheForHoursLength = 6;

export const isStripeTermsAvailable = (
  plbType: FinanceType,
  hasAcceptedStripeTerms?: boolean
) => plbType === FinanceType.Prepayment && hasAcceptedStripeTerms === false; // look for explicitly false, not falsey, since nil means no VC
