import AnalyticsInit from '@blocks/analyticsInit/AnalyticsInit';
import FeatureFlagInit from '@blocks/featureFlagInit/FeatureFlagInit';
import '@fullcalendar/react/dist/vdom';
import queryClient from '@store/queryClient';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {FunctionComponent, ReactElement} from 'react';
import {QueryClientProvider} from 'react-query';
import {HashRouter} from 'react-router-dom';
import styled from 'styled-components';
import './assets/App.scss';
import './assets/tailwind.css';
import {IdleProvider} from './blocks/IdleContext';
import LocalizationInit from './blocks/localizationInit/LocalizationInit';
import LoadingSpinner from './elements/loadingSpinner/LoadingSpinner';
import PrimaryRoutes from './PrimaryRoutes.container';
import {HelmetProvider} from 'react-helmet-async';

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const App: FunctionComponent<any> = (): ReactElement => {
  const {isAuthenticated} = useAuth();
  if (isAuthenticated) {
    return (
      <QueryClientProvider client={queryClient}>
        <FeatureFlagInit>
          <AnalyticsInit>
            <LocalizationInit>
              <HashRouter>
                <IdleProvider timeout={15 * 1000 * 60}>
                  <HelmetProvider>
                    <PrimaryRoutes />
                  </HelmetProvider>
                </IdleProvider>
              </HashRouter>
            </LocalizationInit>
          </AnalyticsInit>
        </FeatureFlagInit>
      </QueryClientProvider>
    );
  } else {
    return (
      <SpinnerContainer>
        <LoadingSpinner />
      </SpinnerContainer>
    );
  }
};

export default App;
