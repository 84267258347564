// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewCustomProgramContentPayload.cs

import {z} from 'zod';
import {BaseCustomProgramContentPayloadSchema} from './BaseCustomProgramContentPayloadSchema.schema';
import {ContentTypeSchema} from './ContentTypeSchema.schema';

const schema = z.object({
  contentType: ContentTypeSchema,
  customProgramId: z.number(),
  order: z.number().nullable().optional(),
  sectionId: z.number(),
});

export const NewCustomProgramContentPayloadSchema =
  BaseCustomProgramContentPayloadSchema.merge(schema);
