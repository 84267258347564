import {RouteElement} from '@models/clientModels';
import {UserPaths} from '@utils/ClientPaths';
import PrimaryRoutesShared from 'PrimaryRoutesShared';
import {Navigate} from 'react-router-dom';

/** Get enabled routes for integrated users
 *  This is used in combination of useBuildRouteElements to handle complexity.
 */
const useGetEnabledIntegratedPrimaryRoutes = ({
  isAdmin,
  isIntegratedUser,
  isManager,
}: {
  isAdmin: boolean;
  isIntegratedUser: boolean;
  isManager: boolean;
}): RouteElement[] => {
  const enabled = true;
  const routeElements: RouteElement[] = [
    {
      path: '/',
      enabled,
      element: <Navigate to={UserPaths.Index} replace />,
    },
    {
      path: '*',
      enabled: isIntegratedUser,
      element: <PrimaryRoutesShared isAdmin={isAdmin} isManager={isManager} />,
    },
  ];
  return routeElements;
};

export default useGetEnabledIntegratedPrimaryRoutes;
