import styled, {css} from 'styled-components';

/*
These are the breakpoints:
  Small Mobile (< 376)
  Mobile (377 - 768) 
  Tablet (769 - 992) 
  Desktop (993 - 1200) 
  Large Desktop (> 1600) 
*/

export enum Breakpoints {
  smallMobile = 376,
  Mobile = 768,
  Tablet = 992,
  Desktop = 1200,
  DesktopLarge = 1600,
}
export const getScreenWidth = (): number => window.innerWidth;

/*
|--------------------------------------------------------------------------
| Make drawer height and width responsive
|--------------------------------------------------------------------------
*/

// This method can be used for height and width, ie drawers from all positions
export const getResponsiveDrawerSize = (
  size?: number | string
): string | '100%' => {
  const screenWidth = getScreenWidth();
  // fullscreen on mobile
  if (screenWidth <= Breakpoints.Tablet) {
    return '100%';
  }
  if (typeof size === 'string') {
    return size;
  }
  return `min(100%, ${size || 650}px)`;
};

/*
|--------------------------------------------------------------------------
| Wrapper Components
|--------------------------------------------------------------------------
*/

// Does not display content outside of the min/max bounds. Used to display content only at certain screen-widths
export const ResponsiveContainer = styled.span<{
  min?: Breakpoints;
  max?: Breakpoints;
  display?: string;
}>`
  display: none;
  ${({min = 0, max = 3600}) => {
    return css`
      @media (min-width: ${min}px) and (max-width: ${max}px) {
        display: block;
      }
    `;
  }}
`;
