// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ProgramSearchResult.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LanguagePreferenceSchema} from './LanguagePreferenceSchema.schema';

export const ProgramSearchResultSchema = z.object({
  actualLearningTime: z.string().nullable().optional(),
  approvalRequested: z.boolean(),
  approvalRequired: z.boolean(),
  cost: z.number(),
  currency: CurrencyCodeSchema,
  id: z.number(),
  imageLarge: z.string(),
  imageSmall: z.string(),
  initiativePurpose: z.string(),
  language: LanguagePreferenceSchema,
  licenseCost: z.number().nullable().optional(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseExpirationDate: z.string().nullable().optional(),
  licenseId: z.number(),
  longDescription: z.string(),
  presenceType: z.string(),
  programDuration: z.number().nullable().optional(),
  programLengthUnit: z.string(),
  programType: z.string(),
  provider: z.string(),
  providerId: z.number().nullable().optional(),
  providerLogo: z.string(),
  ptft: z.string(),
  rank: z.number(),
  recommendationLevel: z.string(),
  requestRemoveConfirmation: z.boolean(),
  selected: z.boolean(),
  shortDescription: z.string(),
  status: z.string(),
  timeCommitHoursWeek: z.number(),
  title: z.string(),
});
