import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInDrawer, {
  DrawerFormContentContainer,
  FormColumn,
  FormColumnsContainer,
  renderFooter,
} from '@components/reusable/LearnInDrawer';
import Grid from '@blocks/Grid';
import {
  CurrencyCode,
  LearningResourceType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import PreApprovalFormItem from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem.container';
import {APPROVAL_AUTOPOPULATE_MULTIPLIER, COLORS} from '@utils/constants';
import {ApplicationQuestionVM} from '@models/serverModels';
import {RejectionNotes} from '@components/user/pages/components/PreApprovalFormItem/RejectionNotes';
import {TotalCost} from '@components/user/pages/components/PreApprovalFormItem/TotalCost';
import {useState} from 'react';
import {RequestOptionButtonProps} from '../request/Request.interfaces';
import RequestMenuOption from '../request/RequestMenuOption/RequestMenuOption.view';
import {MONEY_DEFAULT} from '@utils/moneyUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  width: 100%;
  .ant-form-item {
    display: block;
    width: 100%;
  }
`;

const FormContainer = styled(FormColumnsContainer)`
  flex-direction: column;
  margin-left: 0;

  .ant-form {
    width: 100%;
  }
`;

const FormSection = styled(FormColumn)`
  max-width: 100%;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface FormFields {
  resourceType?: LearningResourceType;
  description?: string;
  url?: string;
  amount?: number;
  purchaseReason?: string;
}

export interface RequestResourceApprovalDrawerProps {
  frequencyDropdown?: ApplicationQuestionVM;
  form: FormInstance;
  formQuestions: ApplicationQuestionVM[];
  isApplicationRejected?: boolean;
  isSubmitButtonLoading: boolean;
  learningResourceOptions: LearningResourceType[];
  onChangeResourceType: (resourceType: LearningResourceType) => void;
  onClickSubmit: (callback?: () => void) => void;
  onClose: () => void;
  rejectedNotes?: string;
  requestResourceTypeOptions?: RequestOptionButtonProps[];
  rerequestResourceType?: LearningResourceType; // This variable is present for loading applications that have previously been saved and submitted
  resourceType: LearningResourceType;
  setTotal?: (total: number) => void;
  total?: number;
  exchangeRate?: number;
  usersCurrency: CurrencyCode;
  visible: boolean;
  hideHeader?: boolean;
}
function RequestResourceApprovalDrawer({
  frequencyDropdown,
  form,
  formQuestions,
  isSubmitButtonLoading,
  isApplicationRejected,
  onClickSubmit,
  onClose,
  requestResourceTypeOptions,
  rejectedNotes,
  rerequestResourceType,
  resourceType,
  setTotal,
  total,
  usersCurrency,
  exchangeRate,
  visible,
  hideHeader,
}: RequestResourceApprovalDrawerProps) {
  // Shared between (at least) MoneyInput & PreApprovalFormItem
  const [currencySelected, setCurrencySelected] =
    useState<CurrencyCode>(usersCurrency);

  const requestResource = (
    <Container>
      <DrawerFormContentContainer>
        <FormContainer>
          {!!rerequestResourceType && requestResourceTypeOptions?.length && (
            <RequestMenuOption
              bodyStyle={{marginBottom: '2rem'}}
              option={requestResourceTypeOptions.find(
                (option) => option.requestType === resourceType
              )}
              selected={true}
            />
          )}
          <FormSection>
            <Grid.Container>
              <Form className="form-drawer" form={form} layout="vertical">
                {formQuestions.map((item, idx) => {
                  return (
                    <Grid.Box key={item.id}>
                      <PreApprovalFormItem
                        total={total}
                        frequencyDropdown={frequencyDropdown}
                        onTotalChange={setTotal}
                        resourceType={resourceType}
                        focusField={idx === 0}
                        form={form}
                        formQuestions={formQuestions}
                        item={item}
                        onChangeSelect={() => {
                          /* https://linear.app/learnin/issue/LI-4265/implement-checkboxes-and-selects-for-request-resources-form */
                        }}
                        selectedCheckboxOptions={[]}
                        onChangeCheckbox={() => {
                          /* https://linear.app/learnin/issue/LI-4265/implement-checkboxes-and-selects-for-request-resources-form */
                        }}
                        isFormDisabled={false}
                        isTextAreaDisabled={false}
                        programCost={0}
                        programCostFeesMultiplier={
                          APPROVAL_AUTOPOPULATE_MULTIPLIER
                        }
                        programCurrency={CurrencyCode.USD}
                        isResourceRequest={true}
                        isUserRequest={false}
                        isApplicationRejected={isApplicationRejected}
                        isCostField={
                          item.inputType === PreApprovalQuestionInputType.Money
                        }
                        isForPlanItemOrResourcePreapproval
                        setInitialCostEstimate={isApplicationRejected}
                        setCurrencySelected={setCurrencySelected}
                      />
                    </Grid.Box>
                  );
                })}
              </Form>
              <RejectionNotes rejectedNotes={rejectedNotes} />
            </Grid.Container>
            {resourceType === LearningResourceType.Subscription && (
              <TotalCost
                total={total}
                usersCurrency={currencySelected}
                exchangeRate={
                  currencySelected !== usersCurrency
                    ? exchangeRate
                    : MONEY_DEFAULT.exchangeRate
                }
              />
            )}
          </FormSection>
        </FormContainer>
      </DrawerFormContentContainer>
    </Container>
  );

  const wrapInDrawer = (form: JSX.Element) => {
    return (
      <LearnInDrawer
        closeButtonPlacement={'right'}
        bodyStyle={{background: COLORS.White}}
        title={i18n.t(
          rerequestResourceType
            ? k.REQUEST__RESUBMIT
            : k.REQUEST__RESOURCE__TITLE
        )}
        visible={visible}
        onClose={onClose}
        footer={renderFooter({
          loading: isSubmitButtonLoading,
          onClose,
          onOk: onClickSubmit,
        })}>
        {form}
      </LearnInDrawer>
    );
  };

  return visible && isNaN(rerequestResourceType)
    ? requestResource
    : wrapInDrawer(requestResource);
}

export default RequestResourceApprovalDrawer;
