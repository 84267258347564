import {ReactQueryRequest} from './../utils';
import {BASE_PATH} from '.';

export const getAcademyProjectSubmissionAttachmentUrlRq = (
  submissionId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-project-submission-url', submissionId].join(
    '/'
  );
  return {
    path,
    queryKey: [path],
  };
};

export const getAcademiesUsersRq = (academyId: number): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-academy-users/${academyId}`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getAcademyLevelsRq = (academyId: number): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-academy-levels`;
  const path = `${BASE_PATH}/get-academy-levels/${academyId}`;
  const queryKey = [baseUrl, `${academyId}`];

  return {
    path,
    queryKey,
  };
};

export const getAcademyLevelStepsRq = (
  academyLevelId: number
): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-academy-level-step`;
  const path = `${baseUrl}/${academyLevelId}`;
  const queryKey = [baseUrl, `${academyLevelId}`];

  return {
    path,
    queryKey,
  };
};

// TODO: Move to report directory
export const getAcademyOverviewRq = (academyId: number): ReactQueryRequest => {
  const baseUrl = 'report/get-academy-overview-report';
  const path = `${baseUrl}/${academyId}`;
  const queryKey = [baseUrl, academyId?.toString() || ''];
  return {
    path,
    queryKey,
  };
};

// TODO: Move to report directory
export const getAcademyUserDrillDownRq = (
  academyId: number,
  userId: number
): ReactQueryRequest => {
  const baseUrl = 'report/get-academy-overview-user-report';
  const path = `${baseUrl}/${academyId}/${userId}`;
  const queryKey = [
    baseUrl,
    academyId?.toString() || '',
    userId?.toString() || '',
  ];
  return {
    path,
    queryKey,
  };
};

export const getCustomAcademyContentAttachmentsRq = (
  academyLevelId?: number
): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-academy-step-content-attachments`;
  const path = `${baseUrl}/${academyLevelId}`;
  const queryKey = [baseUrl, academyLevelId?.toString() || ''];

  return {
    path,
    queryKey,
  };
};

export const getAcademyLevelDetailsQuery = (
  academyLevelId: number
): ReactQueryRequest => {
  const baseUrl = `${BASE_PATH}/get-academy-level-detail`;
  const path = `${baseUrl}/${academyLevelId}`;
  const queryKey = [baseUrl, academyLevelId?.toString()];

  return {
    path,
    queryKey,
  };
};

export const getAcademiesCountRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-academies-counts`;
  const queryKey = [path];

  return {
    path,
    queryKey,
  };
};

export const getCustomStepContentAttachmentUrlRq = (attachmentId: number) => {
  const baseUrl = `${BASE_PATH}/get-content-attachment-url/${attachmentId}`;
  const queryKey = [baseUrl, String(attachmentId)];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getCustomStepContentLinksRq = (academyStepId?: number) => {
  const baseUrl = `${BASE_PATH}/get-academy-step-content-links/${academyStepId}`;
  const queryKey = [baseUrl, academyStepId?.toString() || ''];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getAcademyPermissionVMsRq = (
  academyId: number
): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-user-permissions/${academyId}`;
  return {
    path,
    queryKey: [path, String(academyId)],
  };
};
