import {COLORS} from '@utils/constants';

/** The hexes are one-offs, as per the design team. 
   Extract these colors if we end up using them more than just here */
export const COLORS_REQUEST_MENU = {
  BlueBanner: '#DDEEFF',

  Blue: '#ECF6FF',
  BlueActive: '#DCEEFF',
  BlueHover: '#E0F1FF',
  BlueIconBackground: '#CCE6FF',

  Green: '#EAF8EC',
  GreenActive: '#DAF3DC',
  GreenHover: '#DDF4E0',
  GreenIcon: '#2EBD41',
  GreenIconBackground: '#C8ECCB',

  Pink: String(COLORS.Pink500),
  PinkActive: '#FFEBEE',
  PinkHover: '#FFEDF0',
  PinkIcon: String(COLORS.Pink100),
  PinkIconBackground: '#FFE1E6',

  Purple: String(COLORS.Purple350),
  PurpleActive: '#F0E5F9',
  PurpleHover: '#E8DEEE',
  PurpleIcon: '#7B18B8',
  PurpleIconBackground: '#E8D8F4',

  Yellow: String(COLORS.Yellow350),
  YellowActive: '#FFF6DA',
  YellowHover: '#FAF1D7',
  YellowIcon: '#FECB21',
  YellowIconBackground: '#FDECBA',
};

export const REQUEST_MENU_BUTTON_AND_DRAWER_BUTTON_TEST_ID =
  'request-menu-button-and-drawer-button';

export const REQUEST_MENU_BUTTON_AND_DRAWER_DRAWER_TEST_ID =
  'request-menu-button-and-drawer-drawer';
