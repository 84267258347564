import {ReactQueryRequest} from './../utils';
import {BASE_PATH} from '.';
import querystring from 'querystring';
import {addQueryParams} from './../utils';

export const getSettingsRq = (): ReactQueryRequest => {
  const baseUrl = `/${BASE_PATH}/get-settings`;
  const queryKey = [baseUrl];
  return {
    path: baseUrl,
    queryKey,
  };
};

export const getCompanyPersonalizationRq = (
  queryKeyId?: string
): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-personalization`;
  const queryKey = [path];
  if (!!queryKeyId) {
    queryKey.push(queryKeyId);
  }
  return {
    path,
    queryKey,
  };
};

export const getUserRq = (): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-user`;
  const queryKey = [path];
  return {
    path,
    queryKey,
  };
};

export const userSearchRq = ({
  name = '',
  limit = 10,
  companyId,
}: {
  name: string;
  limit?: number;
  companyId: number;
}): ReactQueryRequest => {
  const basePath = `companies/${companyId}/users`;
  const queryParams = [querystring.stringify({name, limit})];
  const queryKey = [basePath, String(name)];
  return {
    path: addQueryParams(basePath, queryParams),
    queryKey,
  };
};
