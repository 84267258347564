/** These strings aren't customer-facing: these don't need to be localized. */
export const VOUCHER_REQUEST_DECLINED = {
  approved: 'false',
  name: 'Link',
  providerName: 'Nintendo',
  providerImageUrl:
    'https://cdn11.bigcommerce.com/s-b72t4x/images/stencil/1280x1280/products/7727/14890/NNSN590-2__40459.1624820846.jpg',
  programUrl:
    'https://www.nintendo.com/games/detail/the-legend-of-zelda-breath-of-the-wild-switch/',
  programName: 'A Journey Through Hyrule',
  managerName: 'Zelda 👸',
  managerNote: 'You have failed me, and have condemned Hyrule.',
  adminName: 'Old Man 🧙',
  adminNote:
    'I told you how dangerous it was to go alone. You should have taken this: 🗡',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/406px-Nintendo_red_logo.svg.png',
  voucherUrl: 'https%3A%2F%2Fec.nintendo.com%2Fredeem%2F%23%2F',
};
