// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Permission/CustomProgramPermissionVM.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  customProgramId: z.number(),
  endDateUtc: z.string(),
  newSubmissionCount: z.number(),
  parentCustomProgramId: z.number(),
  programName: z.string(),
  startDateUtc: z.string(),
});

export const CustomProgramPermissionVMSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
