// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewLevelUserReport.cs

import {z} from 'zod';

export const AcademyOverviewLevelUserCompletionReportVmSchema = z.object({
  completionPercentage: z.number(),
  email: z.string().nullable().optional(),
  lastNudgeSent: z.string().nullable().optional(),
  name: z.string(),
  userCompanyId: z.number(),
});
