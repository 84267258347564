import {useQuery} from 'react-query';
import {notify} from '@components/user/notifications';
import {ResourceApprovalApplicationVM} from '@models/serverModels';
import {getResourceApprovalApplicationsRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {useEffect} from 'react';

export const useGetResourceApprovalFormQuery = (
  userPlanResourceId?: number
) => {
  const resourceApprovalApplicationsRq = getResourceApprovalApplicationsRq();
  const resourceApprovalApplicationsQuery = useQuery<
    ResourceApprovalApplicationVM[]
  >(
    resourceApprovalApplicationsRq.queryKey,
    () => simpleQueryFn(resourceApprovalApplicationsRq.path),
    {
      enabled: !userPlanResourceId,
      onError: () => notify.FetchResourceWithApprovalError(),
    }
  );

  return resourceApprovalApplicationsQuery;
};

export const useGetRequestApplicationQueries = (
  userPlanResourceId?: number
) => {
  const resourceApplicationQuery =
    useGetResourceApprovalFormQuery(userPlanResourceId);

  return {
    resourceApplicationQuery,
  };
};

export const useRequestFormEffects = ({
  rerequestResourceType,
  setRequestType,
  setTransitioned,
}) => {
  useEffect(() => {
    if (rerequestResourceType !== undefined)
      setRequestType(rerequestResourceType);
  }, [rerequestResourceType, setRequestType]);

  useEffect(() => {
    setTransitioned(true);
  }, [setTransitioned]);
};
