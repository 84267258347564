// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Requests/UpdateAcademyIntegrationChatChannelRequest.cs

import {z} from 'zod';
import {IntegrationChatChannelTypeSchema} from './IntegrationChatChannelTypeSchema.schema';
import {IntegrationChatProviderSchema} from './IntegrationChatProviderSchema.schema';

export const UpdateAcademyIntegrationChatChannelRequestSchema = z.object({
  channelExternalId: z.string().nullable().optional(),
  channelLink: z.string(),
  channelName: z.string().nullable().optional(),
  integrationChatChannelType: IntegrationChatChannelTypeSchema,
  integrationChatProvider: IntegrationChatProviderSchema,
});
