import {ReactMutateRequest} from './../utils';
import {
  AcademyLevelStepMovePayload,
  AcademyPayload,
  AcademyProgramStatusUpdate,
  AcademySingleUserPayload,
  AcademyStepContentPayload,
  AcademyStepContentUpdatePayload,
  UserPermissionUpdatePayload,
  UserPlanAcademyContentStatusUpdatePayload,
  UserPlanAcademyEventRSVPUpdatePayload,
  AcademyUserPermissionPayload,
  NewFeedbackPayload,
} from '@models/serverModels';
import {AcademyBrandVM, AcademyIdPayload} from '@generated/interfaces';
import {
  AcademyProgramStepPayload,
  AcademyLevelPayload,
  CreateAcademyLevelRequest,
} from '@generated/interfaces';

import {BASE_PATH} from '.';

export const getSubmitContentFeedbackRm = (
  payload: NewFeedbackPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/submit-content-feedback`;

  return {
    path,
    payload,
  };
};

export const getAddAcademyUserPermissionsRm = (
  payload: AcademyUserPermissionPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-user-permissions`;
  return {
    path,
    payload: payload,
  };
};

export const getUpdateUserPermissionRm = (
  payload: UserPermissionUpdatePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-user-permission`;
  return {
    path,
    payload: payload,
  };
};

export const getDeleteAcademyUserPermissionRm = ({
  academyId,
  userCompanyId,
}: {
  academyId: number;
  userCompanyId: number;
}): ReactMutateRequest => {
  const path = `${BASE_PATH}/delete-user-permission/${academyId}/${userCompanyId}`;
  return {
    path,
    payload: {},
  };
};

export const getUpdateAcademyMarketplaceProgramCompletionStatus = (
  payload: AcademyProgramStatusUpdate
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-marketplace-program-completion-status`;

  return {
    path,
    payload,
  };
};

export const createAcademyRm = (
  payload: AcademyPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-academy`;
  return {
    path,
    payload,
  };
};

export const updateAcademyEventRSVPRm = (
  payload: UserPlanAcademyEventRSVPUpdatePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-step-content-rsvp`;
  return {
    path,
    payload,
  };
};

export const updateAcademyRm = (
  payload: AcademyPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy/${payload.id}`;
  return {
    path,
    payload,
  };
};

// AcademyId wasn't added to  AcademyBrandVM after running scripts so appending id here
export const updateAcademyBrandingRm = (
  payload: AcademyBrandVM & AcademyIdPayload
): ReactMutateRequest => {
  const path = `/${BASE_PATH}/update-academy-branding`;
  return {
    path,
    payload,
  };
};

export const getDeleteAcademyCoverImageRm = (academyId: number) => {
  const baseUrl = `/${BASE_PATH}/delete-academy-cover-image/${academyId}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getUpdateAcademyContentStatusRm = (
  payload: UserPlanAcademyContentStatusUpdatePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-step-content-status`;
  return {
    path,
    payload,
  };
};

export const getDeleteAcademyStepRm = (
  academyStepId: number
): ReactMutateRequest => {
  const path = `${BASE_PATH}/delete-academy-step/${academyStepId}`;

  return {
    path,
    payload: {},
  };
};

export const getDeleteAcademyRm = (academyId: number): ReactMutateRequest => {
  const baseUrl = `${BASE_PATH}/delete-academy`;
  const path = `${baseUrl}/${academyId}`;
  return {
    path,
    payload: {},
  };
};

export const getDeleteSelfAcademyUserRm = (
  academyId: number
): ReactMutateRequest => {
  const baseUrl = `${BASE_PATH}/delete-self-academy-user`;
  const path = `${baseUrl}/${academyId}`;
  return {
    path,
    payload: {},
  };
};

export const getAddStepDateToCalendarRm = (
  academyStepId: number
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-date-to-my-calendar/${academyStepId}`;
  return {
    path,
    payload: {},
  };
};

export const getAddAcademiesUsersRm = ({
  academyId,
  userCompanyIds,
}: {
  academyId: number;
  userCompanyIds: number[];
}): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-academy-users`;

  return {
    path,
    payload: {academyId, userCompanyIds},
  };
};

export const getAddAcademyUserRm = (
  payload: AcademySingleUserPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-academy-user`;
  return {
    path,
    payload,
  };
};

export const getRemoveAcademiesUserRm = (
  academyId: number,
  userId: number
): ReactMutateRequest => {
  const path = `${BASE_PATH}/delete-academy-user/${academyId}/${userId}`;

  return {
    path,
    payload: {},
  };
};

export const getAddAcademyLevelRm = (
  payload: CreateAcademyLevelRequest
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-academy-level`;

  return {
    path,
    payload,
  };
};

export const getUpdateAcademyLevelOrderRm = (payload: {
  id: number;
  order: number;
}): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-level-order`;

  return {
    path,
    payload,
  };
};

export const getUpdateAcademyLevelStepOrderRm = (payload: {
  id: number;
  order: number;
}): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-step-order`;

  return {
    path,
    payload,
  };
};

export const getUpdateAcademyLevelRm = (
  payload: AcademyLevelPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-level`;

  return {
    path,
    payload,
  };
};

export const getAddAcademyProgramStepRm = (
  payload: AcademyProgramStepPayload
) => {
  if (Array.isArray(payload.newSteps)) {
    payload.newSteps = payload.newSteps.filter((step) => !!step);
  }
  const path = `/${BASE_PATH}/add-academy-program-steps`;
  return {
    path,
    payload,
  };
};

export const getAddAcademyContentStep = (
  payload: AcademyStepContentPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-academy-content-step`;
  return {
    path,
    payload,
  };
};

export const getDeleteAcademyStepContentAttachmentRm = (
  academyLevelId: number
): ReactMutateRequest => {
  const baseUrl = `${BASE_PATH}/delete-academy-step-content-attachment`;
  const path = `${baseUrl}/${academyLevelId}`;

  return {
    path,
    payload: {},
  };
};

export const getUploadAttachments = (academyStepId: number) => {
  const baseUrl = `/attachments`;

  return {
    path: baseUrl,
    payload: {id: academyStepId},
  };
};

export const getDeleteAttachment = (attachmentId: number) => {
  const baseUrl = `/attachments`;

  return {
    path: baseUrl,
    payload: {id: attachmentId},
  };
};

export const getUpdateCustomAcademyAttachmentRm = (academyStepId: number) => {
  const baseUrl = `/${BASE_PATH}/upload-content-attachment/${academyStepId}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getDeleteLevelRm = (
  academyLevelId: number
): ReactMutateRequest => {
  const baseUrl = `${BASE_PATH}/delete-academy-level`;
  const path = `${baseUrl}/${academyLevelId}`;

  return {
    path,
    payload: {},
  };
};

export const getUpdateAcademyStepsLevelRm = (
  payload: AcademyLevelStepMovePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-steps-level`;

  return {
    path,
    payload,
  };
};

export const getUpdateAcademyContentStepRm = (
  payload: AcademyStepContentUpdatePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-academy-content-step`;
  return {
    path,
    payload,
  };
};
