// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Reports/TransactionReportVM.cs

import {z} from 'zod';

export const TransactionReportVMSchema = z.object({
  amount: z.number(),
  cardId: z.number(),
  category: z.string(),
  description: z.string(),
  employeeName: z.string(),
  id: z.number(),
  lineOfBusiness: z.string(),
  status: z.string(),
  timestamp: z.string(),
  uniqueId: z.string(),
  userCompanyId: z.number(),
  userFinanceIncentiveId: z.number(),
});
