import {useBuildRouteElements} from '@hooks/useBuildRouteElements';
import useGetEnabledPrimaryRoutesShared from '@hooks/useGetEnabledPrimaryRoutesShared';
import {Routes} from 'react-router-dom';

/** Routes common to both integrated and non-integrated (standard) users */
const PrimaryRoutesShared = ({
  isManager,
  isAdmin,
}: {
  isManager: boolean;
  isAdmin: boolean;
}) => {
  const sharedPrimaryRoutes = useGetEnabledPrimaryRoutesShared({
    isManager,
    isAdmin,
  });
  const sharedPrimaryRouteElements = useBuildRouteElements(sharedPrimaryRoutes);

  return <Routes>{sharedPrimaryRouteElements}</Routes>;
};

export default PrimaryRoutesShared;
