import {COLORS} from '@utils/constants';

export const FormItemStyles = {
  addFundsModal: {
    widthLabel: '100px',
    displayLabel: 'flex',
    justifyContentLabel: 'flex-start',
  },
  standard: {
    errorMessageColor: COLORS.Red800,
  },
};
