import {
  getCustomProgramsManagedPermissionRq,
  getAcademiesManagedPermissionRq,
} from '@store/apiEndpoints';
import {
  AcademyPermissionVM,
  CustomProgramPermissionVM,
} from '@models/serverModels';
import {queryHookFactory} from './../utils';

export const useCustomProgramPermissionVMsQuery = queryHookFactory<
  null,
  CustomProgramPermissionVM[]
>(getCustomProgramsManagedPermissionRq);

export const useAcademyPermissionVMsQuery = queryHookFactory<
  null,
  AcademyPermissionVM[]
>(getAcademiesManagedPermissionRq);
