/* Imports */
import keys, {TKeyMapKey} from '@i18n/keys';
import {i18nextInit} from '@i18n/init';

/* Configure */
const i18next = i18nextInit();

/* Exports */
export {i18next as i18n, keys as k};

export default (key: TKeyMapKey, vars?: Record<string, string>) =>
  i18next.t(key, vars);
