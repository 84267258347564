export enum PresenceType {
  Online,
  Classroom,
}

export enum TimeCommitmentType {
  PartTime,
  FullTime,
}

export const mapTimeCommitmentTypeString = (s: string) => {
  if (s === 'Full-time') return TimeCommitmentType.FullTime;
  if (s === 'Part-time') return TimeCommitmentType.PartTime;
};

export enum DurationUnitType {
  Days,
  Weeks,
  Months,
  Years,
  Hours,
}

export interface AddNewProgram {
  providerId: number;
  providerName: string;
  programTypeId: number;
  title: string;
  shortDescription: string;
  cost: number;
  url: string;
  presenceType: number;
  timeCommitmentType: number;
  programLength: number;
  programLengthUnit: number;
}
