// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateAcademyStepRequirementPayload.cs

import {z} from 'zod';
import {AcademyStepRequirementPropertySchema} from './AcademyStepRequirementPropertySchema.schema';

export const UpdateAcademyStepRequirementPayloadSchema = z.object({
  blockedId: z.number(),
  requiredId: z.number().nullable().optional(),
  requirementProperty: AcademyStepRequirementPropertySchema,
});
