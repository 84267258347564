// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/ReimbursementVM.cs

import {z} from 'zod';
import {ReimbursementStatusSchema} from './ReimbursementStatusSchema.schema';

export const ReimbursementVMSchema = z.object({
  id: z.number(),
  requestedOn: z.string(),
  status: ReimbursementStatusSchema,
  title: z.string(),
  totalRequestedAmount: z.number(),
});
