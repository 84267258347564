// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/PreApprovalQuestion.cs

import {z} from 'zod';
import {PreApprovalQuestionFunctionalityTypeSchema} from './PreApprovalQuestionFunctionalityTypeSchema.schema';
import {PreApprovalQuestionInputTypeSchema} from './PreApprovalQuestionInputTypeSchema.schema';
import {PreApprovalQuestionResponseSourceSchema} from './PreApprovalQuestionResponseSourceSchema.schema';

export const PreApprovalQuestionSchema = z.object({
  functionalityType: PreApprovalQuestionFunctionalityTypeSchema,
  id: z.number(),
  inputType: PreApprovalQuestionInputTypeSchema,
  isConditionalOnAnswerId: z.number().nullable().optional(),
  localizationId: z.string(),
  required: z.boolean(),
  response: z.string(),
  responseOptions: z.array(z.string()),
  responseType: PreApprovalQuestionResponseSourceSchema,
  value: z.string(),
});
