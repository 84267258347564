// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeDetailVm.cs

import {z} from 'zod';
import {TeamInsightEmployeeOverviewVMSchema} from './TeamInsightEmployeeOverviewVMSchema.schema';
import {UserAcademyInsightSchema} from './UserAcademyInsightSchema.schema';
import {UserProgramInsightSchema} from './UserProgramInsightSchema.schema';
import {UserResourceInsightSchema} from './UserResourceInsightSchema.schema';
import {UserTransactionInsightSchema} from './UserTransactionInsightSchema.schema';

const schema = z.object({
  academies: z.array(UserAcademyInsightSchema),
  programs: z.array(UserProgramInsightSchema),
  resources: z.array(UserResourceInsightSchema),
  transactions: z.array(UserTransactionInsightSchema),
});

export const TeamInsightEmployeeDetailVmSchema =
  TeamInsightEmployeeOverviewVMSchema.merge(schema);
