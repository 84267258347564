import {Areas} from '@utils/ClientPaths';

const trimLeadingAndTrailingSlashes = (path: string) => {
  let trimmedPath = path;

  const leadingNeedsTrimmed = trimmedPath.substring(0, 1) === '/';
  if (leadingNeedsTrimmed) {
    trimmedPath = trimmedPath.substring(1);
  }

  const trailingNeedsTrimmed = trimmedPath.substring(
    trimmedPath.length - 1,
    trimmedPath.length
  );
  if (trailingNeedsTrimmed) {
    trimmedPath = trimmedPath.substring(0, trimmedPath.length - 1);
  }

  return trimmedPath;
};

/** Handles the integrated area prefix to the URL */
export const useHandleUrlPrefix = (isIntegratedUser: boolean) => () => {
  const hasIntegratedPrefix =
    location.href.indexOf(`/${Areas.Integrated}`) > -1;
  const addIntegratedPrefix = isIntegratedUser && !hasIntegratedPrefix;
  const cleanUpIntegratedPrefix = !isIntegratedUser && hasIntegratedPrefix;

  if (addIntegratedPrefix && location.pathname !== '/') {
    history.replaceState(
      {},
      '',
      '/' +
        (location.pathname
          ? location.pathname?.replace(new RegExp(/[\/]/g), '') + '/'
          : '') +
        (location.search ? location.search + '/' : '') +
        Areas.Integrated
    );
  } else if (cleanUpIntegratedPrefix) {
    // Remove integrate prefix from URL, but keep the rest of the URL
    const replaceRegExp = new RegExp(Areas.Integrated, 'g');
    location.assign(location.href.replace(replaceRegExp, ''));
  }
};
