import * as React from 'react';
import styled from 'styled-components';
import placeholderProviderLogo from '@assets/placeholder_provider_logo.svg';

export const Container = styled.div<{size: number}>`
  height: ${({size}) => size}px;
  min-width: ${({size}) => size}px;
  max-width: ${({size}) => size}px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  background: white;
`;

export const PreviewLogoImg = styled.img<{size: number}>`
  width: ${({size}) => size - 2}px !important;
`;

interface Props {
  imageSrc: string;
  size: number;
  style?: React.CSSProperties;
}

function UploadPreviewLogo({imageSrc, size, style}: Props) {
  return (
    <Container size={size} style={style}>
      <PreviewLogoImg
        alt=""
        size={size}
        src={imageSrc}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = placeholderProviderLogo;
        }}
      />
    </Container>
  );
}

export default UploadPreviewLogo;
