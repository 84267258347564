import {i18n, k} from '@i18n/translate';
import {getPersonalLearningBudgetLimitRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import moment from 'moment';
import {FinanceAmountType, FinanceType} from '@generated/enums';
import {
  ApplicationQuestionVM,
  PreApprovalAmountBalanceVM,
  PreApprovalQuestion,
} from '@models/serverModels';
import {WarningOutlined} from '@ant-design/icons';
import {COLORS} from './constants';
import {
  MONEY_DEFAULT,
  REQUEST_CURRENCY_ID,
  exchangeAmount,
  exchangeAmountBack,
  formatCurrency,
  getExchangeRateOfCurrencyPair,
  roundToCents,
} from '@utils/moneyUtils';
import {WhenLoaded} from '@utils/typeUtils';
import {
  CurrencyCode,
  PreApprovalQuestionFunctionalityType,
  PreApprovalQuestionInputType,
  PreApprovalQuestionResponseSource,
} from '@generated/enums';
import {formatResponseValue} from '@components/reusable/LearnInForm';
import {UserPlanFinanceLearningBudgetOverviewVM} from '@generated/interfaces';
import {
  PlanItem,
  ResourceOrPlanItemPreApprovalQuestion,
} from '@models/clientModels';
import {FormInstance} from 'antd';
import {UseCurrencyRateQueryReturnType} from '@hooks/apiEndpoints/localization/queries';
import {handleInputErrorText, noUserInputAllowedHtml} from './itemUtils';
import {localizeText} from './l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

/*
|--------------------------------------------------------------------------
| Max Amount
|--------------------------------------------------------------------------
*/
interface UseGetMaxRequestAmount {
  itemId: number;
  planItemId: number;
  planItemType: WhenLoaded<PlanItem>;
  exchangeRate: number;
  enabled: boolean;
}

export const useGetMaxRequestAmount = ({
  exchangeRate,
  enabled,
}: UseGetMaxRequestAmount): number => {
  // Get information about remaining plb funds amount and type
  const preApprovalAmountBalanceRq = getPersonalLearningBudgetLimitRq();
  const {data: preApprovalAmountBalance} =
    useQuery<PreApprovalAmountBalanceVM | null>(
      preApprovalAmountBalanceRq.queryKey,
      () => simpleQueryFn(preApprovalAmountBalanceRq.path),
      {enabled}
    );

  const amountBalanceExchanged = exchangeAmount(
    preApprovalAmountBalance?.amountBalance,
    exchangeRate
  );
  return amountBalanceExchanged || 0;
};

/*
|--------------------------------------------------------------------------
| Subscriptions
|--------------------------------------------------------------------------
*/

export const isRecurringType = (type: PreApprovalQuestionFunctionalityType) =>
  type === PreApprovalQuestionFunctionalityType.ApprovalRequestFrequency;

export const getFrequencyDropdown = (application) =>
  application.find((question) => isQuestionASubscription(question));

export const applySubscriptionSelection = (
  application: PreApprovalQuestion[],
  frequencyDropdown: Partial<{id: string}>,
  data: unknown
) => {
  const monthFrequencyIndex = application.findIndex(
    (item) => item.id === frequencyDropdown?.id
  );
  if (monthFrequencyIndex > -1 && !data[frequencyDropdown.id])
    data[frequencyDropdown.id] = '1';
};

export const isQuestionASubscription = (
  question: Partial<{
    inputType: PreApprovalQuestionInputType;
    functionalityType: PreApprovalQuestionFunctionalityType;
  }>
) =>
  question.inputType === PreApprovalQuestionInputType.Dropdown &&
  question.functionalityType ===
    PreApprovalQuestionFunctionalityType.ApprovalRequestFrequency;

/*
|--------------------------------------------------------------------------
| Currency
|--------------------------------------------------------------------------
*/

export const isQuestionACurrencyField = (
  question: Partial<{
    functionalityType: PreApprovalQuestionFunctionalityType;
  }>
) =>
  question.functionalityType ===
  PreApprovalQuestionFunctionalityType.CurrencySelection;

export const applyCurrencySelection = (
  application: Partial<{
    functionalityType: PreApprovalQuestionFunctionalityType;
    response: string;
  }>[],
  selection: string,
  defaultCurrency: CurrencyCode
) => {
  const item = application.find((item) => isQuestionACurrencyField(item));
  if (item) {
    item.response = String(selection ?? defaultCurrency);
  }
};

export const skipResponseFormatting = (
  item: ApplicationQuestionVM | PreApprovalQuestion
) => {
  const isCurrencySelection =
    item.functionalityType ===
    PreApprovalQuestionFunctionalityType.CurrencySelection;
  return isCurrencySelection;
};

export const getCostField = (application: PreApprovalQuestion[]) =>
  application?.find(
    ({responseType}) =>
      responseType === PreApprovalQuestionResponseSource.UserPlanProgramAmount
  );

/*
|--------------------------------------------------------------------------
| Dates
|--------------------------------------------------------------------------
*/

export const handleDateInputProps = ({
  budgetInfoQuery,
  item,
}: {
  budgetInfoQuery?: UserPlanFinanceLearningBudgetOverviewVM;
  item: ResourceOrPlanItemPreApprovalQuestion;
}) => {
  const isReimbursementPlan =
    budgetInfoQuery?.financeType === FinanceType.TuitionReimbursement;
  const isRangeDate =
    item?.functionalityType ===
      PreApprovalQuestionFunctionalityType.StartDate ||
    item?.functionalityType === PreApprovalQuestionFunctionalityType.EndDate;
  const isStartDate =
    item?.functionalityType === PreApprovalQuestionFunctionalityType.StartDate;
  const isEndDate =
    item?.functionalityType === PreApprovalQuestionFunctionalityType.EndDate;
  const isReimbursementDate = isReimbursementPlan && !isRangeDate;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const responseDate = item.response ? moment(item.response) : '';
  return {
    isRangeDate,
    isStartDate,
    isEndDate,
    isReimbursementDate,
    tomorrow,
    responseDate,
  };
};

/*
|--------------------------------------------------------------------------
| Cost Validation
|--------------------------------------------------------------------------
*/

interface UseGetCostFieldValidationArgs {
  required: boolean;
  helperText?: string;
  localizationId?: string;
  isHelperTextOn: boolean;
  isForPlanItemOrResourcePreapproval?: boolean;
  exchangeRate: number;
  usersCurrency: CurrencyCode;
  enabled: boolean;
  total?: number;
  isRequestWithCurrencyOn?: boolean;
  form?: FormInstance<any>;
  getCurrencyExchangeQuery?: UseCurrencyRateQueryReturnType;
}

export const getSelectedCurrency = (form: FormInstance) =>
  form?.getFieldsValue()?.[REQUEST_CURRENCY_ID];

export const getExchangeRateFromSelection = ({
  form,
  isRequestWithCurrencyOn,
  getCurrencyExchangeQuery,
  usersCurrency,
}: {
  form: FormInstance;
  isRequestWithCurrencyOn: boolean;
  getCurrencyExchangeQuery: UseCurrencyRateQueryReturnType;
  usersCurrency: CurrencyCode;
}) => {
  let effectiveExchange = MONEY_DEFAULT.exchangeRate;
  if (isRequestWithCurrencyOn) {
    effectiveExchange = getExchangeRateOfCurrencyPair(
      getCurrencyExchangeQuery?.data,
      usersCurrency,
      getSelectedCurrency(form)
    );
  }
  return effectiveExchange;
};

export const useGetCostFieldValidation = ({
  required,
  helperText,
  localizationId,
  isHelperTextOn,
  isForPlanItemOrResourcePreapproval,
  exchangeRate,
  usersCurrency,
  total,
  enabled,
  form,
  isRequestWithCurrencyOn,
  getCurrencyExchangeQuery,
}: UseGetCostFieldValidationArgs) => {
  // Get information about remaining plb funds amount and type
  const preApprovalAmountBalanceRq = getPersonalLearningBudgetLimitRq();

  const baseValidation = {
    message: noUserInputAllowedHtml(
      handleInputErrorText(
        i18n.t(k.VALIDATION__GREATER_THAN_ZERO),
        localizeText({
          translationKey: localizationId
            ? localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: helperText,
        }),
        isHelperTextOn
      )
    ),
    min: 1,
    required: true,
    type: 'number',
  };
  const {
    data: preApprovalAmountBalance,
    status: preApprovalAmountBalanceLoadingStatus,
  } = useQuery<PreApprovalAmountBalanceVM | null>(
    preApprovalAmountBalanceRq.queryKey,
    () => simpleQueryFn(preApprovalAmountBalanceRq.path),
    {
      enabled,
    }
  );

  const plbIsExternalReimbursementType =
    preApprovalAmountBalance?.financeType === FinanceType.ExternalReimbursement;

  const plbHasNoLimit =
    preApprovalAmountBalance?.financeAmountType === FinanceAmountType.Any;

  const amountBalanceExchanged = exchangeAmount(
    preApprovalAmountBalance?.amountBalance,
    exchangeRate
  );
  const maxRequestAmount = amountBalanceExchanged || 0;
  const plbIsOutOfFunds = maxRequestAmount === 0;

  if (!enabled) return [];

  if (isForPlanItemOrResourcePreapproval) {
    // If there is no limit on the plb, user can unlimited amount of money
    if (plbHasNoLimit) {
      return [
        {
          ...baseValidation,
          required,
        },
      ];
    } else if (plbIsExternalReimbursementType) {
      // if external plb, only warn the user if they're requesting more than remaining plb
      return [
        {
          ...baseValidation,
          required,
        },
        {
          warningOnly: true,
          message: noUserInputAllowedHtml(
            handleInputErrorText(
              <RequestExceedsLimitWarning amount={maxRequestAmount} />,
              localizeText({
                translationKey: localizationId
                  ? localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
                  : null,
                translationValue: helperText,
              }),
              isHelperTextOn
            )
          ),
          validator: (_, value) => {
            if (value > maxRequestAmount || total > maxRequestAmount) {
              return Promise.reject();
            } else {
              return Promise.resolve();
            }
          },
        },
      ];
    } else if (
      preApprovalAmountBalanceLoadingStatus === 'success' &&
      plbIsOutOfFunds
    ) {
      // if the balance has loaded and there are no funds remaining, user is effectively blocked from submitting request
      return [
        {
          ...baseValidation,
          min: 0,
          max: maxRequestAmount,
          message: noUserInputAllowedHtml(
            handleInputErrorText(
              i18n.t(k.PLB__OUT_OF_FUNDS),
              localizeText({
                translationKey: localizationId
                  ? localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
                  : null,
                translationValue: helperText,
              }),
              isHelperTextOn
            )
          ),
        },
      ];
    } else if (!!maxRequestAmount) {
      // This is the "normal" case of a plb with a limit and funds remaining
      // added required base validation to prevent request with cost 0
      return [
        {
          ...baseValidation,
          required,
        },
        {
          validator: (_, value) => {
            const effectiveExchange = getExchangeRateFromSelection({
              form,
              isRequestWithCurrencyOn,
              getCurrencyExchangeQuery,
              usersCurrency,
            });

            /* roundToCents so if the math causes trailing numbers after the 
               cents place, it doesn't fail (e.g. 1.33333 !== 1.33) */
            if (
              (value &&
                roundToCents(value * effectiveExchange) > maxRequestAmount) ||
              (total &&
                roundToCents(total * effectiveExchange) > maxRequestAmount)
            ) {
              return Promise.reject();
            } else {
              return Promise.resolve();
            }
          },
          message: () => {
            let currency = usersCurrency;
            let effectiveExchange = MONEY_DEFAULT.exchangeRate;
            let amount = maxRequestAmount;

            if (isRequestWithCurrencyOn) {
              effectiveExchange = getExchangeRateFromSelection({
                form,
                isRequestWithCurrencyOn,
                getCurrencyExchangeQuery,
                usersCurrency,
              });
              currency = getSelectedCurrency(form);
              amount = exchangeAmountBack(maxRequestAmount, effectiveExchange);
            }

            return noUserInputAllowedHtml(
              handleInputErrorText(
                i18n.t(k.PLB__EXCEEDED_FUNDS__FORMAT, {
                  amount: formatCurrency(amount, currency, effectiveExchange),
                }),
                localizeText({
                  translationKey: localizationId
                    ? localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
                    : null,
                  translationValue: helperText,
                }),
                isHelperTextOn
              )
            );
          },
        },
      ];
    }
  } else {
    // When a user is requesting a program
    return [
      {
        ...baseValidation,
        required,
      },
    ];
  }
};

function RequestExceedsLimitWarning({amount}: {amount: number}) {
  return (
    <div style={{color: COLORS.Neutral600, fontWeight: 400}}>
      <WarningOutlined
        style={{color: COLORS.Yellow500, marginRight: '6px'}}
        aria-hidden="true"
      />
      {i18n.t(k.FUND__BUDGET__EXCEEDED__FORMAT, {
        amount,
      })}
    </div>
  );
}

/*
|--------------------------------------------------------------------------
| Response Handling
|--------------------------------------------------------------------------
*/

export const conditionallyFormatResponse = ({
  item,
  values,
  exchangeRate = 1,
}: {
  item: PreApprovalQuestion;
  values: Partial<string>[];
  exchangeRate?: number;
}) =>
  String(
    skipResponseFormatting(item)
      ? item?.response
      : formatResponseValue({
          type: item?.inputType,
          response: values?.[item?.id],
          total: Number(values?.[item?.id]),
          exchangeRate,
        })
  );

export const mapResponsesToApplication = ({
  application,
  data,
  requestExchangeRate,
  total,
}: {
  application: PreApprovalQuestion[];
  data: unknown;
  requestExchangeRate: number;
  total: number;
}) => {
  return application.map((item) => {
    return skipResponseFormatting(item)
      ? item
      : {
          ...item,
          response: String(
            formatResponseValue({
              type: item.inputType,
              response: data[item.id],
              total: total || Number(data[item.id]),
              exchangeRate: requestExchangeRate,
            })
          ),
        };
  });
};
