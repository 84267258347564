// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewProgram.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {PresenceTypeSchema} from './PresenceTypeSchema.schema';
import {DurationUnitTypeSchema} from './DurationUnitTypeSchema.schema';
import {TimeCommitmentTypeSchema} from './TimeCommitmentTypeSchema.schema';

export const NewProgramSchema = z.object({
  cost: z.number(),
  currency: CurrencyCodeSchema,
  presenceType: PresenceTypeSchema,
  programLength: z.number(),
  programLengthUnit: DurationUnitTypeSchema,
  programTypeId: z.number().nullable().optional(),
  providerId: z.number(),
  providerName: z.string(),
  shortDescription: z.string(),
  timeCommitmentType: TimeCommitmentTypeSchema,
  title: z.string(),
  url: z.string(),
});
