import {useMutation, UseMutationResult} from 'react-query';
import {getRemoveAttachmentRm} from '@store/apiEndpoints';
import {simpleDeleteFn} from '@store/queryClient';
import {notify} from '../../../components/user/notifications';

export function useDeleteAttachment(): UseMutationResult<unknown, unknown> {
  return useMutation(
    ({attachmentId}: {attachmentId: number}) => {
      const deleteAttachmentRm = getRemoveAttachmentRm(attachmentId);
      // no return type
      return simpleDeleteFn<undefined>(
        deleteAttachmentRm.path,
        deleteAttachmentRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.deleteCohortSuccess();
      },
      onError: () => {
        notify.AddProgramWithApprovalError();
      },
    }
  );
}
