// Do not modify directly
// Generated from file: learnin.core.application/DTOs/ProjectSubmissionVM.cs

import {z} from 'zod';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';

export const ProjectSubmissionVMSchema = z.object({
  contentId: z.number(),
  id: z.number(),
  isLate: z.boolean(),
  links: z.array(z.string()),
  participantName: z.string(),
  response: z.string().nullable().optional(),
  submittedOn: z.string().nullable().optional(),
  uploadGuids: z.array(z.string()),
  uploads: z.array(AttachmentVMSchema),
});
