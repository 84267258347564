// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyProgramStatusUpdate.cs

import {z} from 'zod';
import {UserProgramStatusSchema} from './UserProgramStatusSchema.schema';

export const AcademyProgramStatusUpdateSchema = z.object({
  contentId: z.number(),
  status: UserProgramStatusSchema,
});
