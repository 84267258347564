// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Insights/TeamInsightEmployeeOverviewVM.cs

import {z} from 'zod';
import {TeamInsightEmployeeOverviewVMSchema} from './TeamInsightEmployeeOverviewVMSchema.schema';

export const TeamInsightFullOverviewVMSchema = z.object({
  employeeOverviewVms: z.array(TeamInsightEmployeeOverviewVMSchema),
  hasPlb: z.boolean(),
});
