// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/ApprovalRequestVM.cs

import {z} from 'zod';
import {PreApprovalApplicationVMSchema} from './PreApprovalApplicationVMSchema.schema';
import {ApprovalRankTypeSchema} from './ApprovalRankTypeSchema.schema';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';
import {ApprovalTypeSchema} from './ApprovalTypeSchema.schema';

export const ApprovalRequestVMSchema = z.object({
  amount: z.number(),
  amountOverLimit: z.number().nullable().optional(),
  amountType: z.string(),
  applicationDetails: PreApprovalApplicationVMSchema,
  approvalGuidelines: z.string(),
  approverNotes: z.string(),
  costCenter: z.string(),
  employeeComments: z.string(),
  employeeReasonForApplication: z.string(),
  id: z.number(),
  itemId: z.number(),
  linkedUserRequestApprovalId: z.number().nullable().optional(),
  manager: z.string(),
  managerId: z.number().nullable().optional(),
  previousRejectionNotes: z.string().nullable().optional(),
  provider: z.string(),
  rank: ApprovalRankTypeSchema,
  rejectedById: z.string().nullable().optional(),
  requestedBy: z.string(),
  requestedDate: z.string(),
  status: ApprovalStatusSchema,
  title: z.string(),
  type: ApprovalTypeSchema,
  uniqueId: z.string(),
});
