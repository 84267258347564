// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramReviewVM.cs

import {z} from 'zod';

export const ProgramReviewVMSchema = z.object({
  editable: z.boolean(),
  hasCompletedProgram: z.boolean(),
  id: z.number(),
  programCompletedOn: z.string(),
  rating: z.number(),
  reviewContent: z.string(),
  reviewCreatedOn: z.string(),
  reviewer: z.string(),
  title: z.string(),
});
