// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateCustomProgramStatus.cs

import {z} from 'zod';
import {CustomProgramStatusSchema} from './CustomProgramStatusSchema.schema';

export const UpdateCustomProgramStatusSchema = z.object({
  id: z.number(),
  status: CustomProgramStatusSchema,
});
