import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {REQUEST_MENU_HIDDEN_CLASS} from '../RequestMenu/RequestMenu.constants';

export const RequestMenuButtonAndDrawerButton = styled(LearnInButton)`
  gap: 0.5rem;
`;

export const RequestMenuButtonAndDrawerViewContainer = styled.div`
  border-top: 1px solid ${COLORS.Neutral200};
  margin-top: 3rem;
  padding: 1.5rem;
`;

export const RequestMenuButtonAndDrawerMarketplaceParagraph = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  padding: 0;
  &.${REQUEST_MENU_HIDDEN_CLASS} {
    display: none;
  }
`;

export const RequestMenuButtonAndDrawerStyles = {
  padding: 0,
  backgroundColor: COLORS.White,
};
