// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/PreApprovalApplicationVM.cs

import {z} from 'zod';
import {PreApprovalQuestionVMSchema} from './PreApprovalQuestionVMSchema.schema';
import {ApprovalStatusSchema} from './ApprovalStatusSchema.schema';

export const PreApprovalApplicationVMSchema = z.object({
  application: z.array(PreApprovalQuestionVMSchema),
  attachmentIds: z.array(z.string()),
  id: z.number(),
  notes: z.string().nullable().optional(),
  rejectedNotes: z.string().nullable().optional(),
  status: ApprovalStatusSchema,
});
