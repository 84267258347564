import styled from 'styled-components';
import {BodySpan} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';
import {MOUNTAIN_PATH} from '@assets/cloudinary-urls';
import {COLORS_REQUEST_MENU} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.constants';
import {Breakpoints} from '@utils/responsiveUtils';

export const RequestBannerWrapper = styled.aside`
  align-items: center;
  background: ${COLORS_REQUEST_MENU.BlueBanner} url(${MOUNTAIN_PATH}) no-repeat;
  background-position: -52px 12px;
  background-size: 244px 181px;
  display: flex;
  min-height: 124px;
  @media (max-width: ${Breakpoints.Mobile}px) {
    display: flex;
    flex-flow: column;
    min-height: 150px;
    padding-left: 10.25rem;
    padding-right: 1.125rem;
    background-position: -75px 32px;
    background-size: 296px 230px;
  }
`;

export const RequestBannerHeader = styled.div`
  margin-left: 184px;
  width: 282px;

  @media (max-width: ${Breakpoints.Mobile}px) {
    margin: 1.25rem 1.125rem;
    width: 100%;
  }
`;

export const RequestBannerTitle = styled.h3`
  color: ${COLORS.Blue950};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const RequestBannerSubtitle = styled(BodySpan)`
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const RequestBannerButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 1.5rem;
  @media (max-width: ${Breakpoints.Mobile}px) {
    align-self: flex-start;
    margin-right: 0;
    padding-bottom: 1.5rem;
  }
`;
