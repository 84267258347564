// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewStepUserReport.cs

import {z} from 'zod';
import {AcademyStepTypeSchema} from './AcademyStepTypeSchema.schema';
import {AcademyOverviewStepUserCompletionReportVmSchema} from './AcademyOverviewStepUserCompletionReportVmSchema.schema';

export const AcademyOverviewStepUserReportVmSchema = z.object({
  academyStepId: z.number(),
  academyStepName: z.string(),
  academyStepType: AcademyStepTypeSchema,
  completionPercentage: z.number(),
  order: z.number(),
  totalCompleted: z.number(),
  totalOutstanding: z.number(),
  totalRsvpNo: z.number(),
  totalRsvpYes: z.number(),
  users: z.array(AcademyOverviewStepUserCompletionReportVmSchema),
});
