// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyVM.cs

import {z} from 'zod';
import {IAcademyBasicInfoSchema} from './IAcademyBasicInfoSchema.schema';
import {AcademySkillVMSchema} from './AcademySkillVMSchema.schema';
import {AcademyBrandVMSchema} from './AcademyBrandVMSchema.schema';
import {AcademyStatusSchema} from './AcademyStatusSchema.schema';

const schema = z.object({
  academyProfileImageUrl: z.string().nullable().optional(),
  academySkillVMs: z.array(AcademySkillVMSchema),
  branding: AcademyBrandVMSchema,
  degreedId: z.string().nullable().optional(),
  description: z.string(),
  discussionId: z.string().nullable().optional(),
  groupId: z.string().nullable().optional(),
  id: z.number(),
  invitationEmail: z.boolean(),
  joinId: z.string(),
  name: z.string(),
  restrictedAccess: z.boolean(),
  status: AcademyStatusSchema,
});

export const AcademyVMSchema = IAcademyBasicInfoSchema.merge(schema);
