// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Licenses/Requests/UpdateLicenseRequest.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LicenseRedemptionTypeSchema} from './LicenseRedemptionTypeSchema.schema';
import {LicenseStatusSchema} from './LicenseStatusSchema.schema';
import {LicenseUsageTypeSchema} from './LicenseUsageTypeSchema.schema';
import {VoucherAssignModeSchema} from './VoucherAssignModeSchema.schema';

export const UpdateLicenseRequestSchema = z.object({
  approvalGuideline: z.string().nullable().optional(),
  currency: CurrencyCodeSchema,
  internalCost: z.number(),
  licenseDuration: z.number(),
  redemptionGuideline: z.string(),
  redemptionType: LicenseRedemptionTypeSchema,
  requireAdminApproval: z.boolean(),
  requireManagerApproval: z.boolean(),
  status: LicenseStatusSchema,
  type: LicenseUsageTypeSchema,
  voucherAssignMode: VoucherAssignModeSchema,
});
