export enum COLORS {
  // Apollo colors
  Blue50 = '#f3fbff',
  Blue100 = '#EDF9FF',
  Blue200 = '#D6F0FF',
  Blue300 = '#b5e7ff',
  Blue400 = '#83D9FF',
  Blue500 = '#48c2ff',
  Blue600 = '#1ea1ff',
  Blue700 = '#0682FF',
  Blue800 = '#0062e3',
  Blue900 = '#0854c5',
  Blue950 = '#0d4a9b',
  Green50 = '#F9FFFB',
  Green100 = '#F0FDF4',
  Green200 = '#ddfbe8',
  Green300 = '#bdf5d3',
  Green400 = '#8aebb0',
  Green500 = '#4fd985',
  Green600 = '#28bf65',
  Green700 = '#1b9e4f',
  Green800 = '#1a8244',
  Green900 = '#196237',
  Green950 = '#16512f',
  Neutral50 = '#fbfbfb',
  Neutral100 = '#f4f6f7',
  Neutral200 = '#e2e8eb',
  Neutral300 = '#c9d3d8',
  Neutral400 = '#a3b5bd',
  Neutral500 = '#768e9a',
  Neutral600 = '#5b737f',
  Neutral700 = '#4e606c',
  Neutral800 = '#44515a',
  Neutral900 = '#3d464d',
  Neutral950 = '#353c42',
  Yellow50 = '#fffef0',
  Yellow100 = '#fffde7',
  Yellow200 = '#fffbc1',
  Yellow300 = '#fff286',
  Yellow400 = '#ffe341',
  Yellow500 = '#ffcf0d',
  Yellow600 = '#f0b400',
  Yellow700 = '#d18b00',
  Yellow800 = '#a66202',
  Yellow900 = '#894c0a',
  Yellow950 = '#743e0f',
  Red50 = '#fff8f9',
  Red100 = '#fff0f2',
  Red200 = '#ffe2e6',
  Red300 = '#ffc9d3',
  Red400 = '#ff9daf',
  Red500 = '#ff6685',
  Red600 = '#ff3160',
  Red700 = '#f00e4c',
  Red800 = '#de0546',
  Red900 = '#aa073d',
  Red950 = '#910a3a',
  Purple50 = '#f9f5ff',
  Purple100 = '#f1e6ff',
  Purple150 = '#7B18B8',
  Purple200 = '#e7d4ff',
  Purple300 = '#d3b1ff',
  Purple350 = '#F7F1FB',
  Purple400 = '#bf8dff',
  Purple500 = '#ab69ff',
  Purple600 = '#9746ff',
  Purple700 = '#8322ff',
  Purple800 = '#6800ee',
  Purple900 = '#5200bb',
  Purple950 = '#3c0088',
  // Misc
  Black = '#000000',
  BoxShadow1 = '#0000001a',
  Transparent = 'transparent',
  White = '#ffffff',
  BoxShadowStandard = '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  // Colors to be migrated to Apollo
  Orange100 = '#F3742C',
  Green25 = '#01860E',
  Pink100 = '#FF5F79',
  Pink400 = '#FFD7DD',
  Pink50 = '#D95167',
  Pink500 = '#FFF4F6',
  Yellow350 = '#FFFAEA',
  //LXP Content type colors, will likely change names used in LevelsScreenHeader.tsx
  Blue150 = '#ECF6FF',
  Blue250 = '#DDEFFF',
  Blue350 = '#146FE3',
}

// Marketplace
export const MAX_PROGRAM_PRICE_FOR_FILTER = 100000000;

// Approvals
export const APPROVAL_AUTOPOPULATE_MULTIPLIER = 1.1;
export const APPROVAL_GUIDELINE_MAX_COUNT = 800;

// Field length
export const SHORT_DESCRIPTION_CHARACTER_MAX_COUNT = 1000;
export const APPROVAL_DRAWER_TEXT_AREA_LINE_LENGTH = 49;
export const CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH = 10000;

// Keys
export const KEY_CODES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  HOME: 'Home',
  SPACE: 'Space',
  TAB: 'Tab',
};

// Sizes
export const FOOTER_HEIGHT = 72;
export const HEADER_HEIGHT = 72;
export const SIDE_NAV_WIDTH = 96;
export const SECTIONS_NAV_WIDTH = 272;
export const DEFAULT_TABLE_SCROLL_HEIGHT = '83vh';

// Localization
export const LANGUAGE_DEFAULT_NAMESPACE = 'translation';
export const LANGUAGE_LOCAL_STORAGE_KEY = 'lng';
export const LANGUAGE_FALLBACK_LOCALE = 'en-US';

// Non-localizable
export const LEARN_IN_LIVE = 'Learn In Live';

// Charts
export const CHART_COLORS = {
  GREEN: COLORS.Green700,
  BLUE: COLORS.Blue700,
  ORANGE: COLORS.Orange100,
  RED: COLORS.Red800,
  PURPLE: COLORS.Purple800,
  PINK: COLORS.Red700,
};

export const CHART_COLORS_SEQUENCES = {
  PRIMARY: [
    CHART_COLORS.GREEN,
    CHART_COLORS.BLUE,
    CHART_COLORS.ORANGE,
    CHART_COLORS.RED,
    CHART_COLORS.PURPLE,
    CHART_COLORS.PINK,
  ],
};

// Program Filter localStorage key
export const PROGRAM_FILTER_LANGUAGES = 'program-filter-languages';

// Session-based Integrated Incoming LXP User key
export const INTEGRATED_INCOMING_LXP_USER = 'incoming-integrated-lxp-user';
