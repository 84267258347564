// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanItemVM.cs

import {z} from 'zod';
import {PlanItemTypeSchema} from './PlanItemTypeSchema.schema';

export const UserPlanItemVMSchema = z.object({
  id: z.number(),
  itemType: PlanItemTypeSchema,
  largeImageUrl: z.string(),
  preApprovalApplicationStatus: z.string(),
  provider: z.string(),
  providerLogo: z.string(),
  requestRemoveConfirmation: z.boolean(),
  status: z.string(),
  title: z.string(),
});
