import {useState, useEffect} from 'react';
import {isLDFeatureFlagOn} from './../utils/feature-flag-helpers';
import {isLocal, isProductionAny} from '@utils/environment-helpers';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
  getLocalHostTreatment,
} from '@utils/feature-flag-helpers';
import {useFlags} from 'launchdarkly-react-client-sdk';
/*
|--------------------------------------------------------------------------
| When a feature flag is added, this hook automatically gives access to its status
|--------------------------------------------------------------------------
*/

export type TIsFeatureFlagOn = Record<
  keyof typeof FeatureFlagExperiments,
  boolean
>;

interface ReturnInterface {
  isFeatureFlagOn: TIsFeatureFlagOn;
  overrideFlag: (key: string, value: boolean) => void;
  flagOverrides?: Record<string, boolean>;
}

/**
 * @example
 *
 * import useFeatureFlags from '@hooks/useFeatureFlags';
 *
 * const {isFeatureFlagOn} = useFeatureFlags();
 */

const useFeatureFlags = (isFlagOnInControlCase?: boolean): ReturnInterface => {
  const [flagOverrides, setFlagOverrides] = useState<Record<string, boolean>>(
    {}
  );

  useEffect(() => {
    const localStorageOvverrides = localStorage.getItem('featureFlagOverrides');
    setFlagOverrides(
      localStorageOvverrides ? JSON.parse(localStorageOvverrides) : {}
    );
  }, []);

  const ldflags = useFlags();

  // Get treatment using Launch Darkly api unless doing local development
  const treatment = (flagkey: string) => {
    return isLocal
      ? getLocalHostTreatment(flagkey)
      : isLDFeatureFlagOn(ldflags[flagkey]);
  };

  const isFeatureFlagOn: any = Object.keys(FeatureFlagExperiments).reduce(
    (flagStatuses, flag: FeatureFlagExperiments) => {
      flagStatuses[flag] = (() => {
        const flagKey = FeatureFlagExperiments[flag];

        // In non-production environments, check the local storage for overrides
        if (!isProductionAny && flagOverrides[flagKey] !== undefined) {
          return flagOverrides[flagKey];
        }

        // Rule skipped: This hook is validly calling another hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        switch (treatment(flagKey)) {
          case FeatureFlagTreatments.On:
            return true;
          case FeatureFlagTreatments.Off:
            return false;
          case FeatureFlagTreatments.Control:
            return !!isFlagOnInControlCase;
          default:
            return false;
        }
      })();
      return flagStatuses;
    },
    {}
  );
  return {
    isFeatureFlagOn,
    flagOverrides,
    overrideFlag: (key, value) => {
      const newState = {...flagOverrides, [key]: value};
      setFlagOverrides(newState);
      localStorage.setItem('featureFlagOverrides', JSON.stringify(newState));
    },
  };
};

export default useFeatureFlags;
