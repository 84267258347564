// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ReimbursementsReportResult.cs

import {z} from 'zod';

export const ReimbursementsReportResultSchema = z.object({
  amountPaidOut: z.number(),
  applicationUrl: z.string(),
  employeeDepartment: z.string(),
  employeeName: z.string(),
  incentive: z.string(),
  isInternalProgram: z.boolean(),
  programTitle: z.string(),
  programType: z.string(),
  providerName: z.string(),
  reimbursementId: z.number(),
  sentToPayrollOn: z.string(),
  totalRequestedAmount: z.number(),
});
