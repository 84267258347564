import {useCallback, useEffect, useMemo, useState} from 'react';
import CreateUserProgramDrawer from './CreateUserProgramDrawer';
import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {
  NewProgram,
  PreApprovalApplication,
  ProgramFilterVM,
  ProgramWithApprovalPayload,
} from '@models/serverModels';
import {useMutation, useQuery} from 'react-query';
import {getPreApprovalFormRq, getUploadAttachmentRm} from '@store/apiEndpoints';
import {
  getProgramsProgramTypesRq,
  getProvidersRq,
} from '@store/apiEndpoints/program/queries';
import {
  getAddNewProgramRm,
  getAddProgramWithApprovalRm,
} from '@store/apiEndpoints/program/mutations';
import {ProgramTypesVM} from '@components/admin/pages/investments/components/incentives/store/interfaces';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {ApprovalStatus, PlanItemType} from '@generated/enums';
import {notify} from '@components/user/notifications';
import {APPROVAL_AUTOPOPULATE_MULTIPLIER} from '@utils/constants';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  getExchangeRateBySelection,
  MONEY_DEFAULT,
  REQUEST_CURRENCY_ID,
} from '@utils/moneyUtils';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useDeleteAttachment} from '@hooks/apiEndpoints/attachments/mutations';
import useChunkUpload from '@hooks/useChunkUpload';
import {
  applyCurrencySelection,
  conditionallyFormatResponse,
} from '@utils/preApprovalRequest';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/
function getProviderSuggestions({id, description}: ProgramFilterVM): {
  value: string;
  label: string;
  id: number;
} {
  return {id, label: description, value: description};
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CreateUserProgramDrawerContainerProps {
  formInstance?: FormInstance;
  visible?: boolean;
  setRedesignsOnSubmit?: (fn: () => void) => void;
  setIsSubmitButtonLoading?: (isLoading: boolean) => void;
  isSubmitButtonLoading?: boolean;
  onClose?: () => void;
  onOk?: () => void;
}

function CreateUserProgramDrawerContainer({
  formInstance,
  setIsSubmitButtonLoading,
  isSubmitButtonLoading,
  visible,
  setRedesignsOnSubmit,
  onClose,
  onOk,
}: CreateUserProgramDrawerContainerProps) {
  const [oldForm] = Form.useForm();
  const form = formInstance ?? oldForm;
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState({});
  const [oldIsSubmitLoading, setOldIsSubmitLoading] = useState(false);
  const [provider, setProvider] = useState('');

  isSubmitButtonLoading = isSubmitButtonLoading ?? oldIsSubmitLoading;
  setIsSubmitButtonLoading = setIsSubmitButtonLoading ?? setOldIsSubmitLoading;

  useEffect(() => {
    form?.resetFields();
    setSelectedCheckboxOptions([]);
  }, [visible]);
  //Fetch form items and options.
  // Get Program Types
  const programTypesRq = getProgramsProgramTypesRq();
  const programTypesQuery = useQuery<ProgramTypesVM>(
    programTypesRq.queryKey,
    () => simpleQueryFn(programTypesRq.path)
  );

  // PLB Budget Query
  const {activePlbExists} = useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const getUserQuery = useGetUserQuery();

  //Provider suggestions
  const providersRq = getProvidersRq();
  const providersQuery = useQuery<ProgramFilterVM[]>(providersRq.queryKey, () =>
    simpleQueryFn(providersRq.path)
  );
  const providerSuggestions = useMemo(() => {
    return providersQuery.data?.map(getProviderSuggestions) || [];
  }, [!!providersQuery.data]);

  //Pre Approval form
  const preApprovalFormRq = getPreApprovalFormRq(PlanItemType.Program);
  const getPreApprovalFormQuery = useQuery<PreApprovalApplication>(
    preApprovalFormRq.queryKey,
    () => simpleQueryFn(preApprovalFormRq.path)
  );

  const isFormDisabled =
    getPreApprovalFormQuery.data?.status === ApprovalStatus.Rejected;

  const {isFeatureFlagOn} = useFeatureFlags();
  const isHelperTextOn = isFeatureFlagOn.HelperText;
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesArrayLength, setFilesArrayLength] = useState(0);
  const [attachmentIds, setAttachmentIds] = useState([]);

  const [filesProgress, setFilesProgress] = useState([]);

  const {chunkUpload, multipleUploadProgress, fileId} = useChunkUpload({
    query: getUploadAttachmentRm,
    customizableLearningCategory: PlanItemType.Program,
  });

  useEffect(() => {
    //check if a file was added and not deleted
    if (filesToUpload.length > 0 && filesArrayLength < filesToUpload.length) {
      const idx = filesToUpload.length - 1;
      const latestFile = filesToUpload[idx];
      setFilesArrayLength(filesToUpload.length);
      setFilesProgress((filesProgress) => {
        const progressBars = [...filesProgress];
        progressBars[idx] = 1;
        return progressBars;
      });
      chunkUpload(latestFile, {index: idx});
    }

    //uploadAttachments
  }, [filesToUpload.length]);
  //update progress bars
  useEffect(() => {
    const idx = multipleUploadProgress.index;
    const progressBars = filesProgress;
    if (idx !== '') {
      progressBars[idx] = Math.round(multipleUploadProgress.progress);
    }
    setFilesProgress(progressBars);
  }, [multipleUploadProgress]);

  useEffect(() => {
    if (fileId.length) {
      setAttachmentIds([...attachmentIds, fileId]);
    }
  }, [fileId]);
  const deleteAttachmentMutation = useDeleteAttachment();

  const deleteFunction = (idx) => {
    //Attachment delete API
    deleteAttachmentMutation.mutate({
      attachmentId: attachmentIds[idx],
    });
    setFilesProgress([
      ...filesProgress.slice(0, idx),
      ...filesProgress.slice(idx + 1, filesProgress.length),
    ]);
    setAttachmentIds([
      ...attachmentIds.slice(0, idx),
      ...attachmentIds.slice(idx + 1, attachmentIds.length),
    ]);
    setFilesArrayLength(filesToUpload.length - 1);
  };

  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;

  const addProgramWithApprovalMutation = useMutation(
    (args: ProgramWithApprovalPayload) => {
      const addProgramWithApproval = getAddProgramWithApprovalRm(args);
      return simpleMutationFn<ProgramWithApprovalPayload>(
        addProgramWithApproval.path,
        addProgramWithApproval.payload
      );
    },
    {
      onSuccess: () => {
        notify.AddProgramWithApprovalSuccess();
        onOk?.();
        onClose?.();
        setIsSubmitButtonLoading(false);
      },
      onError: () => {
        notify.AddProgramWithApprovalError();
        setIsSubmitButtonLoading(false);
      },
    }
  );

  const addNewProgramMutation = useMutation(
    (args: NewProgram) => {
      const addNewProgram = getAddNewProgramRm(args);
      return simpleMutationFn<NewProgram>(
        addNewProgram.path,
        addNewProgram.payload
      );
    },
    {
      onSuccess: () => {
        notify.AddProgramWithApprovalSuccess();
        onOk?.();
        setIsSubmitButtonLoading(false);
        onClose?.();
      },
      onError: () => {
        notify.AddProgramWithApprovalError();
        setIsSubmitButtonLoading(false);
      },
    }
  );

  // Event Handlers
  const handleChangeCheckbox = (id, selectedOptions) => {
    setSelectedCheckboxOptions((prev) => {
      return {...prev, [id]: selectedOptions};
    });
  };

  const handleChangeSelect = async (selectedOption: string) => {
    // Pass
  };

  const handleClickSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      // If a currency is selected, use that exchange rate
      const requestExchangeRate = getExchangeRateBySelection(
        values?.[REQUEST_CURRENCY_ID],
        exchangeRatesList,
        exchangeRate
      );
      const effectiveExchangeRate = requestExchangeRate;

      // If a known provider is selected, find that provider's id
      const suggestedProvider = providerSuggestions.find(
        ({label}) => label === provider
      );

      const application = getPreApprovalFormQuery.data.application;
      const applicationId = getPreApprovalFormQuery.data.id;

      applyCurrencySelection(
        application,
        values?.[REQUEST_CURRENCY_ID],
        getUserQuery.data?.currency
      );

      const appArray = application.map((item) => ({
        ...item,
        response: conditionallyFormatResponse({
          item,
          values,
          exchangeRate: effectiveExchangeRate,
        }),
      }));

      const newProgramPayload = {
        providerId: suggestedProvider?.id || 0,
        providerName: provider || null,
        title: values.title,
        shortDescription: values.shortDescription,
        cost: values.cost,
        currency: values.currency ?? getUserQuery.data?.currency,
        url: values.url,
        programLength: values.programLength,
        programLengthUnit: values.programLengthUnit,
        programTypeId: values.programTypeId,
      };

      setIsSubmitButtonLoading(true);

      if (!activePlbExists) {
        await addNewProgramMutation.mutateAsync(newProgramPayload);
      } else {
        const formattedPostPayload = {
          newProgram: {...newProgramPayload},
          applicationDetails: {
            application: appArray,
            id: applicationId,
            status: ApprovalStatus.Requested,
          },
          attachmentIds: attachmentIds,
        };
        //Send data
        await addProgramWithApprovalMutation.mutateAsync(formattedPostPayload);
      }
    } catch (error) {
      console.error(error);
      setIsSubmitButtonLoading(false);
      return;
    }
  }, [
    activePlbExists,
    addNewProgramMutation,
    addProgramWithApprovalMutation,
    attachmentIds,
    exchangeRate,
    exchangeRatesList,
    form,
    getPreApprovalFormQuery.data?.application,
    getPreApprovalFormQuery.data?.id,
    getUserQuery.data?.currency,
    provider,
    providerSuggestions,
    setIsSubmitButtonLoading,
  ]);

  useEffect(() => {
    // Assigns the submit handler to the wrapper. This can be simplified by moving to the redesign container when we are no longer supporting the old design.
    visible && setRedesignsOnSubmit?.(() => () => handleClickSubmit());
  }, [visible, handleClickSubmit, setRedesignsOnSubmit]);

  return (
    <CreateUserProgramDrawer
      form={form}
      formItems={getPreApprovalFormQuery.data?.application || []}
      isFormDisabled={isFormDisabled || isSubmitButtonLoading}
      isHelperTextOn={isHelperTextOn}
      isSubmitButtonLoading={
        isSubmitButtonLoading ||
        addProgramWithApprovalMutation.isLoading ||
        filesProgress.some((n) => n < 100)
      }
      isTextAreaDisabled={false}
      isUserRequest={true}
      onClose={onClose}
      onChangeProvider={setProvider}
      onSubmitForm={handleClickSubmit}
      onChangeCheckbox={handleChangeCheckbox}
      onChangeSelect={handleChangeSelect}
      planItemType={PlanItemType.Program}
      programTypes={programTypesQuery.data || []}
      provider={provider}
      providerSuggestions={providerSuggestions}
      selectedCheckboxOptions={selectedCheckboxOptions}
      programCostFeesMultiplier={APPROVAL_AUTOPOPULATE_MULTIPLIER}
      rejectedNotes={getPreApprovalFormQuery.data?.rejectedNotes}
      user={getUserQuery.data}
      visible={visible}
      showApprovalQuestions={activePlbExists}
      exchangeRate={exchangeRate}
      filesProgress={filesProgress}
      filesToUpload={filesToUpload}
      setFilesToUpload={setFilesToUpload}
      deleteFunction={deleteFunction}
    />
  );
}

export default CreateUserProgramDrawerContainer;
