const optimizeImageUrlOptions = 'f_auto,q_auto';

export const AIRPLANE_DESKTOP = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647027016/production/airplane-desktop_gtqjjj.png`;
export const AIRPLANE_IMAGE = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026998/production/airplane_jsc4nv.png`;
export const LEARNING_BUDGET_WELCOME_TOUR = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026810/production/learning-budget-welcome-tour_ii7tjv.gif`;
export const PROGRAMS = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026770/production/programs_aij8mp.gif`;
export const CALENDAR = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026631/production/calendar_pzibhc.gif`;
export const CALENDAR_CONNECT = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1657032784/production/calendar-connect_i9nfej.gif`;
export const FINANCIAL_PLANS = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026918/production/financial-plans_dwoji7.gif`;
export const SLIDER = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647026956/production/slider-1_ocn3zt.png`;
export const LOGO_SPIN = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1647027040/production/logo-spin_ozg2n5.gif`;
export const PROGRAM_COMPLETED = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1648744350/production/program-completed_zvk5gw.gif`;
export const NEW_ACADEMY_PAGE_DECORATION = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1692902901/creative_workspace_avnp1z.png`;
export const MOUNTAIN_PATH = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1691445755/production/mountain-path_2x_xbvaan.png`;

export const LEARNING_TROPHY = `https://res.cloudinary.com/dsmsrmuyr/image/upload/${optimizeImageUrlOptions}/v1698929996/learning-trophy_cywcdu.png`;
