import {COLORS} from '@utils/constants';

export const TextAreaStyles = {
  primary: {
    background: COLORS.White,
    disabledBackground: COLORS.Neutral100,
    border: `1px solid ${COLORS.Neutral300}`,
    color: COLORS.Neutral950,
    borderRadius: '4px',
    transition: 'all 0.2s ease-out',
    focusBorder: `1px solid ${COLORS.Blue800}`,
  },
  standard: {
    background: COLORS.White,
    disabledBackground: COLORS.Neutral100,
    border: `1px solid ${COLORS.Neutral300}`,
    errorBorder: `1px solid ${COLORS.Red800}`,
    color: COLORS.Neutral950,
    borderRadius: '4px',
    transition: 'all 0.2s ease-out',
    focusBorder: `1px solid ${COLORS.Blue800}`,
  },
};
