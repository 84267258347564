// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanReimbursementItemVM.cs

import {z} from 'zod';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {ReimbursementItemTypeSchema} from './ReimbursementItemTypeSchema.schema';

export const UserPlanReimbursementItemVMSchema = z.object({
  id: z.number(),
  learningResourceType: LearningResourceTypeSchema.nullable().optional(),
  logo: z.string().nullable().optional(),
  subTitle: z.string(),
  title: z.string(),
  type: ReimbursementItemTypeSchema,
});
