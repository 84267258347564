// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyLevelVM.cs

import {z} from 'zod';
import {IAcademyLevelBasicInfoSchema} from './IAcademyLevelBasicInfoSchema.schema';
import {AcademyStepVMSchema} from './AcademyStepVMSchema.schema';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

const schema = z.object({
  academySteps: z.array(AcademyStepVMSchema),
  description: z.string(),
  id: z.number(),
  order: z.number(),
  requiredId: z.number().nullable().optional(),
  status: UserItemStatusVMSchema,
  title: z.string(),
});

export const AcademyLevelVMSchema = IAcademyLevelBasicInfoSchema.merge(schema);
