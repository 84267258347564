// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Licenses/Responses/ViewLicenseResponse.cs

import {z} from 'zod';
import {LicenseResponseSchema} from './LicenseResponseSchema.schema';

const schema = z.object({
  adminName: z.string().nullable().optional(),
  adminNote: z.string().nullable().optional(),
  managerName: z.string().nullable().optional(),
  managerNote: z.string().nullable().optional(),
  voucherCode: z.string().nullable().optional(),
});

export const ViewLicenseResponseSchema = LicenseResponseSchema.merge(schema);
