// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/ReimbursementPreviewVM.cs

import {z} from 'zod';

export const ReimbursementPreviewVMSchema = z.object({
  amount: z.number(),
  approvalStatus: z.string(),
  date: z.string(),
  id: z.number(),
  title: z.string(),
});
