// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramContentVM.cs

import {z} from 'zod';
import {ContentTypeSchema} from './ContentTypeSchema.schema';
import {SubmissionTypeSchema} from './SubmissionTypeSchema.schema';

export const CustomProgramContentVMSchema = z.object({
  allowPeerVisibility: z.boolean(),
  attachmentGuids: z.array(z.string()),
  contentType: ContentTypeSchema,
  description: z.string(),
  descriptionAttachmentGuids: z.array(z.string()),
  discussionId: z.string().nullable().optional(),
  dueDateTimeZone: z.string().nullable().optional(),
  dueDateUtc: z.string().nullable().optional(),
  endDateUtc: z.string().nullable().optional(),
  endTime: z.string().nullable().optional(),
  eventDate: z.string().nullable().optional(),
  eventLink: z.string().nullable().optional(),
  eventTimeZone: z.string().nullable().optional(),
  id: z.number(),
  isSubmissionTypeEditable: z.boolean(),
  order: z.number(),
  startDateUtc: z.string().nullable().optional(),
  startTime: z.string().nullable().optional(),
  submissionType: SubmissionTypeSchema.nullable().optional(),
  title: z.string(),
});
