import {ReactNode, useLayoutEffect, useRef} from 'react';
import styled from 'styled-components';
import {Menu} from 'antd';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {ClientPathsType} from '@utils/ClientPaths';
import classnames from 'classnames';
import Request from '@components/user/components/request';
import {RequestMenuButtonAndDrawerViewContainer} from '@components/user/components/request/RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
const {SubMenu} = Menu;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const NavContainer = styled.nav`
  min-height: '70vh';
  .nav-menu-item {
    margin: 0 !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    &:hover {
      color: black;
      background: #f2f2f2 !important;
    }
  }
  .nav-menu-item-selected {
    border-right: none !important;
    background-color: #ecf6ff !important;
    color: black !important;
    font-weight: 700 !important;
    &:hover {
      color: black;
      background: #ecf6ff !important;
    }
    &::after {
      border-right: none !important;
    }
  }
  .sub-menu-item {
    font-size: 0.875rem !important;
  }
  .ant-menu-submenu-title {
    margin: 0 !important;
  }
  .ant-menu-submenu-title:hover {
    color: black !important;
    background: #f2f2f2 !important;
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: black !important;
    }
  }
  .ant-menu-submenu-open > .ant-menu-submenu-title {
    font-weight: bold !important;
  }
  .ant-menu-submenu {
    font-size: 1rem !important;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export enum SubMenuKeys {
  Explore = 'GENERIC__EXPLORE',
  Approvals = 'APPROVAL__PLURAL',
  Learning = 'GENERIC__LEARNING',
  TeamInsights = 'TEAM__INSIGHTS',
}

/*
|--------------------------------------------------------------------------
| Util Component
|--------------------------------------------------------------------------
*/

export function NavMenuItemLabelWithCountBadge({
  label,
  count,
}: {
  label: string;
  count: number;
}) {
  return (
    <LearnInContainer tag={ContainerTags.MenuItemBadgeContainer}>
      {label}{' '}
      {!!count && (
        <LearnInContainer className="badge" tag={ContainerTags.BadgeContainer}>
          <LearnInText className="badgeText" tag={TextTags.MenuBadge}>
            {count}
          </LearnInText>
        </LearnInContainer>
      )}
    </LearnInContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface NavMenuItem {
  label: string;
  icon?: ReactNode;
  path?: ClientPathsType;
  count?: number;
  isSelected?: boolean;
  subItems?: {
    path: ClientPathsType;
    icon?: ReactNode;
    label: string;
    count?: number;
  }[];
}

export interface AppNavMenuProps {
  isAdminPath: boolean;
  isDirectBilling: boolean;
  isSubMenuItemSelected: (path: ClientPathsType) => boolean;
  navMenuItems: NavMenuItem[];
  onClickMenuItem: (path: ClientPathsType) => void;
  onOpenChange: (keys: SubMenuKeys[]) => void;
  openSubMenuKeys: SubMenuKeys[];
  pathname: string;
}

function AppNavMenu({
  onClickMenuItem,
  isDirectBilling,
  isAdminPath,
  isSubMenuItemSelected,
  openSubMenuKeys,
  onOpenChange,
  navMenuItems,
  pathname,
}: AppNavMenuProps) {
  /**
   * Ally Improvements - PD-97104
   * Accessibility within the Menu and Submenu components is handled
   * by Ant design. This block of code aims to alleviate inherited a11y issues
   * by manipulating the DOM directly
   */
  const containerRef = useRef<HTMLElement>();
  useLayoutEffect(
    function fixSubmenuRoles() {
      containerRef?.current
        ?.querySelectorAll('div[role="menuitem"][aria-expanded]')
        .forEach((elem) => {
          elem.setAttribute('role', 'menu');
        });

      containerRef?.current
        ?.querySelectorAll('li[role="none"]')
        .forEach((elem) => {
          elem.removeAttribute('role');
        });
    },
    [navMenuItems]
  );
  return (
    <NavContainer ref={containerRef}>
      <Menu
        mode="inline"
        openKeys={openSubMenuKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        selectedKeys={[pathname]}>
        {navMenuItems?.map(
          ({label, path, subItems, count, isSelected, icon}) => {
            // Render Items without SubMenus
            if (!subItems) {
              return (
                <Menu.Item
                  icon={icon}
                  key={path + label}
                  className={classnames('nav-menu-item', {
                    'nav-menu-item-selected':
                      isSelected || isSubMenuItemSelected(path),
                  })}
                  onClick={() => (path ? onClickMenuItem(path) : null)}>
                  {!count ? (
                    label
                  ) : (
                    <NavMenuItemLabelWithCountBadge
                      label={label}
                      count={count}
                    />
                  )}
                </Menu.Item>
              );
            } else {
              // Render Items with SubMenu
              return (
                <SubMenu
                  key={path + label}
                  icon={icon}
                  title={
                    !count ? (
                      label
                    ) : (
                      <NavMenuItemLabelWithCountBadge
                        label={label}
                        count={count}
                      />
                    )
                  }>
                  {subItems?.map(({path, label, count, icon}) => {
                    return (
                      <Menu.Item
                        key={path + label}
                        icon={icon}
                        className={classnames(
                          'nav-menu-item',
                          'sub-menu-item',
                          {
                            'nav-menu-item-selected':
                              isSubMenuItemSelected(path),
                          }
                        )}
                        onClick={() => onClickMenuItem(path)}>
                        {!count ? (
                          label
                        ) : (
                          <NavMenuItemLabelWithCountBadge
                            label={label}
                            count={count}
                          />
                        )}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          }
        )}
      </Menu>
      {!isAdminPath && !isDirectBilling && (
        <RequestMenuButtonAndDrawerViewContainer>
          <Request />
        </RequestMenuButtonAndDrawerViewContainer>
      )}
    </NavContainer>
  );
}

export default AppNavMenu;
