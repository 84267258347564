export enum ModalTags {
  Default = 'default',
  LicenseAuto = 'licenseAutoAddModal',
  MoneyIncentive = 'moneyIncentiveModal',
  PublishIncentiveConfirmation = 'publishIncentiveConfirmation',
  RemoveProgramFromUserPlan = 'RemoveProgramFromUserPlan',
  AddFundsToCreditCard = 'AddFundsToCreditCard',
  CardInfoModal = 'CardInfoModal',
  ProgramCompletedModal = 'ProgramCompletedModal',
  RemoveAcademyModal = 'RemoveAcademyModal',
  ConnectCalendar = 'ConnectCalendarModal',
  CardIssuerTermsModal = 'CardIssuerTermsModal',
}
