// Do not modify directly
// Generated from file: learnin.core.api/Controllers/User/Requests/UserSettingsUpdateRequest.cs

import {z} from 'zod';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LanguagePreferenceSchema} from './LanguagePreferenceSchema.schema';
import {NotificationSettingsSchema} from './NotificationSettingsSchema.schema';

export const UserSettingsUpdateRequestSchema = z.object({
  currency: CurrencyCodeSchema,
  languagePreference: LanguagePreferenceSchema,
  notificationSettings: NotificationSettingsSchema,
});
