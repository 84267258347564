// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Interfaces/IAcademyUserBasicInfo.cs

import {z} from 'zod';
import {AcademyUserStatusSchema} from './AcademyUserStatusSchema.schema';

export const IAcademyUserBasicInfoSchema = z.object({
  academyId: z.number(),
  status: AcademyUserStatusSchema,
});
