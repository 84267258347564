// Do not modify directly
// Generated from file: learnin.core.application/Services/UserService/Responses/PeopleTabUserDetailsVM.cs

import {z} from 'zod';

export const PeopleTabUserDetailsVMSchema = z.object({
  active: z.boolean(),
  canViewAcademiesFeature: z.boolean(),
  costCenter: z.string().nullable().optional(),
  createdDate: z.string(),
  email: z.string(),
  isAdmin: z.boolean(),
  isDev: z.boolean(),
  isEligibleForPersonalLearningBudget: z.boolean(),
  isLearnInAdmin: z.boolean(),
  isManager: z.boolean(),
  jobRole: z.string(),
  lastLogin: z.string().nullable().optional(),
  managerName: z.string().nullable().optional(),
  name: z.string(),
  startDate: z.string().nullable().optional(),
  uniqueId: z.string().nullable().optional(),
});
