import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';
import {useHasIntegratedUx} from './useHasIntegratedUx';
import {useUrlContainsIndicator} from './useUrlContainsIndicator';
import {UserDetailsVM} from '@generated/interfaces';
import {useIsIntegratedUser} from './useIsIntegratedUser';

/** If directly navigating from LXP, we hide the primary navigation for integrated users, regardless of approver status */
export const useForceHidePrimaryNavigation = (
  user: UserDetailsVM,
  isFeatureFlagOn: TIsFeatureFlagOn,
  isApprover: boolean
) => {
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const hasIntegratedUx = useHasIntegratedUx(isIntegratedUser, isApprover);
  const hideIfUrlIndicates = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.ACMLayout || isFeatureFlagOn.LXPAdminLayout
  );

  if (!isFeatureFlagOn.UpdatedLxpToCmFlow) return false;

  // The return will just be the "if" case when we no longer support feature-flag based integration mode
  return isIntegratedUser
    ? hideIfUrlIndicates || hasIntegratedUx
    : hideIfUrlIndicates;
};
