// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CurrencyCode.cs

enum CurrencyCode {
  USD,
  CAD,
  AUD,
  BRL,
  CHF,
  DKK,
  EUR,
  GBP,
  INR,
  RSD,
  SEK,
  ZAR,
  CLP,
}

export default CurrencyCode;
