// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/ItemDetails/DetailsVM.cs

import {z} from 'zod';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {IncentiveAvailabilitySchema} from './IncentiveAvailabilitySchema.schema';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';
import {DetailsSectionVMSchema} from './DetailsSectionVMSchema.schema';

export const DetailsVMSchema = z.object({
  approvalConfig: ApprovalConfigSchema,
  approvalGuidelines: z.string(),
  approvalRequested: z.boolean(),
  approvalRequired: z.boolean(),
  availability: IncentiveAvailabilitySchema,
  companyIncentive: z.boolean(),
  id: z.number(),
  imageLarge: z.string(),
  images: z.array(AttachmentVMSchema),
  imageSmall: z.string(),
  learningBudget: z.boolean(),
  logo: z.string(),
  longDescription: z.string(),
  picture: z.string(),
  programTypeIds: z.array(z.number()),
  provider: z.string(),
  sections: z.array(DetailsSectionVMSchema),
  selected: z.boolean(),
  shortDescription: z.string(),
  title: z.string(),
});
