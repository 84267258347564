// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyPeopleUserVM.cs

import {z} from 'zod';

export const AcademyPeopleUserVMSchema = z.object({
  name: z.string(),
  role: z.string(),
  userCompanyId: z.number(),
});
