import pinkPlaceholder from '@assets/topographic_pattern_pink.svg';
import yellowPlaceholder from '@assets/topographic_pattern_yellow.svg';
import darkPurplePlaceholder from '@assets/topographic_pattern_dark_purple.svg';
import purplePlaceholder from '@assets/topographic_pattern_purple.svg';

/**
 * Updates cloudinary URL to resize image if it's cloudinary. Returns original url if not Cloudinary. Can pass width, height or both
 * @param url
 * @param width optional, width of output
 * @param height optional, height of output
 */
export const resizeIfCloudinary = (
  url: string,
  width?: number,
  height?: number
): string => {
  if (!url) {
    return url;
  }

  // nothing to do
  if (!width && !height) {
    return url;
  }

  // not cloudinary so can't resize
  if (!url.includes('cloudinary')) {
    return url;
  }

  const resizeInfo = ['c_scale'];
  width && resizeInfo.push(`w_${Math.ceil(width)}`);
  height && resizeInfo.push(`h_${Math.ceil(height)}`);

  const replacementPrefix = '/image/upload';
  const replacementString = `${replacementPrefix}/${resizeInfo.join(',')}`;

  return url.replace(replacementPrefix, replacementString);
};

export const getPlaceholderImgSrc = (id = 0) => {
  const randomFixedNumber = id % 4; // get a random number, but based on entity id so it is evenly distributed and random
  return [
    pinkPlaceholder,
    yellowPlaceholder,
    purplePlaceholder,
    darkPurplePlaceholder,
  ][randomFixedNumber];
};

export async function getBlobUrl(binaryString): Promise<string> {
  const base64Response = await fetch(binaryString);
  const blob = await base64Response.blob();
  return URL.createObjectURL(blob);
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
};

export const imageExists = (image_url) => {
  const http = new XMLHttpRequest();
  http?.open('HEAD', image_url, false);
  http?.send();
  return http?.status === 200 ? image_url : false;
};
