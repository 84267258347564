// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyOverviewStepUserReport.cs

import {z} from 'zod';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';

export const AcademyOverviewStepUserCompletionReportVmSchema = z.object({
  email: z.string().nullable().optional(),
  lastNudgeSent: z.string().nullable().optional(),
  name: z.string(),
  status: UserItemStatusVMSchema,
  userCompanyId: z.number(),
});
