// Do not modify directly
// Generated from file: learnin.core.integrations/LXP/Models/Skills/SkillDetail.cs

import {z} from 'zod';

export const SkillDetailSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
});
