// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/PrepaymentSettings/ConnectedBankAccountVM.cs

import {z} from 'zod';

export const ConnectedBankAccountVMSchema = z.object({
  accountLast4: z.string(),
  bankName: z.string(),
  default: z.boolean(),
  id: z.string(),
});
