// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateUserEvent.cs

import {z} from 'zod';
import {NewUserEventSchema} from './NewUserEventSchema.schema';

const schema = z.object({
  cancel: z.boolean(),
  id: z.number(),
  previousStartDate: z.string().nullable().optional(),
});

export const UpdateUserEventSchema = NewUserEventSchema.merge(schema);
