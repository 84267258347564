import UserMenuDropdown from './UserMenuDropdown';
import {useAuth} from '@utils/oidc-auth-wrapper';
import usePathInfo from '@hooks/usePathInfo';
import {useNavigate} from 'react-router-dom';
import {UserPaths, AdminPaths} from '@utils/ClientPaths';
import {clearLocalAcademiesActivity} from '@hooks/academies/academyUserActivity';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

function getInitialsOfName(name: string): string {
  let nameInitials = '';
  name?.split(' ').forEach((partOfName) => {
    nameInitials = nameInitials + partOfName[0] + '';
  });
  return nameInitials.toLocaleUpperCase();
}

function getDisplayName(
  firstName: string | null,
  lastName: string | null,
  email: string | undefined
) {
  const firstNameTrimmed = firstName?.trim();
  const lastNameTrimmed = lastName?.trim();

  if (firstNameTrimmed) {
    return lastNameTrimmed
      ? `${firstNameTrimmed} ${lastNameTrimmed}`
      : firstNameTrimmed;
  } else {
    return email || null;
  }
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function UserMenuDropdownContainer({
  closeMobileMenu,
}: {
  closeMobileMenu?: () => void;
}) {
  const navigate = useNavigate();
  const {isAuthenticated, user, logout, loginWithRedirect} = useAuth();
  const {isAdminPath} = usePathInfo();

  const handleClickSettings = () => {
    navigate(UserPaths.Settings);
    closeMobileMenu && closeMobileMenu();
  };

  // Switch between Admin and User apps
  const handleClickChangeApps = () => {
    if (isAdminPath) {
      navigate(UserPaths.ManagePlan);
    } else {
      navigate(AdminPaths.Programs);
    }
    closeMobileMenu && closeMobileMenu();
  };

  const handleLogout = () => {
    logout();
    clearLocalAcademiesActivity();
  };

  const handleKnowledgeCenter = () => {
    const url = 'https://degreed.zendesk.com/hc/categories/10329959026332';
    const newTab = window.open(url, '_blank');

    // Prevent new tab from having access to the current window
    if (newTab) {
      newTab.opener = null;
    }
  };

  return (
    <UserMenuDropdown
      isAuthenticated={isAuthenticated}
      isAdmin={user?.isAdmin}
      isAdminPath={isAdminPath}
      onClickLogin={loginWithRedirect}
      onClickLogout={handleLogout}
      userDisplayName={getDisplayName(
        user?.firstName,
        user?.lastName,
        user?.email
      )}
      userInitials={getInitialsOfName(`${user?.firstName} ${user?.lastName}`)}
      onClickSettings={handleClickSettings}
      onClickChangeApps={handleClickChangeApps}
      onClickKnowledgeCenter={handleKnowledgeCenter}
    />
  );
}

export default UserMenuDropdownContainer;
