/** These strings aren't customer-facing: these don't need to be localized. */
export const PROJECT_SUBMISSION_FEEDBACK_PROGRAM = {
  name: 'Lucas',
  title: 'D&D Starter Kit',
  employeeName: 'Will',
  projectUrl: 'https://staging.degreed.dev/develop/#fake-project-url',
  message:
    'Your explanation of supernatural forces and secret government exploits makes me think you have too active of an imagination.',
  type: 'program',
  parentTitle: 'Academy of Excellence',
  companyLogoUrl:
    'https://static.wikia.nocookie.net/logopedia/images/f/f5/Stranger_Things.svg/revision/latest/scale-to-width-down/300?cb=20220803140619',
};
