// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Payments/CreditCardPaymentDetailsVM.cs

import {z} from 'zod';

export const CreditCardPaymentDetailsVMSchema = z.object({
  address: z.string(),
  cardholderName: z.string(),
  cardNumber: z.string(),
  city: z.string(),
  cvc: z.string(),
  expiration: z.string(),
  postalCode: z.string(),
  state: z.string(),
});
