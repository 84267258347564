import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {TextStyles} from './TextTheme';
import {TextTags} from './TextEnums';
import {COLORS} from '@utils/constants';

const StyledText = styled.div`
  color: ${(props: any) => props.color || TextStyles[props.tag].color};
  font-size: ${(props: any) =>
    props.fontSize || TextStyles[props.tag].fontSize};
  font-weight: ${(props: any) =>
    props.fontWeight || TextStyles[props.tag].fontWeight};
  font-family: ${(props: any) =>
    props.fontFamily || TextStyles[props.tag].fontFamily};
  line-height: ${(props: any) =>
    props.lineHeight || TextStyles[props.tag].lineHeight};
  padding-bottom: ${(props: any) =>
    props.paddingBottom || TextStyles[props.tag].paddingBottom};
  margin: ${(props: any) => props.margin || TextStyles[props.tag].margin};
  letter-spacing: ${(props: any) =>
    props.letterSpacing || TextStyles[props.tag].letterSpacing};
  text-align: ${(props: any) =>
    props.textAlign || TextStyles[props.tag].textAlign};
`;

interface Props {
  children: ReactNode;
  tag: TextTags;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  lineHeight?: string;
  paddingBottom?: string;
  margin?: string;
  className?: string;
  id?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export function LearnInText({children, ...rest}: Props) {
  return <StyledText {...rest}>{children}</StyledText>;
}

export const ManageYourPlanTitle = styled.h4`
  margin: 16px 0 8px 0;
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: ${COLORS.Neutral900} !important;
`;
