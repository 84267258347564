import {ReactNode} from 'react';
import styled from 'styled-components';

import {ContainerStyles} from './ContainerTheme';
import {ContainerTags} from './ContainerEnums';

const StyledContainer = styled.div`
  padding: ${(props: any) =>
    props.padding || ContainerStyles[props.tag].padding};
  background: ${(props: any) =>
    props.background || ContainerStyles[props.tag].background};
  border-radius: ${(props: any) =>
    props.borderRadius || ContainerStyles[props.tag].borderRadius};
  box-shadow: ${(props: any) =>
    props.boxShadow || ContainerStyles[props.tag].boxShadow};
  border: ${(props: any) => props.border || ContainerStyles[props.tag].border};
  width: ${(props: any) => props.width || ContainerStyles[props.tag].width};
  max-width: ${(props: any) =>
    props.maxWidth || ContainerStyles[props.tag].maxWidth};
  margin: ${(props: any) => props.margin || ContainerStyles[props.tag].margin};
  height: ${(props: any) => props.height || ContainerStyles[props.tag].height};
  display: ${(props: any) =>
    props.display || ContainerStyles[props.tag].display};
  justify-content: ${(props: any) =>
    props.justifyContent || ContainerStyles[props.tag].justifyContent};
  align-items: ${(props: any) =>
    props.alignItems || ContainerStyles[props.tag].alignItems};
  flex-direction: ${(props: any) =>
    props.flexDirection || ContainerStyles[props.tag].flexDirection};
  cursor: ${(props: any) => props.cursor || ContainerStyles[props.tag].cursor};
  border-top: ${(props: any) =>
    props.borderTop || ContainerStyles[props.tag].borderTop};
  &:hover {
    border: ${(props: any) =>
      props.hoverBorder || ContainerStyles[props.tag].hoverBorder};
    background: ${(props: any) =>
      props.hoverBackground || ContainerStyles[props.tag].hoverBackground};
  }
`;

export interface LearnInContainerProps {
  children: ReactNode;
  tag: ContainerTags;
  padding?: string;
  background?: string;
  margin?: string;
  borderRadius?: string;
  boxShadow?: string;
  style?: Record<string, any>;
  onClick?: () => void;
  role?: string;
  id?: string;
  flexDirection?: string;
  className?: string;
  hoverBackground?: string;
  width?: string;
  maxWidth?: string;
  tabIndex?: string;
  borderTop?: string;
  border?: string;
}

/**
 * This is the standard container that should be used in all places where containers are needed. You may
 * need to add new tags to this container if you are implementing a new type of container. The default
 * container should cover most use cases but you can pass in props to override styling if necessary.
 */
export function LearnInContainer({children, ...rest}: LearnInContainerProps) {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
}
