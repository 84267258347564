import * as React from 'react';
import styled from 'styled-components';

export type IconSizes = 'S' | 'M' | 'L' | 'XL';

enum SIZE {
  S = '1rem',
  M = '1.25rem',
  L = '1.5rem',
  XL = '2rem',
}

export const StyledIcon = styled(({icon, ...rest}) =>
  React.cloneElement(icon, rest)
)`
  color: ${({color}) => color};
  &,
  svg {
    height: ${({size}) => size};
    width: ${({size}) => size};
  }
  &:hover {
    color: ${({color, hovercolor}) => hovercolor ?? color};
  }
`;

export interface IconProps {
  color?: string;
  icon: React.ReactElement;
  size?: IconSizes;
  hoverColor?: string;
}

function Icon({color, icon, size = 'S', hoverColor}: IconProps) {
  const iconSize = SIZE[size];
  return (
    <StyledIcon
      className="learnin-icon"
      aria-hidden="true"
      hovercolor={hoverColor}
      color={color}
      icon={icon}
      size={iconSize}
    />
  );
}

export default Icon;
