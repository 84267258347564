import {Container} from './WhatsNext.styled';
import {WhatsNextViewProps} from './WhatsNext.interfaces';
import {BodyParagraph, BodySpan} from '../reusable/Typography';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';

function WhatsNextView({visible}: WhatsNextViewProps) {
  return (
    visible && (
      <Container>
        <BodySpan fontWeight={500}>
          {i18n.t(k.APPROVAL__WHATS_NEXT__TITLE)}
        </BodySpan>
        <BodyParagraph color={COLORS.Neutral600}>
          {i18n.t(k.APPROVAL__WHATS_NEXT__DESCRIPTION)}
        </BodyParagraph>
      </Container>
    )
  );
}

export default WhatsNextView;
