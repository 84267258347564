// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Payments/CreditCardDetailsVM.cs

import {z} from 'zod';
import {CreditCardAdminActionVMSchema} from './CreditCardAdminActionVMSchema.schema';
import {CreditCardTransactionVMSchema} from './CreditCardTransactionVMSchema.schema';

export const CreditCardDetailsVMSchema = z.object({
  adminActions: z.array(CreditCardAdminActionVMSchema),
  approvalRequired: z.boolean(),
  billingAddress: z.string(),
  billingCity: z.string(),
  billingPostalCode: z.string(),
  billingState: z.string(),
  cardHolder: z.string(),
  cardId: z.number(),
  last4: z.string(),
  remainingBalance: z.number(),
  transactions: z.array(CreditCardTransactionVMSchema),
});
