// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/AcademyUserPermissionPayload.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  academyId: z.number(),
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  userCompanyIds: z.array(z.number()),
});

export const AcademyUserPermissionPayloadSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
