// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewApprovalRequest.cs

import {z} from 'zod';
import {PreApprovalApplicationSchema} from './PreApprovalApplicationSchema.schema';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';

export const LearningResourceApprovalRequestSchema = z.object({
  applicationDetails: PreApprovalApplicationSchema,
  attachmentIds: z.array(z.string()),
  resourceType: LearningResourceTypeSchema,
  userBudgetId: z.number().nullable().optional(),
  userPlanResourceId: z.number().nullable().optional(),
});
