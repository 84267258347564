// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramContentProgressResult.cs

import {z} from 'zod';
import {RsvpStatusSchema} from './RsvpStatusSchema.schema';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';
import {ContentTypeSchema} from './ContentTypeSchema.schema';

export const CustomProgramContentProgressResultSchema = z.object({
  completedOn: z.string().nullable().optional(),
  id: z.number(),
  order: z.number(),
  rsvpStatus: RsvpStatusSchema.nullable().optional(),
  status: UserItemStatusVMSchema,
  title: z.string(),
  type: ContentTypeSchema,
});
