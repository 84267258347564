import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {Form} from 'antd';

export const StyledLearnInModal = styled(LearnInModal)`
  .ant-form-item-label {
    align-self: center !important;
    font-weight: 500;
    text-align: left;
    width: 70px;
  }
`;

export const StyledForm = styled(Form)`
  margin: 24px;
`;
