import {TIsFeatureFlagOn} from './useFeatureFlags';

/**
 * Returns true if any flag is on (isFeatureFlagOn starts out all false)
 * Assumption is that we'll always have at least one flag turned on
 */
export const useFlagsHaveLoaded = (isFeatureFlagOn: TIsFeatureFlagOn) => {
  for (const x in isFeatureFlagOn) {
    if (isFeatureFlagOn[x]) return true;
  }
  return false;
};
