export enum AdminScreenApprovalStatus {
  Requested = 1,
  Approved = 2,
  Rejected = 3,
  PendingManagerApproval = 4,
}

export enum UserPlanCardActions {
  Remove,
  Apply,
  Admit,
  Start,
  Complete,
  Finish,
  Reapply,
  ViewLicense,
  ViewWebsite,
  Request,
  ViewDetails,
}

export enum InitiativePurposeString {
  LearnCriticalSkill = 'learn_critical_skill',
  PrepareForNewRole = 'prepare_for_new_role',
  Other = 'other',
}

export enum InitiativePurposeStringCode {
  LearnCriticalSkill = 'learn_critical_skill',
  PrepareForNewRole = 'prepare_for_new_role',
  Other = 'other',
}

export enum INCENTIVE_RECURRING_TYPE {
  single = 'single',
  recurring = 'recurring',
}

export enum FINANCE_INCENTIVE_ADD_TYPES {
  PersonalLearningBudget,
  License,
  Other,
}

export enum IncentivesTabName {
  PROGRAM = '0',
  TIME = '1',
  FINANCE = '2',
}

export enum YourPlanTabName {
  INPROGRESS = 'InProgress',
  COMPLETED = 'Completed',
}

export enum TransactionsTabName {
  TRANSACTIONS = 'Transactions',
  ACTIVITY = 'AdminActivity',
}

export enum TransactionStatuses {
  Pending = 'pending',
  Declined = 'declined',
}

export enum UserPlanSectionTitle {
  PROGRAMS = 'PROGRAMS',
}

export enum ManagerApprovalsTabsName {
  REQUESTS = 'Requests',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

// these are based on strings in the database (not enums)
export enum UserPlanItemStatusString {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Requested = 'Requested',
  Applied = 'Applied',
  Admitted = 'Admitted',
  Denied = 'Denied',
  InProgress = 'InProgress',
  Complete = 'Complete',
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  ScheduleApproved = 'ScheduleApproved',
  ScheduleRejected = 'ScheduleRejected',
  PendingUse = 'PendingUse',
  Expired = 'Expired',
  ReimbursementSubmitted = 'ReimbursementSubmitted',
  ReimbursementApproved = 'ReimbursementApproved',
  ReimbursementNeedsReview = 'ReimbursementNeedsReview',
  ReimbursementPaymentPending = 'ReimbursementPaymentPending',
  ReimbursementPaid = 'ReimbursementPaid',
  Planned = 'Planned',
}

export enum UserPlanItemStatus {
  Pending,
  Requested,
  Approved,
  Rejected,
  Cancelled,
  Applied,
  Admitted,
  Denied,
  InProgress,
  Complete,
  NotSubmitted,
  Submitted,
  ScheduleApproved,
  ScheduleRejected,
  PendingUse,
  Expired,
  ReimbursementSubmitted,
  ReimbursementApproved,
  ReimbursementNeedsReview,
  ReimbursementPaymentPending,
  ReimbursementPaid,
}

export enum IncentiveTypeString {
  Time = 'time',
  Finance = 'finance',
}

export enum AcademyUserStatusEnum {
  NotStarted,
  Progress,
  Complete,
  Dropped,
}

export enum AcademyPermissions {
  Public,
  Restricted,
}

export enum Locale {
  en_US = 'en-US',
}

export enum CurrencyCodeString {
  USD = 'USD',
  CAD = 'CAD',
  AUD = 'AUD',
  BRL = 'BRL',
  CHF = 'CHF',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  INR = 'INR',
  RSD = 'RSD',
  SEK = 'SEK',
  ZAR = 'ZAR',
  CLP = 'CLP',
}

export enum AcademyItemType {
  Level,
  Step,
}

/** @deprecated */
export enum InternalCost {
  Fixed = 'fixed',
  Dependent = 'dependent',
  Zero = 'zero',
}

/** @deprecated */
export enum LicenseDuration {
  Fixed = 'fixed',
  NoExpiration = 'noExpiration',
}

export enum RecommendationLevelNumber {
  FEATURED = 0,
  NOMINATED = 1,
  RECOMMENDED = 2,
}

export enum RecommendationLevelString {
  Featured = 'featured',
  Nominated = 'nominated',
  Recommended = 'recommended',
  Standard = 'standard',
  Initiative = 'initiative',
}

export enum ContributorPermissionType {
  canApproveProgram = 'canApproveProgram',
  canReviewProject = 'canReviewProject',
  canViewReport = 'canViewReport',
  canEdit = 'canEdit',
}

export enum ProgramContentStatus {
  Completed = 1,
  Planned = 0,
}

export enum AttachmentTypeString {
  File,
  Video,
  FileString = 'File',
  VideoString = 'Video',
}

// Used for client-side distinction only
export enum PlanItemTypeWithCustomProgram {
  CustomProgram = -1,
  MarketplaceProgram = 0, // Same as Program
  Program = 0,
  Time,
  Finance,
  ProgramLicense,
}

/** @deprecated */
export enum ApprovalsDiscriminatorWithTime {
  Time = -1,
  Incentive,
  Program,
  LearningResource,
  License,
  ProgramReimbursement,
  LearningResourceReimbursement,
}

export enum FinanceIncentiveTypeString {
  Loan = 'Loan',
  ISA = 'ISA',
  Scholarship = 'Scholarship',
  Discount = 'Discount',
  Prepayment = 'Prepayment',
  TuitionReimbursement = 'TuitionReimbursement',
  CompletionBonus = 'CompletionBonus',
  Other = 'Other',
  ExternalReimbursement = 'ExternalReimbursement',
}

export enum BudgetSections {
  Ready = 'ready',
  Awaiting = 'awaiting',
  Pending = 'pending',
  Spent = 'spent',
  Remaining = 'remaining',
}
