// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramCohortUpdateRequest.cs

import {z} from 'zod';
import {CustomProgramStatusSchema} from './CustomProgramStatusSchema.schema';

export const CustomProgramCohortUpdateRequestSchema = z.object({
  cohortTitle: z.string(),
  endDate: z.string(),
  id: z.number(),
  isAccessRestricted: z.boolean(),
  isNudgeEmailEnabled: z.boolean(),
  isProgramCompletionEmailEnabled: z.boolean(),
  isWelcomeEmailEnabled: z.boolean(),
  parentCustomProgramId: z.number(),
  startDate: z.string(),
  status: CustomProgramStatusSchema,
  timezone: z.string(),
});
