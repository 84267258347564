import {useParamInSessionStorage} from '@hooks/useParamInSessionStorage';

/** A user is LXP and Academies enabled when the user's integrated property is false AND the param was passed in the URL and used in session storage */
export const useIsLxpAndAcademiesUser = (
  isAcademiesIntegratedExperienceDisabled: boolean
) => {
  const enabled = !isAcademiesIntegratedExperienceDisabled;
  const paramUsed = useParamInSessionStorage(enabled, 'lxpAndAcademies');
  return enabled && paramUsed;
};
