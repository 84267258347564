import {ReactNode} from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {RadioButtonProps} from 'antd/es/radio/radioButton';
import {Radio, Space} from 'antd';

const StyledRadioButtons = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    border: none;
    box-shadow: ${COLORS.BoxShadowStandard};
    color: ${COLORS.Neutral900};
    display: block;
    width: max-content;
    &:focus-within {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: -2px;
    }
    &:hover {
      color: ${COLORS.White};
      background: ${COLORS.Blue800};
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &:hover {
      color: ${COLORS.White};
      background: ${COLORS.Blue800};
    }
    color: ${COLORS.Neutral950};
    &,
    &::before {
      background: ${COLORS.Neutral200};
    }
  }
`;
interface Props extends RadioButtonProps {
  buttonStyle?: 'outline' | 'solid';
  children: ReactNode;
  defaultValue?: any;
  gapSize?: 'small' | 'middle' | 'large' | 'none';
  size?: 'large' | 'middle' | 'small';
}

export default function LearnInRadioButtons({
  children,
  gapSize,
  ...rest
}: Props) {
  return (
    <StyledRadioButtons {...rest}>
      <Space size={gapSize === 'none' ? 0 : gapSize}>{children}</Space>
    </StyledRadioButtons>
  );
}
