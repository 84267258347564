// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/Requests/CustomProgramNudgeUserRequest.cs

import {z} from 'zod';

export const CustomProgramNudgeUserRequestSchema = z.object({
  contentId: z.number().nullable().optional(),
  note: z.string(),
  subject: z.string(),
});
