import {COLORS} from '@utils/constants';
import {Tooltip as AntDTooltip} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
import styled from 'styled-components';

const StyledA11yButton = styled.span`
  background: transparent;
  border: none;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

type Props = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  childHasButton?: boolean;
  open?: boolean;
  placement?: TooltipPlacement;
  width?: string;
};

/**
 * @param childHasButton should be `true` if you're embedding a child element which is focusable. If this is the case, this component will not create its own focusable trigger for a11y. If you're not embedding a child element, just leave this false so component can create its own focusable trigger for a11y.
 */
function Tooltip({
  title,
  children,
  open,
  childHasButton = false,
  placement = 'top',
  width = '167px',
}: Props) {
  return (
    <AntDTooltip
      placement={placement}
      trigger={['focus', 'hover']}
      open={open}
      overlayStyle={{
        borderRadius: '4px',
        width,
      }}
      title={title}>
      {childHasButton ? (
        children
      ) : (
        <StyledA11yButton tabIndex={0} aria-label={title}>
          {children}
        </StyledA11yButton>
      )}
    </AntDTooltip>
  );
}

export default Tooltip;
