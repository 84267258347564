// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramSectionPreviewVM.cs

import {z} from 'zod';
import {CustomProgramSectionVMSchema} from './CustomProgramSectionVMSchema.schema';
import {CustomProgramContentPreviewVMSchema} from './CustomProgramContentPreviewVMSchema.schema';

const schema = z.object({
  content: z.array(CustomProgramContentPreviewVMSchema),
  progressPercentage: z.number(),
});

export const CustomProgramSectionPreviewVMSchema =
  CustomProgramSectionVMSchema.merge(schema);
