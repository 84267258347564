import {useState} from 'react';
import RequestFormView from './RequestForm.view';
import {RequestFormContainerProps} from './RequestForm.interfaces';
import {useRequestFormEffects} from './RequestForm.hooks';
import {ProgramRequestType} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.interfaces';

function RequestFormContainer({
  form,
  handleOnCloseSuccess,
  requestType,
  rerequestResourceType,
  setIsSubmitButtonLoading,
  setOnSubmit,
  setRequestType,
}: RequestFormContainerProps) {
  const [transitioned, setTransitioned] = useState(false);

  useRequestFormEffects({
    rerequestResourceType,
    setRequestType,
    setTransitioned,
  });

  return (
    <RequestFormView
      form={form}
      isProgram={requestType === ProgramRequestType}
      handleOnCloseSuccess={handleOnCloseSuccess}
      requestType={requestType}
      setOnSubmit={setOnSubmit}
      setIsSubmitButtonLoading={setIsSubmitButtonLoading}
      transitioned={transitioned}
    />
  );
}

export default RequestFormContainer;
