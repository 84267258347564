// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPermissionUpdatePayload.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  contentId: z.number(),
  userCompanyId: z.number(),
});

export const UserPermissionUpdatePayloadSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
