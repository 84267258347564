import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

/** @returns boolean of on/off state. Only supports booleans. */
export const useParamInSessionStorage = (
  featureFlagValue: unknown,
  sessionItemName: string
) => {
  const location = useLocation();
  const [param, setParam] = useState(
    sessionStorage.getItem(sessionItemName) === 'true'
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionItemValue = searchParams.get(sessionItemName);

    if (sessionItemValue === 'true' && featureFlagValue === true) {
      sessionStorage.setItem(sessionItemName, 'true');
      setParam(true);
    } else if (sessionItemValue === 'false' || featureFlagValue === false) {
      sessionStorage.removeItem(sessionItemName);
      setParam(false);
    }
  }, [location.search, featureFlagValue, sessionItemName]);

  return param;
};
