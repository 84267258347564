// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AcademyUserStatus.cs

import {z} from 'zod';

export const AcademyUserStatusSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
