// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Initiatives/InitiativeAdminVM.cs

import {z} from 'zod';

export const InitiativeAdminVMSchema = z.object({
  audience: z.string(),
  id: z.number(),
  incentives: z.number(),
  programs: z.number(),
  status: z.string(),
  title: z.string(),
});
