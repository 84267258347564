// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/LinkVM.cs

import {z} from 'zod';

export const LinkVMSchema = z.object({
  id: z.number(),
  title: z.string(),
  url: z.string(),
});
