import {datadogLogs} from '@datadog/browser-logs';
export const logMockData = (path, pathVars, hookName, queryOptions) => {
  const LOG_MSG = 'Using mockResponseData mock data for query';
  const logInfo = {
    path,
    pathVars,
    hookName,
    mockResponseData: queryOptions.mockResponseData,
  };
  console.warn(`***${LOG_MSG}: `, logInfo);
  datadogLogs.logger.info(LOG_MSG, logInfo);
};
