// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ProgramProgressUpdate.cs

import {z} from 'zod';
import {ProgressSkillSchema} from './ProgressSkillSchema.schema';
import {UserProgressSummarySchema} from './UserProgressSummarySchema.schema';

export const ProgramProgressUpdateSchema = z.object({
  content: z.string(),
  id: z.number(),
  rating: z.number(),
  skills: z.array(ProgressSkillSchema),
  submitted: z.boolean(),
  summary: UserProgressSummarySchema.nullable().optional(),
  title: z.string(),
});
