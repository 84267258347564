import {Breakpoints} from '@utils/responsiveUtils';
import styled from 'styled-components';

export const GridContainer = styled.div<{
  column: boolean;
}>`
  display: flex;
  ${({column}) => {
    return column && 'flex-direction: column';
  }};
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const GridBox = styled.div<{
  quarter?: boolean;
  half?: boolean;
  threeQuarters?: boolean;
  third?: boolean;
  twoThirds?: boolean;
  full?: boolean;
  fullWidthOnMobile?: boolean;
}>`
  box-sizing: border-box;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  width: ${({quarter, half, threeQuarters, third, twoThirds, full}) => {
    if (full) return '100%';
    if (quarter) return '23%';
    if (half) return '48%';
    if (threeQuarters) return '69%';
    if (third) return '31.5%';
    if (twoThirds) return '64.5%';
    return '100%';
  }};
  @media (max-width: ${Breakpoints.Tablet}px) {
    width: ${({fullWidthOnMobile = true}) => fullWidthOnMobile && '100%'};
  }
`;

export default {
  Container: GridContainer,
  Box: GridBox,
};
