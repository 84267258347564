import {LearnInInput} from '@components/reusable/Input/Input.style';
import {
  FormItem,
  getCommonRules,
  renderQuillLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {i18n, k} from '@i18n/translate';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {FormInstance} from 'antd';
import {
  handleInputErrorText,
  showHelperTextIfAvailable,
  noUserInputAllowedHtml,
  validateUrl,
} from '@utils/itemUtils';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

interface UrlInputProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  isHelperTextOn: boolean;
  isTextAreaDisabled: boolean;
  form: FormInstance;
}

export const UrlInput = ({
  form,
  item,
  isTextAreaDisabled,
  isHelperTextOn,
}: UrlInputProps) => {
  return (
    <LearnInFormItem
      key={item.id}
      name={String(item.id)}
      label={renderQuillLabel(item)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      requiredMark={false}
      className="lb-application-drawer"
      initialValue={item.response || ''}
      validateTrigger={[
        'onBlur',
        !!form.getFieldError(String(item.id)).length && 'onChange',
      ]}
      rules={[
        ...getCommonRules(item, isHelperTextOn),
        {
          message: noUserInputAllowedHtml(
            handleInputErrorText(
              i18n.t(k.VALIDATION__URL_INVALID__FORMAT),
              localizeText({
                translationKey: item.localizationId
                  ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
                  : null,
                translationValue: item.helperText,
              }),
              isHelperTextOn
            )
          ),
          validator: (_, value) => {
            if (!value || validateUrl(value)) return Promise.resolve();
            return Promise.reject(
              new Error(i18n.t(k.VALIDATION__URL_INVALID__FORMAT))
            );
          },
        },
      ]}>
      <LearnInInput disabled={isTextAreaDisabled} maxLength={5000} />
    </LearnInFormItem>
  );
};
