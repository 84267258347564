// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Dashboard/FeaturedDashboardItem.cs

import {z} from 'zod';
import {GetStartedItemVMSchema} from './GetStartedItemVMSchema.schema';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {PlanItemTypeSchema} from './PlanItemTypeSchema.schema';

const schema = z.object({
  approvalConfig: ApprovalConfigSchema,
  description: z.string(),
  featured: z.boolean(),
  itemType: PlanItemTypeSchema,
});

export const FeaturedDashboardItemVMSchema =
  GetStartedItemVMSchema.merge(schema);
