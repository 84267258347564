import {ChangeEvent, KeyboardEvent} from 'react';
import * as React from 'react';
import styled, {css} from 'styled-components';
import {Input, InputNumber} from 'antd';
import {InputNumberProps} from 'antd/lib/input-number';
import {InputProps} from 'antd/lib/input';
import {InputPrefixContainer} from '../LearnInForm';
import {InputStyles} from './InputTheme';
import {InputTags} from './InputEnums';
import {CurrencyCode} from '@generated/enums';
import {
  displayCurrencySymbol,
  formatCurrency,
  MONEY_DEFAULT,
  moneyFormInputParserWithPrefix,
} from '@utils/moneyUtils';
import {Locale} from '@models/clientEnums';

interface LearnInInputProps extends InputProps {
  bordered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  readonly?: boolean;
  size?: 'large' | 'middle' | 'small';
  style?: React.CSSProperties;
  type?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
  isError?: boolean;
  background?: string;
  tag?: InputTags;
  updateFunc?: (val: any) => void;
}

const StyledInput = styled(Input)`
  height: 40px;
  background: ${(props: any) =>
    props?.disabledBackground ||
    props?.background ||
    InputStyles[props?.tag]?.background} !important;
  border: ${(props: any) =>
    props?.isError
      ? InputStyles[props?.tag]?.errorBorder
      : InputStyles[props?.tag]?.border} !important;
  color: ${(props: any) => props?.color || InputStyles[props?.tag]?.color};
  border-radius: ${(props: any) =>
    props?.borderRadius || InputStyles[props?.tag]?.borderRadius};
  transition: ${(props: any) =>
    props?.transition || InputStyles[props?.tag]?.transition};
  &:disabled {
    background: ${(props: any) =>
      props?.disabledBackground ||
      InputStyles[props?.tag]?.disabledBackground} !important;
  }
  &:focus {
    border: ${(props: any) =>
      props?.isError
        ? InputStyles[props?.tag]?.errorBorder
        : InputStyles[props?.tag]?.focusBorder} !important;
    box-shadow: none !important;
  }
  &.ant-input-status-error {
    border: ${(props: any) => InputStyles[props?.tag]?.errorBorder} !important;
  }
`;

/**
 *
 * This is the standard input that should be used in all places where inputs are needed. You may need to
 * add new tags to this input if you are implementing a new type of input. The default input should cover
 * most use cases but you can pass in props to override styling if necessary.
 */
export const LearnInInput = React.forwardRef<
  HTMLInputElement,
  LearnInInputProps
>((props, ref) => {
  return (
    <StyledInput {...props} tag={props.tag || InputTags.PRIMARY} ref={ref} />
  );
});

const Suffix = styled.span`
  margin-left: 8px;
`;

LearnInInput.displayName = 'LearnInInput';

interface LearnInNumberInputProps extends InputNumberProps {
  style?: React.CSSProperties;
  min?: number;
  max?: number;
  formatter?: (value: string | number) => string;
  parser?: (displayValue: string) => string | number;
  disabled?: boolean;
  step?: number | 'any';
  border?: string;
  suffix?: string;
}

/*
|--------------------------------------------------------------------------
| Number Input
|--------------------------------------------------------------------------
*/

const StyledNumberInput = styled(InputNumber)`
  background:${(props: any) =>
    props?.disabledBackground ||
    props?.background ||
    InputStyles[props?.tag]?.background} !important;
  border: ${(props: any) =>
    props.bordered && props?.isError
      ? InputStyles[props?.tag]?.errorBorder
      : InputStyles[props?.tag]?.border} !important;
    box-shadow: none !important;
  color: ${(props: any) => props?.color || InputStyles[props?.tag]?.color};
  border-radius: ${(props: any) =>
    props?.borderRadius || InputStyles[props?.tag]?.borderRadius};
  transition: ${(props: any) =>
    props?.transition || InputStyles[props?.tag]?.transition};
  &:disabled {
    background: ${(props: any) =>
      props?.disabledBackground ||
      InputStyles[props?.tag]?.disabledBackground} !important;
  }
  &:focus-within {
    border: ${(props: any) =>
      props?.isError
        ? props?.errorBorder || InputStyles[props?.tag]?.errorBorder
        : props?.focusBorder ||
          InputStyles[props?.tag]?.focusBorder} !important;
    box-shadow: none !important;
  }
  .ant-input-number-group-addon {
    padding: 0;
    > div {
      margin-right: 0;
    }
  }
  ${({noInnerBorder}) => {
    return (
      noInnerBorder &&
      css`
        padding-inline-start: 0px;
        div.ant-input-number {
          border: none !important;
        }
        div.ant-input-number-input-wrap {
          height: 100% !important;
        }
        input.ant-input-number-input {
          height: 40px !important;
        }
      `
    );
  }}

  ${({$condensed}) =>
    $condensed &&
    css`
      .ant-input-number-prefix {
        -webkit-margin-end: 0;
        margin-inline-end: 0;
      }
    `}};


    ${({$prefixBgTransparent}) =>
      $prefixBgTransparent &&
      css`
        .ant-input-number-prefix {
          background: transparent !important;
        }
      `}};
`;

export const LearnInNumberInput = React.forwardRef<
  HTMLInputElement,
  LearnInNumberInputProps
>((props, ref) => {
  return (
    <>
      <StyledNumberInput tag={InputTags.PRIMARY} {...props} ref={ref} />
      {props?.suffix && <Suffix>{props.suffix}</Suffix>}
    </>
  );
});

LearnInNumberInput.displayName = 'LearnInNumberInput';

/*
|--------------------------------------------------------------------------
| Money Input
|--------------------------------------------------------------------------
*/
export interface LearnInMoneyInputProps extends LearnInNumberInputProps {
  condensed?: boolean;
  currency?: CurrencyCode;
  locale?: Locale;
  prefixType?: CurrencyCode;
  tag?: InputTags;
  prefixBgTransparent?: boolean;
  hide?: boolean;
}

export const LearnInMoneyInput = React.forwardRef<
  HTMLInputElement,
  LearnInMoneyInputProps
>(
  (
    {
      currency = CurrencyCode.USD,
      locale = MONEY_DEFAULT.locale,
      controls = true,
      condensed = false,
      prefixBgTransparent = false,
      hide = false,
      ...rest
    }: LearnInMoneyInputProps,
    ref
  ) => {
    const symbolData = hide ? null : displayCurrencySymbol(currency, locale);
    const symbolContainer = symbolData && (
      <InputPrefixContainer
        condensed={condensed}
        prefixBgTransparent={prefixBgTransparent}>
        {condensed
          ? symbolData?.symbol.replace(/[A-Za-z]/gi, '')
          : symbolData?.symbol}
      </InputPrefixContainer>
    );

    return (
      <StyledNumberInput
        formatter={(value) =>
          formatCurrency(value, currency, MONEY_DEFAULT.exchangeRate, {
            numberStyle: 'decimal',
            isInput: true,
          })
        }
        parser={moneyFormInputParserWithPrefix}
        tag={InputTags.PRIMARY}
        prefix={symbolData?.prefix ? symbolContainer : null}
        addonAfter={!symbolData?.prefix ? symbolContainer : null}
        noInnerBorder={true}
        controls={controls}
        $condensed={condensed}
        ref={ref}
        {...rest}
      />
    );
  }
);

LearnInMoneyInput.displayName = 'LearnInMoneyInput';
