// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Requests/AcademyNudgeUsersRequest.cs

import {z} from 'zod';

export const AcademyNudgeUsersRequestSchema = z.object({
  note: z.string(),
  stepId: z.number().nullable().optional(),
  subject: z.string(),
});
