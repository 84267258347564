// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/ReimbursementPayload.cs

import {z} from 'zod';
import {PreApprovalQuestionSchema} from './PreApprovalQuestionSchema.schema';
import {ReimbursementItemTypeSchema} from './ReimbursementItemTypeSchema.schema';

export const ReimbursementPayloadSchema = z.object({
  application: z.array(PreApprovalQuestionSchema),
  attachmentIds: z.array(z.string()),
  itemId: z.number(),
  reimbursementItemType: ReimbursementItemTypeSchema,
});
