// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Budgets/Requests/CreateBudgetRequest.cs

import {z} from 'zod';
import {BudgetAllocationTypeSchema} from './BudgetAllocationTypeSchema.schema';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {ApprovalConfigSchema} from './ApprovalConfigSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';
import {FinanceRecurrenceSchema} from './FinanceRecurrenceSchema.schema';
import {BudgetTypeSchema} from './BudgetTypeSchema.schema';

export const CreateBudgetRequestSchema = z.object({
  about: z.string(),
  active: z.boolean(),
  allocationType: BudgetAllocationTypeSchema,
  allowDirectBilling: z.boolean(),
  allowedResources: z.array(LearningResourceTypeSchema),
  allowExternalReimbursement: z.boolean(),
  allowReimbursement: z.boolean(),
  allowVirtualCard: z.boolean(),
  approvalConfig: ApprovalConfigSchema,
  approvalExpirationDays: z.number().nullable().optional(),
  approvalGuidelines: z.string(),
  balanceRecurType: BalanceRecurTypeSchema,
  companyId: z.number(),
  description: z.string(),
  fiscalStartDate: z.string(),
  limit: z.number(),
  proratingEnabled: z.boolean(),
  recurrence: FinanceRecurrenceSchema,
  title: z.string(),
  type: BudgetTypeSchema,
});
