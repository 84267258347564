// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/PrepaymentSettings/BalanceDetailsVM.cs

import {z} from 'zod';

export const BalanceDetailsVMSchema = z.object({
  allocatedBalance: z.number(),
  currentBalance: z.number(),
  spentYtd: z.number(),
});
