import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';

export interface UploadInstructionsProps {
  fileTypes?: string;
  maxSize?: string;
}

function UploadInstructions({fileTypes, maxSize}: UploadInstructionsProps) {
  const defaultFileTypes = i18n.t(k.FILE__UPLOAD__DEFAULT_TYPES);
  const defaultMaxSize = '500 MB';
  return (
    <>
      <p>
        <span style={{color: COLORS.Blue800}}>
          {i18n.t(k.FILE__CLICK_TO_SELECT)}
        </span>{' '}
        {i18n.t(k.GENERIC__OR)}{' '}
        <span style={{color: COLORS.Blue800}}>
          {i18n.t(k.FILE__DRAG_AND_DROP)}
        </span>
      </p>
      <p>
        {i18n.t(k.FILE__UPLOAD__ACCEPTED_TYPES__FORMAT, {
          types: fileTypes ?? defaultFileTypes,
          maxSize: maxSize ?? defaultMaxSize,
        })}
      </p>
    </>
  );
}

export default UploadInstructions;
