import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {FormInstance} from 'antd';
import {
  CurrencyCode,
  LearningResourceType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import {ProgramDetailsVM} from '@models/serverModels';
import {UserDetailsVM} from '@generated/interfaces';
import {COLORS} from '@utils/constants';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';
import {DateInput} from './DateInput';
import {ImageUpload} from '@components/user/pages/components/PreApprovalFormItem/ImageUpload';
import {Dropdown} from '@components/user/pages/components/PreApprovalFormItem/Dropdown';
import {TextInput} from '@components/user/pages/components/PreApprovalFormItem/TextInput';
import {UrlInput} from '@components/user/pages/components/PreApprovalFormItem/UrlInput';
import {Multiselect} from '@components/user/pages/components/PreApprovalFormItem/Multiselect';
import {isRecurringType} from '@utils/preApprovalRequest';
import MoneyInput from './MoneyInput';
import {handleInputErrorText, noUserInputAllowedHtml} from '@utils/itemUtils';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const FormItem = styled(LearnInFormItem)`
  .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;

const LabelText = styled.div`
  align-items: center;
  color: ${COLORS.Neutral900};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

export const renderQuillLabel = (item, resourceType?) => (
  <LabelText>
    <QuillHtmlViewer
      html={localizeText({
        translationKey: item.localizationId,
        translationValue: item.value,
      })}
    />
  </LabelText>
);

export const renderLabel = (item, resourceType?) => {
  return (
    <LabelText>
      {localizeText({
        translationKey: item.localizationId,
        translationValue: item.value,
      })}
    </LabelText>
  );
};

export const getCommonRules = (
  item: Partial<{
    required: boolean;
    localizationId?: string;
    helperText?: string;
  }>,
  isHelperTextOn: boolean
) => [
  {
    required: item?.required,
    message: noUserInputAllowedHtml(
      handleInputErrorText(
        i18n.t(k.VALIDATION__RESPONSE_REQUIRED),
        localizeText({
          translationKey: item?.localizationId
            ? item?.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item?.helperText,
        }),
        isHelperTextOn
      )
    ),
  },
];

export interface PreApprovalFormItemProps {
  frequencyDropdown?: ResourceOrPlanItemPreApprovalQuestion;
  onTotalChange?: (total: number) => void;
  resourceType?: LearningResourceType;
  costFieldValidation: any[];
  costFormattedInProgramCurrency: string;
  costFormattedInUsersCurrency: string;
  defaultToProgramCurrency: boolean;
  filesToUpload?: any[];
  filesProgress?: any[];
  form?: FormInstance;
  formQuestions?: ResourceOrPlanItemPreApprovalQuestion[];
  isCostField;
  isFormDisabled: boolean;
  isFormIsEditable?: boolean;
  isHelperTextOn: boolean;
  isProgram?: boolean;
  isResourceRequest?: boolean;
  isTextAreaDisabled: boolean;
  isUserRequest?: boolean;
  item: ResourceOrPlanItemPreApprovalQuestion;
  maxLength: number;
  onChangeCheckbox?: (id, selectedOptions: string[]) => void;
  onChangeSelect?: (selectedOption: string) => void;
  prefilledCost?: number;
  programCost: number | null;
  programDetails?: ProgramDetailsVM | null;
  selectedCheckboxOptions?: {[key: number]: string[]};
  setFilesToUpload?: (files: any[]) => void;
  showCount?: boolean;
  user: UserDetailsVM;
  subscriptionCost?: number | null;
  total?: number;
  programCurrency?: CurrencyCode;
  rangeDates?: {startDate: any; endDate: any};
  setRangeDates?: (rangeDates) => void;
  isSubscription?: boolean;
  currencyQuestion?: ResourceOrPlanItemPreApprovalQuestion;
  deleteFunction?: (idx) => void;
  showAmountSubtext?: boolean;
  setCurrencySelected?: (currency: CurrencyCode) => void;
}

export default function PreApprovalFormItem({
  frequencyDropdown,
  subscriptionCost,
  onTotalChange,
  resourceType,
  costFieldValidation,
  costFormattedInProgramCurrency,
  costFormattedInUsersCurrency,
  defaultToProgramCurrency,
  filesToUpload = [],
  filesProgress,
  form,
  isCostField,
  isFormDisabled,
  isHelperTextOn,
  isProgram,
  isResourceRequest,
  isTextAreaDisabled,
  isUserRequest,
  item,
  maxLength,
  onChangeCheckbox,
  onChangeSelect,
  prefilledCost,
  programCost,
  selectedCheckboxOptions,
  setFilesToUpload,
  showCount,
  user,
  total,
  programCurrency,
  rangeDates,
  setRangeDates,
  isSubscription,
  currencyQuestion,
  deleteFunction,
  showAmountSubtext,
  setCurrencySelected,
}: PreApprovalFormItemProps) {
  switch (item.inputType) {
    case PreApprovalQuestionInputType.Date:
      return (
        <DateInput
          item={item}
          isHelperTextOn={isHelperTextOn}
          rangeDates={rangeDates}
          setRangeDates={setRangeDates}
        />
      );
    case PreApprovalQuestionInputType.ImageUpload:
      return (
        <ImageUpload
          form={form}
          item={item}
          isHelperTextOn={isHelperTextOn}
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          filesProgress={filesProgress}
          deleteFunction={deleteFunction}
        />
      );
    case PreApprovalQuestionInputType.Dropdown:
      if (isRecurringType(item.functionalityType)) return null;

      return (
        <Dropdown
          item={item}
          isHelperTextOn={isHelperTextOn}
          onChangeSelect={onChangeSelect}
          isFormDisabled={isFormDisabled}
        />
      );
    case PreApprovalQuestionInputType.Decimal:
    case PreApprovalQuestionInputType.Money:
      return (
        <MoneyInput
          form={form}
          onTotalChange={onTotalChange}
          item={item}
          isCostField={isCostField}
          isProgram={isProgram}
          isResourceRequest={isResourceRequest}
          isUserRequest={isUserRequest}
          prefilledCost={prefilledCost}
          costFieldValidation={costFieldValidation}
          isFormDisabled={isFormDisabled}
          user={user}
          resourceType={resourceType}
          subscriptionCost={subscriptionCost}
          programCost={programCost}
          costFormattedInUsersCurrency={costFormattedInUsersCurrency}
          defaultToProgramCurrency={defaultToProgramCurrency}
          costFormattedInProgramCurrency={costFormattedInProgramCurrency}
          frequencyDropdown={frequencyDropdown}
          isSubscription={isSubscription}
          showAmountSubtext={showAmountSubtext}
          total={total}
          programCurrency={programCurrency}
          currencyQuestion={currencyQuestion}
          setCurrencySelected={setCurrencySelected}
        />
      );
    case PreApprovalQuestionInputType.Text:
      return (
        <TextInput
          isHelperTextOn={isHelperTextOn}
          item={item}
          maxLength={maxLength}
          isTextAreaDisabled={isTextAreaDisabled}
          resourceType={resourceType}
          showCount={showCount}
          dataCy="additionFundsTextInput"
        />
      );
    case PreApprovalQuestionInputType.Multiselect:
      return (
        <Multiselect
          item={item}
          selectedCheckboxOptions={selectedCheckboxOptions}
          isFormDisabled={isFormDisabled}
          isHelperTextOn={isHelperTextOn}
          onChangeCheckbox={onChangeCheckbox}
        />
      );
    case PreApprovalQuestionInputType.Url:
    default:
      return (
        <UrlInput
          form={form}
          item={item}
          isHelperTextOn={isHelperTextOn}
          isTextAreaDisabled={isTextAreaDisabled}
        />
      );
  }
}
