import {i18n, k} from '@i18n/translate';
import {Col, Form, Row} from 'antd';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInSelect} from '@components/reusable/LearnInForm';
import {currencyOptions} from '@utils/moneyUtils';
import {FIELD_NAME, formName} from './QuickActionModal.constants';
import {StyledForm, StyledLearnInModal} from './QuickActionModal.styled';
import {QuickActionModalViewProps} from './QuickActionModal.interfaces';
import {LANGUAGE} from '@utils/l10nUtils';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';

function QuickActionModalView({
  form,
  getInitialFormValues,
  isVisible,
  handleOk,
  handleCancel,
}: QuickActionModalViewProps) {
  const localeOptions: JSX.Element[] = (() => {
    const options = [];
    for (const x in LANGUAGE) {
      if (!LANGUAGE[x].isPseudo) {
        options.push(
          <LearnInSelectOption
            title={LANGUAGE[x].label}
            value={LANGUAGE[x].enum}
            key={LANGUAGE[x].enum}>
            {LANGUAGE[x].label}
          </LearnInSelectOption>
        );
      }
    }
    return options;
  })();

  return (
    <StyledLearnInModal
      tag={ModalTags.RemoveProgramFromUserPlan}
      visible={isVisible}
      width={400}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{id: 'quick-action-ok-button'}}
      okButtonTag={ButtonTags.Primary}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      okButtonChild={i18n.t(k.CTA__SAVE)}
      title={'Quick Action ⚡'}>
      <StyledForm
        key={formName}
        name={formName}
        form={form}
        initialValues={getInitialFormValues()}>
        <Row>
          <Col>
            <Form.Item
              label={FIELD_NAME.CURRENCY}
              name={FIELD_NAME.CURRENCY}
              rules={[{required: false}]}>
              <LearnInSelect
                placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
                style={{width: '75px'}}>
                {currencyOptions}
              </LearnInSelect>
            </Form.Item>
            <Form.Item
              label={FIELD_NAME.LOCALE}
              name={FIELD_NAME.LOCALE}
              rules={[{required: false}]}>
              <LearnInSelect
                placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
                style={{width: '225px'}}>
                {localeOptions}
              </LearnInSelect>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </StyledLearnInModal>
  );
}

export default QuickActionModalView;
