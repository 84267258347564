// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/ProgramUserRecommendationVM.cs

import {z} from 'zod';

export const ProgramUserRecommendationVMSchema = z.object({
  employeeName: z.string(),
  id: z.number(),
  recommendationLevel: z.string(),
  recommendedDate: z.string().nullable().optional(),
  status: z.string(),
  userId: z.number(),
  userPlanProgramItemCreatedOn: z.string().nullable().optional(),
});
