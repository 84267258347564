// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/PreApprovalQuestionResponseInputType.cs

enum PreApprovalQuestionInputType {
  Text,
  Dropdown,
  Decimal,
  Multiselect,
  Money,
  Url,
  Radio,
  ImageUpload,
  Date,
}

export default PreApprovalQuestionInputType;
