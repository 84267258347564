// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewApprovalRequest.cs

import {z} from 'zod';
import {PreApprovalApplicationSchema} from './PreApprovalApplicationSchema.schema';

export const UserPlanItemNewApprovalRequestSchema = z.object({
  applicationDetails: PreApprovalApplicationSchema,
  attachmentIds: z.array(z.string()),
  userBudgetId: z.number().nullable().optional(),
  userPlanItemId: z.number(),
});
