// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/LicenseStatus.cs

import {z} from 'zod';

export const LicenseStatusSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
]);
