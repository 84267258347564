// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademySkillVM.cs

import {z} from 'zod';

export const AcademySkillVMSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
});
