import {ActiveCompanyVM} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ActiveCompanyVMSchema} from '../zodSchemas/ActiveCompanyVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Dev/DevController.cs

/*
|--------------------------------------------------------------------------
|  get-companies 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCompaniesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: companies,
 *    isLoading: isCompaniesLoading,
 *    invalidateExact: invalidateCompanies,
 *  } = useCompaniesQuery(null)
 *
 */

export const useCompaniesQuery = queryHookFactory<
  null,
  ActiveCompanyVM[] // return type
>('useCompaniesQuery', () => `dev/get-companies`, {
  responseSchema: z.array(ActiveCompanyVMSchema),
});

/*
|--------------------------------------------------------------------------
|  run-background-job 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRunBackgroundJobQuery} from '@generated/hooks';
 *
 *  const {
 *    data: runBackgroundJob,
 *    isLoading: isRunBackgroundJobLoading,
 *    invalidateExact: invalidateRunBackgroundJob,
 *  } = useRunBackgroundJobQuery({
 *    queryParams: {
 *        jobName
 *    }
 *  })
 *
 */

export const useRunBackgroundJobQuery = queryHookFactory<
  {queryParams?: {jobName?: string}},
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useRunBackgroundJobQuery',
  ({queryParams}) =>
    addQueryParams(() => `dev/run-background-job`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  test-get-ip-address-and-user-agent-info 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTestIpAddressAndUserAgentInfoQuery} from '@generated/hooks';
 *
 *  const {
 *    data: testIpAddressAndUserAgentInfo,
 *    isLoading: isTestIpAddressAndUserAgentInfoLoading,
 *    invalidateExact: invalidateTestIpAddressAndUserAgentInfo,
 *  } = useTestIpAddressAndUserAgentInfoQuery(null)
 *
 */

export const useTestIpAddressAndUserAgentInfoQuery = queryHookFactory<
  null,
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useTestIpAddressAndUserAgentInfoQuery',
  () => `dev/test-get-ip-address-and-user-agent-info`,
  {responseSchema: null}
);
