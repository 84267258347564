// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanVM.cs

import {z} from 'zod';
import {UserPlanItemCardVMSchema} from './UserPlanItemCardVMSchema.schema';

export const UserPlanVMSchema = z.object({
  active: z.boolean(),
  id: z.number(),
  startDate: z.string().nullable().optional(),
  status: z.string(),
  userPlanItems: z.array(UserPlanItemCardVMSchema),
});
