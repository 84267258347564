// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanFinanceLearningBudgetOverviewVM.cs

import {z} from 'zod';
import {CreditCardOverviewVMSchema} from './CreditCardOverviewVMSchema.schema';
import {CreditCardApprovalStatusSchema} from './CreditCardApprovalStatusSchema.schema';
import {FinanceAmountTypeSchema} from './FinanceAmountTypeSchema.schema';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';
import {FinanceRecurrenceSchema} from './FinanceRecurrenceSchema.schema';
import {BalanceRecurTypeSchema} from './BalanceRecurTypeSchema.schema';

export const UserPlanFinanceLearningBudgetOverviewVMSchema = z.object({
  advancedAmount: z.number(),
  awaitingApproval: z.number(),
  balance: z.number(),
  creditCard: CreditCardOverviewVMSchema.nullable().optional(),
  creditCardApprovalStatus: CreditCardApprovalStatusSchema,
  description: z.string(),
  financeAmountType: FinanceAmountTypeSchema,
  financeIncentiveAmount: z.number(),
  financeIncentiveId: z.number(),
  financeType: FinanceTypeSchema,
  limitOnRequestedFunds: z.number(),
  pendingReimbursement: z.number(),
  programPreApprovalRequired: z.boolean(),
  recurrenceFrequency: FinanceRecurrenceSchema,
  recurrenceType: BalanceRecurTypeSchema,
  remaining: z.number(),
  spentAmount: z.number(),
  title: z.string(),
  totalApprovedFunds: z.number(),
  userPlanItemId: z.number(),
});
