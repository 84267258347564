import {ReactElement} from 'react';
import styled from 'styled-components';
import {Form} from 'antd';
import {FormItemProps, FormProps} from 'antd/lib/form';
import {FormItemTags} from './FormEnums';
import {COLORS} from '@utils/constants';
import {FormItemStyles} from './FormItemTheme';
import {InputStyles} from '../Input/InputTheme';

interface LearnInFormProps extends FormProps {
  children: ReactElement;
}

export function LearnInForm({
  children,
  ...rest
}: LearnInFormProps): ReactElement {
  return <StyledForm {...rest}>{children}</StyledForm>;
}

const StyledForm = styled(Form)``;
const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-label {
    width: ${(props) =>
      props?.widthLabel || FormItemStyles[props?.tag]?.widthLabel};
    display: ${(props) =>
      props?.displayLabel || FormItemStyles[props?.tag]?.displayLabel};
    justify-content: ${(props) =>
      props?.justifyContentLabel ||
      FormItemStyles[props?.tag]?.justifyContentLabel};
    align-items: ${(props) =>
      props?.alignItemsLabel || FormItemStyles[props?.tag]?.alignItemsLabel};
    > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
    }
    .ant-form-item-label > label::after {
      content: '';
    }
    > label {
      font-weight: 500;
      &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
          display: none !important;
        }
        &::after {
          content: '*';
          color: ${COLORS.Red800} !important;
          font-size: 1rem !important;
          display: inline-block;
          margin-left: 0.25rem;
          line-height: 1;
        }
      }
    }
  }
  & .ant-form-item-explain-error {
    color: ${(props) =>
      FormItemStyles[props?.tag]?.errorMessageColor || COLORS.Red800};
  }
  & .ant-input-number-affix-wrapper-status-error {
    border: ${(props: any) => InputStyles[props?.tag]?.errorBorder} !important;
  }

  .ant-form-item-extra {
    color: ${COLORS.Neutral900};
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    min-height: 0 !important;
    transition: all 0.5s ease-in;
    transition-delay: 0.3s;
  }

  // When there's an error on the field, this hides the helper text,
  // since it's already appended to the validation message
  &.ant-form-item-has-error {
    .ant-form-item-extra {
      position: absolute;
      opacity: 0;
      transition: none;
      z-index: -1;
    }
  }

  &.ant-form-item-has-success {
    .ant-form-item-extra {
      position: relative;
      opacity: 1;
    }
  }
`;

interface LearnInFormItemProps extends FormItemProps {
  children: ReactElement;
  isHelperTextOn?: boolean;
  tag?: FormItemTags;
  onChange?: (e: any) => void;
}

export function LearnInFormItem({
  children,
  ...rest
}: LearnInFormItemProps): ReactElement {
  return <StyledFormItem {...rest}>{children}</StyledFormItem>;
}
