import {LearningResourceType} from '@generated/enums';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {isQuestionACurrencyField} from '@utils/preApprovalRequest';

export const useIsSubscription = (
  resourceType: LearningResourceType,
  subscriptionCost: number,
  enabled: boolean
) =>
  enabled &&
  (resourceType === LearningResourceType.Subscription || !!subscriptionCost);

export const useGetCurrencyField = (
  formQuestions: ResourceOrPlanItemPreApprovalQuestion[]
) => formQuestions?.find((q) => isQuestionACurrencyField(q));
