import {NavigateFunction} from 'react-router-dom';
import {
  ProgramFinderLaunchOrigin,
  trackProgramFinderWelcomeModalView,
} from '@utils/analytics';
import {getUserProgramFinderPath, sendToUserPrograms} from '@utils/ClientPaths';

export const handleClickProgramFinder = (navigate: NavigateFunction) => {
  trackProgramFinderWelcomeModalView({
    programFinder: {
      launchOrigin: ProgramFinderLaunchOrigin.UserPlanNewRequest,
    },
  });
  navigate(getUserProgramFinderPath());
};

export const handleClickFindNewProgram = (navigate: NavigateFunction) => {
  navigate(sendToUserPrograms());
};

export const handleClickRequestProgram = (
  openCreateUserProgramDrawer: () => void
) => {
  openCreateUserProgramDrawer();
};

export const handleClickRequestResource = (
  openRequestResourceDrawer: () => void
) => {
  openRequestResourceDrawer();
};
