import * as React from 'react';
import {Typography} from 'antd';
import {TitleProps} from 'antd/lib/typography/Title';
import {TextProps} from 'antd/lib/typography/Text';
import {ParagraphProps} from 'antd/lib/typography/Paragraph';
import {COLORS} from '@utils/constants';

const {Title, Text, Paragraph} = Typography;

/*
|--------------------------------------------------------------------------
| Figma Reference
|--------------------------------------------------------------------------
*/

// https://www.figma.com/file/jIcsjhFt986gq1DIt1D9Ug/Text-Styles?node-id=23%3A0&t=RQrdfiYElq2hN3ro-0

/*
|--------------------------------------------------------------------------
| Headings
|--------------------------------------------------------------------------
*/

type TypographyFontWeight = 400 | 500 | 600 | 700;

export interface HeadingProps extends TitleProps {
  children: React.ReactNode;
  color?: COLORS;
  fontWeight?: TypographyFontWeight;
  noMargin?: boolean;
  style?: React.CSSProperties;
}

export function H1({
  children,
  color = COLORS.Neutral950,
  fontWeight = 400,
  style,
  ...props
}: HeadingProps) {
  return (
    <Title
      {...props}
      style={{
        color,
        fontSize: `2rem`,
        fontWeight,
        ...style,
      }}>
      {children}
    </Title>
  );
}

export function H2({
  children,
  color = COLORS.Neutral950,
  fontWeight = 400,
  style,
  ...props
}: HeadingProps) {
  return (
    <Title
      level={2}
      {...props}
      style={{
        color,
        fontWeight,
        fontSize: `1.5rem`,
        ...style,
      }}>
      {children}
    </Title>
  );
}

export function H3({
  children,
  color = COLORS.Neutral950,
  fontWeight = 400,
  noMargin = true,
  style,
  ...props
}: HeadingProps) {
  return (
    <Title
      level={3}
      {...props}
      style={{
        color,
        fontSize: `1.25rem`,
        fontWeight,
        margin: noMargin && 0,
        ...style,
      }}>
      {children}
    </Title>
  );
}

/*
|--------------------------------------------------------------------------
| Body
|--------------------------------------------------------------------------
*/

// There are 2 Types of Body's here because one creates a div,
// and the other a paragraph. They look the same, it depends what
// html tag is needed

type BodySize = 'normal' | 'small' | 'x-small';

export interface BodyProps {
  children: React.ReactNode;
  color?: COLORS;
  fontWeight?: TypographyFontWeight;
  lineHeight?: number;
  size?: BodySize | number;
  style?: React.CSSProperties;
}

type BodySpanProps = BodyProps & TextProps;
type BodyParagraphProps = BodyProps & ParagraphProps;

function getFontSize(size: BodySize | number): number {
  return size === 'normal'
    ? 1
    : size === 'small'
    ? 0.75
    : size === 'x-small'
    ? 0.625
    : size;
}

export function BodySpan({
  children,
  color = COLORS.Neutral950,
  fontWeight = 400,
  lineHeight = 1.5,
  size = 'normal',
  style,
  ...props
}: BodySpanProps) {
  return (
    <Text
      {...props}
      style={{
        color,
        fontWeight,
        lineHeight: `${getFontSize(size) * lineHeight}rem`,
        fontSize: `${getFontSize(size)}rem`,
        ...style,
      }}>
      {children}
    </Text>
  );
}

export function BodyParagraph({
  children,
  color = COLORS.Neutral950,
  fontWeight = 400,
  size = 'normal',
  style,
  ...props
}: BodyParagraphProps) {
  return (
    <Paragraph
      {...props}
      style={{
        color,
        fontWeight,
        fontSize: `${getFontSize(size)}rem`,
        ...style,
      }}>
      {children}
    </Paragraph>
  );
}
