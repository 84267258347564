import {Checkbox} from 'antd';
import {localizeApprovalAnswer, localizeText} from '@utils/l10nUtils';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';
import styled from 'styled-components';
import {
  getCommonRules,
  renderQuillLabel,
} from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem';
import {COLORS} from '@utils/constants';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {LearnInFormItem} from '@components/reusable/Form/Form';
import {showHelperTextIfAvailable} from '@utils/itemUtils';

const StyledCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    margin-left: 0;
    color: ${COLORS.Neutral600};
    display: flex;
  }
`;

interface MultiselectProps {
  item: ResourceOrPlanItemPreApprovalQuestion;
  selectedCheckboxOptions?: {[key: number]: string[]};
  onChangeCheckbox?: (id, checkedValues: string[]) => void;
  isFormDisabled: boolean;
  isHelperTextOn: boolean;
}

export const Multiselect = ({
  item,
  selectedCheckboxOptions,
  onChangeCheckbox,
  isFormDisabled,
  isHelperTextOn,
}: MultiselectProps) => {
  return (
    <LearnInFormItem
      className="lb-application-drawer"
      key={item.id}
      name={String(item.id)}
      label={renderQuillLabel(item)}
      extra={showHelperTextIfAvailable(
        isHelperTextOn,
        localizeText({
          translationKey: item.localizationId
            ? item.localizationId + DYNAMIC_KEYS.SUFFIXES.HELPER
            : null,
          translationValue: item.helperText,
        })
      )}
      requiredMark={false}
      valuePropName="checked"
      rules={getCommonRules(item, isHelperTextOn)}>
      <StyledCheckboxGroup
        disabled={isFormDisabled}
        value={selectedCheckboxOptions[item.id]}
        onChange={(val) => onChangeCheckbox(item.id, val)}
        key={item.id}>
        {item.options?.map((option) => (
          <Checkbox
            key={`option-${option?.responseValue}`}
            value={option?.responseValue}>
            {localizeApprovalAnswer(option?.id, option?.responseValue)}
          </Checkbox>
        ))}
      </StyledCheckboxGroup>
    </LearnInFormItem>
  );
};
