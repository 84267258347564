import {CustomProgramUserResponse} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {CustomProgramUserResponseSchema} from '../zodSchemas/CustomProgramUserResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramUsersController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/users/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsUsersByIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramsUsersById,
 *    isLoading: isCustomProgramsUsersByIdLoading,
 *    invalidateExact: invalidateCustomProgramsUsersById,
 *  } = useCustomProgramsUsersByIdQuery({
 *    customProgramId,
 *    userCompanyId
 *  })
 *
 */

export const useCustomProgramsUsersByIdQuery = queryHookFactory<
  {customProgramId: number | string; userCompanyId: number | string}, // path variables
  CustomProgramUserResponse // return type
>(
  'useCustomProgramsUsersByIdQuery',
  ({customProgramId, userCompanyId}) =>
    `custom-programs/${customProgramId}/users/${userCompanyId}`,
  {responseSchema: CustomProgramUserResponseSchema}
);

/*
|--------------------------------------------------------------------------
|  {customProgramId}/users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramsUsers,
 *    isLoading: isCustomProgramsUsersLoading,
 *    invalidateExact: invalidateCustomProgramsUsers,
 *  } = useCustomProgramsUsersQuery({
 *    customProgramId,
 *      queryParams: {
 *        incompleteContentId,
 *        limit,
 *        page,
 *        searchText
 *    }
 *  })
 *
 */

export const useCustomProgramsUsersQuery = queryHookFactory<
  {
    customProgramId: number;
    queryParams?: {
      incompleteContentId?: number;
      limit?: number;
      page?: number;
      searchText?: string;
    };
  }, // path variables
  CustomProgramUserResponse[] // return type
>(
  'useCustomProgramsUsersQuery',
  ({customProgramId, queryParams}) =>
    addQueryParams(
      ({customProgramId}) => `custom-programs/${customProgramId}/users`,
      {customProgramId},
      queryParams
    ),
  {responseSchema: z.array(CustomProgramUserResponseSchema)}
);
