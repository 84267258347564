// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanEventPreviewVM.cs

import {z} from 'zod';

export const UserPlanEventPreviewVMSchema = z.object({
  approvalStatus: z.string(),
  endDate: z.string(),
  endTime: z.string(),
  id: z.number(),
  startDate: z.string(),
  startTime: z.string(),
});
