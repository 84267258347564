/*
|---------------------------------------------------------------------------------
| Util for custom escaping - Added here to avoid "cannot access before init error"
|---------------------------------------------------------------------------------
*/

/**
 * Entity map intended to be used
 * as a default for customEscape function
 */
const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '’',
  '/': '&#x2F;',
};

/**
 * Intended to be used in i18next initialization (interpolation.escape).
 * Takes a string and returns an escaped version of the original one.
 * @param {string} originalStr original unescaped string
 * @returns {string} escaped string
 */
export const customEscape = (originalStr: string): string => {
  return originalStr.replace(/[&<>"'\/]/g, (s) => entityMap[s]);
};
