import {useState} from 'react';
import {MoneyInputContainerProps} from './MoneyInput.interfaces';
import {MoneyInputView} from './MoneyInput.view';
import {
  useMoneyInputHooks,
  useMoneyInputPrefixDisplay,
  useMonthsInitially,
  useRecurringDurationVisibility,
  useRecurringInitially,
} from './MoneyInput.hooks';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {CurrencyCode} from '@generated/enums';
import {isNil} from 'lodash';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';

export default function MoneyInputContainer({
  costFieldValidation,
  costFormattedInProgramCurrency,
  costFormattedInUsersCurrency,
  currencyQuestion,
  defaultToProgramCurrency,
  form,
  frequencyDropdown,
  isCostField,
  isFormDisabled,
  isProgram,
  isResourceRequest,
  isSubscription,
  isUserRequest,
  item,
  onTotalChange,
  prefilledCost,
  programCost,
  programCurrency,
  resourceType,
  setCurrencySelected,
  showAmountSubtext,
  subscriptionCost,
  total,
  user,
}: MoneyInputContainerProps) {
  // State
  const [isRecurring, setIsRecurring] = useState(
    useRecurringInitially(frequencyDropdown?.response)
  );
  const [months, setMonths] = useState(
    useMonthsInitially(frequencyDropdown?.response)
  );
  const currencyQuestionCode = currencyQuestion?.response;
  const [currency, setCurrency] = useState(
    !isNil(currencyQuestionCode) ? Number(currencyQuestionCode) : user.currency
  );
  const [exchangeRate, setExchangeRate] = useState(MONEY_DEFAULT.exchangeRate);
  const [costInUsersCurrency, setCostInUsersCurrency] = useState(
    costFormattedInUsersCurrency
  );
  const [showOnlyDefaultAmount, setShowOnlyDefaultAmount] = useState(
    defaultToProgramCurrency
  );
  const [isRecurringDurationVisible] = useState(
    useRecurringDurationVisibility(frequencyDropdown?.response, isSubscription)
  );
  const [isSubTextVisible] = useState(isCostField && showAmountSubtext);

  // Hooks
  const {isFeatureFlagOn} = useFeatureFlags();
  const isHelperTextOn = isFeatureFlagOn.HelperText;
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });
  const prefixDisplay = useMoneyInputPrefixDisplay({
    isRequestWithCurrencyOn,
    currency,
    programCurrency,
    userCurrency: user?.currency,
  });

  useMoneyInputHooks({
    currency,
    currencyQuestion,
    exchangeQueryData: getCurrencyExchangeQuery?.data,
    exchangeRate,
    form,
    frequencyDropdown,
    isRecurring,
    isSubscription,
    item,
    months,
    onTotalChange,
    programCost,
    programCurrency,
    setCostInUsersCurrency,
    setExchangeRate,
    setMonths,
    setShowOnlyDefaultAmount,
    total,
  });

  // Event Handlers
  const onCurrencyChange = (
    currency: CurrencyCode,
    item: ResourceOrPlanItemPreApprovalQuestion
  ) => {
    setCurrency(currency);
    setCurrencySelected?.(currency);
    form.validateFields([String(item.id)]);
  };

  return (
    <MoneyInputView
      costFieldValidation={costFieldValidation}
      costInUsersCurrency={costInUsersCurrency}
      costFormattedInProgramCurrency={costFormattedInProgramCurrency}
      costFormattedInUsersCurrency={costFormattedInUsersCurrency}
      currencyQuestion={currencyQuestion}
      defaultToProgramCurrency={defaultToProgramCurrency}
      form={form}
      frequencyDropdown={frequencyDropdown}
      item={item}
      isCostField={isCostField}
      isFormDisabled={isFormDisabled}
      isHelperTextOn={isHelperTextOn}
      isProgram={isProgram}
      isRecurring={isRecurring}
      isRecurringDurationVisible={isRecurringDurationVisible}
      isResourceRequest={isResourceRequest}
      isRequestWithCurrencyOn={isRequestWithCurrencyOn}
      isSubscription={isSubscription}
      isSubTextVisible={isSubTextVisible}
      isUserRequest={isUserRequest}
      months={months}
      onCurrencyChange={onCurrencyChange}
      onTotalChange={onTotalChange}
      prefilledCost={prefilledCost}
      prefixDisplay={prefixDisplay}
      programCost={programCost}
      resourceType={resourceType}
      setIsRecurring={setIsRecurring}
      setMonths={setMonths}
      showAmountSubtext={showAmountSubtext}
      showOnlyDefaultAmount={showOnlyDefaultAmount}
      subscriptionCost={subscriptionCost}
      total={total}
      user={user}
    />
  );
}
