import Grid from '@blocks/Grid';
import {LearningResourceType} from '@generated/enums';
import {RequestFormViewProps} from './RequestForm.interfaces';
import {
  RequestFormSection,
  RequestFormColumnsContainer,
  RequestFormWrapper,
} from './RequestForm.styles';
import {DrawerFormContentContainer} from '@components/reusable/LearnInDrawer';
import CreateUserProgramDrawerContainer from '@components/user/pages/components/createUserProgramDrawer/CreateUserProgramDrawer.container';
import RequestResourceApprovalDrawerContainer from '@components/user/components/resources/RequestResourceApprovalDrawer.container';
import {
  REQUEST_FORM_PROGRAM_DRAWER_TEST_ID,
  REQUEST_FORM_RESOURCES_DRAWER_TEST_ID,
} from './RequestForm.constants';
import WhatsNext from '@components/WhatsNext';

function RequestFormView({
  form,
  isProgram,
  handleOnCloseSuccess,
  requestType,
  setOnSubmit,
  setIsSubmitButtonLoading,
  isSubmitButtonLoading,
  transitioned,
}: RequestFormViewProps) {
  return (
    <DrawerFormContentContainer>
      <RequestFormColumnsContainer>
        <RequestFormSection transitioned={transitioned}>
          <Grid.Container>
            {isProgram ? (
              <RequestFormWrapper
                data-testid={REQUEST_FORM_PROGRAM_DRAWER_TEST_ID}>
                <CreateUserProgramDrawerContainer
                  formInstance={form}
                  onOk={() => handleOnCloseSuccess(isProgram)}
                  setRedesignsOnSubmit={setOnSubmit}
                  setIsSubmitButtonLoading={setIsSubmitButtonLoading}
                  isSubmitButtonLoading={isSubmitButtonLoading}
                  visible={true}
                />
              </RequestFormWrapper>
            ) : (
              <RequestFormWrapper
                data-testid={REQUEST_FORM_RESOURCES_DRAWER_TEST_ID}>
                <RequestResourceApprovalDrawerContainer
                  formInstance={form}
                  onCloseSuccess={handleOnCloseSuccess}
                  presetResourceType={requestType as LearningResourceType}
                  setRedesignsOnSubmit={setOnSubmit}
                  setIsSubmitButtonLoading={setIsSubmitButtonLoading}
                  isSubmitButtonLoading={isSubmitButtonLoading}
                  visible={true}
                />
              </RequestFormWrapper>
            )}
            <WhatsNext />
          </Grid.Container>
        </RequestFormSection>
      </RequestFormColumnsContainer>
    </DrawerFormContentContainer>
  );
}

export default RequestFormView;
