import {RequestMenuOptionProps} from './RequestMenuOption.interfaces';
import {
  RequestMenuButtonAndDrawerIcon,
  RequestMenuButtonAndDrawerListArrow,
  RequestMenuButtonAndDrawerListButton,
  RequestMenuButtonAndDrawerSubTitle,
  RequestMenuButtonAndDrawerTitle,
  RequestMenuButtonAndDrawerTitleSection,
} from '../RequestMenu/RequestMenu.styled';
import {REQUEST_MENU_HIDDEN_CLASS} from '../RequestMenu/RequestMenu.constants';
import CaretRight from '@assets/svg/CaretRight';

const RequestMenuOption = ({
  bodyStyle,
  onClick,
  option,
  selected,
}: RequestMenuOptionProps) => {
  return (
    option && (
      <RequestMenuButtonAndDrawerListButton
        backgroundColor={option.background}
        hoverBackground={option.hoverBackground}
        activeBackground={option.activeBackground}
        style={bodyStyle}
        iconColor={option.iconColor}
        onClick={onClick}
        disabled={selected}
        tabIndex={selected ? -1 : 0}
        data-testid={`newRequest${option.id}`}>
        <RequestMenuButtonAndDrawerIcon
          iconBackground={option.iconBackground}
          iconColor={option.iconColor}>
          <option.Icon aria-hidden="true" />
        </RequestMenuButtonAndDrawerIcon>
        <RequestMenuButtonAndDrawerTitleSection>
          <RequestMenuButtonAndDrawerTitle>
            {option.title}
          </RequestMenuButtonAndDrawerTitle>
          <RequestMenuButtonAndDrawerSubTitle>
            {option.subtitle}
          </RequestMenuButtonAndDrawerSubTitle>
        </RequestMenuButtonAndDrawerTitleSection>
        <RequestMenuButtonAndDrawerListArrow
          className={selected ? REQUEST_MENU_HIDDEN_CLASS : ''}>
          <CaretRight aria-hidden="true" className="arrow-icon" />
        </RequestMenuButtonAndDrawerListArrow>
      </RequestMenuButtonAndDrawerListButton>
    )
  );
};

export default RequestMenuOption;
