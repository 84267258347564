import {useMemo, useCallback} from 'react';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import {useParams} from 'react-router-dom';
import {useAcademyLevelsQuery} from '@generated/hooks';

type AcademyActivity = {
  lastLevel: number;
  lastPosition: number;
};

type LsAcademiesActivity = {
  [academyId: string]: AcademyActivity;
};

const ACADEMY_ACTIVITY_LS_KEY = 'ls__academy_activity';

function readLocalAcademiesActivity() {
  const academiesLs = localStorage.getItem(ACADEMY_ACTIVITY_LS_KEY);

  if (!academiesLs) {
    return null;
  }

  return JSON.parse(academiesLs) as LsAcademiesActivity;
}

/**
 * Retrieve the latest level and scroll position for an academy
 * from local storage
 */
function readLocalAcademyActivity(academyId: string) {
  if (!academyId) {
    return null;
  }

  try {
    const academies = readLocalAcademiesActivity();

    return academies[academyId];
  } catch (error) {
    return null;
  }
}

/**
 * Saves academy activity to local storage
 */
export function saveLocalAcademyActivity(
  academyId: string,
  lastActivity: {lastLevel: number; lastPosition: number}
) {
  try {
    const academiesLs = readLocalAcademiesActivity();

    const lastActivityPayload = JSON.stringify({
      ...academiesLs,
      [academyId]: lastActivity,
    });

    localStorage.setItem(ACADEMY_ACTIVITY_LS_KEY, lastActivityPayload);
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Removes academy activity from local storage
 */
export function clearLocalAcademiesActivity() {
  localStorage.removeItem(ACADEMY_ACTIVITY_LS_KEY);
}

/**
 * Converts an object to a search param string,
 * filtering out keys with null and undefined values
 */
export function toSearchParams(obj: Record<string, unknown>): string {
  const searchParamsObj = {};

  for (const key in obj) {
    if (!isNil(obj[key])) {
      searchParamsObj[key] = obj[key];
    }
  }

  const searchParams = new URLSearchParams(searchParamsObj).toString();

  return searchParams ? `?${searchParams}` : '';
}

/**
 * Retrieves the latest user activity for a given academy
 * prioritizing localStorage data first, earliest completed step second,
 * and falls back to the first level in case there's no data
 */
export const useAcademyUserActivity: () =>
  | {
      scrollContentId: number;
      lastLevel: number;
    }
  | {
      lastPosition: number;
      lastLevel: number;
    } = () => {
  const {academyId} = useParams();

  const localAcademyActivity = useMemo(
    () => readLocalAcademyActivity(academyId),
    [academyId]
  );

  const {data: academyLevels} = useAcademyLevelsQuery(
    {academyId: parseInt(academyId)},
    {enabled: !localAcademyActivity && !!academyId}
  );

  return useMemo(() => {
    // check for local data first
    if (localAcademyActivity) {
      return localAcademyActivity;
    }

    // fall back to latest completed step
    if (academyLevels instanceof Array) {
      let mostRecentlyCompleted = '0';
      let lastAssumedActivity = null;

      for (const level of academyLevels) {
        if (!level.academySteps) {
          // adding check here until a fix is added to the BE
          continue;
        }
        for (const step of level.academySteps) {
          if (
            step.status.isDone &&
            new Date(mostRecentlyCompleted) < new Date(step.status.completedOn)
          ) {
            mostRecentlyCompleted = step.status.completedOn;
            lastAssumedActivity = {
              lastLevel: level.id,
              scrollContentId: step.id,
            };
          }
        }
      }

      if (lastAssumedActivity) {
        return lastAssumedActivity;
      }

      // fall back to first level and step
      const sortedLevels = [...academyLevels].sort((a, b) => a.order - b.order);
      return {
        lastLevel: sortedLevels?.[0]?.id,
        scrollContentId: null,
      };
    }
  }, [localAcademyActivity, academyLevels]);
};

/**
 * Returns a function that handles storing scroll
 * position into localStorage memoizing the function
 * definition so it can be debounced for better performance
 */
export const useSaveScrollPosition = () => {
  const {academyId, levelId} = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSaveScrollPosition = useCallback(
    debounce((scrollPosition: number) => {
      saveLocalAcademyActivity(academyId, {
        lastLevel: parseInt(levelId),
        lastPosition: Math.round(scrollPosition),
      });
    }, 1000),
    [academyId, levelId]
  );

  return {saveScrollPosition: handleSaveScrollPosition};
};
