// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/User/UserPersonalizationVM.cs

import {z} from 'zod';

export const UserPersonalizationVMSchema = z.object({
  companyDisplayName: z.string(),
  companyLogoUrlRectangle: z.string().nullable().optional(),
  companyLogoUrlSquare: z.string().nullable().optional(),
  primaryBrandColor: z.string(),
  virtualCardColor: z.string(),
});
