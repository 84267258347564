import {useForceHidePrimaryNavigation} from '@hooks/integrated/useForceHidePrimaryNavigation';
import {useHidePrimaryNavigation} from '@hooks/integrated/useHidePrimaryNavigation';
import {useIsIntegratedUser} from '@hooks/integrated/useIsIntegratedUser';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useFlagsHaveLoaded} from '@hooks/useFlagsHaveLoaded';
import {useHandleUrlPrefix} from '@hooks/useHandleUrlPrefix';
import {CheckUserStatusResponseData} from '@models/serverModels';
import {getCheckUserStatusRq} from '@store/apiEndpoints';
import {cacheForHours, simpleQueryFn} from '@store/queryClient';
import {initZenDesk} from '@utils/chat/chat-utils';
import {initCredSpark} from '@utils/credSpark/credspark-utils';
import {useAuth} from '@utils/oidc-auth-wrapper';
import PrimaryRoutes from 'PrimaryRoutes';
import {useEffect} from 'react';
import {useQuery} from 'react-query';

const PrimaryRoutesContainer = ({}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {user} = useAuth();

  const flagsReady = useFlagsHaveLoaded(isFeatureFlagOn);
  const isCredSparkDemoEnabled = isFeatureFlagOn.CredsparkDemo;
  const isChatEnabled = !isFeatureFlagOn.HideChatWidget;
  const isApprover = useCanManageApprovals(user);
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const forceNavOff = useForceHidePrimaryNavigation(
    user,
    isFeatureFlagOn,
    isApprover
  );

  const hidePrimaryNavigation = useHidePrimaryNavigation({
    user,
    location: window.location,
    forceOff: forceNavOff,
  });

  const checkUserStatusRq = getCheckUserStatusRq();
  useQuery<CheckUserStatusResponseData>(
    checkUserStatusRq.queryKey,
    () => simpleQueryFn(checkUserStatusRq.path),
    {
      ...cacheForHours(6),
    }
  );

  useEffect(() => {
    if (!flagsReady) {
      return;
    }
    if (isChatEnabled) {
      initZenDesk(user);
    }
    if (isCredSparkDemoEnabled) {
      initCredSpark();
    }
  }, [flagsReady, isCredSparkDemoEnabled, isChatEnabled, user]);

  const handleUrlPrefix = useHandleUrlPrefix(isIntegratedUser);
  useEffect(() => {
    flagsReady && handleUrlPrefix();
  }, [flagsReady, handleUrlPrefix]);

  const isLxpAdmin = useIsLxpAdmin();

  /* This is a temporary patch to address a small bug which caused users to see the Apollo layout in production when they shouldn't have. 

  Inside layout.hook's useEffect, lxpOptions is always going to be null for non-LXP users. That means showApolloLayout (which checks for the flags inside that block) is not getting called for these users. In that scenario, showApolloLayout gets defaulted to true by the initial setup, which effectively bypasses the flags for that type of user.
  
  That will be addressed there. However, at this level, the flags will be checked to protect from this bug. The use of hasApolloFlags here should be removed when the underlying issue is fixed. For now, we need the flags to always be a determining factor so we can use them to control whether or not the feature is on while the underlying issue is addressed. */
  const hasApolloFlags =
    isFeatureFlagOn.ACMLayout || (isLxpAdmin && isFeatureFlagOn.LXPAdminLayout);

  return (
    <PrimaryRoutes
      flagsReady={flagsReady}
      hidePrimaryNavigation={hidePrimaryNavigation}
      isAdmin={user?.isAdmin}
      isLxpAdmin={isLxpAdmin}
      hasApolloFlags={hasApolloFlags}
      isIntegratedUser={isIntegratedUser}
      isManager={user?.isManager}
    />
  );
};

export default PrimaryRoutesContainer;
