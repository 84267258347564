// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Incentives/ReimbursementTargetVM.cs

import {z} from 'zod';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';
import {ReimbursementTargetProgramVMSchema} from './ReimbursementTargetProgramVMSchema.schema';

export const ReimbursementTargetVMSchema = z.object({
  financeType: FinanceTypeSchema,
  programs: z.array(ReimbursementTargetProgramVMSchema),
  userFinanceBalance: z.number(),
  userFinanceIncentiveId: z.number(),
  userFinanceTotalAmount: z.number(),
  userPendingAmount: z.number(),
  userPlanItemId: z.number(),
});
