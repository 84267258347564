// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/NewDevCompany.cs

import {z} from 'zod';

export const NewDevCompanySchema = z.object({
  isAcademiesIntegratedExperienceDisabled: z.boolean(),
  isContentMarketplaceIntegratedExperienceDisabled: z.boolean(),
  name: z.string(),
});
