// Do not modify directly
// Generated from file: learnin.core.application/DTOs/AttachmentVM.cs

import {z} from 'zod';

export const AttachmentVMSchema = z.object({
  description: z.string(),
  fileName: z.string(),
  id: z.number(),
  image: z.string(),
  type: z.string(),
  url: z.string().nullable().optional(),
});
