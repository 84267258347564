// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Payments/CreditCardAdminActionVM.cs

import {z} from 'zod';
import {AdminActionDetailVMSchema} from './AdminActionDetailVMSchema.schema';

export const CreditCardAdminActionVMSchema = z.object({
  details: z.array(AdminActionDetailVMSchema),
  note: z.string(),
  timestamp: z.string(),
  uniqueId: z.string(),
  updatedBy: z.string(),
});
