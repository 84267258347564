// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/UserPlan/UserPlanProgramItemVM.cs

import {z} from 'zod';
import {UserPlanItemVMSchema} from './UserPlanItemVMSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {UserCustomProgramContentPreviewVMSchema} from './UserCustomProgramContentPreviewVMSchema.schema';
import {ProgramProgressPreviewVMSchema} from './ProgramProgressPreviewVMSchema.schema';

const schema = z.object({
  applicationUrl: z.string(),
  approvalNotes: z.string(),
  approvalRequired: z.boolean(),
  approvedAmount: z.number(),
  cost: z.number(),
  currency: CurrencyCodeSchema,
  daysUntilExpiration: z.number().nullable().optional(),
  declinedAmount: z.number(),
  endDateUtc: z.string().nullable().optional(),
  externalReimbursementApplicationUrl: z.string().nullable().optional(),
  externalUrl: z.string(),
  incompleteCustomProgramContent: z.array(
    UserCustomProgramContentPreviewVMSchema
  ),
  isLicenseProgram: z.boolean(),
  lastCustomProgramNudgeSent: z.string().nullable().optional(),
  licenseCost: z.number().nullable().optional(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseId: z.number().nullable().optional(),
  marketplaceProgram: z.boolean(),
  nextUpdateDue: z.string().nullable().optional(),
  programId: z.number(),
  progressPercentage: z.number(),
  progressUpdates: z.array(ProgramProgressPreviewVMSchema),
  purchaseMade: z.boolean().nullable().optional(),
  reimbursementApprovedAmount: z.number().nullable().optional(),
  reimbursementRequestedAmount: z.number().nullable().optional(),
  requestedAmount: z.number(),
  startDateUtc: z.string().nullable().optional(),
  voucherCode: z.string().nullable().optional(),
});

export const UserPlanProgramItemVMSchema = UserPlanItemVMSchema.merge(schema);
