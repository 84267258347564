import NoAccess from './NoAccess';
import {useLocation} from 'react-router-dom';

const AdminWrapper = ({
  isAdmin,
  children,
}: {
  isAdmin: boolean;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  if (!isAdmin) {
    if (
      location.pathname.includes('programs/custom-programs') &&
      location.pathname.includes('submission')
    ) {
      return <NoAccess path="programs" />;
    }
    return <NoAccess path="academies" />;
  }
  return <>{children}</>;
};

export default AdminWrapper;
