// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/Requests/RemoveSkillTagPayload.cs

import {z} from 'zod';
import {ResourceSkillTypeSchema} from './ResourceSkillTypeSchema.schema';

export const RemoveSkillTagPayloadSchema = z.object({
  entityId: z.number(),
  entityType: ResourceSkillTypeSchema,
  resourceSkillId: z.number(),
});
