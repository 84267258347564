import {i18n, k} from '@i18n/translate';
import {Wand} from '@assets/svg/Wand';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {RequestProgramFinderRequestBannerProps} from './RequestProgramFinderBanner.interfaces';
import {
  RequestBannerButtonWrapper,
  RequestBannerHeader,
  RequestBannerSubtitle,
  RequestBannerTitle,
  RequestBannerWrapper,
} from './RequestProgramFinderBanner.styled';
import {REQUEST_PROGRAM_FINDER_BANNER_LINK_ID} from './RequestProgramFinderBanner.constants';

function RequestProgramFinderBanner({
  dataTestId,
  onButtonClick,
}: RequestProgramFinderRequestBannerProps) {
  return (
    <RequestBannerWrapper data-testid={dataTestId}>
      <RequestBannerHeader>
        <RequestBannerTitle>
          {i18n.t(k.PROGRAM_FINDER__TITLE)}
        </RequestBannerTitle>
        <RequestBannerSubtitle>
          {i18n.t(k.PROGRAM_FINDER__DESCRIPTION__ALT)}
        </RequestBannerSubtitle>
      </RequestBannerHeader>
      <RequestBannerButtonWrapper>
        <LearnInButton
          data-testid={REQUEST_PROGRAM_FINDER_BANNER_LINK_ID}
          tag={ButtonTags.Secondary}
          icon={<Wand />} // a11y: aria-hidden="true" is applied in <Wand />
          onClick={onButtonClick}>
          {i18n.t(k.CTA__TRY_IT_OUT)}
        </LearnInButton>
      </RequestBannerButtonWrapper>
    </RequestBannerWrapper>
  );
}

export default RequestProgramFinderBanner;
