// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramUserPermissionPayload.cs

import {z} from 'zod';
import {IAcademyAndCustomProgramPermissionSchema} from './IAcademyAndCustomProgramPermissionSchema.schema';

const schema = z.object({
  canApproveProgram: z.boolean(),
  canEdit: z.boolean(),
  canReviewProject: z.boolean(),
  canViewReport: z.boolean(),
  customProgramId: z.number(),
  userCompanyIds: z.array(z.number()),
});

export const CustomProgramUserPermissionPayloadSchema =
  IAcademyAndCustomProgramPermissionSchema.merge(schema);
